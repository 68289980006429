import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Placeholder from 'react-bootstrap/Placeholder'
import { DateTime, SystemZone } from 'luxon';
import { NerdHerderBadgeInjector } from './NerdHerderBadge';
import { getStaticStorageImageFilePublicUrl, getRandomString } from '../utilities';
import { NerdHerderFontIcon, NerdHerderBetaIcon } from './NerdHerderFontIcon';
import { NerdHerderToolTip } from './NerdHerderToolTip';

export class NerdHerderStandardCardTemplate extends React.Component {

    onClickManage() {
        const manageOptions = this.props.manageOptions;
        if (!manageOptions) {
            console.error('manage options not set');
            return;
        }

        let fullUrl = manageOptions.url;
        if (manageOptions.focusCard && manageOptions.focusTab) {
            fullUrl += `?focus=${manageOptions.focusCard}&tab=${manageOptions.focusTab}`;
        } else if (manageOptions.focusCard) {
            fullUrl += `?focus=${manageOptions.focusCard}`;
        } else if (manageOptions.focusTab) {
            fullUrl += `?tab=${manageOptions.focusTab}`;
        }
        window.location.assign(fullUrl);
    }

    render() {
        // most of the card is generated directly from the user or league object
        const localUserData = this.props.localUser;
        const userData = this.props.user;
        const leagueData = this.props.league;
        const userFeedback = this.props.userFeedback;
        let errorMessage = this.props.errorMessage;
        let onClick = this.props.onClick || null;
        if (typeof this.props.errorMessage === 'undefined' || errorMessage === null) errorMessage = this.props.errorFeedback;

        // some cards put time or date stamps in the title
        let timestampString = null;
        let timestampJsx = null;
        if (this.props.dateStamp) {
            timestampString = this.props.dateStamp.setZone(new SystemZone()).toLocaleString(DateTime.DATE_MED);
        } else if (this.props.timeStamp) {
            timestampString = this.props.timeStamp.setZone(new SystemZone()).toLocaleString(DateTime.DATETIME_SHORT);
        }

        // figure out the icon, if there is one
        let icon = null;
        if (this.props.userIcon || this.props.leagueIcon) {
            if (this.props.userIcon) {
                icon = this.props.userIcon;
                if (icon === true) {
                    icon = <Image className="rounded-circle" alt="user icon" height={50} width={50} src={userData.getImageUrl()}/>
                }
            }
            else if (this.props.leagueIcon) {
                icon = this.props.leagueIcon;
                if (icon === true) {
                    icon = <Image className="rounded rounded-2" alt="league icon" height={50} width={50} src={leagueData.getImageUrl()}/>
                }
            }

            if (this.props.iconBadge) {
                icon = <NerdHerderBadgeInjector badge={this.props.iconBadge}>{icon}</NerdHerderBadgeInjector>
            }
        }

        let centralColClassName = 'p-0 my-1 mx-3';
        if (icon) {
            centralColClassName = 'p-0 m-0';
        }

        let title = null;
        let titleRealname = null;
        let subtitle = null;
        if (this.props.userTitle ||  this.props.leagueTitle) {
            if (this.props.userTitle) {
                // if the user_title is simply true - generate it
                if (this.props.userTitle === true) {
                    title = <big><b>{userData.username}</b></big>
                    if (localUserData.hasContact(userData.id)) {
                        titleRealname = <small className="text-muted"> ({localUserData.getContact(userData.id).short_name})</small>
                    }
                }
                // if the user title was some string, just use it as is
                else {
                    title = <big><b>{this.props.userTitle}</b></big>
                }
                // some cards need a league subtitle in addition to the user title
                if (this.props.leagueSubtitle) {
                    if (this.props.leagueSubtitle === true) {
                        subtitle = <small>in: <a href={leagueData.getPageUrl()}><b>{leagueData.name}</b></a></small>
                    }
                    else if (this.props.leagueSubtitle !== false) {
                        subtitle = <small>in: <b>{this.props.leagueSubtitle}</b></small>
                    }
                        
                }
            } else {
                // if the league_title is simply true - generate it
                if (this.props.leagueTitle === true) {
                    title = <big><b>{leagueData.name}</b></big>
                }
                // if the league title was some string, just use it as is
                else {
                    title = <big><b>{this.props.leagueTitle}</b></big>
                }
            }

            if (timestampString) {
                timestampJsx = <small className="text-muted"> {timestampString}</small>
            }
        }

        let doMoreLessButton = false;
        let randomId = null;
        if (this.props.moreLessButton) {
            randomId = getRandomString(6);
            doMoreLessButton = true;
        }

        let cardStyle = {borderRadius: '0.5rem'};

        return (
            <Row id={this.props.id} name={this.props.name} className="m-1 justify-content-evenly" style={{position: 'relative'}}>
                <Col xs={12} className={`g-0 my-${this.props.verticalMargin || 1}`}>
                    <Card className="shadow-sm" style={cardStyle}>
                        {this.props.title &&
                        <Card.Title className="mx-1 mt-1 mb-0" onClick={onClick}>
                            {this.props.titleIcon &&
                            <Row className='align-items-center'>
                                <Col xs='auto' className='pe-0'>
                                    <Image alt='title icon' src={getStaticStorageImageFilePublicUrl(`/${this.props.titleIcon}`)} height='40px'/>
                                </Col>
                                <Col>
                                    <h3 className="my-0 py-0">{this.props.title}</h3>
                                </Col>
                                {this.props.betaIcon &&
                                <Col xs='auto'><NerdHerderBetaIcon/></Col>}
                            </Row>}
                            {!this.props.titleIcon &&
                            <h3>{this.props.title}</h3>}
                        </Card.Title>}
                        {this.props.subtitle &&
                        <Card.Subtitle className="ms-2 text-muted">
                            {this.props.subtitle}
                        </Card.Subtitle>}
                        {this.props.title && <hr className="my-1"/>}
                        <Card.Body className="p-2 text-left">
                            <Row className="pe-2">
                                {icon &&
                                <Col xs="auto" className="p-0 m-2" onClick={onClick}>
                                    {icon}
                                </Col>}
                                <Col className={centralColClassName}>
                                    {title && 
                                    <div onClick={onClick}>
                                        {title}{titleRealname}{timestampJsx}
                                    </div>}
                                    {subtitle &&
                                    <div>
                                        {subtitle}
                                    </div>}
                                    <div>
                                        {errorMessage &&
                                        <Alert variant="danger" className="px-2 py-1 mb-1">{errorMessage}</Alert>}
                                        {userFeedback &&
                                        <Alert variant="primary" className="px-2 py-1 mb-1">{userFeedback}</Alert>}
                                    </div>
                                    {doMoreLessButton &&
                                    <div id={randomId} className="line-clamp-4">
                                        {this.props.children}
                                    </div>}
                                    {!doMoreLessButton &&
                                    <div>
                                        {this.props.children}
                                    </div>}
                                </Col>
                            </Row>
                            {this.props.additionalContent}
                        </Card.Body>
                    </Card>
                    {this.props.additionalCards}
                </Col>
                {this.props.rightColumn &&
                <div style={{position: 'absolute', top: '10px', right: '6px', width: '40px'}}>
                    {this.props.rightColumn}
                </div>}
                {this.props.manageOptions &&
                <ManageIcon onClick={()=>this.onClickManage()}/>}
            </Row>
        )
    }
}

export class NerdHerderLoadingCard extends React.Component {
    render() {
        if (this.props.postStyle) {
            let centralColClassName = 'p-0 m-0';
            return (
                <NerdHerderStandardCardTemplate>
                    <Row className="pe-2">
                        <Col xs="auto" className="p-0 m-2">
                            <Spinner variant="primary" animation="grow" size="sm" role="status" aria-hidden="true" style={{width: '50px', height: '50px'}}/>
                        </Col>
                        <Col className={centralColClassName}>
                            <Placeholder as="span" animation="glow">
                                <Placeholder bg='primary' xs={6}/>{' '}<Placeholder xs={1}/>
                                <Placeholder xs={5}/>{' '}<Placeholder xs={1}/>
                                <Placeholder xs={3}/>{' '}<Placeholder xs={1}/>{' '}<Placeholder xs={2}/>
                            </Placeholder>
                        </Col>
                    </Row>
                </NerdHerderStandardCardTemplate>
            )
        }
        return (
            <NerdHerderStandardCardTemplate title={this.props.title || 'Loading...'}>
                <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            </NerdHerderStandardCardTemplate>
        );
    }
}

export class ManageIcon extends React.Component {

    render() {
        return (
            <div style={{position: 'absolute', top: '10px', right: '6px', width: '40px'}}>
                <NerdHerderToolTip placement='left' text='Manage'>
                    <Button ref={this.triggerRef} size='sm' variant='outline-secondary' onClick={()=>this.props.onClick()} style={{border: '0px'}}>
                        <NerdHerderFontIcon icon='flaticon-configuration-with-gear'/>
                    </Button>
                </NerdHerderToolTip>
            </div>
        )
    }

    
}