import axios from 'axios';
import axiosThrottle from 'axios-request-throttle';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDocs, addDoc, collection, doc, onSnapshot, query, where, getDoc } from 'firebase/firestore';
import { getAuth, signOut, signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
import { handleSpecialCasesRestError, getCookie, getLocal, setLocal, delLocal, getLocalParseObject, delCookie } from './utilities';

axiosThrottle.use(axios, { requestsPerSecond: 8});

export class NerdHerderRestApi {
    constructor(localUserId, restAuthKey) {
        if (!NerdHerderRestApi.instance) {
            let protocol = window.location.protocol;
            let hostname = window.location.hostname;
            let portnum = window.location.port;

            // if running in NPM on port 3000, switch to port 8080 for localhost testing
            if (hostname === 'localhost' && portnum === '3000') {
                portnum = 8080;
            }
            this.baseUrl = `${protocol}//${hostname}:${portnum}/rest/v1/`;
            this.localUserId = localUserId;
            this.restAuthKey = restAuthKey;

            this.firebaseConfig = {
                apiKey: "AIzaSyAJZe9hz0k91LKKHJ0rw_ERgmKFqcAuj94",
                authDomain: "tidal-axis-331817.firebaseapp.com",
                projectId: "tidal-axis-331817",
                storageBucket: "tidal-axis-331817.appspot.com",
                messagingSenderId: "986217896990",
                appId: "1:986217896990:web:4766841ee159697c88220f"
            };
            initializeApp(this.firebaseConfig);
            this.firestoreDb = getFirestore();
            this.firebaseAuth = getAuth();
            if (hostname.includes('nerdherder.app')) {
                this.firestorePrefix = '/database/production/';
            } else {
                this.firestorePrefix = '/database/debug/';
            }
            this.firebaseToken = null;
            this.firebaseId = null;
            this.firebaseUserObject = null;
            this.localUserId = null;
            this.firebasePollInterval = null;
            this.firebasePollCount = 0;

            // best case scenario is that we already have a user object from firebase:
            if (this.firebaseAuth.currentUser) {
                let user = this.firebaseAuth.currentUser;
                this.firebaseUserObject = this.firebaseAuth.currentUser;
                this.firebaseToken = user.accessToken;
                this.firebaseId = user.uid;
                this.localUserId = this.firebaseId.slice(2);
                this.localUserId = parseInt(this.localUserId);
            }

            // second best scenario is that the authentication happens between the instance being init'd and
            // the call to firebaseSignin()
            onAuthStateChanged(this.firebaseAuth, (user) => {
                if (user) {
                    console.debug('firebaseAuth.onAuthStateChanged: user signed in');
                    console.debug(user);
                    this.firebaseUserObject = user;
                    this.firebaseToken = user.accessToken;
                    this.firebaseId = user.uid;
                    this.localUserId = this.firebaseId.slice(2);
                    this.localUserId = parseInt(this.localUserId);
                } else {
                    console.debug('firebaseToken: no user');
                    this.firebaseToken = null;
                    this.firebaseId = null;
                    this.firebaseUserObject = null;
                    this.localUserId = null;
                }
            });

            NerdHerderRestApi.instance = this;
        }
        else {
            return NerdHerderRestApi.instance;
        }
    }

    getInstance() {
        return NerdHerderRestApi.instance;
    }

    firebaseSignin(successcallback, errorCallback) {
        // there's a good chance we're already signed in - don't sign in again
        // there's also a good chance taht when this is called the auth process with firebase isn't done yet
        // poll until it's done or 3 seconds have elapsed
        this.firebasePollInterval = setInterval(()=>{
            if (this.firebaseUserObject) {
                console.log('Firebase already signed in');
                clearInterval(this.firebasePollInterval);
                this.firebasePollInterval = null;
                if (successcallback) successcallback(this.firebaseId, this.firebaseToken, this.firestorePrefix);
            } else if (this.firebasePollCount >= 15) {
                clearInterval(this.firebasePollInterval);
                this.firebasePollInterval = null;
                this.fetchFirebaseCustomToken()
                .then(() => {
                    console.debug(`firebaseToken: ${this.firebaseToken}`);
                    signInWithCustomToken(this.firebaseAuth, this.firebaseToken)
                    .then((cred)=>{
                        console.log('Firebase signin success');
                        if (successcallback) successcallback(this.firebaseId, this.firebaseToken, this.firestorePrefix);
                        this.firebaseUserObject = cred.user;
                        console.log(cred);
                        console.log(cred.user);
                    })
                    .catch((error)=>{
                        console.error('Firebase signin failure - cannot sign into Firebase');
                        if (errorCallback) errorCallback(error);
                    });
                })
                .catch((error) => {
                    console.error('Firebase signin failure - cannot get token from NerdHerder');
                    if (errorCallback) errorCallback(error);
                });
            }
            this.firebasePollCount++;
        }, 200);
    }

    fetchFirebaseCustomToken() {
        return new Promise((resolve, reject) => {
            let timestamp = Math.floor(Date.now() / 1000);
            // if we are already signed in, refresh the token and then exit
            if (this.firebaseUserObject) {
                console.debug('Firebase - already signed in');
                this.firebaseUserObject.getIdToken()
                .then((newToken) => {
                    if (newToken !== this.firebaseToken) {
                        console.debug('Firebase - token refresh = new token');
                        this.firebaseToken = newToken;
                        this.cacheFirebaseToken(timestamp);
                    } else {
                        console.debug('Firebase - token refresh = reuse token');
                    }
                    resolve();
                    return;
                })
                .catch((error) => {
                    reject(error);
                    return;
                })
            }

            // see if there is an existing token saved to local storage
            let firebaseToken = getLocalParseObject('FirebaseToken', null);
            if (firebaseToken && firebaseToken.timestamp && firebaseToken.prefix && firebaseToken.id) {
                // if the token is older than 30 minutes, get a new one
                if (firebaseToken.timestamp + 1800 < timestamp) {
                    console.debug('Firebase - delete stale token cache');
                    delLocal('FirebaseToken');
                } else {
                    console.debug('Firebase - reuse existing token from cache');
                    this.firebaseToken = firebaseToken.token;
                    this.firebaseId = firebaseToken.id;
                    this.localUserId = this.firebaseId.slice(2);
                    this.localUserId = parseInt(this.localUserId);
                    resolve();
                    return;
                }
            }

            // if we get here then we need to get a firebase signin token
            console.debug('Firebase - retrieve new token');
            const headers = this.getApiHeaders();
            axios.get(`${this.baseUrl}self-firebase`, {withCredentials: false, headers: headers})
            .then(response => {
                this.firebaseToken = response.data.token;
                this.firebaseId = response.data.id;
                this.localUserId = this.firebaseId.slice(2);
                this.localUserId = parseInt(this.localUserId);
                this.cacheFirebaseToken(timestamp);
                resolve();
                return;
            }).catch(error => {
                handleSpecialCasesRestError(error, 'self-firebase');
                reject(error);
                return;
            });
        });
    }

    firebaseSignOut() {
        delLocal('FirebaseToken');
        signOut(this.firebaseAuth)
        .then(()=>{
            this.firebaseToken = null;
            this.firebaseId = null;
            this.firebaseUserObject = null;
            this.localUserId = null;
            console.log('firebase sign out success')
        })
        .catch((error)=>{
            console.error('firebase sign out failed');
            console.error(error);
        });
    }

    cacheFirebaseToken(timestamp) {
        setLocal('FirebaseToken', {token: this.firebaseToken, id: this.firebaseId, prefix: this.firestorePrefix, timestamp: timestamp});
    }

    genericGetEndpointData(apiName, index=null, queryParams=null, ignoreErrors=false) {
        return new Promise((resolve, reject) => {
            const headers = this.getApiHeaders();
            axios.get(this.generateRestUrl(apiName, index), {withCredentials: false, params: queryParams, headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!ignoreErrors) handleSpecialCasesRestError(error, apiName);
                    reject(error);
                });
        })
    }

    genericGetFirestoreData(apiName, index=null, queryParams=null, ignoreErrors=false) {
        return new Promise((resolve, reject) => {
            const fullApiName = `${this.firestorePrefix}${apiName}`;
            const docRef = doc(this.firestoreDb, fullApiName, index.toString());
            getDoc(docRef)
            .then((snapshot)=>{
                if (snapshot.exists()) resolve(snapshot.data());
                else reject('does not exist');
            })
            .catch((error)=>{
                if (!ignoreErrors) handleSpecialCasesRestError(error, apiName);
                reject(error);
            });
        })
    }

    genericPutEndpointData(apiName, index=null, putData, queryParams=null, ignoreErrors=false) {
        return new Promise((resolve, reject) => {
            const headers = this.getApiHeaders();
            axios.put(this.generateRestUrl(apiName, index), putData, {withCredentials: false, params: queryParams, headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!ignoreErrors) handleSpecialCasesRestError(error, apiName);
                    reject(error);
                });
        })
    }

    genericPatchEndpointData(apiName, index=null, patchData, queryParams=null, ignoreErrors=false) {
        return new Promise((resolve, reject) => {
            const headers = this.getApiHeaders();
            axios.patch(this.generateRestUrl(apiName, index), patchData, {withCredentials: false, params: queryParams, headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!ignoreErrors) handleSpecialCasesRestError(error, apiName);
                    reject(error);
                });
        })
    }

    genericPostEndpointData(apiName, index=null, postData, queryParams=null, ignoreErrors=false) {
        return new Promise((resolve, reject) => {
            const headers = this.getApiHeaders();
            axios.post(this.generateRestUrl(apiName, index), postData, {withCredentials: false, params: queryParams, headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!ignoreErrors) handleSpecialCasesRestError(error, apiName);
                    reject(error);
                });
        })
    }

    genericDeleteEndpointData(apiName, index=null, queryParams=null, ignoreErrors=false) {
        return new Promise((resolve, reject) => {
            const headers = this.getApiHeaders();
            axios.delete(this.generateRestUrl(apiName, index), {withCredentials: false, params: queryParams, headers: headers})
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!ignoreErrors) handleSpecialCasesRestError(error, apiName);
                    reject(error);
                })
        })
    }

    getApiHeaders() {
        let loginToken = getLocal('LoginToken');
        if (!loginToken) {
            loginToken = getCookie('LoginToken');
            // if we got the login token from the cookie, switch it to local storage
            if (loginToken) {
                setLocal('LoginToken', loginToken);
                delCookie('LoginToken');
            }
        }
        if (loginToken) return {'X-Access-Token': loginToken};
        return {};
    }

    generateIndexedApiName(apiName, index=null){
        let fullName = apiName;

        // all indexes on nerdherder start at 1
        if (index < 1) {
            index = null;
        }

        if (index !== null) {
            fullName += `/${index}`;
        }
        return fullName;
    }

    generateRestUrl(apiName, index=null) {
        let url = this.baseUrl + this.generateIndexedApiName(apiName, index);
        return url
    }
}
