import React from 'react';
import { load } from 'recaptcha-v3';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';

export class NerdHerderCaptcha extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.apiKey = '6Lcw2UEjAAAAAK6O5tETWeytqAt1D1OGO9X56eNY';

        // if running in NPM on port 3000 or localhost, this is for testing, use the test key
        let hostname = window.location.hostname;
        let portnum = window.location.port;
        if (hostname === 'localhost' || hostname === '192.168.68.115' || portnum === '3000') {
            this.apiKey = '6LdH6EEjAAAAAMXQ4XNtiTjBXAIAdvPrSMhU_74x';
            console.info('using test reCAPTCHA key');
        }

        this.recaptcha = null;
        const params = {
            autoHideBadge: true,
            useEnterprise: true,
        };

        load(this.apiKey, params)
        .then((recaptcha) => {
            this.recaptcha = recaptcha;
            if (this.props.page && this.props.page !== 'errorpage') {
                this.recaptcha.execute(`view_${this.props.page}`)
                .then((token) => {
                    const postData = {page: this.props.page, token: token, action: `view_${this.props.page}`};
                    this.restApi.genericPostEndpointData('captcha', null, postData, null, true);
                });
            }
        });
    }

    createAction(actionName) {
        return this.recaptcha.execute(actionName);
    }

    render() {
        return (
            <div id='new-user-captcha-checkbox'></div>
        );
    }
}

export class CaptchaNotice extends React.Component {
    render() {
        if (this.props.singleLine) {
            return (
                <div className='text-center'>
                    <small><small>
                        This site is protected by reCAPTCHA. Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </small></small>
                </div>
            );
        } else {
            return (
                <div className='text-end'>
                    <small><small>
                        This site is protected by reCAPTCHA.<br/>Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </small></small>
                </div>
            );
        }
    }
}
