import React from 'react';
import withRouter from './withRouter';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navigate } from 'react-router-dom';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { Required } from './nerdherder-components/NerdHerderBadge';
import { FormErrorText, getFormErrors, setErrorState, clearErrorState, FormTextInputLimit } from './nerdherder-components/NerdHerderFormHelpers';


class NewVenuePage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.pageRef = React.createRef();
        this.resolveRefsReload = true;

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            errorFeedback: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.setState({firebaseSigninComplete: true}), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal />);

        return(
            <NerdHerderStandardPageTemplate ref={this.pageRef} pageName='new_venue' localUser={this.state.localUser}>
                <NewVenueCard pageRef={this.pageRef} query={this.props.query} localUser={this.state.localUser}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class NewVenueCard extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const draftVenue = NerdHerderDataModelFactory('venue', null, this.props.localUser.id);
        draftVenue.email = this.props.localUser.email;

        // it is possible to create a new venue and add it directly to the league from which it was created...
        this.addToLeagueId = null;
        if (this.props.query.get('league-id')) this.addToLeagueId = this.props.query.get('league-id');

        this.state = {
            navigateTo: null,
            updating: false,
            ipAddress: null,
            ipZipcode: null,
            ipCountry: null,
            countryList: [],
            venue: draftVenue,
            acceptTerms: false,
            formErrors: {},
            formValidated: false,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('country-list', null, (d, k)=>this.updateCountryList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('ip-location', null, (d, k)=>this.updateIpLocation(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateCountryList(response, key) {
        console.debug('got country list');
        this.setState({countryList: response});

        // if we have the IP details, and the IP country is in the response, update the form with IP details
        if (this.state.ipCountry !== null && response.includes(this.state.ipCountry)) {
            console.debug('auto set the country and zip');
            this.autoUpdateCountryAndZip(this.state.ipCountry, this.state.ipZipcode);
        }
    }

    updateIpLocation(response, key) {
        console.debug(`got country ${response.country}`);
        let country = response.details.country_name || '';
        let zipcode = response.details.postal || '';
        this.setState({ipAddress: response.ip, ipCountry: country, ipZipcode: zipcode});

        // if we have the updated country list, and the IP country is in the list, update the form with IP details
        if (this.state.countryList.length > 0 && this.state.countryList.includes(country)) {
            console.debug('auto set the country and zip');
            this.autoUpdateCountryAndZip(country, zipcode);
        }
    }

    autoUpdateCountryAndZip(country, zipcode) {
        const updatedVenue = {...this.state.venue};
        updatedVenue.country = country;
        updatedVenue.zipcode = zipcode;
        this.setState({venue: updatedVenue})
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, updating: true});
            const postData = {...this.state.venue};
            postData.name = postData.name.trimEnd();
            postData.venue_string = this.getVenueString();
            postData.venue_string = postData.venue_string.trimEnd();
            if (postData.address1 !== null) postData.address1 = postData.address1.trimEnd();
            if (postData.address2 !== null) postData.address2 = postData.address2.trimEnd();
            if (postData.city !== null) postData.city = postData.city.trimEnd();
            if (postData.state_province !== null) postData.state_province = postData.state_province.trimEnd();
            if (postData.zipcode !== null) postData.zipcode = postData.zipcode.trimEnd();
            if (postData.phone !== null) postData.phone = postData.phone.trimEnd();
            if (postData.email !== null) postData.email = postData.email.trimEnd();
            if (postData.website !== null) postData.website = postData.website.trimEnd();
            if (postData.description !== null) postData.description = postData.description.trimEnd();
            if (this.addToLeagueId !== null) postData.league_id = this.addToLeagueId;
            this.restApi.genericPostEndpointData('venue', null, postData)
            .then(response => {
                const newVenueId = response.data.id;
                this.setState({navigateTo: `/app/managevenue/${newVenueId}`, updating: false});
            }).catch(error => {
                this.setState({updating: false});
                this.formUpdateError(error, null);
            });
        }
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 4) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this name is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.name = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleAddress1Change(event) {
        let value = event.target.value;
        let errorState = clearErrorState('address1', {...this.state.formErrors});
        if (value.length < 4) {
            errorState = setErrorState('address1', {...this.state.formErrors}, 'this address is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.address1 = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleAddress2Change(event) {
        let value = event.target.value;
        let errorState = clearErrorState('address2', {...this.state.formErrors});
        if (value.length < 4) {
            errorState = setErrorState('address2', {...this.state.formErrors}, 'this address is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.address2 = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleCityChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('city', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('city', {...this.state.formErrors}, 'this city name is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.city = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleStateProvinceChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('state_province', {...this.state.formErrors});
        if (value.length < 2) {
            errorState = setErrorState('state_province', {...this.state.formErrors}, 'this state or province is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.state_province = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleZipcodeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('zipcode', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('zipcode', {...this.state.formErrors}, 'this value is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.zipcode = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleCountryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('country', {...this.state.formErrors});
        const newVenue = {...this.state.venue};
        newVenue.country = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handlePhoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('phone', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('phone', {...this.state.formErrors}, 'this phone number is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.phone = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleEmailChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('email', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('email', {...this.state.formErrors}, 'this email address is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.email = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleVenueStringChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('venue_string', {...this.state.formErrors});
        if (value.length < 10) {
            errorState = setErrorState('venue_string', {...this.state.formErrors}, 'this venue information is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.venue_string = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleWebsiteChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('website', {...this.state.formErrors});
        if (value.length < 9) {
            errorState = setErrorState('website', {...this.state.formErrors}, 'this web address is too short - make sure to include the http or https part!');
        }
        const newVenue = {...this.state.venue};
        newVenue.website = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleDescriptionChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('description', {...this.state.formErrors});
        if (value.length < 10) {
            errorState = setErrorState('description', {...this.state.formErrors}, 'this description is too short');
        }
        const newVenue = {...this.state.venue};
        newVenue.description = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handlePrivateVenueChange(event) {
        let value = event.target.checked;
        let errorState = clearErrorState('private_venue', {...this.state.formErrors});
        const newVenue = {...this.state.venue};
        newVenue.private_venue = value;
        this.setState({venue: newVenue, formErrors: errorState});
    }

    handleAcceptTermsChange(event) {
        let value = event.target.checked;
        this.setState({acceptTerms: value});
    }

    getVenueString() {
        const venueData = this.state.venue;
        let venueString = venueData.venue_string;
        if (venueString === null || venueString.length === 0) {
            venueString = '';
            if (venueData.address1 !== null) venueString = venueData.address1;
            if (venueData.address2 !== null) {
                if (venueString.length > 0) venueString += ' ';
                venueString += venueData.address2;
            }
            if (venueData.city !== null) {
                if (venueString.length > 0) venueString += ', ';
                venueString += venueData.city;
            }
            if (venueData.state_province !== null) {
                if (venueString.length > 0) venueString += ' ';
                venueString += venueData.state_province;
            }
            if (venueData.zipcode !== null) {
                if (venueString.length > 0) venueString += ', ';
                venueString += `${venueData.country} ${venueData.zipcode}`;
            }
        }

        return(venueString);
    }

    render() {
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo} replace={true}/>);

        const venueData = this.state.venue;

        const countryOptions = [];
        for (const countryName of this.state.countryList) {
            countryOptions.push(<option key={countryName} value={countryName}>{countryName}</option>)
        }

        let postalCodeLabel = 'Postal Code';
        let stateProvinceLabel = 'State or Province';
        let stateProvinceText = 'state or province';
        if (this.state.venue.country === 'United States') {
            postalCodeLabel = 'Zipcode';
            stateProvinceLabel = 'State';
            stateProvinceText = 'state';
        } else if (this.state.venue.country === 'Canada') {
            postalCodeLabel = 'Postal Code';
            stateProvinceLabel = 'Province';
            stateProvinceText = 'province';
        }

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // the venue string can come from the address info or by the user overriding it...
        let venueString = this.getVenueString();

        // if any item has an error, is empty, or the topic isn't selected, then the submit is disabled
        let disableSubmitButton = false;
        if (venueData.name.length < 4 ||
            venueData.email.length < 10 ||
            venueString.length < 10 ||
            this.state.acceptTerms === false ||
            hasFormErrors === true ||
            this.state.updating) {
            disableSubmitButton = true;
        }

        // could have done this below - description can be null so just easier to do this here
        let descriptionLength = 0;
        if (venueData.description !== null) descriptionLength = venueData.description.length;

        const termsLink = <a href='/terms' target='_blank'>Terms & Conditions, etc.</a>

        return(
            <NerdHerderStandardCardTemplate title="New Venue" titleIcon='add.png'>
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Name<Required/></Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleNameChange(event)} autoComplete='off' value={venueData.name} minLength={4} maxLength={40} required/>
                        <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>This is the name of your venue - make sure to use something your patrons will recognize!</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Description</Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='description' name='description' as="textarea" rows={5} disabled={this.state.updating} onChange={(event)=>this.handleDescriptionChange(event)} autoComplete='off' value={venueData.description || ''} minLength={10} maxLength={500}/>
                            <FormTextInputLimit current={descriptionLength} max={500}/>
                        </div>
                        <FormErrorText errorId='description' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Optional - This can be any extra information you want to show your patrons - your hours, what nights games are played, what you sell, additional websites, how to set up an appointment, etc.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Address (line 1)</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleAddress1Change(event)} autoComplete='off' value={venueData.address1 || ''} minLength={4} maxLength={100}/>
                        <FormErrorText errorId='address1' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Optional - the street address of your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Address (line 2)</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleAddress2Change(event)} autoComplete='off' value={venueData.address2 || ''} minLength={4} maxLength={100}/>
                        <FormErrorText errorId='address2' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Optional - a second line on the street address address of your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleCityChange(event)} autoComplete='off' value={venueData.city || ''} minLength={3} maxLength={100}/>
                        <FormErrorText errorId='city' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Optional - the city where your venue is located.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{stateProvinceLabel}</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleStateProvinceChange(event)} autoComplete='off' value={venueData.state_province || ''} minLength={2} maxLength={100}/>
                        <FormErrorText errorId='city' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Optional - the {stateProvinceText} where your venue is located.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{`${postalCodeLabel} & Country`}<Required/></Form.Label>
                        <Form.Control className='mb-1' type="text" disabled={this.state.updating} onChange={(event)=>this.handleZipcodeChange(event)} autoComplete='postal-code' value={venueData.zipcode || ''} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='zipcode' errorState={this.state.formErrors}/>
                        <Form.Select disabled={this.state.updating} onChange={(event)=>this.handleCountryChange(event)} value={venueData.country} required>
                            {countryOptions}
                        </Form.Select>
                        <FormErrorText errorId='country' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Used to help users find leagues in their area.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Phone</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handlePhoneChange(event)} autoComplete='off' value={venueData.phone || ''} minLength={3} maxLength={20}/>
                        <FormErrorText errorId='phone' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Optional - a phone number to contact your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Website</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleWebsiteChange(event)} autoComplete='off' value={venueData.website || ''} minLength={9} maxLength={200}/>
                        <FormErrorText errorId='website' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Optional - the main website associated with your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Email Address<Required/></Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleEmailChange(event)} autoComplete='off' value={venueData.email || ''} minLength={3} maxLength={90}/>
                        <FormErrorText errorId='email' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>An email address for your venue - can be the same as your account's email.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Override Venue Location<Required/></Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='venue_string' name='venue_string' as="textarea" rows={4} disabled={this.state.updating} onChange={(event)=>this.handleVenueStringChange(event)} autoComplete='off' value={venueString} minLength={10} maxLength={100}/>
                            <FormTextInputLimit current={venueString.length} max={100}/>
                        </div>
                        <FormErrorText errorId='venue_string' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>NerdHerder will use this data to generate (Google) maps for users. Normally autogenerated from the address info above - however you can override or update as desired.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Row>
                            <Col>
                                <Form.Check id="online" name="online" label="This venue is private & should be hidden from searches" disabled={this.state.updating} onChange={(event)=>this.handlePrivateVenueChange(event)} autoComplete='off' checked={venueData.private_venue}/>
                                <Form.Text className='text-muted'>Select this option if the venue isn't 'real' and you are creating it just to collect registration fees.</Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Check id="accept_tandc" name="accept_tandc" label="I accept NerdHerder's Terms & Conditions and other Policies" disabled={this.state.updating} onChange={(event)=>this.handleAcceptTermsChange(event)} autoComplete='off' checked={this.state.acceptTerms}/>
                        <div className='text-end'>
                            {termsLink}
                        </div>
                    </Form.Group>

                    <Button type='submit' className="float-end" variant='primary' disabled={disableSubmitButton}>Create This Venue</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(NewVenuePage);
