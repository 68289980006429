import React from 'react';
import withRouter from './withRouter';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import { FormTypeahead } from './nerdherder-components/NerdHerderFormHelpers';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderUserProfileModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { UserListItem } from './nerdherder-components/NerdHerderListItems';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { NerdHerderTour } from './nerdherder-components/NerdHerderTour';

class ContactsPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.tourSteps = [
            {
                target: '#tour-info',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Contact Concept',
                content: 
                    <div className='text-start'>
                        A contact is a person you know...<i>in theory</i>
                        <br/>
                        <small>
                            <ol>
                                <li>Both users must agree to become contacts</li>
                                <li>A contact can can always see your name & phone number</li>
                                <li>In swiss tournaments, we avoid pairing contacts in the first round</li>
                            </ol>
                        </small>
                    </div>
            },
            {
                target: 'label[for=toggle-contacts]',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Your Contacts',
                content: <span>Click here to see your contacts</span>
            },
            {
                target: 'label[for=toggle-requests]',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Requests',
                content: <span>If another user has sent a contact request, you'll see those here</span>
            },
            {
                target: 'label[for=toggle-pending]',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Pending',
                content: <span>The opposite - requests you've sent are shown here</span>
            },
            {
                target: 'label[for=toggle-search]',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Search',
                content: <span>Search for users to establish contact here</span>
            }
        ];

        this.state = {
            errorFeedback: null,
            showUserProfileModal: false,
            isUpdating: false,
            isSearching: false,
            firebaseSigninComplete: false,
            localUser: null,
            contactsIdList: [],
            requestsIdList: [],
            pendingIdList: [],
            searchIdList: [],

            // autocomplete stuff
            selectedUsername: '',

            showWhichFilter: 'contacts',
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);

        // get the header alerts - if the user has contact requests default to the filter for to accept contacts
        this.restApi.genericGetEndpointData('header-alerts')
        .then((response)=>{
            const headerAlerts = response.data;
            if (headerAlerts.contact_request_count > 0) {
                this.setState({showWhichFilter: 'requests'});
            }
        })
        .catch((error)=>{
            console.error(error);
        })
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        const contactsIdList = [];
        const pendingIdList = [];
        const requestsIdList = [];
        for (const userId of userData.contact_ids) {
            const contactState = localUser.getContactState(userId);
            switch(contactState) {
                case 'friends':
                    contactsIdList.push(userId);
                    break;

                case 'pending':
                    pendingIdList.push(userId);
                    break;
                
                case 'requested':
                    requestsIdList.push(userId);
                    break;

                default:
                    // do nothing?
            }
        }
        this.setState({localUser: localUser, contactsIdList: contactsIdList, pendingIdList: pendingIdList, requestsIdList: requestsIdList});
    }

    updateLeague(leagueData, leagueId) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState((state) => {
            return {leagues: {...state.leagues, [leagueId]: newLeague}}
        });
    }

    onSwitchFilter(value) {
        this.setState({showWhichFilter: value});
        this.restPubSub.refresh('self', null);
        this.restPubSub.refresh('header-alerts', null);
    }

    onModalCancel() {
        this.setState({showUserProfileModal: false});
    }

    handleUsernameChange(selected) {
        console.debug(selected);
        if (selected) {
            this.setState((state) => {
                const newList = [...state.searchIdList];
                for (let i=0; i<newList.length; i++) {
                    // eslint-disable-next-line eqeqeq
                    if (newList[i] == selected.id) newList.splice(i, 1);
                }
                newList.unshift(selected.id);
                return {searchIdList: newList, selectedUsername: selected.username}
            });
        } else {
            this.setState({selectedUsername: ''});
        }
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);

        const userListItems = [];
        let selectedUserIdList = [];

        let showContactDetails = false;
        switch(this.state.showWhichFilter) {
            case 'contacts':
                showContactDetails = true;
                selectedUserIdList = this.state.contactsIdList;
                break;
            case 'requests':
                selectedUserIdList = this.state.requestsIdList;
                break;
            case 'pending':
                selectedUserIdList = this.state.pendingIdList;
                break;
            default:
                selectedUserIdList = this.state.searchIdList;
        }

        for (const userId of selectedUserIdList) {
            const item = <UserListItem key={userId} userId={userId} localUser={this.state.localUser} detailedContactBadge={true} showContact={showContactDetails}/>
            userListItems.push(item);
        }

        // generate the text that goes under the filter selector
        let selectionTitle = null;
        let selectionText = null;
        switch (this.state.showWhichFilter) {
            case 'contacts':
                selectionTitle = 'Contacts';
                selectionText = "You know these people (in theory at least). Contacts can see each other's phone numbers and real names. Additionally, NerdHerder will avoid pairing you in the first round during swiss tournaments.";
                break;
            case 'requests':
                selectionTitle = 'Requests';
                selectionText = "Other users who have sent you a contact request. They think they know you?";
                break;
            case 'pending':
                selectionTitle = 'Pending';
                selectionText = "People you have sent a contact request to."
                break;
            default:
                selectionTitle = 'Search';
                selectionText = "You can use the field below to search for new contacts";
        }

        return(
            <NerdHerderStandardPageTemplate pageName='contacts' headerSelection='contacts'
                                            navPath={[{icon: 'flaticon-invisible-person-of-clothes', label: 'Contacts', href: '/app/contacts'}]}
                                            localUser={this.state.localUser}>
                <NerdHerderTour name='contacts' steps={this.tourSteps} localUser={this.state.localUser}/>
                {this.state.showUserProfileModal && <NerdHerderUserProfileModal user={null}
                                                                                localUser={this.state.localUser}
                                                                                onCancel={()=>this.onModalCancel()}/>}
                <NerdHerderStandardCardTemplate id="contacts-card">
                    <Form>
                        <Form.Group className="form-outline mb-2">
                            <div className='d-grid gap-2'>
                                <ToggleButtonGroup size='sm' name='filter-contacts' type="radio" value={this.state.showWhichFilter} onChange={(event)=>this.onSwitchFilter(event)}>
                                    <ToggleButton variant='outline-primary' id='toggle-contacts' value={'contacts'}>Contacts</ToggleButton>
                                    <ToggleButton variant='outline-primary' id='toggle-requests' value={'requests'}>Requests</ToggleButton>
                                    <ToggleButton variant='outline-primary' id='toggle-pending' value={'pending'}>Pending</ToggleButton>
                                    <ToggleButton variant='outline-primary' id='toggle-search' value={'search'}>Search</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Form.Group>
                        <Form.Group className="form-outline mb-2">
                            <Form.Text>
                                <p><small>
                                    <b>{selectionTitle}</b> - {selectionText}
                                </small></p>
                            </Form.Text>
                            {this.state.showWhichFilter === 'search' &&
                            <div>
                                <Form.Group className="form-outline mb-2">
                                    <FormTypeahead placeholder='Username to filter on' disableFilter={true} delay={300} endpoint='user' queryParams={{'username-similar': 'query', 'realname-similar': 'query'}} labelKey='username' onChange={(s)=>{this.handleUsernameChange(s)}}/>
                                </Form.Group>
                            </div>}
                            <hr/>
                        </Form.Group>
                    </Form>
                    {this.state.isSearching &&
                    <b>
                        Searching... <Spinner as='span' variant="primary" animation="border" size="sm" role="status" aria-hidden="true"/>
                    </b>}
                    {!this.state.isSearching && userListItems.length === 0 &&
                    <b>
                        The filter resulted in no results. There's nothing to show.
                    </b>}
                    {userListItems}
                </NerdHerderStandardCardTemplate>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

export default withRouter(ContactsPage);
