import React from 'react';
import withRouter from './withRouter';
import Alert from 'react-bootstrap/Alert';
import { NerdHerderNavigate } from './nerdherder-components/NerdHerderNavigate';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderMessageModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { getFailureMessage } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';

class PaymentCompletePage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const urlSearchParams = new URLSearchParams(window.location.search);
        this.queryParams = Object.fromEntries(urlSearchParams.entries());
        this.nextUrl = '/app/main';

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            navigateTo: null,
            localUser: null,
            messageModal: null,
            errorFeedback: null,
        }
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        if (this.queryParams.hasOwnProperty('payment_intent')) {
            const piId = this.queryParams['payment_intent'];
            const piClientSecret = this.queryParams['payment_intent_client_secret'];
            const queryParams = {client_secret: piClientSecret};
            this.restApi.genericGetEndpointData('stripe-payment-intent', piId, queryParams)
            .then((response)=>{
                const paymentIntent = response.data;
                this.handlePaymentIntent(paymentIntent);
            })
            .catch((error)=>{
                console.error('failed to get payment intent');
                console.error(error);
            });
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        let message = getFailureMessage(error);
        this.setState({errorFeedback: message})
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    handlePaymentIntent(paymentIntent) {
        console.debug("paymentIntent.id=", paymentIntent.id);
        console.debug("paymentIntent.status=", paymentIntent.status);
        console.debug("paymentIntent.reason=", paymentIntent.reason);
        // look at the reason and figure out where we'll go on success or failure
        if (paymentIntent.reason === 'league reg fee') {
            this.nextUrl = `/app/league/${paymentIntent.league_id}`;
        } else {
            console.error('cannot determine where to go next for paymentIntent.reason=', paymentIntent.reason)
        }
        if (['requires_capture', 'processing', 'succeeded'].includes(paymentIntent.status)) this.paymentSuccess();
        else if (['requires_confirmation', 'requires_action'].includes(paymentIntent.status)) this.paymentPending();
        else this.paymentFailure();
    }

    onCancelMessageModal() {
        console.log('onCancelMessageModal()');
        this.setState({navigateTo: this.nextUrl});
    }

    paymentSuccess() {
        console.log('paymentSuccess()');
        const messageModal = <NerdHerderMessageModal title='Payment Success' message='Your payment was successfully processed.' autoCancelDuration={5000} onCancel={()=>this.onCancelMessageModal()}/>
        this.setState({messageModal: messageModal});
    }

    paymentPending() {
        console.log('paymentPending()');
        const messageModal = <NerdHerderMessageModal title='Payment Confirmation' message='Waiting on you (or your financial institution to complete payment).' onCancel={()=>this.onCancelMessageModal()}/>
        this.setState({messageModal: messageModal});
    }

    paymentFailure() {
        console.log('paymentFailure()');
        const messageModal = <NerdHerderMessageModal title='Payment Failed' message='Your payment could not be processed.' onCancel={()=>this.onCancelMessageModal()}/>
        this.setState({messageModal: messageModal});
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo} replace={true}/>);

        return(
            <NerdHerderStandardPageTemplate pageName='payment_complete' localUser={this.state.localUser}>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.messageModal}
                {!this.state.messageModal &&
                <NerdHerderLoadingModal title="Checking Payment..."/>}
            </NerdHerderStandardPageTemplate>
        );
    }
}

export default withRouter(PaymentCompletePage);
