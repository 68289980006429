import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

export class NerdHerderVerticalScroller extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        if (this.props.autoscroll === true) this.scrollToBottom();
    }

    componentDidUpdate() {
        if (this.props.autoscroll === true) this.scrollToBottom();
    }

    scrollToBottom() {
        if (this.ref.current) this.ref.current.scrollToBottom();
    }

    scrollToTop() {
        if (this.ref.current) this.ref.current.scrollToTop();
    }

    render() {
        let height = this.props.height || '500px';
        const style = {overflowX: 'hidden'};
        if (this.props.height) style['height'] = this.props.height;
        const autoHeightProps = {};
        if (this.props.maxHeight) {
            autoHeightProps['autoHeight'] = true;
            autoHeightProps['autoHeightMax'] = this.props.maxHeight;
        }

        return (
            <Scrollbars ref={this.ref} autoHide {...autoHeightProps} style={style} renderTrackHorizontal={(props)=><div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                {this.props.children}
            </Scrollbars>
        );
    }
}
