import React from 'react';
import withRouter from './withRouter';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, arrayBufferToBase64, base64ToArrayBuffer, browserSupportsWebAuthn, delCookieAfterDelay } from './utilities';
import { CBORencode, CBORdecode } from './cbor';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';

class BiometricsPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            errorFeedback: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        const sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);

        return(
            <NerdHerderStandardPageTemplate pageName='biometrics' headerSelection='settings' dropdownSelection='biometrics'
                                            navPath={[{icon: 'flaticon-configuration-with-gear', label: 'Biometric Login', href: '/app/biometrics'}]}
                                            localUser={this.state.localUser}>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                <WebauthnRegistrationCard localUser={this.state.localUser}/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class WebauthnRegistrationCard extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            userFeedback: null,
            errorFeedback: null,
            updating: false,
            showAddDeviceModal: false,
            credentials: {},
        }
    }

    componentDidMount() {
        this.refreshCredentials();
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    refreshCredentials() {
        for (const credId of this.props.localUser.webauthn_ids) {
            if (!this.state.credentials.hasOwnProperty(credId)) {
                const sub = this.restPubSub.subscribe('webauthn-credential', credId, (d, k) => {this.updateCredential(d, k)}, null, credId);
                this.restPubSubPool.add(sub);
            }
        }
    }

    updateCredential(credData, credId) {
        this.setState((state) => {
            return {credentials: {...state.credentials, [credId]: credData}}
        });
    }

    getAlgorithmNameFromId(algId) {
        switch(algId) {
            case -7:
                return 'ES256';
            case -8:
                return 'EdDSA';
            case -35:
                return 'ES384';
            case -36:
                return 'ES512';
            case -257:
                return 'RS256';
            default:
                return 'unknown';
        }
    }

    packAttestation(attestation) {
        var attestationData = {
            'clientDataJSON': new Uint8Array(attestation.response.clientDataJSON),
            'attestationObject': new Uint8Array(attestation.response.attestationObject)
        };
        return arrayBufferToBase64(CBORencode(attestationData));
    }

    onRegisterPart1() {
        this.setState({updating: true, userFeedback: 'Registration started...'});
        this.restApi.genericGetEndpointData('webauthn-register')
        .then((response) => {
            setTimeout(()=>this.onRegisterPart2(response.data.webauthn_registration_data), 500);
        })
        .catch((err) => {
            console.log(err);
            this.setState({errorFeedback: 'Did not receive registration data'});
            this.restPubSub.refresh('self');
        });
    };

    onRegisterPart2(webauthnRegistrationData) {
        this.setState({updating: true, userFeedback: 'Gathering device credentials'});
        let pkccoEncoded = webauthnRegistrationData;
        let pkcco = CBORdecode(base64ToArrayBuffer(pkccoEncoded));
        navigator.credentials.create(pkcco)
        .then((newCredentialInfo) => {
            let packedData = this.packAttestation(newCredentialInfo);
            this.setState({userFeedback: 'Retrieved device credentials'});
            setTimeout(()=>this.onRegisterPart3(packedData));
        })
        .catch((err) => {
            console.log(err);
            if (err.name === 'NotAllowedError') {
                this.setState({errorFeedback: 'Register failed (aborted)', updating: false});
            }
            else {
                this.setState({errorFeedback: `Register failed (${err.name})`, updating: false});
            }
            this.restPubSub.refresh('self');
        });
    };

    onRegisterPart3(webauthnRegistrationData) {
        this.restApi.genericPostEndpointData('webauthn-register', null, {webauthn_registration_data: webauthnRegistrationData})
        .then((response) => {
            this.setState({userFeedback: 'Device registered successfully', updating: false});
            this.restPubSub.refresh('self');
            setTimeout(()=>this.refreshCredentials(), 1000);
        })
        .catch((err) => {
            console.log(err);
            this.setState({errorFeedback: 'Device registration failed', updating: false});
            this.restPubSub.refresh('self');
        });
    }

    onRemoveCredential(credId) {
        this.setState({updating: true});
        this.restApi.genericDeleteEndpointData('webauthn-credential', credId)
        .then(response => {
            const newCredentials = {...this.state.credentials};
            delete newCredentials[credId];
            this.setState({credentials: newCredentials, updating: false, userFeedback: 'Device removed'});
            this.restPubSub.refresh('self');
        }).catch(error => {
            console.error(error);
            this.setState({errorFeedback: 'While removing, an error occurred'});
        });
    }

    render() {
        let userFeedback = this.state.userFeedback;
        let errorFeedback = this.state.errorFeedback;

        const tableRows = [];
        for (const [credId, credData] of Object.entries(this.state.credentials)) {
            const row = 
                <tr key={credId}>
                    <td>
                        <small>{credData.device_id}<br/>{this.getAlgorithmNameFromId(credData.algorithm)}</small>
                    </td>
                    <td>
                        <small>{credData.creation_date}</small>
                    </td>
                    <td>
                        <small>{credData.last_used_date}</small>
                    </td>
                    <td className='text-center'>
                        <small>{credData.use_count}</small>
                    </td>
                    <td className="text-center align-middle">
                        <Button size='sm' variant='danger' onClick={()=>this.onRemoveCredential(credId)}><NerdHerderFontIcon icon='flaticon-recycle-bin-filled-tool'/></Button>
                    </td>
                </tr>
            tableRows.push(row);
        }

        let registerButtonDisabled = false;
        if (browserSupportsWebAuthn() === false) {
            registerButtonDisabled = true;
            errorFeedback = 'Browser does not support biometrics or webauthn';
        }
        if (this.state.updating) registerButtonDisabled = true;

        return(
            <NerdHerderStandardCardTemplate id="webauthn-registration-card" title="Biometric and WebAuthn Login" titleIcon='fingerprint-scan.png' userFeedback={userFeedback} errorFeedback={errorFeedback}>
                {tableRows.length === 0 &&
                <small>
                    <p>No devices are registered to provide biometric or webauthn credentials. You should register one!</p>
                    <p>Essentially all Android phones and iPhones support biometrics. Windows 10+ and MacOS support webauthn via password or PIN. Devices like YubiKey are also supported.</p>
                </small>}
                {tableRows.length > 0 &&
                <p><small>
                    The table shows registered devices that can provide biometric or webauthn credentials. These credentials may be used instead of a password when logging in.
                </small></p>}
                {tableRows.length > 0 &&
                <Table size='sm' striped responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Created</th>
                            <th>Used</th>
                            <th>Uses</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>}
                <Button className='float-end' variant="primary" disabled={registerButtonDisabled} onClick={()=>this.onRegisterPart1()}>Register Device</Button>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(BiometricsPage);
