import React from 'react';
import withRouter from './withRouter';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderConfirmModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderJoinModelRestApi } from './NerdHerder-JoinModelRestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay, getFailureMessage, isValidHttpUrl} from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderDropzoneImageUploader } from './nerdherder-components/NerdHerderDropzone';
import { FormErrorText, getFormErrors, setErrorState, clearErrorState, FormTextInputLimit, FormTypeahead } from './nerdherder-components/NerdHerderFormHelpers';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { TableOfUsers } from './nerdherder-components/NerdHerderTableHelpers';
import { Required } from './nerdherder-components/NerdHerderBadge';

class ManageVenuePage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            venueId: this.props.params.venueId,
            venue: null,
            errorFeedback: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('venue', this.state.venueId, (d, k) => {this.updateVenue(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateVenue(venueData, key) {
        const newVenue = NerdHerderDataModelFactory('venue', venueData);
        this.setState({venue: newVenue});
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);
        if (!this.state.venue) return (<NerdHerderLoadingModal/>);

        // only allow managers to see this stuff
        if (!this.state.venue.isManager(this.state.localUser.id)) {
            return(<NerdHerderErrorModal errorFeedback='You are not a Venue Manager'/>);
        }

        return(
            <NerdHerderStandardPageTemplate pageName='venue_management' headerSelection='settings' dropdownSelection={`managevenue-${this.state.venueId}`}
                                            navPath={[{icon: 'flaticon-configuration-with-gear', label: 'Manage Venue', href: `/app/managevenue/${this.state.venueId}`}]}
                                            localUser={this.state.localUser}>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                <VenueBasicsManagementCard localUser={this.state.localUser} venue={this.state.venue}/>
                <VenueImageUpdloadCard localUser={this.state.localUser} venue={this.state.venue}/>
                <ManageVenueManagersCard localUser={this.state.localUser} venue={this.state.venue}/>
                <VenueDiscordManagementCard localUser={this.state.localUser} venue={this.state.venue}/>
                <ManageStripeCard localUser={this.state.localUser} venue={this.state.venue} query={this.props.query}/>
                <UsurpLeagueCard localUser={this.state.localUser} venue={this.state.venue}/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class VenueBasicsManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='VenueBasicsManagementCard'>
                <VenueBasicsManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class VenueBasicsManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.postalCodeLabel = 'Postal Code';
        this.stateProvinceLabel = 'State or Province';
        this.stateProvinceText = 'state or province';

        this.state = {
            updating: false,
            countryList: [],
            timezoneList: [],

            formName: '',
            formDescription: '',
            formAddress1: '',
            formAddress2: '',
            formCity: '',
            formStateProvince: '',
            formZipcode: '',
            formCountry: 'United States',
            formPhone: '',
            formEmail: '',
            formVenueString: '',
            formWebsite: '',
            formPrivateVenue: false,
            formTimezone: 'America/Chicago',

            formErrors: {},
            formValidated: false,
        }
    }

    componentDidMount() {
        this.updateVenue(this.props.venue, this.props.venue.id);
        let sub = this.restPubSub.subscribeNoRefresh('venue', this.props.venue.id, (d, k)=>this.updateVenue(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('country-list', null, (d, k)=>this.updateCountryList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('timezone-list', null, (d, k)=>this.updateTimezoneList(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}, updating: false}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateCountryList(response, key) {
        this.setState({countryList: response});
    }

    updateTimezoneList(response, key) {
        this.setState({timezoneList: response});
    }

    updateVenue(venueData, key) {
        const updatedVenue = NerdHerderDataModelFactory('venue', venueData);
        this.setState({
            formName: updatedVenue.name,
            formDescription: updatedVenue.description || '',
            formAddress1: updatedVenue.address1 || '',
            formAddress2: updatedVenue.address2 || '',
            formCity: updatedVenue.city || '',
            formStateProvince: updatedVenue.state_province || '',
            formZipcode: updatedVenue.zipcode || '',
            formCountry: updatedVenue.country,
            formPhone: updatedVenue.phone || '',
            formEmail: updatedVenue.email,
            formWebsite: updatedVenue.website || '',
            formVenueString: updatedVenue.venue_string || '',
            formPrivateVenue: updatedVenue.private_venue,
            formTimezone: updatedVenue.timezone,
            formHasBeenUpdated: false,
            updating: false,
        });
        this.determineStateProvinceValues(updatedVenue.country);
    }

    determineStateProvinceValues(country) {
        this.postalCodeLabel = 'Postal Code';
        this.stateProvinceLabel = 'State or Province';
        this.stateProvinceText = 'state or province';
        if (country === 'United States') {
            this.postalCodeLabel = 'Zipcode';
            this.stateProvinceLabel = 'State';
            this.stateProvinceText = 'state';
        } else if (country === 'Canada') {
            this.postalCodeLabel = 'Postal Code';
            this.stateProvinceLabel = 'Province';
            this.stateProvinceText = 'province';
        }
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, updating: true, formHasBeenUpdated: false});
            const patchData = {
                name: this.state.formName.trimEnd(),
                description: this.state.formDescription,
                country: this.state.formCountry,
                timezone: this.state.formTimezone,
                zipcode: this.state.formZipcode!=='' ? this.state.formZipcode.trimEnd() : null,
                state_province: this.state.formStateProvince!=='' ? this.state.formStateProvince.trimEnd() : null,
                city: this.state.formCity!=='' ? this.state.formCity.trimEnd() : null,
                address1: this.state.formAddress1!=='' ? this.state.formAddress1.trimEnd() : null,
                address2: this.state.formAddress2!=='' ? this.state.formAddress2.trimEnd() : null,
                phone: this.state.formPhone!=='' ? this.state.formPhone.trimEnd() : null,
                email: this.state.formEmail!=='' ? this.state.formEmail.trimEnd() : null,
                website: this.state.formWebsite!=='' ? this.state.formWebsite.trimEnd() : null,
                venue_string: this.state.formVenueString.trimEnd(),
                private_venue: this.state.formPrivateVenue,
            }
            this.restPubSub.patch('venue', this.props.venue.id, patchData);
        }
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 4) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this venue name is too short');
        }
        this.setState({formName: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleAddress1Change(event) {
        let value = event.target.value;
        let errorState = clearErrorState('address1', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 4) {
            errorState = setErrorState('address1', {...this.state.formErrors}, 'this address line is too short');
        }
        this.setState({formAddress1: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleAddress2Change(event) {
        let value = event.target.value;
        let errorState = clearErrorState('address2', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 4) {
            errorState = setErrorState('address2', {...this.state.formErrors}, 'this address line is too short');
        }
        this.setState({formAddress2: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleCityChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('city', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 3) {
            errorState = setErrorState('city', {...this.state.formErrors}, 'this city name is too short');
        }
        this.setState({formCity: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleStateProvinceChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('state_province', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 2) {
            errorState = setErrorState('state_province', {...this.state.formErrors}, `this ${this.stateProvinceText} name is too short`);
        }
        this.setState({formStateProvince: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleZipcodeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('zipcode', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 3) {
            errorState = setErrorState('zipcode', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formZipcode: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleCountryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('country', {...this.state.formErrors});
        this.setState({formCountry: value, formHasBeenUpdated: true, formErrors: errorState});
        this.determineStateProvinceValues(value);
    }

    handleTimezoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('timezone', {...this.state.formErrors});
        this.setState({formTimezone: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handlePhoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('phone', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 3) {
            errorState = setErrorState('phone', {...this.state.formErrors}, 'this phone number is too short');
        }
        this.setState({formPhone: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleEmailChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('email', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('email', {...this.state.formErrors}, 'this email address is too short');
        }
        this.setState({formEmail: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleWebsiteChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('website', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 9) {
            errorState = setErrorState('website', {...this.state.formErrors}, 'this web address is too short - make sure to include the http or https part!');
        }
        this.setState({formWebsite: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleVenueStringChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('venue_string', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 10) {
            errorState = setErrorState('venue_string', {...this.state.formErrors}, 'this venue information is too short');
        }
        this.setState({formVenueString: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleDescriptionChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('description', {...this.state.formErrors});
        if (value.length < 10) {
            errorState = setErrorState('description', {...this.state.formErrors}, 'this description is too short');
        }
        this.setState({formDescription: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handlePrivateVenueChange(value) {
        let errorState = clearErrorState('private_venue', {...this.state.formErrors});
        this.setState({formPrivateVenue: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    render() {
        const countryOptions = [];
        for (const countryName of this.state.countryList) {
            countryOptions.push(<option key={countryName} value={countryName}>{countryName}</option>)
        }

        // create timezone priority options if the timezone matches the user's country
        const timezoneNamesPriority = [];
        const timezoneNamesOthers = [];
        for (const timezone of this.state.timezoneList) {
            if (timezone.country_name === this.props.venue.country) {
                timezoneNamesPriority.push(timezone.timezone_name);
            } else {
                timezoneNamesOthers.push(timezone.timezone_name);
            } 
        }
        timezoneNamesPriority.sort();
        timezoneNamesOthers.sort();
        const timezoneOptions = [];
        for (const timezoneName of timezoneNamesPriority) {
            const timezoneItem = <option key={timezoneName} value={timezoneName}>{timezoneName}</option>
            timezoneOptions.push(timezoneItem);
        }
        for (const timezoneName of timezoneNamesOthers) {
            const timezoneItem = <option key={timezoneName} value={timezoneName}>{timezoneName}</option>
            timezoneOptions.push(timezoneItem);
        }

        let privateTitle = null;
        let privateCurrentBlurb = null;
        let privateDescription = null;
        if (this.state.formPrivateVenue) {
            privateTitle = 'Private';
            privateDescription = "Your venue is normally hidden. If a league is hosted by this venue, it will be visible through the league. This is a good option if the venue isn't real, and is only being used to collect registration fees."
        } else {
            privateTitle = 'Public';
            privateDescription = 'Your venue is public. When players are creating events or leagues nearby, they will be given the option of selecting this venue. This is the recommended setting.'
        }
        if (this.state.formPrivateVenue === this.props.venue.private_venue) privateCurrentBlurb = " (the current setting)";
        
        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // if any item has an error or a required item is empty, then the submit is disabled
        let disableSubmitButton = false;
        if (this.state.formName.length < 6 ||
            this.state.formEmail.length < 3 ||
            hasFormErrors === true || this.state.formHasBeenUpdated === false || this.state.updating) {
            disableSubmitButton = true;
        }

        return(
            <NerdHerderStandardCardTemplate id="basics-card" title="Venue Basics" titleIcon="building_config.png">
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Name<Required/></Form.Label>
                        <Form.Control id='name' name='name' type="text" disabled={this.state.updating} onChange={(e)=>this.handleNameChange(e)} autoComplete='off' value={this.state.formName} minLength={4} maxLength={40} required/>
                        <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                        <Form.Text muted>This is the name of your venue - make sure to use something your patrons will recognize!</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Description</Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='description' name='description' as="textarea" rows={5}  disabled={this.state.updating} onChange={(e)=>this.handleDescriptionChange(e)} autoComplete='off' value={this.state.formDescription} minLength={10} maxLength={500}/>
                            <FormTextInputLimit current={this.state.formDescription.length} max={500}/>
                        </div>
                        <FormErrorText errorId='description' errorState={this.state.formErrors}/>
                        <Form.Text muted>Optional - This can be any extra information you want to show your patrons - your hours, what nights games are played, what you sell, additional websites, how to set up an appointment, etc.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Address (line 1)</Form.Label>
                        <Form.Control id='address1' name='address1' type="text" disabled={this.state.updating} onChange={(e)=>this.handleAddress1Change(e)} autoComplete='off' value={this.state.formAddress1} minLength={4} maxLength={100}/>
                        <FormErrorText errorId='address1' errorState={this.state.formErrors}/>
                        <Form.Text muted>Optional - the street address of your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Address (line 2)</Form.Label>
                        <Form.Control id='address2' name='address2' type="text" disabled={this.state.updating} onChange={(e)=>this.handleAddress2Change(e)} autoComplete='off' value={this.state.formAddress2} minLength={4} maxLength={100}/>
                        <FormErrorText errorId='address2' errorState={this.state.formErrors}/>
                        <Form.Text muted>Optional - a second line on the street address address of your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control id='city' name='city' type="text" disabled={this.state.updating} onChange={(e)=>this.handleCityChange(e)} autoComplete='off' value={this.state.formCity} minLength={3} maxLength={100}/>
                        <FormErrorText errorId='city' errorState={this.state.formErrors}/>
                        <Form.Text muted>Optional - the city where your venue is located.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{this.stateProvinceLabel}</Form.Label>
                        <Form.Control id='state_province' name='state_province' type="text" disabled={this.state.updating} onChange={(e)=>this.handleStateProvinceChange(e)} autoComplete='off' value={this.state.formStateProvince} minLength={2} maxLength={100}/>
                        <FormErrorText errorId='state_province' errorState={this.state.formErrors}/>
                        <Form.Text muted>Optional - the {this.stateProvinceText} where your venue is located.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{`${this.postalCodeLabel}, Country & Timezone`}<Required/></Form.Label>
                        <Form.Control id='zipcode' name='zipcode'className='mb-1' type="text" disabled={this.state.updating} onChange={(event)=>this.handleZipcodeChange(event)} autoComplete='postal-code' value={this.state.formZipcode} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='zipcode' errorState={this.state.formErrors}/>
                        <Form.Select id='country' name='country' className='mb-1' disabled={this.state.updating} onChange={(event)=>this.handleCountryChange(event)} value={this.state.formCountry} required>
                            {countryOptions}
                        </Form.Select>
                        <Form.Select id='timezone' name='timezone' disabled={this.state.updating} onChange={(event)=>this.handleTimezoneChange(event)} value={this.state.formTimezone} required>
                            {timezoneOptions}
                        </Form.Select>
                        <FormErrorText errorId='country' errorState={this.state.formErrors}/>
                        <Form.Text muted>Used to find patrons for your venue, and present time & date information to them in their local timezone.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Phone Number</Form.Label>
                        <Form.Control id='phone' name='phone' type="text" disabled={this.state.updating} onChange={(e)=>this.handlePhoneChange(e)} autoComplete='off' value={this.state.formPhone} minLength={3} maxLength={20}/>
                        <FormErrorText errorId='phone' errorState={this.state.formErrors}/>
                        <Form.Text muted>Optional - a phone number to contact your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Website</Form.Label>
                        <Form.Control id='website' name='website' type="text" disabled={this.state.updating} onChange={(e)=>this.handleWebsiteChange(e)} autoComplete='off' value={this.state.formWebsite} minLength={9} maxLength={200}/>
                        <FormErrorText errorId='website' errorState={this.state.formErrors}/>
                        <Form.Text muted>Optional - the main website associated with your venue.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue Email Address</Form.Label>
                        <Form.Control id='email' name='email' type="text" disabled={this.state.updating} onChange={(e)=>this.handleEmailChange(e)} autoComplete='off' value={this.state.formEmail} minLength={3} maxLength={90}/>
                        <FormErrorText errorId='email' errorState={this.state.formErrors}/>
                        <Form.Text muted>An email address for your venue - can be the same as your account's email.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Override Venue Location</Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='venue_string' name='venue_string' as="textarea" rows={2}  disabled={this.state.updating} onChange={(e)=>this.handleVenueStringChange(e)} autoComplete='off' value={this.state.formVenueString} maxLength={100}/>
                            <FormTextInputLimit current={this.state.formVenueString.length} max={100}/>
                        </div>
                        <FormErrorText errorId='venue_string' errorState={this.state.formErrors}/>
                        <Form.Text muted>NerdHerder will use this data to generate (Google) maps for users. Normally generated from the address info above - however you can override or update as desired. Leave blank to let NerdHerder regenerate it.</Form.Text>
                    </Form.Group>

                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='venue-private' type="radio" value={this.state.formPrivateVenue} onChange={(v)=>this.handlePrivateVenueChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-private' value={true}>Private</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-public' value={false}>Public</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-3'>
                        <Form.Text>
                            <p><b>{privateTitle}{privateCurrentBlurb}</b> - {privateDescription}</p>
                        </Form.Text>
                    </Form.Group>

                    <Button type='submit' className="float-end" variant='primary' disabled={disableSubmitButton}>Update</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class VenueImageUpdloadCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='VenueImageUpdloadCard'>
                <VenueImageUpdloadCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class VenueImageUpdloadCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.dzRef = React.createRef();

        this.state = {
            updating: false,
        }
    }

    componentDidMount() {
        this.updateVenue(this.props.venue, this.props.venue.id);
        let sub = this.restPubSub.subscribeNoRefresh('venue', this.props.venue.id, (d, k)=>this.updateVenue(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateVenue(venueData, key) {
        this.setState({updating: false})
    }

    onDzSending(file) {
        this.setState({updating: true})
    }

    onDzSuccess(file) {
        this.dzRef.current.clearUploadedFiles();
        this.restPubSub.refresh('venue', this.props.venue.id);
    }

    onDzError(file) {
        this.dzRef.current.clearUploadedFiles();
        this.restPubSub.refresh('venue', this.props.venue.id);
    }

    render() {
        return(
            <NerdHerderStandardCardTemplate id="image-upload-card" title="Image Upload" titleIcon='picture.png'>
                <Form>
                    <Form.Group className="mb-2">
                        <Row className='text-center align-items-center'>
                            <Col xs='auto'>
                                {!this.state.updating &&
                                <Image className='rounded mb-1' src={this.props.venue.getImageUrl()} width='100px' alt='venue image'/>}
                                {this.state.updating &&
                                <Spinner variant="primary" animation="border" role="status" aria-hidden="true" style={{width: '100px', height: '100px'}}/>}
                            </Col>
                            <Col>
                                <NerdHerderDropzoneImageUploader
                                    ref={this.dzRef}
                                    localUser={this.props.localUser}
                                    message={'Drop file here to update'}
                                    uploadUrl={`/rest/v1/dz-venue-image-upload/${this.props.venue.id}`}
                                    sendingCallback={(f)=>this.onDzSending(f)}
                                    successCallback={(f)=>this.onDzSuccess(f)}
                                    errorCallback={(f)=>this.onDzError(f)}/>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class ManageVenueManagersCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ManageVenueManagersCard'>
                <ManageVenueManagersCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ManageVenueManagersCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            creatorUserId: this.props.venue.creator_id,
            managerUserIds: [],
            addUserId: null,
        }
    }

    componentDidMount() {
        this.updateVenue(this.props.venue, this.props.venue.id);
        let sub = this.restPubSub.subscribeNoRefresh('venue', this.props.venue.id, (d, k)=>this.updateVenue(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateVenue(venueData, key) {
        const updatedVenue = NerdHerderDataModelFactory('venue', venueData);
        this.setState({managerUserIds: updatedVenue.manager_ids, creatorUserId: updatedVenue.creator_id, updating: false});
    }

    onChangeManager(managerId, isManager) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-venue', 'user-venue',
                                                                'user-id', managerId,
                                                                'venue-id', this.props.venue.id);
        joinModelRestApi.patch({manager: isManager})
        .then((response)=>{
            this.restPubSub.refresh('venue', this.props.venue.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    handleChangeUsername(userDetails) {
        if (userDetails === null) {
            this.setState({addUserId: null});
        } else {
            this.setState({addUserId: userDetails.id});
        }
    }

    render() {
        // can't add the user as a manger if we are already adding a user, if the user isn't set, or if the user is already a manager
        let addUserDisabled = false;
        if (this.state.updating) addUserDisabled = true;
        if (this.state.addUserId === null) addUserDisabled = true;
        if (this.state.managerUserIds.includes(this.state.addUserId)) addUserDisabled = true;

        // hide the delete button for the creator
        const deleteButtonList = []
        for (const userId of this.state.managerUserIds) {
            // eslint-disable-next-line eqeqeq
            if (userId != this.props.venue.creator_id) {
                deleteButtonList.push(userId);
            }
        }

        // don't allow a venue to go past 10 managers
        const managerLimit = 10;
        let showAddButton = true;
        if (this.state.managerUserIds.length >= managerLimit) showAddButton = false;

        return(
            <NerdHerderStandardCardTemplate id="venue-managers-card" title="Venue Managers" titleIcon="helmet.png">
                <p className='text-muted'>
                    <small>Configure your venue managers here. You may assign up to {managerLimit}. Managers have full access to the public's view of your venue and all events and leagues hosted by it, so choose them wisely.</small>
                </p>
                <Form>
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.managerUserIds} title='Venue Managers' disable={this.state.updating} headers={['Manager']} showDeleteButton={deleteButtonList} onDelete={(userId)=>this.onChangeManager(userId, false)} localUser={this.props.localUser}/>
                    </Form.Group>
                    {showAddButton &&
                    <Form.Group className='form-outline mb-3'>
                        <Row>
                            <Col>
                                <Form.Label>Add Manager</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pe-0'>
                                <FormTypeahead placeholder='Username' delay={300} endpoint='user' queryParams={{'username-similar': 'query'}} labelKey='username' onChange={(s)=>{this.handleChangeUsername(s)}} disabled={this.state.updating}/>
                            </Col>
                            <Col xs='auto'>
                                <Button className='mt-1 float-end' size='sm' type='button' variant='primary' disabled={addUserDisabled} onClick={()=>this.onChangeManager(this.state.addUserId, true)}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                            </Col>
                        </Row>
                    </Form.Group>}
                    {!showAddButton &&
                    <Form.Group className='form-outline mb-3'>
                        <Row>
                            <Col>
                                <b className='text-danger'>This venue has reached the maximum number of managers</b>
                            </Col>
                        </Row>
                    </Form.Group>}
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class ManageStripeCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ManageStripeCard'>
                <ManageStripeCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ManageStripeCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.alreadySubscribedToStripeAccount = false;

        this.state = {
            updating: false,
            showDeleteAccountModal: false,
            venue: null,
            stripeAccount: null,
            mode: null,
            stripeReturnParam: this.props.query.get('stripe-result'),
            userFeedback: null,
            errorFeedback: null,
            accountLinkUrl: null,
            formStripeAccountId: '',
            formErrors: {},
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('venue', this.props.venue.id, (d, k)=>this.updateVenue(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateVenue(venueData, key) {
        const updatedVenue = NerdHerderDataModelFactory('venue', venueData);
        // modes are add, onboarding, complete
        let mode = 'add';
        if (updatedVenue.stripe_account_id) mode = 'onboarding';
        if (updatedVenue.stripe_onboarding_complete) mode = 'complete';
        this.setState({venue: updatedVenue, mode: mode, updating: false});

        if ((mode === 'onboarding' || mode === 'complete') && !this.alreadySubscribedToStripeAccount) {
            this.alreadySubscribedToStripeAccount = true;
            let sub = this.restPubSub.subscribe('venue-stripe', this.props.venue.id, (d, k)=>this.updateStripeAccount(d, k));
            this.restPubSubPool.add(sub);
            this.setState({updating: true});
        }
    }

    updateStripeAccount(stripeAccountData, key) {
        this.setState({stripeAccount: stripeAccountData, updating: false});

        // if there is an error, we'll want to refresh the venue
        if (stripeAccountData.error_message !== null) {
            this.setState({errorFeedback: stripeAccountData.error_message, updating: true});
            this.restPubSub.refresh('venue', this.state.venue.id, 1000);
        }
    }

    handleStripeIdChange(event) {
        const value = event.target.value;
        this.setState({formStripeAccountId: value});
    }

    createStripeAccount() {
        this.setState({userFeedback: 'Creating account...one sec...', updating: true, errorFeedback:null});
        this.restApi.genericPostEndpointData('venue-stripe', this.props.venue.id, {stripe_account_id: null})
        .then((response)=>{
            this.setState({userFeedback: 'Successfully created account...', updating: true, errorFeedback: null});
            this.doStripeAccountLinkOnboardingRedirect();
        })
        .catch((error)=>{
            let message = getFailureMessage(error, 'Failed to create stripe account');
            this.setState({errorFeedback: message, userFeedback: null, updating: false});
            console.error('encountered error while trying to create a stripe account');
            console.error(error)
        });
    }

    doStripeAccountLinkOnboardingRedirect() {
        this.setState({userFeedback: 'Redirecting to Stripe...', updating: true, errorFeedback:null});
        this.restApi.genericGetEndpointData('venue-stripe-account-link', this.props.venue.id)
        .then((response)=>{
            const stripeAccountLink = response.data.stripe_account_link;
            this.setState({
                updating: false,
            });
            window.open(stripeAccountLink, '_self');
        })
        .catch((error)=>{
            this.setState({errorFeedback: 'Failed to retrieve stripe account link', userFeedback: null});
            console.error('encountered error while trying to retrieve stripe account link');
            console.error(error)
        })
    }

    doStripeAccountLinkRedirect() {
        this.setState({userFeedback: 'Redirecting to Stripe...', updating: false, errorFeedback:null});
        window.open('https://connect.stripe.com/login');
    }

    onDeleteStripeAccountClicked() {
        this.setState({showDeleteAccountModal: true});
    }

    onDeleteStripeAccountAccept() {
        this.setState({errorFeedback: 'Deleting Stripe account from NerdHerder...', updating: true, userFeedback:null});
        this.restApi.genericDeleteEndpointData('venue-stripe', this.props.venue.id)
        .then((response)=>{
            this.setState({
                showDeleteAccountModal: false,
                venue: null,
                stripeAccount: null,
                mode: null,
                userFeedback: null,
                errorFeedback: null
            });
            this.restPubSub.refresh('venue', this.state.venue.id, 1000);
        })
        .catch((error)=>{
            this.setState({errorFeedback: 'Failed to retrieve stripe account link', userFeedback: null});
            console.error('encountered error while trying to retrieve stripe account link');
            console.error(error)
        })
    }

    onDeleteStripeAccountCancel() {
        this.setState({showDeleteAccountModal: false});
    }

    render() {
        // we'll hide this card if this user isn't a manager or if we don't know the mode yet
        if (this.props.venue.isManager(this.props.localUser.id) === false) return(null);
        if (this.state.mode === null) return(null);

        // change title based on mode
        let title = 'Add Stripe Payments';
        if (this.state.mode === 'onboarding') title = 'Complete Stripe Setup';
        else if (this.state.mode === 'complete') title = 'Stripe Account';

        return(
            <NerdHerderStandardCardTemplate id="manage-stripe-card" title={title} titleIcon="stripe.png">
                {this.state.userFeedback &&
                <Alert variant='primary'>{this.state.userFeedback}</Alert>}
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.showDeleteAccountModal &&
                <NerdHerderConfirmModal title='Delete NerdHerder Stripe Account?'
                                        message='This will remove your Stripe account details from NerdHerder. This will not fully delete the account on Stripe (there may be cash in the account).'
                                        onCancel={()=>this.onDeleteStripeAccountCancel()}
                                        onAccept={()=>this.onDeleteStripeAccountAccept()}
                                        acceptButtonText='Delete'>
                    <div>
                        <p>To delete your account on Stripe, you can follow this <a target='_blank' rel='noreferrer' href={'https://dashboard.stripe.com/settings/account'}>link</a>. You may have to log in first.</p>
                    </div>
                </NerdHerderConfirmModal>}
                {this.state.mode === 'add' &&
                <div>
                    <Row>
                        <Col xs={12}>
                            <p className='text-muted'>
                                <small>NerdHerder uses Stripe for player registration fees. We don't want to manage any of your financial info, and Stripe is the payment processor used by Amazon, Etsy, Apple, and Target. They know what they're doing. If you would like to collect player registration fees, you should take a few minutes and set up or connect your existing an account. It's quick, safe, and easy!</small>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="d-grid gap-2">
                                <Button onClick={()=>this.createStripeAccount()}>Add Stripe Account</Button>
                            </div>
                        </Col>
                    </Row>
                </div>}
                {this.state.mode === 'onboarding' &&
                <div>
                    <Row>
                        <Col xs={12}>
                            <p className='text-muted'>
                                <small>Stripe shows that you have an account, but onboarding is not yet complete. Onboarding is complete when your Stripe account can receive payments.</small>
                            </p>
                            <p className='text-muted'>
                                <small><b>Stripe sent an email verification, make sure you open it and complete the verification!</b></small>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="d-grid gap-2">
                                <Button variant='primary' onClick={()=>this.doStripeAccountLinkOnboardingRedirect()}>Complete Onboarding</Button>
                                <Button variant='danger' onClick={()=>this.onDeleteStripeAccountClicked()}>Delete Stripe Account</Button>
                            </div>
                        </Col>
                    </Row>
                </div>}
                {this.state.mode === 'complete' &&
                <div>
                    <Row>
                        <Col xs={12}>
                            <p className='text-muted'>
                                <small>Your Stripe account is set up and ready to receive payments.</small>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="d-grid gap-2">
                                <Button variant='primary' onClick={()=>this.doStripeAccountLinkRedirect()}>Open Stripe Account</Button>
                                <Button variant='danger' onClick={()=>this.onDeleteStripeAccountClicked()}>Delete Stripe Account</Button>
                            </div>
                        </Col>
                    </Row>
                </div>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class UsurpLeagueCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='UsurpLeagueCard'>
                <UsurpLeagueCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class UsurpLeagueCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showConfirmModal: false,
            userFeedback: null,
            selectedLeagueId: 0,
            leagues: {}
        }
    }

    componentDidMount() {
        for (const leagueId of this.props.venue.league_ids) {
            const sub = this.restPubSub.subscribeNoRefresh('league', leagueId, (d, k)=>this.updateLeague(d, k), null, leagueId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, leagueId) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState((state) => {
            return {leagues: {...state.leagues, [leagueId]: newLeague}}
        });
    }

    handleSelectLeagueId(event) {
        const value = event.target.value;
        if (value > 0) {
            this.setState({selectedLeagueId: value});
        }
    }

    onUsurpLeagueClicked() {
        this.setState({showConfirmModal: true});
    }

    onUsurpLeague() {
        this.setState({showConfirmModal: false, updating: true});
        this.restApi.genericPostEndpointData('venue-usurp-league', this.props.venue.id, {league_id: this.state.selectedLeagueId})
        .then((response)=>{
            this.restPubSub.refresh('league', this.state.selectedLeagueId, 1000);
            this.restPubSub.refresh('header-leagues', null, 1500);
            this.setState({
                userFeedback: `You have taken control of ${this.state.leagues[this.state.selectedLeagueId].name}`,
                selectedLeagueId: 0,
                updating: false
            });
        })
        .catch((error)=>{
            console.error('POST failed when trying to usurp league');
            console.error(error);
            this.setState({updating: false});
        });
    }

    render() {
        // create all the leagues the user could usurp
        const leagueOptionItems = []
        const nullOption = <option key={0} value={0}>No League Selected</option>
        leagueOptionItems.push(nullOption);
        for (const [leagueId, league] of Object.entries(this.state.leagues)) {
            let isDisabled = false;
            if (league.creator_id === this.props.localUser.id) isDisabled = true;
            const optionItem = <option key={leagueId} value={leagueId} disabled={isDisabled}>{league.name}</option>
            leagueOptionItems.push(optionItem);
        }

        let buttonDisabled = true;
        // eslint-disable-next-line eqeqeq
        if (this.state.selectedLeagueId != 0) buttonDisabled = false;

        return(
            <NerdHerderStandardCardTemplate id="venue-usurper-card" title="Usurp The Throne" titleIcon="knife.png">
                {this.state.showConfirmModal &&
                <NerdHerderConfirmModal title='Usurp League?'
                                        message='You will dipose the existing league creator by force, install yourself as creator and organizer, and take control of the league?'
                                        acceptButtonText='Yes, Do It!'
                                        onAccept={()=>this.onUsurpLeague()}
                                        onCancel={()=>this.setState({showConfirmModal: false})}/>}
                {this.state.userFeedback &&
                <Alert variant='primary'>You have taken control of the league.</Alert>}
                <p>
                    <small className='text-muted'>As a venue manager, you can usurp (take over) any event or league hosted by your venue. <b className='text-danger'>This can be disruptive to the league organizers - use with caution</b>.</small>
                </p>
                <p>
                    <small className='text-muted'>You will become the league creator - the organizer that cannot be removed by other league organizers. The existing organizer will still remain an organizer, but you can remove them if needed.</small>
                </p>
                <p>
                    <small className='text-muted'>This is a last resort option. It should be used when:</small>
                </p>
                <small className='text-muted'>
                    <ul>
                        <li>The league is dead or finished but the organizers aren't concluding it.</li>
                        <li>The league is active but the organizers are mismanaging it.</li>
                        <li>There are inappropriate things going on in the league that damage your brand.</li>
                    </ul>
                </small>
                <p>
                    <small className='text-muted'><i>You do not need to be a league organizer to collect player registration fees from it!</i></small>
                </p>
                <Form>
                    <Form.Group className='form-outline mb-3'>
                        <Form.Select disabled={this.state.updating} onChange={(event)=>this.handleSelectLeagueId(event)} value={this.state.selectedLeagueId} required>
                            {leagueOptionItems}
                        </Form.Select>
                    </Form.Group>
                    <Button className='float-end' type='button' variant='danger' disabled={buttonDisabled} onClick={()=>this.onUsurpLeagueClicked()}>Usurp League</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class VenueDiscordManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='VenueDiscordManagementCard'>
                <VenueDiscordManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class VenueDiscordManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,

            formDiscordLink: '',

            formErrors: {},
            formValidated: false,
        }
    }

    componentDidMount() {
        this.updateVenue(this.props.venue, this.props.venue.id);
        let sub = this.restPubSub.subscribeNoRefresh('venue', this.props.venue.id, (d, k)=>this.updateVenue(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}, updating: false}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateVenue(venueData, key) {
        const updatedVenue = NerdHerderDataModelFactory('venue', venueData);
        this.setState({
            formDiscordLink: updatedVenue.discord_link || '',
            formHasBeenUpdated: false,
            updating: false,
        });
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, updating: true, formHasBeenUpdated: false});
            const patchData = {
                discord_link: this.state.formDiscordLink!=='' ? this.state.formDiscordLink.trimEnd() : null,
            }
            this.restPubSub.patch('venue', this.props.venue.id, patchData);
        }
    }

    handleDiscordLinkChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('discord_link', {...this.state.formErrors});
        if (value.length !== 0) {
            if (value.length < 7) {
                errorState = setErrorState('discord_link', {...this.state.formErrors}, "This URL isn't long enough to be valid.");
            } else if (!isValidHttpUrl(value)) {
                errorState = setErrorState('discord_link', {...this.state.formErrors}, "This URL doesn't look right. It should start with http or https.");
            }
        }
        this.setState({formDiscordLink: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    render() {
        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // if any item has an error or a required item is empty, then the submit is disabled
        let disableSubmitButton = false;
        if (hasFormErrors === true || this.state.formHasBeenUpdated === false || this.state.updating) {
            disableSubmitButton = true;
        }

        return(
            <NerdHerderStandardCardTemplate id="basics-card" title="Venue Discord" titleIcon="discord.png">
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className="form-outline mb-3">
                        <Form.Text muted>If your venue has a Discord server, you can add an invite to it here.</Form.Text>
                        <Form.Control id='discord_link' name='discord_link' type="text" disabled={this.state.updating} onChange={(e)=>this.handleDiscordLinkChange(e)} autoComplete='off' value={this.state.formDiscordLink} maxLength={500}/>
                        <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                    </Form.Group>

                    <Button type='submit' className="float-end" variant='primary' disabled={disableSubmitButton}>Update</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(ManageVenuePage);
