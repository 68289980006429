import React from 'react';
import { Navigate } from 'react-router-dom';
import { NerdHerderLoadingModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { delCookie, delLocal } from './utilities';

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            complete: false,
        }

        this.restApi = new NerdHerderRestApi();
        this.restApi.firebaseSignOut();
    }

    componentDidMount() {
        this.restApi.genericPostEndpointData('user-logout', null)
        .then(response => {
            console.debug('logged out successfully');
            setTimeout(() => this.logoutComplete(), 1000);
        }).catch(error => {
            console.warn('failed to log out');
            console.warn(error);
        })
    }

    logoutComplete() {
        delCookie('LoginToken');
        delLocal('LoginToken');
        delLocal('UserId');
        delLocal('FirebaseToken');
        this.setState({complete: true});
    }

    render() {
        if (this.state.complete) return(<Navigate to="/app/login" replace={true}/>)
        return (<NerdHerderLoadingModal title="Logging Out..." />);
    }
}

export default LogoutPage;
