import React from 'react';
import withRouter from './withRouter';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navigate } from 'react-router-dom';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { TableOfUsers } from './nerdherder-components/NerdHerderTableHelpers';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderAddGameModal, NerdHerderEditEventModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderEditPostModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderLeaguePostCard, CreateLeaguePost } from './nerdherder-components/NerdHerderMessageCards';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { UserListItem, TournamentListItem, GameListItem } from './nerdherder-components/NerdHerderListItems';
import { NerdHerderListUploadCard } from './nerdherder-components/NerdHerderListUploadCard';
import { NerdHerderRandomSelectorCard } from './nerdherder-components/NerdHerderRandomSelectorCard';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';

class EventPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            eventId: this.props.params.eventId,
            event: null,
            league: null,
            topic: null,
            tournaments: {},
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('event', this.state.eventId, (d, k) => {this.updateEvent(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateEvent(eventData, key) {
        let sub = null;
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: newEvent});

        sub = this.restPubSub.subscribe('league', newEvent.league_id, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);

        // update the tournaments
        for (const tournamentId of newEvent.tournament_ids) {
            sub = this.restPubSub.subscribe('tournament', tournamentId, (d, k) => {this.updateTournament(d, k)}, null, tournamentId);
            this.restPubSubPool.add(sub);
        }
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league});

        // update the topic
        let sub = this.restPubSub.subscribe('topic', league.topic_id, (d, k) => {this.updateTopic(d, k)});
        this.restPubSubPool.add(sub);
    }

    updateTopic(topicData, key) {
        const topic = NerdHerderDataModelFactory('topic', topicData);
        this.setState({topic: topic});
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal />);
        if (!this.state.event) return (<NerdHerderLoadingModal />);
        if (!this.state.league) return (<NerdHerderLoadingModal />);

        let localUserIsManager = this.state.event.isManager(this.state.localUser.id);
        let localUserIsPlayer = this.state.event.isPlayer(this.state.localUser.id);
        let localuserIsMember = localUserIsManager || localUserIsPlayer;

        // only allow managers to see this stuff if its in draft
        if (this.state.event.state === 'draft' && !localUserIsManager) {
            return(<NerdHerderErrorModal errorFeedback='This event is currently in draft and you are not an event organizer'/>);
        }

        // if the local user isn't a manager or member, they shouldn't be here - send them back to the league
        if (!localuserIsMember) return (<Navigate to={`/app/league/${this.state.league.id}`} replace={true}/>)

        const eventRandomSelectorCards = [];
        for (const randomSelector of this.state.event.random_selectors) {
            let selectorId = randomSelector.id;
            const card = <NerdHerderRandomSelectorCard key={selectorId} randomSelector={randomSelector} league={this.state.league} event={this.state.event} localUser={this.state.localUser}/>
            eventRandomSelectorCards.push(card);
        }

        const allCardsProps = {
            event: this.state.event,
            league: this.state.league,
            topic: this.state.topic,
            tournaments: this.state.tournaments,
            isPlayer: localUserIsPlayer,
            isManager: localUserIsManager,
            isLeagueMember: localuserIsMember,
            localUser: this.state.localUser,
        }

        // only show the stats card if the league is running or complete/archived
        let showStatsCard = false
        if (['running', 'complete', 'archived'].includes(this.state.league.state)) showStatsCard = true;

        return(
            <NerdHerderStandardPageTemplate  pageName='event' headerSelection='leagues' dropdownSelection={this.state.league.name}
                                             navPath={[{icon: 'flaticon-team', label: this.state.league.name, href: `/app/league/${this.state.league.id}`},
                                                       {icon: 'flaticon-calendar-to-organize-dates', label: this.state.event.name, href: `/app/event/${this.state.eventId}`}]}
                                             league={this.state.league} localUser={this.state.localUser}>
                <EventPageTopper {...allCardsProps}/>
                <EventTopPostCard {...allCardsProps}/>
                <EventVerificationCard {...allCardsProps}/>
                {eventRandomSelectorCards}
                {this.state.event.list_container_ids.length !== 0 &&
                <NerdHerderListUploadCard leagueId={this.state.league.id} eventId={this.state.eventId} {...allCardsProps}/>}
                <EventTournamentsCard {...allCardsProps}/>
                {showStatsCard &&
                <EventGameStatsCard {...allCardsProps}/>}
                {this.state.league.players_create_games &&
                <EventAddGameCard {...allCardsProps}/>}
                <EventGamesCard {...allCardsProps}/>
                <EventContactsCard {...allCardsProps}/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class EventPageTopper extends React.Component {
    constructor(props) {
        super(props);

        this.restPubSub = new NerdHerderRestPubSub();

        this.state = {
            showManageModal: false,
        }
    }

    onCloseManageModal() {
        this.setState({showManageModal: false});
        this.restPubSub.refresh('event', this.props.event.id);
        this.restPubSub.refresh('league', this.props.league.id);
    }

    render() {
        const currentTournamentsList = [];
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        for (const [tournamentId, tournamentData] of Object.entries(this.props.tournaments)) {
            // a tournament is 'current' if it is scheduled for today, or if it is in the in-progress state
            let isCurrent = false;
            if (tournamentData.state === 'in-progress') isCurrent = true;
            else if (tournamentData.date !== null) {
                const tournamentDate = new Date(tournamentData.date);
                if (currentDate.getUTCFullYear() === tournamentDate.getUTCFullYear() &&
                    currentDate.getUTCMonth() === tournamentDate.getUTCMonth() && 
                    currentDate.getUTCDate() === tournamentDate.getUTCDate()) isCurrent = true;
            }
            if (tournamentData.state === 'draft') isCurrent = false;
            if (isCurrent) {
                const listItem = <TournamentListItem key={tournamentId} tournament={tournamentData} league={this.props.league} localUser={this.props.localUser} slim={true}/>
                currentTournamentsList.push(listItem);
            }
        }

        return(
            <Card className="card shadow-sm" style={{borderRadius: '0.5rem'}}>
                {this.state.showManageModal &&
                <NerdHerderEditEventModal eventId={this.props.event.id} onCancel={()=>this.onCloseManageModal()} league={this.props.league} localUser={this.props.localUser}/>}
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <h2 id='event-name'>{this.props.event.name}</h2>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="my-1">
                        <Col xs={5}>
                            <Image className="img-fluid rounded text-center" src={this.props.league.getImageUrl()}/>
                        </Col>
                        <Col xs={7}>
                            <strong>{ this.props.event.summary }</strong>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="my-1">
                        <Col>
                            <h5>Schedule</h5>
                            <p id='schedule'>{this.props.event.getScheduleString()}</p>
                        </Col>
                    </Row>
                    {currentTournamentsList.length > 0 && 
                    <Row className="my-1">
                        <Col xs={12}>
                            <hr/>
                            <h5>Ongoing Tournaments</h5>
                            {currentTournamentsList}
                        </Col>
                    </Row>}
                    {this.props.isManager && 
                    <Row className="my-1">
                        <Col xs={12}>
                            <div className="d-grid gap-2">
                                <Button id='manage-event-button' variant="danger" onClick={()=>this.setState({showManageModal: true})}>Manage Minor Event</Button>
                            </div>
                        </Col>
                    </Row>}
                </Card.Body>
            </Card>
        );
    }
}


class EventTopPostCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventTopPostCard'>
                <EventTopPostCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventTopPostCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            showEditPostModal: false,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onCreatePost() {
        this.setState({showEditPostModal: true});
    }

    onCancelModal() {
        this.setState({showEditPostModal: false});
    }
    
    render() {
        let editPostModal = null;
        if (this.state.showEditPostModal) {
            editPostModal = <NerdHerderEditPostModal event={this.props.event} league={this.props.league} localUser={this.props.localUser}
                                                     setTopPost={true} onCancel={()=>this.onCancelModal()}/>
        }

        if (this.props.event.top_post_id === null) {
            if (this.props.event.isManager(this.props.localUser.id)) {
                return(
                    <NerdHerderStandardCardTemplate id="top-post-card" title='Top Post?' titleIcon="star.png">
                        {editPostModal}
                        <p>This minor event doesn't have a top post. You should create one!</p>
                        <Button id='create-top-post-button' className='float-end' variant='primary' onClick={()=>this.onCreatePost()}>Create</Button>
                    </NerdHerderStandardCardTemplate>
                );
            } else {
                // only managers can create a top post
                return(null);
            }
        } 
        else return(<NerdHerderLeaguePostCard messageId={this.props.event.top_post_id} localUser={this.props.localUser}/>);
    }
}

class EventCreatePostCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventCreatePostCard'>
                <EventCreatePostCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventCreatePostCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            showEditPostModal: false,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onCreatePost() {
        console.log('create post');
        this.restPubSub.refresh('league', this.props.league.id);
        this.restPubSub.refresh('event', this.props.event.id);
    }

    onCancelPost() {
        console.log('cancel post');
    }
    
    render() {
        return(
            <NerdHerderStandardCardTemplate id='create-post-card' title='Create Post' titleIcon='pencil-holder.png'>
                <CreateLeaguePost localUser={this.props.localUser}
                                  event={this.props.event}
                                  league={this.props.league}
                                  createPost={()=>this.onCreatePost()}
                                  cancelPost={()=>this.onCancelPost()}/>
            </NerdHerderStandardCardTemplate>
        );
    }
}


class EventContactsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventContactsCard'>
                <EventContactsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventContactsCardInner extends React.Component {

    render() {
        const listed = [];
        const managerListItems = [];
        const playerListItems = [];

        for (const eventUserId of this.props.event.manager_ids) {
            listed.push(eventUserId);
            const listItem = <UserListItem key={eventUserId} localUser={this.props.localUser} userId={eventUserId} showContact={true}/>
            managerListItems.push(listItem);
        }

        for (const eventUserId of this.props.event.player_ids) {
            if (listed.includes(eventUserId)) continue;
            listed.push(eventUserId);
            const listItem = <UserListItem key={eventUserId} localUser={this.props.localUser} userId={eventUserId} showContact={true}/>
            playerListItems.push(listItem);
        }

        return(
            <NerdHerderStandardCardTemplate id='player-information-card' title='Player Information' titleIcon='address-book.png'>
                <p>Remember to keep this information private!</p>
                {managerListItems.length > 0 &&
                <small className='text-muted'>Managers</small>}
                {managerListItems}

                {playerListItems.length > 0 &&
                <small className='text-muted'>Players</small>}
                {playerListItems}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class EventTournamentsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventTournamentsCard'>
                <EventTournamentsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventTournamentsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            tournaments: {},
        }
    }

    componentDidMount() {
        for (const tournamentId of this.props.event.tournament_ids) {
            let sub = this.restPubSub.subscribe('tournament', tournamentId, (d, k) => {this.updateTournament(d, k)}, null, tournamentId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
    }
    
    render() {
        const upcomingTournamentListItems = [];
        const pastTournamentListItems = [];
        const currentTournamentListItems = [];

        // get today
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (const tournamentData of Object.values(this.state.tournaments)) {
            if (tournamentData.state === 'draft') continue;

            const listItem = <TournamentListItem key={tournamentData.id} tournament={tournamentData} localUser={this.props.localUser} league={this.props.league} tournamentId={tournamentData.id} showSummary={true}/>
            let tournamentDate = null;

            // don't show draft leagues
            if (tournamentData.state === 'draft') continue;

            // if there is a date, get just the date part
            if (tournamentData.date) {
                tournamentDate = new Date(tournamentData.date);
                tournamentDate.setHours(0, 0, 0, 0);
            }

            // a tournament is 'current' if it is scheduled for today, or if it is in the in-progress state
            let isCurrent = false;
            if (tournamentData.state === 'in-progress') isCurrent = true;
            else if (tournamentDate !== null) {
                if (today.getUTCFullYear() === tournamentDate.getUTCFullYear() &&
                    today.getUTCMonth() === tournamentDate.getUTCMonth() && 
                    today.getUTCDate() === tournamentDate.getUTCDate()) isCurrent = true;
            }
            if (isCurrent) {
                currentTournamentListItems.push(listItem);
            }
            // the tournament is not current - could be upcoming or could be past...
            // if the tournament is completed, then it is past, and if the tournament is not yet started, then it is upcoming
            else {
                if (tournamentData.state === 'complete') {
                    pastTournamentListItems.push(listItem);
                } else {
                    upcomingTournamentListItems.push(listItem);
                }
            }
        }

        return(
            <NerdHerderStandardCardTemplate id='tournaments-card' title="Minor Event Tournaments" titleIcon='tournament.png'>
                {upcomingTournamentListItems.length > 0 &&
                <div>
                    <small className="text-muted">Upcoming Tournaments</small>
                    {upcomingTournamentListItems}
                </div>}

                {currentTournamentListItems.length > 0 &&
                <div>
                    <small className="text-muted">Current Tournaments</small>
                    {currentTournamentListItems}
                </div>}
                
                {pastTournamentListItems.length > 0 &&
                <div>
                    <small className="text-muted">Completed Tournaments</small>
                    {pastTournamentListItems}
                </div>}
                {upcomingTournamentListItems.length === 0 && currentTournamentListItems.length === 0 && pastTournamentListItems.length === 0 &&
                <p>This minor event has no tournaments planned.</p>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class EventGamesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventGamesCard'>
                <EventGamesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventGamesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // initially fill all the dicts with nulls - we'll load them when needed
        const tournaments = {};
        for (const tournamentId of this.props.event.tournament_ids) {
            tournaments[tournamentId] = null;
        }

        this.state = {
            updating: false,
            filterType: 'casual',
            filterMyGames: true,
            filterTournamentsSelector: 'any',
            tournaments: tournaments,
        }
    }

    componentDidMount() {
        for (const tournamentId of this.props.event.tournament_ids) {
            let sub = this.restPubSub.subscribe('tournament', tournamentId, (d, k) => {this.updateTournament(d, k)}, null, tournamentId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
    }

    updateGame(gameData, gameId) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        this.setState((state) => {
            return {games: {...state.games, [gameId]: newGame}}
        });
    }

    onSwitchFilter(value) {
        this.setState({filterType: value});
    }

    handleMyGamesCheckboxChange(event) {
        const value = event.target.checked;
        this.setState({filterMyGames: value});
    }

    handleTournamentsSelectorChange(event) {
        const value = event.target.value;
        this.setState({filterTournamentsSelector: value});
    }
    
    render() {

        const selectorOptions = [];
        if (this.state.filterType === 'tournaments') {
            const anyOption = <option value='any'>Any Tournament</option>
            selectorOptions.push(anyOption);
            for (const tournamentId of this.props.event.tournament_ids) {
                let title = null;
                if (this.state.tournaments[tournamentId] === null) {
                    title = `Tournament ${tournamentId}`;
                } else {
                    title = this.state.tournaments[tournamentId].name
                }
                const option = <option key={`t-${tournamentId}`} value={tournamentId}>{title}</option>
                selectorOptions.push(option);
            }
        }

        // get the list of (possible) games to display
        let listOfGameIds = [];
        switch (this.state.filterType) {
            case 'tournaments':
                if (this.state.filterTournamentsSelector === 'any') {
                    listOfGameIds = this.props.event.games.tournament;
                } else {
                    const tournamentId = parseInt(this.state.filterTournamentsSelector);
                    const tournament = this.state.tournaments[tournamentId];
                    if (tournament !== null) {
                        listOfGameIds = tournament.game_ids;
                    }
                }
                break;

            case 'casual':
                listOfGameIds = this.props.event.games.casual;
                break;

            default:
                console.error('got a bogus filterType');
        }

        // create the list of list items - if the user has filtered on their games only then make sure to leave those out
        const gameListItems = []
        for (const gameId of listOfGameIds) {
            let addGameToListItems = false;
            if (this.state.filterMyGames) {
                if (this.props.event.games.local_user_games.includes(gameId)) {
                    addGameToListItems = true;
                }
            } else {
                addGameToListItems = true;
            }
            if (addGameToListItems) {
                const newGameItem = <GameListItem key={`g-${gameId}`} gameId={gameId} league={this.props.league} localUser={this.props.localUser}/>
                gameListItems.unshift(newGameItem);
            }
        }

        return(
            <NerdHerderStandardCardTemplate id='games-card' title='Minor Event Games' titleIcon='dice.png'>
                <Form>
                    <Form.Group className="form-outline mb-2">
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='game-filter' type="radio" value={this.state.filterType} onChange={(v)=>this.onSwitchFilter(v)}>
                                <ToggleButton variant='outline-primary' id='toggle-casual' value={'casual'}>Minor Event Games</ToggleButton>
                                <ToggleButton variant='outline-primary' id='toggle-tournaments' value={'tournaments'}>Tournament Games</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className="form-outline mb-2">
                        <Form.Check type="checkbox" label="My Games Only" onChange={(e)=>this.handleMyGamesCheckboxChange(e)} checked={this.state.filterMyGames} disabled={this.state.updating}/>
                    </Form.Group>
                    {this.state.filterType === 'events' &&
                    <Form.Group className="form-outline mb-2">
                        <Form.Select aria-label="select event" onChange={(e)=>this.handleEventsSelectorChange(e)} value={this.state.filterEventsSelector} disabled={this.state.updating}>
                            {selectorOptions}
                        </Form.Select>
                    </Form.Group>}
                    {this.state.filterType === 'tournaments' &&
                    <Form.Group className="form-outline mb-2">
                        <Form.Select aria-label="select tournament" onChange={(e)=>this.handleTournamentsSelectorChange(e)} value={this.state.filterTournamentsSelector} disabled={this.state.updating}>
                            {selectorOptions}
                        </Form.Select>
                    </Form.Group>}
                    <hr/>
                    {gameListItems}
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class EventVerificationCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventVerificationCard'>
                <EventVerificationCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventVerificationCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            gameIds: [],
        }
    }

    componentDidMount() {
        this.triggerSearch();
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    triggerSearch() {
        const queryParams = {
            'user-id': this.props.localUser.id,
            'league-id': this.props.league.id,
            'event-id': this.props.event.id,
            'state': 'posted',
            'completion': 'completed',
            'needs-concur': true}
        this.restApi.genericGetEndpointData('game', null, queryParams)
        .then((response)=>{
            const gameIds = response.data.game_ids;
            for (const gameId of gameIds) {
                let sub = this.restPubSub.subscribe('game', gameId, (d, k)=>{this.updateGame(d, k)}, null, gameId);
                this.restPubSubPool.add(sub);
            }
        })
        .catch((error)=>{
            console.error(error);
        });
    }

    updateGame(gameData, gameId) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        const playersDict = newGame.getPlayersDict(this.props.league);
        if (playersDict.needsConcurIds.includes(this.props.localUser.id))
        this.setState((state)=>{
            if (this.state.gameIds.includes(gameId)) {
                return {gameIds: [...state.gameIds]};
            } else {
                return {gameIds: [...state.gameIds, gameId]}
            }
        });
    }
    
    render() {
        if (this.state.gameIds.length === 0) return(null);

        const gameListItems = [];
        for (const gameId of this.state.gameIds) {
            let addGameToListItems = true;
            if (addGameToListItems) {
                const newGameItem = <GameListItem key={`g-${gameId}`} gameId={gameId} league={this.props.league} localUser={this.props.localUser}/>
                gameListItems.unshift(newGameItem);
            }
        }

        return(
            <NerdHerderStandardCardTemplate id='review-games-card' title="Review Games" titleIcon='warning.png'>
                <p>These games require your review:</p>
                {gameListItems}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class EventAddGameCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventAddGameCard'>
                <EventAddGameCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventAddGameCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();

        this.state = {
            showAddGameModal: false,
        }
    }

    onAddGameCancel() {
        this.setState({showAddGameModal: false});
        this.restPubSub.refresh('league-alerts', this.props.league.id, 200);
        this.restPubSub.refresh('event', this.props.event.id, 400);
        this.restPubSub.refresh('header-alerts', null, 600);
    }
    
    render() {
        return(
            <NerdHerderStandardCardTemplate id="add-games-card">
                {this.state.showAddGameModal && <NerdHerderAddGameModal localUser={this.props.localUser}
                                                                        event={this.props.event}
                                                                        league={this.props.league}
                                                                        onCancel={()=>this.onAddGameCancel()} 
                                                                        onAddGame={()=>this.onAddGameCancel()}/>}
                <div className="d-grid gap-2">
                    <Button id='add-event-game-button' variant='primary' onClick={()=>this.setState({showAddGameModal: true})}>Add Game</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class EventGameStatsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventGameStatsCard'>
                <EventGameStatsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventGameStatsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            leagueStats: null,
        }
    }

    componentDidMount() {
        const sub = this.restPubSub.subscribe('league-game-stats', this.props.league.id, (d, k)=>{this.updateLeagueStats(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeagueStats(leagueStats, key) {
        this.setState({leagueStats: leagueStats});
    }

    sorter(player1, player2) {
        // sort by more wins, then less losses, then more ties (which really means more games)
        if (player1.wins > player2.wins) return(-1);
        else if (player2.wins > player1.wins) return(1);

        if (player1.losses < player2.losses) return(-1);
        else if (player2.losses > player1.losses) return(1);

        if (player1.ties > player2.ties) return(-1);
        else if (player2.ties > player1.ties) return(1);
        
        // ok, lower id wins
        if (player1.userId < player2.userId) return (-1);
        else return (1);
    }
    
    render() {
        if (this.props.event.games.casual.length === 0) return(null);
        if (this.state.leagueStats === null) return(<NerdHerderLoadingCard title="Minor Event Game Stats"/>);

        const playerIds = [];
        const sortablePlayerList = [];
        const statsColumnData = {};
        const casualPlayerStats = this.state.leagueStats.event[this.props.event.id];

        for (const [userId, stats] of Object.entries(casualPlayerStats)) {
            const playerId = parseInt(userId);
            sortablePlayerList.push({userId: playerId, wins: stats[0], ties: stats[1], losses: stats[2]});
        }

        // if there aren't enough players to make this card valuable, remove it
        if (sortablePlayerList.length < 2) return(null);

        // sort the players, then put that in a list so the table can display them in sorted order
        sortablePlayerList.sort(this.sorter);
        for (const playerDict of sortablePlayerList) {
            playerIds.push(playerDict.userId);
            statsColumnData[playerDict.userId] = `${playerDict.wins} / ${playerDict.ties} / ${playerDict.losses}`;
        }

        return(
            <NerdHerderStandardCardTemplate id="game-stats-card" title='Minor Event Game Stats' titleIcon="bar-chart.png">
                <TableOfUsers userIds={playerIds} headers={['Player', 'W / T / L']} rightColumnContent={statsColumnData} localUser={this.props.localUser}/>
            </NerdHerderStandardCardTemplate>
        );
    }
}

export default withRouter(EventPage);
