import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { DateTime } from 'luxon';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';
import { NerdHerderRestPubSub } from '../NerdHerder-RestPubSub';

export class NerdHerderCountdownTimer {
    constructor(controllerPrefix, roundId, onState, onTick, onComplete) {
        this.restPubSub = new NerdHerderRestPubSub();
        this.onState = onState;
        this.onTick = onTick;
        this.onComplete = onComplete;
        this.initialUpdate = true;
        this.isRunning = false;
        this.duration = 0;
        this.secondsRemaining = 0;
        this.startTimeUtc = null;
        this.selfTickTimeout = null;
        this.doOnCompleteOnce = true;
        this.roundId = roundId;

        this.restPubSub.subscribeToFirestore('tournament-round-timer', this.roundId, (d,k)=>this.updateCountdownTimer(d,k), null, this.roundId);
    }

    updateCountdownTimer(roundTimerData) {
        console.debug('NerdHerderCountdownTimer.updateCountdownTimer()');
        let prevIsRunning = this.isRunning;
        let prevSecondsRemaining = this.secondsRemaining;
        this.isRunning = roundTimerData.clock_running;
        this.duration = roundTimerData.duration;
        this.clockStarted = roundTimerData.clock_started;
        this.secondsRemaining = roundTimerData.remaining;
        // corner condition - if we get in here the first time and the clock is running, secondsRemaining won't be correct
        if (this.initialUpdate && this.isRunning && this.clockStarted) {
            // calculate secondsRemaining by subtracting time now and clock started
            let clockStartedString = this.clockStarted.toString();
            console.log(clockStartedString);
            let clockStartedDateTime = DateTime.fromSeconds(this.clockStarted.seconds, {zone: 'utc'});
            let timeNow = DateTime.utc();
            let duration = timeNow.diff(clockStartedDateTime);
            let differenceSeconds = Math.floor(duration.toMillis()/1000);
            this.secondsRemaining -= differenceSeconds;
        }

        if (this.secondsRemaining < 0) {
            this.secondsRemaining = 0;
            this.isRunning = false;
        }
        console.debug(`  ${this.duration}/${this.secondsRemaining} - run:${this.isRunning}`);
        // check for completion event
        if (this.onComplete && this.doOnCompleteOnce && prevIsRunning && !this.isRunning && prevSecondsRemaining > 0 && this.secondsRemaining === 0) {
            this.doOnCompleteOnce = false;
            this.onComplete();
        }
        // start the ticks if needed
        if (this.isRunning && this.selfTickTimeout === null) {
            this.selfTickTimeout = setTimeout(()=>this.selfTickTimeoutHandler(), 1000);
        }
        // stop the ticks if not needed
        if (!this.isRunning && this.selfTickTimeout !== null) {
            clearTimeout(this.selfTickTimeout);
            this.selfTickTimeout = null
        }

        if (this.onState) {
            this.onState(this.isRunning, this.secondsRemaining);
        }
        this.initialUpdate = false;
    }

    selfTickTimeoutHandler() {
        console.debug('NerdHerderCountdownTimer.selfTickTimeoutHandler()');
        this.secondsRemaining = this.secondsRemaining - 1;
        if (this.secondsRemaining <= 0) {
            this.secondsRemaining = 0;
            this.isRunning = false;
            clearTimeout(this.selfTickTimeout);
            this.selfTickTimeout = null
            if (this.onComplete && this.doOnCompleteOnce) {
                this.doOnCompleteOnce = false;
                this.onComplete();
            }
        } else if (this.isRunning) {
            this.selfTickTimeout = setTimeout(()=>this.selfTickTimeoutHandler(), 1000);
            if (this.onTick) this.onTick(this.secondsRemaining);
        }
    }

    start(secondsRemaining=null) {
        console.debug(`NerdHerderCountdownTimer.start(${secondsRemaining})`);
        let patchData = {controller: this.instanceControllerId, clock_running: true};
        if (secondsRemaining !== null) {
            patchData['remaining'] = secondsRemaining;
        }
        this.restPubSub.patch('tournament-round-timer', this.roundId, patchData);
    }

    stop(secondsRemaining=null) {
        console.debug(`NerdHerderCountdownTimer.stop(${secondsRemaining})`);
        clearTimeout(this.selfTickTimeout);
        this.selfTickTimeout = null
        let patchData = {controller: this.instanceControllerId, clock_running: false};
        if (secondsRemaining !== null) {
            patchData['remaining'] = secondsRemaining;
        }
        this.restPubSub.patch('tournament-round-timer', this.roundId, patchData);
    }

    reset(secondsRemaining=null) {
        console.debug(`NerdHerderCountdownTimer.reset(${secondsRemaining})`);
        clearTimeout(this.selfTickTimeout);
        this.selfTickTimeout = null
        if (secondsRemaining === null) secondsRemaining = this.duration;
        this.restPubSub.patch('tournament-round-timer', this.roundId, {controller: this.instanceControllerId, remaining: secondsRemaining, clock_running: false});
    }

    set(secondsRemaining) {
        console.debug(`NerdHerderCountdownTimer.set(${secondsRemaining})`);
        this.restPubSub.patch('tournament-round-timer', this.roundId, {controller: this.instanceControllerId, remaining: secondsRemaining});
    }

    setSecondsRemainingFromStartTime() {
        let dtUtcStart = DateTime.utc(this.startTimeUtc);
        let dtUtcNow = DateTime.utc();
        let elapsedTime = dtUtcNow.toMillis() - dtUtcStart.toMillis();
        if (elapsedTime > 0) {
            elapsedTime = parseInt(elapsedTime / 1000);
            this.secondsRemaining = this.duration - elapsedTime;
        }
        if (this.secondsRemaining < 0) {
            this.secondsRemaining = 0;
            if (this.onComplete && this.doOnCompleteOnce) {
                this.doOnCompleteOnce = false;
                this.onComplete();
            }
        }
    }
}

export class NerdHerderCountdown extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.roundId === 'undefined') console.error('missing props.roundId');

        this.countdownTimer = new NerdHerderCountdownTimer('manager', this.props.roundId, (r, sr)=>this.stateCallback(r, sr), (sr)=>this.tickCallback(sr), ()=>this.completeCallback());
        this.state = {
            running: false,
            secondsRemaining: 0
        }
    }

    stateCallback(running, secondsRemaining) {
        this.setState({running: running, secondsRemaining: secondsRemaining});
        if (this.props.onState) this.props.onState(running, secondsRemaining);
    }

    tickCallback(secondsRemaining) {
        this.setState({secondsRemaining: secondsRemaining});
        if (this.props.onTick) this.props.onTick(secondsRemaining);
    }

    completeCallback() {
        this.setState({running: false, secondsRemaining: 0});
        if (this.props.onComplete) this.props.onComplete();
    }

    generateOutputStringHMS() {
        let secondsRemaining = this.state.secondsRemaining;
        let outputString = '0:00:00';
        if (typeof secondsRemaining !== 'undefined' && secondsRemaining !== null && secondsRemaining !== 0) {
            if (this.state.run) {
                secondsRemaining = this.calcSecondsRemaining();
            }
            
            // if the clock isn't running, but it should be, start that up now
            if (this.state.run && secondsRemaining > 0) setTimeout(()=>this.timeoutHandler(), 1000);
            
            // here we format the time for display
            let hoursRemaining = parseInt(secondsRemaining / 3600);
            secondsRemaining -= hoursRemaining * 3600;
            let minutesRemaining = parseInt(secondsRemaining / 60);
            secondsRemaining -= minutesRemaining * 60;
            let minutesRemainingString = minutesRemaining.toString();
            let secondsRemainingString = secondsRemaining.toString();
            if (minutesRemaining < 10) minutesRemainingString = '0' + minutesRemainingString;
            if (secondsRemaining < 10) secondsRemainingString = '0' + secondsRemainingString;
            outputString = `${hoursRemaining}:${minutesRemainingString}:${secondsRemainingString}`;
        }
        return(outputString);
    }

    render() {
        const outputString = this.generateOutputStringHMS();
        return (
            <span>{outputString}</span>
        );
    }
}

export class FormControlCountdown extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.roundId === 'undefined') console.error('missing props.roundId');
        if (typeof this.props.duration === 'undefined') console.error('missing props.duration');

        this.countdownTimer = new NerdHerderCountdownTimer('manager', this.props.roundId, (r, sr)=>this.stateCallback(r, sr), (sr)=>this.tickCallback(sr), ()=>this.completeCallback());
        this.state = {
            running: false,
            secondsRemaining: 0,
        }
        this.allowUpdate = false;
        this.setSecondsRemainingTimeout = null;
    }

    onClickStart() {
        // if there is no timeout waiting in the background to set time remaining, just start the timer
        if (this.setSecondsRemainingTimeout === null) {
            this.countdownTimer.start();
        }
        // if there is a timeout, cancel it and we'll set seconds remaning in the start call
        else {
            clearTimeout(this.setSecondsRemainingTimeout);
            this.setSecondsRemainingTimeout = null;
            this.countdownTimer.start(this.state.secondsRemaining);
        } 
        if (this.props.onStart) this.props.onStart(this.state.secondsRemaining);
    }

    onClickStop() {
        this.countdownTimer.stop();
        if (this.props.onStop) this.props.onStop(this.state.secondsRemaining);
    }

    onClickReset() {
        this.countdownTimer.reset();
        this.setState({secondsRemaining: this.props.duration, valueUpdated: false});
        if (this.props.onReset) this.props.onReset();
    }

    onChangeValue(event) {
        const value = event.target.value * 60;
        // don't update the timer immediately, let the user enter more values if the need to
        clearTimeout(this.setSecondsRemainingTimeout);
        this.setSecondsRemainingTimeout = setTimeout(()=>{this.delayedSetRemaining()}, 3000);
        this.setState({secondsRemaining: value, valueUpdated: true});
    }

    setRemaining(secondsRemaining) {
        this.countdownTimer.set(secondsRemaining);
        this.setState({secondsRemaining: secondsRemaining});
    }

    delayedSetRemaining() {
        this.countdownTimer.set(this.state.secondsRemaining);
        clearTimeout(this.setSecondsRemainingTimeout);
        this.setSecondsRemainingTimeout = null;
    }

    getRemaining() {
        return(this.state.secondsRemaining);
    }

    stateCallback(running, secondsRemaining) {
        this.setState({running: running, secondsRemaining: secondsRemaining});
    }

    tickCallback(secondsRemaining) {
        this.setState({secondsRemaining: secondsRemaining});
        if (this.props.onTick) this.props.onTick(secondsRemaining);
    }

    completeCallback() {
        this.setState({secondsRemaining: 0});
        if (this.props.onComplete) this.props.onComplete();
    }

    generateRemainingStringHMS() {
        let secondsRemaining = this.state.secondsRemaining;
        let outputString = '0:00:00';
        if (typeof secondsRemaining !== 'undefined' && secondsRemaining !== null) {
            
            // here we format the time for display
            let hoursRemaining = parseInt(secondsRemaining / 3600);
            secondsRemaining -= hoursRemaining * 3600;
            let minutesRemaining = parseInt(secondsRemaining / 60);
            secondsRemaining -= minutesRemaining * 60;
            let minutesRemainingString = minutesRemaining.toString();
            let secondsRemainingString = secondsRemaining.toString();
            if (minutesRemaining < 10) minutesRemainingString = '0' + minutesRemainingString;
            if (secondsRemaining < 10) secondsRemainingString = '0' + secondsRemainingString;
            outputString = `${hoursRemaining}:${minutesRemainingString}:${secondsRemainingString}`;
        }
        return(outputString);
    }

    render() {
        // don't allow resetting or changing the input if the clock is going
        let disableControl = false;
        let disableResetButton = false;
        if (this.state.running) {
            disableControl = true;
            disableResetButton = true;
        }

        let minutesRemaining = parseInt(this.state.secondsRemaining / 60);
        let disableRun = false;
        if (minutesRemaining === 0 && this.state.secondsRemaining === 0) disableRun = true;
        let timeRemainingHMS = this.generateRemainingStringHMS();

        return(
            <div style={{position: 'relative'}}>
                <InputGroup >
                    <Form.Control type='number' disabled={disableControl || this.props.disabled} onChange={(e)=>this.onChangeValue(e)} value={minutesRemaining}/>
                    <Button size='sm' variant="warning" disabled={disableResetButton || this.props.disabled} onClick={()=>this.onClickReset()}>
                        <NerdHerderFontIcon icon='flaticon-clock-with-refresh-circular-arrow'/>
                    </Button>
                    {!this.state.running &&
                    <Button size='sm' variant="warning" disabled={this.props.disabled || disableRun} onClick={()=>this.onClickStart()}>
                        <NerdHerderFontIcon icon='flaticon-play-arrow'/>
                    </Button>}
                    {this.state.running &&
                    <Button size='sm' variant="warning" disabled={this.props.disabled} onClick={()=>this.onClickStop()}>
                        <NerdHerderFontIcon icon='flaticon-pause-two-lines'/>
                    </Button>}
                </InputGroup>
                <div className='text-muted px-1' style={{position: 'absolute', top: '-12px', right: '80px',
                            border: '1px solid #ced4da', borderRadius: '5px', fontSize: '14px',
                            backgroundColor: 'white'}}>
                    {timeRemainingHMS}
                </div>
            </div>
        );
    }
}