import { doc, onSnapshot } from 'firebase/firestore';
import { NerdHerderRestApi } from './NerdHerder-RestApi.js';

export class NerdHerderRestPubSub {
    constructor() {
        if (!NerdHerderRestPubSub.instance) {
            NerdHerderRestPubSub.instance = this;
            this.restApi = new NerdHerderRestApi(); 
            this.globalErrorHandler = null;
            this.apiDict = {};
            this.apiDataPendingDict = {};
            this.apiLastDataDict = {};
            this.firestoreUnsubscribes = [];
            this.tagSourceApiList = [
                'self', 'user', 'user-summary', 'league', 'league-summary', 'event', 'event-summary', 'tournament', 'tournament-summary', 'game', 'game-summary', 'topic',
                'users', 'leagues', 'events', 'tournaments', 'games', 'topics'
            ]
        }
        else {
            return NerdHerderRestPubSub.instance;
        }
    }

    getInstance() {
        return NerdHerderRestPubSub.instance;
    }

    getRestApi() {
        return this.restApi;
    }

    #distributeResponseData(indexedApiName, response) {
        if (indexedApiName in this.apiDict) {
            const callbackList = this.apiDict[indexedApiName];
            
            // call back each subscriber with the data and key
            for (const subscriber of callbackList) {
                if (subscriber.dataCallback !== null) {
                    try {
                        subscriber.dataCallback(response.data, subscriber.key);
                    } catch (error) {
                        let indexedName = this.restApi.generateIndexedApiName(subscriber.apiName, subscriber.index);
                        console.error(`RestPubSub failed to distribute response to data callback for ${indexedName}`);
                        console.error(error);
                    }
                }
            }
        }
    }

    #distributeLastResponseData(indexedApiName, subscriber) {
        if (indexedApiName in this.apiDict && indexedApiName in this.apiLastDataDict) {
            const lastResponseData = this.apiLastDataDict[indexedApiName];
            // call back the subscriber with the data and key
            if (subscriber.dataCallback !== null) {
                try {
                    subscriber.dataCallback(lastResponseData.data, subscriber.key);
                } catch (error) {
                    let indexedName = this.restApi.generateIndexedApiName(subscriber.apiName, subscriber.index);
                    console.error(`RestPubSub failed to distribute cached response to data callback for ${indexedName}`);
                    console.error(error);
                }
            }
        }
    }

    #distributeError(indexedApiName, error) {
        if (indexedApiName in this.apiDict) {
            const callbackList = this.apiDict[indexedApiName];
            let caughtError = false;
            
            // call back each subscriber with the data and key
            for (const subscriber of callbackList) {
                if (subscriber.errorCallback !== null) {
                    caughtError = true;
                    subscriber.errorCallback(error, subscriber.key);
                }
            }

            // if there is a global error handler, call that with the data if no other handler caught it
            if (!caughtError && this.globalErrorHandler) {
                this.globalErrorHandler(error, indexedApiName);
            }
        }
    }

    get(apiName, index=null) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
        this.apiDataPendingDict[indexedApiName] = true;
        this.restApi.genericGetEndpointData(apiName, index)
        .then(response => {
            console.debug(`NerdHerderPubSub - GET success: ${indexedApiName}`);
            if (this.tagSourceApiList.includes(apiName)) {
                response.data.apiSource = 'rest';
            }
            this.apiLastDataDict[indexedApiName] = response;
            this.#distributeResponseData(indexedApiName, response);
            this.apiDataPendingDict[indexedApiName] = false;
        })
        .catch(error => {
            console.debug(`NerdHerderPubSub - GET failure: ${indexedApiName}`);
            console.debug(error);
            this.#distributeError(indexedApiName, error);
            this.apiDataPendingDict[indexedApiName] = false;
        });
    }

    put(apiName, index=null, data) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
        this.apiDataPendingDict[indexedApiName] = true;
        this.restApi.genericPutEndpointData(apiName, index, data)
        .then(response => {
            console.debug(`NerdHerderPubSub - PUT success: ${indexedApiName}`);
            if (this.tagSourceApiList.includes(apiName)) {
                response.data.apiSource = 'rest';
            }
            this.apiLastDataDict[indexedApiName] = response;
            this.#distributeResponseData(indexedApiName, response);
            this.apiDataPendingDict[indexedApiName] = false;
        })
        .catch(error => {
            console.debug(`NerdHerderPubSub - PUT failure: ${indexedApiName}`);
            console.debug(error);
            this.#distributeError(indexedApiName, error);
            this.apiDataPendingDict[indexedApiName] = false;
        });
    }

    patch(apiName, index=null, data) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
        this.apiDataPendingDict[indexedApiName] = true;
        this.restApi.genericPatchEndpointData(apiName, index, data)
        .then(response => {
            console.debug(`NerdHerderPubSub - PATCH success: ${indexedApiName}`);
            if (this.tagSourceApiList.includes(apiName)) {
                response.data.apiSource = 'rest';
            }
            this.apiLastDataDict[indexedApiName] = response;
            this.#distributeResponseData(indexedApiName, response);
            this.apiDataPendingDict[indexedApiName] = false;
        })
        .catch(error => {
            console.debug(`NerdHerderPubSub - PATCH failure: ${indexedApiName}`);
            console.debug(error);
            this.#distributeError(indexedApiName, error);
            this.apiDataPendingDict[indexedApiName] = false;
        });
    }

    post(apiName, index=null, data) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
        this.apiDataPendingDict[indexedApiName] = true;
        this.restApi.genericPostEndpointData(apiName, index, data)
        .then(response => {
            console.debug(`NerdHerderPubSub - POST success: ${indexedApiName}`);
            if (this.tagSourceApiList.includes(apiName)) {
                response.data.apiSource = 'rest';
            }
            this.apiLastDataDict[indexedApiName] = response;
            this.#distributeResponseData(indexedApiName, response);
            this.apiDataPendingDict[indexedApiName] = false;
        })
        .catch(error => {
            console.debug(`NerdHerderPubSub - POST failure: ${indexedApiName}`);
            console.debug(error);
            this.#distributeError(indexedApiName, error);
            this.apiDataPendingDict[indexedApiName] = false;
        });
    }

    delete(apiName, index=null) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
        this.restApi.genericDeleteEndpointData(apiName, index)
        .then(response => {
            console.debug(`NerdHerderPubSub - DELETE success: ${indexedApiName}`);
            this.apiLastDataDict[indexedApiName] = null;
            this.#distributeResponseData(indexedApiName, response);
            this.apiDataPendingDict[indexedApiName] = false;
        })
        .catch(error => {
            console.debug(`NerdHerderPubSub - DELETE failure: ${indexedApiName}`);
            console.debug(error);
            this.#distributeError(indexedApiName, error);
            this.apiDataPendingDict[indexedApiName] = false;
        });
    }

    refresh(apiName, index=null, delay=0) {
        // if nobody is subscribed to the apiName, don't refresh because nobody is listening
        let indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
        if (!this.apiDict.hasOwnProperty(indexedApiName)) {
            console.debug('bypassing refresh - no subscribers');
            return;
        }

        if (delay === 0) {
            this.get(apiName, index);
        } else {
            return setTimeout(()=>this.refresh(apiName, index, 0), delay);
        }
    }

    cancelRefresh(timeoutValue) {
        clearTimeout(timeoutValue);
    }

    postAndSubscribe(apiName, index, data, dataCallback, errorCallback=null, key=null) {
        // if index is a string, it will be the name of the object property that becomes the index
        // if it's an integer, it is the index
        // if it's null, there is no index
        let indexedApiName = null;
        let afterPostPropertyName = null;
        let subscriber = null;
        if (index === null || Number.isInteger(index)) {
            indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
            this.apiDataPendingDict[indexedApiName] = true;
            subscriber = this.subscribeSilently(apiName, index, dataCallback, errorCallback, key);
        } else {
            afterPostPropertyName = index;
            index = null;
            subscriber = {'apiName': apiName, 'index': index, 'dataCallback': dataCallback, 'errorCallback': errorCallback, 'key': index, 'type': 'REST'};
        }

        this.restApi.genericPostEndpointData(apiName, index, data)
        .then(response => {
            // if the POST is successful & a property name is defined, need to silently subscribe before distributing the result
            if (afterPostPropertyName !== null) {
                let newIndex = response.data[afterPostPropertyName]
                indexedApiName = this.restApi.generateIndexedApiName(apiName, newIndex);
                let newSubscriber = this.subscribeSilently(apiName, newIndex, dataCallback, errorCallback, key);
                // update the index between the OG and new subscriber
                subscriber.index = newSubscriber.index;
            }
            console.debug(`NerdHerderPubSub - POST & Subscribe success: ${indexedApiName}`);
            this.apiLastDataDict[indexedApiName] = response;
            this.#distributeResponseData(indexedApiName, response);
            this.apiDataPendingDict[indexedApiName] = false;
        })
        .catch(error => {
            console.debug(`NerdHerderPubSub - POST & Subscribe failure: ${indexedApiName}`);
            console.debug(error);
            this.#distributeError(indexedApiName, error);
            this.apiDataPendingDict[indexedApiName] = false;
        });

        return subscriber;
    }

    subscribe(apiName, index, dataCallback, errorCallback=null, key=null) {
        return this.#subscribeCommon(apiName, index, dataCallback, errorCallback, key, true, false);
    }

    subscribeNoRefresh(apiName, index, dataCallback, errorCallback=null, key=null) {
        return this.#subscribeCommon(apiName, index, dataCallback, errorCallback, key, false, false);
    }

    subscribeSilently(apiName, index, dataCallback, errorCallback=null, key=null) {
        return this.#subscribeCommon(apiName, index, dataCallback, errorCallback, key, true, true);
    }

    subscribeToFirestore(apiName, index, dataCallback, errorCallback=null, key=null) {
        const fullApiName = `${this.restApi.firestorePrefix}${apiName}`;
        console.debug(`NerdHerderRestPubSub - subscribe(firestore): ${fullApiName}/${index} (key=${key})`);
        let subscriber = {'apiName': apiName, 'index': index, 'dataCallback': dataCallback, 'errorCallback': errorCallback, 'key': key, 'type': 'firestore'};        
        subscriber['unsubscribe'] = onSnapshot(doc(this.restApi.firestoreDb, fullApiName, index.toString()), (doc) => {
            if (doc.exists()) {
                const docData = doc.data();
                if (this.tagSourceApiList.includes(apiName)) {
                    docData.apiSource = 'firestore';
                }
                dataCallback(docData, key);
            } else {
                dataCallback('DELETED', key);
            }
        }, (error) => {
            if (errorCallback) {
                errorCallback(error, key);
            } else if (this.globalErrorHandler) {
                this.globalErrorHandler(error, `${fullApiName}/${index}`);
            }
        });
        this.firestoreUnsubscribes.push(subscriber['unsubscribe']);
    }

    subscribeGlobalErrorHandler(errorCallback) {
        this.globalErrorHandler = errorCallback;
    }

    #subscribeCommon(apiName, index, dataCallback, errorCallback=null, key=null, doRefresh=true, silent=false) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);
        let subscriber = null;
        let debugApiName = 'subscribe';
        if (!doRefresh) {
            debugApiName = 'subscribe(no-refresh)'
        }
        if (silent) {
            debugApiName = 'subscribe(silent)'
        }

        // see if this sub already exists - if so just need to add this subscriber
        if (indexedApiName in this.apiDict) {
            let isNew = true;
            const callbackList = this.apiDict[indexedApiName];
            
            // check for duplicates - update error callback if it already exists
            for (const existingSubscriber of callbackList) {
                if (dataCallback === existingSubscriber.dataCallback) {
                    if (key === null || key === existingSubscriber.key) {
                        isNew = false;
                        existingSubscriber.errorCallback = errorCallback;
                        subscriber = existingSubscriber;
                        break;
                    }
                }
            }

            // if it's new add it
            if (isNew) {
                console.debug(`NerdHerderRestPubSub - ${debugApiName}: ${indexedApiName} (key=${key})`);
                subscriber = {'apiName': apiName, 'index': index, 'dataCallback': dataCallback, 'errorCallback': errorCallback, 'key': key, 'type': 'REST'};
                callbackList.push(subscriber);
            } else {
                console.debug(`NerdHerderRestPubSub - ${debugApiName}: ${indexedApiName} (key=${key}) duplicate`);
            }

        } else {
            // create a sub & subscriber then add the subscriber to the sub
            console.debug(`NerdHerderRestPubSub - create new sub and subscribe: ${indexedApiName} (key=${key})`);
            subscriber = {'apiName': apiName, 'index': index, 'dataCallback': dataCallback, 'errorCallback': errorCallback, 'key': key, 'type': 'REST'};
            this.apiDict[indexedApiName] = [];
            this.apiLastDataDict[indexedApiName] = null;
            this.apiDataPendingDict[indexedApiName] = false;
            this.apiDict[indexedApiName].push(subscriber);
        }

        // from here down we're managing the get/refresh stuff - so if silent just get out
        if (silent) return subscriber;

        // actually do the get if there isn't data pending already
        if (doRefresh && !this.apiDataPendingDict[indexedApiName]) {
            this.get(apiName, index);
        }

        // if this sub doesn't want to do a refresh...
        if (!doRefresh) {
            // ...if there's data incoming, just wait for that
            if (this.apiDataPendingDict[indexedApiName]) {
                console.debug(`NerdHerderRestPubSub - new sub ${indexedApiName} (key=${key}) will wait for pending data`);
            }
            // ...if there's not data incoming, but there is some stale data after this function returns just deliver that
            else if (this.apiLastDataDict[indexedApiName] !== null) {
                setTimeout(()=>this.#distributeLastResponseData(indexedApiName, subscriber), 10);
            }
            // ...there is no stale data, do a get
            else {
                this.get(apiName, index);
            }
        }

        return subscriber;
    }

    unsubscribe(apiName, index, dataCallback, key=null) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);

        // do nothing if the apiname doesn't exist
        if (indexedApiName in this.apiDict) {
            const callbackList = this.apiDict[indexedApiName];
            
            // find it, remove it
            let i = 0;
            while (i < callbackList.length) {
                if (dataCallback === callbackList[i].dataCallback) {
                    // if looking for a specific key, then this is it - remove and we're done
                    if (key === callbackList[i].key) {
                        console.debug(`NerdHerderRestPubSub - unsubscribe: ${indexedApiName} (key=${key}) remove entry: ${i}`);
                        callbackList.splice(i, 1);
                        break;
                    }
                    // if removing all keys, there might be more
                    if (key === null) {
                        console.debug(`NerdHerderRestPubSub - unsubscribe: ${indexedApiName} (key=null) remove entry: ${i}`);
                        callbackList.splice(i, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }
            }
        }
    }

    unsubscribeSubscriber(subscriber) {
        if (subscriber) {
            if (subscriber.type === 'REST') {
                this.unsubscribe(subscriber.apiName, subscriber.index, subscriber.dataCallback, subscriber.key);
            } else if (subscriber.type === 'firestore') {
                subscriber.unsubscribe();
            } else {
                console.warn('detected subscriber of unknown type - attempting to unsubscribe anyway');
                this.unsubscribe(subscriber.apiName, subscriber.index, subscriber.dataCallback, subscriber.key);
            }
        }
    }

    unsubscribeAll(apiName, index) {
        const indexedApiName = this.restApi.generateIndexedApiName(apiName, index);

        // do nothing if the apiname doesn't exist
        if (indexedApiName in this.apiDict) {
            console.debug(`NerdHerderRestPubSub - unsubscribeAll: ${indexedApiName}`);
            this.apiDict[indexedApiName] = [];
            this.apiDataPendingDict[indexedApiName] = false;
        }
    }

    clear() {
        console.debug(`NerdHerderRestPubSub - clear`);
        for (const unsub of this.firestoreUnsubscribes) {
            unsub();
        }
        this.globalErrorHandler = null;
        this.apiDict = {};
        this.apiDataPendingDict = {};
        this.apiLastDataDict = {};
    }
}

export class NerdHerderRestPubSubPool {
    constructor() {
        this.pubsub = new NerdHerderRestPubSub();  
        this.pool = [];
    }

    add(subscription) {
        this.pool.push(subscription);
    }

    remove(subscription) {
        for (let i=0; i<this.pool.length; i++) {
            if (this.pool[i] == subscription) {
                this.pool.splice(i, 1);
            }
        }
    }

    unsubscribe() {
        let subscription = this.pool.pop();
        while (subscription) {
            this.pubsub.unsubscribeSubscriber(subscription);
            subscription = this.pool.pop();
        }
    }
}