import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';

export class CardErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.cardTypeName === 'undefined') console.error('missing props.cardTypeName to identify the UI element that had the failure');

        this.errorInfo = null;

        this.state = {
            hasError: false,
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        if (!this.state.error) {
            this.setState({error: error});
            this.errorInfo = errorInfo;
        }
    }

    render() {
        if (this.state.hasError) {
            // fallback UI
            return (
                <Row className="m-1 justify-content-evenly">
                    <Col xs={12} className={`g-0 my-${this.props.verticalMargin || 1}`}>
                        <Card className="shadow-sm" style={{borderRadius: '0.5rem'}}>
                            <Card.Title className="mx-1 mt-1 mb-0">
                                {`${this.props.cardTypeName} Render Error`}
                            </Card.Title>
                            <Card.Body className="p-2 text-left">
                                <p>While rendering the UI an error was encountered.</p><br/>
                                <p>{`Error: ${this.state.error}`}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            );
        }

        return this.props.children;
    }
}

export class ListItemErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.listItemTypeName === 'undefined') console.error('missing props.listItemTypeName to identify the UI element that had the failure');

        this.errorInfo = null;

        this.state = {
            hasError: false, 
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        if (!this.state.error) {
            this.setState({error: error});
            this.errorInfo = errorInfo;
        }
    }

    render() {
        if (this.state.hasError) {
            // fallback UI
            return (
                <Row className="my-1 align-items-center">
                    <Col xs={12}>
                        <div className={`list-group-item rounded align-middle`}>
                            <b>{`${this.props.listItemTypeName} Render Error`}</b>
                            {this.props.listItemId &&
                            <br/>}
                            {this.props.listItemId &&
                            <small>{`Item ID: ${this.props.listItemId}`}</small>}
                            {this.state.error &&
                            <br/>}
                            {this.state.error &&
                            <small>{`${this.state.error}`}</small>}
                        </div>
                    </Col>
                </Row>
            );
        }

        return this.props.children;
    }
}

export class ModalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.modalTypeName === 'undefined') console.error('missing props.modalTypeName to identify the UI element that had the failure');

        this.errorInfo = null;

        this.state = {
            hasError: false, 
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        if (!this.state.error) {
            this.setState({error: error});
            this.errorInfo = errorInfo;
        }
    }

    render() {
        if (this.state.hasError) {
            // fallback UI
            return (
                <div onClick={(e)=>e.stopPropagation()}>
                    <Modal show={this.props.show || true} onHide={()=>{this.props.onCancel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>{`${this.props.modalTypeName} Render Error`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='text-center'>
                            {this.state.error &&
                            <p>{`${this.state.error}`}</p>}
                        </Modal.Body>
                    </Modal>
                </div>
            );
        }

        return this.props.children;
    }
}
