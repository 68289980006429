import React from 'react';
import Button from 'react-bootstrap/Button';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './NerdHerderStandardCardTemplate';
import { NerdHerderUserProfileModal } from './NerdHerderModals';

export class NerdHerderContactRequestCard extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.localUser === null) console.error('localUser not included in props');

        this.state = {
            showUserProfileModal: false,
            contactUserId: this.props.userId,
            contactUser: null,
            usersContacts: null,
            updating: false,
            disabled: false,
            userFeedback: null,
            errorFeedback: null,
        };

        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool(); 
    }

    componentDidMount() {
        console.debug('NerdHerderContactRequestCard mount');
        let sub = this.restPubSub.subscribe('user', this.state.contactUserId, (d, k) => {this.updateContactUser(d, k)}, (e, k) => {this.restError(e, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('self-contact', this.state.contactUserId, (d, k) => {this.updateUsersContacts(d, k)}, (e, k) => {this.restError(e, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        console.debug('NerdHerderContactRequestCard unmount');
        this.restPubSubPool.unsubscribe();
    }

    restError(error, key) {
        this.setState({errorFeedback: 'An error was encountered'})
    }

    updateContactUser(userData, key) {
        console.debug('NerdHerderContactRequestCard updateContactUser()');
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({contactUser: user})
    }

    updateUsersContacts(usersContactsData, key) {
        console.debug('NerdHerderContactRequestCard updateUsersContacts()');
        this.setState({usersContacts: usersContactsData})

        // if there's no longer a pending request, disable the buttons
        if (usersContactsData.state !== 'requested') this.setState({disabled: true});
        if (usersContactsData.state === 'friends') this.setState({userFeedback: 'Contact accepted!'});
    }

    onCancelModal() {
        this.setState({showUserProfileModal: false});
    }

    onAcceptClick() {
        this.setState({updating: true});
        this.restPubSub.patch('self-contact', this.state.contactUserId, {state: 'friends'});
    }

    onRejectClick() {
        this.setState({userFeedback: 'Contact rejected!'});
        this.restPubSub.delete('self-contact', this.state.contactUserId);
    }

    render() {
        if (this.state.contactUser === null) return(<NerdHerderLoadingCard title="Contact Request..."/>);
        if (this.state.usersContacts === null) return(<NerdHerderLoadingCard title="Contact Request..."/>);

        let buttonsDisabled = false;
        if (this.state.updating || this.state.disabled) buttonsDisabled = true;

        return (
            <NerdHerderStandardCardTemplate
                user={this.state.contactUser}
                localUser={this.props.localUser}
                userTitle={true}
                userIcon={true}
                icon={true}
                errorFeedback={this.state.errorFeedback}
                userFeedback={this.state.userFeedback}
                updating={this.state.updating}
                onClick={()=>this.setState({showUserProfileModal: true})}>
                {this.state.showUserProfileModal &&
                <NerdHerderUserProfileModal userId={this.props.userId} localUser={this.props.localUser} onCancel={()=>this.onCancelModal()}/>}
                <p>{this.state.contactUser.username} ({this.state.contactUser.short_name}) would like to add you to their contacts.</p>
                <div className="float-end">
                    <Button size="sm" variant="secondary" onClick={()=>this.onRejectClick()} disabled={buttonsDisabled}>Reject</Button>{' '}
                    <Button size="sm" variant="primary" onClick={()=>this.onAcceptClick()} disabled={buttonsDisabled}>Accept</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}
