import React from 'react';
import { DateTime } from 'luxon';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Navigate } from 'react-router-dom';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderJoinModelRestApi } from '../NerdHerder-JoinModelRestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './NerdHerderStandardCardTemplate';
import { NerdHerderScheduleGameModal } from './NerdHerderModals';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';


export class NerdHerderScheduledGameCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='NerdHerderScheduledGameCard'>
                <NerdHerderScheduledGameCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class NerdHerderScheduledGameCardInner extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.localUser) console.error('missing props.localUser');
        if (!this.props.leagueId) console.error('missing props.leagueId');
        if (!this.props.gameId) console.error('missing props.gameId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            navigateTo: null,
            fromUser: null,
            league: null,
            game: null,
            usersGames: null,
            updating: false,
            hasError: false,
            userFeedback: null,
            showProposeScheduleModal: false,
            userRescheduled: false,
        };
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('user', this.props.fromUserId, (d, k)=>{this.updateFromUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('league', this.props.leagueId, (d, k)=>{this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('game', this.props.gameId, (d, k)=>{this.updateGame(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateFromUser(userData, key) {
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({fromUser: user});
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague});
    }

    updateGame(gameData, key) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        this.setState({game: newGame});
    }

    // this is from the user clicking the accept button
    onAccept() {
        this.setState({accepted: true});
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-game', 'user-game',
                                                                'user-id', this.props.localUser.id,
                                                                'game-id', this.state.game.id);
        joinModelRestApi.patch({concur_with_schedule: true})
        .then((response)=>{
            this.restPubSub.refresh('game', this.props.gameId);
            this.setState({updating: false});
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    // this is from the user clicking the accept button in the proposed schedule dialog
    onAcceptSchedule(datetime) {
        this.restPubSub.patch('game', this.props.gameId, {proposed_datetime: datetime});
        this.setState({showProposeScheduleModal: false, userRescheduled: true});
    }

    onReschedule() {
        if (this.props.onRescheduleClick) {
            this.props.onRescheduleClick();
        } else {
            this.setState({showProposeScheduleModal: true});
        }
    }

    errorUsersLeagues(error, apiName) {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) return(null);
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);
        if (this.state.league === null) return(<NerdHerderLoadingCard title="Game Schedule..."/>);
        if (this.state.game === null) return(<NerdHerderLoadingCard title="Game Schedule..."/>);
        if (this.state.fromUser === null) return(<NerdHerderLoadingCard title="Game Schedule..."/>);

        let proposedDateTime = null;
        if (this.state.game.proposed_datetime) {
            let dateTime = DateTime.now();
            let browserTimezone = this.props.localUser.timezone;
            if (dateTime.isValid) browserTimezone = dateTime.zoneName;
            proposedDateTime = DateTime.fromISO(this.state.game.proposed_datetime, {zone: this.state.league.timezone});
            if (browserTimezone !== this.state.league.timezone) proposedDateTime = proposedDateTime.setZone(browserTimezone);
            proposedDateTime = `${proposedDateTime.toLocaleString(DateTime.DATE_HUGE)} at ${proposedDateTime.toLocaleString(DateTime.TIME_SIMPLE)} ${proposedDateTime.toFormat('ZZZZ')}`;
        } else {
            return(null);
        }

        let alertJsx = null;
        let disableAcceptButton = false;
        let disableRescheduleButton = false;
        let thisUserIsPlayer = false;
        let allPlayersAccepted = true;
        for (const player of this.state.game.players) {
            if (player.user_id === this.props.localUser.id) {
                thisUserIsPlayer = true;
                if (player.concur_with_schedule) {
                    if (this.state.userRescheduled) {
                        alertJsx = <Alert variant='primary'>You send a proposal to reschedule</Alert>
                    } else {
                        alertJsx = <Alert variant='primary'>You have accepted the proposal</Alert>
                    }
                    disableAcceptButton = true;
                }
            }
            if (!player.concur_with_schedule) allPlayersAccepted = false;
        }

        if (!thisUserIsPlayer) {
            alertJsx = <Alert variant='warning'>You are not a player in this game</Alert>
            disableAcceptButton = true;
            disableRescheduleButton = true;
        }

        if (allPlayersAccepted) {
            alertJsx = <Alert variant='primary'>All players have accepted the proposal</Alert>
            disableAcceptButton = true;
        }

        if (this.state.game.state === 'posted' && this.state.game.completion === 'completed') {
            alertJsx = <Alert variant='warning'>This game is complete and cannot be rescheduled</Alert>
            disableAcceptButton = true;
            disableRescheduleButton = true;
        }

        return (
            <NerdHerderStandardCardTemplate
                league={this.state.league}
                localUser={this.props.localUser}
                title='Game Schedule'
                titleIcon='calendar.png'
                userFeedback={this.state.userFeedback}
                updating={this.state.updating}>
                {alertJsx}
                {this.state.showProposeScheduleModal &&
                <NerdHerderScheduleGameModal
                    localUser={this.props.localUser}
                    league={this.state.league}
                    game={this.state.game}
                    playerIdList={this.state.game.player_ids}
                    onAccept={(dt)=>this.onAcceptSchedule(dt)}
                    onCancel={()=>this.setState({showProposeScheduleModal: false})}/>}
                <p>{this.state.fromUser.username} has proposed a date & time for your upcoming game in <a href={`/app/league/${this.props.leagueId}?tab=game&focus=schedule-games-card`}>{this.state.league.name}</a></p>
                <p><b>{proposedDateTime}</b></p>
                <div className="text-end">
                    <Button variant='secondary' disabled={this.state.updating || disableRescheduleButton} onClick={()=>this.onReschedule()}>Reschedule</Button>
                    {' '}
                    <Button variant='primary' disabled={this.state.updating || disableAcceptButton} onClick={()=>this.onAccept()}>Accept</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}
