import React from 'react';
import { Navigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Placeholder from 'react-bootstrap/Placeholder';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import he from 'he';
import { DateTime, SystemZone } from 'luxon';
import { InView } from 'react-intersection-observer';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';
import { NerdHerderDropzoneImageUploader, NerdHerderSingleImageUpload } from './NerdHerderDropzone';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { Truncate } from './NerdHerderTruncate';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './NerdHerderStandardCardTemplate';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';
import { NerdHerderUserProfileModal, NerdHerderEditPostModal, NerdHerderEditMessageModal, NerdHerderConfirmModal } from './NerdHerderModals';
import { FormattedText, FormTextInputLimit, LinkifyText } from './NerdHerderFormHelpers';
import { OpenGraphCard } from './NerdHerderOpenGraph';
import { getStorageFilePublicUrl, getLocalStaticFileUrl, capitalizeFirstLetter, getUrlFromText } from '../utilities';

export class NerdHerderMessageCard extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.messageId === 'undefined') console.error('missing props.messageId');

        this.state = {
            showUserProfileModal: false,
            navigateTo: null,
            messageId: this.props.messageId,
            message: null,
            parentMessageId: null,
            parentMessage: null,
            fromUserId: null,
            fromUser: null,
            updating: false,
            disabled: false,
            userFeedback: null,
            errorFeedback: null,
        };

        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool(); 
    }

    componentDidMount() {
        console.debug('NerdHerderMessageCard mount');
        let sub = this.restPubSub.subscribe('message', this.state.messageId, (d, k) => {this.updateMessage(d, k)}, (e, k) => {this.restError(e, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        console.debug('NerdHerderMessageCard unmount');
        this.restPubSubPool.unsubscribe();
    }

    restError(error, key) {
        this.setState({errorFeedback: 'Cannot retrieve message details'})
    }

    onCancelModal() {
        this.setState({showUserProfileModal: false});
    }

    updateMessage(messageData, key) {
        console.debug('NerdHerderMessageCard updateMessage()');
        const message = NerdHerderDataModelFactory('message', messageData);
        this.setState({message: message, fromUserId: messageData.from_user_id, parentMessageId: messageData.parent_id})
        let sub = this.restPubSub.subscribe('user', messageData.from_user_id, (d, k) => {this.updateUser(d, k)}, (e, k) => {this.restError(e, k)});
        this.restPubSubPool.add(sub);
        // if there is a parent message, include that
        if (messageData.parent_id) {
            let sub = this.restPubSub.subscribe('message', messageData.parent_id, (d, k) => {this.updateParentMessage(d, k)}, (e, k) => {this.restError(e, k)});
            this.restPubSubPool.add(sub);
        }
    }

    updateParentMessage(messageData, key) {
        console.debug('NerdHerderMessageCard updateParentMessage()');
        const message = NerdHerderDataModelFactory('message', messageData);
        this.setState({parentMessage: message})
    }

    updateUser(userData, key) {
        console.debug('NerdHerderMessageCard updateUser()');
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({fromUser: user})
    }

    render() {
        if (this.state.message === null) return(<NerdHerderLoadingCard title="Message..."/>);
        if (this.state.fromUser === null) return(<NerdHerderLoadingCard title="Message..."/>);
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);

        let messageOrReplyHtml = null;
        let parentMessageBubble = null;
        let childMessageBubble = null;
        let messageBubbles = null;
        if (this.state.parentMessageId) {
            messageOrReplyHtml = <small className='text-muted'>{this.state.fromUser.getUserNameAndShortNameIfAvailable()} replied</small>
        } else {
            messageOrReplyHtml = <small className='text-muted'>{this.state.fromUser.getUserNameAndShortNameIfAvailable()} sent a message</small>
        }

        const messageText = he.decode(this.state.message.text);

        if (this.state.parentMessage) {
            const parentMessageText = he.decode(this.state.parentMessage.text);
            parentMessageBubble = <ChatBubble user={this.props.localUser} text={parentMessageText} iconSide='right'/>
            childMessageBubble = <ChatBubble user={this.state.fromUser} text={messageText} iconSide='left'/>
            messageBubbles = <div>{parentMessageBubble}{childMessageBubble}</div>
            return (
                <NerdHerderStandardCardTemplate
                    user={this.state.fromUser}
                    localUser={this.props.localUser}
                    userTitle={true}
                    userIcon={true}
                    icon={true}
                    errorFeedback={this.state.errorFeedback}
                    userFeedback={this.state.userFeedback}
                    updating={this.state.updating}
                    onClick={()=>console.log('clicked message card')}
                    additionalContent={messageBubbles}>
                    {this.state.showUserProfileModal &&
                    <NerdHerderUserProfileModal userId={this.state.fromUserId} localUser={this.props.localUser} onCancel={()=>this.onCancelModal()}/>}
                    {messageOrReplyHtml}
                    <div className='text-end'>
                        <Button variant='primary' size='sm' onClick={()=>this.setState({navigateTo: `/app/messages?message_id=${this.state.messageId}`})}>Reply</Button>
                    </div>
                </NerdHerderStandardCardTemplate>
            )
        }

        return (
            <NerdHerderStandardCardTemplate
                user={this.state.fromUser}
                localUser={this.props.localUser}
                userTitle={true}
                userIcon={true}
                icon={true}
                errorFeedback={this.state.errorFeedback}
                userFeedback={this.state.userFeedback}
                updating={this.state.updating}
                onClick={()=>this.setState({showUserProfileModal: true})}>
                {this.state.showUserProfileModal &&
                <NerdHerderUserProfileModal userId={this.state.fromUserId} localUser={this.props.localUser} onCancel={()=>this.onCancelModal()}/>}
                {messageOrReplyHtml}
                {parentMessageBubble}
                {childMessageBubble}
                {!childMessageBubble &&
                <Truncate>
                    <LinkifyText>{messageText}</LinkifyText>
                </Truncate>}
                <div className='text-end'>
                    <Button variant='primary' size='sm' onClick={()=>this.setState({navigateTo: `/app/messages?message_id=${this.state.messageId}`})}>Reply</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export class ChatBubble extends React.Component {

    render() {
        let iconLeft = true;
        if (this.props.iconSide === 'right') {
            iconLeft = false;
        }
        const icon = <Image className="rounded-circle" alt="user icon" height={25} width={25} src={this.props.user.getImageUrl()}/>

        return (
            <div className="chat-bubble my-1">
                <Row>
                    {iconLeft &&
                    <Col xs="auto">
                        {icon}
                    </Col>}
                    {iconLeft &&
                    <Col className="text-start">
                        <Truncate>
                            <LinkifyText><small>{this.props.text}</small></LinkifyText>
                        </Truncate>
                    </Col>}
                    {!iconLeft &&
                    <Col className="text-end">
                        <Truncate>
                            <LinkifyText><small>{this.props.text}</small></LinkifyText>
                        </Truncate>
                    </Col>}
                    {!iconLeft &&
                    <Col xs="auto">
                        {icon}
                    </Col>}
                </Row>
            </div>
        );
    }
}

export class NerdHerderLeaguePostCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inView: false,
        }
    }

    onChangeView(inView, entry) {
        if (inView && this.state.inView === false) {
            this.setState({inView: true});
            console.debug(`show LeaguePost-${this.props.messageId}`);
        }
    }

    render() {
        return (
            <CardErrorBoundary cardTypeName='NerdHerderLeaguePostCard'>
                <InView onChange={(inView, entry)=>{this.onChangeView(inView, entry)}}>
                    {!this.state.inView &&
                    <PlaceholderLeaguePostCard {...this.props}/>}
                    {this.state.inView &&
                    <NerdHerderLeaguePostCardInner {...this.props}/>}
                </InView>
            </CardErrorBoundary>
        )
    }
}

class PlaceholderLeaguePostCard extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1">
                        <Row>
                            <Col xs="auto" className="text-center px-1 py-0 my-0">
                                <Spinner variant="primary" animation="grow" size="sm" role="status" aria-hidden="true" style={{width: '50px', height: '50px'}}/>
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                    <Placeholder xs={2}/>
                                </Placeholder>
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={4}/>
                                    <Placeholder xs={6}/>
                                </Placeholder>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class NerdHerderLeaguePostCardInner extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.localUser === null) console.error('localUser not included in props');

        this.state = {
            messageId: this.props.messageId,
            message: null,
            childMessageIds: [],
            fromUserId: null,
            fromUser: null,
            leagueId: null,
            league: null,
            eventId: null,
            event: null,
            showButtons: true,
            showAddReplyBubble: false,
            showLikePopover: false,
            disabled: false,
            userFeedback: null,
            errorFeedback: null,
            deleted: false,
        };

        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool(); 
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('message', this.state.messageId, (d, k) => {this.updateMessage(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateMessage(messageData, key) {
        if (messageData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newMessage = NerdHerderDataModelFactory('message', messageData);
        const childMessageIds = this.consolidateChildMessageIds(newMessage.tree.children);
        this.setState({
            message: newMessage,
            fromUserId: newMessage.from_user_id,
            leagueId: newMessage.league_id,
            eventId: newMessage.event_id,
            childMessageIds: childMessageIds
        });
        let sub = this.restPubSub.subscribeNoRefresh('user', newMessage.from_user_id, (d, k)=>{this.updateUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('league', newMessage.league_id, (d, k)=>{this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
        if (newMessage.event_id !== null) {
            sub = this.restPubSub.subscribeNoRefresh('event', newMessage.event_id, (d, k)=>{this.updateEvent(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    updateUser(userData, key) {
        console.debug('NerdHerderMessageCard updateUser()');
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({fromUser: user})
    }

    updateLeague(leagueData, key) {
        console.debug('NerdHerderMessageCard updateLeague()');
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league})
    }

    updateEvent(eventData, key) {
        console.debug('NerdHerderMessageCard updateLeague()');
        const event = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: event})
    }

    consolidateChildMessageIds(childrenList) {
        let childMessageIds = [];
        for (const childMessageFragment of childrenList) {
            if (childMessageFragment.state === 'sent') {
                childMessageIds.push(childMessageFragment.id);
            }
            const moreChildMessageIds = this.consolidateChildMessageIds(childMessageFragment.children);
            for (const moreChildId of moreChildMessageIds) {
                childMessageIds.push(moreChildId);
            }
        }
        return childMessageIds;
    }

    onComment() {
        if (this.state.showAddReplyBubble) {
            this.setState({showAddReplyBubble: false, showButtons: true});
        } else {
            this.setState({showAddReplyBubble: true, showButtons: false});
        }
    }

    onAddReply() {
        this.setState({showAddReplyBubble: false, showButtons: true});
        // need to refresh this message
        this.restPubSub.refresh('message', this.state.message.id);
    }

    onCancelReply() {
        this.setState({showAddReplyBubble: false, showButtons: true});
    }

    onEdit(event) {
        console.debug('edit clicked');
    }

    onDelete(event) {
        console.debug('delete clicked');
    }

    onSelectReaction(event) {
        console.debug('set reaction');
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.message === null) return(<NerdHerderLoadingCard postStyle={true}/>);
        if (this.state.fromUser === null) return(<NerdHerderLoadingCard postStyle={true}/>);
        if (this.state.league === null) return(<NerdHerderLoadingCard postStyle={true}/>);

        const replyBubbles = [];
        for (const childMessageId of this.state.childMessageIds) {
            const replyBubble = <DisplayReplyBubble key={childMessageId} messageId={childMessageId} localUser={this.props.localUser} league={this.props.league}></DisplayReplyBubble>
            replyBubbles.push(replyBubble);
        }

        let replyButtons = null;
        let isLeagueMember = this.state.league.isManager(this.props.localUser.id) || this.state.league.isPlayer(this.props.localUser.id);
        // don't allow comments on archived leagues
        // don't allow comments by non-members once the league is in running or complete state
        if (this.state.showButtons && this.state.league.state !== 'archived' && (isLeagueMember || this.state.league.state === 'interest' || this.state.league.state === 'open')) {
            replyButtons =
                <Row className='mt-2'>
                    <Col className='d-grid'>
                        <Button variant='outline-primary' size='sm' onClick={()=>this.onComment()}>
                            <NerdHerderFontIcon icon='flaticon-message-of-rounded-rectangular-filled-speech-bubble'/>
                        </Button>
                    </Col>
                </Row>
        }

        let addReplyBubble = null;
        if (this.state.showAddReplyBubble) {
            addReplyBubble = <AddReplyBubble localUser={this.props.localUser}
                                             parentMessage={this.state.message}
                                             postReply={()=>this.onAddReply()}
                                             cancelReply={()=>this.onCancelReply()}/>
        }

        let gallery = null;
        if (this.state.message.type === 'gallery') {
            gallery = <MessageGallery localUser={this.props.localUser} message={this.state.message}/>
        }

        let reactionStatus = null;
        if (this.state.message && this.state.message.reactions && this.state.message.reactions.length !== 0) {
            reactionStatus = 
                <Row>
                    <Col></Col>
                    <Col xs='auto'>
                        <div className='chat-bubble shadow-sm'>
                            <NerdHerderReactionStatus message={this.state.message} localUser={this.props.localUser}/>
                        </div>
                    </Col>
                </Row>
        }

        let urlSnippet = null;
        const urlText = getUrlFromText(this.state.message.text);
        if (urlText) {
            urlSnippet = <div className='my-3'><OpenGraphCard url={urlText}/></div>
        }

        let additionalContent = 
            <div>
                {gallery}
                {reactionStatus}
                {urlSnippet}
                {replyBubbles}
                {replyButtons}
                {addReplyBubble}
            </div>

        return (
            <NerdHerderStandardCardTemplate
                user={this.state.fromUser}
                localUser={this.props.localUser}
                userTitle={true}
                userIcon={true}
                icon={true}
                timeStamp={DateTime.fromISO(this.state.message.date_sent, {zone: 'utc'})}
                errorFeedback={this.state.errorFeedback}
                userFeedback={this.state.userFeedback}
                rightColumn={<NerdHerderMessageReactMenu message={this.state.message} league={this.state.league} localUser={this.props.localUser}/>}
                additionalContent={additionalContent}>
                <div className='text-break'>
                    <Truncate>
                        <FormattedText text={this.state.message.text}/>
                    </Truncate>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export class DisplayReplyBubble extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser ');

        // avoid looking up the data if we have it already
        let messageData = null;
        let messageId = null;
        if (typeof this.props.message !== 'undefined') {
            messageData = this.props.message;
            messageId = messageData.id;
        } else {
            messageId = this.props.messageId;
        }

        this.state = {
            messageId: messageId,
            message: messageData,
            fromUserId: null,
            fromUser: null,
            deleted: false,
        };

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool(); 
    }

    componentDidMount() {
        if (this.state.message === null) {
            let sub = this.restPubSub.subscribe('message', this.state.messageId, (d, k) => {this.updateMessage(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateMessage(messageData, key) {
        if (messageData === 'DELETED') {
            this.setState({deleted: true});
            return;
        }

        const message = NerdHerderDataModelFactory('message', messageData);
        this.setState({message: message, fromUserId: messageData.from_user_id, leagueId: messageData.league_id})
        let sub = this.restPubSub.subscribe('user', messageData.from_user_id, (d, k) => {this.updateUser(d, k)});
        this.restPubSubPool.add(sub);
    }

    updateUser(userData, key) {
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({fromUser: user})
    }

    openImageInNewTab(filename, event) {
        event.preventDefault();
        let url = getStorageFilePublicUrl(`/user_images/${filename}`);
        window.open(url, '_blank');
    }

    render() {
        if (this.state.deleted) return(null);

        let icon = null;
        if (this.state.fromUser === null) {
            icon = <Spinner className="mt-2" variant="primary" animation="grow" size="sm" role="status" aria-hidden="true" style={{width: '25px', height: '25px'}}/>
        } else {
            icon = <Image className="rounded-circle mt-2" alt="user icon" height={25} width={25} src={this.state.fromUser.getImageUrl()}/>
        }

        let messageArea = null;
        let imageArea = null;
        let snippetArea = null;
        let urlText = null;
        let messageText = null;
        if (this.state.message === null) {
            messageArea = <Placeholder as="div" animation="glow" size="xs"> <Placeholder xs={6}/> </Placeholder>
        } else {
            messageText = he.decode(this.state.message.text);
            urlText = getUrlFromText(messageText);
            messageArea = <div className='text-break'><Truncate><LinkifyText><small>{messageText}</small></LinkifyText></Truncate></div>

            if (this.state.message.type === 'gallery') {
                const galleryFiles = this.state.message.filenames.split(" ");
                
                // if there's no image, just hide the reply
                if (galleryFiles.length === 0) {
                    return(null);
                }
                else if (galleryFiles.length === 1) {
                    imageArea = 
                        <div className='text-center'>
                            <Image className='d-block w-100 mb-1 rounded' height={200} onClick={(e)=>this.openImageInNewTab(galleryFiles[0], e)} style={{objectFit: 'contain'}} src={getStorageFilePublicUrl(`/user_images/${galleryFiles[0]}`)} alt='user uploaded image'/>
                        </div>
                } else {
                    return (null);
                }
            }
            if (urlText) {
                snippetArea = <div className='my-2'><OpenGraphCard url={urlText}/></div>
            }
        }

        // if this is the local user, put the icon on the right side unless explicitly given a side
        let iconLeft = true;
        if (typeof this.props.iconSide === 'undefined' || this.props.iconSide === null){
            // eslint-disable-next-line eqeqeq
            if (this.state.fromUserId == this.props.localUser.id) {
                iconLeft = false;
            }
        }else if (this.props.iconSide === 'right') {
            iconLeft = false;
        }

        let username = null;
        if (this.state.fromUser) {
            username =
                <div className="m-0 p-0">
                    <small className='text-muted m-0 p-0'>
                        <small className="m-0 p-0">{this.state.fromUser.username}</small>
                    </small>
                </div>
        }

        let button = null;
        if (this.state.message) {
            button = <NerdHerderMessageReactMenu direction={iconLeft ? 'left' : 'right'} message={this.state.message} localUser={this.props.localUser} />
        }

        let className = 'chat-bubble shadow-sm my-1';
        if (this.state.message && this.state.message.reactions && this.state.message.reactions.length !== 0) className = 'chat-bubble shadow-sm mt-1 mb-2'
        
        return (
            <Row>
                {!iconLeft && <Col xs={1}></Col>}
                <Col xs={11}>
                    <div className={className}>
                        <Row>
                            {iconLeft &&
                            <Col xs="auto">
                                {icon}
                            </Col>}
                            {iconLeft &&
                            <Col className="ps-0 text-start">
                                {username}
                                {messageArea}
                                {imageArea}
                                {snippetArea}
                            </Col>}
                            {iconLeft &&
                            <Col xs="auto">
                                {button}
                            </Col>}

                            {!iconLeft &&
                            <Col xs="auto">
                                {button}
                            </Col>}
                            {!iconLeft &&
                            <Col className="pe-0">
                                {username}
                                <div className='text-start'>{messageArea}</div>
                                {imageArea}
                                {snippetArea}
                            </Col>}
                            {!iconLeft &&
                            <Col xs="auto">
                                {icon}
                            </Col>}
                        </Row>
                        <ChatBubbleReactions message={this.state.message} localUser={this.props.localUser} direction={iconLeft ? 'right' : 'left'}/>
                    </div>
                </Col>
                {iconLeft && <Col xs={1}></Col>}
            </Row>
        );
    }
}

export class AddReplyBubble extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('localUser not included in props.local');
        if (typeof this.props.parentMessage === 'undefined') console.error('localUser not included in props.parentMessage');
        if (typeof this.props.cancelReply === 'undefined') console.error('cancelReply not included in props.cancelReply');
        if (typeof this.props.postReply === 'undefined') console.error('postReply not included in props.postReply');

        this.restPubSub = new NerdHerderRestPubSub();

        this.state = {
            messageText: '',
            uploadAlert: null,
            updating: false,
        }
    }

    handleInputChange(event) {
        this.setState({messageText: event.target.value});
    }

    handlePaste(event) {
        if (event.clipboardData.files.length !== 0) {
            event.preventDefault();
            this.handleUpload(event.clipboardData.files[0]);
        }
    }

    handleDrop(event) {
        if (event.dataTransfer.files.length !== 0) {
            event.preventDefault();
            this.handleUpload(event.dataTransfer.files[0]);
        }
    }

    handleUpload(uploadFile) {
        NerdHerderSingleImageUpload(uploadFile, (f)=>this.onSingleFileAdd(f), null, (f,r)=>this.onSingleFileUploadSuccess(f,r), (f,e)=>this.onSingleFileUploadFailure(f,e));
    }

    onSingleFileAdd(file) {
        const uploadAlert = <Alert className='py-1 mb-1' variant='warning'>Uploading...</Alert>
        this.setState({updating: true, uploadAlert: uploadAlert});
    }

    onSingleFileUploadSuccess(file, response) {
        this.setState({updating: true});
        const postData = {type: 'gallery',
                          to_user_id: null,
                          from_user_id: this.props.localUser.id,
                          text: '',
                          subject: 'league post',
                          parent_id: this.props.parentMessage.id,
                          league_id: this.props.parentMessage.league_id,
                          event_id: this.props.parentMessage.event_id,
                          filenames: file.upload.filename,
                          read: false,
                          state: 'sent'};
        this.restPubSub.post('message', null, postData);
        setTimeout(()=>this.onAfterPostTimer(), 1500);
    }

    onSingleFileUploadFailure(file, error) {
        const uploadAlert = <Alert className='py-1 mb-1' variant='danger'>File rejected by server</Alert>
        this.setState({updating: false, uploadAlert: uploadAlert});
        this.props.cancelReply();
    }

    onAfterPostTimer() {
        this.setState({updating: false, uploadAlert: null});
        this.props.postReply();
    }

    onPostReply() {
        this.setState({updating: true});
        const postData = {type: 'text',
                          to_user_id: null,
                          from_user_id: this.props.localUser.id,
                          text: this.state.messageText,
                          subject: 'league post',
                          parent_id: this.props.parentMessage.id,
                          league_id: this.props.parentMessage.league_id,
                          event_id: this.props.parentMessage.event_id,
                          filenames: null,
                          read: false,
                          state: 'sent'};
        this.restPubSub.post('message', null, postData);
        setTimeout(()=>this.onAfterPostTimer(), 1500);
    }

    render() {
        const maxLength = 250;

        return (
            <div>
                {this.state.uploadAlert}
                <Form>
                    <Form.Group className="mb-1">
                        <div style={{position: 'relative'}}>
                            <Form.Control as="textarea" rows={3} onChange={(e)=>this.handleInputChange(e)} onPaste={(e)=>this.handlePaste(e)} onDrop={(e)=>this.handleDrop(e)} value={this.state.messageText} placeholder='add a reply...' autoComplete='off' maxLength={maxLength} required/>
                            <FormTextInputLimit current={this.state.messageText.length} max={maxLength}/>
                        </div>
                    </Form.Group>
                    <div className='text-end'>
                        <Button variant='secondary' size='sm' disabled={this.state.updating} onClick={()=>this.props.cancelReply()}>Cancel</Button>
                        {' '}
                        <Button variant='primary' size='sm' disabled={this.state.updating || this.state.messageText.length < 4} onClick={()=>this.onPostReply()}>Post</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export class NerdHerderLeaguePostSnippet extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.localUser === null) console.error('localUser not included in props');

        this.state = {
            messageId: this.props.messageId,
            message: null,
            childMessageIds: [],
            fromUserId: null,
            fromUser: null,
            leagueId: null,
            league: null,
        };

        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool(); 
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('message', this.state.messageId, (d, k) => {this.updateMessage(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateMessage(messageData, key) {
        const message = NerdHerderDataModelFactory('message', messageData);
        const childMessageIds = this.consolidateChildMessageIds(message.tree.children);
        this.setState({message: message, fromUserId: messageData.from_user_id, leagueId: messageData.league_id, childMessageIds: childMessageIds})
        let sub = this.restPubSub.subscribeNoRefresh('user', messageData.from_user_id, (d, k) => {this.updateUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('league', messageData.league_id, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
    }

    updateUser(userData, key) {
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({fromUser: user})
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league})
    }

    consolidateChildMessageIds(childrenList) {
        let childMessageIds = [];
        for (const childMessageFragment of childrenList) {
            if (childMessageFragment.state === 'sent') {
                childMessageIds.push(childMessageFragment.id);
            }
            const moreChildMessageIds = this.consolidateChildMessageIds(childMessageFragment.children);
            for (const moreChildId of moreChildMessageIds) {
                childMessageIds.push(moreChildId);
            }
        }
        return childMessageIds;
    }

    render() {
        if (this.state.message === null) return(<NerdHerderLoadingCard postStyle={true}/>);
        if (this.state.fromUser === null) return(<NerdHerderLoadingCard postStyle={true}/>);
        if (this.state.league === null) return(<NerdHerderLoadingCard postStyle={true}/>);

        const luxonDate = DateTime.fromISO(this.state.message.date_sent, {zone: 'utc'});
        const timestampString = luxonDate.setZone(new SystemZone()).toLocaleString(DateTime.DATETIME_MED);
        let borderSelectedClass = null;
        if (this.props.selected) {
            borderSelectedClass = 'border-selected-primary';
        }

        return (
            <div>
                <div className={`my-1 list-group-item rounded align-middle ${borderSelectedClass}`} onClick={()=>this.props.onClick()}>
                    <Row>
                        <Col className='pe-0' xs='auto'>
                            <Image className="rounded-circle" alt="user icon" height={25} width={25} src={this.state.fromUser.getImageUrl()}/>
                        </Col>
                        <Col className='ps-1'>
                            <b>{this.state.fromUser.username}</b>
                            <small className="text-muted"> {timestampString}</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Truncate>
                                <LinkifyText><small>{this.state.message.text}</small></LinkifyText>
                            </Truncate>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}


export class CreateLeaguePost extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('localUser not included in props.local');
        if (typeof this.props.league === 'undefined') console.error('league not included in props.league');
        if (typeof this.props.cancelPost === 'undefined') console.error('cancelReply not included in props.cancelPost');
        if (typeof this.props.createPost === 'undefined') console.error('createPost not included in props.createPost');

        this.restPubSub = new NerdHerderRestPubSub();
        this.dzRef = React.createRef();
        this.filesSent = 0;
        this.filesSuccess = 0;

        this.state = {
            messageText: '',
            updating: false,
            dzBusy: false,
            showDropzone: false,
        }
    }

    handleInputChange(event) {
        this.setState({messageText: event.target.value});
    }

    onAddImages() {
        if (this.state.showDropzone) {
            this.setState({showDropzone: false});
        } else {
            this.setState({showDropzone: true});
        }
    }

    onAfterPostTimer() {
        this.setState({messageText: '', showDropzone: false, updating: false, dzBusy: false});
        if (this.state.showDropzone) {
            this.dzRef.current.clearUploadedFiles();
        }
        this.props.createPost();
    }

    onCreatePost() {
        this.setState({updating: true});

        let filenames = null;
        let type = 'text';
        if (this.state.showDropzone) {
            const uploadedFiles = this.dzRef.current.getUploadedFiles();
            if (uploadedFiles.length > 0) {
                filenames = '';
                type = 'gallery';
                for (const file of uploadedFiles) {
                    filenames += `${file.upload.filename}|`
                }
            }
        }

        let eventId = null;
        if (this.props.event) eventId = this.props.event.id;

        const postData = {type: type,
                          to_user_id: null,
                          from_user_id: this.props.localUser.id,
                          text: this.state.messageText,
                          subject: 'league post',
                          parent_id: null,
                          league_id: this.props.league.id,
                          event_id: eventId,
                          filenames: filenames,
                          read: false,
                          state: 'sent'};
        this.restPubSub.post('message', null, postData);
        setTimeout(()=>this.onAfterPostTimer(), 2000);
    }

    onCancelPost() {
        this.setState({messageText: '', updating: false, dzBusy: false, showDropzone: false});
        this.props.cancelPost();
    }

    onDzSuccess(file, response) {
        this.filesSuccess++;
        if (this.filesSuccess >= this.filesSent) {
            this.setState({dzBusy: false});
        }
    }

    onDzRemoveFile(file, response) {
        this.filesSent--;
        this.setState({dzBusy: false});
    }

    onDzSending(file) {
        this.filesSent++;
        this.setState({dzBusy: true});
    }

    onDzError(file) {
        this.setState({dzBusy: true});
        console.error('DZ Error');
        console.error(file);
    }

    render() {
        const maxLength = 4096;

        return (
            <div>
                <Form>
                    <Form.Group className="mb-1">
                        <div style={{position: 'relative'}}>
                            <Form.Control as="textarea" rows={3} disabled={this.state.updating} onChange={(event)=>this.handleInputChange(event)} placeholder='add a your message here...' autoComplete='off' value={this.state.messageText} maxLength={maxLength}/>
                            <FormTextInputLimit current={this.state.messageText.length} max={maxLength}/>
                        </div>
                    </Form.Group>
                    {!this.state.showDropzone &&
                    <Form.Group>
                        <Button className='float-start' variant='outline-primary' size='sm' disabled={this.state.updating} onClick={()=>this.onAddImages()}>
                            <NerdHerderFontIcon icon='flaticon-photo-camera'/>
                        </Button>
                    </Form.Group>}
                    {this.state.showDropzone &&
                    <Form.Group className="mb-1">
                        <NerdHerderDropzoneImageUploader ref={this.dzRef} 
                                                         localUser={this.props.localUser}
                                                         message={null}
                                                         removeCallback={(f)=>this.onDzRemoveFile(f)}
                                                         sendingCallback={(f)=>this.onDzSending(f)}
                                                         successCallback={(f, r)=>this.onDzSuccess(f, r)}
                                                         errorCallback={(f)=>this.onDzError(f)}/>
                    </Form.Group>
                    }
                    <Form.Group className='text-end'>
                        <Button variant='secondary' size='sm' disabled={this.state.updating} onClick={()=>this.onCancelPost()}>Cancel</Button>
                        {' '}
                        <Button variant='primary' size='sm' disabled={this.state.updating || this.state.dzBusy} onClick={()=>this.onCreatePost()}>Post</Button>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}

export class MessageGallery extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('localUser not included in props.local');
        if (typeof this.props.message === 'undefined') console.error('message not included in props.message');
    }

    openImageInNewTab(filename, event) {
        event.preventDefault();
        let url = getStorageFilePublicUrl(`/user_images/${filename}`);
        window.open(url, '_blank');
    }

    render() {
        const items = []
        const galleryFiles = this.props.message.filenames.split(" ");
        
        // if there's no images, just hide the gallery
        if (galleryFiles.length === 0) return null;
        if (galleryFiles.length === 1) return (
            <Image className='d-block w-100 mb-1' height={300} onClick={(e)=>this.openImageInNewTab(galleryFiles[0], e)} style={{objectFit: 'cover'}} src={getStorageFilePublicUrl(`/user_images/${galleryFiles[0]}`)} alt='user uploaded image'/>
        )

        for (const filename of galleryFiles) {
            const item = 
                <Carousel.Item key={filename}>
                    <Image className='d-block w-100' height={300} onClick={(e)=>this.openImageInNewTab(filename, e)} style={{objectFit: 'cover'}} src={getStorageFilePublicUrl(`/user_images/${filename}`)} alt='user uploaded image'/>
                </Carousel.Item>
            items.push(item);
        }

        if (items.length === 0) return null;
        return (
            <Carousel className="my-1">
                {items}                
            </Carousel>
        );
    }
}

export class NerdHerderMessageReactMenu extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.localUser === null) console.error('localUser not included in props');
        if (this.props.message === null) console.error('message not included in props');

        this.triggerRef = React.createRef();
        this.timeout = null;

        this.state = {
            showOverlay: false,
            showEditPostModal: false,
            showEditMessageModal: false,
            showDeletePostModal: false,
        };

        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool(); 
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onToggleOverlay() {
        // if the overlay is not being shown, we are about to show it, only let it stay out for a few seconds
        if (this.state.showOverlay === false) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(()=>this.setState({showOverlay: false}), 4000);
            this.setState({showOverlay: true})
        } else {
            this.setState({showOverlay: false})
        }
    }

    onEdit() {
        if (this.timeout) clearTimeout(this.timeout);
        
        // if this was a post (it's in a league) then we need the edit post modal - otherwise use the message modal
        let showEditPostModal = false;
        let showEditMessageModal = false;
        if (this.props.league) showEditPostModal = true;
        else showEditMessageModal = true;

        this.setState({showOverlay: false, showEditPostModal: showEditPostModal, showEditMessageModal: showEditMessageModal});
    }

    onDelete() {
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({showOverlay: false, showDeletePostModal: true});
    }

    onDeleteConfirm() {
        // for posts, we use the message API, for chats use the chat API
        if (this.props.message.channel === null || !this.props.message.channel.includes('chat')) {
            this.restPubSub.delete('message', this.props.message.id);
        } else {
            // the API is slightly different for user or league chats
            const queryParams = {'message-id': this.props.message.id};
            if (this.props.message.channel.includes('chat-league')) {
                this.restApi.genericDeleteEndpointData('league-chat', this.props.message.league_id, queryParams)
                .then((response)=>{
                    console.debug('deleted league chat message successfully');
                })
                .catch((error)=>{
                    console.error('failed to delete league chat message');
                    console.error(error);
                });
            }
            else if (this.props.message.channel.includes('chat-user')) {
                this.restApi.genericDeleteEndpointData('user-chat', this.props.message.channel, queryParams)
                .then((response)=>{
                    console.debug('deleted user chat message successfully');
                })
                .catch((error)=>{
                    console.error('failed to delete user chat message');
                    console.error(error);
                });
            }
        }
    }

    onCancel() {
        this.setState({showOverlay: false, showDeletePostModal: false, showEditPostModal: false, showEditMessageModal: false});
    }

    onSelectReaction(reactionType) {
        // if the reaction type is the same as the current selection, then cancel it
        const currentSelection = this.props.message.getUserReactionType(this.props.localUser.id);
        if (reactionType === currentSelection) reactionType = 'none';

        this.restApi.genericPostEndpointData('message-reaction', this.props.message.id, {type: reactionType})
        .then((response)=>{
            this.restPubSub.refresh('message', this.props.message.id);
        })
        .catch((error)=>{
            console.error(error);
        });
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(()=>this.setState({showOverlay: false}), 200);
    }

    render() {
        // generate like options for everyone and edit/delete button for authors & league managers
        let canEdit = false;
        let canDelete = false;
        let showEditDeleteOptions = false;
        if (this.props.message.from_user_id === this.props.localUser.id) {
            canEdit = true;
            canDelete = true;
            showEditDeleteOptions = true;
        }
        if (this.props.league && this.props.league.isManager(this.props.localUser.id)) {
            canDelete = true
            showEditDeleteOptions = true;
        }

        const currentSelection = this.props.message.getUserReactionType(this.props.localUser.id);

        let deleteTitle = 'Delete Comment?';
        let deleteMessage = 'Are you sure you want to delete this comment? The comment will be removed, and there is no undo.'
        if (this.props.league) {
            deleteTitle = 'Delete Post?';
            deleteMessage = 'Are you sure you want to delete this post? The post and all replies will be removed, and there is no undo.'
        }

        let iconSize = '24px';
        let selectedIconClassName = 'border-sm-selected-primary p-1';
        let iconClassName = 'border-sm-selected-hoverable p-1';
        let content = 
            <div className='py-2'>
                <div className='px-1'>
                    <span className={currentSelection==='like' ? selectedIconClassName : iconClassName} onClick={()=>this.onSelectReaction('like')}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/like.png')} alt='like'/>
                    </span>
                    <span className={currentSelection==='dislike' ? selectedIconClassName : iconClassName} onClick={()=>this.onSelectReaction('dislike')}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/dislike.png')} alt='dislike'/>
                    </span>
                    <span className={currentSelection==='wow' ? selectedIconClassName : iconClassName} onClick={()=>this.onSelectReaction('wow')}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/surprised.png')} alt='wow'/>
                    </span>
                    <span className={currentSelection==='funny' ? selectedIconClassName : iconClassName} onClick={()=>this.onSelectReaction('funny')}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/funny.png')} alt='funny'/>
                    </span>
                    <span className={currentSelection==='sad' ? selectedIconClassName : iconClassName} onClick={()=>this.onSelectReaction('sad')}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/crying.png')} alt='sad'/>
                    </span>
                    <span className={currentSelection==='anger' ? selectedIconClassName : iconClassName} onClick={()=>this.onSelectReaction('anger')}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/angry.png')} alt='anger'/>
                    </span>
                </div>
                {showEditDeleteOptions &&
                <hr className='mx-2 my-2'/>}
                {showEditDeleteOptions &&
                <div className='px-1'>
                    {canEdit &&
                    <span className={iconClassName} onClick={()=>this.onEdit()}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/edit32.png')} alt='edit'/>
                    </span>}
                    {canDelete &&
                    <span className={iconClassName} onClick={()=>this.onDelete()}>
                        <Image height={iconSize} src={getLocalStaticFileUrl('/delete32.png')} alt='delete'/>
                    </span>}
                </div>}
            </div>

        return (
            <div>
                {this.state.showEditPostModal &&
                <NerdHerderEditPostModal messageId={this.props.message.id} onCancel={()=>this.onCancel()} league={this.props.league} localUser={this.props.localUser}/>}
                {this.state.showEditMessageModal &&
                <NerdHerderEditMessageModal messageId={this.props.message.id} onCancel={()=>this.onCancel()} localUser={this.props.localUser}/>}
                {this.state.showDeletePostModal &&
                <NerdHerderConfirmModal title={deleteTitle} message={deleteMessage} onCancel={()=>this.onCancel()} onAccept={()=>this.onDeleteConfirm()} acceptButtonText='Delete' localUser={this.props.localUser}/>}
                <Button ref={this.triggerRef} size='sm' variant='outline-secondary' onClick={()=>this.onToggleOverlay()} style={{border: '0px'}}>
                    <NerdHerderFontIcon icon='flaticon-more-with-three-dots-button'/>
                </Button>
                <Overlay target={this.triggerRef.current} placement={this.props.direction || 'left'} show={this.state.showOverlay}>
                    <Popover>{content}</Popover>
                </Overlay>
            </div>
            
        )
    }
}

class NerdHerderReactionStatus extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('localUser not included in props');
        if (typeof this.props.message === 'undefined') console.error('message not included in props');

        this.triggerRef = React.createRef();
        this.timeout = null;

        this.state = {
            showOverlay: false,
            showWhichOverlay: 'none',
        };
    }

    showWho(reactionType) {
        if (this.state.showOverlay) {
            this.setState({showOverlay: false});
        } else {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(()=>this.setState({showOverlay: false}), 3000);
            this.setState({showOverlay: true, showWhichOverlay: reactionType});
        }
    }

    render() {
        if (this.props.message === null) return(null);
        if (this.props.message.reactions === null) return(null);
        if (this.props.message.reactions.length === 0) return(null);

        let hasSomeReactions = false;
        const reactDict = {'like': 0, 'dislike': 0, 'funny': 0, 'wow': 0, 'sad': 0, 'anger': 0};
        const overlayDict = {'like': [], 'dislike': [], 'funny': [], 'wow': [], 'sad': [], 'anger': [], 'none': []};
        for (const [reactionType, reactionList] of Object.entries(overlayDict)) {
            reactionList.push(<div key={reactionType}><small><b>Reacted {capitalizeFirstLetter(reactionType)}:</b></small></div>)
        }
        for (const usersMessages of this.props.message.reactions) {
            const reactionType = usersMessages.type;
            if (reactDict.hasOwnProperty(reactionType)) {
                reactDict[reactionType] += 1;
                hasSomeReactions = true;
            }
            if (overlayDict.hasOwnProperty(reactionType) && reactionType !== 'none') {
                overlayDict[reactionType].push(<div key={usersMessages.username}><small>{usersMessages.username}</small></div>);
            }
        }

        if (!hasSomeReactions) return(null);

        const reactImages = {'like': '/like.png', 'dislike': '/dislike.png', 'funny': '/funny.png', 'wow': '/surprised.png', 'sad': '/crying.png', 'anger': '/angry.png'};

        const spanItems = [];
        for (const [reactionType, reactionNumber] of Object.entries(reactDict)) {
            if (reactionNumber === 0) continue;
            let image = <Image height={`${this.props.height || 15}px`} src={getLocalStaticFileUrl(reactImages[reactionType])} alt={reactionType}/>
            let reactionNumberIndicator = null;
            if (reactionNumber > 1) reactionNumberIndicator = ` x${reactionNumber}`;
            let item = <span key={reactionType} className='mx-1 text-muted' style={{fontWeight: 'bold', fontSize: '10px'}} onClick={()=>this.showWho(reactionType)}>{image}{reactionNumberIndicator}</span>;
            spanItems.push(item);
        }

        let overlayContent = null;
        if (this.state.showWhichOverlay !== 'none') overlayContent = overlayDict[this.state.showWhichOverlay];

        return (
            <span>
                <span ref={this.triggerRef}>
                    {spanItems}
                </span>
                <Overlay target={this.triggerRef.current} placement={'bottom'} show={this.state.showOverlay}>
                    <Tooltip>{overlayContent}</Tooltip>
                </Overlay>
            </span>
        )
    }
}

export class ChatBubbleReactions extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.message === 'undefined') console.error('missing props.message');
    }

    render() {
        if (this.props.message === null) return(null);
        if (this.props.message.reactions === null) return(null);
        if (this.props.message.reactions.length === 0) return(null);
        
        let style = {
            position: 'absolute',
            bottom: '-8px',
            border: '1px solid #ced4da',
            borderRadius: '5px',
            fontSize: '10px',
            backgroundColor: 'white'
        }

        if (this.props.direction === 'right') {
            style.right = '8px';
        } else {
            style.left = '8px';
        }

        return (
            <div className='text-muted px-1' style={style}>
                <NerdHerderReactionStatus message={this.props.message} localUser={this.props.localUser}/>
            </div>
        )
    }
}