import React from 'react';
import withRouter from './withRouter';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { Navigate } from 'react-router-dom';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderVenueSearchModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, convertLocalDateObjectToFormInput, delCookieAfterDelay, capitalizeFirstLetter } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { Required } from './nerdherder-components/NerdHerderBadge';
import { FormErrorText, getFormErrors, setErrorState, clearErrorState, FormTextInputLimit } from './nerdherder-components/NerdHerderFormHelpers';
import { VenueListItem } from './nerdherder-components/NerdHerderListItems';


class NewLeaguePage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.pageRef = React.createRef();
        this.resolveRefsReload = true;

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            errorFeedback: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal />);

        return(
            <NerdHerderStandardPageTemplate ref={this.pageRef} pageName='new_league' localUser={this.state.localUser}>
                <NewLeagueCard pageRef={this.pageRef} localUser={this.state.localUser}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class NewLeagueCard extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const draftLeague = NerdHerderDataModelFactory('league', null);
        draftLeague.creator_id = this.props.localUser.id;
        draftLeague.topic_id = '000';
        draftLeague.end_date = '';

        this.state = {
            navigateTo: null,
            showVenueSearchModal: false,
            selectedVenueId: null,
            updating: false,
            ipAddress: null,
            ipZipcode: null,
            ipCountry: null,
            countryList: [],
            topicList: [],
            league: draftLeague,
            acceptTerms: false,
            formErrors: {},
            formValidated: false,
            venueMode: 'manual'
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('country-list', null, (d, k)=>this.updateCountryList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('ip-location', null, (d, k)=>this.updateIpLocation(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('topic', null, (d, k)=>this.updateTopicList(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateCountryList(response, key) {
        console.debug('got country list');
        this.setState({countryList: response});

        // if we have the IP details, and the IP country is in the response, update the form with IP details
        if (this.state.ipCountry !== null && response.includes(this.state.ipCountry)) {
            console.debug('auto set the country and zip');
            this.autoUpdateCountryAndZip(this.state.ipCountry, this.state.ipZipcode);
        }
    }

    updateIpLocation(response, key) {
        console.debug(`got country ${response.country}`);
        let country = response.details.country_name || '';
        let zipcode = response.details.postal || '';
        this.setState({ipAddress: response.ip, ipCountry: country, ipZipcode: zipcode});

        // if we have the updated country list, and the IP country is in the list, update the form with IP details
        if (this.state.countryList.length > 0 && this.state.countryList.includes(country)) {
            console.debug('auto set the country and zip');
            this.autoUpdateCountryAndZip(country, zipcode);
        }
    }

    updateTopicList(response, key) {
        this.setState({topicList: response});
    }

    updateVenue(venueData, key) {
        // eslint-disable-next-line eqeqeq
        if (key && key == this.state.league.venue_id) {
            const newLeague = {...this.state.league};        
            if (venueData.country) {
                newLeague.country = venueData.country;
            }
            if (venueData.zipcode) {
                newLeague.zipcode = venueData.zipcode;
            }
            this.setState({league: newLeague});
        }
    }

    autoUpdateCountryAndZip(country, zipcode) {
        const updatedLeague = {...this.state.league};
        updatedLeague.country = country;
        updatedLeague.zipcode = zipcode;
        this.setState({league: updatedLeague})
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, updating: true});
            const postData = {...this.state.league};
            if (postData.start_date === '') postData.start_date = null;
            if (postData.end_date === '') postData.end_date = null;
            postData.name = postData.name.trimEnd();
            postData.zipcode = postData.zipcode.trimEnd();
            postData.contact = postData.contact.trimEnd();
            postData.venue_string = postData.venue_string.trimEnd();
            postData.summary = postData.summary.trimEnd();
            console.debug(postData);
            this.restApi.genericPostEndpointData('league', null, postData)
            .then(response => {
                const newLeagueId = response.data.id;
                this.setState({navigateTo: `/app/manageleague/${newLeagueId}`, updating: false});
            }).catch(error => {
                this.setState({updating: false});
                this.formUpdateError(error, null);
            });
        }
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this name is too short');
        }
        const newLeague = {...this.state.league};
        newLeague.name = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleTypeChange(value) {
        let errorState = clearErrorState('type', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        newLeague.type = value;
        if (newLeague.type === 'tournament' && newLeague.end_date === '') {
            newLeague.end_date = newLeague.start_date;
        }
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleTopicIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('topic_id', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        newLeague.topic_id = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleZipcodeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('zipcode', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('zipcode', {...this.state.formErrors}, 'this value is too short');
        }
        const newLeague = {...this.state.league};
        newLeague.zipcode = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleCountryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('country', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        newLeague.country = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleContactChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('contact', {...this.state.formErrors});
        if (value.length < 10) {
            errorState = setErrorState('contact', {...this.state.formErrors}, 'this contact information is too short');
        }
        const newLeague = {...this.state.league};
        newLeague.contact = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleVenueStringChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('venue_string', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        if (value.length < 10) {
            errorState = setErrorState('venue_string', {...this.state.formErrors}, 'this venue information is too short');
        } else {
            newLeague.venue_id = null;
        }
        newLeague.venue_string = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleSummaryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('summary', {...this.state.formErrors});
        if (value.length < 30) {
            errorState = setErrorState('summary', {...this.state.formErrors}, 'this summary information is too short');
        }
        const newLeague = {...this.state.league};
        newLeague.summary = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleStartDateChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('start_date', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        newLeague.start_date = value;
        if (newLeague.end_date === '') {
            newLeague.end_date = value;
        }
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleEndDateChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('end_date', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        newLeague.end_date = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleRoughDatesChange(event) {
        let value = event.target.checked;
        let errorState = clearErrorState('rough_dates', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        newLeague.rough_dates = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleOnlineChange(event) {
        let value = event.target.checked;
        let errorState = clearErrorState('online', {...this.state.formErrors});
        const newLeague = {...this.state.league};
        newLeague.online = value;
        this.setState({league: newLeague, formErrors: errorState});
    }

    handleAcceptTermsChange(event) {
        let value = event.target.checked;
        this.setState({acceptTerms: value});
    }

    handleVenueModeChange(value) {
        let errorState = clearErrorState('state', {...this.state.formErrors});
        this.setState({venueMode: value, formErrors: errorState});
    }

    onSelectVenue(value) {
        const newLeague = {...this.state.league};
        newLeague.venue_id = value;
        newLeague.venue_string = '';
        this.setState({league: newLeague, showVenueSearchModal: false});
        
        // need to get a copy of the venue so we can set sane zip and country
        if (value !== null) {
            let sub = this.restPubSub.subscribe('venue', value, (d, k)=>this.updateVenue(d, k), null, value);
            this.restPubSubPool.add(sub);
        }
    }

    onCancelSelectVenue() {
        this.setState({showVenueSearchModal: false});
    }

    render() {
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo} replace={true}/>);

        const leagueData = this.state.league;

        const countryOptions = [];
        for (const countryName of this.state.countryList) {
            countryOptions.push(<option key={countryName} value={countryName}>{countryName}</option>)
        }

        const topicOptions = [<option key={'000'} value={'000'}>{'Select A Topic'}</option>];
        for (const topicData of this.state.topicList) {
            const topicId = topicData.id;
            const topicName = topicData.name;
            topicOptions.push(<option key={topicId} value={topicId}>{topicName}</option>)
        }

        let postalCodeText = 'Postal Code';
        if (this.state.league.country === 'United States') {
            postalCodeText = 'Zipcode';
        }

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // the venue is selected if the user entered some text or if they selected an existing venue
        let venueIsSelected = false;
        if (leagueData.venue_string.length >= 10 || leagueData.venue_id) venueIsSelected = true;

        // if any item has an error, is empty, or the topic isn't selected, then the submit is disabled
        let disableSubmitButton = false;
        if (leagueData.name.length < 6 ||
            !venueIsSelected ||
            leagueData.contact.length < 10 ||
            leagueData.summary.length < 30 ||
            leagueData.zipcode.length < 3 ||
            leagueData.topic_id === '000' ||
            this.state.acceptTerms === false ||
            hasFormErrors === true ||
            this.state.updating) {
            disableSubmitButton = true;
        }

        // figure out the min and max days for startDate and endDate - default is +/- 1 year from now
        let todayDate = new Date();
        let minStartDate = new Date();
        let maxStartDate = new Date();
        let minEndDate = new Date();
        let maxEndDate = new Date();
        maxStartDate.setFullYear(todayDate.getFullYear() + 1);
        maxEndDate.setFullYear(todayDate.getFullYear() + 1);
        // if the league has a start date set - set the end date limits based on that
        if (this.state.league.start_date) {
            minEndDate = new Date(this.state.league.start_date);
            maxEndDate = new Date(this.state.league.start_date);
            maxEndDate.setFullYear(minEndDate.getFullYear() + 1);
        }

        let typeTitle = null;
        let typeDescription = null;
        let typeWord = null;
        if (leagueData.type === 'league') {
            typeTitle = 'League';
            typeWord = 'league';
            typeDescription = "Typically spread over multiple weeks/months, may or may not include tournaments and narrative events. Often players are not required to participate in all weeks/events/games. If in doubt, this is the recommended setting.";
        } else if (leagueData.type === 'tournament') {
            typeTitle = 'Competitive Event';
            typeWord = 'event';
            typeDescription = 'An event that is focused around a competitive tournament. Most competitive events have just a day or two of games. Unless players are cut or drop, they are normally expected to participate in all rounds. A "competitive event" can actually be composed of multiple tournaments (e.g. swiss + top cut).';
        }else if (leagueData.type === 'event') {
            typeTitle = 'Other Event';
            typeWord = 'event';
            typeDescription = "Anything that isn't a league or a tournament specific event. This is a good choice if you are running an event that is a little outside the normal rules but will complete in a single day.";
        }

        const termsLink = <a href='/terms' target='_blank'>Terms & Conditions, etc.</a>

        return(
            <NerdHerderStandardCardTemplate title={`New ${capitalizeFirstLetter(leagueData.type)}`} titleIcon='add.png'>
                {this.state.showVenueSearchModal &&
                <NerdHerderVenueSearchModal onAccept={(v)=>this.onSelectVenue(v)} onCancel={()=>this.onCancelSelectVenue()} localUser={this.props.localUser}/>}
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{capitalizeFirstLetter(leagueData.type)} Name<Required/></Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleNameChange(event)} autoComplete='off' value={leagueData.name} minLength={6} maxLength={40} required/>
                        <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>This is the main title for your league - choose something that sets your league apart from all the others!</Form.Text>
                    </Form.Group>

                    <Form.Group className='form-outline mb-2'>
                        <Form.Label>What best describes the focus?<Required/></Form.Label>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-type' type="radio" value={leagueData.type} onChange={(v)=>this.handleTypeChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-league' value={'league'}>League</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-tournament' value={'tournament'}>Competitive Event</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-event' value={'event'}>Other Event</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-3'>
                        <Form.Text>
                            <p><b>{typeTitle}</b> - {typeDescription}</p>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Topic<Required/></Form.Label>
                        <Form.Select disabled={this.state.updating} onChange={(event)=>this.handleTopicIdChange(event)} value={leagueData.topic_id} required>
                            {topicOptions}
                        </Form.Select>
                        <FormErrorText errorId='topic_id' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Users can find {leagueData.type}s by filtering on topics they are interested in.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Summary<Required/></Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='summary' name='summary' as="textarea" rows={4} disabled={this.state.updating} onChange={(event)=>this.handleSummaryChange(event)} autoComplete='off' value={leagueData.summary} minLength={30} maxLength={200} required/>
                            <FormTextInputLimit current={leagueData.summary.length} max={200}/>
                        </div>
                        <FormErrorText errorId='summary' errorState={this.state.formErrors}/>
                        {leagueData.type === 'league' &&
                        <div>
                            <Form.Text className='text-muted'>A brief summary of the league's details. Is there a league night? A tournament? Slow-grow? Casual? Open-ended?</Form.Text>
                        </div>}
                        {leagueData.type === 'tournament' &&
                        <div>
                            <Form.Text className='text-muted'>A brief summary of the event's details. What's the tournament format? Is there a top cut? Fee?</Form.Text>
                        </div>}
                        {leagueData.type === 'event' &&
                        <div>
                            <Form.Text className='text-muted'>A brief summary of the event's details. What make this event special? What's the main idea? Explain what your event is about.</Form.Text>
                        </div>}
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Contact<Required/></Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='contact' name='contact' as="textarea" rows={3} disabled={this.state.updating} onChange={(event)=>this.handleContactChange(event)} autoComplete='off' value={leagueData.contact} minLength={10} maxLength={200} required/>
                            <FormTextInputLimit current={leagueData.contact.length} max={200}/>
                        </div>
                        <FormErrorText errorId='contact' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>A way for potential players to ask questions. For example, this might be an email, a discord server, or a facebook group. It could also be a phone number to a local game store or club.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Venue<Required/></Form.Label>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='venue-mode' type="radio" value={this.state.venueMode} onChange={(e)=>this.handleVenueModeChange(e)}>
                                <ToggleButton variant='outline-primary' id='toggle-venue-manual' value={'manual'}>Enter Address</ToggleButton>
                                <ToggleButton variant='outline-primary' id='toggle-venue-existing' value={'existing'}>Existing Venue</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        {this.state.venueMode === 'manual' &&
                        <div className='mt-3'>
                            <div style={{position: 'relative'}}>
                                <Form.Control id='venue_string' name='venue_string' as="textarea" rows={2} disabled={this.state.updating} onChange={(event)=>this.handleVenueStringChange(event)} autoComplete='off' value={leagueData.venue_string} minLength={10} maxLength={100} required/>
                                <FormTextInputLimit current={leagueData.venue_string.length} max={100}/>
                            </div>
                            <FormErrorText errorId='venue_string' errorState={this.state.formErrors}/>
                            <Form.Text className='text-muted'>Most {leagueData.type}s meet in person - where is the meeting place? It should include an address or something Google Maps would recognize. If the league is online, what is used to play the games or participate (i.e. TTS or Zoom). Select this option if you are unable to find an existing venue. You can update the venue later if desired.</Form.Text>
                        </div>}
                        {this.state.venueMode === 'existing' &&
                        <div className='mt-2'>
                            {this.state.league.venue_id &&
                            <div className='mb-2'>
                                <VenueListItem venueId={this.state.league.venue_id} localUser={this.props.localUser} slim={true} onClick={null}/>
                            </div>}
                            <div className='d-grid gap-2'>
                                <Button variant='primary' onClick={()=>this.setState({showVenueSearchModal: true})}>Select Existing Venue</Button>
                            </div>
                            {this.state.league.venue_id === null &&
                            <Form.Text className='text-muted'>Click the button above to select an existing venue. If you can't find your venue - it may not be added to NerdHerder yet. In this case use <b>Enter Address</b> and you can add/update the venue after you create the {leagueData.type}.</Form.Text>}
                            {this.state.league.venue_id !== null &&
                            <Form.Text className='text-muted'>You have selected the venue shown above. You can update the venue later if desired.</Form.Text>}
                        </div>}
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{`${postalCodeText} & Country`}<Required/></Form.Label>
                        <Form.Control className='mb-1' type="text" disabled={this.state.updating} onChange={(event)=>this.handleZipcodeChange(event)} autoComplete='postal-code' value={leagueData.zipcode} minLength={3} maxLength={45} required/>
                        <FormErrorText errorId='zipcode' errorState={this.state.formErrors}/>
                        <Form.Select disabled={this.state.updating} onChange={(event)=>this.handleCountryChange(event)} value={leagueData.country} required>
                            {countryOptions}
                        </Form.Select>
                        <FormErrorText errorId='country' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Helps users find leagues, tournaments, and events in their area.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Row className='mb-2'>
                            <Col xs={6}>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control id="start_date" name="start_date" type="date" disabled={this.state.updating} onChange={(event)=>this.handleStartDateChange(event)} autoComplete='off' value={leagueData.start_date} min={convertLocalDateObjectToFormInput(minStartDate)} max={convertLocalDateObjectToFormInput(maxStartDate)}/>
                                <FormErrorText errorId='start_date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>When does the {typeWord} start?</Form.Text>
                            </Col>
                            <Col xs={6}>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control id="end_date" name="end_date" type="date" disabled={this.state.updating} onChange={(event)=>this.handleEndDateChange(event)} autoComplete='off' value={leagueData.end_date} min={convertLocalDateObjectToFormInput(minStartDate)} max={convertLocalDateObjectToFormInput(maxStartDate)}/>
                                <FormErrorText errorId='end_date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>When does the {typeWord} end?</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check id="rough_dates" name="rough_dates" label='Approximate Dates' disabled={this.state.updating} onChange={(event)=>this.handleRoughDatesChange(event)} autoComplete='off' checked={leagueData.rough_dates}/>
                                <Form.Text className='text-muted'>Select if the start & end dates are not yet solid.</Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Row>
                            <Col>
                                <Form.Check id="online" name="online" label={`This ${typeWord} (including its games and tournaments) will be played via Internet`} disabled={this.state.updating} onChange={(event)=>this.handleOnlineChange(event)} autoComplete='off' checked={leagueData.online}/>
                                <Form.Text className='text-muted'>If your {typeWord} will be played over the Internet and there is no expectation that players ever meet in person, select this option. This helps players interested in online events & leagues find them.</Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Check id="accept_tandc" name="accept_tandc" label="I accept NerdHerder's Terms & Conditions and other Policies" disabled={this.state.updating} onChange={(event)=>this.handleAcceptTermsChange(event)} autoComplete='off' checked={this.state.acceptTerms}/>
                        <div className='text-end'>
                            {termsLink}
                        </div>
                    </Form.Group>

                    <Button type='submit' className="float-end text-capitalize" variant='primary' disabled={disableSubmitButton}>create this {typeWord}</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(NewLeaguePage);
