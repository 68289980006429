import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';
import { isMobileDevice } from '../utilities';

export class NerdHerderToolTip extends React.Component {
    constructor(props) {
        super(props);
        this.isMobile = isMobileDevice();
    }

    render() {
        // no tooltips on mobile
        if (this.isMobile) return(this.props.children);

        return (
            <OverlayTrigger placement={this.props.placement || 'bottom'} trigger={["hover","hover"]} delay={{ show: 500, hide: 300 }} overlay={<Tooltip>{this.props.text}</Tooltip>}>
                {this.props.children}
            </OverlayTrigger>
        );
    }
}

export class NerdHerderToolTipButton extends React.Component {
    constructor(props) {
        super(props);
        this.isMobile = isMobileDevice();
    }

    render() {
        let buttonProps = {...this.props};
        if (buttonProps.hasOwnProperty('tooltipText')) delete buttonProps.tooltipText;
        if (buttonProps.hasOwnProperty('placement')) delete buttonProps.placement;
        if (buttonProps.hasOwnProperty('icon')) delete buttonProps.icon;

        if (this.props.tooltipText && !this.isMobile) {
            if (this.props.icon) {
                return (
                    <OverlayTrigger placement={this.props.placement || 'bottom'} trigger={["hover","hover"]} delay={{ show: 500, hide: 300 }} overlay={<Tooltip>{this.props.tooltipText}</Tooltip>}>
                        <span className="d-inline-block">
                            <Button {...buttonProps}><NerdHerderFontIcon icon={this.props.icon}/></Button>
                        </span>
                    </OverlayTrigger>
                );
            } else {
                return (
                    <OverlayTrigger placement={this.props.placement || 'bottom'} trigger={["hover","hover"]} delay={{ show: 500, hide: 300 }} overlay={<Tooltip>{this.props.tooltipText}</Tooltip>}>
                        <span className="d-inline-block">
                            <Button {...buttonProps}>{this.props.children}</Button>
                        </span>
                    </OverlayTrigger>
                );
            }
        } else if (this.props.icon) {
            return (
                <Button {...buttonProps}><NerdHerderFontIcon icon={this.props.icon}/></Button>
            );
        } else {
            return (
                <Button {...buttonProps}>{this.props.children}</Button>
            );
        }
    }
}

export class NerdHerderToolTipIcon extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }

    render() {
        const popover = 
            <Popover>
                <Popover.Header as="h3">{this.props.title || 'Information'}</Popover.Header>
                <Popover.Body>{this.props.message || 'This is a helpful message!'}</Popover.Body>
            </Popover>
        return (
            <OverlayTrigger trigger="click" placement={this.props.placement || 'bottom'} overlay={popover}>
                <span style={{color:'#cccccc', fontWeight: 'normal'}}><NerdHerderFontIcon icon={this.props.icon || 'flaticon-question-sign-in-a-circle'}/></span>
            </OverlayTrigger>
        );
    }
}
