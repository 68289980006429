import React from 'react';
import withRouter from './withRouter';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Navigate } from 'react-router-dom';
import { CaptchaNotice } from './nerdherder-components/NerdHerderCaptcha';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, setCookie, setLocal, delLocal } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { FormErrorText, getFormErrors, setErrorState, clearErrorState } from './nerdherder-components/NerdHerderFormHelpers';
import { Required } from './nerdherder-components/NerdHerderBadge';

class NewUserPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.pageRef = React.createRef();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            localUser: null,
            errorFeedback: null,
        }
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    render() {
        return(
            <NerdHerderStandardPageTemplate ref={this.pageRef} pageName='new_user' localUser={null}>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                <NewUserCard pageRef={this.pageRef}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class NewUserCard extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.usernameCheckTimeout = null;

        this.state = {
            navigateTo: null,
            localUser: null,
            updating: false,
            ipAddress: null,
            ipZipcode: null,
            ipCountry: null,
            countryList: [],

            formUsername: '',
            formUsernameResultFor: null,
            formUsernameAvailable: null,
            formUsernameMessage: null,
            formPassword: '',
            formMatchPassword: '',
            formName: '',
            formEmail: '',
            formMatchEmail: '',
            formDiscordId: '',
            formZipcode: '',
            formCountry: '',
            formPhone: '',
            formUnder13: true,
            formAcceptTerms: false,

            formErrors: {},
            formValidated: false,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('country-list', null, (d, k)=>this.updateCountryList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('ip-location', null, (d, k)=>this.updateIpLocation(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    checkFreeUsername() {
        this.restApi.genericGetEndpointData('username-available', null, {'username': this.state.formUsername})
            .then(response => {
                this.updateAvailableUsername(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    updateAvailableUsername(response) {
        let isAvailable = null;
        if (response.result === 'available') {
            isAvailable = true;
        } else {
            isAvailable = false;
        }
        this.setState({formUsernameResultFor: response.username, formUsernameAvailable: isAvailable, formUsernameMessage: response.message});
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateCountryList(response, key) {
        console.debug('got country list');
        this.setState({countryList: response});

        // if we have the IP details, and the IP country is in the response, update the form with IP details
        if (this.state.ipCountry !== null && response.includes(this.state.ipCountry)) {
            console.debug('auto set the country and zip');
            this.setState({formZipcode: this.state.ipZipcode});
            this.setState({formCountry: this.state.ipCountry});
        }
    }

    updateIpLocation(response, key) {
        console.debug(`got country ${response.country}`);
        let country = response.details.country_name || '';
        let zipcode = response.details.postal || '';
        this.setState({ipAddress: response.ip, ipCountry: country, ipZipcode: zipcode});

        // if we have the updated country list, and the IP country is in the list, update the form with IP details
        if (this.state.countryList.length > 0 && this.state.countryList.includes(country)) {
            console.debug('auto set the country and zip');
            this.setState({formZipcode: zipcode});
            this.setState({formCountry: country});
        }
    }

    onSubmit(event) {
        console.log('clicked submit button');
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, updating: true});
            const postData = {
                username: this.state.formUsername.trimEnd(),
                password: this.state.formPassword,
                name: this.state.formName.trimEnd(),
                phone: this.state.formPhone.trimEnd(),
                email: this.state.formEmail.trimEnd(),
                zipcode: this.state.formZipcode.trimEnd(),
                discord_id: null,
                longshanks_id: null,
                bandai_id: null,
                konami_id: null,
                wizards_id: null,
                pokemon_id: null,
                country: this.state.formCountry,
                web_push_enabled: false,
                online_interest: false,
                latitude: null,
                longitude: null,
                timezone: null,
                urls: '',
                under_13: this.state.formUnder13

            }
            if (this.state.formDiscordId.length >= 3) {
                postData.discord_id = this.state.formDiscordId.trimEnd();
            }
            this.restApi.genericPostEndpointData('new-user', null, postData)
            .then(response => {
                let loginToken = response.data.login_token;
                let userId = response.data.self.id;
                setCookie('LoginToken', loginToken, 358);
                setLocal('LoginToken', loginToken);
                setLocal('UserId', userId);
                delLocal('FirebaseToken');
                setCookie('RememberMe', true, 358);
                setTimeout(()=>this.setState({navigateTo: `/app/main`, updating: false}), 200);
            }).catch(error => {
                this.setState({updating: false});
                this.formUpdateError(error, null);
            });
        }
    }

    handleUsernameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('username', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('username', {...this.state.formErrors}, 'this username is too short');
        } else {
            if (this.usernameCheckTimeout !== null) clearTimeout(this.usernameCheckTimeout);
            setTimeout(()=>this.checkFreeUsername(), 500);
        }
        this.setState({formUsernameAvailable: null, formUsername: value, formErrors: errorState});
    }

    handlePasswordChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('password', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('password', {...this.state.formErrors}, 'this password is too short');
        }
        this.setState({formPassword: value, formErrors: errorState});
    }

    handleMatchPasswordChange(event) {
        let value = event.target.value;
        this.setState({formMatchPassword: value});
    }

    handleDiscordIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('discord_id', {...this.state.formErrors});
        if (value.length !== 0 && value.length < 3) {
            errorState = setErrorState('discord_id', {...this.state.formErrors}, 'this Discord ID is too short');
        }
        this.setState({formDiscordId: value, formErrors: errorState});
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formName: value, formErrors: errorState});
    }

    handlePhoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('phone', {...this.state.formErrors});
        if (value.length < 8) {
            errorState = setErrorState('phone', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formPhone: value, formErrors: errorState});
    }

    handleZipcodeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('zipcode', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('zipcode', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formZipcode: value, formErrors: errorState});
    }

    handleCountryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('country', {...this.state.formErrors});
        this.setState({formCountry: value, formErrors: errorState});
    }

    handleEmailChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('email', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('email', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formEmail: value, formErrors: errorState});
    }

    handleMatchEmailChange(event) {
        let value = event.target.value;
        this.setState({formMatchEmail: value});
    }

    handleUnder13Change(event) {
        let value = event.target.checked;
        this.setState({formUnder13: !value});
    }

    handleAcceptTermsChange(event) {
        let value = event.target.checked;
        this.setState({formAcceptTerms: value});
    }

    render() {
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo} replace={true}/>);

        const countryOptions = [];
        for (const countryName of this.state.countryList) {
            countryOptions.push(<option key={countryName} value={countryName}>{countryName}</option>)
        }

        let postalCodeText = 'Postal Code';
        if (this.state.formCountry === 'United States') {
            postalCodeText = 'Zipcode';
        }

        let passwordIsOk = false;
        let passwordsMatchElement = null;
        if (this.state.formMatchPassword.length >= 6) {
            if (this.state.formPassword === this.state.formMatchPassword) {
                passwordsMatchElement = <Form.Text className='text-primary' size='sm'>The passwords entered match.</Form.Text>
                passwordIsOk = true;
            } else {
                passwordsMatchElement = <Form.Text className='text-danger' size='sm'><b>The passwords entered do not match...</b></Form.Text>
            }
        }

        let emailIsOk = false;
        let emailsMatchElement = null;
        if (this.state.formMatchEmail.length >= 6) {
            if (this.state.formEmail === this.state.formMatchEmail) {
                emailsMatchElement = <Form.Text className='text-primary' size='sm'>The email addresses entered match.</Form.Text>
                emailIsOk = true;
            } else {
                emailsMatchElement = <Form.Text className='text-danger' size='sm'><b>The email addresses entered do not match...</b></Form.Text>
            }
        }

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // if any item has an error, is empty, or the passwords don't match, then the submit is disabled
        let disableSubmitButton = false;
        if (this.state.formUsernameAvailable === null || this.state.formUsernameAvailable === false ||
            this.state.formUsername.length < 6 ||
            this.state.formName.length < 3 ||
            this.state.formZipcode.length < 3 ||
            this.state.formPassword.length < 6 ||
            this.state.formPhone.length < 8 ||
            this.state.formEmail.length < 6 ||
            this.state.formAcceptTerms === false ||
            hasFormErrors === true ||
            passwordIsOk === false ||
            emailIsOk === false ||
            this.state.updating) {
            disableSubmitButton = true;
        }

        return(
            <NerdHerderStandardCardTemplate title="New User" titleIcon='add-user.png'>
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Username<Required/></Form.Label>
                        <Form.Control id='username' name='username' type="text" disabled={this.state.updating} onChange={(event)=>this.handleUsernameChange(event)} autoComplete='username' value={this.state.formUsername} minLength={6} maxLength={20} required/>
                        <FormErrorText errorId='username' errorState={this.state.formErrors}/>
                        {this.state.formUsernameAvailable === null &&
                        <Form.Text className='text-muted'>This is your main identifier visible to others on the site.</Form.Text>}
                        {this.state.formUsernameAvailable === true && this.state.formUsername.length >= 6 && this.state.formUsernameResultFor === this.state.formUsername &&
                        <Form.Text className='text-primary'>{this.state.formUsernameMessage}</Form.Text>}
                        {this.state.formUsernameAvailable === false && this.state.formUsername.length >= 6 && this.state.formUsernameResultFor === this.state.formUsername &&
                        <Form.Text className='text-danger'>{this.state.formUsernameMessage}</Form.Text>}
                        <div>
                            <Form.Text className='text-muted'><b>Retailers:</b> Create an account for yourself, not for your store or venue.</Form.Text>
                        </div>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>'Real-Life' Name<Required/></Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleNameChange(event)} autoComplete='name' value={this.state.formName} minLength={3} maxLength={20} required/>
                        <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Once a league is running, this is provided to other players to help 'in real life'. Use a name that other players will recognize, plus an initial to reduce confusion for common names (e.g. Tim S). A nickname is also appropriate.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Password<Required/></Form.Label>
                        <Form.Control id='password' name='password' className='mb-1' type="password" onChange={(event)=>this.handlePasswordChange(event)} autoComplete='new-password' value={this.state.formPassword} minLength={6} maxLength={90} required/>
                        <FormErrorText errorId='password' errorState={this.state.formErrors}/>
                        <Form.Label>Confirm Password...</Form.Label>
                        <Form.Control type="password" onChange={(event)=>this.handleMatchPasswordChange(event)} autoComplete='off' value={this.state.formMatchPassword} minLength={6} maxLength={90} required/>
                        {passwordsMatchElement &&
                        <div>
                            {passwordsMatchElement}
                        </div>}
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Email Address<Required/></Form.Label>
                        <Form.Control type="email" disabled={this.state.updating} onChange={(event)=>this.handleEmailChange(event)} autoComplete='email' value={this.state.formEmail} minLength={6} maxLength={90} required/>
                        <FormErrorText errorId='email' errorState={this.state.formErrors}/>
                        <Form.Label>Confirm Email...</Form.Label>
                        <Form.Control type="email" disabled={this.state.updating} onChange={(event)=>this.handleMatchEmailChange(event)} autoComplete='email' value={this.state.formMatchEmail} minLength={6} maxLength={90} required/>
                        {emailsMatchElement && 
                        <div>
                            {emailsMatchElement}
                        </div>}
                        <Form.Text className='text-muted'>Email verification is used to minimize duplicate or bot accounts. We also send updates about the leagues you are participating in. Finally, we use your email address to easily upload lists and rosters with sites like Cerebro MCP and Tabletop Admiral.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Phone Number<Required/></Form.Label>
                        <Form.Control type="tel" disabled={this.state.updating} onChange={(event)=>this.handlePhoneChange(event)} autoComplete='tel' value={this.state.formPhone} minLength={8} maxLength={20} required/>
                        <FormErrorText errorId='phone' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Only your contacts can see your phone number. Feel free to use a bogus number if desired.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{`${postalCodeText} & Country`}<Required/></Form.Label>
                        <Form.Control className='mb-1' type="text" disabled={this.state.updating} onChange={(event)=>this.handleZipcodeChange(event)} autoComplete='postal-code' value={this.state.formZipcode} minLength={3} maxLength={45} required/>
                        <FormErrorText errorId='zipcode' errorState={this.state.formErrors}/>
                        <Form.Select disabled={this.state.updating} onChange={(event)=>this.handleCountryChange(event)} value={this.state.formCountry} required>
                            {countryOptions}
                        </Form.Select>
                        <FormErrorText errorId='country' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Used to find leagues that you may be interested in.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Discord ID</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleDiscordIdChange(event)} autoComplete='off' value={this.state.formDiscordId} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='discord_id' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'><b>Optional:</b> If you have a Discord account this will help others find you there. A user number is no longer required (but may still be included - e.g. BillyBob#7509).</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Check id="under_13" name="under_13" label="I am 13 years old or older" disabled={this.state.updating} onChange={(event)=>this.handleUnder13Change(event)} autoComplete='off' checked={!this.state.formUnder13}/>
                        <Form.Text className='text-muted'>Children are welcome on NerdHerder, but require parent or guardian permission. Don't use a parent's email account when registering children.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Check id="accept_tandc" name="accept_tandc" label={<span>I accept NerdHerder's <a href='/terms' target='_blank'>Terms & Conditions</a> and other Policies</span>} disabled={this.state.updating} onChange={(event)=>this.handleAcceptTermsChange(event)} autoComplete='off' checked={this.state.acceptTerms}/>
                    </Form.Group>

                    <div className='text-end'>
                        <Button type='submit' variant='primary' disabled={disableSubmitButton}>Sign Up!</Button>
                        <CaptchaNotice/>
                    </div>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(NewUserPage);
