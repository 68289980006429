import React from 'react';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export class Truncate extends React.Component {
    constructor(props) {
        super(props);
        this.divRef = React.createRef();

        this.state = {
            needsTruncate: false,
            isTruncated: false,
        }
    }

    componentDidMount() {
        const truncHeight = this.getHeight();
        const node = this.divRef.current;
        const height = node.clientHeight;
        if (height > truncHeight) {
            this.setState({needsTruncate: true, isTruncated: true});
        }
    }

    handleMore() {
        this.setState({isTruncated: false});
    }

    handleLess() {
        this.setState({isTruncated: true});
    }

    getHeight() {
        let height = 100;
        if (this.props.height) height = this.props.height;
        return height;
    }

    getHeightPx() {
        let height = '100px';
        if (this.props.height) height = `${this.props.height}px`;
        return height;
    }

    render() {
        const height=this.getHeightPx();

        if (!this.state.needsTruncate) {
            return (
                <div ref={this.divRef}>         
                    {this.props.children}
                </div>
            );
        } else {
            if (this.state.isTruncated) {
                return (
                    <div ref={this.divRef} style={{maxHeight: height, overflow: 'hidden', overflowWrap: 'break-word', textOverflow: 'ellipsis', position: 'relative'}}>
                        {this.props.children}
                        <Button variant="link" className="p-0 bg-white" onClick={()=>this.handleMore()} style={{position: 'absolute', bottom: '3px', right:'5px'}}>
                            <span className="flaticon-cross-in-square"/>
                        </Button>
                    </div>
                );
            } else {
                return (
                    <div ref={this.divRef} style={{position: 'relative'}}>
                        {this.props.children}
                        <Button variant="link" className="p-0 bg-white" onClick={()=>this.handleLess()} style={{position: 'absolute', bottom: '3px', right:'5px'}}>
                            <span className="flaticon-minus-in-square-outlined-interface-button"/>
                        </Button>
                    </div>
                );
            }
        }
        
    }
}
