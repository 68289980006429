import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { Navigate } from 'react-router-dom';
import { Howl } from 'howler';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub } from '../NerdHerder-RestPubSub';
import { NerdHerderRealtimePushChannel } from '../NerdHerder-RealtimePushChannel';
import { NerdHerderHeader } from './NerdHerderHeader';
import { GameListItem } from './NerdHerderListItems';
import { NerdHerderPleaseWaitModal } from './NerdHerderModals';
import { getRandomString, getStaticStorageSoundFilePublicUrl, messageServiceWorker, getCookie } from '../utilities';

export class NerdHerderStandardPageTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.toastContainer = React.createRef();
        this.restApi = new NerdHerderRestApi();

        this.state = {
            waitingForServiceWorkerUpdate: false,
        }

        if ('serviceWorker' in navigator && !(window.location.hostname === 'localhost' && window.location.port === '3000')) {
            navigator.serviceWorker.onmessage = (event) => {
                if (event.data && (event.data.type === 'SW_UPDATE_DETECTED')) {
                    console.debug('service worker update detected in react');
                    this.setState({waitingForServiceWorkerUpdate: true});
                }
            };
        }
    }

    componentDidMount() {
        if (!this.props.localUser) return;
        // this is for web push messages - if we're already subscribed the service worker won't do it again
        if (this.props.localUser.web_push_enabled && getCookie('EnableDeviceWebPush', false)) {
            messageServiceWorker({type: 'PUSH_SUBSCRIBE'});
        }
    }

    getToastContainer() {
        return this.toastContainer.current;
    }

    render() {        
        let headerLocalUser = this.props.localUser;
        if (this.props.disableHeader) headerLocalUser = null;

        // if there is an incoming service worker update, keep the user from doing anything until the update is complete
        // note: the page is refreshed when the service worker updates
        let serviceWorkerUpdate = false;
        let propsChildren = this.props.children;
        if (this.state.waitingForServiceWorkerUpdate) {
            serviceWorkerUpdate = true;
            propsChildren = null;
            headerLocalUser = null;
        }

        let xlSize = this.props.baseWidth || 6;
        let lgSize = xlSize + 2;
        let mdSize = lgSize + 2;
        let smSize = mdSize + 2;
        let xsSize = smSize + 2;
        if (xlSize > 12) xlSize = 12;
        if (lgSize > 12) lgSize = 12;
        if (mdSize > 12) mdSize = 12;
        if (smSize > 12) smSize = 12;
        if (xsSize > 12) xsSize = 12;
        
        return (
            <div>
                <NerdHerderHeader localUser={headerLocalUser} headerSelection={this.props.headerSelection} dropdownSelection={this.props.dropdownSelection} navPath={this.props.navPath} headerHidden={this.props.headerHidden}/>
                <NerdHerderToastContainer ref={this.toastContainer} labelCallbacks={this.props.labelCallbacks} toastsHidden={this.props.toastsHidden} disableRefresh={this.props.disableRefresh} localUser={this.props.localUser} league={this.props.league}/>
                <Container fluid={this.props.fluid || false} className="py-2">
                    {serviceWorkerUpdate &&
                    <NerdHerderPleaseWaitModal title='Software Update' userFeedback='NerdHerder is doing an automatic software update...please wait'/>}
                    <Row className="justify-content-evenly justify-content-center align-items-center h-100">
                        <Col xl={xlSize} lg={lgSize} md={mdSize} sm={smSize} xs={xsSize}>
                            {propsChildren}
                        </Col>
                    </Row>
                </Container>  
            </div>
        )
    }
}

export class NerdHerderTwoColumnPageTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.toastContainer = React.createRef();
        this.restApi = new NerdHerderRestApi();

        this.state = {
            waitingForServiceWorkerUpdate: false,
        }

        if ('serviceWorker' in navigator && !(window.location.hostname === 'localhost' && window.location.port === '3000')) {
            navigator.serviceWorker.onmessage = (event) => {
                if (event.data && (event.data.type === 'SW_UPDATE_DETECTED')) {
                    console.debug('service worker update detected in react');
                    this.setState({waitingForServiceWorkerUpdate: true});
                }
            };
        }
    }

    componentDidMount() {
        if (!this.props.localUser) return;
        // this is for web push messages - if we're already subscribed the service worker won't do it again
        if (this.props.localUser.web_push_enabled && getCookie('EnableDeviceWebPush', false)) {
            messageServiceWorker({type: 'PUSH_SUBSCRIBE'});
        }
    }

    getToastContainer() {
        return this.toastContainer.current;
    }

    render() {
        let headerLocalUser = this.props.localUser;
        if (this.props.disableHeader) headerLocalUser = null;

        let leftChildren = [];
        let rightChildren = [];
        let childArray = React.Children.toArray(this.props.children);
        for (const child of childArray) {
            if (child.props.nerdHerderTwoColumnPageTemplateSide === 'right') {
                rightChildren.push(child);
            } else {
                leftChildren.push(child);
            }
        }

        const leftColumnSize = this.props.leftColumnSize || 6
        const rightColumnSize = 12 - leftColumnSize;

        // if there is an incoming service worker update, keep the user from doing anything until the update is complete
        // note: the page is refreshed when the service worker updates
        let serviceWorkerUpdate = false;
        if (this.state.waitingForServiceWorkerUpdate) {
            serviceWorkerUpdate = true;
            leftChildren = null;
            rightChildren = null;
            headerLocalUser = null;
        }

        return (
            <div>
                <NerdHerderHeader localUser={headerLocalUser} headerSelection={this.props.headerSelection} dropdownSelection={this.props.dropdownSelection} navPath={this.props.navPath} headerHidden={this.props.headerHidden}/>
                <NerdHerderToastContainer ref={this.toastContainer} labelCallbacks={this.props.labelCallbacks} allowLocalUserRefresh={this.props.allowLocalUserRefresh} toastsHidden={this.props.toastsHidden} disableRefresh={this.props.disableRefresh} localUser={this.props.localUser} league={this.props.league}/>
                <Container fluid className="py-2">
                    {serviceWorkerUpdate &&
                    <NerdHerderPleaseWaitModal title='Software Update' userFeedback='NerdHerder is doing an automatic software update...please wait'/>}
                    <Row className="justify-content-evenly justify-content-center h-100">
                        <Col lg={leftColumnSize}>
                            {leftChildren}
                        </Col>
                        <Col lg={rightColumnSize}>
                            {rightChildren}
                        </Col>
                    </Row>
                </Container>  
            </div>
        )
    }
}

export class NerdHerderToastContainer extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();

        this.channelNames = ['announcements'];
        this.toastsDict = {};
        this.pusherConnectionTimeout = null;

        if (this.props.localUser) {
            this.channelNames.push(`private-push-user-${this.props.localUser.id}`);
        }
        
        if (this.props.league) {
            if (this.props.league.push_channel_name) this.channelNames.push(this.props.league.push_channel_name);
            if (this.props.league.chat_channel_name) this.channelNames.push(this.props.league.chat_channel_name);
            if (this.props.league.pres_channel_name) this.channelNames.push(this.props.league.pres_channel_name);
        }

        // if no label callbacks were passed in, default to an empty dict
        this.labelCallbacks = this.props.labelCallbacks || {};

        this.state = {
            pusherConnectionComplete: false,
            navigateTo: null,
            toastHashList: [],
        }
    }

    getHashKey(message) {
        return (`${message.user_id}:${message.league_id}:${message.tournament_id}:${message.tournament_round_id}:${message.event_id}:${message.game_id}:${message.title}:${message.message_string}`);
    }

    subscribeToRealtimePushes() {
        for (const channelName of this.channelNames) {
            this.channel = new NerdHerderRealtimePushChannel(channelName);
            
            // for announcements only
            if (channelName === 'announcements') {
                this.channel.bind('broadcast', (eventData)=>this.onBroadcast(eventData));
            } else if (channelName.includes('push-')) {
                this.channel.bind('league-update', (eventData)=>this.onLeagueUpdateEvent(eventData));
                this.channel.bind('league-chat-update', (eventData)=>this.onLeagueChatUpdateEvent(eventData));
                this.channel.bind('tournament-update', (eventData)=>this.onTournamentUpdateEvent(eventData));
                this.channel.bind('event-update', (eventData)=>this.onEventUpdateEvent(eventData));
                this.channel.bind('game-update', (eventData)=>this.onGameUpdateEvent(eventData));
                this.channel.bind('game-schedule', (eventData)=>this.onGameScheduleEvent(eventData));
                this.channel.bind('user-update', (eventData)=>this.onUserUpdateEvent(eventData));
                this.channel.bind('user-chat-update', (eventData)=>this.onUserChatUpdateEvent(eventData));
                this.channel.bind('user-navigate', (eventData)=>this.onUserNavigateEvent(eventData));
            } else if (channelName.includes('presence-')) {
                this.channel.bind('presence-update', (eventData)=>this.onPresenceUpdateEvent(eventData));
            }
        }
        this.setState({pusherConnectionComplete: true});
    }

    doMessageRequiredRefreshes(message) {
        // if there is stuff to refresh, do it, but not if page has disabled refreshes or if the message is from this user
        if (message.refresh && !this.props.disableRefresh && (message.from_user_id !== this.props.localUser.id || this.props.allowLocalUserRefresh)) {
            for (const refreshString of message.refresh) {
                this.restPubSub.refresh(refreshString, null);
            }
        }
    }

    doMessageLabelCallbacks(message) {
        // if there are callbacks to do - do those now
        if (message.label) {
            if (this.labelCallbacks.hasOwnProperty(message.label)) {
                this.labelCallbacks[message.label](message.label, message);
            }
        }
    }

    onBroadcast(eventData) {
        if (!this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onPresenceUpdateEvent(eventData) {
        console.debug('got presence channel event');
    }

    onLeagueUpdateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);
        
        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onTournamentUpdateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);
        
        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onEventUpdateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);
        
        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onGameUpdateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);
        
        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onGameScheduleEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);
        
        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onUserUpdateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);
        
        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onLeagueChatUpdateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);
        
        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onUserChatUpdateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // check for refreshes
        this.doMessageRequiredRefreshes(message);

        // check for label callbacks
        this.doMessageLabelCallbacks(message);

        // don't create user chat toasts when already viewing the messages page
        if (window.location.pathname.includes('/app/messages')) return;

        // if the update has a message to display, create the toast if not hidden
        if (message.message_string && !this.props.toastsHidden) this.createGenericToast(eventData);
    }

    onUserNavigateEvent(eventData) {
        const message = eventData;
        // if this message was blasted to multiple recipients but it's not for this user just get out
        if (message.user_id && message.user_id !== this.props.localUser.id) return;

        // set navigate to, this will automatically redirect the user when react updates
        this.setState({navigateTo: eventData.url});
    }

    createGenericToast(eventData) {
        const message = eventData;
        const hashKey = this.getHashKey(message);
        const reactKey = getRandomString(5);
        const newToast = <NerdHerderToast key={reactKey} message={message} disableRefresh={this.props.disableRefresh} localUser={this.props.localUser}/>
        this.setState((state) => {
            const newToastHashList = [...state.toastHashList]
            // remove any duplicates
            if (newToastHashList.includes(hashKey)) {
                const index = newToastHashList.indexOf(hashKey);
                if (index > -1) newToastHashList.splice(index, 1);
            }
            newToastHashList.push(hashKey);
            this.toastsDict[hashKey] = newToast;
            return {toastHashList: newToastHashList}
        });
    }

    render() {
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);
        if (!this.props.localUser) return(null);
        if (!this.state.pusherConnectionComplete && this.pusherConnectionTimeout === null) {
            this.pusherConnectionTimeout = setTimeout(()=>this.subscribeToRealtimePushes(), 100);
            return(null);
        }
        const toastList = [];
        for (const hashKey of this.state.toastHashList) {
            toastList.push(this.toastsDict[hashKey]);
        }
        return (
            <ToastContainer position="bottom-end" className="p-1 position-fixed" style={{zIndex: 3}}>
                {toastList}
            </ToastContainer>
        );
    }
}

export class NerdHerderToast extends React.Component {
    constructor(props) {
        super(props);

        this.timeoutId = null;

        this.state = {
            navigateTo: null,
            recvTime: new Date(),
            fancyBorderIndex: 0,
            show: true,
            message: this.props.message,
        }

        this.sound = new Howl({src: [getStaticStorageSoundFilePublicUrl('/notification.mp3')], preload: true});
        
    }

    componentDidMount() {
        this.sound.play();
    }

    onClick() {
        if (this.state.message.url) {
            // if it's an external url - open in new window
            if (this.state.message.url.includes('http')) {
                window.open(this.state.message.url, '_blank');
            }
            // if it's a relative url - just do a navigate
            else {
                let url = this.state.message.url;
                if (this.state.message.tab && this.state.message.focus) {
                    url += `?focus=${this.state.message.focus}&tab=${this.state.message.tab}`
                }
                else if (this.state.message.focus) {
                    url += `?focus=${this.state.message.focus}`
                }
                else if (this.state.message.tab) {
                    url += `?tab=${this.state.message.tab}`
                }
                this.setState({navigateTo: url});
            }
            
        } 
    }

    refreshTimeMessage() {
        this.timeoutId = null;
        if (this.state.show) {
            this.setState({show: true});
        }
    }

    refreshFancyBorderStyle(index) {
        this.setState({fancyBorderIndex: index + 1});
    }

    render() {
        if (this.state.show && this.state.navigateTo) {
            setTimeout(()=>this.setState({show: false}));
            return(<Navigate to={this.state.navigateTo}/>);
        }

        const viewTime = new Date();
        const milliseconds = viewTime.getTime() - this.state.recvTime.getTime();
        const seconds = Math.floor(milliseconds / 1000);

        let timeMessage = 'just now';
        if (seconds < 10) timeMessage = 'just now';
        else if (seconds < 30) timeMessage = `seconds ago`;
        else if (seconds < 60) timeMessage = `${seconds} seconds ago`;
        else if (seconds < 120) timeMessage = `a minute ago`;
        else if (seconds < 3600) timeMessage = `${Math.floor(seconds/60)} minutes ago`;
        else if (seconds < 7200) timeMessage = `a hour ago`;
        else if (seconds < 86400) timeMessage = `${Math.floor(seconds/3600)} hours ago`;
        else if (seconds < 172800) timeMessage = 'a day ago';
        else timeMessage = 'days ago';

        if (this.state.show && this.timeoutId === null) this.timeoutId = setTimeout(()=>this.refreshTimeMessage(), 10000);

        let variant = 'light';
        if (this.state.message.variant) variant = this.state.message.variant;

        let listItem = null;
        if (this.state.show) {
            if (this.state.message.game_id !== null && this.state.message.title === 'Game Review' || this.state.message.title === 'Game Schedule') {
                listItem = <GameListItem key={`game-${this.state.message.game_id}`} gameId={this.state.message.game_id} league={this.props.league} localUser={this.props.localUser}/>
            }
        }

        const extraStyle = {};
        switch (this.state.fancyBorderIndex) {
            case 0:
                extraStyle.border = '3px solid #0d6efd';
                setTimeout(()=>this.refreshFancyBorderStyle(this.state.fancyBorderIndex), 50);
                break;
            case 1:
                extraStyle.border = '2px solid #0d6efd';
                setTimeout(()=>this.refreshFancyBorderStyle(this.state.fancyBorderIndex), 50);
                break;
            case 2:
                extraStyle.border = '1px solid #0d6efd';
                setTimeout(()=>this.refreshFancyBorderStyle(this.state.fancyBorderIndex), 100);
                break;
            default:
        }

        return (
            <Toast bg={variant} show={this.state.show} style={extraStyle} onClose={()=>this.setState({show: false})}>
                <Toast.Header>
                    <strong className="me-auto">{this.state.message.title}</strong>
                    <small className="text-muted">{timeMessage}</small>
                </Toast.Header>
                <Toast.Body onClick={()=>this.onClick()}>
                    {this.state.message.message_string}
                    {listItem}
                    {this.props.children}
                </Toast.Body>
            </Toast>
        );
    }
}
