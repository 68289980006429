import React from 'react';
import withRouter from './withRouter';
import Alert from 'react-bootstrap/Alert';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';

class ModelComparisonPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            localUser: null,
            firebaseSigninComplete: false,
        }

        this.apiName = null;
        this.apiIndex = null;

        if (this.props.query.get('api')) this.apiName = this.props.query.get('api');
        if (this.props.query.get('index')) this.apiIndex = this.props.query.get('index');

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.setState({firebaseSigninComplete: true}), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k)=>{this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API or Firebase access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    render() {
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);
        if (this.apiName === null) return(<NerdHerderErrorModal errorFeedback='Missing api in query params'/>);
        if (this.apiName !== 'self' && this.apiIndex === null) return(<NerdHerderErrorModal errorFeedback='Missing index in query params'/>);

        return(
            <NerdHerderStandardPageTemplate pageName='test' headerSelection='test' localUser={this.state.localUser}>
                <DifferencesCard localUser={this.state.localUser} apiName={this.apiName} apiIndex={this.apiIndex}/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class DifferencesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='DifferencesCard'>
                <DifferencesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class DifferencesCardInner extends React.Component {

    constructor(props) {
        super(props);

        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            restApiResponse: null,
            firestoreApiResponse: null,
            restApiErrorAlert: null,
            firestoreApiErrorAlert: null,
        }
    }

    componentDidMount() {
        let fsApiName = this.props.apiName;
        let fsApiIndex = this.props.apiIndex;
        switch(this.props.apiName) {
            case 'self':
                fsApiName = 'self';
                fsApiIndex = this.props.localUser.id;
                break;
            case 'user':
                fsApiName = 'users';
                break;
            case 'league':
                fsApiName = 'leagues';
                break;
            case 'event':
                fsApiName = 'events';
                break;
            case 'tournament':
                fsApiName = 'tournaments';
                break;
            case 'tournament-round':
                fsApiName = 'tournament-rounds';
                break;
            case 'game':
                fsApiName = 'games';
                break;
            case 'topic':
                fsApiName = 'topics';
                break;
            default:
        }

        let sub = this.restPubSub.subscribe(this.props.apiName, this.props.apiIndex, (d,k)=>this.updateRestApiResult(d,k), (e,k)=>this.restApiError(e,k), this.apiIndex);
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeToFirestore(fsApiName, fsApiIndex, (d,k)=>this.updateFirestoreResult(d,k), (e,k)=>this.firestoreApiError(e,k), this.apiIndex);
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateRestApiResult(data, key) {
        this.setState({restApiResponse: data});
        console.log('REST API RESPONSE...');
        console.log(data);
    }

    restApiError(error, key) {
        let alert = <Alert variant='error'>Got Rest API Error</Alert>
        this.setState({restApiErrorAlert: alert});
        console.error(error);
    }

    updateFirestoreResult(data, key) {
        this.setState({firestoreApiResponse: data});
        console.log('FS API RESPONSE...');
        console.log(data);
    }

    firestoreApiError(error, key) {
        let alert = <Alert variant='danger'>Got Firestore API Error</Alert>
        this.setState({firestoreApiErrorAlert: alert});
        console.error(error);
    }

    compareObjects(rows, restObj, fsObj, label) {
        for (const [key, restValue] of Object.entries(restObj)) {
            let fullKey = `${label}.${key}`;
            let fullLabel = `${label}/${key}`;
            
            if (fsObj.hasOwnProperty(key)) {
                let fsValue = fsObj[key];
                if (typeof fsValue !== typeof restValue) {
                    rows.push(<div key={fullKey}>{fullKey}: REST is type {typeof restValue}, FS is type {typeof fsValue}</div>);
                    continue;
                }
                if (restValue === null) {
                    if (restValue !== null) {
                        rows.push(<div key={fullKey}>{fullKey}: REST is null, FS is not null</div>);
                    }
                    continue;
                }
                if (typeof restValue === 'object') {
                    if (Array.isArray(restValue)) {
                        if (!Array.isArray(fsValue)) {
                            rows.push(<div key={fullKey}>{fullKey}: REST is an array, FS is not</div>);
                            continue;
                        }
                        if (restValue.length !== fsValue.length) {
                            rows.push(<div key={fullKey}>{fullKey}: REST is an array length {restValue.length}, FS is an array length {fsValue.length}</div>);
                            continue;
                        }
                        for (let i=0; i<restValue.length; i++) {
                            let restArrayVal = restValue[i];
                            let fsArrayVal = fsValue[i];
                            let arrayLabel = `${fullLabel}[${i}]`;
                            let arrayKey = `${fullKey}[${i}]`;
                            if (typeof restArrayVal === 'object') {
                                this.compareObjects(rows, restArrayVal, fsArrayVal, arrayLabel);
                            } else {
                                if (restArrayVal !== fsArrayVal) {
                                    rows.push(<div key={arrayKey}>{fullKey}: REST is {restValue}, FS is {fsValue}</div>);
                                }
                            }
                        }
                    } else {
                        this.compareObjects(rows, restValue, fsValue, fullLabel);
                    }
                    continue;
                }
                if (fsValue !== restValue) {
                    rows.push(<div key={fullKey}>{fullKey}: REST is {restValue}, FS is {fsValue}</div>);
                    continue
                }
            } else {
                rows.push(<div key={fullKey}>{fullKey}: FS is missing this key</div>)
            }
        }
    }

    render() {
        let comparisonDetails = null;
        const rows = [];
        if (this.state.firestoreApiResponse && this.state.restApiResponse) {
            const fsResponse = this.state.firestoreApiResponse;
            const restResponse = this.state.restApiResponse;
            this.compareObjects(rows, restResponse, fsResponse, 'root');
            comparisonDetails = <div><p>Got both responses...</p>{rows}</div>
        } else if (this.state.firestoreApiResponse) {
            comparisonDetails = <div><p>Waiting on REST API response.</p></div>
        } else if (this.state.restApiResponse) {
            comparisonDetails = <div><p>Waiting on FS response.</p></div>
        } else {
            comparisonDetails = <div><p>Waiting on REST and FS responses.</p></div>
        }

        return (
            <NerdHerderStandardCardTemplate id='differences' title='Differences'>
                {this.state.restApiResponse && this.state.firestoreApiResponse && this.state.restApiErrorAlert === null && this.state.firestoreApiErrorAlert === null && rows.length === 0 &&
                <Alert variant='primary'>Firestore and REST API responses are identical</Alert>}
                {this.state.restApiErrorAlert}
                {this.state.firestoreApiErrorAlert}
                {comparisonDetails}
            </NerdHerderStandardCardTemplate>
        );
    }
}

export default withRouter(ModelComparisonPage);
