import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { UnitTestPage } from './unit_test_page';
import FirebaseTestPage from './firebase_test_page';
import TestPage from './test_page';
import NewUserPage from './newuser_page';
import NewLeaguePage from './newleague_page';
import NewVenuePage from './newvenue_page';
import LoginPage from './login';
import LogoutPage from './logout';
import ErrorPage from './error_page';
import MainPage from './main_page';
import ProfilePage from './profile_page';
import BiometricsPage from './biometrics_page';
import ManageCoppaPage from './coppa_management_page';
import LeaguePage from './league_page';
import TournamentPage from './tournament_page';
import TournamentTimerPage from './tournament_timer_page';
import TournamentCheckinPage from './tournament_checkin_page';
import EventPage from './event_page';
import ManageLeaguePage from './league_management_page';
import ManageTournamentPage from './tournament_management_page';
import ManageVenuePage from './venue_management_page';
import GamesPage from './game_page';
import TopicPage from './topic_page';
import ContactsPage from './contacts_page';
import MessagesPage from './messages_page';
import HomePage from './home_page';
import FeaturesPage from './features_page';
import QrPage from './qr_page';
import PaymentCompletePage from './payment_complete_page';
import ModelComparisonPage from './response_comparison_page';
import 'bootstrap/dist/css/bootstrap.css';
import './static/league_extra.css';
import './static/dropzone.min.css';
import './static/calendar.css';
import './static/icons/web_interface/filled/font/flaticon.css'

export class App extends React.Component {
    constructor(props) {
        super(props);

        // we check this a lot - keep local copy
        const hostname = window.location.hostname;
        
        // if running in NPM on localhost port 3000, set flag for NPM development
        this.npmDev = false;
        if (hostname === 'localhost' && window.location.port === '3000') {
            this.npmDev = true;
        }

        this.redirectToWww = false;
        if (hostname.includes('nerdherder.app') && !hostname.includes('www.nerdherder.app')) {
            console.warn('You should use www.nerdherder.app instead of the raw domain');
            this.redirectToWww = true;
        } 

        this.restApi = new NerdHerderRestApi();
    }

    render() {

        if (this.redirectToWww) {
            const path = window.location.pathname;
            window.location.replace(`https://www.nerdherder.app${path}`);
        }

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/app/firebase-test-test" element={ <FirebaseTestPage /> } />
                    <Route path="/app/unit-test-test" element={ <UnitTestPage /> } />
                    <Route path="/app/test-test-test" element={ <TestPage /> } />
                    <Route path="/app/response-check" element={ <ModelComparisonPage/> } />

                    <Route path="/" element= { <Navigate to="/app/homepage" replace={true}/> } />
                    <Route path="/app" element= { <Navigate to="/app/homepage" replace={true}/> } />
                    <Route path="/app/homepage" element= { <HomePage /> } />
                    <Route path="/app/errorpage" element={ <ErrorPage/> } />
                    <Route path="/app/features" element={ <FeaturesPage/> } />
                    <Route path="/app/qrpage" element={ <QrPage/> } />
                    <Route path="/app/newuser" element={ <NewUserPage/> } />
                    <Route path="/app/newleague" element={ <NewLeaguePage/> } />
                    <Route path="/app/newvenue" element={ <NewVenuePage/> } />
                    <Route path="/app/login" element={ <LoginPage/> } />
                    <Route path="/app/logout" element={ <LogoutPage/> } />
                    <Route path="/app/main" element={ <MainPage/> } />
                    <Route path="/app/profile" element={ <ProfilePage/> } />
                    <Route path="/app/biometrics" element={ <BiometricsPage/> } />
                    <Route path="/app/parents" element={ <ManageCoppaPage/> } />
                    <Route path="/app/contacts" element={ <ContactsPage/> } />
                    <Route path="/app/messages" element={ <MessagesPage/> } />
                    <Route path="/app/message/:messageId" element={ <MessagesPage/> } />
                    <Route path="/app/games" element={ <GamesPage/> } />
                    <Route path="/app/event/:eventId" element={ <EventPage/> } />
                    <Route path="/app/tournament/:tournamentId" element={ <TournamentPage/> } />
                    <Route path="/app/managetournament/:tournamentId" element={ <ManageTournamentPage/> } />
                    <Route path="/app/league/:leagueId" element={ <LeaguePage/> } />
                    <Route path="/app/manageleague/:leagueId" element={ <ManageLeaguePage/> } />
                    <Route path="/app/managevenue/:venueId" element={ <ManageVenuePage/> } />
                    <Route path="/app/topic/:topicId" element={ <TopicPage/> } />
                    <Route path="/app/tournamenttimer/:tournamentId" element={ <TournamentTimerPage/> } />
                    <Route path="/app/tournamentcheckin/:tournamentId" element={ <TournamentCheckinPage/> } />
                    <Route path="/app/stripepaymentcomplete" element={ <PaymentCompletePage/> } />
                    <Route path="/app/*" element={ <Navigate to="/app/main" replace={true}/> } />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
