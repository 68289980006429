import React from 'react';
import withRouter from './withRouter';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Collapse from 'react-bootstrap/esm/Collapse';
import Calendar from 'react-calendar';
import { DateTime, IANAZone } from 'luxon';
import he from 'he';
import { EmailShareButton, FacebookShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton} from 'react-share';
import { EmailIcon, FacebookIcon, RedditIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { NerdHerderFontIcon, NerdHerderMapIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { TournamentContainer } from './tournament_page';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderUserProfileModal, NerdHerderAddGameModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderBadge, NerdHerderBadgeInjector } from './nerdherder-components/NerdHerderBadge';
import { NerdHerderQrCode } from './nerdherder-components/NerdHerderQrCode';
import { NerdHerderEditPostModal, NerdHerderConfirmModal, NerdHerderPasswordModal, NerdHerderStripePaymentModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderJoinModelRestApi } from './NerdHerder-JoinModelRestApi';
import { NerdHerderDataModelFactory, NerdHerderUserLeague } from './nerdherder-models';
import { handleGlobalRestError, reloadPage, delCookieAfterDelay, getUrlFromText } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderLeaguePostCard, CreateLeaguePost } from './nerdherder-components/NerdHerderMessageCards';
import { LeaguePollCard } from './nerdherder-components/NerdHerderPolling';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { ChatCard } from './nerdherder-components/NerdHerderChat';
import { UserListItem, FileListItem, LeagueListItem, EventListItem, TournamentListItem, GameListItem, VenueListItem, DateListItem } from './nerdherder-components/NerdHerderListItems';
import { TableOfUsers } from './nerdherder-components/NerdHerderTableHelpers';
import { NerdHerderListUploadCard } from './nerdherder-components/NerdHerderListUploadCard';
import { NerdHerderRandomSelectorCard } from './nerdherder-components/NerdHerderRandomSelectorCard';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { NerdHerderTour } from './nerdherder-components/NerdHerderTour';
import { LinkifyText } from './nerdherder-components/NerdHerderFormHelpers';
import { NerdHerderToolTip } from './nerdherder-components/NerdHerderToolTip';
import { OpenGraphCard } from './nerdherder-components/NerdHerderOpenGraph';

class LeaguePage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.managersPubSubPool = new NerdHerderRestPubSubPool();
        this.postsPubSubPool = new NerdHerderRestPubSubPool();
        this.tournamentsPubSubPool = new NerdHerderRestPubSubPool();
        this.pageTemplateRef = React.createRef();
        this.leagueNavBarRef = React.createRef();
        this.gameTabRef = React.createRef();
        this.scrollFunction = null;

        // discard any existing subs
        this.restPubSub.clear();

        this.tourSteps = [
            {
                target: '#tour-info',
                disableBeacon: true,
                placement: 'bottom',
                title: 'League / Event Concept',
                content: <span>You are viewing a <b>League</b> or <b>Event</b> - which may be a multi-week league, a tournament, a set of narrative events...or any combination thereof.<br/><br/>Essentially, this is a sandbox and the organizers can run whatever they want!</span>
            },
            {
                target: '#topper-basics',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Basics',
                content: <span>Here you have the name & summary of the league or event, what it's all about, how far it has progressed, etc.</span>
            },
            {
                target: '#topper-topic-id',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Topic',
                content:
                    <div>
                        <p>
                            Leagues/events have a single topic - ususally the game system (e.g. Warmachine or Kill Team) but it can also be more abstract (e.g. board games or hobby)
                        </p>
                        <p>
                            Clicking the topic will take you to a summary of the topic - including global rankings (if applicable)
                        </p>
                    </div>
            },
            {
                target: '#topper-schedule-venue-contact-organizers',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Details',
                content:
                    <div>
                        <p>
                            This is the schedule, venue (where games are played), and how to contact the oranizers
                        </p>
                        <p>
                            Clicking the <span className='text-primary'><NerdHerderFontIcon icon='flaticon-placeholder-filled-tool-shape-for-maps'/></span> will open Google Maps and take you to the venue
                        </p>
                    </div>
            },
            {
                target: '#topper-organizers',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Organizers',
                content:
                    <div>
                        <p>
                            The organizers are in charge - they set the rules and make sure the league runs smoothly
                        </p>
                        <p>
                            Clicking one will open their profile - from there you can message them if needed
                        </p>
                    </div>
            },
            {
                target: '#tab-container-nav',
                disableBeacon: true,
                placement: 'top',
                title: 'League Navigation Bar',
                content:
                    <div>
                        <p>
                            These are the tabs for navigating within the league or event
                        </p>
                        <p>
                            <i>Once things are up and running, all the interesting stuff is in here!</i>
                        </p>
                    </div>
            },
            {
                target: '#tab-container-tab-info',
                disableBeacon: true,
                placement: 'top',
                title: 'Announcements & Social',
                content:
                    <div>
                        <p>
                            All of the posts, images, and polls are in the Info Tab - it has a social-media feel to it
                        </p>
                        <p>
                            If the league/event uses <b>Google Sheets</b> for exotic scoring formats, that's also in this tab
                        </p>
                    </div>
            },
            {
                target: '#tab-container-tab-join',
                disableBeacon: true,
                placement: 'top',
                title: 'Joining & Payments',
                content: 
                    <div>
                        <p>
                            Used to show interest and <b>Join</b> the league or event
                        </p>
                        <p>
                            If there is a <b>Registration Fee</b>, that can be paid online here
                        </p>
                    </div>
            },
            {
                target: '#tab-container-tab-event',
                disableBeacon: true,
                placement: 'top',
                title: 'Calendar & Minor Events',
                content:
                    <div>
                        <p>
                            A calendar with the dates, games, tournaments, and minor events is shown in this tab
                        </p>
                        <p>
                            If the league or event has <b>Narrative Events</b> planned, they will be in here
                        </p>
                    </div>
            },
            {
                target: '#tab-container-tab-game',
                disableBeacon: true,
                placement: 'top',
                title: 'Tournaments & Games',
                content:
                    <div>
                        <p>
                            The <b>Games</b> and <b>Tournaments</b> are here
                        </p>
                        <p>
                            <i>Note: Some leagues/events have multiple tournaments - and not every player is necessarily in every tournament!</i>
                        </p>
                        <p>
                            Statistics for casual games are also here
                        </p>
                    </div>
            },
            {
                target: '#tab-container-tab-chat',
                disableBeacon: true,
                placement: 'top',
                title: 'Chat',
                content:
                    <div>
                        <p>
                            Each league/event has a real-time <b>Chat</b> channel - works the same as Discord / FB Messenger
                        </p>
                        <p>
                            They don't all use the chat channel, it is possible for the organizers to disable it
                        </p>
                    </div>
            },
            {
                target: '#tab-container-tab-players',
                disableBeacon: true,
                placement: 'top',
                title: 'Files & Players',
                content:
                    <div>
                        <p>
                            Organizers can upload <b>Files</b> (e.g. rules, special scenarios, etc.)
                        </p>
                        <p>
                            It is also possible for the organizers to add external <b>Links</b> (e.g. Google Docs, publisher's official site, etc.)
                        </p>
                        <p>
                            Finally, all the players, managers, waitlisted users, and interested <b>potential players</b> are listed here. If you are having trouble remembering who's who...this is where you figure that out
                        </p>
                    </div>
            },
        ];

        this.state = {
            navbarSticky: false,
            navbarCollapseShow: true,
            hasUsersLeagues: false,
            localUsersLeagues: null,
            firebaseSigninComplete: false,
            localUser: null,
            leagueId: this.props.params.leagueId,
            league: null,
            alerts: null,
            managers: {},
            posts: {},
            tournaments: {},
        }

        this.defaultActiveKey = 'info';
        if (this.props.query.get('tab')) this.defaultActiveKey = this.props.query.get('tab');
        if (!['info', 'join', 'event', 'game', 'chat', 'players'].includes(this.defaultActiveKey)) this.defaultActiveKey = 'info';

        this.clearTabAlertsTimer = null;
        this.initialClearInfoPageAlerts = true;

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k)=>this.updateLocalUser(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('league', this.state.leagueId, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('league-alerts', this.state.leagueId, (d, k)=>this.updateAlerts(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('self-user-league', this.state.leagueId, (d, k)=>this.updateUsersLeagues(d, k), (e, a)=>this.errorUsersLeagues(e, a));
        this.restPubSubPool.add(sub);

        // bind the scroll function
        this.scrollFunction = this.handleScroll.bind(this);
        window.addEventListener('scroll', this.scrollFunction);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
        this.managersPubSubPool.unsubscribe();
        this.postsPubSubPool.unsubscribe();
        this.tournamentsPubSubPool.unsubscribe();

        if (this.scrollFunction) window.removeEventListener('scroll', this.scrollFunction);
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateAlerts(alertData, key) {
        this.setState({alerts: alertData});
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league});

        // update the managers
        this.managersPubSubPool.unsubscribe();
        for (const managerUserId of league.manager_ids) {
            const sub = this.restPubSub.subscribe('user', managerUserId, (d, k) => {this.updateManager(d, k)}, null, managerUserId);
            this.managersPubSubPool.add(sub);
        }

        // update the tournaments
        this.tournamentsPubSubPool.unsubscribe();
        for (const tournamentId of league.tournament_ids) {
            const sub = this.restPubSub.subscribe('tournament', tournamentId, (d, k) => {this.updateTournament(d, k)}, null, tournamentId);
            this.tournamentsPubSubPool.add(sub);
        }
    }

    // if this user does not have a users-leagues record yet, don't post or patch users-leagues
    errorUsersLeagues(error, apiName) {
        this.setState({hasUsersLeagues: false});
    }

    updateUsersLeagues(usersLeaguesData, key) {
        const newUsersLeagues = NerdHerderDataModelFactory('user-league', usersLeaguesData);
        this.setState({hasUsersLeagues: true, localUsersLeagues: newUsersLeagues});
    }

    updateManager(userData, userId) {
        const newManager = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {managers: {...state.managers, [userId]: newManager}}
        });
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
    }

    startTimeoutToClearTabAlerts(activeKey) {
        if (this.clearTabAlertsTimer) clearTimeout(this.clearTabAlertsTimer);
        this.setOnTabTimer = setTimeout(()=>this.clearTabAlerts(activeKey), 2000);
    }

    clearTabAlerts(activeKey) {
        if (this.state.hasUsersLeagues) {
            const alertData = {};
            alertData[activeKey] = true;
            const patchData = {'alerts': alertData};
            this.restPubSub.patch('league-alerts', this.state.leagueId, patchData);
        }
    }

    handleTabChange(activeKey) {
        this.startTimeoutToClearTabAlerts(activeKey);
    }

    handleScroll(event) {
        if (this.leagueNavBarRef.current) {
            if (this.state.navbarSticky === false) {
                if (this.leagueNavBarRef.current.getBoundingClientRect().top <= -70) {
                    this.setState({navbarCollapseShow: false});
                    setTimeout(()=>this.setState({navbarSticky: true}), 500);
                    setTimeout(()=>this.setState({navbarCollapseShow: true}), 1000);
                }
            } else {
                if (this.leagueNavBarRef.current.getBoundingClientRect().top > -50) {
                    this.setState({navbarSticky: false, navbarCollapseShow: true});
                }
            }
        }
    }

    handleTimerUpdateMessage(messageLabel, message) {
        // need to pass this down to the tournament container - it will then pass to the right round card
        if (this.gameTabRef.current) {
            this.gameTabRef.current.handleTimerUpdateMessage(messageLabel, message);
        }
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal />);
        if (!this.state.league) return (<NerdHerderLoadingModal />);

        let localUserIsManager = this.state.league.isManager(this.state.localUser.id);
        let localUserIsPlayer = this.state.league.isPlayer(this.state.localUser.id);
        let localuserIsMember = localUserIsManager || localUserIsPlayer;
        let isCompetitiveEvent = this.state.league.type === 'tournament';
        let isSingleTournamentMode = this.state.league.isSingleTournamentEnabled();

        // only allow managers to see this stuff if its in draft
        if (this.state.league.state === 'draft' && !localUserIsManager) {
            return(<NerdHerderErrorModal errorFeedback='This league is currently in draft and you are not a league organizer'/>);
        }

        // if the user MUST pay and hasn't, setup the league page to encourage payment
        let paymentIsRequiredNotComplete = false;
        if (localUserIsPlayer && !localUserIsManager && this.state.localUsersLeagues !== null && ['open', 'running'].includes(this.state.league.state)) {
            if (this.state.league.registration_fee_option === 'required' && !this.state.localUsersLeagues.paid) {
                paymentIsRequiredNotComplete = true;
            }
        }

        let infoPageDisabled = false;
        let joinPageDisabled = false;
        let eventPageDisabled = false;
        let gamePageDisabled = false;
        let chatPageDisabled = false;
        let playersPageDisabled = false;
        
        // use flaticon-question-sign-in-a-circle if the user has not joined or stripe is not enabled
        // use flaticon-credit-card-filled-back is used when the player has joined and stripe is enabled
        let joinFlaticonSymbol = 'flaticon-question-sign-in-a-circle';

        // set the default active key
        let activeKey = this.defaultActiveKey;

        // when in single tournament mode or if the event is a competitive event, if the user is a member select games over the default
        if (localuserIsMember && (isSingleTournamentMode || isCompetitiveEvent) && ['running', 'complete'].includes(this.state.league.state)) activeKey='game';
        
        // if this user has not joined and the league is not concluded/archived, make join the default
        if (!localuserIsMember && ['interest', 'open', 'running'].includes(this.state.league.state)) activeKey = 'join';

        // similarly, if the user needs to pay, make the join the default tab and disable some of the others
        if (paymentIsRequiredNotComplete) {
            activeKey = 'join';
            eventPageDisabled = true;
            gamePageDisabled = true;
            chatPageDisabled = true;
            playersPageDisabled = true;
        }

        // trigger to clear alerts on info page when first visiting the page
        if (activeKey === 'info' && this.initialClearInfoPageAlerts) {
            this.startTimeoutToClearTabAlerts(activeKey);
            this.initialClearInfoPageAlerts = false;
        }

        // it is possible to fully disable the league chat
        if (this.state.league.chat_channel_enabled === 'disabled' && this.state.league.alt_chat_text === null) {
            chatPageDisabled = true;
        }

        // once the league is running, non-members can't see the chat page unless the chat is open to anyone
        if (this.state.league.chat_channel_enabled === 'members' && !localuserIsMember && ['running', 'complete', 'archived'].includes(this.state.league.state)) {
            chatPageDisabled = true;
        }

        // players can't change their participation once running - managers still need to see this page
        if (!localUserIsManager && localUserIsPlayer && ['running', 'complete', 'archived'].includes(this.state.league.state)) {
            joinPageDisabled = true;
        } else if (!localUserIsManager && ['complete', 'archived'].includes(this.state.league.state)) {
            joinPageDisabled = true;
        }

        // even if the user is a manager, still disable the join page if there are no join requests
        if (localUserIsManager && this.state.league.join_request_user_ids.length === 0) {
            joinPageDisabled = true;
        }

        // it is possible to see your payment status in most states if the registration fee option is setup
        if (localUserIsPlayer && 
            ['interest', 'open', 'running', 'complete'].includes(this.state.league.state) && 
            ['optional', 'required'].includes(this.state.league.registration_fee_option)) {
            joinPageDisabled = false;
            joinFlaticonSymbol = 'flaticon-credit-card-back';
        }

        // leagues in the draft, gathering interest, can't have games added normally
        if (!localUserIsManager && ['draft', 'interest'].includes(this.state.league.state)) {
            gamePageDisabled = true;
        }

        // generate badges - because we actually need stinking badges
        let infoAlertBadge = null;
        let joinAlertBadge = null;
        let eventAlertBadge = null;
        let gameAlertBadge = null;
        let chatAlertBadge = null;
        let playerAlertBadge = null;
        if (this.state.alerts) {
            let infoAlerts = this.state.alerts.alerts.info;
            if (!infoPageDisabled) {
                if (infoAlerts > 9) {
                    infoAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>9+</NerdHerderBadge>
                } else if (infoAlerts > 0) {
                    infoAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>{infoAlerts}</NerdHerderBadge>
                }
            }

            let joinAlerts = this.state.alerts.alerts.join;
            if (!joinPageDisabled) {
                // we do a special badge if this user isn't part of the league
                if (!localuserIsMember) {
                    joinAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle' fontIcon='flaticon-favourites-filled-star-symbol'/>
                } else if (joinAlerts > 9) {
                    joinAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>9+</NerdHerderBadge>
                } else if (joinAlerts > 0) {
                    joinAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>{joinAlerts}</NerdHerderBadge>
                }
            }

            let eventAlerts = this.state.alerts.alerts.event;
            if (!eventPageDisabled) {
                if (eventAlerts > 9) {
                    eventAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>9+</NerdHerderBadge>
                } else if (eventAlerts > 0) {
                    eventAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>{eventAlerts}</NerdHerderBadge>
                }
            }

            let gameAlerts = this.state.alerts.alerts.game;
            if (!gamePageDisabled) {
                if (gameAlerts > 9) {
                    gameAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>9+</NerdHerderBadge>
                } else if (gameAlerts > 0) {
                    gameAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>{gameAlerts}</NerdHerderBadge>
                }
            }

            let chatAlerts = this.state.alerts.alerts.chat;
            if (!chatPageDisabled) {
                if (chatAlerts > 9) {
                    chatAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>9+</NerdHerderBadge>
                } else if (chatAlerts > 0) {
                    chatAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>{chatAlerts}</NerdHerderBadge>
                }
            }

            let playerAlerts = this.state.alerts.alerts.players;
            if (!playersPageDisabled) {
                if (playerAlerts > 9) {
                    playerAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>9+</NerdHerderBadge>
                } else if (playerAlerts > 0) {
                    playerAlertBadge = <NerdHerderBadge position='top-right' translate='translate-middle-y' size='medium' shape='circle'>{playerAlerts}</NerdHerderBadge>
                }
            }
        }

        let outerDivClassName = 'my-2';
        let outerStyle={};
        let innerDivClassName = 'bg-white p-3';
        let innerStyle={borderRadius: '0.5rem', border: '1px solid rgba(0, 0, 0, 0.125)', boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)'};

        if (this.state.navbarSticky) {
            outerDivClassName = 'fixed-top';
            innerStyle={borderBottom: '1px solid rgba(0, 0, 0, 0.125)'};
        }

        let labelCallbacks = {};
        if (isSingleTournamentMode) {
            labelCallbacks = labelCallbacks={'tournament_clock_start': (mid, msg)=>this.handleTimerUpdateMessage(mid, msg), 'tournament_clock_stop': (mid, msg)=>this.handleTimerUpdateMessage(mid, msg), 'tournament_clock_complete': (mid, msg)=>this.handleTimerUpdateMessage(mid, msg)};
        }

        return(
            <NerdHerderStandardPageTemplate ref={this.pageTemplateRef} pageName='league' headerSelection='leagues' dropdownSelection={`league-${this.state.league.id}`}
                                            navPath={[{icon: 'flaticon-team', label: this.state.league.name, href: `/app/league/${this.state.leagueId}`}]}
                                            league={this.state.league} localUser={this.state.localUser} labelCallbacks={labelCallbacks}>
                <NerdHerderTour name='league' steps={this.tourSteps} localUser={this.state.localUser}/>
                <LeaguePageTopper league={this.state.league} managers={this.state.managers} tournaments={this.state.tournaments} isSingleTournamentMode={isSingleTournamentMode} localUser={this.state.localUser}/>
                <Tab.Container id="tab-container" defaultActiveKey={activeKey} onSelect={(activeKey)=>this.handleTabChange(activeKey)}>
                    <div ref={this.leagueNavBarRef}/>
                    <div id="tab-container-nav" className={outerDivClassName} style={outerStyle}>
                        <Collapse in={this.state.navbarCollapseShow}>
                            <div className={innerDivClassName} style={innerStyle}>
                                <Nav fill variant="pills" className="text-center">
                                    {this.state.navbarSticky &&
                                    <div style={{width: '60px'}}></div>}
                                    <NerdHerderToolTip text='Announcements & Social'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="info" disabled={infoPageDisabled}>
                                                <NerdHerderBadgeInjector badge={infoAlertBadge}>
                                                    <NerdHerderFontIcon icon="flaticon-tower-with-signal-emission"/>
                                                </NerdHerderBadgeInjector>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text={localuserIsMember?'Joining & Payments':'Join?'}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="join" disabled={joinPageDisabled}>
                                                <NerdHerderBadgeInjector badge={joinAlertBadge}>
                                                    <NerdHerderFontIcon icon={joinFlaticonSymbol}/>
                                                </NerdHerderBadgeInjector>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Calendar & Minor Events'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="event" disabled={eventPageDisabled}>
                                                <NerdHerderBadgeInjector badge={eventAlertBadge}>
                                                    <NerdHerderFontIcon icon="flaticon-calendar-to-organize-dates"/>
                                                </NerdHerderBadgeInjector>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Tournaments & Games'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="game" disabled={gamePageDisabled}>
                                                <NerdHerderBadgeInjector badge={gameAlertBadge}>
                                                    <NerdHerderFontIcon icon="flaticon-trophy-cup-black-shape"/>
                                                </NerdHerderBadgeInjector>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Chat'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="chat" disabled={chatPageDisabled}>
                                                <NerdHerderBadgeInjector badge={chatAlertBadge}>
                                                    <NerdHerderFontIcon icon="flaticon-chat-of-two-rounded-rectangular-filled-speech-bubbles"/>
                                                </NerdHerderBadgeInjector>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Player Info & Files'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="players" disabled={playersPageDisabled}>
                                                <NerdHerderBadgeInjector badge={playerAlertBadge}>
                                                    <NerdHerderFontIcon icon="flaticon-personal-card"/>
                                                </NerdHerderBadgeInjector>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                </Nav>
                            </div>
                        </Collapse>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="info" mountOnEnter unmountOnExit>
                            <LeagueInfoTabContent localUser={this.state.localUser}
                                                  league={this.state.league}
                                                  isPlayer={localUserIsPlayer}
                                                  isManager={localUserIsManager}
                                                  isLeagueMember={localuserIsMember}
                                                  isSingleTournamentMode={isSingleTournamentMode}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="join" mountOnEnter unmountOnExit>
                            <LeagueJoinTabContent localUser={this.state.localUser}
                                                  league={this.state.league}
                                                  isPlayer={localUserIsPlayer}
                                                  isManager={localUserIsManager}
                                                  isLeagueMember={localuserIsMember}
                                                  isSingleTournamentMode={isSingleTournamentMode}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="event" mountOnEnter unmountOnExit>
                            <LeagueEventTabContent localUser={this.state.localUser}
                                                   league={this.state.league}
                                                   isPlayer={localUserIsPlayer}
                                                   isManager={localUserIsManager}
                                                   isLeagueMember={localuserIsMember}
                                                   isSingleTournamentMode={isSingleTournamentMode}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="game" mountOnEnter unmountOnExit>
                            <LeagueGameTabContent ref={this.gameTabRef}
                                                  localUser={this.state.localUser}
                                                  league={this.state.league}
                                                  isPlayer={localUserIsPlayer}
                                                  isManager={localUserIsManager}
                                                  isLeagueMember={localuserIsMember}
                                                  isSingleTournamentMode={isSingleTournamentMode}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="chat" mountOnEnter unmountOnExit>
                            <LeagueChatTabContent localUser={this.state.localUser}
                                                  league={this.state.league}
                                                  isPlayer={localUserIsPlayer}
                                                  isManager={localUserIsManager}
                                                  isLeagueMember={localuserIsMember}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="players" mountOnEnter unmountOnExit>
                            <LeaguePlayersTabContent localUser={this.state.localUser}
                                                     league={this.state.league}
                                                     isPlayer={localUserIsPlayer}
                                                     isManager={localUserIsManager}
                                                     isLeagueMember={localuserIsMember}
                                                     isSingleTournamentMode={isSingleTournamentMode}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class LeaguePageTopper extends React.Component {

    render() {
        const leagueData = this.props.league;
        const isSingleTournamentMode = this.props.isSingleTournamentMode;
        let venueHtml = null;
        if (leagueData.online) {
            if (leagueData.venue_id === null) {
                if (leagueData.resolved_venue === 'Venue Unknown') {
                    venueHtml = 
                        <div>
                            <h5>Venue (Online)</h5>
                            <p>Unknown</p>
                        </div>
                } else {
                    venueHtml = 
                        <div>
                            <h5>Venue (Online)</h5>
                            <LinkifyText><p>{leagueData.resolved_venue}</p></LinkifyText>
                        </div>
                } 
            } else {
                venueHtml = 
                    <div>
                        <h5>Venue (Online)</h5>
                        <VenueListItem venueId={leagueData.venue_id} slim={true} hideLocation={true} localUser={this.props.localUser}/>
                        <LinkifyText><p>{leagueData.resolved_venue}</p></LinkifyText>
                    </div>
            }
        } else {
            if (leagueData.venue_id === null) {
                if (leagueData.resolved_venue === 'Venue Unknown') {
                    venueHtml = 
                        <div>
                            <h5>Venue</h5>
                            <p>Unknown</p>
                        </div>
                } else {
                    venueHtml = 
                        <div>
                            <h5>Venue<NerdHerderMapIcon location={leagueData.resolved_venue}/></h5>
                            <LinkifyText><p>{leagueData.resolved_venue}</p></LinkifyText>
                        </div>
                } 
            } else {
                if (leagueData.venue_string.length > 10) {
                    venueHtml = 
                        <div>
                            <h5>Venue<NerdHerderMapIcon location={leagueData.resolved_venue}/></h5>
                            <LinkifyText><p><b>Alternate Location:</b> {leagueData.resolved_venue}</p></LinkifyText>
                            <VenueListItem venueId={leagueData.venue_id} slim={true} hideLocation={true} localUser={this.props.localUser}/>
                            
                        </div>
                } else {
                    venueHtml = 
                        <div>
                            <h5>Venue</h5>
                            <VenueListItem venueId={leagueData.venue_id} slim={true} localUser={this.props.localUser}/>
                        </div>
                }
            }
        }

        // figure out doors open time and start time
        // if we have a start date, convert from the league's time zone to local time
        let openTimeJsx = null;
        let startTimeJsx = null;
        let luxonTimeZone = new IANAZone(leagueData.timezone);
        if (leagueData.start_date && luxonTimeZone.isValid) {
            if (leagueData.open_time !== null) {
                let luxonDateTime = DateTime.fromISO(`${leagueData.start_date}T${leagueData.open_time}`, {zone: luxonTimeZone});
                openTimeJsx = <span>Doors Open: {luxonDateTime.toLocaleString(DateTime.TIME_SIMPLE)} {luxonDateTime.toFormat('ZZZZ')}</span>
            }
            if (leagueData.start_time !== null) {
                let luxonDateTime = DateTime.fromISO(`${leagueData.start_date}T${leagueData.start_time}`, {zone: luxonTimeZone});
                startTimeJsx = <span>Start Time: {luxonDateTime.toLocaleString(DateTime.TIME_SIMPLE)} {luxonDateTime.toFormat('ZZZZ')}</span>
            }
        }

        const organizersList = [];
        for (const userId of Object.keys(this.props.managers)) {
            const listItem = <UserListItem key={userId} localUser={this.props.localUser} userId={userId} slim={true}/>
            // put the creator first
            if (parseInt(userId) === this.props.league.creator_id) {
                organizersList.unshift(listItem);
            } else {
                organizersList.push(listItem);
            }
        }

        const currentTournamentsList = [];
        // no tournaments in progress if league is archived / tounament mode
        if (leagueData.state !== 'archived' && !isSingleTournamentMode) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            for (const [tournamentId, tournamentData] of Object.entries(this.props.tournaments)) {
                // a tournament is 'current' if it is scheduled for today, or if it is in the in-progress state
                let isCurrent = false;
                if (tournamentData.state === 'in-progress') isCurrent = true;
                else if (tournamentData.date !== null) {
                    const tournamentDate = new Date(tournamentData.date);
                    if (currentDate.getUTCFullYear() === tournamentDate.getUTCFullYear() &&
                        currentDate.getUTCMonth() === tournamentDate.getUTCMonth() && 
                        currentDate.getUTCDate() === tournamentDate.getUTCDate()) isCurrent = true;
                }
                if (tournamentData.state === 'draft') isCurrent = false;
                if (tournamentData.state === 'complete') isCurrent = false;
                if (isCurrent) {
                    const listItem = <TournamentListItem key={tournamentId} tournament={tournamentData} league={this.props.league} localUser={this.props.localUser} slim={true}/>
                    currentTournamentsList.push(listItem);
                }
            }
        }

        // need to combine the tournament with the league topper for single tournament mode
        let singleTournamentId = null;
        if (isSingleTournamentMode) singleTournamentId = leagueData.getSingleTournamentId();
        if (isSingleTournamentMode && this.props.tournaments.hasOwnProperty(singleTournamentId)) {
            const tournamentData = this.props.tournaments[singleTournamentId];
            return(
                <Card className="card shadow-sm" style={{borderRadius: '0.5rem'}}>
                    <Card.Body>
                        <div id='topper-basics'>
                            <Row>
                                <Col xs={12}>
                                    <h2>{leagueData.name}</h2>
                                    <hr className="mb-1"/>
                                </Col>
                                <Col id='topper-topic-id' xs={6}>
                                    <small className="text-muted">Topic: <Link to={`/app/topic/${this.props.league.topic.id}`}>{this.props.league.topic.name}</Link></small>
                                </Col>
                                <Col xs={6} className="text-end">
                                    {leagueData.state === 'running' && 
                                    <small className="text-muted">{tournamentData.getShortStatusString()}</small>}
                                    {leagueData.state !== 'running' &&
                                    <small className="text-muted">{leagueData.getStatusString()}</small>}
                                </Col>
                            </Row>
                            {this.props.league.state === 'draft' &&
                            <Alert variant='danger'>League is in draft - only visisble to organizers</Alert>}
                            <hr className="mt-1"/>
                            <Row className="my-1">
                                <Col xs={5}>
                                    <Image className="img-fluid rounded text-center" src={leagueData.getImageUrl()}/>
                                </Col>
                                <Col xs={7}>
                                    <div>
                                        <LinkifyText><strong>{ leagueData.summary }</strong></LinkifyText>
                                    </div>
                                    <div className='mt-2'>
                                        <LinkifyText><strong>{ tournamentData.summary }</strong></LinkifyText>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {this.props.league.topic.external_site && (this.props.league.external_site || this.props.league.external_site_code) &&
                        <div>
                            <hr/>
                            <Row>
                                <Col>
                                    <p>This event uses {this.props.league.topic.getExternalSiteName()} to manage tournaments.</p>
                                </Col>
                            </Row>
                            {this.props.league.external_site &&
                            <Row>
                                <Col>
                                    <a target='_blank' href={this.props.league.external_site} rel='noreferrer'>{this.props.league.external_site}</a>
                                </Col>
                            </Row>}
                            {this.props.league.external_site_code &&
                            <Row className='justify-content-center'>
                                <Col xs='auto'>
                                    <div style={{border: '1px solid #000000', borderRadius: '5px', fontSize: '30px'}}>
                                        <b>{this.props.league.external_site_code}</b>
                                    </div>
                                </Col>
                            </Row>}  
                        </div>}
                        <hr/>
                        <div id='topper-schedule-venue-contact-organizers'>
                            <Row className="my-1">
                                <Col lg={6}>
                                    <h5>Type & Pairing</h5>
                                    {tournamentData.getTypeAndMethodJsx()}
                                </Col>
                                <Col lg={6}>
                                    <h5>Rounds</h5>
                                    <p>{tournamentData.getMaxNumberOfRounds()}</p>
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col lg={6}>
                                    <h5>Schedule</h5>
                                    <div>{leagueData.getScheduleString()}</div>
                                    <div>{openTimeJsx}</div>
                                    <div>{startTimeJsx}</div>
                                </Col>
                                <Col lg={6}>
                                    {venueHtml}
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col lg={6} id='topper-contact'>
                                    <h5>Contact</h5>
                                    <LinkifyText><p>{leagueData.contact}</p></LinkifyText>
                                </Col>
                                <Col lg={6} id='topper-organizers'>
                                    <h5>Organizers</h5>
                                    {organizersList}
                                </Col>
                            </Row>
                        </div>
                        <div id='topper-tournament-management'>
                            {leagueData.state !== 'archived' && leagueData.isManager(this.props.localUser.id) && 
                            <Row className="my-1">
                                <Col xs={12}>
                                    <div className="d-grid gap-2">
                                        <Button variant="danger" href={leagueData.getManageUrl()}>Manage Event</Button>
                                        <Button variant="danger" href={tournamentData.getManageUrl()}>Manage Tournament</Button>
                                    </div>
                                </Col>
                            </Row>}
                        </div>
                    </Card.Body>
                </Card>
            );
        }
        
        return(
            <Card className="card shadow-sm" style={{borderRadius: '0.5rem'}}>
                <Card.Body>
                    <div id='topper-basics'>
                        <Row>
                            <Col xs={12}>
                                <h2>{leagueData.name}</h2>
                                <hr className="mb-1"/>
                            </Col>
                            <Col id='topper-topic-id' xs={6}>
                                <small className="text-muted">Topic: <Link to={`/app/topic/${this.props.league.topic.id}`}>{this.props.league.topic.name}</Link></small>
                            </Col>
                            <Col xs={6} className="text-end">
                                <small className="text-muted">{leagueData.getStatusString()}</small>
                            </Col>
                        </Row>
                        {this.props.league.state === 'draft' &&
                        <Alert variant='danger'>League is in draft - only visisble to organizers</Alert>}
                        <hr className="mt-1"/>
                        <Row className="my-1">
                            <Col xs={5}>
                                <Image className="img-fluid rounded text-center" src={leagueData.getImageUrl()}/>
                            </Col>
                            <Col xs={7}>
                                <LinkifyText><strong>{ leagueData.summary }</strong></LinkifyText>
                            </Col>
                        </Row>
                    </div>
                    {this.props.league.topic.external_site && (this.props.league.external_site || this.props.league.external_site_code) &&
                    <div>
                        <hr/>
                        <Row>
                            <Col>
                                <h5>External Tournament</h5>
                                This event uses {this.props.league.topic.getExternalSiteName()} to manage tournaments.
                            </Col>
                        </Row>
                        {this.props.league.external_site &&
                        <Row>
                            <Col>
                                <a target='_blank' href={this.props.league.external_site} rel='noreferrer'>{this.props.league.external_site}</a>
                            </Col>
                        </Row>}
                        {this.props.league.external_site_code &&
                        <Row className='justify-content-center'>
                            <Col xs='auto'>
                                <div style={{border: '4px solid #1d1e3d', borderRadius: '10px', fontSize: '40px', color: 'rgb(86,106,205)'}}>
                                    <b className='m-2'>{this.props.league.external_site_code}</b>
                                </div>
                            </Col>
                        </Row>}  
                    </div>}
                    <hr/>
                    <div id='topper-schedule-venue-contact-organizers'>
                        <Row className="my-1">
                            <Col lg={6}>
                                <h5>Schedule</h5>
                                <div>{leagueData.getScheduleString()}</div>
                                <div>{openTimeJsx}</div>
                                <div>{startTimeJsx}</div>
                            </Col>
                            <Col lg={6}>
                                {venueHtml}
                            </Col>
                        </Row>
                        <Row className="my-1">
                            <Col lg={6} id='topper-contact'>
                                <h5>Contact</h5>
                                <LinkifyText><p>{leagueData.contact}</p></LinkifyText>
                            </Col>
                            <Col lg={6} id='topper-organizers'>
                                <h5>Organizers</h5>
                                {organizersList}
                            </Col>
                        </Row>
                    </div>
                    <div id='topper-tournament-management'>
                        {currentTournamentsList.length > 0 && 
                        <Row className="my-1">
                            <Col xs={12}>
                                <hr/>
                                <h5>Ongoing Tournaments</h5>
                                {currentTournamentsList}
                            </Col>
                        </Row>}
                        {leagueData.state !== 'archived' && leagueData.isManager(this.props.localUser.id) && 
                        <Row className="my-1">
                            <Col xs={12}>
                                <div className="d-grid gap-2">
                                    <Button variant="danger" href={leagueData.getManageUrl()}>Manage {this.props.league.getTypeWordCaps()}</Button>
                                </div>
                            </Col>
                        </Row>}
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

class LeagueInfoTabContent extends React.Component {

    /* cards needed:
       x Top Post
       x Create Post
       x All the posts
       x Googlesheet (Players & Managers only)
    */

    findLatestMessageDate(messageItem) {
        let newestDate = new Date(messageItem.date);
        // check all children to see if they have a newer date
        for (const childMessageItem of messageItem.children) {
            let childMessageDate = this.findLatestMessageDate(childMessageItem, newestDate);
            if (childMessageDate > newestDate) {
                newestDate = childMessageDate
            }
        }
        return newestDate;
    }

    render() {
        const parentMessageItems = [];
        const leaguePostCards = [];
        // only want the top-level parent messages, but need to traverse the tree branches to find the newest one to put up top
        for (const messageItem of this.props.league.message_tree) {
            if (messageItem.state === 'sent' && messageItem.id !== this.props.league.top_post_id) {
                let dupItem = Object.assign({}, messageItem);
                dupItem.latestMessageDate = this.findLatestMessageDate(dupItem);
                parentMessageItems.push(dupItem);
            }
        }

        // sort so that the newest is on top
        parentMessageItems.sort((item1, item2) => {
            return item2.latestMessageDate.getTime() - item1.latestMessageDate.getTime();
        });

        // now generate the cards and add them to the array to be rendered by React
        for (const messageItem of parentMessageItems) {
            const messageId = messageItem.id;
            const card = <NerdHerderLeaguePostCard key={messageId} messageId={messageId} localUser={this.props.localUser}></NerdHerderLeaguePostCard>
            leaguePostCards.push(card);
        }

        let pollManageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            pollManageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'info',
                focusCard: 'manage-polls-card',
            }
        }

        const leaguePollCards = [];
        for (const pollId of this.props.league.polls.posted) {
            const card = <LeaguePollCard key={pollId} pollId={pollId} league={this.props.league} localUser={this.props.localUser} manageOptions={pollManageOptions}/>
            leaguePollCards.push(card);
        }
        for (const pollId of this.props.league.polls.completed) {
            const card = <LeaguePollCard key={pollId} pollId={pollId} league={this.props.league} localUser={this.props.localUser} manageOptions={pollManageOptions}/>
            leaguePollCards.push(card);
        }

        const leagueRandomSelectorCards = [];
        for (const randomSelector of this.props.league.random_selectors) {
            let selectorId = randomSelector.id;
            const card = <NerdHerderRandomSelectorCard key={selectorId} randomSelector={randomSelector} league={this.props.league} localUser={this.props.localUser}/>
            leagueRandomSelectorCards.push(card);
        }

        return(
            <div>
                <LeagueTopPostCard {...this.props}/>
                {leaguePollCards}
                {leagueRandomSelectorCards}
                {this.props.isLeagueMember &&
                <LeagueGoogleSheetsDataCard {...this.props}/>}
                {this.props.league.state !== 'archived' && this.props.isLeagueMember &&
                <LeagueCreatePostCard {...this.props}/>}
                {leaguePostCards}
            </div>
        )
    }
}

class LeagueJoinTabContent extends React.Component {
    
    render() {
        // only want to show the LeagueInterestAndJoinCard card if the league can be joined...
        // anytime in the interest or open states to anyone
        // to non-members also when in the running state
        let showInterestJoinCard = false;
        if (this.props.league.state === 'interest' || this.props.league.state === 'open') showInterestJoinCard = true;
        else if (!this.props.isLeagueMember && this.props.league.state === 'running') showInterestJoinCard = true;

        // only want to show the PlayerRegistrationFeeStatusCard card if the league is configured to accept stripe payments
        // and only to league members
        let showPlayerRegistrationFeeStatusCard = false;
        if (['interest', 'open', 'running', 'complete'].includes(this.props.league.state) &&
            this.props.league.isPlayer(this.props.localUser.id) &&
            this.props.league.registration_fee_option !== 'disabled') {
            showPlayerRegistrationFeeStatusCard = true;
        }

        const joinRequestCards = [];
        if (this.props.isManager) {
            for (const userId of this.props.league.join_request_user_ids) {
                const item = <LeagueJoinRequestCard key={userId} userId={userId} {...this.props}/>
                joinRequestCards.push(item);
            }
        }

        return(
            <div>
                {showPlayerRegistrationFeeStatusCard &&
                <PlayerRegistrationFeeStatusCard {...this.props}/>}
                {joinRequestCards}
                {showInterestJoinCard &&
                <LeagueInterestAndJoinCard {...this.props}/>}
            </div>
        )
    }
}

class LeagueEventTabContent extends React.Component {
    
    render() {
        let tournamentMode = false;
        if (this.props.league.type === 'tournament') tournamentMode = true;

        return(
            <div>
                <LeagueEventCalendarCard {...this.props}/>
                {tournamentMode === false &&
                <LeagueEventsCard {...this.props}/>}
            </div>
        )
    }
}

class LeagueGameTabContent extends React.Component {
    constructor(props) {
        super(props);
        this.tournamentContainerRef = React.createRef();
    }

    handleTimerUpdateMessage(messageLabel, message) {
        // need to pass this down to the tournament container - it will then pass to the right round card
        if (this.tournamentContainerRef.current) {
            this.tournamentContainerRef.current.handleTimerUpdateMessage(messageLabel, message);
        }
    }
    
    render() {
        if (this.props.isSingleTournamentMode) {
            let singleTournamentId = this.props.league.getSingleTournamentId();
            return(
                <TournamentContainer ref={this.tournamentContainerRef} tournamentId={singleTournamentId} league={this.props.league} topic={this.props.league.topic} localUser={this.props.localUser}/>
            )
        }

        // only show the add games card if:
        // the league is open for registration or running
        // the user is a player & players can create games or the user is a manager
        let showAddGameCard = false;
        if (['open', 'running'].includes(this.props.league.state)) {
            if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
                showAddGameCard = true;
            } else if (this.props.league.isPlayer(this.props.localUser.id) && this.props.league.players_create_games) {
                showAddGameCard = true;
            }
        }

        // only show the stats card if the league is running or complete/archived
        let showStatsCard = false
        if (['running', 'complete', 'archived'].includes(this.props.league.state)) showStatsCard = true;

        return(
            <div>
                <LeagueVerificationCard {...this.props}/>
                <LeagueScheduleGameCard {...this.props}/>
                <LeagueTournamentsCard {...this.props}/>
                {showStatsCard &&
                <LeagueGameStatsCard {...this.props}/>}
                {this.props.league.list_container_ids.length !== 0 &&
                <NerdHerderListUploadCard leagueId={this.props.league.id} {...this.props}/>}
                {showAddGameCard &&
                <LeagueAddGameCard {...this.props}/>}
                <LeagueGamesCard {...this.props}/>
            </div>
        )
    }
}

class LeagueChatTabContent extends React.Component {

    render() {

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'chat',
            }
        }

        // if the chat is disabled, show the disabled message card
        if (this.props.league.chat_channel_enabled === 'disabled') {
            return (
                <LeagueChatDisabledCard league={this.props.league} localUser={this.props.localUser} manageOptions={manageOptions}/>
            )
        }

        return(
            <ChatCard title={`${this.props.league.getTypeWordCaps()} Chat`} league={this.props.league} localUser={this.props.localUser} manageOptions={manageOptions}/>
        )
    }
}

class LeaguePlayersTabContent extends React.Component {
    
    render() {
        let showInterestedUsersCard = false;
        if (['draft', 'interest', 'open'].includes(this.props.league.state)) {
            showInterestedUsersCard = true;
        }

        return(
            <div>
                <LeagueFilesAndLinksCard {...this.props}/>
                {showInterestedUsersCard &&
                <InterestedUsersCard {...this.props}/>}
                <LeagueContactsCard {...this.props}/>
                <LeagueSharingCard {...this.props}/>
            </div>
        )
    }
}

class LeagueTopPostCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueTopPostCard'>
                <LeagueTopPostCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueTopPostCardInner extends React.Component {
    // if there is a top post, this tab needs to show the top post
    // if there is no top post, just return null (nothing is displayed)
    // if this is a manager and there is no top post, have a simple card with a button to add one
    // if this is a manager, have a modal? to allow editing the post
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            showEditPostModal: false,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onCreatePost() {
        this.setState({showEditPostModal: true});
    }

    onCancelModal() {
        this.setState({showEditPostModal: false});
    }
    
    render() {
        let editPostModal = null;
        if (this.state.showEditPostModal) {
            editPostModal = <NerdHerderEditPostModal league={this.props.league} localUser={this.props.localUser}
                                                     setTopPost={true} onCancel={()=>this.onCancelModal()}/>
        }

        if (this.props.league.top_post_id === null) {
            // don't show top post stuff if the league is archived
            if (this.props.league.state === 'archived') return(null);
            // otherwise only allow managers to make the top post
            else if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
                return(
                    <NerdHerderStandardCardTemplate id="top-post-card" title='Top Post?' titleIcon="star.png">
                        {editPostModal}
                        <p>This {this.props.league.getTypeWord()} doesn't have a top post. You should create one!</p>
                        <Button className='float-end' variant='primary' onClick={()=>this.onCreatePost()}>Create</Button>
                    </NerdHerderStandardCardTemplate>
                );
            } else return(null);
        } 
        else return(<NerdHerderLeaguePostCard messageId={this.props.league.top_post_id} localUser={this.props.localUser}/>);
    }
}

class LeagueCreatePostCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueCreatePostCard'>
                <LeagueCreatePostCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueCreatePostCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            showEditPostModal: false,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onCreatePost() {
        this.restPubSub.refresh('league', this.props.league.id, 1000);
    }

    onCancelPost() {
    }
    
    render() {
        // no creating posts in archived leagues
        if (this.props.league.state === 'archived') return(null);

        return(
            <NerdHerderStandardCardTemplate id='create-post-card' title='Create Post' titleIcon='pencil-holder.png'>
                <CreateLeaguePost localUser={this.props.localUser}
                                  league={this.props.league}
                                  createPost={()=>this.onCreatePost()}
                                  cancelPost={()=>this.onCancelPost()}/>
            </NerdHerderStandardCardTemplate>
        );
    }
}

class LeagueFilesAndLinksCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueTopPosLeagueFilesAndLinksCardtCard'>
                <LeagueFilesAndLinksCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueFilesAndLinksCardInner extends React.Component {
    
    render() {
        const leagueFiles = []
        const leagueLinks = []

        // if props.files is null or undefined then this user can't see the files
        if (typeof this.props.league.files !== 'undefined' && this.props.league.files !== null ) {
            for (const fileData of this.props.league.files) {
                const file = NerdHerderDataModelFactory('file', fileData);
                const fileElement = <FileListItem key={fileData.id} file={file}/>
                leagueFiles.push(fileElement);
            }
        }

        // if props.external_links is null or undefined then this user can't see the links
        if (typeof this.props.league.external_links !== 'undefined' && this.props.league.external_links !== null) {
            const lines = this.props.league.external_links.split('\n');
            let index = 0;
            for (const line of lines) {
                if (line.startsWith('http://') || line.startsWith('https://')) {
                    leagueLinks.push(<div key={index}><a href={line} rel="noreferrer" target='_blank'>{line}</a></div>)
                } else if (line.includes('http://')) {
                    const result = line.split('http://', 2);
                    let text = result[0];
                    let link = `http://${result[1]}`;
                    leagueLinks.push(<div key={index}><a href={link} rel="noreferrer" target='_blank'>{text}</a></div>)
                } else if (line.includes('https://')) {
                    const result = line.split('https://', 2);
                    let text = result[0];
                    let link = `https://${result[1]}`;
                    leagueLinks.push(<div key={index}><a href={link} rel="noreferrer" target='_blank'>{text}</a></div>)
                } else if (line.length !== 0) {
                    leagueLinks.push(<div key={index}>{line}</div>)
                }
                index++;
            }
        }

        let noStuffHereMessage = null;
        if (leagueFiles.length === 0 && leagueLinks.length === 0) {
            noStuffHereMessage = <p>This {this.props.league.getTypeWord()} doesn't have any files or links that you can view.</p>
        }

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'players',
                focusCard: 'manage-files-card',
            }
        }

        return(
            <NerdHerderStandardCardTemplate id='files-links-card' title='Files and Links' titleIcon='folder.png' manageOptions={manageOptions}>
                {leagueFiles}
                {leagueLinks}
                {noStuffHereMessage}
            </NerdHerderStandardCardTemplate>
        );
    }
}

class LeagueGoogleSheetsDataCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueGoogleSheetsDataCard'>
                <LeagueGoogleSheetsDataCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueGoogleSheetsDataCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            sheetData: null,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('league-google-data', this.props.league.id, (d, k) => {this.updateSheetData(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }


    updateSheetData(newSheetData, key) {
        this.setState({sheetData: newSheetData});
    }
    
    render() {
        if (this.state.sheetData === null) return(<NerdHerderLoadingCard title='Google Sheet Scores'/>);
        if (this.state.sheetData.data === null) return(null);
        
        const sheetData = this.state.sheetData;
        const rowData = sheetData.data;

        let noDataMessage = null;
        if (rowData.length === 0) {
            noDataMessage = true;
        }

        let tableHeader = [];
        let tableData = []
        let rowIndex = 0;
        let maxColumns = 0;
        // pad out each row to have the same number of columns - first find the max number of columns
        for (const row of rowData) {
            let numColumns = row.length;
            if (numColumns > maxColumns) maxColumns = numColumns;
        }
        // then append empty strings on each row to make the number of columns equal
        for (const row of rowData) {
            let numColumns = row.length;
            while (numColumns < maxColumns) {
                row.push('');
                numColumns = row.length;
            }
        }
        for (const row of rowData) {
            let tableRowData = []
            let columnIndex = 0;
            let itemElement = null;
            for (const item of row) {
                if (rowIndex === 0) {
                    itemElement = <th key={`r${rowIndex}-c${columnIndex}`}>{item}</th>
                    tableHeader.push(itemElement);
                } else {
                    itemElement = <td key={`r${rowIndex}-c${columnIndex}`}>{item}</td>
                    tableRowData.push(itemElement);
                }
                columnIndex++;
            }
            if (rowIndex !== 0) {
                let rowElement = <tr key={`r${rowIndex}`}>{tableRowData}</tr>
                tableData.push(rowElement);
            }
            rowIndex++;
        }

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'info',
                focusCard: 'google-integration-card',
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="google-sheets-card" title={sheetData.label || 'Google Sheet Scores'} titleIcon='excel.png'
                manageOptions={manageOptions}>
                {!noDataMessage &&
                <Table responsive striped size="sm">
                    <thead>
                        <tr>
                            {tableHeader}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </Table>}
                {noDataMessage &&
                <p>The Google sheet contains no data.</p>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueSharingCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueSharingCard'>
                <LeagueSharingCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueSharingCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.ref = null;

        this.setRef = ref => {
            let oldref = this.ref;
            this.ref = ref;
            if (oldref === null) this.forceUpdate();
        }

        this.state = {
            userFeedback: null,
        }
        this.userFeedbackTimeout = null;
    }

    copyUrlToClipboard(url) {
        navigator.clipboard.writeText(url);
        this.setState({userFeedback: 'Link copied to clipboard'});
        if (this.userFeedbackTimeout) clearTimeout(this.userFeedbackTimeout);
        this.userFeedbackTimeout = setTimeout(()=>this.setState({userFeedback: null}), 1500);
    }
    
    render() {
        const leagueHref = `${window.location.protocol}//${window.location.host}/app/league/${this.props.league.id}`;
        const qrHref = `/app/league/${this.props.league.id}`;
        let qrSize = 0;
        let iconSize = 32;
        if (this.ref) {
            let rect = this.ref.getBoundingClientRect();
            let width = rect.width;
            if (window.innerHeight < width) qrSize = window.innerHeight * 0.75;
            else qrSize = width * 0.75;
            iconSize = (width * 0.75)/6;
        }

        if (iconSize > 64) iconSize = 64;
        const iconProps = {size: iconSize, round: true};

        return(
            <NerdHerderStandardCardTemplate id="sharing-card" title='Sharing' titleIcon='qr-code.png'>
                {this.state.userFeedback &&
                <Row>
                    <Col xs={12}>
                        <Alert variant='primary'>{this.state.userFeedback}</Alert>
                    </Col>
                </Row>}
                <Row>
                    <Col xs={12}>
                        <p>This QR code links directly to your {this.props.league.getTypeWord()}'s signup. Share it!</p>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col xs={12} className="text-center" ref={this.setRef}>
                        <Row className='justify-content-center'>
                            <Col xs='auto'>
                                {qrSize !== 0 && 
                                <NerdHerderQrCode size={qrSize} href={qrHref}/>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12}>
                        You can also share this link to invite others!
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col className='align-items-center text-break'>
                        <a href={leagueHref}>{leagueHref}</a>
                    </Col>
                    <Col xs='auto'>
                        <NerdHerderToolTip placement='top' text='Share on Reddit'>
                            <Button onClick={()=>this.copyUrlToClipboard(leagueHref)}><NerdHerderFontIcon icon='flaticon-clipboard-with-left-arrow'/></Button>
                        </NerdHerderToolTip>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12}>
                        Share your {this.props.league.getTypeWord()} on social media!
                    </Col>
                </Row>
                <Row className='mt-2 justify-content-center'>
                    <Col xs='auto'>
                        <NerdHerderToolTip placement='top' text='Share via Email'>
                            <EmailShareButton url={leagueHref} subject={this.props.league.name} body={this.props.league.summary}>
                                <EmailIcon {...iconProps} />
                            </EmailShareButton>
                        </NerdHerderToolTip>
                        {' '}
                        <NerdHerderToolTip placement='top' text='Share on Facebook'>
                            <FacebookShareButton url={leagueHref} quote={`${this.props.league.name} - ${this.props.league.summary}`}>
                                <FacebookIcon {...iconProps} />
                            </FacebookShareButton>
                        </NerdHerderToolTip>
                        {' '}
                        <NerdHerderToolTip placement='top' text='Share on Reddit'>
                            <RedditShareButton url={leagueHref} title={this.props.league.name}>
                                <RedditIcon {...iconProps} />
                            </RedditShareButton>
                        </NerdHerderToolTip>
                        {' '}
                        <NerdHerderToolTip placement='top' text='Share via Telegram'>
                            <TelegramShareButton url={leagueHref} title={this.props.league.name}>
                                <TelegramIcon {...iconProps} />
                            </TelegramShareButton>
                        </NerdHerderToolTip>
                        {' '}
                        <NerdHerderToolTip placement='top' text='Share on Twitter'>
                            <TwitterShareButton url={leagueHref} title={this.props.league.name}>
                                <TwitterIcon {...iconProps} />
                            </TwitterShareButton>
                        </NerdHerderToolTip>
                        {' '}
                        <NerdHerderToolTip placement='top' text='Share via Whatsapp'>
                            <WhatsappShareButton url={leagueHref} title={this.props.league.name}>
                                <WhatsappIcon {...iconProps} />
                            </WhatsappShareButton>
                        </NerdHerderToolTip>
                    </Col>
                </Row>
            </NerdHerderStandardCardTemplate>
        );
    }
}

class InterestedUsersCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='InterestedUsersCard'>
                <InterestedUsersCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class InterestedUsersCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // this holds the users leagues - not in state on purpose
        this.usersLeagues = {};

        this.state = {
            userRows: {},
            showUserProfileModal: false,
            showUserId: null,
        }
    }

    componentDidMount() {
        const allUserIds = [];

        for (const leagueUserId of this.props.league.manager_ids) {
            allUserIds.push(leagueUserId);
            for (const usersLeagues of this.props.league.managers_users_leagues) {
                if (usersLeagues.user_id === leagueUserId) {
                    this.addListRow(leagueUserId, usersLeagues);
                    break;
                }
            }
        }

        for (const leagueUserId of this.props.league.player_ids) {
            allUserIds.push(leagueUserId);
            for (const usersLeagues of this.props.league.players_users_leagues) {
                if (usersLeagues.user_id === leagueUserId) {
                    this.addListRow(leagueUserId, usersLeagues);
                    break;
                }
            }
        }

        for (const leagueUserId of this.props.league.interested_user_ids) {
            allUserIds.push(leagueUserId);
            for (const usersLeagues of this.props.league.interested_users_leagues) {
                if (usersLeagues.user_id === leagueUserId) {
                    this.addListRow(leagueUserId, usersLeagues);
                    break;
                }
            }
        }

        for (const leagueUserId of this.props.league.invite_sent_user_ids) {
            allUserIds.push(leagueUserId);
            for (const usersLeagues of this.props.league.invite_sent_users_leagues) {
                if (usersLeagues.user_id === leagueUserId) {
                    this.addListRow(leagueUserId, usersLeagues);
                    break;
                }
            }
        }

        for (const leagueUserId of this.props.league.join_request_user_ids) {
            allUserIds.push(leagueUserId);
            for (const usersLeagues of this.props.league.join_request_users_leagues) {
                if (usersLeagues.user_id === leagueUserId) {
                    this.addListRow(leagueUserId, usersLeagues);
                    break;
                }
            }
        }

        for (const leagueUserId of this.props.league.waitlist_user_ids) {
            allUserIds.push(leagueUserId);
            for (const usersLeagues of this.props.league.waitlist_users_leagues) {
                if (usersLeagues.user_id === leagueUserId) {
                    this.addListRow(leagueUserId, usersLeagues);
                    break;
                }
            }
        }

        let sub = null;
        for (const userId of allUserIds) {
            sub = this.restPubSub.subscribe('user', userId, (d, k) => {this.updateListRow(d, k)}, null, userId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    addListRow(userId, usersLeagues) {
        this.usersLeagues[userId] = usersLeagues;
        const newRow = this.createListRow(userId, null, usersLeagues);
        this.setState((state) => {
            return {userRows: {...state.userRows, [userId]: newRow}}
        });
    }

    updateListRow(user, userId) {
        const newRow = this.createListRow(userId, user, this.usersLeagues[userId]);
        this.setState((state) => {
            return {userRows: {...state.userRows, [userId]: newRow}}
        });
    }

    createListRow(userId, user, usersLeagues) {
        const badgeWidth = 20;
        let badge = null;
        if (usersLeagues.manager && usersLeagues.player) {
            badge = 
                <span>
                    <NerdHerderBadge color='red' size='medium' width={15} value='O'/>
                    <NerdHerderBadge color='blue' size='medium' width={15} value='P'/>
                </span>
        } else if (usersLeagues.manager) {
            badge = 
                <span>
                    <NerdHerderBadge color='red' size='medium' width={badgeWidth} value='O'/>
                </span>
        } else if (usersLeagues.player) {
            badge = 
                <span>
                    <NerdHerderBadge color='blue' size='medium' width={badgeWidth} value='P'/>
                </span>
        } else if (usersLeagues.waitlist_position !== null) {
            badge = 
            <span>
                <NerdHerderBadge color='yellow' size='medium' width={badgeWidth} value={usersLeagues.waitlist_position}/>
            </span>
        } else {
            badge = 
                <span>
                    <NerdHerderBadge color='green' size='medium' width={badgeWidth} value='?'/>
                </span>
        }
        
        return (
            <tr key={userId} className='cursor-pointer' onClick={()=>this.onShowUserProfile(userId)}>
                <td className='text-center'>{badge}</td>
                <td>
                    {user &&
                    <small>{user.username}</small>}
                    {!user &&
                    <Spinner animation="border" variant='primary' size="sm" />}
                </td>
                <td><small>{usersLeagues.level_of_interest}</small></td>
                <td className='text-center'>
                    {(usersLeagues.invite_sent || usersLeagues.join_request) &&
                    <NerdHerderBadge color='blue' size='medium' fontIcon='flaticon-new-email-filled-back-envelope'/>}
                </td>
            </tr>
        )
    }

    onShowUserProfile(userId) {
        this.setState({showUserProfileModal: true, showUserId: userId});
    }
    
    render() {
        const badgeWidth = 20;
        const arrayOfRows = Object.values(this.state.userRows);

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'players',
                focusCard: 'manage-players-card',
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="user-interest-card" title='User Interest' titleIcon='user-question.png' manageOptions={manageOptions}>
                {this.state.showUserProfileModal &&
                <NerdHerderUserProfileModal userId={this.state.showUserId} localUser={this.props.localUser} onCancel={()=>this.setState({showUserProfileModal: false})}/>}
                <Row>
                    <Col xs={12}>
                        <p>See who is interested in the {this.props.league.getTypeWord()}, convince them to join!</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <NerdHerderBadge color='red'    size='medium' width={badgeWidth} value='O'/> - Organizer<br/>
                        <NerdHerderBadge color='green'  size='medium' width={badgeWidth} value='?'/> - Interested<br/>
                        <NerdHerderBadge color='yellow' size='medium' width={badgeWidth} value='X'/> - Waitlisted
                    </Col>
                    <Col xs={6}>
                        <NerdHerderBadge color='blue'   size='medium' width={badgeWidth} value='P'/> - Player<br/>
                        <NerdHerderBadge color='blue'   size='medium' width={badgeWidth} fontIcon='flaticon-new-email-filled-back-envelope'/> - Invite Sent or Join Request
                    </Col>
                </Row>
                <Table responsive striped hover size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>User</th>
                            <th>Interest</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrayOfRows}
                    </tbody>
                </Table>
                
                {arrayOfRows.length === 0 &&
                <p>No users have expressed interest in this league yet.</p>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueContactsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueContactsCard'>
                <LeagueContactsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueContactsCardInner extends React.Component {

    render() {
        const listed = [];
        const managerListItems = [];
        const playerListItems = [];
        const interestedListItems = [];

        // show extra Ids if needed
        let extraId = null;
        if (this.props.league.topic.external_site && this.props.league.external_site) extraId = this.props.league.topic.external_site;

        for (const leagueUserId of this.props.league.manager_ids) {
            listed.push(leagueUserId);
            const listItem = <UserListItem key={leagueUserId} localUser={this.props.localUser} userId={leagueUserId} extraId={extraId} showContact={true}/>
            managerListItems.push(listItem);
        }

        for (const leagueUserId of this.props.league.player_ids) {
            if (listed.includes(leagueUserId)) continue;
            listed.push(leagueUserId);
            const listItem = <UserListItem key={leagueUserId} localUser={this.props.localUser} userId={leagueUserId} extraId={extraId} showContact={true}/>
            playerListItems.push(listItem);
        }

        for (const leagueUserId of this.props.league.interested_user_ids) {
            if (listed.includes(leagueUserId)) continue;
            listed.push(leagueUserId);
            const listItem = <UserListItem key={leagueUserId} localUser={this.props.localUser} userId={leagueUserId} extraId={extraId} showContact={true}/>
            interestedListItems.push(listItem);
        }

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'players',
                focusCard: 'manage-players-card',
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="player-information-card" title='Player Information' titleIcon='address-book.png' manageOptions={manageOptions}>
                <p>Remember to keep this information private!</p>
                {managerListItems.length > 0 &&
                <small className='text-muted'>Managers</small>}
                {managerListItems}

                {playerListItems.length > 0 &&
                <small className='text-muted'>Players</small>}
                {playerListItems}

                {interestedListItems.length > 0 &&
                <small className='text-muted'>Interested Users</small>}
                {interestedListItems}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueJoinRequestCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueJoinRequestCard'>
                <LeagueJoinRequestCardCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueJoinRequestCardCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();

        this.state = {
            updating: false,
        }
    }

    onRejectJoin() {
        const userId = this.props.userId;
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({join_request: false})
        .then((response)=>{
            this.restPubSub.refresh('league-alerts', this.props.league.id);
            this.restPubSub.refresh('league', this.props.league.id);
            this.setState({updating: false});
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onAcceptJoin() {
        const userId = this.props.userId;
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({player: true, join_request: false})
        .then((response)=>{
            this.restPubSub.refresh('league-alerts', this.props.league.id);
            this.restPubSub.refresh('league', this.props.league.id);
            this.setState({updating: false});
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    render() {
        return(
            <NerdHerderStandardCardTemplate id="join-request-card" title="League Join Request" titleIcon="team-management.png">
                <p className='text-muted'><small>The user below has requested to join this league.</small></p>
                <UserListItem localUser={this.props.localUser} userId={this.props.userId} showContact={true}/>
                <div className='float-end'>
                    <Button type='button' variant='danger'  disabled={this.state.updating} onClick={()=>this.onRejectJoin()}>Reject</Button>
                    {' '}
                    <Button type='button' variant='primary' disabled={this.state.updating} onClick={()=>this.onAcceptJoin()}>Accept</Button>
                </div>
                
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueInterestAndJoinCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueInterestAndJoinCard'>
                <LeagueInterestAndJoinCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueInterestAndJoinCardInner extends React.Component {
    // allow any user to set their interest
    // want join button enabled unless the league is invite only
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.doPost = false;               // set true when there is no existing users-leagues and we need to post a new one
        this.isJoinConfirm = true;         // set true when the confirmation modal is confirming a join request (false for quit)
        this.userProvidedPassword = null;  // a place to store the password inbetween the modal and this component
        this.userDidAQuit = false;         // set true when the user tries to quit, if the quit works then the errorFeedback is set
        this.loiUpdateTimeout = null;      // used to timeout updates to the slider

        this.state = {
            usersLeagues: null,
            userIsPlayer: false,
            userFeedback: null,
            errorFeedback: null,
            loiSliderValue: 0,
            updating: false,
            showCommitConfirmationModal: false,
            showEnterPasswordModal: false,
            showStripePaymentModal: false,
            doPageReload: false,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('self-user-league', this.props.league.id, (d, k)=>{this.updateUsersLeagues(d, k)}, (e, a)=>{this.errorUsersLeagues(e, a)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    // if this user does not have a users-leagues record yet, we'll need to create one
    errorUsersLeagues(error, apiName) {
        const newUsersLeagues = new NerdHerderUserLeague(this.props.localUser.id, this.props.league.id);
        this.doPost = true;
        this.setState({usersLeagues: newUsersLeagues, updating: false});

        // this is expected, stop propagation
        return true;
    }

    updateUsersLeagues(usersLeaguesData, key) {
        const newUsersLeagues = NerdHerderDataModelFactory('user-league', usersLeaguesData);
        let loiSliderValue = 0;
        let typeWord = this.props.league.getTypeWord();
        switch (newUsersLeagues.level_of_interest) {
            case 'none':
                loiSliderValue = 0;
                break;
            case 'some':
                loiSliderValue = 1;
                break;
            case 'nominal':
                loiSliderValue = 2;
                break;
            case 'very':
                loiSliderValue = 3;
                break;
            default:
                console.error(`got invalid level_of_interest value: ${newUsersLeagues.level_of_interest}`);
                loiSliderValue = 0;
        }
        let userFeedback = null;
        let errorFeedback = null;
        if (newUsersLeagues.player) {
            userFeedback = `You have joined this ${typeWord}`;
            if (this.state.doPageReload) {
                this.setState({doPageReload: false});
                reloadPage(2000);
            } 
        }
        if (newUsersLeagues.join_request) userFeedback = `You have requested to join this ${typeWord}`;
        else if (newUsersLeagues.waitlist_position !== null) userFeedback = `You are #${newUsersLeagues.waitlist_position} on the waitlist for this ${typeWord}`;
        if (this.userDidAQuit && !newUsersLeagues.player) errorFeedback = `You have quit this ${typeWord}`;
        if (this.userProvidedPassword && !(newUsersLeagues.player || newUsersLeagues.join_request)) errorFeedback = 'That password was not correct';

        this.userDidAQuit = false;
        this.userProvidedPassword = null;
        this.doPost = false;
        this.setState({usersLeagues: newUsersLeagues, updating: false, loiSliderValue: loiSliderValue,
                       userFeedback: userFeedback, errorFeedback: errorFeedback});
    }

    onJoin() {
        this.isJoinConfirm = true;
        if (this.props.league.isFull()) {
            this.onPostPatchJoin();
        }
        else if (this.props.league.join_league_via_password === false || this.state.usersLeagues.invite_sent) {
            this.setState({showCommitConfirmationModal: true});
        } else {
            this.setState({showEnterPasswordModal: true});
        }
    }

    onQuit() {
        this.isJoinConfirm = false;
        this.setState({showCommitConfirmationModal: true});
    }

    onSetInterest(event) {
        const loiSliderValue = event.target.value;
        let loiSliderText = null;
        // convert the slider numerical val to text to send to server
        switch (loiSliderValue) {
            case "0":
                loiSliderText = 'none';
                break;
            case "1":
                loiSliderText = 'some';
                break;
            case "2":
                loiSliderText = 'nominal';
                break;
            case "3":
                loiSliderText = 'very';
                break;
            default:
                console.error(`got invalid loiSliderValue value: ${loiSliderValue}`);
                loiSliderText = 'none';
        } 

        if (this.loiUpdateTimeout !== null) clearTimeout(this.loiUpdateTimeout);
        this.loiUpdateTimeout = setTimeout(()=>{
            if (this.doPost) {
                const usersLeagues = {...this.state.usersLeagues};
                usersLeagues.level_of_interest = loiSliderText;
                this.restPubSub.post('self-user-league', this.props.league.id, usersLeagues);
                this.restPubSub.refresh('league', this.props.league.id, 1000);
                this.setState({updating: true});
            } else {
                this.restPubSub.patch('self-user-league', this.props.league.id, {level_of_interest: loiSliderText});
                this.restPubSub.refresh('league', this.props.league.id, 1000);
                this.setState({updating: true});
            }
        }, 500);

        this.setState({loiSliderValue: loiSliderValue});
    }

    onConfirmJoin() {
        // if the league requires payment, take it now - except when using a capped league:
        // Capped leagues need players to go from non-players to players without a delay, but payment causes a delay
        // It is possible during this time that a player pays, but another player is added filling the league to the limit
        if (this.props.league.registration_fee_option === 'required' || this.props.league.registration_fee_option === 'optional') {
            if (this.props.league.max_players === null) {
                this.setState({showStripePaymentModal: true});
            } else {
                this.onPostPatchJoin();
            }
        } else {
            this.onPostPatchJoin();
        }
    }

    onPostPatchJoin() {
        this.setState({showCommitConfirmationModal: false});
        let leagueIsFull = this.props.league.isFull();
        if (this.doPost) {
            const usersLeagues = {...this.state.usersLeagues};
            if (leagueIsFull) {
                // doesn't matter the position, the server is going to set it
                usersLeagues.waitlist_position = 1;
            }
            else if (this.props.league.join_league_via_request) {
                usersLeagues.join_request = true;
            } else {
                usersLeagues.player = true;
            }
            if (this.userProvidedPassword !== null) {
                usersLeagues.password = this.userProvidedPassword;
            }
            this.restPubSub.post('self-user-league', this.props.league.id, usersLeagues);
        } else {
            if (leagueIsFull) {
                this.restPubSub.patch('self-user-league', this.props.league.id, {password: this.userProvidedPassword, waitlist_position: 1});
            }
            if (this.props.join_league_via_request) {
                this.restPubSub.patch('self-user-league', this.props.league.id, {password: this.userProvidedPassword, join_request: true});
            } else {
                this.restPubSub.patch('self-user-league', this.props.league.id, {password: this.userProvidedPassword, player: true});
            }
            
        }
        this.setState({updating: true, doPageReload: true});
        this.restPubSub.refresh('header-leagues', null, 1000);
        this.restPubSub.refresh('header-alerts', null, 1000);
        this.restPubSub.refresh('league-alerts', this.props.league.id, 2000);
    }

    onConfirmQuit() {
        this.setState({showCommitConfirmationModal: false});
        if (this.doPost) {
            const usersLeagues = {...this.state.usersLeagues};
            usersLeagues.player = false;
            usersLeagues.manager = false;
            usersLeagues.password = this.userProvidedPassword;
            this.restPubSub.post('self-user-league', this.props.league.id, usersLeagues);
        } else {
            this.restPubSub.patch('self-user-league', this.props.league.id, {password: this.userProvidedPassword, player: false, manager: false});
        }
        this.userDidAQuit = true;
        this.setState({updating: true, doPageReload: true});
    }

    onAcceptUserPassword(password) {
        this.userProvidedPassword = password.trimEnd();
        this.setState({showEnterPasswordModal: false});
        this.onConfirmJoin();
    }

    onPlayerCompletedPayment() {
        this.setState({showStripePaymentModal: false});
        this.onPostPatchJoin();
    }

    onPlayerOptOutPayment() {
        this.setState({showStripePaymentModal: false});
        this.onPostPatchJoin();
    }

    onCancelModal() {
        this.setState({showCommitConfirmationModal: false, showEnterPasswordModal: false, showStripePaymentModal: false});
    }
    
    render() {
        if (!['interest', 'open', 'running'].includes(this.props.league.state)) return(null);
        if (this.props.league.state === 'running' && (this.props.league.isPlayer() || this.props.league.isManager())) return(null);
        if (this.state.usersLeagues === null) return(<NerdHerderLoadingCard/>);
        let typeWord = this.props.league.getTypeWord();
        let typeWordCaps = this.props.league.getTypeWordCaps();
        let leagueIsFull = this.props.league.isFull();

        let modal = null;
        if (this.state.showCommitConfirmationModal && this.isJoinConfirm) {
            modal = <NerdHerderConfirmModal localUser={this.props.localUser}
                                            title={'Are You Sure?'}
                                            message={`You are committing to this ${typeWord}. If you are not sure it is better to wait than to back out later.`}
                                            acceptButtonText={"Commit"}
                                            onAccept={()=>this.onConfirmJoin()}
                                            onCancel={()=>this.onCancelModal()}/>
        }
        if (this.state.showCommitConfirmationModal && !this.isJoinConfirm) {
            modal = <NerdHerderConfirmModal localUser={this.props.localUser}
                                            title={'Are You Sure?'}
                                            message={`You are about to quit this ${typeWord}`}
                                            acceptButtonText={"Quit"}
                                            onAccept={()=>this.onConfirmQuit()}
                                            onCancel={()=>this.onCancelModal()}/>
        }
        if (this.state.showEnterPasswordModal) {
            modal = <NerdHerderPasswordModal localUser={this.props.localUser}
                                             title={'Enter League Password'}
                                             passwordLabel={`This ${typeWord} requires a password to join`}
                                             onAccept={(p)=>this.onAcceptUserPassword(p)}
                                             onCancel={()=>this.onCancelModal()}/>
        }
        if (this.state.showStripePaymentModal) {
            modal = <NerdHerderStripePaymentModal localUser={this.props.localUser}
                                                  title={'Registration Fee'}
                                                  league={this.props.league}
                                                  onPaid={()=>this.onPlayerCompletedPayment()}
                                                  onOptOut={()=>this.onPlayerOptOutPayment()}
                                                  onCancel={()=>this.onCancelModal()}/>
        }

        // the join, quit and set interst sliders are enabled by default - disable when not allowed to change
        let joinDisable = false;
        let quitDisable = false;
        let sliderDisable = false;

        // if the form is updating, disable further button pushes
        if (this.state.updating) {
            joinDisable = true;
            sliderDisable = true;
        }

        // if the user is already joined, on the waitlist or has requested to join, disable the join button
        if (this.state.usersLeagues.player || this.state.usersLeagues.join_request || this.state.usersLeagues.waitlist_position !== null) {
            joinDisable = true;
        }
        
        // you can't quit a league that you are not a player in
        if (!this.state.usersLeagues.player) {
            quitDisable = true;
        }

        let allowQuit = false;
        // only allow quitting the league if it's still in the draft, registration, or gathering interest state
        if (['draft', 'interest', 'open'].includes(this.props.league.state)) {
            allowQuit = true;
        }
        // this gets confusing if we're just adding to the waitlist
        if (leagueIsFull && !this.state.usersLeagues.player) allowQuit = false;

        let titleText = 'Interested in Joining?';
        if (this.state.usersLeagues.invite_sent) {
            titleText = "You've been invited!"
        }
        else if (this.state.usersLeagues.player) {
            titleText = 'Change Interest?';
        }

        let joinButtonText = `Join ${typeWordCaps}`;
        if (this.state.usersLeagues.invite_sent) {
            joinButtonText = `Accept Invite to ${typeWordCaps}`;
        } else if (this.props.league.join_league_via_request) {
            joinButtonText = `Request to Join ${typeWordCaps}`;
        } else if (leagueIsFull) {
            joinButtonText = 'Add To Waitlist';
        }
        
        let loiMeaningTitle = null;
        let loiMeaningText = null;
        switch (this.state.loiSliderValue) {
            case "0":
            case 0:
                loiMeaningTitle = 'Not Interested';
                loiMeaningText = `You have no interest in joining this ${typeWord}.`;
                break;
            case "1":
            case 1:
                loiMeaningTitle = 'Somewhat Interested';
                loiMeaningText = `You will probably not join this ${typeWordCaps} - you would like to but aren't able to. However, you would like to see its progress.`;
                break;
            case "2":
            case 2:
                loiMeaningTitle = 'Interested';
                loiMeaningText = `You are likely to join this ${typeWord} as long as other commitments don't get in the way.`;
                break;
            case "3":
            case 3:
                loiMeaningTitle = 'Very Interested';
                loiMeaningText = `You're in! You're going to commit to this ${typeWord} and will make arrangements so you can participate.`;
                break;
            default:
                console.error(`got invalid loiSliderValue value: ${this.state.loiSliderValue}`);
                loiMeaningTitle = 'Not Interested';
                loiMeaningText = `You have no interest in joining this ${typeWord}.`;
        }
        
        return(
            <NerdHerderStandardCardTemplate id="join-league-card" title={titleText} titleIcon='question.png'>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.userFeedback &&
                <Alert variant='primary'>{this.state.userFeedback}</Alert>}
                {modal}
                <p>Here you can join the {typeWord} and/or tell the organizers how interested you are in it.</p>
                <p>Showing interest (event if you can't join) helps promote the {typeWord}, encourages the organizers, and helps NerdHerder find similar events for you in the future.</p>
                <Form>
                    <Form.Group className="form-outline mb-4">
                        <Form.Range className="mb-1" onChange={(event)=>this.onSetInterest(event)} value={this.state.loiSliderValue} min={0} max={3} step={1} disabled={sliderDisable}/>
                        <Form.Text>
                            <small className="text-muted float-start">Less</small>
                        </Form.Text>
                        <Form.Text>
                            <small className="text-muted float-end">More</small>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="form-outline mb-4">
                        <Form.Text>
                            <p>
                                <small>
                                    <b>{loiMeaningTitle}</b> - {loiMeaningText}
                                </small>
                            </p>
                        </Form.Text>
                    </Form.Group>
                    <hr/>
                    {leagueIsFull &&
                     !this.state.usersLeagues.invite_sent && !this.state.usersLeagues.player && !this.state.usersLeagues.manager &&
                     this.state.usersLeagues.waitlist_position === null  &&
                    <Alert variant='warning'>This {this.props.league.getTypeWord()} is full, however you may still add yourself to the waitlist!</Alert>}
                    <Form.Group className="form-outline mb-1">
                        <div className='d-grid gap-2'>
                            <Button className='float-end' variant='primary' onClick={()=>this.onJoin()} disabled={joinDisable}>{joinButtonText}</Button>
                            {allowQuit &&
                            <Button className='float-end' variant='danger' onClick={()=>this.onQuit()} disabled={quitDisable}>Quit {typeWordCaps}</Button>}
                        </div>
                    </Form.Group>
                </Form>
            </NerdHerderStandardCardTemplate>
        );
    }
}

class PlayerRegistrationFeeStatusCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='PlayerRegistrationFeeStatusCard'>
                <PlayerRegistrationFeeStatusCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class PlayerRegistrationFeeStatusCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            showStripePaymentModal: false,
            usersLeagues: null,
            paymentIntent: null,
            updating: false,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('self-user-league', this.props.league.id, (d, k)=>{this.updateUsersLeagues(d, k)}, (e, a)=>{this.errorUsersLeagues(e, a)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    errorUsersLeagues(error, apiName) {
        this.setState({usersLeagues: null, updating: false});
        return true;
    }

    updateUsersLeagues(usersLeaguesData, key) {
        const newUsersLeagues = NerdHerderDataModelFactory('user-league', usersLeaguesData);
        this.setState({usersLeagues: newUsersLeagues, updating: false});

        // user might have paid, but it might not have gone through yet...
        if (!usersLeaguesData.paid) {
            const queryParams = {user_id: this.props.localUser.id, league_id: this.props.league.id, venue_id: this.props.league.venue_id};
            this.restApi.genericGetEndpointData('stripe-payment-intent', 'league-reg-fee', queryParams)
            .then((response)=>{
                const paymentIntent = response.data;
                this.setState({paymentIntent: paymentIntent});
            })
            .catch((error)=>{
                // and error means the user doesn't have a payment intent with stripe
                this.setState({paymentIntent: 'no payment intent'});
            });
        }
    }

    onPlayerCompletedPayment() {
        this.restPubSub.refresh('self-user-league', this.props.league.id, 200);
        this.restPubSub.refresh('league-alerts', this.props.league.id, 1000);
        this.setState({showStripePaymentModal: false});
    }

    onPlayerOptOutPayment() {
        this.setState({showStripePaymentModal: false});
    }

    onCancelModal() {
        this.setState({showStripePaymentModal: false});
    }
    
    render() {
        const registrationFeeOption = this.props.league.registration_fee_option;
        if (this.state.usersLeagues === null) return(null);
        if (registrationFeeOption === 'disabled') return(null);
        let typeWord = this.props.league.getTypeWord();
        
        // this card doesn't really do much, it's all about this modal
        let paymentModal = null;
        if (this.state.showStripePaymentModal) {
            paymentModal = <NerdHerderStripePaymentModal
                localUser={this.props.localUser}
                title={'Registration Fee'}
                league={this.props.league}
                onPaid={()=>this.onPlayerCompletedPayment()}
                onOptOut={()=>this.onPlayerOptOutPayment()}
                onCancel={()=>this.onCancelModal()}/>
        }

        // figure out if the user needs to pay
        let disablePayNow = true;
        let statusMessage = null;
        if (this.state.usersLeagues.paid) {
            disablePayNow = true;
            statusMessage = 'You have paid the registration fee';
        } else if (this.state.paymentIntent === 'no payment intent') {
            disablePayNow = false;
        } else if (this.state.paymentIntent) {
            let paymentIntent = this.state.paymentIntent;
            if (paymentIntent.refunded) {
                disablePayNow = false;
                statusMessage = 'Your registration fee (less Stripe fees) was refunded';
            } else if (paymentIntent.status === 'succeeded') {
                disablePayNow = true;
                statusMessage = 'You have paid the registration fee';
            } else if (['processing', 'requires_capture'].includes(paymentIntent.status)) {
                disablePayNow = true;
                statusMessage = 'Your payment is processing - nothing for you to do right now';
            }  else if (['requires_confirmation', 'requires_action'].includes(paymentIntent.status)) {
                disablePayNow = true;
                statusMessage = 'The payment is held up - confirmation is required by your financial instituion';
            } else if (['canceled'].includes(paymentIntent.status)) {
                disablePayNow = false;
                statusMessage = 'The payment was canceled - please pay again';
                if (this.props.league.registration_fee_option === 'optional') {
                    statusMessage = 'The payment was canceled - you can try again';
                }
            } else {
                disablePayNow = false;
            }
        }

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'join',
                focusCard: 'registration-fees-card',
            }
        }
        
        return(
            <NerdHerderStandardCardTemplate id="pay-registration-fee-status-card" title='Registration Fee Status' titleIcon='stripe.png'
                manageOptions={manageOptions}>
                {paymentModal}
                {registrationFeeOption === 'required' &&
                <div>
                    <p>This {typeWord} requires new players to pay the registration fee online.</p>
                </div>}
                {registrationFeeOption === 'optional' &&
                <div>
                    <p>This {typeWord} requires new players to pay a registration fee. You may pay it now, or it may be paid later.</p>
                    <p><i>If you have already paid (outside of NerdHerder) you can ignore this message.</i></p>
                </div>}
                {statusMessage && disablePayNow &&
                <Alert variant='primary'>{statusMessage}</Alert>}
                {statusMessage && !disablePayNow &&
                <Alert variant='warning'>{statusMessage}</Alert>}
                <div className='text-end'>
                    <Button variant='primary' onClick={()=>this.setState({showStripePaymentModal: true})} disabled={disablePayNow}>Pay Now</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        );
    }
}

class LeagueEventCalendarCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueEventCalendarCard'>
                <LeagueEventCalendarCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueEventCalendarCardInner extends React.Component {
    constructor(props) {
        super(props);

        this.querySent = false;

        this.state = {
            calendarAlerts: null,
            selectedDate: null,
        };

        this.restApi = new NerdHerderRestApi(); 
    }

    triggerRestQuery() {
        if (this.querySent === false){
            let queryParams = {league_id: this.props.league.id};
            this.querySent = true;
            this.restApi.genericGetEndpointData('calendar-alerts', null, queryParams)
            .then(response => {
                this.updateCalendarAlerts(response.data);
                this.querySent = false;
            }).catch(error => {
                console.error(error);
            });
        }
    }

    updateCalendarAlerts(alertData) {
        this.setState({calendarAlerts: alertData});
    }

    onSelectDate(date) {
        let dateString = date.toISOString().split('T')[0];
        this.setState({selectedDate: dateString});
    }

    onTileContent(obj) {
        const date = obj.date;
        const view = obj.view;
        if (view === 'month') {
            let dateString = date.toISOString().split('T')[0];
            if (this.state.calendarAlerts !== null) {
                let numDates = 0;
                let numEvents = 0;
                let numGames = 0;
                let numTournaments = 0;
                let numLeagueMessages = 0;
                if (this.state.calendarAlerts.dates.hasOwnProperty(dateString)) {
                    numDates = this.state.calendarAlerts.dates[dateString].length;
                }
                if (this.state.calendarAlerts.events.hasOwnProperty(dateString)) {
                    numEvents = this.state.calendarAlerts.events[dateString].length;
                }
                if (this.state.calendarAlerts.games.hasOwnProperty(dateString)) {
                    numGames = this.state.calendarAlerts.games[dateString].length;
                }
                if (this.state.calendarAlerts.tournaments.hasOwnProperty(dateString)) {
                    numTournaments = this.state.calendarAlerts.tournaments[dateString].length;
                }
                if (this.state.calendarAlerts.leagues.hasOwnProperty(dateString)) {
                    numLeagueMessages = this.state.calendarAlerts.leagues[dateString].length;
                }
                let totalBadges = numDates + numEvents + numGames + numTournaments + numLeagueMessages;
                let badge = null;
                if (numDates !== 0 && numDates === totalBadges) {
                    badge = <NerdHerderBadge size='medium' position='top' translate='none' color='yellow' shape='circle' fontIcon='flaticon-information-letter-sign-or-person-shape-from-side-view'/>
                } else if (numEvents !== 0 && numEvents === totalBadges) {
                    badge = <NerdHerderBadge size='medium' position='top' translate='none' color='yellow' shape='circle' fontIcon='flaticon-calendar-to-organize-dates'/>
                } else if (numGames !== 0 && numGames === totalBadges) {
                    badge = <NerdHerderBadge size='medium' position='top' translate='none' color='blue' shape='circle' fontIcon='flaticon-sport-dices'/>
                } else if (numTournaments !== 0 && numTournaments === totalBadges) {
                    badge = <NerdHerderBadge size='medium' position='top' translate='none' color='blue' shape='circle' fontIcon='flaticon-trophy-cup-black-shape'/>
                } else if (numLeagueMessages !== 0 && numLeagueMessages === totalBadges) {
                    badge = <NerdHerderBadge size='medium' position='top' translate='none' color='blue' shape='circle' fontIcon='flaticon-team'/>
                } else if (totalBadges > 0) {
                    badge = <NerdHerderBadge size='medium' position='top' translate='none' color='red' shape='circle' fontIcon='flaticon-burn'/>
                }
                return (badge);
            }
        }
    }

    render() {
        if (this.state.calendarAlerts === null) this.triggerRestQuery();

        let selectedDateListItems = [];
        if (this.state.calendarAlerts !== null && this.state.selectedDate !== null) {
            const dateString = this.state.selectedDate;
            if (this.state.calendarAlerts.leagues.hasOwnProperty(dateString)) {
                let messageNum = 0;
                for (const message of this.state.calendarAlerts.leagues[dateString]) {
                    const item = <LeagueListItem key={`leagueMessage-${dateString}-${messageNum}`} topMessage={<big><b>{message}</b></big>} localUser={this.props.localUser} league={this.props.league}/>
                    selectedDateListItems.push(item);
                    messageNum++;
                }
            }
            if (this.state.calendarAlerts.events.hasOwnProperty(dateString)) {
                for (const eventId of this.state.calendarAlerts.events[dateString]) {
                    const item = <EventListItem key={`event-${eventId}`} eventId={eventId} showSummary={true} showTopPost={true} localUser={this.props.localUser} league={this.props.league}/>
                    selectedDateListItems.push(item);
                }
            }
            if (this.state.calendarAlerts.tournaments.hasOwnProperty(dateString)) {
                for (const tournamentId of this.state.calendarAlerts.tournaments[dateString]) {
                    const item = <TournamentListItem key={`tournament-${tournamentId}`} tournamentId={tournamentId} showSummary={true} showTopPost={true} localUser={this.props.localUser} league={this.props.league}/>
                    selectedDateListItems.push(item);
                }
            }
            if (this.state.calendarAlerts.games.hasOwnProperty(dateString)) {
                for (const gameId of this.state.calendarAlerts.games[dateString]) {
                    const item = <GameListItem key={`game-${gameId}`} gameId={gameId} localUser={this.props.localUser} league={this.props.league}/>
                    selectedDateListItems.push(item);
                }
            }
            if (this.state.calendarAlerts.dates.hasOwnProperty(dateString)) {
                for (const dateId of this.state.calendarAlerts.dates[dateString]) {
                    const item = <DateListItem key={`date-${dateId}`} dateId={dateId} localUser={this.props.localUser} league={this.props.league}/>
                    selectedDateListItems.push(item);
                }
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="calendar-card" title='Calendar' titleIcon='calendar.png' additionalContent={selectedDateListItems}>
                <div className='p-0'>
                    <Calendar onChange={(d)=>this.onSelectDate(d)} tileContent={(o)=>this.onTileContent(o)}/>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueEventsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueEventsCard'>
                <LeagueEventsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueEventsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            events: {},
        }
    }

    componentDidMount() {
        for (const eventId of this.props.league.event_ids) {
            let sub = this.restPubSub.subscribe('event', eventId, (d, k) => {this.updateEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateEvent(eventData, eventId) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState((state) => {
            return {events: {...state.events, [eventId]: newEvent}}
        });
    }
    
    render() {
        const unscheduledEventListItems = [];
        const upcomingEventListItems = [];
        const pastEventListItems = [];
        const currentEventListItems = [];

        for (const event of Object.values(this.state.events)) {
            const listItem = <EventListItem key={event.id} event={event} localUser={this.props.localUser} league={this.props.league} eventId={event.id} showSummary={true} showTopPost={true}/>
            let startDate = null;
            let endDate = null;

            // don't show draft events
            if (event.state === 'draft') continue;

            // if there is a start/end date, get just the date part
            if (event.start_date) {
                startDate = new Date(event.start_date);
                startDate.setHours(0, 0, 0, 0);
            }
            if (event.end_date) {
                endDate = new Date(event.end_date);
                endDate.setHours(0, 0, 0, 0);
            }

            // get today
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            // if somehow there is a end but no start, then assume the end is the start
            if (startDate === null && endDate !== null) {
                startDate = endDate;
                endDate = null;
            }

            // if there is a start but no end, assume this is a 1-day event
            if (startDate !== null && endDate === null) {
                endDate = new Date(startDate)
                endDate.setDate(endDate.getDate() + 1);
            }

            // no start means unscheduled
            if (startDate === null) {
                unscheduledEventListItems.push(listItem);
            }

            // there is a start, so this is scheduled...
            else {
                // if today is before the start, then this is a upcoming event
                if (today < startDate) upcomingEventListItems.push(listItem);
                // if today is after the end, then this is a past event
                else if (today > endDate) pastEventListItems.push(listItem);
                // if the event is not upcoming or past, it must be current
                else currentEventListItems.push(listItem);
            }
        }

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'event',
                focusCard: 'manage-events-card',
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="events-card" title="Minor Events" titleIcon='calendar-event.png' manageOptions={manageOptions}>
                {unscheduledEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Unscheduled Minor Events</small>
                    {unscheduledEventListItems}
                </div>}
                {currentEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Current Minor Events</small>
                    {currentEventListItems}
                </div>}
                {upcomingEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Upcoming Minor Events</small>
                    {upcomingEventListItems}
                </div>}
                {pastEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Past Minor Events</small>
                    {pastEventListItems}
                </div>}
                {this.props.league.event_ids.length === 0 &&
                <p>This {this.props.league.getTypeWord()} has no minor events planned.</p>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueTournamentsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueTournamentsCard'>
                <LeagueTournamentsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueTournamentsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            tournaments: {},
        }
    }

    componentDidMount() {
        for (const tournamentId of this.props.league.tournament_ids) {
            let sub = this.restPubSub.subscribe('tournament', tournamentId, (d, k) => {this.updateTournament(d, k)}, null, tournamentId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
    }
    
    render() {
        const upcomingTournamentListItems = [];
        const pastTournamentListItems = [];
        const currentTournamentListItems = [];
        const useExternalSite = this.props.league.external_site===null ? false : true;

        // get today
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (const tournamentData of Object.values(this.state.tournaments)) {
            const listItem = <TournamentListItem key={tournamentData.id} tournament={tournamentData} localUser={this.props.localUser} league={this.props.league} tournamentId={tournamentData.id} showSummary={true}/>
            let tournamentDate = null;

            // don't show draft tournaments
            if (tournamentData.state === 'draft') continue;

            // if there is a date, get just the date part
            if (tournamentData.date) {
                tournamentDate = new Date(tournamentData.date);
                tournamentDate.setHours(0, 0, 0, 0);
            }

            // a tournament is 'current' if it is scheduled for today, or if it is in the in-progress state
            let isCurrent = false;
            if (tournamentData.state === 'in-progress') isCurrent = true;
            else if (tournamentDate !== null) {
                if (today.getUTCFullYear() === tournamentDate.getUTCFullYear() &&
                    today.getUTCMonth() === tournamentDate.getUTCMonth() && 
                    today.getUTCDate() === tournamentDate.getUTCDate()) isCurrent = true;
            }
            if (isCurrent) {
                currentTournamentListItems.push(listItem);
            }
            // the tournament is not current - could be upcoming or could be past...
            // if the tournament is completed, then it is past, and if the tournament is not yet started, then it is upcoming
            else {
                if (tournamentData.state === 'complete') {
                    pastTournamentListItems.push(listItem);
                } else {
                    upcomingTournamentListItems.push(listItem);
                }
            }
        }

        let totalItemsListed = upcomingTournamentListItems.length + currentTournamentListItems.length + pastTournamentListItems.length;

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'game',
                focusCard: 'manage-tournaments-card',
            }
        }

        let titleText = `${this.props.league.getTypeWordCaps()} Tournaments`;
        if (this.props.league.type === 'tournament') titleText = 'Tournaments';
        
        return(
            <NerdHerderStandardCardTemplate id="tournaments-card" title={titleText} titleIcon='tournament.png' manageOptions={manageOptions}>
                {useExternalSite &&
                <div>
                    <div>
                        This {this.props.league.getTypeWord()} uses {this.props.league.topic.getExternalSiteName()} to run its tournament. Follow the link below to sign up and enter scores:
                    </div>
                    <div className='text-center'>
                        <a href={this.props.league.external_site} target='_blank' rel='noreferrer'>{this.props.league.external_site}</a>
                    </div>
                    <div>
                        <OpenGraphCard url={this.props.league.external_site}/>
                    </div>
                </div>}
                {upcomingTournamentListItems.length > 0 &&
                <div>
                    <small className="text-muted">Upcoming Tournaments</small>
                    {upcomingTournamentListItems}
                </div>}

                {currentTournamentListItems.length > 0 &&
                <div>
                    <small className="text-muted">Current Tournaments</small>
                    {currentTournamentListItems}
                </div>}
                
                {pastTournamentListItems.length > 0 &&
                <div>
                    <small className="text-muted">Completed Tournaments</small>
                    {pastTournamentListItems}
                </div>}
                {totalItemsListed === 0 && !useExternalSite &&
                <div>
                    {this.props.league.tournament_ids.length === 0 && this.props.league.type === 'tournament' &&
                    <p>This tournament has not been configured - check back later.</p>}
                    {this.props.league.tournament_ids.length === 0 && this.props.league.type !== 'tournament' &&
                    <p>This {this.props.league.getTypeWord()} either has no tournaments planned, or they aren't public just yet.</p>}
                    {this.props.league.tournament_ids.length !== 0 &&
                    <p>This {this.props.league.getTypeWord()} has tournaments in the planning stages, but they aren't public just yet.</p>}
                </div>}
                
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueGamesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueGamesCard'>
                <LeagueGamesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueGamesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // initially fill all the dicts with nulls - we'll load them when needed
        const tournaments = {};
        for (const tournamentId of this.props.league.tournament_ids) {
            tournaments[tournamentId] = null;
        }

        const events = {};
        for (const eventId of this.props.league.event_ids) {
            events[eventId] = null;
        }

        let defaultFilterMyGames = this.props.league.isPlayer(this.props.localUser.id);

        this.state = {
            updating: false,
            filterType: 'casual',
            filterMyGames: defaultFilterMyGames,
            filterEventsSelector: 'any',
            filterTournamentsSelector: 'any',
            events: events,
            tournaments: tournaments,
        }
    }

    componentDidMount() {
        for (const tournamentId of this.props.league.tournament_ids) {
            let sub = this.restPubSub.subscribe('tournament', tournamentId, (d, k) => {this.updateTournament(d, k)}, null, tournamentId);
            this.restPubSubPool.add(sub);
        }
        for (const eventId of this.props.league.event_ids) {
            let sub = this.restPubSub.subscribe('event', eventId, (d, k) => {this.updateEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
    }

    updateEvent(eventData, eventId) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState((state) => {
            return {events: {...state.events, [eventId]: newEvent}}
        });
    }

    updateGame(gameData, gameId) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        this.setState((state) => {
            return {games: {...state.games, [gameId]: newGame}}
        });
    }

    onSwitchFilter(value) {
        this.setState({filterType: value});
    }

    handleMyGamesCheckboxChange(event) {
        const value = event.target.checked;
        this.setState({filterMyGames: value});
    }

    handleEventsSelectorChange(event) {
        const value = event.target.value;
        this.setState({filterEventsSelector: value});
    }

    handleTournamentsSelectorChange(event) {
        const value = event.target.value;
        this.setState({filterTournamentsSelector: value});
    }
    
    render() {

        const selectorOptions = [];
        if (this.state.filterType === 'events') {
            const anyOption = <option value='any'>Any Event</option>
            selectorOptions.push(anyOption);
            for (const eventId of this.props.league.event_ids) {
                let title = null;
                if (this.state.events[eventId] === null) {
                    title = `Event ${eventId}`;
                } else {
                    title = this.state.events[eventId].name
                }
                const option = <option key={`t-${eventId}`} value={eventId}>{title}</option>
                selectorOptions.push(option);
            }
        }

        if (this.state.filterType === 'tournaments') {
            const anyOption = <option value='any'>Any Tournament</option>
            selectorOptions.push(anyOption);
            for (const tournamentId of this.props.league.tournament_ids) {
                let title = null;
                if (this.state.tournaments[tournamentId] === null) {
                    title = `Tournament ${tournamentId}`;
                } else {
                    title = this.state.tournaments[tournamentId].name
                }
                const option = <option key={`t-${tournamentId}`} value={tournamentId}>{title}</option>
                selectorOptions.push(option);
            }
        }

        // get the list of (possible) games to display
        let listOfGameIds = [];
        switch (this.state.filterType) {
            case 'tournaments':
                if (this.state.filterTournamentsSelector === 'any') {
                    listOfGameIds = this.props.league.games.tournament;
                } else {
                    const tournamentId = parseInt(this.state.filterTournamentsSelector);
                    const tournament = this.state.tournaments[tournamentId];
                    if (tournament !== null) {
                        listOfGameIds = tournament.game_ids;
                    }
                }
                break;

            case 'events':
                if (this.state.filterEventsSelector === 'any') {
                    listOfGameIds = this.props.league.games.event;
                } else {
                    const eventId = parseInt(this.state.filterEventsSelector);
                    const event = this.state.events[eventId];
                    if (event !== null) {
                        listOfGameIds = event.game_ids;
                    }
                }
                break;

            case 'casual':
                listOfGameIds = this.props.league.games.casual;
                break;

            default:
                console.error('got a bogus filterType');
        }

        // create the list of list items - if the user has filtered on their games only then make sure to leave those out
        const gameListItems = []
        for (const gameId of listOfGameIds) {
            let addGameToListItems = false;
            if (this.state.filterMyGames) {
                if (this.props.league.games.local_user_games.includes(gameId)) {
                    addGameToListItems = true;
                }
            } else {
                addGameToListItems = true;
            }
            if (addGameToListItems) {
                const newGameItem = <GameListItem key={`g-${gameId}`} gameId={gameId} league={this.props.league} localUser={this.props.localUser}/>
                gameListItems.unshift(newGameItem);
            }
        }

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'game',
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="games-card" title='Games' titleIcon='dice.png' manageOptions={manageOptions}>
                <Form>
                    <Form.Group className="form-outline mb-2">
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='game-filter' type="radio" value={this.state.filterType} onChange={(v)=>this.onSwitchFilter(v)}>
                                <ToggleButton variant='outline-primary' id='toggle-casual' value={'casual'}>Casual Games</ToggleButton>
                                <ToggleButton variant='outline-primary' id='toggle-events' value={'events'}>Minor Event Games</ToggleButton>
                                <ToggleButton variant='outline-primary' id='toggle-tournaments' value={'tournaments'}>Tournament Games</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className="form-outline mb-2">
                        <Form.Check type="checkbox" label="My Games Only" onChange={(e)=>this.handleMyGamesCheckboxChange(e)} checked={this.state.filterMyGames} disabled={this.state.updating}/>
                    </Form.Group>
                    {this.state.filterType === 'events' &&
                    <Form.Group className="form-outline mb-2">
                        <Form.Select aria-label="select event" onChange={(e)=>this.handleEventsSelectorChange(e)} value={this.state.filterEventsSelector} disabled={this.state.updating}>
                            {selectorOptions}
                        </Form.Select>
                    </Form.Group>}
                    {this.state.filterType === 'tournaments' &&
                    <Form.Group className="form-outline mb-2">
                        <Form.Select aria-label="select tournament" onChange={(e)=>this.handleTournamentsSelectorChange(e)} value={this.state.filterTournamentsSelector} disabled={this.state.updating}>
                            {selectorOptions}
                        </Form.Select>
                    </Form.Group>}
                    <hr/>
                    {gameListItems}
                </Form>
                {this.props.league.external_site &&
                <Alert variant='warning'>This {this.props.league.getTypeWord()} is using {this.props.league.topic.getExternalSiteName()} to record tournament games. Only games reported on NerdHerder will appear here.</Alert>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueVerificationCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueVerificationCard'>
                <LeagueVerificationCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueVerificationCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            gameIds: [],
        }
    }

    componentDidMount() {
        this.triggerSearch();
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    triggerSearch() {
        const queryParams = {
            'user-id': this.props.localUser.id,
            'league-id': this.props.league.id,
            'state': 'posted',
            'completion': 'completed',
            'needs-concur': true}
        this.restApi.genericGetEndpointData('game', null, queryParams)
        .then((response)=>{
            const gameIds = response.data.game_ids;
            for (const gameId of gameIds) {
                let sub = this.restPubSub.subscribe('game', gameId, (d, k)=>{this.updateGame(d, k)}, null, gameId);
                this.restPubSubPool.add(sub);
            }
        })
        .catch((error)=>{
            console.error(error);
        });
    }

    updateGame(gameData, gameId) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        const playersDict = newGame.getPlayersDict(this.props.league);
        if (playersDict.needsConcurIds.includes(this.props.localUser.id))
        this.setState((state)=>{
            if (this.state.gameIds.includes(gameId)) {
                return {gameIds: [...state.gameIds]};
            } else {
                return {gameIds: [...state.gameIds, gameId]}
            }
        });
    }
    
    render() {
        if (this.state.gameIds.length === 0) return(null);

        const gameListItems = [];
        for (const gameId of this.state.gameIds) {
            let addGameToListItems = true;
            if (addGameToListItems) {
                const newGameItem = <GameListItem key={`g-${gameId}`} gameId={gameId} league={this.props.league} localUser={this.props.localUser}/>
                gameListItems.unshift(newGameItem);
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="review-games-card" title="Review Games" titleIcon='warning.png'>
                <p>These games require your review:</p>
                {gameListItems}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueScheduleGameCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueScheduleGameCard'>
                <LeagueScheduleGameCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueScheduleGameCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            games: {},
            playersDict: {}
        }
    }

    componentDidMount() {
        this.triggerSearch();
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    triggerSearch() {
        const queryParams = {
            'user-id': this.props.localUser.id,
            'league-id': this.props.league.id,
            'state': 'posted',
            'is-scheduled': true}
        this.restApi.genericGetEndpointData('game', null, queryParams)
        .then((response)=>{
            const gameIds = response.data.game_ids;
            for (const gameId of gameIds) {
                let sub = this.restPubSub.subscribe('game', gameId, (d, k)=>{this.updateGame(d, k)}, null, gameId);
                this.restPubSubPool.add(sub);
            }
        })
        .catch((error)=>{
            console.error(error);
        });
    }

    updateGame(gameData, gameId) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        const playersDict = newGame.getPlayersDict(this.props.league);
        this.setState((state)=>{
            return {games: {...state.games, [newGame.id]: newGame}, playersDict: {...state.playersDict, [newGame.id]: playersDict}}
        });
    }
    
    render() {
        const proposedGameListItems = [];
        const otherGameListItems = [];
        for (const gameId of Object.keys(this.state.games)) {
            let playersDict = this.state.playersDict[gameId];
            if (playersDict.needsScheduleConcurIds.includes(this.props.localUser.id)) {
                const newGameItem = <GameListItem key={`g-${gameId}`} gameId={gameId} league={this.props.league} localUser={this.props.localUser}/>
                proposedGameListItems.unshift(newGameItem);
            } else if (playersDict.loserPlayerIds.includes(this.props.localUser.id) || playersDict.winnerPlayerIds.includes(this.props.localUser.id)) {
                const newGameItem = <GameListItem key={`g-${gameId}`} gameId={gameId} league={this.props.league} localUser={this.props.localUser}/>
                otherGameListItems.push(newGameItem);
            }
        }

        if (otherGameListItems.length === 0 && proposedGameListItems.length === 0) return(null);

        return(
            <NerdHerderStandardCardTemplate id="schedule-games-card" title="Scheduled Games" titleIcon='calendar.png'>
                {proposedGameListItems.length !== 0 && <div>
                <p>These games have been proposed, but you have not accepted:</p>
                {proposedGameListItems}
                <hr/>
                </div>}
                {otherGameListItems}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueAddGameCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueAddGameCard'>
                <LeagueAddGameCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueAddGameCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();

        this.state = {
            showAddGameModal: false,
        }
    }

    onAddGameCancel() {
        this.setState({showAddGameModal: false});
        this.restPubSub.refresh('league-alerts', this.props.league.id, 200);
        this.restPubSub.refresh('league', this.props.league.id, 400);
        this.restPubSub.refresh('header-alerts', null, 600);
    }
    
    render() {
        return(
            <NerdHerderStandardCardTemplate  id="add-games-card">
                {this.state.showAddGameModal && <NerdHerderAddGameModal localUser={this.props.localUser}
                                                                        league={this.props.league}
                                                                        onCancel={()=>this.onAddGameCancel()} 
                                                                        onAddGame={()=>this.onAddGameCancel()}/>}
                <div className="d-grid gap-2">
                    <Button variant='primary' onClick={()=>this.setState({showAddGameModal: true})}>Add Game</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueGameStatsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueGameStatsCard'>
                <LeagueGameStatsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueGameStatsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            leagueStats: null,
            leagueEloStats: null,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('league-game-stats', this.props.league.id, (d, k)=>{this.updateLeagueStats(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('league-elo-stats', this.props.league.id, (d, k)=>{this.updateLeagueEloStats(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeagueStats(leagueStats, key) {
        this.setState({leagueStats: leagueStats});
    }

    updateLeagueEloStats(eloStats, key) {
        this.setState({leagueEloStats: eloStats});
    }

    standardSorter(player1, player2) {
        // sort by more wins, then less losses, then more ties (which really means more games)
        if (player1.wins > player2.wins) return(-1);
        else if (player2.wins > player1.wins) return(1);

        if (player1.losses < player2.losses) return(-1);
        else if (player2.losses > player1.losses) return(1);

        if (player1.ties > player2.ties) return(-1);
        else if (player2.ties > player1.ties) return(1);
        
        // ok, lower id wins
        if (player1.userId < player2.userId) return (-1);
        else return (1);
    }

    eloSorter(player1, player2) {
        // use the elo table to determine sorting - and if we cant use that table then use the results of
        // standard sorting
        let player1HasElo = false;
        let player2HasElo = false;
        if (player1.hasOwnProperty('elo')) player1HasElo = true;
        if (player2.hasOwnProperty('elo')) player2HasElo = true;
        
        // first sort by elo, higher if both players have it, or if only one player has a rating they rate first
        if (player1HasElo && player2HasElo) {
            if (player1.elo > player2.elo) return(-1);
            else if (player2.elo > player1.elo) return(1);
        } else if (player1HasElo) {
            return (-1);
        } else if (player2HasElo) {
            return (1);
        }

        // if no elo, then more wins, then less losses, then more ties (which really means more games)
        if (player1.wins > player2.wins) return(-1);
        else if (player2.wins > player1.wins) return(1);

        if (player1.losses < player2.losses) return(-1);
        else if (player2.losses > player1.losses) return(1);

        if (player1.ties > player2.ties) return(-1);
        else if (player2.ties > player1.ties) return(1);
        
        // ok, lower id wins
        if (player1.userId < player2.userId) return (-1);
        else return (1);
    }
    
    render() {
        if (this.props.league.games.casual.length === 0) return(null);
        if (this.state.leagueStats === null) return(<NerdHerderLoadingCard title="Casual Game Stats"/>);

        let showEloStats = false;
        if (this.props.league.elo_configuration === 'casual' || this.props.league.elo_configuration === 'all') showEloStats = true;
        if (showEloStats && this.state.leagueEloStats === null) return(<NerdHerderLoadingCard title="Casual Game Stats"/>);

        const playerIds = [];
        const sortablePlayerList = [];
        const statsColumnData = {};
        const eloColumnData = {};
        const casualPlayerStats = this.state.leagueStats.casual;

        for (const [userId, stats] of Object.entries(casualPlayerStats)) {
            const playerId = parseInt(userId);
            const statsDict = {userId: playerId, wins: stats[0], ties: stats[1], losses: stats[2]};
            if (showEloStats && this.state.leagueEloStats.hasOwnProperty(playerId)) {
                statsDict.elo = this.state.leagueEloStats[playerId];
            }
            sortablePlayerList.push(statsDict);
        }

        // if there aren't enough players to make this card valuable, remove it
        if (sortablePlayerList.length < 2) return(null);

        // sort the players, then put that in a list so the table can display them in sorted order
        // if ELO is enabled, use that - otherwise sort normally
        let sorterMethod = this.standardSorter;
        if (showEloStats) sorterMethod = this.eloSorter;
        sortablePlayerList.sort(sorterMethod);
        for (const playerDict of sortablePlayerList) {
            playerIds.push(playerDict.userId);
            statsColumnData[playerDict.userId] = `${playerDict.wins} / ${playerDict.ties} / ${playerDict.losses}`;
            eloColumnData[playerDict.userId] = 'none';
        }

        // if casual games have ELO, populate the column
        if (showEloStats) {
            // however, if there are no stats to show, we'll not show the column
            showEloStats = false;
            for (const userId of Object.keys(this.state.leagueEloStats)) {
                if (eloColumnData.hasOwnProperty(userId)) {
                    eloColumnData[userId] = this.state.leagueEloStats[userId];
                    if (typeof eloColumnData[userId] === 'number') eloColumnData[userId] = eloColumnData[userId].toFixed(2);
                    showEloStats = true;
                }
            }
        }

        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'game',
                focusCard: 'elo-configuration-card',
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="game-stats-card" title='Casual Game Stats' titleIcon="bar-chart.png" manageOptions={manageOptions}>
                {!showEloStats &&
                <TableOfUsers userIds={playerIds} headers={['Player', 'W / T / L']} rightColumnContent={statsColumnData} localUser={this.props.localUser}/>}
                {showEloStats &&
                <TableOfUsers userIds={playerIds} headers={['Player', 'ELO', 'W / T / L']} middleColumnContent={eloColumnData} rightColumnContent={statsColumnData} localUser={this.props.localUser}/>}
            </NerdHerderStandardCardTemplate>
        );
    }
}

class LeagueChatDisabledCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueChatDisabledCard'>
                <LeagueChatDisabledCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueChatDisabledCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }
    
    render() {
        let messageArea = null;
        let snippetArea = null;
        let urlText = null;
        let messageText = null;
        messageText = he.decode(this.props.league.alt_chat_text || 'The real-time chat is disabled.');
        urlText = getUrlFromText(messageText);
        messageArea = <div className='text-break'><LinkifyText>{messageText}</LinkifyText></div>
        if (urlText) {
            snippetArea = <div className='my-2'><OpenGraphCard url={urlText}/></div>
        }

        return(
            <NerdHerderStandardCardTemplate id='chat-disabled-card' title='Chat Disabled' titleIcon='chat.png'>
                {messageArea}
                {snippetArea}
            </NerdHerderStandardCardTemplate>
        );
    }
}

export default withRouter(LeaguePage);
