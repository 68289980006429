import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';

export class NerdHerderBadge extends React.Component {

    render() {
        let position = this.props.position;
        let color = this.props.color || 'red';
        let shape = this.props.shape || 'square';
        let border = this.props.border || true;
        let size = this.props.size || 'small';
        let translate_string = this.props.translate || 'translate-middle';

        let value=this.props.value;
        if (value === 'dot') {
            shape = 'circle';
            value = ' ';
        }
    
        let position_string = '';
        switch (position) {
            case 'top-left':
                position_string = 'top-0 start-0';
                break;
            case 'top':
                position_string = 'top-0 start-50';
                break;
            case 'top-right':
                position_string = 'top-0 start-100';
                break;
            case 'bottom-left':
                position_string = 'top-100 start-0';
                break;
            case 'bottom':
                position_string = 'top-100 start-50';
                break;
            case 'bottom-right':
                position_string = 'top-100 start-100';
                break;
            case 'left':
                position_string = 'top-50 start-0';
                break;
            case 'center':
                position_string = 'top-50 start-50';
                break;
            case 'right':
                position_string = 'top-50 start-100';
                break;
            default:
                position_string = '';
        }

        let border_string = '';
        switch (border) {
            case true:
                border_string = 'border border-light';
                break;
            default:
                border_string = '';
        }

        let size_string = '';
        let style = {};
        switch (size) {
        case'tiny':
            size_string = 'p-1';
            style = {fontSize: '0.3em'};
            break;
        case'small':
            size_string = 'p-1';
            style = {fontSize: '0.5em'};
            break;
        case'medium':
            size_string = 'p-1';
            style = {fontSize: '0.7em'};
            break;
        case'large':
            size_string = 'p-2';
            style = {fontSize: '0.9em'};
            break;
        default:
            size_string = size;
        }

        if (this.props.zIndex) style['zIndex'] = this.props.zIndex;

        let usePill = false;
        switch (shape) {
            case'pill':
            case 'round':
            case 'circle':
                usePill = true;
                break;
            default:
                usePill = false;
        }

        let bg_color_string = 'bg-primary';
        let text_color_string = 'white';
        switch (color) {
            case 'blue':
                bg_color_string = 'primary';
                break;
            case 'grey':
                bg_color_string = 'secondary';
                break;
            case 'gray':
                bg_color_string = 'secondary';
                break;
            case 'green':
                bg_color_string = 'success';
                break;
            case 'red':
                bg_color_string = 'danger';
                break;
            case 'yellow':
                bg_color_string = 'warning';
                text_color_string = 'dark';
                break;
            case 'cyan':
                bg_color_string = 'info';
                text_color_string = 'dark';
                break;
            case 'white':
                bg_color_string = 'light';
                text_color_string = 'dark';
                break;
            case 'black':
                bg_color_string = 'dark';
                break;
            default:
                bg_color_string = color;
        }

        // the more common 'variant' overrides the color stuff
        if (this.props.variant) {
            switch (this.props.variant) {
                case 'primary':
                    bg_color_string = 'primary';
                    break;
                case 'secondary':
                    bg_color_string = 'secondary';
                    break;
                case 'success':
                    bg_color_string = 'success';
                    break;
                case 'danger':
                    bg_color_string = 'danger';
                    break;
                case 'warning':
                    bg_color_string = 'warning';
                    text_color_string = 'dark';
                    break;
                case 'info':
                    bg_color_string = 'info';
                    text_color_string = 'dark';
                    break;
                case 'light':
                    bg_color_string = 'light';
                    text_color_string = 'dark';
                    break;
                case 'dark':
                    bg_color_string = 'dark';
                    break;
                default:
                    bg_color_string = color;
            }
        }

        if (translate_string === null || translate_string === '') {
            translate_string = '';
        }

        if (position_string) {
            position_string = `position-absolute ${position_string} ${translate_string}`
        }

        let innerSpanStyle = {};
        if (this.props.width) {
            innerSpanStyle.display = 'inline-block';
            innerSpanStyle.width = this.props.width;
        }

        return (
            <Badge bg={bg_color_string} text={text_color_string} pill={usePill} className={`${position_string} ${size_string} ${border_string}`} style={style}>
                <span style={innerSpanStyle}>
                    {this.props.fontIcon &&
                    <NerdHerderFontIcon icon={this.props.fontIcon}/>}
                    {this.props.value}
                    {this.props.children}
                </span>
            </Badge>
        )
    }
}

export class NerdHerderBadgeInjector extends React.Component {

    render() {
        return (
            <label className="position-relative">
                {this.props.children}
                {this.props.badge}
            </label>
        )
    }
}

export class NerdHerderTopicBadge extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.topicId === 'undefined' && typeof this.props.topic === 'undefined') console.error('missing props.topicId or props.topic');
    }

    onClick(e, url) {
        e.preventDefault();
        e.stopPropagation();
        window.location.assign(url);
    }
    
    render() {
        let topicId = null;
        let topicName = null;
        let topicUrl = null;

        if (this.props.topic) {
            topicId = this.props.topicId;
            topicName = this.props.topic.name;
        } else {
            if (this.props.topicId) topicId = this.props.topicId;
            if (this.props.topicName) topicName = this.props.topicName;
        }

        if (topicId === null) return;

        topicUrl = `/app/topic/${topicId}`;

        if (topicName === null) {
            return (
                <div className='cursor-help' onClick={(e)=>this.onClick(e, topicUrl)}>
                    <span className="m-0 text-center badge bg-primary">{topicId}</span>
                </div>
            );
        } else {
            return (
                <div className='cursor-help' onClick={(e)=>this.onClick(e, topicUrl)}>
                    <OverlayTrigger placement="top" trigger={["hover","hover"]} delay={{ show: 500, hide: 300 }} overlay={<Tooltip>{this.topicName}</Tooltip>}>
                        <Badge bg="primary">
                            {topicId}
                        </Badge>
                    </OverlayTrigger>
                </div>
            );
        }
    }
}

export class RequiredBadge extends React.Component {
    
    render() {
        return (
            <sup><Badge bg="danger">Required</Badge></sup>
        );
    }
}

export class Required extends React.Component {
    
    render() {
        return (
            <span className='text-danger'>*</span>
        );
    }
}
