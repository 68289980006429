import React from 'react';
import Alert from 'react-bootstrap/Alert';

export class NerdHerderAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: true,
        };
    }

    onClose() {
        this.setState({showAlert: false});
        if (this.props.onCancel) this.props.onCancel();
    }

    render() {
        if (!this.state.showAlert) return(null);
        
        return (
            <Alert className={this.props.className} dismissible variant={this.props.variant || 'primary'} onClose={()=>this.onClose()}>
                {this.props.title &&
                <Alert.Heading>{this.props.title}</Alert.Heading>}
                {this.props.children}
            </Alert>
        )
    }
}
