import React from 'react';
import withRouter from './withRouter';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Fade from 'react-bootstrap/Fade';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderAlert } from './nerdherder-components/NerdHerderAlert';
import { NerdHerderCountdown } from './nerdherder-components/NerdHerderCountdown';
import { NerdHerderTwoColumnPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderPleaseWaitModal, NerdHerderMessageModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderQrCode } from './nerdherder-components/NerdHerderQrCode';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { getLocalStaticFileUrl, handleGlobalRestError, delCookieAfterDelay, getCookie, setCookie } from './utilities';
import { parseTournamentGetResponse, generateTournamentCheckinCode, getCurrentRound } from './tournament_utilities';


class TournamentTimerPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();
        
        this.state = {
            showFullscreenMessageModal: true,
            firebaseSigninComplete: false,
            localUser: null,
            league: null,
            tournamentId: this.props.params.tournamentId,
            tournament: null,
            currentRoundId: null,
            tournamentRoundIds: [],
            tournamentRounds: {},
            games: {},
            usersTournaments: {},
            usersGames: {},
            usersCache: {},
            players: {},
            clockStarted: null,
            secondsRemaining: 0,
            run: false,
            toRequests: [],
        }
            
        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.firestoreSigninComplete(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    firestoreSigninComplete() {
        this.setState({firebaseSigninComplete: true});
        let sub = this.restPubSub.subscribeToFirestore('tournaments', this.state.tournamentId, (d, k) => {this.updateTournament(d, k)});
        this.restPubSubPool.add(sub);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateTournament(tournamentData, key) {
        const tournament = NerdHerderDataModelFactory('tournament', tournamentData);

        // update the league - should be a one-time thing
        if (this.state.league === null) {
            const sub = this.restPubSub.subscribeToFirestore('leagues', tournament.league_id, (d, k) => {this.updateLeague(d, k)});
            this.restPubSubPool.add(sub);
        }

        // update the managers
        for (const managerUserId of tournament.manager_ids) {
            if (this.state.usersCache.hasOwnProperty(managerUserId)) continue;
            const sub = this.restPubSub.subscribeToFirestore('users', managerUserId, (d, k) => {this.updateUser(d, k)}, null, managerUserId);
            this.restPubSubPool.add(sub);
        }

        // for simplicity, keep our own copy of the round IDs
        const updatedRoundIds = [...tournament.round_ids];

        // get the users_tournaments, round, users_games, game, and user info out of the tournament response
        const updatedUsersTournaments = {};
        const updatedRounds = {};
        const updatedGames = {};
        const updatedUsersGames = {};
        const updatedPlayers = {};
        let newCurrentRoundId = null;

        parseTournamentGetResponse(tournament, updatedUsersTournaments, updatedRounds, updatedGames, updatedUsersGames, updatedPlayers);
        let currentRound = getCurrentRound(tournament);
        let remaining = 0;
        let clockStarted = null;
        let run = false;
        if (currentRound) {
            newCurrentRoundId = currentRound.id;
            clockStarted = updatedRounds[newCurrentRoundId].clock_started;
            remaining = updatedRounds[newCurrentRoundId].remaining;
            run = updatedRounds[newCurrentRoundId].clock_running;
        }

        this.setState({
            tournament: tournament,
            currentRoundId: newCurrentRoundId,
            usersTournaments: updatedUsersTournaments,
            tournamentRoundIds: updatedRoundIds,
            tournamentRounds: updatedRounds,
            games: updatedGames,
            usersGames: updatedUsersGames,
            players: updatedPlayers,
            clockStarted: clockStarted,
            secondsRemaining: remaining,
            run: run,
        });
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league}); 
    }

    updateUser(userData, userId) {
        this.updateUsersCache(userData);
    }

    updateUsersCache(userData) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [newUser.id]: newUser}}
        });
    }

    handleTORequestMessage(messageLabel, message) {
        const newToRequests = [...this.state.toRequests];
        newToRequests.push(message.message_string);
        this.setState({toRequests: newToRequests});
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return(<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return(<NerdHerderLoadingModal />);
        if (!this.state.tournament) return(<NerdHerderLoadingModal />);
        if (!this.state.league) return(<NerdHerderLoadingModal />);
        
        // if there's no round...
        if (!this.state.currentRoundId) {
            return(<NerdHerderPleaseWaitModal userFeedback='Waiting for Round 1 to be created...'/>);
        }

        let localUserIsManager = this.state.tournament.isManager(this.state.localUser.id);
        if (!localUserIsManager) return(<NerdHerderErrorModal errorFeedback='You must be a TO to view this page...'/>);

        const allCardsProps = {
            tournament: this.state.tournament,
            tournamentRoundIds: this.state.tournamentRoundIds,
            currentRoundId: this.state.currentRoundId,
            tournamentRounds: this.state.tournamentRounds,
            games: this.state.games,
            usersTournaments: this.state.usersTournaments,
            usersGames: this.state.usersGames,
            usersCache: this.state.usersCache,
            players: this.state.players,
            league: this.state.league,
            localUser: this.state.localUser,
            clockStarted: this.state.clockStarted,
            secondsRemaining: this.state.secondsRemaining,
            run: this.state.run,
        }

        return (
            <NerdHerderTwoColumnPageTemplate
                pageName='tournament_management'
                leftColumnSize={9}
                headerSelection='leagues'
                headerHidden={true}
                toastsHidden={true}
                allowLocalUserRefresh={true}
                dropdownSelection={this.state.league.name}
                disableRefresh={false}
                league={this.state.league}
                localUser={this.state.localUser}>

                {this.state.showFullscreenMessageModal &&
                <NerdHerderMessageModal title='Fullscreen' message='If desired, press F11 to toggle fullscreen. This message will automatically close.' autoCancelDuration='3000' onCancel={()=>this.setState({showFullscreenMessageModal: false})}/>}
                <RoundClockCard {...allCardsProps} toRequests={this.state.toRequests} nerdHerderTwoColumnPageTemplateSide='left'/>
                <TournamentDetailsCard {...allCardsProps} nerdHerderTwoColumnPageTemplateSide='right'/>
            </NerdHerderTwoColumnPageTemplate>
        );
                
                
    }
}

class TournamentDetailsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentDetailsCard'>
                <TournamentDetailsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentDetailsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
        this.noRoomForQrCode = false;
        
        this.state = {
            fadeState: 'details',
        }
    }

    componentDidMount() {
        this.updateFadeState('detailsIn');
    }

    updateFadeState(newState) {
        // if there's no room for the qr, just set the state to details
        if (this.noRoomForQrCode) {
            this.setState({fadeState: 'details'});
            setTimeout(()=>this.updateFadeState('details'), 6000);
        }
        // but if there is room, follow this pattern
        // detailsIn -> details -> detailsOut -> qrCodeIn -> qrCode -> qrCodeOut -> detailsIn
        else {
            this.setState({fadeState: newState});
            switch(newState) {
                case 'detailsIn':
                    setTimeout(()=>this.updateFadeState('details'), 300);
                    break;
                case 'details':
                    setTimeout(()=>this.updateFadeState('detailsOut'), 6000);
                    break;
                case 'detailsOut':
                    setTimeout(()=>this.updateFadeState('qrCodeIn'), 300);
                    break;
                case 'qrCodeIn':
                    setTimeout(()=>this.updateFadeState('qrCode'), 300);
                    break;
                case 'qrCode':
                    setTimeout(()=>this.updateFadeState('qrCodeOut'), 6000);
                    break;
                case 'qrCodeOut':
                    setTimeout(()=>this.updateFadeState('detailsIn'), 300);
                    break;
                default:
                    this.setState({fadeState: 'details'});
            }
        }
    }

    render() {
        let realtimeTournamentHref = `/app/tournament/${this.props.tournament.id}`;
        if (this.props.tournament.require_checkin) {
            const manualCode = generateTournamentCheckinCode(this.props.tournament.id);
            realtimeTournamentHref = `/app/tournament/${this.props.tournament.id}?checkin=${manualCode}`;
        }
        
        // for the number of rounds we use the larger of num_rounds or how many rounds actually exist
        let numberOfRounds = this.props.tournament.num_rounds;
        if (this.props.tournament.round_ids.length > numberOfRounds) numberOfRounds = this.props.tournament.round_ids.length;

        // calculate the size of the QR code - don't let it go past the width of the image
        const divHeight = window.innerHeight - 70;
        let qrSize = parseInt(divHeight/4);
        if (this.imageRef.current) {
            const imageHeight = this.imageRef.current.getBoundingClientRect().height;
            const imageWidth = this.imageRef.current.getBoundingClientRect().width;
            qrSize = divHeight - imageHeight - 115;
            if (qrSize > imageWidth) qrSize = imageWidth;
        }
        if (qrSize < 100) {
            this.noRoomForQrCode = true;
        } else {
            this.noRoomForQrCode = false;
        }

        return(
            <NerdHerderStandardCardTemplate id='tournament-details-card'>
                <div style={{height: `${divHeight}px`}}>
                    <Row>
                        <Col xs={12}>
                            <div ref={this.imageRef} className='p-4' style={{position: 'relative'}}>
                                <Image fluid src={this.props.league.getImageUrl()}/>
                                <Image roundedCircle width={120} src={this.props.tournament.getImageUrl()} style={{backgroundColor: 'white', border: '5px solid #0d6efd', position: 'absolute', bottom:'0px', right:'25px'}}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {this.state.fadeState.includes('qrCode') && this.noRoomForQrCode === false &&
                            <Fade in={this.state.fadeState === 'qrCode'}>
                                <div>
                                    <Row className='m-4 justify-content-center'>
                                        <Col xs='auto' className='text-center'>
                                            <big><b>Follow the QR code to get to this tournament!</b></big>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col xs='auto'>
                                            <NerdHerderQrCode size={qrSize} href={realtimeTournamentHref}/>
                                        </Col>
                                    </Row>
                                </div>
                            </Fade>}
                            {this.state.fadeState.includes('details') &&
                            <Fade in={this.state.fadeState === 'details'}>
                                <div>
                                    <div className='text-center my-3'>
                                        <big><b>{this.props.tournament.summary}</b></big>
                                    </div>
                                    <Table>
                                        <tbody>
                                            <tr><td>State</td><td><small>{this.props.tournament.getShortStatusString()}</small></td></tr>
                                            <tr><td>Schedule</td><td><small>{this.props.tournament.getScheduleString()}</small></td></tr>
                                            <tr><td>Ranking</td><td><small>{this.props.tournament.getRankingString()}</small></td></tr>
                                            <tr><td>Rounds</td><td><small>{numberOfRounds}</small></td></tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Fade>}
                        </Col>
                    </Row>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class RoundClockCard extends React.Component {

    render() {
        return (
            <CardErrorBoundary cardTypeName='RoundClockCard'>
                <RoundClockCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class RoundClockCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.messageDivRef = null;

        this.setRef = ref => {
            let oldref = this.messageDivRef;
            this.messageDivRef = ref;
            if (oldref === null) this.forceUpdate();
        }

        this.cookieName = `tournament-${this.props.tournament.id}-message`;
        let initialMessage = getCookie(this.cookieName, 'Welcome%20to%20the%20tournament!');
        initialMessage = decodeURIComponent(initialMessage);
        this.setCookieTimeout = null;
        
        this.state = {
            progressBarValue: 0,
            timerExpired: false,
            toRequestAlertsCanceled: [],
            formTournamentMessage: initialMessage
        }
    }

    updateState(running, secondsRemaining) {
        if (!this.props.currentRoundId) return;
        let currentRound = this.props.tournamentRounds[this.props.currentRoundId];
        let progressBarValue = 0;
        let timerExpired = this.state.timerExpired;
        if (currentRound.duration) {
            progressBarValue = currentRound.duration - secondsRemaining;
            if (secondsRemaining > 0) timerExpired = false;
        }
        this.setState({progressBarValue: progressBarValue, timerExpired: timerExpired});
    }

    updateTick(secondsRemaining) {
        if (!this.props.currentRoundId) return;
        let currentRound = this.props.tournamentRounds[this.props.currentRoundId];
        let progressBarValue = 0;
        let timerExpired = this.state.timerExpired;
        if (currentRound.duration) {
            progressBarValue = currentRound.duration - secondsRemaining;
            if (secondsRemaining > 0) timerExpired = false;
        }
        this.setState({progressBarValue: progressBarValue, timerExpired: timerExpired});
    }

    updateComplete() {
        if (!this.props.currentRoundId) return;
        let currentRound = this.props.tournamentRounds[this.props.currentRoundId];
        let progressBarValue = 0;
        if (currentRound.duration) {
            progressBarValue = currentRound.duration;
        }
        this.setState({progressBarValue: progressBarValue, timerExpired: true});
    }

    handleTournamentMessageChange(event) {
        const value = event.target.value;
        this.setState({formTournamentMessage: value});
        if (this.setCookieTimeout) clearTimeout(this.setCookieTimeout);
        this.setCookieTimeout = setTimeout(()=>{
            setCookie(this.cookieName, encodeURIComponent(value), 10);
        }, 5000);
    }

    cancelToAlert(index) {
        const newCancelList = [...this.state.toRequestAlertsCanceled, index];
        this.setState({toRequestAlertsCanceled: newCancelList});
    }
    
    render() {
        let currentRound = null;
        if (this.props.currentRoundId) {
            currentRound = this.props.tournamentRounds[this.props.currentRoundId];
        }

        const toRequestAlerts = [];
        let index = 0;
        for (const toRequestString of this.props.toRequests) {
            const key = index;
            index++;
            if (this.state.toRequestAlertsCanceled.includes(key)) continue;
            const newAlert = 
                <NerdHerderAlert key={key} dismissible variant='danger' title='Organizer Help Request!' onCancel={()=>this.cancelToAlert(key)}>
                    <big>{toRequestString}</big>
                </NerdHerderAlert>
            toRequestAlerts.push(newAlert);
        }

        const divHeight = window.innerHeight - 70;

        let taHeight = 0;
        if (this.messageDivRef) {
            let rect = this.messageDivRef.getBoundingClientRect();
            let top = rect.top;
            taHeight = divHeight - top + 20;
            if (taHeight < 50) taHeight = 0;
        }

        let tournamentMessageBox = 
            <div ref={this.setRef}>
                {taHeight !== 0 && 
                <Form>
                    <Form.Control type="text" as="textarea" onChange={(e)=>this.handleTournamentMessageChange(e)} value={this.state.formTournamentMessage} style={{fontSize: '6vh', height: `${taHeight}px`, resize: 'none'}}/>
                </Form>}
            </div>

        let alertsShowing = toRequestAlerts.length;

        return(
            <NerdHerderStandardCardTemplate id='round-clock-card'>
                <div style={{height: `${divHeight}px`}}>
                    <Row>
                        <Col xs={2}>
                            <Image fluid src={getLocalStaticFileUrl('/nerdherder_light_512.png')} alt="nerdherder logo"/>
                        </Col>
                        <Col xs={10}>
                            <div style={{fontSize: '8vh'}}>
                                <b>{this.props.tournament.name}</b>
                            </div>
                            {currentRound && currentRound.state !== 'complete' &&
                            <div style={{fontSize: '6vh'}}>
                                <b>{currentRound.name}</b>
                            </div>}
                            {currentRound && currentRound.state === 'complete' &&
                            <div style={{fontSize: '6vh'}}>
                                <b>{currentRound.name} Complete!</b>
                            </div>}
                        </Col>
                    </Row>
                    {currentRound && currentRound.state !== 'complete' &&
                    <Row className='mt-4 justify-content-center'>
                        <Col xs='auto'>
                            <div className='px-4 py-3' style={{border: '1vw solid black', borderRadius: '15px'}}>
                                <div style={{fontSize: '15vh', fontFamily: 'monospace'}}>
                                    <b>
                                        <NerdHerderCountdown roundId={this.props.currentRoundId} onState={(r, sr)=>this.updateState(r, sr)} onTick={(sr)=>this.updateTick(sr)} onComplete={()=>this.updateComplete()}/>
                                    </b>
                                </div>
                                <ProgressBar striped animated variant={this.state.timerExpired?'danger':'primary'} min={0} max={currentRound.duration || 100} now={this.state.progressBarValue}/>
                            </div>
                        </Col>
                    </Row>}
                    
                    <Row className='mt-4'>
                        {alertsShowing !== 0 &&
                        <Col>
                            {toRequestAlerts}
                        </Col>}
                        <Col>
                            {tournamentMessageBox}
                        </Col>
                    </Row>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(TournamentTimerPage);
