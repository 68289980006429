import React from 'react';
import withRouter from './withRouter';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { NerdHerderNavigate } from './nerdherder-components/NerdHerderNavigate';
import { ScreenSizeSelector } from './nerdherder-components/NerdHerderDisplayHelpers';
import { NerdHerderSelectAccountTypeModal, NerdHerderCreateGoogleAccountModal } from './nerdherder-components/NerdHerderModals';
import { getStaticStorageImageFilePublicUrl, getLocalStaticFileUrl, isMobileDevice } from './utilities';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navigateTo: null,
            showTopContent: false,
            showCreateGoogleAccountModal: false,
            showSelectAccountTypeModal: false,
            scrollPosition: 0,
            tagPhraseState: 'increasing',
            tagPhraseIndex: 0,
            tagPhraseOpacity: 0.3
        }

        this.tagPhraseTimeout = setTimeout(()=>this.tagPhraseTimeoutHandler(), 100);
        this.tagPhrases = [
            'A tabletop hobby organizer & community builder!',
            '...for when you need more than a tournament organizer!',
            'Great for players, organizers, and venue owners!',
        ];

        this.googleJwt = null;
        this.disableAutoScroll = false;
        this.timeout = null;
        this.featureList = [];
        this.maxFeatureCount = 0;
        this.featureCount = 1;
        let feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Herd Your Nerds!';
        feature['description'] =
            <p>
                Find yourself wondering how many people are going to join the league or tournament? Who's who on the Discord server? Message all your players?
                Painted an awesome mini - want to share it? Need to get a game in this week...who is available tonight?
                <br/>
                <br/>
                <strong className="text-primary">NerdHerder assists tournament, league, and special event organizers manage their players, and helps the players help themselves!</strong>
            </p>
        feature['imageUrl'] = '/homepage_images/homepage_top_image3_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'NerdHerder for Miniature Wargames'
        feature['description'] = 
            <p>
                Multi-week skirmish league? Culminates in a tournament? An escalation or growth league?
                Recurring wargame or tabletop night at the LGS?
                Each week has slightly different narrative driven rules?
                Tournaments where player's lists change?
                <br/>
                <br/>
                <strong className="text-primary">NerdHerder can do all this and more.</strong>
            </p>
        feature['imageUrl'] = '/homepage_images/warhammer_40K_600px_80pct.webp';
        this.featureList.push(feature)

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Not Only Miniatures! More than Scores!';
        feature['description'] = 
            <p>
                RPG campaign spanning months, potentially with players being added and removed ad-hoc? Multiple GMs sharing secret info? Painting competition?
                Card Games like Pokemon, MTG, or Yu-Gi-Oh? Competitive board gaming? A group of friends that simply want to track who has won the most game nights?
                <br/>
                <br/>
                <strong className="text-primary">With NerdHerder, there can be so much more to the game than the score.</strong>
            </p>
        feature['imageUrl'] = '/homepage_images/d-and-d_600px_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Fantastic Tournament Support';
        feature['description'] = 
            <p>
                Although NerdHerder can do so much more than tournaments, it <b>excels</b> at tournaments.
                Supports Round-Robin, Swiss, and Elimination. Match Points, Margin of Victory, or Victory Point pairing...Strength of Schedule...all that.
                Single or double elimination tournaments are also configurable.
                <br/>
                <br/>
                Something more complicated? Maybe 3 rounds of swiss then cut to top 4? Pool based? Triple elimination? Wildcard selection? Players dropping or adding mid-tournament?
                <br/>
                <br/>
                <strong className="text-primary">Multiple tournaments can exist in a single league or event - and you can carry the seeds or scores from one to the next allowing any format to be run.</strong>
            </p>
        feature['imageUrl'] = '/homepage_images/tournament_d20_white_600px_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Tournament Streamlining';
        feature['description'] =
            <div>
                Large tournaments can be a logistical nightmare, especially before the tournament begins. Checking in each player, reviewing their list(s), taking payment... NerdHerder has streamlining features to simplify this process - no need to meet with every player before the event starts:
                <div className='text-start'>
                    <small>
                        <ul>
                            <li>Army Lists: players upload & share with opponents - no paper!</li>
                            <li>Registration Fees: payments may be taken during registration through NerdHerder</li>
                            <li>Check-In: players check in via QR code scanned on the day of the tournament</li>
                            <li>Real-Time: a "clock page" can be displayed, answering most questions before they are asked</li>
                        </ul>
                    </small>
                </div>
                <strong className="text-primary">Skip the lines! Use NerdHerder to run your large tournament and focus on the tournament, not the logistics.</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/standing_in_line_600px_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = '"Out of the Box" Scoring Formats...';
        feature['description'] = 
            <div>
                You might have a crazy achievement point system that awards both winning, painting, recruiting players, rewarding antics, whatever...
                More than you can track on any site. But we've got you! If your data can be modeled in a Google spreadsheet - you can post it in NerdHerder! We've got integration with Google sheets.
                <br/>
                <br/>
                <strong className="text-primary">Run whatever wild scoring system you want, and present that data to your players as if it was built-in.</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/spreadsheet_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Army Builder Integration';
        feature['description'] = 
            <div>
                NerdHerder doesn't replace your favorite Army / List / Team / Roster builder. We can accept a list in virtually any format (image, text, pdf, hyperlink). All you need to do is paste or drag-n-drop that list to easily share it with an opponent.
                <br/>
                <br/>
                Finally, NerdHerder has integration with a few builders allowing you to select your lists directly from NerdHerder:
                <div className='text-center'><small><b>Tabletop Admiral - Cerebro MCP - Point Break</b></small></div>
                <br/>
                <br/>
                <strong className="text-primary">No need for paper lists - share your list directly from NerdHerder!</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/integration_600px_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Rules and Files and Links - Oh My!';
        feature['description'] = 
            <div>
                Organizers can upload files to NerdHerder - event packs, league rules, tournament formats, blank army list pages, etc.
                In addition, they can add links to any content on the web. Players can find answers themselves, instead of having to go to the organizers.
                <br/>
                <br/>
                <strong className="text-primary">All your documents and rules, organized together in one place.</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/wargame_600_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'LGS or Venue Owner?';
        feature['description'] = 
            <div>
                NerdHerder's development team includes two LGS owners and was designed with retailers in mind. League,
                event, and tournament organizers are separate - allowing you to encourage your "alpha players" to run things. Organizers manage the event, while you manage your establishment.
                <br/>
                <br/>
                The #1 way to get more players (and therfore more revenue) is to build the local community through multi-week leagues. Growth leagues work especially well, and is what NerdHerder was specifically created for.
                <br/>
                <br/>
                <strong className="text-primary">NerdHerder enables LGS owners to increase their player base (and revenue) with minimal effort.</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/game_store_600px_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Stripe For Payments';
        feature['description'] = 
            <div>
                NerdHerder has partnered with Stripe to process payments. Venues create a Stripe account, and the financial details are kept secret from NerdHerder and event organizers. Stripe is used by Target and Amazon - it's safe and secure.
                <br/>
                <br/>
                When players join an event at your venue, they can easily pay any registration fee. Funds are paid out to the venue's financial institution daily.
                <br/>
                <br/>
                <strong className="text-primary">Seamless payments, safe and secure.</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/stripe_wordmark.png';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Advertise Your Event. or Not.';
        feature['description'] = 
            <div>
                NerdHerder makes it easy to find events, tournaments, and leagues by zip or postal code, or sharing and advertising locally with QR codes.
                <br/>
                <br/>
                Alternatively, make your event private. You can have players join by request, or set a password, set a player cap, or invite the world.
                <br/>
                <br/>
                <strong className="text-primary">NerdHerder simplifies sharing your league...or keeping it a secret.</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/homepage_top_image2_80pct.webp';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Mobile First Approach';
        feature['description'] = 
            <div>
                NerdHerder isn't just a website - it is an installable web application with an interface designed for Android and iPhone users. Of course tablets and PCs work great as well.
                <br/>
                <br/>
                Whether you are playing at home with your family, across the table at the FLGS, or at Adepticon, the computer everybody has access to is in their phone.
                <br/>
                <br/>
                <strong className="text-primary">No matter where you are, if you have internet you have NerdHerder!</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/mobile_first_600.jpg';
        this.featureList.push(feature);

        feature = {};
        feature['id'] = `feature-${this.featureCount++}`;
        feature['title'] = 'Finally Ditch Facebook';
        feature['description'] = 
            <div>
                Break free from social media as an event or league communication tool. NerdHerder is privacy focused.
                Usernames are used throughout the site, and "Real Life" details
                are only displayed when two users are actively participating in an event, league or tournament together. When the league concludes, that info is once again hidden.
                <br/>
                <br/>
                <strong className="text-primary">Your information is never shared outside of NerdHerder.</strong>
            </div>
        feature['imageUrl'] = '/homepage_images/thumbs-down.jpg';
        this.featureList.push(feature);
        
        // save the max count and reset feature count (used by auto scroll)
        this.isMobileDevice = isMobileDevice();
        this.maxFeatureCount = this.featureCount;
        this.featureCount = 1;

        // show the external links after a little bit
        this.showFixedTopContentTimer = null;

        // hook the user interactions, so if the user does anything we can stop autoscrolling
        document.body.addEventListener('mousemove', (e)=>this.stopAutoScroll(e));
        document.body.addEventListener('keydown', (e)=>this.stopAutoScroll(e));
        document.body.addEventListener('click', (e)=>this.stopAutoScroll(e));
        document.body.addEventListener('touchstart', (e)=>this.stopAutoScroll(e));

        // hook the scroll function, as we need to adjust the text fade
        window.addEventListener('scroll', (e)=>this.doFeatureAnimation(e))
    }

    componentDidMount() {
        this.timeout = setTimeout(()=>this.doFeatureAutoScroll(), 5000);
    }

    stopAutoScroll(event) {
        if (!this.disableAutoScroll) {
            if (this.timeout) clearTimeout(this.timeout);
            this.disableAutoScroll = true;
        }
    }

    doFeatureAutoScroll() {
        if (!this.disableAutoScroll) {
            let targetId = `feature-${this.featureCount++}`;
            let targetElement = document.getElementById(targetId);
            // there is no feature-0, instead scroll to top
            if (targetId === 'feature-0') {
                window.scrollTo(0, 0);
            }
            else {
                targetElement.scrollIntoView({behavior: "smooth", block: "center"});
            }
            // reset when needed
            if (this.featureCount === this.maxFeatureCount) {
                this.featureCount = 0;
            }
            this.timeout = setTimeout(()=>this.doFeatureAutoScroll(), 10000);
        }
    }

    doFeatureAnimation(event) {
        let position = window.scrollY;
        this.setState({scrollPosition: position});
    }

    onCreateGoogleAccount(googleJwt) {
        this.googleJwt = googleJwt;
        this.setState({showSelectAccountTypeModal: false, showCreateGoogleAccountModal: true});
    }

    tagPhraseTimeoutHandler() {
        let nextHandlerDelay = 30;
        switch(this.state.tagPhraseState) {
            case 'increasing':
                if (this.state.tagPhraseOpacity < 1.0) {
                    this.setState({tagPhraseOpacity: this.state.tagPhraseOpacity+0.1});
                } else {
                    this.setState({tagPhraseOpacity: 1.0, tagPhraseState: 'showing'});
                }
                break;
            case 'decreasing':
                if (this.state.tagPhraseOpacity > 0.0) {
                    this.setState({tagPhraseOpacity: this.state.tagPhraseOpacity-0.1});
                } else {
                    let nextTagPhraseIndex = this.state.tagPhraseIndex + 1;
                    if (nextTagPhraseIndex >= this.tagPhrases.length) nextTagPhraseIndex = 0;
                    this.setState({tagPhraseOpacity: 0.0, tagPhraseState: 'increasing', tagPhraseIndex: nextTagPhraseIndex});
                }
                break;
            default: // showing
                nextHandlerDelay = 1200
                this.setState({tagPhraseState: 'decreasing'});
        }
        
        this.tagPhraseTimeout = setTimeout(()=>this.tagPhraseTimeoutHandler(), nextHandlerDelay);
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);

        const featureItemList = [];
        let index=0;
        for (const feature of this.featureList) {
            let side='left';
            if (index % 2 === 0) side = 'right';
            const featureItem = <HomePageFeature key={index++} side={side} feature={feature} scrollPosition={this.state.position}/>
            featureItemList.push(featureItem);
        }

        if (this.showFixedTopContentTimer === null) this.showFixedTopContentTimer = setTimeout(()=>{this.setState({showTopContent: true})}, 800);
        let showExternalLinks = this.state.showTopContent;
        if (this.state.scrollPosition > 500) showExternalLinks = false;

        const nhLogoUrl = getLocalStaticFileUrl('/nerdherder_light.webp');
        const apdLogoUrl = getLocalStaticFileUrl('/apd_logo_color.png');
        const bgImageUrl = getStaticStorageImageFilePublicUrl('/homepage_images/scythe_1920_80pct.webp');

        let tagPhrase = this.tagPhrases[this.state.tagPhraseIndex];

        return(
            <section style={{backgroundImage: `url(${bgImageUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center center'}}>
                <Container fluid className="fixed-top">
                    <Row className="my-2">
                        <Col xs={6} >
                            <Collapse in={showExternalLinks}>
                                <div>
                                    <a className='mx-2' href='https://www.facebook.com/people/NerdHerder/100081615949450/' target='_blank' rel="noreferrer">
                                        <Image height={36} alt='nerdherder facebook link' src={getStaticStorageImageFilePublicUrl('/facebook.png')}/>
                                    </a>
                                    <a className='mx-1' href='https://discord.gg/4EzERQQxzQ' target='_blank' rel="noreferrer">
                                        <Image height={36} alt='nerdherder discord link' src={getStaticStorageImageFilePublicUrl('/discord.png')}/>
                                    </a>
                                    <a className='mx-2' href='https://www.youtube.com/@nerdherderapp' target='_blank' rel="noreferrer">
                                        <Image height={36} alt='nerdherder youtube link' src={getStaticStorageImageFilePublicUrl('/youtube.png')}/>
                                    </a>
                                </div>
                            </Collapse>
                        </Col>
                        <Col xs={6} className="text-end">
                            <Collapse in={this.state.showTopContent}>
                                <div>
                                    <Button className="mt-2" onClick={()=>this.setState({showSelectAccountTypeModal: '/app/newuser', doFeatureScroll: false})}>Sign Up</Button>
                                    {' '}
                                    <Button className="mt-2" onClick={()=>this.setState({navigateTo: "/app/login", doFeatureScroll: false})}>Login</Button>
                                </div>
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <GoogleOAuthProvider clientId='986217896990-4jekqeps6512tpjcere4ucnhkur5ps96.apps.googleusercontent.com'>
                        {this.state.showSelectAccountTypeModal &&
                        <NerdHerderSelectAccountTypeModal onAccept={(j)=>this.onCreateGoogleAccount(j)} onCancel={()=>this.setState({showSelectAccountTypeModal: false})}/>}
                        {this.state.showCreateGoogleAccountModal &&
                        <NerdHerderCreateGoogleAccountModal googleJwt={this.googleJwt} onCancel={()=>this.setState({showCreateGoogleAccountModal: false})}/>}
                    </GoogleOAuthProvider>

                    <Row style={{height: '25vh'}}>
                        <Col>
                        </Col>
                    </Row>

                    {/* logo on medium & large devices */}
                    <ScreenSizeSelector min='md'>
                        <Row className="justify-content-center bg-white">
                            <Col md={4}>
                                <Row className="p-4">
                                    <Col className="text-center">
                                        <Image src={nhLogoUrl} alt="nerdherder logo" height="200px"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="p-4 align-items-center">
                                    <Col className="text-center">
                                        <h1 className="text-primary" style={{fontSize: '4vw'}}><strong>NerdHerder</strong></h1>
                                        <h2 className="text-primary" style={{fontSize: '2vw', opacity: `${this.state.tagPhraseOpacity}`}}>{tagPhrase}</h2>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ScreenSizeSelector>

                    {/* logo on small devices */}
                    <ScreenSizeSelector max='sm'>
                        <Row className="justify-content-center bg-white">
                            <Col>
                                <Row className="p-4">
                                    <Col className="text-center">
                                        <Image src={nhLogoUrl} alt="nerdherder logo" height="200px"/>
                                    </Col>
                                </Row>
                                <Row className="p-4 align-items-center">
                                    <Col className="text-center">
                                        <h1 className="text-primary" style={{fontSize: '10vw'}}><strong>NerdHerder</strong></h1>
                                        <h2 className="text-primary" style={{fontSize: '5vw', opacity: `${this.state.tagPhraseOpacity}`}}>{tagPhrase}</h2>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ScreenSizeSelector>

                    <Row style={{height: '20vh'}}>
                        <Col>
                        </Col>
                    </Row>

                    {featureItemList}

                    <Row className="justify-content-center bg-black">
                        <Col className='py-3' xs='auto'>
                            <Image src={apdLogoUrl} alt="ashparkdigital logo" height="100px"/>
                        </Col>
                        <Col>
                            <Row>
                                <Col md={4} className="text-start py-3">
                                    <h3 className="text-light">Contact</h3>
                                    <div className="text-light">Ash Park Digital, LLC</div>
                                    <div className="text-light">helpdesk@ashparkdigital.com</div>
                                </Col>
                                <Col md={4} className="text-start py-3">
                                    <h3 className="text-light">Social</h3>
                                    <div>
                                        <a className="text-light text-decoration-none" href='https://www.facebook.com/people/NerdHerder/100081615949450/' target='_blank' rel="noreferrer">
                                            <Image height={20} alt='nerdherder facebook link' src={getStaticStorageImageFilePublicUrl('/facebook.png')}/> Facebook
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-light text-decoration-none" href='https://discord.gg/4EzERQQxzQ' target='_blank' rel="noreferrer">
                                            <Image height={20} alt='nerdherder discord link' src={getStaticStorageImageFilePublicUrl('/discord.png')}/> Discord
                                        </a>
                                    </div>
                                    <div>
                                        <a className="text-light text-decoration-none" href='https://www.youtube.com/@nerdherderapp' target='_blank' rel="noreferrer">
                                            <Image height={20} alt='nerdherder youtube link' src={getStaticStorageImageFilePublicUrl('/youtube.png')}/> YouTube
                                        </a>
                                    </div>
                                </Col>
                                <Col md={4} className="text-start py-3">
                                    <h3 className="text-light">Links</h3>
                                    <div className="text-light"><a href='/app/features'>Full Feature List</a></div>
                                    <div className="text-light"><a href='/app/qrpage'>Shareable QR Code</a></div>
                                    <div className="text-light"><a href='/terms'>Site Policies</a></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

class HomePageFeature extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            textSectionRef: React.createRef(),
            textSectionLgRef: React.createRef(),
            textSectionSmRef: React.createRef(),
        }
    }

    render() {
        const imageUrl = getStaticStorageImageFilePublicUrl(this.props.feature.imageUrl);

        // we can get into strange situations with this fade, default to fully in and only do the fade if things look good
        // need to have exactly 1 good bounding rect - a rect with top=0 is not good
        let divOpacity = 1.0;
        if (this.state.textSectionRef.current) {
            let boundingRect = this.state.textSectionRef.current.getBoundingClientRect();
            let targetWindowInnerHeight = window.innerHeight * 0.5;
            let boundingRectTop = boundingRect.top;
            // the text section is actually 25% of view height down...
            boundingRectTop += window.innerHeight * 0.25;
            if (boundingRectTop < targetWindowInnerHeight) {
                // past half way - opacity is full
                divOpacity = 1.0;
            } else if (boundingRectTop > window.innerHeight) {
                // not showing - opacity is clear
                divOpacity = 0;
            } else {
                // do a ratio to determine opacity based on how close we are to the middle of the screen
                divOpacity = 1.0 - (boundingRectTop - targetWindowInnerHeight)/(targetWindowInnerHeight);
            }
        }

        return(
            <div id={this.props.feature.id} ref={this.state.textSectionRef} style={{opacity: divOpacity}}>
                <Row style={{height: '25vh'}}>
                    <Col>
                    </Col>
                </Row>

                {/* feature area on medium and large devices */}
                <ScreenSizeSelector min='md'>
                    <Row className="bg-white justify-content-center align-items-center">
                        <Col lg={6}>
                            <Row className="p-5">
                                <Col className="text-center">
                                    <Image fluid className="rounded-3" src={imageUrl} alt="feature image"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Row className="p-5">
                                <Col className="text-center">
                                    <div id='textSectionLg' ref={this.state.textSectionLgRef}>
                                        <h1>{this.props.feature.title}</h1>
                                        <big>{this.props.feature.description}</big>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ScreenSizeSelector>

                {/* feature area on small devices */}
                <ScreenSizeSelector max='sm'>
                    <Row className="bg-white justify-content-center align-items-center">
                        <Col>
                            <Row className="pt-3">
                                <Col className="text-center">
                                    <Image fluid className="rounded-3" src={imageUrl} alt="feature image"/>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col className="text-center">
                                    <div id='textSectionSm' ref={this.state.textSectionSmRef}>
                                        <h1>{this.props.feature.title}</h1>
                                        <div>{this.props.feature.description}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ScreenSizeSelector>

                <Row style={{height: '20vh'}}>
                    <Col>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(HomePage);
