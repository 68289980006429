import currency from 'currency.js';
import { DateTime } from 'luxon';

export function getPublicStorageUrl() {
    return 'https://storage.googleapis.com';
}

export function getStorageFilePublicUrl(filename) {
    return getPublicStorageUrl() + '/league_data_storage' + filename
}

export function getStaticStorageImageFilePublicUrl(filename) {
    return getPublicStorageUrl() + '/league_data_storage/static' + filename;
}

export function getStaticStorageSoundFilePublicUrl(filename) {
    return getLocalStaticFileUrl('/sound' + filename);
}

export function getUiIconUrl(iconPack, iconVariant, iconName) {
    const fullFilename = `/icons/${iconPack}/${iconVariant}/png/${iconName}`;
    return getStaticStorageImageFilePublicUrl(fullFilename)
}

// this one returns data stored in public/static - use sparingly
export function getLocalStaticFileUrl(filename) {
    let protocol = window.location.protocol;
    let hostname = window.location.hostname;
    let portnum = window.location.port;

    // if running in NPM on port 3000, switch to port 8080 for localhost testing
    if (hostname === 'localhost' && portnum === '3000') {
        portnum = 8080;
    }
    return `${protocol}//${hostname}:${portnum}/app/static${filename}`;
}

export function getDateIsoFormat(date = null) {
    let someDate = date;
    if (date === null) {
        someDate = new Date();
    }
    let yearString = someDate.getUTCFullYear();
    let monthString = String(someDate.getUTCMonth() + 1).padStart(2, '0');
    let dateString =String(someDate.getUTCDate()).padStart(2, '0');
    return `${yearString}-${monthString}-${dateString}`;
}

export function generateDateString(date) {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentDate = new Date();
    const currentYearString = currentDate.getUTCFullYear();
    const yearString = date.getUTCFullYear();
    const weekdayString = weekdays[date.getUTCDay()];
    const monthString = months[date.getUTCMonth()];
    const dateString = date.getUTCDate();
    if (yearString === currentYearString) return `${weekdayString}, ${dateString} ${monthString}`;
    else return `${dateString} ${monthString}, ${yearString}`;
}

export function convertTodaysLocalDateObjectToFormInput() {
    let todayDateObj = new Date();
    let todayYear = todayDateObj.getFullYear().toString();
    let todayMonth = (todayDateObj.getMonth() + 1).toString();
    let todayDate = todayDateObj.getDate().toString();
    if (todayMonth.length < 2) todayMonth = `0${todayMonth}`;
    if (todayDate.length < 2) todayDate = `0${todayDate}`;
    return(`${todayYear}-${todayMonth}-${todayDate}`);
}

export function convertLocalDateObjectToFormInput(dateObj) {
    let todayYear = dateObj.getFullYear().toString();
    let todayMonth = (dateObj.getMonth() + 1).toString();
    let todayDate = dateObj.getDate().toString();
    if (todayMonth.length < 2) todayMonth = `0${todayMonth}`;
    if (todayDate.length < 2) todayDate = `0${todayDate}`;
    return(`${todayYear}-${todayMonth}-${todayDate}`);
}

export function convertDateObjectToFormInput(date) {
    let isoString = date.toISOString();
    let isoParts = isoString.split('T');
    return(isoParts[0]);
}

export function getLocalDateOnlyObject(date) {
    let dateObj = new Date(date);
    dateObj = new Date(dateObj.toISOString().slice(0, -1));
    return(dateObj);
}

export function generateDateTimeString(date, useAmPm=true) {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let currentDate = new Date();
    let currentDateString = currentDate.getUTCDate();
    let currentMonthString = currentDate.getUTCMonth();
    let currentYearString = currentDate.getUTCFullYear();
    let utcDateString = date.getUTCDate();
    let utcMonthString = date.getUTCMonth();
    let utcYearString = date.getUTCFullYear();
    let yearString = date.getFullYear();
    let weekdayString = weekdays[date.getDay()];
    let monthString = months[date.getMonth()];
    let dateString = date.getDate();
    let hourString = date.getHours();
    let minuteString = date.getMinutes();
    let amPmString = '';
    if (useAmPm) {
        if (hourString < 12) amPmString = 'AM ';
        else amPmString = 'PM ';
        if (hourString > 12) hourString -= 12; 
    }
    if (minuteString < 10) minuteString = `0${minuteString}`
    
    if (utcYearString === currentYearString && utcMonthString === currentMonthString && utcDateString === currentDateString) {
        return `${hourString}:${minuteString} ${amPmString}Today`;
    }
    else if (utcYearString === currentYearString) {
        return `${hourString}:${minuteString} ${amPmString}on ${weekdayString}, ${dateString} ${monthString}`;
    }
    else {
        return `${hourString}:${minuteString} ${amPmString}on ${dateString} ${monthString}, ${yearString}`;
    }
}

export function getRandomString(length) {
    const characters       = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = characters.charAt(Math.floor(Math.random() * 26));
    for (let i=0; i<length; i++) {
      result += characters.charAt(Math.floor(Math.random() * 62));
    }
    return result;
}

export function getRandomInteger(min, max) {
    let randomVal = Math.random();
    let result = Math.floor(randomVal * (max-min));
    result += min;
    return result;
}

export function isDefined(theVariable) {
    if (typeof theVariable === 'undefined') return false;
    return true;
}

export function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
}

export function getFailureMethod(error) {
    let method = 'unknown';

    if (isDefined(error.name) && error.name === 'AxiosError') {
        if (isDefined(error.response.config) && isDefined(error.response.config.method)) {
            method = error.response.config.method;
        }
    }

    return method;
}

export function getFailureStatus(error) {
    let status = 400;

    if (isDefined(error.name) && error.name === 'AxiosError') {
        if (isDefined(error.response.status)) {
            status = error.response.status;
        }
    }

    return status;
}

export function getFailureMessage(error, defaultMessage='An error occurred') {
    let message = defaultMessage;

    // these are errors from the REST api
    if (isDefined(error.name) && error.name === 'AxiosError') {
        message = error.message;
        if (isDefined(error.response.data) && isDefined(error.response.data.message)) {
            message = error.response.data.message;
        }
    }

    // not sure what happened
    else if (isDefined(error.message)) {
        message = error.message;
    }

    // if the error message is 'invalid data' we've run into a JSON schema issue - try to parse it
    if (message === 'invalid data') {
        message = 'Invalid data';
        if (isDefined(error.response.data.invalid_data)) {
            let errorChunks = Object.values(error.response.data.invalid_data);
            if (errorChunks.length !== 0) {
                message = errorChunks.join(', ');
            }
        }
    }

    return message;
}

export function handleSpecialCasesRestError(error, apiName) {
    // if we are already in the global error handler, just stop
    if (window.location.href.includes('app/errorpage')) return;

    let status = getFailureStatus(error);
    let message = getFailureMessage(error);
    let reason = 'unknown';

    let isFirebaseError = false;
    if (error.name && error.name === 'FirebaseError') isFirebaseError = true;

    if (isFirebaseError) {
        console.error('Firebase error staus:', status, 'message:', message);
        if (status === 400 && message.includes('Missing or insufficient permissions')) reason = 'login-expired';
        else if (status === 400 && message.includes('Error (auth/user-disabled)')) reason = 'user-suspended';
        else if (status === 400 && message.includes('Error (auth/invalid-custom-token)')) reason = 'login-required';
    } else {
        console.error('REST API error staus:', status, 'message:', message);
        if (status === 403 && message === 'user unverified') reason = 'email-verification';
        else if (status === 401 && message === 'password expired') reason = 'password-update';
        else if (status === 401 && message === 'requires login') reason = 'login-required';
        else if (status === 401 && message === 'login expired') reason = 'login-expired';
        else if (status === 403 && message === 'user suspended') reason = 'user-suspended';
        else if (status === 403 && message === 'user deleted') reason = 'user-deleted';
        else if (status === 403 && message === 'user underage') reason = 'user-underage';
        else if (status === 403 && message === 'user deleted') reason = 'user-deleted';
        else if (status === 401 && message === 'user deleted') reason = 'user-deleted';
        else if (status === 0 && message === 'Network Error') reason = 'no-network';
    }
    
    // if the error isn't recognized then its not one of the special cases - stop
    if (reason === 'unknown') {
        // if it is the case that the error was caused by where the user was "shortcutting" to, clear that so we don't go there again
        delCookie('DesiredUrl');
        return;
    }

    // otherwise, save the page the user was trying to get to and go to the error handler page instead
    setDesiredUrlCookie(window.location.pathname);
    setTimeout(()=>{window.location.href = `/app/errorpage?reason=${reason}`}, 3000);
}

export function handleGlobalRestError(error, apiName, onlyWhenRecognized=false) {
    // if we are already in the global error handler, just stop
    if (window.location.href.includes('app/errorpage')) return;

    let status = getFailureStatus(error);
    let message = getFailureMessage(error);
    let method = getFailureMethod(error);
    let reason = 'unknown';
    
    let isFirebaseError = false;
    if (error.name && error.name === 'FirebaseError') isFirebaseError = true;

    if (isFirebaseError) {
        console.error('Firebase error staus:', status, 'method:', method, 'message:', message);
        if (status === 400 && message.includes('Missing or insufficient permissions')) reason = 'login-expired';
        else if (status === 400 && message.includes('Error (auth/user-disabled)')) reason = 'user-suspended';
        else if (status === 400 && message.includes('Error (auth/invalid-custom-token)')) reason = 'login-required';
    } else {
        console.error('REST API error staus:', status, 'method:', method, 'message:', message);
        if (status === 403 && message === 'user unverified') reason = 'email-verification';
        else if (status === 401 && message === 'password expired') reason = 'password-update';
        else if (status === 401 && message === 'requires login') reason = 'login-required';
        else if (status === 401 && message === 'login expired') reason = 'login-expired';
        else if (status === 403 && message === 'user suspended') reason = 'user-suspended';
        else if (status === 403 && message === 'user deleted') reason = 'user-deleted';
        else if (status === 401 && message === 'user deleted') reason = 'user-deleted';
        else if (status === 403 && message === 'user underage') reason = 'user-underage';
        else if (status === 0 && message === 'Network Error') reason = 'no-network';
    }
        
    // if it is the case that the error was caused by where the user was "shortcutting" to, clear that so we don't go there again
    if (reason === 'unknown') delCookie('DesiredUrl');

    // if the error isn't recognized and we're handling this conditionally, just stop
    if (reason === 'unknown' && onlyWhenRecognized === true) return;

    // now we're being more speculative...
    if (reason === 'unknown') {
        if (status === 404) {
            if (method === 'get') reason = 'read-404';
            else if (method === 'put') reason = 'write-404';
            else if (method === 'patch') reason = 'write-404';
            else if (method === 'post') reason = 'write-404';
            else if (method === 'delete') reason = 'delete-404';
        } else if (status === 401 || status === 403) {
            if (method === 'get') reason = 'unauthorized-read';
            else if (method === 'put') reason = 'unauthorized-write';
            else if (method === 'patch') reason = 'unauthorized-write';
            else if (method === 'post') reason = 'unauthorized-write';
            else if (method === 'delete') reason = 'unauthorized-delete';
        }
    }

    // otherwise, save the page the user was trying to get to and go to the error handler page instead
    setDesiredUrlCookie(window.location.pathname);
    window.location.href = `/app/errorpage?reason=${reason}`;
}

export function determinePrivateChatChannelName(userId1, userId2) {
    let firstId = userId1;
    let secondId = userId2;
    if (userId1 > userId2) {
        firstId = userId2;
        secondId = userId1;
    }
    
    let baseName = 'chat-user';
    return `${baseName}-${firstId}-${secondId}`;
}

export function setDesiredUrlCookie(desiredUrl) {
    // there are some pages we should never set as the desired...
    if (desiredUrl.includes('/app/logout')) desiredUrl = '/app/main';
    if (desiredUrl.includes('/app/login')) desiredUrl = '/app/main';
    setCookie('DesiredUrl', desiredUrl, 1);
}

export function hashCode(s) {
    var hash = 0, i, chr;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
        chr   = s.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash;
}

export function arrayBufferToBase64(buffer) {
    return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
}

export function base64ToArrayBuffer(data) {
    return Uint8Array.from(atob(data), c => c.charCodeAt(0)).buffer;
}

export function browserSupportsWebAuthn() {
    if (window.PublicKeyCredential === undefined || typeof window.PublicKeyCredential !== "function") {
        return false;
    }
    return true;
}

export function setCookie(name, value, lifetime) {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (lifetime * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${expireDate.toUTCString()};path=/;SameSite=Strict`;
}

export function setCookieHours(name, value, lifetime) {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (lifetime * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${expireDate.toUTCString()};path=/;SameSite=Strict`;
}
  
export function getCookie(name, valueNotFound=null) {
    let nameEquals = name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(nameEquals) === 0) {
            let result = c.substring(nameEquals.length, c.length);
            if (result === 'false') return false;
            return result;
        }
    }
    return valueNotFound;
}

export function getCookieParseInt(name, valueNotFound=null) {
    let val = getCookie(name, valueNotFound);
    if (val === valueNotFound) return val;
    return parseInt(val);
}

export function getCookieParseBool(name, valueNotFound=false) {
    let val = getCookie(name, valueNotFound);
    if (val === valueNotFound) return val;
    if (val.toLowerCase() === "true") return true;
    return false;
}

export function delCookie(name) {
    if (getCookie(name) !== null) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
    }
}

export function delCookieAfterDelay(name, delay=0) {
    setTimeout(delCookie(name), delay);
}

export function setLocal(name, value) {
    if (typeof window.localStorage !== "undefined") {
        if (typeof value === 'object') value = JSON.stringify(value);
        localStorage.setItem(name, value);
    } else {
        console.warn('This browser does not support LocalStorage');
    }
}
  
export function getLocal(name, valueNotFound=null) {
    if (typeof window.localStorage !== "undefined") {
        let value = localStorage.getItem(name);
        if (value === null) return valueNotFound;
        return value;
    } else {
        console.warn('This browser does not support LocalStorage');
    }
    return valueNotFound;
}

export function getLocalParseInt(name, valueNotFound=null) {
    let val = getLocal(name, valueNotFound);
    if (val === valueNotFound) return val;
    return parseInt(val);
}

export function getLocalParseBool(name, valueNotFound=false) {
    let val = getLocal(name, valueNotFound);
    if (val === valueNotFound) return val;
    if (val.toLowerCase() === "true") return true;
    return false;
}

export function getLocalParseObject(name, valueNotFound=null) {
    let val = getLocal(name, valueNotFound);
    if (val === valueNotFound) return val;
    return JSON.parse(val);
}

export function delLocal(name) {
    if (typeof window.localStorage !== "undefined") {
        localStorage.removeItem(name);
    } else {
        console.warn('This browser does not support LocalStorage');
    }
}

export function delLocalAfterDelay(name, delay=0) {
    setTimeout(delLocal(name), delay);
}

export function reloadPage(delay=0) {
    setTimeout(window.location.reload(false), delay);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetters(string) {
    if (!string) return string;
    let words = string.split(' ');
    if (words) {
        for (let i=0; i<words.length; i++) {
            words[i] = capitalizeFirstLetter(words[i]);
        }
        return words.join(' ');
    }
    return string;
}

export function getUrlFromText(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const sections = text.split();
    for (const textSection of sections) {
        let trimmedSection = textSection.trim();
        const index = trimmedSection.search(urlRegex);
        if (index >= 0) {
            return textSection.slice(index);
        }
    }
    return null;
}

export function getFileUiIconUrl(filename) {
    let extension = filename.split('.').pop();
    extension = '.' + extension;

    const iconExtensionDict = {
        '.ai':    '001-adobe-illustrator.png',
        '.apk':   '002-apk.png',
        '.css':   '003-css.png',
        '.htm':   '003-css.png',
        '.html':  '003-css.png',
        '.iso':   '004-disc.png',
        '.doc':   '030-word.png',
        '.docx':  '030-word.png',
        '.odf':   '007-font file.png',
        '.rtf':   '007-font file.png',
        '.text':  '026-txt.png',
        '.txt':   '026-txt.png',
        '.ttf':   '025-ttf.png',
        '.xls':   '006-excel.png',
        '.xlsx':  '005-doc.png',
        '.ppt':   '018-powerpoint.png',
        '.pptx':  '018-powerpoint.png',
        '.pdf':   '016-pdf.png',
        '.ps':    '020-psd.png',
        '.c':     '009-javascript.png',
        '.js':    '009-javascript.png',
        '.cpp':   '009-javascript.png',
        '.h':     '009-javascript.png',
        '.java':  '009-javascript.png',
        '.sql':   '022-sql.png',
        '.php':   '017-php.png',
        '.wav':   '021-record.png',
        '.png':   '010-image.png',
        '.jpg':   '010-image.png',
        '.jpeg':  '010-image.png',
        '.gif':   '010-image.png',
        '.bmp':   '010-image.png',
        '.pcx':   '010-image.png',
        '.mp4':   '014-video.png',
        '.mp3':   '015-music.png',
        '.svg':   '023-svg.png',
        '.zip':   '032-zip.png',
        '.7zip':  '032-zip.png',
        '.bz':    '032-zip.png',
        '.tar':   '032-zip.png'
    }

    let iconName = '024-text.png';
    if (extension in iconExtensionDict) {
        iconName = iconExtensionDict[extension];
    }
    
    return getUiIconUrl('file-types', 'filled', iconName);
}

export function getFileDownloadTarget(filename) {
    const targetOptionBlankList = ['.html', '.htm', '.png', '.bmp', '.gif', '.pcx', '.jpg', '.jpeg', '.pdf'];
    let extension = filename.split('.').pop();
    extension = '.' + extension;
    let targetOption = '_self';
    if (targetOptionBlankList.includes(extension)) {
        targetOption = '_blank'
    }
    return targetOption;
}

export function messageServiceWorker(messageObject) {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(messageObject);
    } else {
        console.warn('service worker is either not installed or not avaialbe on this browser')
    }
}

export function isValidHttpUrl(string) {
    let url = null;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    
    return url.protocol === "http:" || url.protocol === "https:";
}

export function isMobileDevice() {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/android/i)) return true;
    if (userAgent.match(/webos/i)) return true;
    if (userAgent.match(/iphone/i)) return true;
    if (userAgent.match(/ipad/i)) return true;
    if (userAgent.match(/ipod/i)) return true;
    if (userAgent.match(/blackberry/i)) return true;
    return false;
}

export function isProbablySafari() {
    const userAgent = window.navigator.userAgent;
    console.debug(userAgent);
    if (userAgent.match(/iphone/i)) return true;
    if (userAgent.match(/ipad/i)) return true;
    if (userAgent.match(/ipod/i)) return true;
    if (userAgent.match(/macintosh/i)) return true;
}

export function decimalSeparator(country) {
    const countryUsesCommaList = [
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Argentina',
        'Armenia',
        'Austria',
        'Azerbaijan',
        'Belarus',
        'Belgium',
        'Bolivia',
        'Brazil',
        'Bulgaria',
        'Cameroon',
        'Chile',
        'Colombia',
        'Costa Rica',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'East Timor',
        'Ecuador',
        'Estonia',
        'Faroes',
        'Finland',
        'France',
        'Germany',
        'Georgia',
        'Greece',
        'Greenland',
        'Hungary',
        'Iceland',
        'Indonesia',
        'Italy',
        'Kazakhstan',
        'Kosovo',
        'Kyrgyzstan',
        'Latvia',
        'Lebanon',
        'Lithuania',
        'Macedonia',
        'Moldova',
        'Mongolia',
        'Morocco',
        'Mozambique',
        'Namibia',
        'The Netherlands',
        'Norway',
        'Paraguay',
        'Peru',
        'Poland',
        'Portugal',
        'Romania',
        'Russia',
        'Serbia',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'Spain',
        'Switzerland',
        'Sweden',
        'Tunisia',
        'Turkey',
        'Ukraine',
        'Uruguay',
        'Uzbekistan',
        'Venezuela',
        'Vietnam',
    ];
    if (countryUsesCommaList.includes(country)) return(',');
    return('.');
}

export function getCurrency(amount, currencyData, country='United States') {
    let currencyAlpha = currencyData.alpha_id;
    let currencySymbol = currencyData.symbol;
    if (currencySymbol === null) currencySymbol = '';
    let currencyMinorDigits = currencyData.minor_digits;
    let currencyDecimal = decimalSeparator(country);
    let result = currency(amount, {fromCents: true, decimal: currencyDecimal, symbol: currencySymbol, precision: currencyMinorDigits});
    return(result);
}

export function convertListToDict(listOfObjects, idField) {
    const newDict = {};
    for (const listItem of listOfObjects) {
        const key = listItem[idField];
        newDict[key] = listItem;
    }
    return(newDict);
}

// this function is designed to execute batches of work over time (e.g. slowing down repeated or massive fetch api calls)
export function batchExecuteSmallChunks(list, batchSize, batchDelay, batchCallback) {
    // it is an error to have a batchSize < 1
    if (batchSize < 1) {
        console.error(`batchExecuteSmallChunks(): invalid batch size (${batchSize})`);
        return;
    }

    // split the list into batches & execute each
    for (let listIndex=0, batchCount=0; listIndex<list.length; listIndex+=batchSize, batchCount++) {
        // build a batch list from the list provided, but only with the values in this batch
        const batchList = [];
        for (let i=listIndex; i<listIndex+batchSize; i++) {
            if (i < list.length) batchList.push(list[i]);
            else break;
        }
        // do the batch callback, putting a delay in between each
        const delay = batchCount * batchDelay;
        console.debug(`calling batchCallback(${batchList.toString()}) with a ${delay} millisecond delay`);
        // if the batchSize is just 1, then instead of a list put the single value
        if (batchSize === 1) {
            setTimeout(batchCallback, delay, batchList[0]);
        } else {
            setTimeout(batchCallback, delay, batchList);
        }
    }
}