import { collection, onSnapshot, getDocs } from 'firebase/firestore';
import { NerdHerderRestApi } from './NerdHerder-RestApi.js';

export class NerdHerderRealtimePushChannel {
    constructor(channelName) {
        this.restApi = new NerdHerderRestApi();
        this.channelName = channelName;
        this.boundEventCurrentData = {};
        this.boundEventsInitialUpdate = {};
        this.boundEvents = {};
        console.log(`NerdHerderRealtimePushChannel: ${this.fullChannelName}`);
    }

    getChannelName() {
        return this.channelName;
    }

    getFullChannelName() {
        return `${this.restApi.firestorePrefix}realtime-push/${this.channelName}`;
    }

    getChannelCollectionReference() {
        return this.channelColRef;
    }

    bind(eventName, callback) {
        if (!this.boundEvents.hasOwnProperty(eventName)) {
            this.boundEvents[eventName] = [];
            this.boundEventsInitialUpdate[eventName] = false;
            this.boundEventCurrentData[eventName] = [];
            const constEventName = eventName;
            const collectionName = `${this.getFullChannelName()}/${constEventName}`;
            const channelColRef = collection(this.restApi.firestoreDb, collectionName);
            const self = this;
            this.unsubscribeFunction = onSnapshot(channelColRef, (collection) => {
                // only do the callback after the initial update
                if (self.boundEventsInitialUpdate[constEventName]) {
                    console.debug(`NerdHerderRealtimePushChannel: ${collectionName} - update detected`);
                    getDocs(channelColRef)
                    .then((querySnapshot) => {
                        const docIdList = []
                        const docDataDict = {};
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            docIdList.push(doc.id);
                            docDataDict[doc.id] = doc;
                        });
                        // for any new id - add to the current list then do callbacks
                        for (const docId of docIdList) {
                            if (!self.boundEventCurrentData[constEventName].includes(docId)) {
                                self.boundEventCurrentData[constEventName].push(docId);
                                for (const callbackFunction of self.boundEvents[constEventName]) {
                                    console.debug(`NerdHerderRealtimePushChannel: ${collectionName} - new message ${docId}`);
                                    const message_data = docDataDict[docId].data();
                                    callbackFunction(message_data);
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(`NerdHerderRealtimePushChannel: ${collectionName} - error in update querySnapshot`);
                        console.error(error);
                    });
                    
                } else {
                    console.debug(`NerdHerderRealtimePushChannel: ${collectionName} - initial`);
                    getDocs(channelColRef)
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            this.boundEventCurrentData[constEventName].push(doc.id);
                        });
                    })
                    .catch((error) => {
                        console.error(`NerdHerderRealtimePushChannel: ${collectionName} - error in initial querySnapshot`);
                        console.error(error);
                    });
                    self.boundEventsInitialUpdate[constEventName] = true;
                }
            }, (error) => {
                console.warn(`NerdHerderRealtimePushChannel: ${collectionName} - missing or no perms`);
            });
        }
        this.boundEvents[eventName].push(callback);
    }
}