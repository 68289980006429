import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';
import { NerdHerderPollResultsModal } from './NerdHerderModals';
import { NerdHerderBadge, NerdHerderBadgeInjector } from './NerdHerderBadge';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './NerdHerderStandardCardTemplate';
import { UserListItem, PollOptionListItem } from './NerdHerderListItems';


export class LeaguePollCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeaguePollCard'>
                <LeaguePollCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeaguePollCardInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.pollId === 'undefined') console.error('missing props.pollId');
    
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            poll: null,
            hasVoted: false,
            results: null,
            showDetailsModal: false,
            pollOptions: {},
        }
    }

    componentDidMount() {
        const sub = this.restPubSub.subscribe('poll', this.props.pollId, (d, k)=>this.updatePoll(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updatePoll(pollData, key) {
        const newPoll = NerdHerderDataModelFactory('poll', pollData);
        const hasVoted = newPoll.hasUserVoted(this.props.localUser.id);
        
        // grab all the poll optoions from the poll
        const newPollOptions = {};
        for (const pollOptionData of newPoll.options) {
            const newPollOption = NerdHerderDataModelFactory('poll_option', pollOptionData);
            newPollOptions[pollOptionData.id] = newPollOption;
        }
        this.setState({poll: newPoll, hasVoted: hasVoted, pollOptions: newPollOptions, results: newPoll.results});
    }
    
    render() {
        if (this.state.poll === null) return(<NerdHerderLoadingCard title='Poll'/>);

        let userFeedback = null;
        let errorFeedback = null;
        if (this.state.hasVoted) userFeedback = 'You have voted in this poll';
        if (!this.state.hasVoted && this.state.poll.state === 'completed') {
            userFeedback = null;
            errorFeedback = 'You missed your chance to vote!'
        }

        const canShowResults = this.state.poll.canShowResults(this.props.localUser.id)
        const pollOptionListItems = [];
        for (const pollOptionId of this.state.poll.option_ids) {
            const pollOption = this.state.pollOptions[pollOptionId];
            const votes = this.state.results[pollOptionId].length;
            const option = <PollOptionListItem pollOptionId={pollOptionId} pollOption={pollOption} poll={this.state.poll} onClickVote={true} league={this.props.league} localUser={this.props.localUser}/>
            let optionWrapper = null;
            if (canShowResults) {
                optionWrapper =
                    <div key={pollOptionId} className="my-2" style={{position: 'relative'}}>
                        {option}    
                        <div className="translate-middle" style={{position: 'absolute', top: '5px', right: '-18px', zIndex: '1'}}>
                            <Badge pill><big>{votes}</big></Badge>
                        </div>
                    </div>
            } else {
                optionWrapper =
                    <div key={pollOptionId}>
                        {option}
                    </div>
            }

            pollOptionListItems.push(optionWrapper);
        }

        let titleText = 'Poll'
        if (this.state.poll.state === 'completed') titleText = 'Completed Poll';

        return(
            <NerdHerderStandardCardTemplate title={titleText} titleIcon='voting-box.png' userFeedback={userFeedback} errorFeedback={errorFeedback} manageOptions={this.props.manageOptions}>
                {this.state.showDetailsModal &&
                <NerdHerderPollResultsModal onCancel={()=>this.setState({showDetailsModal: false})} pollId={this.props.pollId} league={this.props.league} localUser={this.props.localUser}/>}
                <div><b>{this.state.poll.title}</b></div>
                {this.state.poll.text &&
                <small>{this.state.poll.text}</small>}
                {pollOptionListItems}
                <div className='text-end'>
                    <Button size='sm' variant='primary' onClick={()=>this.setState({showDetailsModal: true})} disabled={!canShowResults}>Details</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        );
    }
}
