import React from 'react';
import withRouter from './withRouter';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { DateTime, IANAZone } from 'luxon';
import { SingleEliminationBracket, DoubleEliminationBracket, Match, SVGViewer, createTheme } from '@g-loot/react-tournament-brackets';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderQrScanModal, NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderMessageModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { NerdHerderCountdown } from './nerdherder-components/NerdHerderCountdown';
import { handleGlobalRestError, generateDateString, generateDateTimeString, getRandomString, delCookieAfterDelay } from './utilities';
import { parseTournamentGetResponse, parseRanking, generateTournamentCheckinCode, getCurrentRound, usersGamesKey, getLastCompletedRound, tabulateTournamentData, sortTournamentPlayers, generateTimeRemainingHMS } from './tournament_utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { UserListItem, GameListItem } from './nerdherder-components/NerdHerderListItems';
import { TableOfUsers } from './nerdherder-components/NerdHerderTableHelpers';
import { NerdHerderListUploadCard } from './nerdherder-components/NerdHerderListUploadCard';
import { NerdHerderRandomSelectorCard } from './nerdherder-components/NerdHerderRandomSelectorCard';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';

class TournamentPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.roundCardRefs = {};

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            league: null,
            event: null,
            tournamentId: this.props.params.tournamentId,
            tournament: null,
            tournamentRoundIds: [],
            tournamentRounds: {},
            topic: null,
            games: {},
            usersTournaments: {},
            usersGames: {},
            usersCache: {},
            players: {},
            eloStats: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        
    }

    componentDidMountStage2() {
        let sub = this.restPubSub.subscribeToFirestore('tournaments', this.state.tournamentId, (d, k) => {this.updateTournament(d, k)});
        this.restPubSubPool.add(sub);
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateTournament(tournamentData, key) {
        const tournament = NerdHerderDataModelFactory('tournament', tournamentData);

        // update the league - should be a one-time thing
        if (this.state.league === null) {
            const sub = this.restPubSub.subscribe('league', tournament.league_id, (d, k) => {this.updateLeague(d, k)});
            this.restPubSubPool.add(sub);
        }

        // update the managers
        for (const managerUserId of tournament.manager_ids) {
            if (this.state.usersCache.hasOwnProperty(managerUserId)) continue;
            const sub = this.restPubSub.subscribeToFirestore('users', managerUserId, (d, k) => {this.updateUser(d, k)}, null, managerUserId);
            this.restPubSubPool.add(sub);
        }

        // update the event if there is one or if it changed somehow
        if (tournament.event_id !== null && (this.state.event === null || this.state.event.id !== tournament.event.id)) {
            const sub = this.restPubSub.subscribe('event', tournament.event_id, (d, k) => {this.updateEvent(d, k)});
            this.restPubSubPool.add(sub);
        }

        // for simplicity, keep our own copy of the round IDs
        const updatedRoundIds = [...tournament.round_ids];

        // get the users_tournaments, round, users_games, game, and user info out of the tournament response
        const updatedUsersTournaments = {};
        const updatedRounds = {};
        const updatedGames = {};
        const updatedUsersGames = {};
        const updatedPlayers = {};

        parseTournamentGetResponse(tournament, updatedUsersTournaments, updatedRounds, updatedGames, updatedUsersGames, updatedPlayers);
        
        this.setState({
            tournament: tournament,
            usersTournaments: updatedUsersTournaments,
            tournamentRoundIds: updatedRoundIds,
            tournamentRounds: updatedRounds,
            games: updatedGames,
            usersGames: updatedUsersGames,
            players: updatedPlayers
        });

        for (const tournamentRoundId of updatedRoundIds) {
            if (!this.roundCardRefs.hasOwnProperty(tournamentRoundId)) {
                this.roundCardRefs[tournamentRoundId] = React.createRef();
            }
        }
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league});

        // update the topic if we don't have it yet or if it changed somehow
        if (this.state.topic === null || this.state.topic.id !== league.topic_id) {
            let sub = this.restPubSub.subscribeToFirestore('topics', league.topic_id, (d, k) => {this.updateTopic(d, k)});
            this.restPubSubPool.add(sub);
        }

        if (league.elo_configuration === 'tournament') {
            let sub = this.restPubSub.subscribe('league-elo-stats', league.id, (d, k)=>{this.updateLeagueEloStats(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    updateTopic(topicData, key) {
        const topic = NerdHerderDataModelFactory('topic', topicData);
        this.setState({topic: topic});
    }

    updateEvent(eventData, key) {
        const event = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: event});
    }

    updateLeagueEloStats(eloStats, key) {
        this.setState({eloStats: eloStats});
    }

    updateUser(userData, userId) {
        this.updateUsersCache(userData);
    }

    updateUsersCache(userData) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [newUser.id]: newUser}}
        });
    }

    handleTimerUpdateMessage(messageLabel, message) {
        if (message.tournament_round_id) {
            let roundId = message.tournament_round_id
            if (this.roundCardRefs.hasOwnProperty(roundId)) {
                let cardRef = this.roundCardRefs[roundId];
                switch(messageLabel) {
                    case 'tournament_clock_start':
                        cardRef.current.startClock(message.data.remaining);
                        break;
                    case 'tournament_clock_stop':
                        cardRef.current.stopClock(message.data.remaining);
                        break;
                    case 'tournament_clock_complete':
                        cardRef.current.stopClock(0);
                        break;
                    default:
                        console.error('got unexpected message label');
                }
            }
        }
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal />);
        if (!this.state.tournament) return (<NerdHerderLoadingModal />);
        if (this.state.tournament.event_id && !this.state.event) return (<NerdHerderLoadingModal />);
        if (!this.state.league) return (<NerdHerderLoadingModal />);

        let localUserIsManager = this.state.tournament.isManager(this.state.localUser.id);
        let localUserIsPlayer = this.state.tournament.isPlayer(this.state.localUser.id);
        let localuserIsMember = localUserIsManager || localUserIsPlayer;

        // only allow managers to see this stuff if its in draft
        if (this.state.tournament.state === 'draft' && !localUserIsManager) {
            return(<NerdHerderErrorModal errorFeedback='This tournament is currently in draft and you are not a tournament organizer'/>);
        }

        const tournamentRandomSelectorCards = [];
        for (const randomSelector of this.state.tournament.random_selectors) {
            let selectorId = randomSelector.id;
            const card = <NerdHerderRandomSelectorCard key={selectorId} randomSelector={randomSelector} league={this.state.league} tournament={this.state.tournament} localUser={this.state.localUser}/>
            tournamentRandomSelectorCards.push(card);
        }

        const allCardsProps = {
            tournament: this.state.tournament,
            tournamentRoundIds: this.state.tournamentRoundIds,
            tournamentRounds: this.state.tournamentRounds,
            games: this.state.games,
            usersTournaments: this.state.usersTournaments,
            usersGames: this.state.usersGames,
            usersCache: this.state.usersCache,
            players: this.state.players,
            event: this.state.event,
            league: this.state.league,
            topic: this.state.topic,
            eloStats: this.state.eloStats,
            isTournamentMember: localuserIsMember,
            localUser: this.state.localUser,
        }

        const tournamentRoundCards = [];
        for (const tournamentRoundId of this.state.tournamentRoundIds) {
            const thisRound = this.state.tournamentRounds[tournamentRoundId];
            const roundCard = <TournamentRoundCard ref={this.roundCardRefs[tournamentRoundId]} key={tournamentRoundId} {...allCardsProps} tournamentRound={thisRound}/>
            tournamentRoundCards.unshift(roundCard);
        }

        // normally, focus on an element depending on the query params, but if there is no query param and the
        // user is a player and the tournament is in-progress, jump to the current round
        let focusElementId = this.props.query.get('focus');
        if (!focusElementId && this.state.tournament.state === 'in-progress' && this.state.tournament.isPlayer(this.state.localUser.id)) {
            const currentRound = getCurrentRound(this.state.tournament);
            if (currentRound) {
                focusElementId = `tournament-round-${currentRound.id}`;
            }
        }

        return(
            <NerdHerderStandardPageTemplate pageName='tournament' headerSelection='leagues' dropdownSelection={this.state.league.name}
                                            navPath={[{icon: 'flaticon-team', label: this.state.league.name, href: `/app/league/${this.state.league.id}`},
                                                      {icon: 'flaticon-trophy-cup-black-shape', label: this.state.tournament.name, href: `/app/tournament/${this.state.tournamentId}`}]}
                                            league={this.state.league} localUser={this.state.localUser}
                labelCallbacks={{'tournament_clock_start': (mid, msg)=>this.handleTimerUpdateMessage(mid, msg), 'tournament_clock_stop': (mid, msg)=>this.handleTimerUpdateMessage(mid, msg), 'tournament_clock_complete': (mid, msg)=>this.handleTimerUpdateMessage(mid, msg)}}>
                <TournamentPageTopper {...allCardsProps}/>
                {this.state.tournament.state === 'complete' && 
                <TournamentResultsCard {...allCardsProps}/>}
                {this.state.tournament.list_container_ids.length !== 0 &&
                <NerdHerderListUploadCard leagueId={this.state.league.id} tournamentId={this.state.tournamentId} {...allCardsProps}/>}
                {localUserIsPlayer && this.state.tournament.require_checkin && this.state.tournament.state !== 'complete' &&
                <TournamentCheckinCard {...allCardsProps}/>}
                {tournamentRandomSelectorCards}
                {this.state.tournament.type !== 'elimination' && this.state.tournamentRoundIds.length > 0 &&
                <TournamentRankingsCard {...allCardsProps}/>}
                {this.state.league.elo_configuration === 'tournament' && this.state.tournamentRoundIds.length > 0 &&
                <TournamentEloCard {...allCardsProps}/>}
                {this.state.tournament.type === 'elimination' &&
                <TournamentBracketCard {...allCardsProps}/>}
                {tournamentRoundCards}
                <NerdHerderScrollToFocusElement elementId={focusElementId}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

// this class is used to hold the guts of a tournament page when displayed in the league or event page
// it needs to be kept in sync with the TournamentPage class above
export class TournamentContainer extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.tournamentId === 'undefined') console.error('missing props.tournamentId');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.topic === 'undefined') console.error('missing props.topic');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.roundCardRefs = {};

        this.state = {
            event: null,
            tournamentId: this.props.tournamentId,
            tournament: null,
            tournamentRoundIds: [],
            tournamentRounds: {},
            games: {},
            usersTournaments: {},
            usersGames: {},
            usersCache: {},
            players: {},
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('tournaments', this.state.tournamentId, (d, k) => {this.updateTournament(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, key) {
        const tournament = NerdHerderDataModelFactory('tournament', tournamentData);

        // update the managers
        for (const managerUserId of tournament.manager_ids) {
            if (this.state.usersCache.hasOwnProperty(managerUserId)) continue;
            const sub = this.restPubSub.subscribeToFirestore('users', managerUserId, (d, k) => {this.updateUser(d, k)}, null, managerUserId);
            this.restPubSubPool.add(sub);
        }

        // for simplicity, keep our own copy of the round IDs
        const updatedRoundIds = [...tournament.round_ids];

        // get the users_tournaments, round, users_games, game, and user info out of the tournament response
        const updatedUsersTournaments = {};
        const updatedRounds = {};
        const updatedGames = {};
        const updatedUsersGames = {};
        const updatedPlayers = {};

        parseTournamentGetResponse(tournament, updatedUsersTournaments, updatedRounds, updatedGames, updatedUsersGames, updatedPlayers);
        
        this.setState({
            tournament: tournament,
            usersTournaments: updatedUsersTournaments,
            tournamentRoundIds: updatedRoundIds,
            tournamentRounds: updatedRounds,
            games: updatedGames,
            usersGames: updatedUsersGames,
            players: updatedPlayers
        });

        for (const tournamentRoundId of updatedRoundIds) {
            if (!this.roundCardRefs.hasOwnProperty(tournamentRoundId)) {
                this.roundCardRefs[tournamentRoundId] = React.createRef();
            }
        }
    }

    updateUser(userData, userId) {
        this.updateUsersCache(userData);
    }

    updateUsersCache(userData) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [newUser.id]: newUser}}
        });
    }

    handleTimerUpdateMessage(messageLabel, message) {
        if (message.tournament_round_id) {
            let roundId = message.tournament_round_id
            if (this.roundCardRefs.hasOwnProperty(roundId)) {
                let cardRef = this.roundCardRefs[roundId];
                switch(messageLabel) {
                    case 'tournament_clock_start':
                        cardRef.current.startClock(message.data.remaining);
                        break;
                    case 'tournament_clock_stop':
                        cardRef.current.stopClock(message.data.remaining);
                        break;
                    case 'tournament_clock_complete':
                        cardRef.current.stopClock(0);
                        break;
                    default:
                        console.error('got unexpected message label');
                }
            }
        }
    }

    render() {
        if (!this.state.tournament) return (null);

        let localUserIsManager = this.state.tournament.isManager(this.props.localUser.id);
        let localUserIsPlayer = this.state.tournament.isPlayer(this.props.localUser.id);
        let localuserIsMember = localUserIsManager || localUserIsPlayer;

        // only allow managers to see this stuff if its in draft
        if (this.state.tournament.state === 'draft' && !localUserIsManager) {
            return(
                <NerdHerderStandardCardTemplate title='Tournament' titleIcon='finish-trophy.png'>
                    <Alert variant='warning'>This tournament is not yet configured</Alert>
                </NerdHerderStandardCardTemplate>
            );
        }

        const tournamentRandomSelectorCards = [];
        for (const randomSelector of this.state.tournament.random_selectors) {
            let selectorId = randomSelector.id;
            const card = <NerdHerderRandomSelectorCard key={selectorId} randomSelector={randomSelector} league={this.props.league} tournament={this.state.tournament} localUser={this.props.localUser}/>
            tournamentRandomSelectorCards.push(card);
        }

        const allCardsProps = {
            tournament: this.state.tournament,
            tournamentRoundIds: this.state.tournamentRoundIds,
            tournamentRounds: this.state.tournamentRounds,
            games: this.state.games,
            usersTournaments: this.state.usersTournaments,
            usersGames: this.state.usersGames,
            usersCache: this.state.usersCache,
            players: this.state.players,
            event: this.props.event,
            league: this.props.league,
            topic: this.props.topic,
            isTournamentMember: localuserIsMember,
            localUser: this.props.localUser,
        }

        const tournamentRoundCards = [];
        for (const tournamentRoundId of this.state.tournamentRoundIds) {
            const thisRound = this.state.tournamentRounds[tournamentRoundId];
            const roundCard = <TournamentRoundCard ref={this.roundCardRefs[tournamentRoundId]} key={tournamentRoundId} {...allCardsProps} tournamentRound={thisRound}/>
            tournamentRoundCards.unshift(roundCard);
        }

        return(
            <div>
                {this.state.tournament.state === 'complete' && 
                <TournamentResultsCard {...allCardsProps}/>}
                {this.state.tournament.list_container_ids.length !== 0 && this.state.tournament.state !== 'complete' &&
                <NerdHerderListUploadCard leagueId={this.props.league.id} tournamentId={this.state.tournamentId} {...allCardsProps}/>}
                {localUserIsPlayer && this.state.tournament.require_checkin && this.state.tournament.state !== 'complete' &&
                <TournamentCheckinCard {...allCardsProps}/>}
                {tournamentRandomSelectorCards}
                {this.state.tournament.type !== 'elimination' && this.state.tournamentRoundIds.length > 0 &&
                <TournamentRankingsCard {...allCardsProps}/>}
                {this.state.tournament.type === 'elimination' &&
                <TournamentBracketCard {...allCardsProps}/>}
                {tournamentRoundCards}
                <TournamentEventPlaceholderCard {...allCardsProps}/>
            </div>
        );
    }
}

class TournamentPageTopper extends React.Component {

    render() {
        const organizersList = [];
        for (const managerId of this.props.tournament.manager_ids) {
            const listItem = <UserListItem key={managerId} localUser={this.props.localUser} userId={managerId} slim={true}/>
            organizersList.push(listItem)
        }

        let topicLink = null;
        if (this.props.topic) {
            topicLink = <Link to={`/app/topic/${this.props.topic.id}`}>{this.props.topic.name}</Link>
        }

        // figure out doors open time and start time
        // if we have a start date, convert from the league's time zone to local time
        let openTimeJsx = null;
        let startTimeJsx = null;
        let luxonTimeZone = new IANAZone(this.props.league.timezone);
        if (this.props.league.start_date && luxonTimeZone.isValid) {
            if (this.props.league.open_time !== null) {
                let luxonDateTime = DateTime.fromISO(`${this.props.league.start_date}T${this.props.league.open_time}`, {zone: luxonTimeZone});
                openTimeJsx = <span>Doors Open: {luxonDateTime.toLocaleString(DateTime.TIME_SIMPLE)} {luxonDateTime.toFormat('ZZZZ')}</span>
            }
            if (this.props.league.start_time !== null) {
                let luxonDateTime = DateTime.fromISO(`${this.props.league.start_date}T${this.props.league.start_time}`, {zone: luxonTimeZone});
                startTimeJsx = <span>Start Time: {luxonDateTime.toLocaleString(DateTime.TIME_SIMPLE)} {luxonDateTime.toFormat('ZZZZ')}</span>
            }
        }

        return(
            <Card className="card shadow-sm" style={{borderRadius: '0.5rem'}}>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <h2>{this.props.tournament.name}</h2>
                            <hr className="mb-1"/>
                        </Col>
                        <Col xs={6}>
                            {!topicLink && <small className="text-muted">Topic: </small>}
                            {topicLink && <small className="text-muted">Topic: {topicLink}</small>}
                        </Col>
                        <Col xs={6} className="text-end">
                            <small className="text-muted">{ this.props.tournament.getShortStatusString() }</small>
                        </Col>
                    </Row>
                    <hr className="mt-1"/>
                    <Row className="my-1">
                        <Col xs={5}>
                            <Image className="img-fluid rounded text-center" src={this.props.tournament.getImageUrl()}/>
                        </Col>
                        <Col xs={7}>
                            <strong>{this.props.tournament.summary}</strong>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="my-1">
                        <Col lg={6}>
                            <h5>Type & Pairing</h5>
                            {this.props.tournament.getTypeAndMethodJsx()}
                        </Col>
                        <Col lg={6}>
                            <h5>Rounds</h5>
                            <p>{this.props.tournament.getMaxNumberOfRounds()}</p>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col lg={6}>
                            <h5>Schedule</h5>
                            <div>{this.props.tournament.getScheduleString()}</div>
                            <div>{openTimeJsx}</div>
                            <div>{startTimeJsx}</div>
                        </Col>
                        <Col lg={6}>
                            <h5>Tournament Organizers</h5>
                            {organizersList}
                        </Col>
                    </Row>
                    {this.props.tournament.isManager(this.props.localUser.id) && 
                    <Row>
                        <Col className="my-1" sm={6}>
                            <div className="d-grid gap-2">
                                <Button variant="danger" href={this.props.tournament.getManageUrl()}>Manage Tournament</Button>
                            </div>
                        </Col>
                        <Col className="my-1" sm={6}>
                            <div className="d-grid gap-2">
                                <Button variant="primary" target='_blank' href={`/app/tournamenttimer/${this.props.tournament.id}`}>Real-Time Page</Button>
                            </div>
                        </Col>
                    </Row>}
                </Card.Body>
            </Card>
        );
    }
}

class TournamentResultsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentResultsCard'>
                <TournamentResultsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentResultsCardInner extends React.Component {
    
    render() {
        if (!this.props.tournament.place_1_user_id) return(null);

        return(
            <NerdHerderStandardCardTemplate title='Results' titleIcon='finish-trophy.png'>
                {this.props.tournament.place_1_user_id &&
                <Row className='mb-2'>
                    <Col xs={12}>
                        <b>First Place</b>
                        <UserListItem userId={this.props.tournament.place_1_user_id} localUser={this.props.localUser}/>
                    </Col>
                </Row>}
                {this.props.tournament.place_2_user_id &&
                <Row className='mb-2'>
                    <Col xs={12}>
                        <b>Second Place</b>
                        <UserListItem userId={this.props.tournament.place_2_user_id} localUser={this.props.localUser}/>
                    </Col>
                </Row>}
                {this.props.tournament.place_3_user_id &&
                <Row className='mb-2'>
                    <Col xs={12}>
                        <b>Third Place</b>
                        <UserListItem userId={this.props.tournament.place_3_user_id} localUser={this.props.localUser}/>
                    </Col>
                </Row>}
            </NerdHerderStandardCardTemplate>
        );
    }
}

class TournamentCheckinCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentCheckinCard'>
                <TournamentCheckinCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentCheckinCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.expectedCode = generateTournamentCheckinCode(this.props.tournament.id);
        this.doCheckinOnce = true;

        // check to see if the user is trying to check-in via query param
        this.queryParamCheckinCode = null;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.checkin) {
            this.queryParamCheckinCode = params.checkin;
        }

        let userIsCheckedIn = false;
        for (const usersTournaments of this.props.tournament.users_tournaments) {
            if (usersTournaments.user_id === this.props.localUser.id) {
                if (usersTournaments.checked_in) {
                    userIsCheckedIn = true;
                }
                break;
            }
        }

        this.state = {
            showQrModal: false,
            showCheckedInMessageModal: false,
            userIsCheckedIn: userIsCheckedIn,
            updating: false,
            goodScan: null,
            formManualCode: ''
        }
    }

    componentDidMount() {
        // only thing we're doing here is checking to see if the user is checking in
        if (!this.state.userIsCheckedIn) {
            if (this.queryParamCheckinCode === this.expectedCode) {
                setTimeout(()=>this.doCheckin(), 0);
            }
        }
    }

    doCheckin() {
        this.setState({updating: true});
        const postData = {user_id: this.props.localUser.id, checked_in: true};
        this.restApi.genericPostEndpointData('tournament-checkin', this.props.tournament.id, postData)
        .then((response)=>{
            console.debug('user checked in');
            this.setState({showCheckedInMessageModal: true});
            this.restPubSub.refresh('tournament', this.props.tournament.id, 500);
        })
        .catch((error)=>{
            console.error('failed to change user checkin status');
            console.error(error);
            this.setState({updating: false});
        });
    }

    handleManualCodeChange(event) {
        let value = event.target.value;
        value = value.toUpperCase();
        value = value.trim();
        this.setState({formManualCode: value});
    }

    onQrScan(decodedText, decodedResult) {
        console.log('text:', decodedText);
        this.setState({showQrModal: false});
        if (decodedText.includes(this.expectedCode)) {
            if (this.doCheckinOnce) setTimeout(()=>this.doCheckin(), 500);
            this.setState({goodScan: true});
        }
    }
    
    render() {
        if (!this.props.tournament.require_checkin) return(null);
        if (this.state.showCheckedInMessageModal && this.state.userIsCheckedIn) return(<NerdHerderMessageModal title='Check-In' message='Congratulations! You are checked in!' onCancel={()=>this.setState({showCheckedInMessageModal: false})} localUser={this.props.localUser}/>)
        if (this.state.userIsCheckedIn) return(null);

        // see if the user is checked in already
        for (const usersTournaments of this.props.tournament.users_tournaments) {
            if (usersTournaments.user_id === this.props.localUser.id) {
                if (usersTournaments.checked_in) {
                    setTimeout(()=>this.setState({userIsCheckedIn: true}), 1000);
                }
                break;
            }
        }

        // check the code
        let codeResult = null;
        if (this.state.formManualCode.length === 7) {
            if (this.state.formManualCode === this.expectedCode) {
                codeResult = <Form.Text className='text-primary'><small>Code accepted, checking in...</small></Form.Text>
                if (this.doCheckinOnce) setTimeout(()=>this.doCheckin(), 500);
                this.doCheckinOnce = false;
            } else {
                codeResult = <Form.Text className='text-danger'><small>That code is not correct</small></Form.Text>
            }
        }

        let scanResult = null;
        if (this.state.goodScan === true) {
            scanResult = <Form.Text className='text-primary'><small>QR Code accepted, checking in...</small></Form.Text>
        } else if (this.state.goodScan === false) {
            scanResult = <Form.Text className='text-danger'><small>That code is not correct</small></Form.Text>
            setTimeout(()=>this.setState({goodScan: null}), 3000);
        }

        return(
            <NerdHerderStandardCardTemplate title='Check-In' titleIcon='qr-code.png'>
                {this.state.showQrModal &&
                <NerdHerderQrScanModal onCancel={()=>this.setState({showQrModal: false})} onScan={(dt, dr)=>this.onQrScan(dt, dr)}/>}
                <Form>
                    <Form.Group className="form-outline mb-3">
                        <Form.Text muted>
                            <div>
                                Once you arrive at the venue, you are required to check in through NerdHerder. This way, the organizers can figure out who is present and who is missing.
                            </div>
                            <div className='mt-2'>
                                This is normally done with a QR Code that is provided/posted by the organizers.
                            </div>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="form-outline mb-3">
                        <div className="d-grid gap-2">
                            <Button variant='primary' disabled={this.state.updating} onClick={()=>this.setState({showQrModal: true})}>Open QR Scanner</Button>
                        </div>
                        {scanResult}
                    </Form.Group>
                    <hr/>
                    <Form.Group className="form-outline mb-3">
                        <Form.Text muted>If scanning a QR code won't work for your device, there is also a 6 digit code.</Form.Text>
                        <div className='mt-1'>
                            <Row className='justify-content-center'>
                                <Col xs={12} sm={8} md={6}>
                                    <Form.Control size='sm' type="text" placeholder="XXX-XXX" disabled={this.state.updating} onChange={(e)=>this.handleManualCodeChange(e)} autoComplete='off' value={this.state.formManualCode} maxLength={7}/>
                                </Col>
                            </Row>
                        </div>
                        {codeResult}
                    </Form.Group>
                    <hr/>
                    <Form.Group className="form-outline mb-3">
                        <Form.Text muted>As a last resort, the organizers can manually mark you present.</Form.Text>
                    </Form.Group>
                </Form>
            </NerdHerderStandardCardTemplate>
        );
    }
}

class TournamentRankingsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentRankingsCard'>
                <TournamentRankingsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentRankingsCardInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showTopOnly: true,
        }
    }

    render() {
        const tournament = this.props.tournament;
        const lastCompletedRound = getLastCompletedRound(this.props.tournament);
        const playerIds = [];
        const droppedIds = [];
        const hasPlayedDict = {};
        const recordDict = {};
        const matchPointsDict = {};
        const vpDict = {};
        const movDict = {};
        const metricPerOpponentDict = {};
        const strengthOfScheduleDict = {};
        const numberOfByesDict = {};
        tabulateTournamentData(tournament, lastCompletedRound, hasPlayedDict, recordDict, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict);
        const sortedUserIds = sortTournamentPlayers(tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict);

        // setup the column contents
        const rank = {};
        const rightColumnData = {};
        let rankIndex = 1;
        let middleColumnData = {};
        let middleColumnTitle = null;
        let rightColumnTitle = null;

        // determine the tiebreakers - drop the first ranking because it's the tournament subtype & not a tiebreaker
        let tiebreakersList = parseRanking(tournament.ranking);
        if (tiebreakersList.length > 1) tiebreakersList.shift();
        // remove all instances of rec and na from tiebreakers
        let doRemove = true;
        while (doRemove) {
            doRemove = false;
            for (let i=0; i<tiebreakersList.length; i++) {
                if (tiebreakersList[i] === 'rec' || tiebreakersList[i] === 'na') {
                    tiebreakersList.splice(i, 1);
                    doRemove = true;
                    break;
                }
            }
        }
        // only want tiebreakers to be 3 long max
        if (tiebreakersList.length > 3) tiebreakersList = tiebreakersList.slice(0, 3);

        // the middle column title depends on the tournament subtype
        if (tournament.subtype === 'mp') {
            middleColumnTitle = 'MPs'
        } else if (tournament.subtype === 'vp') {
            middleColumnTitle = 'VPs';
        } else if (tournament.subtype === 'vp') {
            middleColumnTitle = 'MoV';
        } else {
            middleColumnTitle = '';
        }

        // the right column title depends on the tiebreakers
        const rightTitleList = [];
        for (const [i, tiebreaker] of tiebreakersList.entries()) {
            let titleJsx = null;
            let sep = null;
            if (i > 0) sep = ' / ';
            switch (tiebreaker) {
                case 'mov':
                    titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                    break;
                case 'mov1':
                    titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                    break;
                case 'mov2':
                    titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                    break;
                case 'mov3':
                    titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                    break;
                case 'sos':
                    titleJsx = <span key={tiebreaker}>{sep}SoS</span>
                    break;
                case 'vp':
                    titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                    break;
                case 'vp1':
                    titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                    break;
                case 'vp2':
                    titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                    break;
                case 'vp3':
                    titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                    break;
                default:
                    console.error(`hit unexpected tiebreaker condition ${tiebreaker}`);
            }
            rightTitleList.push(titleJsx);
        }
        if (rightTitleList.length === 0) {
            rightColumnTitle = '';
        } else {
            rightColumnTitle = <span>{rightTitleList}</span>
        }

        for (const playerId of sortedUserIds) {
            if (this.props.usersTournaments[playerId].dropped) {
                droppedIds.push(playerId);
            } else {
                playerIds.push(playerId);
            }

            // the middle column data is determined by the subtype of the tournament
            if (tournament.subtype === 'mp') {
                middleColumnData[playerId] = <span>{matchPointsDict[playerId]}</span>
            } else if (tournament.subtype === 'vp') {
                middleColumnData[playerId] = <span>{vpDict[playerId].score1}</span>
            } else if (tournament.subtype === 'mov'){
                middleColumnData[playerId] = <span>{movDict[playerId].score1}</span>
            } else {
                middleColumnData[playerId] = '';
            }

            // the right column data is determined by the tiebreakers
            const rightDataList = [];
            for (const [i, tiebreaker] of tiebreakersList.entries()) {
                let jsx = null;
                let sep = null;
                if (i > 0) sep = ' / ';
                let keyId = `${tiebreaker}-${playerId}`;
                switch (tiebreaker) {
                    case 'mov':
                        jsx = <span key={keyId}><small>{sep}{movDict[playerId].score1}</small></span>
                        break;
                    case 'mov1':
                        jsx = <span key={keyId}><small>{sep}{movDict[playerId].score1}</small></span>
                        break;
                    case 'mov2':
                        jsx = <span key={keyId}><small>{sep}{movDict[playerId].score2}</small></span>
                        break;
                    case 'mov3':
                        jsx = <span key={keyId}><small>{sep}{movDict[playerId].score3}</small></span>
                        break;
                    case 'sos':
                        let sosString = strengthOfScheduleDict[playerId];
                        if (tournament.sos_method === 'average') sosString = strengthOfScheduleDict[playerId].toFixed(2);
                        jsx = <span key={keyId}><small>{sep}{sosString}</small></span>
                        break;
                    case 'vp':
                        jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score1}</small></span>
                        break;
                    case 'vp1':
                        jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score1}</small></span>
                        break;
                    case 'vp2':
                        jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score2}</small></span>
                        break;
                    case 'vp3':
                        jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score3}</small></span>
                        break;
                    default:
                        console.error(`hit unexpected tiebreaker condition ${tiebreaker}`);
                }
                rightDataList.push(jsx);
            }
            if (rightDataList.length === 0) {
                rightColumnData[playerId] = '';
            } else {
                rightColumnData[playerId] = <span>{rightDataList}</span>
            }

            rank[playerId] = rankIndex;
            rankIndex++;

            // if we're only showing the top players, break out after 3rd place
            if (this.state.showTopOnly && rankIndex > 3) break;
        }

        return(
            <NerdHerderStandardCardTemplate title='Tournament Rankings' titleIcon='ranking.png'>
                <TableOfUsers userIds={playerIds} showDeleteButton={false} headers={['Rank', 'Player', middleColumnTitle, rightColumnTitle]} leftColumnContent={rank} middleColumnContent={middleColumnData} rightColumnContent={rightColumnData} localUser={this.props.localUser}/>
                {!this.state.showTopOnly && droppedIds.length !== 0 &&
                <div className='my-3'>
                    <TableOfUsers title='Dropped Players' userIds={droppedIds} showDeleteButton={false} headers={['Rank', 'Player', middleColumnTitle, rightColumnTitle]} leftColumnContent={rank} middleColumnContent={middleColumnData} rightColumnContent={rightColumnData} localUser={this.props.localUser}/>
                </div>}
                <div className='mt-3 d-grid gap-2'> 
                    <Button variant='primary' onClick={()=>this.setState({showTopOnly: !this.state.showTopOnly})}>{this.state.showTopOnly ? 'Show All Players' : 'Show Top Players'}</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class TournamentEloCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentEloCard'>
                <TournamentEloCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentEloCardInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showTopOnly: true,
        }
    }

    render() {
        if (!this.props.eloStats) return(<NerdHerderLoadingCard title='Tournament ELO'/>);
        const tournament = this.props.tournament;
        const playerIds = [];
        const droppedIds = [];

        // setup the column contents
        const eloColumnData = {};
        let index = 0;

        for (const userId of tournament.player_ids) {
            if (this.props.usersTournaments[userId].dropped) {
                droppedIds.push(userId);
                eloColumnData[userId] = 'none';
            } else {
                playerIds.push(userId);
                eloColumnData[userId] = 'none';
            }

            if (this.props.eloStats.hasOwnProperty(userId)) {
                eloColumnData[userId] = this.props.eloStats[userId];
                if (typeof eloColumnData[userId] === 'number') eloColumnData[userId] = eloColumnData[userId].toFixed(2);
            }

            index++;
            if (this.state.showTopOnly && index > 3) break;
        }

        return(
            <NerdHerderStandardCardTemplate title='Tournament ELO' titleIcon='ranking.png'>
                <TableOfUsers userIds={playerIds} showDeleteButton={false} headers={['Player', 'ELO']} rightColumnContent={eloColumnData} localUser={this.props.localUser}/>
                {!this.state.showTopOnly && droppedIds.length !== 0 &&
                <div className='my-3'>
                    <TableOfUsers title='Dropped Players' userIds={droppedIds} showDeleteButton={false} headers={['Player', 'ELO']} rightColumnContent={eloColumnData} localUser={this.props.localUser}/>
                </div>}
                <div className='mt-3 d-grid gap-2'> 
                    <Button variant='primary' onClick={()=>this.setState({showTopOnly: !this.state.showTopOnly})}>{this.state.showTopOnly ? 'Show All Players' : 'Show Top Players'}</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class TournamentBracketCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentBracketCard'>
                <TournamentBracketCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentBracketCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.widthRef = React.createRef();

        this.theme = createTheme({
            textColor: { main: '#000000', highlighted: '#07090D', dark: '#3E414D' },
            matchBackground: { wonColor: '#cfe2ff', lostColor: '#ffffff' },
            score: {
              background: { wonColor: '#adceff', lostColor: '#f0f1f2' },
              text: { highlightedWonColor: '#0d6efd', highlightedLostColor: '#000' },
            },
            border: {
              color: '#6c757d',
              highlightedColor: '#0000ff',
            },
            roundHeader: { backgroundColor: '#0d6efd', fontColor: '#fff' },
            connectorColor: '#6c757d',
            connectorColorHighlight: '#0000ff',
            svgBackground: '#ffffff',
            width: 200,
            spaceBetweenColumns: 40,
            spaceBetweenRows: 10,
          });
    }

    generateMatchData() {
        let isSingleElim = true;
        let isDoubleElim = false;
        if (this.props.tournament.subtype === 'double-elimination') {
            isSingleElim = false;
            isDoubleElim = true;
        }

        let fullBracketMatches = {upper: [], lower: []};
        let winnerBracketMatches = fullBracketMatches.upper;
        let loserBracketMatches = fullBracketMatches.lower;

        for (const gameId of this.props.tournament.game_ids) {
            const game = this.props.games[gameId];
            const gameIndex = this.props.tournament.idToIndexTable[gameId];
            const elimCodeDict = this.props.tournament.bracketTreeTable[gameIndex];
            const nextIndex = elimCodeDict.nextIndex;
            const nextLoserIndex = elimCodeDict.nextLoserIndex;
            let matchState = null;
            if (game.completion === 'completed') {
                matchState = 'DONE';
            }
            let roundLabel = `Round ${elimCodeDict.roundIndex}`;
            if (isDoubleElim) {
                let bracket = 'WB';
                if (elimCodeDict.bracket === 'lb') bracket = 'LB';
                roundLabel = `${bracket}: Round ${elimCodeDict.roundIndex}`;
            }
            const newMatch =
                {
                    "id": gameIndex,
                    "name": `Game ${gameIndex} / ${game.table_name}`,
                    "nextMatchId": nextIndex,
                    "nextLooserMatchId": null,
                    "startTime": roundLabel,
                    "tournamentRoundText": `Round ${elimCodeDict.roundIndex}`,
                    "state": matchState,
                    "participants": []
                };
            if (nextLoserIndex) {
                if (elimCodeDict.bracket === 'wb') newMatch['nextLooserMatchId'] = nextLoserIndex;
                else newMatch['nextMatchId'] = nextLoserIndex;
            }
            if (isSingleElim || elimCodeDict.bracket === 'wb') {
                winnerBracketMatches.push(newMatch);
            } else {
                loserBracketMatches.push(newMatch);
            }
            
            if (game.player_ids.length === 2) {
                for (const playerId of game.player_ids) {
                    const usersGames = this.props.usersGames[usersGamesKey(playerId, gameId)];
                    const user = this.props.players[playerId];
                    const participant = {
                            "id": user.id,
                            "resultText": usersGames.score.toString(),
                            "isWinner": usersGames.winner,
                            "status": "PLAYED",
                            "name": user.username
                        };
                    newMatch.participants.push(participant);
                }
            } else if (game.player_ids.length === 1 && game.bye) {
                for (const playerId of game.player_ids) {
                    const usersGames = this.props.usersGames[usersGamesKey(playerId, gameId)];
                    const user = this.props.players[playerId];
                    const participant1 = {
                            "id": user.id,
                            "resultText": '-',
                            "isWinner": usersGames.winner,
                            "status": "WALK_OVER",
                            "name": user.username
                        };
                    const participant2 = {
                        "id": getRandomString(10),
                        "resultText": '-',
                        "isWinner": false,
                        "status": "NO_PARTY",
                        "name": 'BYE'
                    };
                    newMatch.participants.push(participant1);
                    newMatch.participants.push(participant2);
                }
            } else {
                const participant1 = {
                        "id": getRandomString(10),
                        "resultText": '-',
                        "isWinner": null,
                        "status": "NO_PARTY",
                        "name": elimCodeDict.topPrevIndex ? `from Game ${elimCodeDict.topPrevIndex}` : 'Placeholder'
                    };
                const participant2 = {
                        "id": getRandomString(10),
                        "resultText": '-',
                        "isWinner": null,
                        "status": "NO_PARTY",
                        "name": elimCodeDict.botPrevIndex ? `from Game ${elimCodeDict.botPrevIndex}` : 'Placeholder'
                    };
                newMatch.participants.push(participant1);
                newMatch.participants.push(participant2);
            }
        }

        if (isSingleElim) return (winnerBracketMatches);
        return (fullBracketMatches);
    }

    render() {
        let isSingleElim = true;
        if (this.props.tournament.subtype === 'double-elimination') isSingleElim = false;

        const matches = this.generateMatchData();
        console.debug(matches);
        let svgHeight = window.innerHeight - 100;
        let svgWidth = 500;
        if (this.widthRef.current) {
            svgWidth = this.widthRef.current.offsetWidth - 30;
        }

        return(
            <NerdHerderStandardCardTemplate title='Elimination Bracket' titleIcon='bracket.png'>
                <div className='fluid' ref={this.widthRef}>
                    {isSingleElim &&
                    <SingleEliminationBracket matches={matches} matchComponent={Match} theme={this.theme}
                        options={{
                            style: {
                            connectorColor: this.theme.connectorColor,
                            connectorColorHighlight: this.theme.connectorColorHighlight,
                            width: this.theme.width,
                            spaceBetweenColumns: this.theme.spaceBetweenColumns,
                            spaceBetweenRows: this.theme.spaceBetweenRows,
                            roundHeader: {isShown: false},
                            },
                        }}
                        svgWrapper={({ children, ...props }) => (
                        <SVGViewer width={svgWidth} height={svgHeight} miniatureProps={{position: 'none'}} {...props} preventPanOutside={true} scaleFactorMax={1} scaleFactorMin={1}>
                            {children}
                        </SVGViewer>)}/>}
                    {!isSingleElim &&
                    <DoubleEliminationBracket matches={matches} matchComponent={Match} theme={this.theme}
                        options={{
                            style: {
                            connectorColor: this.theme.connectorColor,
                            connectorColorHighlight: this.theme.connectorColorHighlight,
                            width: this.theme.width,
                            spaceBetweenColumns: this.theme.spaceBetweenColumns,
                            spaceBetweenRows: this.theme.spaceBetweenRows,
                            roundHeader: {isShown: false},
                            },
                        }}
                        svgWrapper={({ children, ...props }) => (
                        <SVGViewer width={svgWidth} height={svgHeight} miniatureProps={{position: 'none'}} {...props} preventPanOutside={true} scaleFactorMax={1} scaleFactorMin={1}>
                            {children}
                        </SVGViewer>)}/>}
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class TournamentRoundCard extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    startClock(secondsRemaining=null) {
        this.ref.current.startClock(secondsRemaining);
    }

    stopClock(secondsRemaining=null) {
        this.ref.current.stopClock(secondsRemaining);
    }

    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentRoundCard'>
                <TournamentRoundCardInner ref={this.ref} {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentRoundCardInner extends React.Component {
    constructor(props) {
        super(props);

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.isManager = this.props.tournament.isManager(this.props.localUser.id);

        this.state = {
            myGameId: null,
            myGame: null,
            showMyTableOnly: true,
            calledTo: null,
            roundState: this.props.tournamentRound.state,
        }

        this.stateChangeTimeout = null;
    }

    checkGameStatusUpdate(tournamentRound) {
        let myGameId = null;
        let myGame = null;
        for (const gameData of tournamentRound.games) {
            if (gameData.player_ids.includes(this.props.localUser.id)) {
                myGameId = gameData.id;
                myGame = gameData;
                break;
            }
        }

        // this is called from render, so only update the values in state if the game changes
        if (this.state.myGameId !== myGameId) {
            this.setState({myGameId: myGameId, myGame: myGame});
        }
    }

    requestTo() {
        const postData = {
            type: 'call_TO',
            tournament_id: this.props.tournament.id,
            tournament_round_id: this.props.tournamentRound.id,
            game_id: this.state.myGameId,
            message: null,
        };
        this.restPubSub.post('tournament-alert', null, postData);
        this.setState({calledTo: true});
        setTimeout(()=>this.setState({calledTo: false}), 30000);
    }

    render() {
        const thisRound = this.props.tournamentRound;
        if (thisRound.state === 'draft') return (null);

        // update the game id and game data if needed
        setTimeout(()=>this.checkGameStatusUpdate(thisRound), 1000);

        let userFeedback = null;
        if (thisRound.game_ids.length === 0) userFeedback = 'Pairings have not been assigned yet';
        else if (thisRound.state === 'initialized') userFeedback = 'This round has not started yet';
        else if (thisRound.state === 'in-progress') userFeedback = 'This round is in-progress';
        else if (thisRound.state === 'complete') userFeedback = 'This round is complete';

        const gameListItems = [];
        for (const gameId of thisRound.game_ids) {
            let gameIncludesThisUser = false;
            let gameIncludesContact = false;
            const gameData = this.props.games[gameId];
            if (gameData.player_ids.includes(this.props.localUser.id)) gameIncludesThisUser = true;
            for (const playerId of gameData.player_ids) {
                if (this.props.localUser.hasContact(playerId)) {
                    gameIncludesContact = true;
                    break;
                }
            }

            // we always put the local user's game up top
            // then if they are a manager list all games
            // then there is a button to show all games or only show contact games (the default)
            const gameListItem = <GameListItem key={`${gameId}`} gameId={gameId} selectLocalUser={true} league={this.props.league} event={this.props.event} tournament={this.props.tournament} localUser={this.props.localUser}/>
            if (gameIncludesThisUser) {
                gameListItems.unshift(gameListItem);
            } else if (this.isManager) {
                gameListItems.push(gameListItem);
            } else if (gameIncludesContact) {
                gameListItems.push(gameListItem);
            } else if (!this.state.showMyTableOnly) {
                gameListItems.push(gameListItem);
            } 
        }

        let showTable = false;
        if (thisRound.date || thisRound.time || thisRound.duration || thisRound.remaining) showTable = true;
        
        let dateLabel = 'Date';
        let dateTimeString = null;
        if (thisRound.date && thisRound.time) {
            dateLabel = 'Begins';
            let dateTimeValue = `${thisRound.date}T${thisRound.time}`;
            dateTimeString = generateDateTimeString(new Date(dateTimeValue));
        } else if (thisRound.date && !thisRound.time) {
            dateTimeString = generateDateString(new Date(thisRound.date));
        } else if (thisRound.time && !thisRound.date) {
            dateLabel = 'Begins';
            dateTimeString = thisRound.time;
        }
        
        let durationString = null;
        if (thisRound.duration) durationString = `${generateTimeRemainingHMS(thisRound.duration)}`;
        let showRemainingCountdown = false;
        if (thisRound.state !== 'complete' && thisRound.duration && thisRound.remaining !== null) showRemainingCountdown = true;

        // only show the call button if the game is init'd or in-progress and the user has a game in the round, and the game is not yet complete
        let showCallToButton = false;
        if (thisRound.state === 'initialized' || thisRound.state === 'in-progress') {
            if (this.state.myGameId !== null && this.state.myGame.state === 'posted' && this.state.myGame.completion !== 'completed') {
                showCallToButton = true;
            }
        }

        return(
            <NerdHerderStandardCardTemplate id={`tournament-round-${thisRound.id}`} title={thisRound.name} titleIcon='swords.png' userFeedback={userFeedback}>
                {showTable &&
                <Table>
                    <tbody>
                        {dateTimeString &&
                        <tr>
                            <td>{dateLabel}</td>
                            <td>{dateTimeString}</td>
                        </tr>}
                        {durationString &&
                        <tr>
                            <td>Round Duration</td>
                            <td>{durationString}</td>
                        </tr>}
                        {showRemainingCountdown &&
                        <tr>
                            <td>Time Remaining</td>
                            <td><NerdHerderCountdown roundId={thisRound.id}/></td>
                        </tr>}
                    </tbody>
                </Table>
                }
                {gameListItems}
                <div className='mt-3 d-grid gap-2'> 
                    <Button variant='primary' onClick={()=>this.setState({showMyTableOnly: !this.state.showMyTableOnly})}>{this.state.showMyTableOnly ? 'Show All Tables' : 'Show Less'}</Button>
                    {showCallToButton &&
                    <Button variant='danger' disabled={this.state.calledTo} onClick={()=>this.requestTo()}>{this.state.calledTo ? 'Request Sent' : 'Request Tournament Organizer'}</Button>}
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class TournamentEventPlaceholderCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='TournamentEventPlaceholderCard'>
                <TournamentEventPlaceholderCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class TournamentEventPlaceholderCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.tournamentId = null;
        if (this.props.league.tournament_ids.length === 1) {
            this.tournamentId = this.props.league.tournament_ids[0];
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState({tournament: newTournament});
    }
    
    render() {
        if (this.tournamentId === null) return(null);
        if (this.props.tournament === null) return(null);

        // don't want to show anything unless the tab would be empty
        // this happens if the tournament is in draft...
        // the tournament is in some other state, but there are no rounds
        // there are rounds, but they're all in draft
        let showThisCard = false;
        if (this.props.tournament.state === 'draft') showThisCard = true;
        if (!showThisCard && this.props.tournament.round_ids === 0) showThisCard = true;
        if (!showThisCard) {
            let allRoundsDraft = true
            for (const roundData of this.props.tournament.rounds) {
                if (roundData.state !== 'draft') {
                    allRoundsDraft = false
                }
            }
            if (allRoundsDraft) showThisCard = true;
        }

        if (!showThisCard) return(null);


        let manageOptions = null;
        if (this.props.league.isManager(this.props.localUser.id) && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'game',
                focusCard: 'manage-tournaments-card',
            }
        }
        
        return(
            <NerdHerderStandardCardTemplate id="tournament-placeholder-card" title='Tournament' titleIcon='tournament.png' manageOptions={manageOptions}>
                <p>This tournament has not been fully configured - check back later.</p>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(TournamentPage);
