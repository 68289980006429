import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';
import { getStorageFilePublicUrl } from '../utilities';

export class IntegratedListSelector extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='IntegratedListSelector'>
                <IntegratedListSelectorInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class IntegratedListSelectorInner extends React.Component {
    constructor(props) {
        super(props);

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.serviceName = this.props.service;
        switch (this.serviceName) {
            case 'swsp_stb':
                this.serviceName = 'SWSP Strike Team Builder';
                break;
            case 'cerebro':
                this.serviceName = 'Cerebro MCP';
                break;
            case 'tabletop_admiral':
                this.serviceName = 'Tabletop Admiral';
                break;
            case 'point_break':
                this.serviceName = 'Point Break';
                break;
            default:
        }

        this.state = {
            serviceResponse: null,
            selectedListId: null,
            errorFeedback: null,
        }
    }

    componentDidMount() {
        this.initializeLists();
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    initializeLists() {
        if (this.props.service === null) return;
        this.restApi.genericGetEndpointData('list-integration-list', null, {service: this.props.service})
        .then(response => {
            let serviceResponseDict = response.data;
            if (serviceResponseDict.user_feedback === 'OK' || serviceResponseDict.user_feedback === null) {
                this.setState({serviceResponse: serviceResponseDict, errorFeedback: null});
            } else {
                this.setState({serviceResponseDict: null, errorFeedback: serviceResponseDict.user_feedback});
            }
        })
        .catch(error => {

            console.error(error)
        })
    }

    onSelectListItem(listId) {
        let passToCallback = listId;
        if (listId === this.state.selectedListId) {
            this.setState({selectedListId: null});
            passToCallback = null;
        } else {
            this.setState({selectedListId: listId});
        }

        if (this.props.selectCallback) {
            this.props.selectCallback(passToCallback);
        }
        
    }

    onClickViewList(event, listId) {
        event.stopPropagation();
        this.restApi.genericGetEndpointData('list-integration', listId, {'service': this.props.service})
        .then((response) => {
            let filename = response.data.filename;
            let errorFeedback = response.data.user_feedback;
            let path = response.data.path;
            if (errorFeedback && (filename === null || path === null)) {
                this.setState({errorFeedback: errorFeedback});
            } else if (path) {
                let url = getStorageFilePublicUrl(`/${path}`);
                window.open(url, '_blank');
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        
        // if this topic uses integrated lists, then select the integration as the default option
        let uploadMode = 'upload';
        if (newLeague.topic_id === 'MCP') {
            this.isMCP = true;
            uploadMode = 'cerebro';
        }
        if (newLeague.topic_id === 'SWL') {
            this.isSWL = true;
            uploadMode = 'tabletop_admiral';
        }
        this.setState({league: newLeague, uploadMode: uploadMode});
        
        if (this.presentation === 'league') {
            // the league provides list_container_ids and list_containers for the league, as well as tournaments, and events in the league
            //...so need to only include those without events or tournaments
            const reducedListContainerIds = [];
            const reducedListContainers = [];
            for (const listContainer of newLeague.list_containers) {
                if (listContainer.tournament_id === null && listContainer.event_id === null) {
                    reducedListContainerIds.push(listContainer.id);
                    reducedListContainers.push(listContainer);
                }
            }
            this.setState({listContainerIds: reducedListContainerIds, listContainers: reducedListContainers, typeWord: 'league'});
            this.initializeDzRefs(reducedListContainerIds);
            this.initializeListTexts(reducedListContainerIds);
        }

        // if this was the update that matters for our presentation, clear the update flag
        if (this.state.presentation === 'league') {
            this.setState({updating: false});
        }
    }

    render() {
        if (this.props.league === null) return(null);
        let listNoun = this.props.league.topic.list_noun;

        const listGroupItems = [];
        if (this.state.serviceResponse) {
            for (const listId of this.state.serviceResponse.list_ids) {
                const summary = this.state.serviceResponse.list_summaries[listId];
                let item = null;
                let extras = null;
                if (summary.hasOwnProperty('factions') && summary.hasOwnProperty('points')) {
                    extras = `${summary.factions}-${summary.points}`;
                }
                else if (summary.hasOwnProperty('factions')) {
                    extras = `${summary.factions}`;
                }
                else if (summary.hasOwnProperty('points')) {
                    extras = `${summary.points}`;
                }
                
                if (extras) {
                    item =
                    <ListGroup.Item action active={listId===this.state.selectedListId} as='div' key={listId} onClick={()=>this.onSelectListItem(listId)}>
                        <Row>
                            <Col>
                                <small>{summary.name} <small className='text-muted'>({extras})</small></small>
                            </Col>
                            <Col xs='auto'>
                                <Button size='sm' variant='primary' onClick={(e)=>this.onClickViewList(e, listId)}>
                                    <NerdHerderFontIcon icon='flaticon-search'/>
                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>  
                } else {
                    item =
                    <ListGroup.Item action active={listId===this.state.selectedListId} as='div' key={listId} onClick={()=>this.onSelectListItem(listId)}>
                        <Row>
                            <Col>
                                <small>{summary.name}</small>
                            </Col>
                            <Col xs='auto'>
                                <Button size='sm' variant='primary' onClick={(e)=>this.onClickViewList(e, listId)}>
                                    <NerdHerderFontIcon icon='flaticon-search'/>
                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                }

                listGroupItems.push(item);
            }
        }

        return(
            <div className='text-start'>
                {this.state.errorFeedback && 
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.serviceResponse && listGroupItems.length !== 0 &&
                <ListGroup variant='flush'>
                    {listGroupItems}
                </ListGroup>}
                {this.state.serviceResponse && !this.state.errorFeedback && listGroupItems.length === 0 &&
                <p>Successfully accessed {this.serviceName}...however there are no {listNoun}s to show.</p>}
                {!this.state.serviceResponse && !this.state.errorFeedback &&
                <div className='text-center' style={{height: '200px'}}>
                    <Spinner variant='primary' animation="border" role="status" style={{width: "100px", height: "100px" }}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>}
            </div>
        );
    }
}
