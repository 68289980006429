import React from 'react';
import { getLocalStaticFileUrl } from '../utilities';
import { QRCode } from 'react-qrcode-logo';

export class NerdHerderQrCode extends React.Component {

    render() {
        const logoSource = getLocalStaticFileUrl('/nerdherder_light_small.png');
        let value = 'https://www.nerdherder.app'
        if (this.props.href && this.props.href.includes('http')) {
            value = this.props.href;
        }
        if (this.props.href && !this.props.href.includes('http')) {
            if (this.props.href[0] === '/') {
                value = `${window.location.protocol}//${window.location.host}${this.props.href}`;
            } else {
                value = `${window.location.protocol}//${window.location.host}/${this.props.href}`;
            }
        }

        if (this.props.value) value = this.props.value;
        let size = this.props.size || 150;
        let quietZone = this.props.quietZone || 10;
        let padding = 5;
        let qrSize = size - 2 * (quietZone + padding + 5);
        
        return (
            <div className='text-center' style={{padding: `${padding}px`, height: `${size}px`, width:`${size}px`, border: '5px solid #0d6efd', borderRadius: '10px'}}>
                <QRCode value={value} size={qrSize} quietZone={quietZone} qrStyle='dots' ecLevel='H' eyeColor='#0d6efd' eyeRadius={5} logoImage={logoSource} removeQrCodeBehindLogo={true}/>
            </div>
        )
    }
}

export class NerdHerderSimpleQrCode extends React.Component {

    render() {
        let value = 'https://www.nerdherder.app'
        if (this.props.href && this.props.href.includes('http')) value = this.props.href;
        if (this.props.href && !this.props.href.includes('http')) value = `${window.location.protocol}//${window.location.host}/${this.props.href}`;
        if (this.props.value) value = this.props.value;
        let size = this.props.size || 150;
        let quietZone = this.props.quietZone || 10;
        let padding = 5;
        let qrSize = size - 2 * (quietZone + padding + 5);
        
        return (
            <div className='text-center' style={{padding: `${padding}px`, height: `${size}px`, width:`${size}px`, border: '5px solid black', display: 'block'}}>
                <QRCode value={value} size={qrSize} quietZone={quietZone} ecLevel='M'/>
            </div>
        )
    }
}
