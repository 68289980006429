import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderRandomSelectorResultModal } from './NerdHerderModals';
import { NerdHerderStandardCardTemplate } from './NerdHerderStandardCardTemplate';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';
import { UserListItem } from './NerdHerderListItems';


export class NerdHerderRandomSelectorCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='NerdHerderRandomSelectorCard'>
                <NerdHerderRandomSelectorCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class NerdHerderRandomSelectorCardInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.randomSelector === 'undefined') console.error('missing props.randomSelector');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.presentation = 'league';
        if (this.props.tournament) {
            this.presentation = 'tournament';
        } else if (this.props.event) {
            this.presentation = 'event';
        }

        this.state = {
            showSelectResultModal: false,
            updating: false,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    refresh(delay=0) {
        switch(this.presentation) {
            case 'league':
                this.restPubSub.refresh('league', this.props.randomSelector.league_id, delay);
                break;
            case 'event':
                this.restPubSub.refresh('event', this.props.randomSelector.event_id, delay);
                break;
            case 'tournament':
                this.restPubSub.refresh('tournament', this.props.randomSelector.tournament_id, delay);
                break;
            default:
                console.error('cannot determine presentation to refresh');
        }
    }

    onAcceptResultModal(result) {
        this.refresh(500);
        this.setState({showSelectResultModal: false});
    }

    onCancelResultModal() {
        this.setState({showSelectResultModal: false});
    }

    onClickRandomizeButton(containerId, lockOnSubmit) {
        this.setState({showSelectResultModal: true});
    }

    render() {
        const localUserIsManager = this.props.league.isManager(this.props.localUser.id);
        
        // make sure the presentation matches the view displayed
        if (this.presentation === 'league' && (this.props.randomSelector.event_id || this.props.randomSelector.tournament_id)) return(null);
        if (this.presentation === 'event' && this.props.randomSelector.tournament_id) return(null);

        // if this user isn't a manager but the view is managers only don't display the card
        if (!localUserIsManager && this.props.randomSelector.state === 'draft') return(null);

        let resultJsx = generateRandomSelectorJsx(this.props.randomSelector.result, this.props.localUser);

        let manageOptions = null;
        if (localUserIsManager && this.props.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.props.league.id}`,
                focusTab: 'info',
                focusCard: 'manage-random-selectors-card',
            }
        }

        return (
            <NerdHerderStandardCardTemplate id={`random-selector-${this.props.randomSelector.id}`} title={this.props.randomSelector.name} titleIcon='shuffle.png' manageOptions={manageOptions}>
                {this.state.showSelectResultModal &&
                <NerdHerderRandomSelectorResultModal randomSelectorId={this.props.randomSelector.id}
                                                     league={this.props.league}
                                                     event={this.props.event}
                                                     tournament={this.props.tournament}
                                                     onCancel={()=>this.onCancelResultModal()}
                                                     onAccept={(r)=>this.onAcceptResultModal(r)}
                                                     localUser={this.props.localUser}/>}
                {this.props.randomSelector.state === 'draft' &&
                <Alert variant='warning'>This card is only visible to the organizers, it is hidden from everyone else</Alert>}
                {this.props.randomSelector.description &&
                <Row className='mb-2'>
                    <Col>
                        <small className='text-muted'>{this.props.randomSelector.description}</small>
                    </Col>
                </Row>}
                {resultJsx &&
                <Row>
                    <Col>
                        {resultJsx}
                    </Col>
                </Row>}
                {localUserIsManager && this.props.randomSelector.result === null &&
                <Row>
                    <Col>
                        <div className='text-end'>
                            <Button variant='primary' onClick={()=>this.onClickRandomizeButton()}>Select Now</Button>
                        </div>
                    </Col>
                </Row>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

export function generateRandomSelectorJsx(result, localUser) {
    let resultJsx = null;
    let resultJsxItems = [];
    if (result === null) {
        resultJsx = <div className='text-center'><p>The result has not been determined yet!</p></div>
        return(resultJsx);
    } else {
        let resultList = result.split(':');
        if (resultList.length === 0 || resultList.length === 1) {
            console.error('invalid result list when parsing random selector result value');
            return(null);
        }
        let resultType = resultList.shift();
        switch(resultType) {
            case 'SUID':
                let userId = resultList[0];
                userId = parseInt(userId);
                resultJsx = 
                    <div>
                        <UserListItem userId={userId} localUser={localUser}/>
                    </div>
                break;

            case 'MUID':
                for (const userId of resultList) {
                    const userListItem = <UserListItem key={userId} userId={userId} slim={true} localUser={localUser}/>
                    resultJsxItems.push(userListItem);
                }
                resultJsx = 
                    <div>
                        {resultJsxItems}
                    </div>
                break;

            case 'ORDR':
                for (const userId of resultList) {
                    const userListItem = <UserListItem key={userId} userId={userId} slim={true} localUser={localUser}/>
                    resultJsxItems.push(userListItem);
                }
                resultJsx = 
                    <div>
                        {resultJsxItems}
                    </div>
                break;

            case 'GRUP':
                let groupSize = resultList.shift();
                let groupCount = 0;
                let groupIndex = 1;
                for (const userId of resultList) {
                    if (groupCount === 0) {
                        resultJsxItems.push(<div key={`group-${groupIndex}`} className='mt-1'><b>Group {groupIndex}</b></div>);
                    }
                    const userListItem = <UserListItem key={userId} userId={userId} slim={true} localUser={localUser}/>
                    resultJsxItems.push(userListItem);
                    groupCount++;
                    if (groupCount >= groupSize) {
                        groupCount = 0;
                        groupIndex++;
                    }
                }
                resultJsx = 
                    <div>
                        {resultJsxItems}
                    </div>
                break;

            case 'VALX':
                let min = resultList.shift();
                let max = resultList.shift();
                let value = resultList.shift();
                resultJsx = 
                    <div className='text-center'>
                        <p className='text-muted'><small>NerdHerder chose a random number between {min} and {max}:</small></p>
                        <p style={{fontSize: 40}}><b>{value}</b></p>
                    </div>
                break;
            default:
                console.error('reached invalid state.method in generateRandomSelectorJsx()');
        }
    }

    return(resultJsx);
}
