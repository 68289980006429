import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {ElementsConsumer, PaymentElement} from '@stripe/react-stripe-js';

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.formLoadedTimeout = setTimeout(()=>this.checkFormLoaded(), 1000);

        this.state = {
            updating: true,
            errorFeedback: null,
        }
    }

    checkFormLoaded() {
        // not loaded? keep checking
        if (!this.props.stripe || !this.props.elements) {
            this.formLoadedTimeout = setTimeout(()=>this.checkFormLoaded(), 1000);
        } else {
            this.setState({updating: false});
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (!this.props.stripe || !this.props.elements) return;

        // set the stripe return url
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const portnum = window.location.port;
        const returnUrl = `${protocol}//${hostname}:${portnum}/app/stripepaymentcomplete`;
        const elements = this.props.elements;
        const result = await this.props.stripe.confirmPayment({
            elements,
            confirmParams: {return_url: returnUrl},
        });

        if (result.error) {
            this.setState({errorFeedback: result.error.message});
        } else {
            this.setState({errorFeedback: null});
        }
    };

    render() {
        return (
        <form onSubmit={this.handleSubmit}>
            {this.state.errorFeedback &&
            <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
            <PaymentElement />
            <hr/>
            <div className='text-end'>
                <Button type='submit' variant='primary' disabled={!this.props.stripe||this.state.updating}>Submit</Button>
            </div>
        </form>
        );
    }
}

export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <CheckoutForm stripe={stripe} elements={elements}/>
            )}
        </ElementsConsumer>
    )
}