import React from 'react';
import Button from 'react-bootstrap/Button';
import { Navigate } from 'react-router-dom';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './NerdHerderStandardCardTemplate';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';

export class NerdHerderLeagueInvitationCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='NerdHerderLeagueInvitationCard'>
                <NerdHerderLeagueInvitationCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

export class NerdHerderLeagueInvitationCardInner extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.localUser) console.error('missing props.localUser');
        if (!this.props.leagueId) console.error('missing props.leagueId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            navigateTo: null,
            fromUser: null,
            league: null,
            usersLeagues: null,
            updating: false,
            hasError: false,
            userFeedback: null,
        };
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('user', this.props.fromUserId, (d, k)=>{this.updateFromUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('league', this.props.leagueId, (d, k)=>{this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('self-user-league', this.props.leagueId, (d, k)=>{this.updateUsersLeagues(d, k)}, (e, a)=>{this.errorUsersLeagues(e, a)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateFromUser(userData, key) {
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({fromUser: user});
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague});
    }

    errorUsersLeagues(error, apiName) {
        this.setState({hasError: true});
    }

    updateUsersLeagues(usersLeaguesData, key) {
        const newUsersLeagues = NerdHerderDataModelFactory('user-league', usersLeaguesData);
        let userFeedback = null;
        let hasError = false;

        if (newUsersLeagues.player) {
            userFeedback = `You have joined this ${this.state.league.getTypeWord()}!`;
        } else if (newUsersLeagues.invite_sent === false) {
            hasError = true;
        }

        this.setState({usersLeagues: newUsersLeagues, updating: false, hasError: hasError, userFeedback: userFeedback});
    }

    render() {
        if (this.state.hasError) return(null);
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);
        if (this.state.league === null) return(<NerdHerderLoadingCard title="Invitation..."/>);
        if (this.state.usersLeagues === null) return(<NerdHerderLoadingCard title="Invitation..."/>);
        if (this.state.fromUser === null) return(<NerdHerderLoadingCard title="Invitation..."/>);

        let leagueUrl = this.state.league.getPageUrl();
        let buttonText =  'Check It Out';
        if (this.state.usersLeagues.player) buttonText = "Let's Go!";

        return (
            <NerdHerderStandardCardTemplate
                league={this.state.league}
                localUser={this.props.localUser}
                leagueTitle={true}
                leagueIcon={true}
                icon={true}
                userFeedback={this.state.userFeedback}
                updating={this.state.updating}>
                <p>{this.state.fromUser.username} has invited you to join {this.state.league.name}</p>
                <div className="text-end">
                    <Button variant="primary" onClick={()=>this.setState({navigateTo: leagueUrl})} disabled={this.state.updating}>{buttonText}</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}
