import { NerdHerderDataModelFactory } from './nerdherder-models';
import { NerdHerderRestApi } from './NerdHerder-RestApi';

export class NerdHerderJoinModelRestApi {
    constructor(apiName, modelName, idName1, idValue1, idName2, idValue2) {
        this.restApi = new NerdHerderRestApi();
        this.apiName = apiName;
        this.modelName = modelName;
        this.idNames = [idName1, idName2];
        this.idValues = [idValue1, idValue2];
        this.queryParams = {[idName1]: idValue1, [idName2]: idValue2};
    }

    get() {
        return new Promise((resolve, reject) => {
            this.restApi.genericGetEndpointData(this.apiName, null, this.queryParams)
            .then(response => {
                const newModel = NerdHerderDataModelFactory(this.modelName, response, this.idValues[0], this.idValues[1]);
                resolve(newModel);
            }).catch(error => {
                reject(error);
            });
        })
    }

    put(putData) {
        return new Promise((resolve, reject) => {
            this.restApi.genericPutEndpointData(this.apiName, null, putData, this.queryParams)
            .then(response => {
                resolve(response);
            }).catch(error => {
                if (error.response.status === 404) {
                    const newModel = NerdHerderDataModelFactory(this.modelName, null, this.idValues[0], this.idValues[1]);
                    const mergedModel = Object.assign(newModel, putData);
                    this.post(mergedModel)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            });
        })
    }

    patch(patchData) {
        return new Promise((resolve, reject) => {
            this.restApi.genericPatchEndpointData(this.apiName, null, patchData, this.queryParams)
            .then(response => {
                resolve(response);
            }).catch(error => {
                if (error.response.status === 404) {
                    const newModel = NerdHerderDataModelFactory(this.modelName, null, this.idValues[0], this.idValues[1]);
                    const mergedModel = Object.assign(newModel, patchData);
                    this.post(mergedModel)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            });
        })
    }

    post(postData) {
        return new Promise((resolve, reject) => {
            this.restApi.genericPostEndpointData(this.apiName, null, postData, this.queryParams)
            .then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
    }

    delete() {
        return new Promise((resolve, reject) => {
            this.restApi.genericDeleteEndpointData(this.apiName, null, this.queryParams)
            .then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    }
}
