import React from 'react';

export class ScreenSizeSelector extends React.Component {
    constructor(props) {
        super(props);
        const allowedValues = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

        if (this.props.min && !allowedValues.includes(this.props.min)) console.error(`ScreenSizeSelector given invalid props.min (${this.props.min})`);
        if (this.props.max && !allowedValues.includes(this.props.max)) console.error(`ScreenSizeSelector given invalid props.max (${this.props.min})`);
    }

    getOneLarger(displaySize) {
        switch(displaySize) {
            case 'xs':
                return 'sm';
            case 'sm':
                return 'md';
            case 'md':
                return 'lg';
            case 'lg':
                return 'xl';
            case 'xl':
                return 'xxl';
            case 'xxl':
                return 'xxl';
            default:
                return 'xs';
        }
    }

    render() {
        let minSize = this.props.min || null;
        let maxSize = this.props.max || null;

        // if minSize or maxSize are at the limit, just assume no min or max
        if (minSize === 'xs') minSize = null;
        if (maxSize === 'xxl') maxSize = null;

        // default is show everything - note that block = show it in block format (not block it from being displayed)
        let displayClass = 'd-block';
        
        // if only a min was specified, need to display:none everything smaller
        if (minSize !== null && maxSize === null) {
            displayClass = `d-none d-${minSize}-block`;
        }
        
        // if only a max was specified, need to display:none everything one larger
        else if (maxSize !== null && minSize === null) {
            displayClass = `d-block d-${this.getOneLarger(maxSize)}-none`;
        }

        // if there is a min and max, only show what is inbetween
        else {
            displayClass = `d-none d-${minSize}-block d-${this.getOneLarger(maxSize)}-none`;
        }

        return(
            <div className={displayClass}>
                {this.props.children}
            </div>
        );
    }
}
