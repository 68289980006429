import React from 'react';
import { DateTime } from 'luxon';
import { Html5QrcodeScanner } from 'html5-qrcode';
import axios from 'axios';
import currency from 'currency.js';
import jwt_decode from "jwt-decode";
import { GoogleLogin } from '@react-oauth/google';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import Badge from 'react-bootstrap/Badge';
import pluralize from 'pluralize';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './NerdHerderStripeCheckoutForm';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderJoinModelRestApi } from '../NerdHerder-JoinModelRestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { parseRanking, generateRanking, getLastCompletedRound, tabulateTournamentData, sortTournamentPlayers } from '../tournament_utilities';
import { getRandomInteger, convertListToDict, generateDateString, convertTodaysLocalDateObjectToFormInput, getStaticStorageImageFilePublicUrl, getStorageFilePublicUrl, capitalizeFirstLetter, capitalizeFirstLetters, isValidHttpUrl, getCurrency, getCookie, setCookie, setLocal, delLocal, getCookieParseBool, getFailureMessage, convertLuxonTimezone } from '../utilities';
import { TableOfUsers } from './NerdHerderTableHelpers';
import { NerdHerderToolTipButton, NerdHerderToolTipIcon } from './NerdHerderToolTip';
import { NerdHerderLeaguePostSnippet } from './NerdHerderMessageCards';
import { NerdHerderVerticalScroller } from './NerdHerderScroller';
import { NerdHerderNavigate } from './NerdHerderNavigate';
import { GameListItem, ScheduledGameListItem, LeagueListItem, AvailableLeagueListItem, PollOptionListItem, UserListItem, BoardGameListItem, VenueListItem } from './NerdHerderListItems';
import { NerdHerderFontIcon, NerdHerderMapIcon, NerdHerderFavoriteIcon } from './NerdHerderFontIcon';
import { ModalErrorBoundary } from './NerdHerderErrorBoundary';
import { NerdHerderDropzoneImageUploader, NerdHerderDropzoneFileUploader } from './NerdHerderDropzone';
import { IntegratedListSelector } from './NerdHerderIntegratedListSelector';
import { FormErrorText, getFormErrors, setErrorState, clearErrorState, FormTextInputLimit, FormTypeahead, TripleDeleteButton, EmailLink, LinkifyText, FormControlSearch, FormControlSubmit } from './NerdHerderFormHelpers';
import { Required } from './NerdHerderBadge';
import { NerdHerderUpdateButton } from './NerdHerderUpdateButton';
import { Truncate } from './NerdHerderTruncate';
import { NerdHerderQrCode, NerdHerderSimpleQrCode } from './NerdHerderQrCode';
import { generateRandomSelectorJsx } from './NerdHerderRandomSelectorCard';
import { LongshanksId } from './NerdHerderLongshanks';


// this overrides the back button to instead cancel a modal - but only once...
function onBackCancelModal(onCancelModal) {
    // don't let modals nest the blank entries in history
    if (!window.onpopstate) {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = (event)=>{
            window.onpopstate = undefined;
            onCancelModal();
        };
    }
}

// this undoes the back button cancel function above by undefining the handler then going back past the empty history entry
function undoOnBackCancelModal() {
    if (window.onpopstate) {
        window.onpopstate = undefined;
        window.history.go(-1);
    }
}

export class NerdHerderModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing prop.onAccept');
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>{this.props.cancelButtonText || 'Cancel'}</Button>
                        <Button variant="primary" onClick={()=>{undoOnBackCancelModal(); this.props.onAccept()}}>{this.props.acceptButtonText || 'Ok'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderMessageModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        if (this.props.autoCancelDuration) {
            setTimeout(()=>this.autoCancel(), this.props.autoCancelDuration);
        }
    }

    autoCancel() {
        undoOnBackCancelModal();
        this.props.onCancel();
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'A Message'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.props.message}</p>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>{this.props.buttonText || 'Ok'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderConfirmModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing prop.onAccept');
        if (typeof this.props.message === 'undefined') console.error('missing prop.message');
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Confirm?'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.props.message}</p>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {this.props.onCancel()}}>{this.props.cancelButtonText || 'Cancel'}</Button>
                        <Button variant="primary" onClick={() => {this.props.onAccept()}}>{this.props.acceptButtonText || 'Confirm'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderLoadingModal extends React.Component {
    render() {
        let spinnerVariant = "primary";
        if (this.props.errorFeedback) {
            spinnerVariant = "danger";
        }
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} centered size="sm">
                    <Modal.Header>
                        <Modal.Title>{this.props.title || 'Loading...'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        {this.props.errorFeedback && 
                        <Alert variant="danger">{this.props.errorFeedback}</Alert>}
                        {this.props.userFeedback && 
                        <Alert variant="primary">{this.props.userFeedback}</Alert>}
                        {this.props.children}
                        <Spinner animation="border" role="status" variant={spinnerVariant} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderErrorModal extends React.Component {
    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} size="sm">
                    <Modal.Header>
                        <Modal.Title>{this.props.title || 'Error'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <Alert variant="danger">{this.props.errorFeedback || "An error was encountered"}</Alert>
                        {this.props.children}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderPleaseWaitModal extends React.Component {
    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} size="sm">
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.title || 'Please Wait'}
                        </Modal.Title>
                        <Spinner className='float-end' animation='border' role='status' variant='primary'/>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <Alert variant="warning">{this.props.userFeedback || "Please wait for a background operation to complete"}</Alert>
                        {this.props.children}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderAboutModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered scrollable={true} show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>About NerdHerder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <b>NerdHerder</b>
                        <div className='text-muted'>
                            <p>NerdHerder is a product of frustration. In mid 2021 I volunteered to run a Marvel Crisis Protocol league to grow the local community in cooperation with my FLGS.
                            Scores didn't really matter, and the league was instead about reaching out to new players, getting people to the shop to throw dice, and to get the hobby side going.
                            I tried TableTop TO, Best Coast Pairings, and Longshanks - but they were all about the competitive scene, tournaments & rankings, etc. They didn't really work for what I was trying to do.</p>
                            <p>I tried other methods. For a while the league ran straight out of Facebook messenger - but generally our group was moving away from Facebook. Discord wasn't really a good fit either.
                            So I taught myself HTML & SQL and whipped up a website straight out of the early 2000s called 'League'. It allowed players to report games, upload pictures of their minis and would show a read-only copy of a scores spreadsheet. 'League' evolved into NerdHerder, and NerdHerder continues to evolve. It will probably forever be a work in progress.</p>
                            <p>Send feature requests, suggestions, bugs, etc. to <EmailLink/></p>
                        </div>
                        <b>Isaac a.k.a. ikedasquid</b>
                        <div className='text-muted'>
                            <p>I've been playing board games, war games and RPGs of one kind or another since the mid 90's. Lately I've been focused on Marvel Crisis Protocol and Star Wars Legion. I've also played Warmahoards (Mk 2 & 3), Infinity, and X-Wing 1.0.</p>
                            <p>Heroquest was my first introduction to miniatures. Back in 'the day' I also played Battletech (3055) and Blood Bowl.</p>
                            <p>I've also played a handful of RPGs - although none recently: D&D 3E and 'Red Box', Shadowrun 2E, Rifts...some of my best memories in junior high come from weekends playing TMNT & Other Strangeness.</p>
                            <p>I try to hit up either GenCon or Adepticon every year. If you have had your picture taken with a slightly-balder-than-Chris-Pratt Starlord that was probably me!</p> 
                            <p>I am from the US midwest, however I've lived on both coasts here and spent a few years living in Germany.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderReportBugModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel'); 

        this.to_user_id = 1;
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            messageText: '',
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    determinePrivateChannelName(userId1, userId2) {
        let firstId = userId1;
        let secondId = userId2;
        if (userId1 > userId2) {
            firstId = userId2;
            secondId = userId1;
        }
        
        let baseName = 'chat-user';
        return `${baseName}-${firstId}-${secondId}`;
    }

    onSend() {
        this.setState({updating: true});

        let extraDetails = `username: ${this.props.localUser.username} (id=${this.props.localUser.id})\n`;
        extraDetails += `URL: ${window.location.href}\n`;
        extraDetails += `User Agent: ${navigator.userAgent}\n`;
        // eslint-disable-next-line no-restricted-globals
        extraDetails += `Screen (wxh): ${screen.width}x${screen.height}\n`;
        extraDetails += `Cookies Enabled: ${navigator.cookieEnabled}`;
        let cookieList = document.cookie.split(';');
        for (const cookie of cookieList) {
            extraDetails += `  Cookie: ${cookie}\n`;
        }

        // basically giving the autogenerated part 2000 chars and the user 2000 chars for a message
        if (extraDetails.length > 2000) extraDetails = extraDetails.slice(0, 2000);

        const postData = {type: 'chat',
                            to_user_id: this.to_user_id,
                            from_user_id: this.props.localUser.id,
                            channel: this.determinePrivateChannelName(this.to_user_id, this.props.localUser.id),
                            text: `Bug Report:\n${extraDetails}\n${this.state.messageText}`,
                            subject: 'bug report',
                            parent_id: null,
                            league_id: null,
                            event_id: null,
                            filenames: null,
                            read: false,
                            state: 'sent'};
        this.restPubSub.post('message', null, postData);
        undoOnBackCancelModal();
        this.props.onCancel();
    }

    handleTextInputChange(event) {
        this.setState({messageText: event.target.value});
    }

    render() {

        let maxTextLength = 2000;

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal backdrop='static' centered show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bug Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Text className="text-muted">Explain as best you can what happened (or didn't happen) and what you were trying to do when the problem occurred. Alternatively you can send an email to <EmailLink defaultSubject='Bug Report'/></Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <div style={{position: 'relative'}}>
                                    <Form.Control as="textarea" rows={10} onChange={(e)=>this.handleTextInputChange(e)} placeholder={'Bug details...'} value={this.state.messageText} maxLength={maxTextLength} required/>
                                    <FormTextInputLimit current={this.state.messageText.length} max={maxTextLength}/>
                                </div>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>{this.props.cancelButtonText || 'Cancel'}</Button>
                        <Button variant="primary" onClick={()=>{this.onSend()}}>{this.props.acceptButtonText || 'Send Report'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderSelectAccountTypeModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.usernameCheckTimeout = null;

        this.state = {
            navigateTo: null,
            googleDivWidth: null,
        }

        this.country = null;
        this.zipcode = null;
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    setGoogleDivWidth(element) {
        if (element) {
            let width = element.offsetWidth;
            if (width > 400) width = 400;
            if (this.state.googleDivWidth !== width) {
                this.setState({googleDivWidth: width});
            }
        }
    }

    handleGoogleLoginResponse(success, response) {
        if (success) {
            this.props.onAccept(response.credential);
        } else {
            console.log('got failure google response');
            console.log(response);
        }
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Welcome!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <div id='google-login' ref={(e)=>this.setGoogleDivWidth(e)}>
                                    The easist and fastest way to create an account on NerdHerder is through Google:
                                    <ul>
                                        <li><small>Your account is 'passwordless' - nothing to remember!</small></li>
                                        <li><small>You will not need to verify your email address</small></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col xs='auto'>
                                <GoogleLogin onSuccess={(r)=>this.handleGoogleLoginResponse(true, r)}
                                             onError={(r)=>this.handleGoogleLoginResponse(false, r)}
                                             width={this.state.googleDivWidth}
                                             size='large'
                                             context='signup'/>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col xs={12}>
                                Of course, you can create an account without Google:
                            </Col>
                        </Row>
                        <Row className='justify-content-center my-3'>
                            <Col xs='auto'>
                                <div className="d-grid gap-2" style={{width: this.state.googleDivWidth}}>
                                    <Button variant='primary' onClick={()=>this.setState({navigateTo: '/app/newuser'})}>Standard Sign Up</Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderCreateGoogleAccountModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.googleJwt === 'undefined') console.error('missing props.googleJwt');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.usernameCheckTimeout = null;

        this.state = {
            navigateTo: null,
            updating: false,
            ipAddress: null,
            ipZipcode: null,
            ipCountry: null,
            countryList: [],

            formUsername: '',
            formUsernameResultFor: null,
            formUsernameAvailable: null,
            formUsernameMessage: null,
            formPhone: '',
            formUnder13: true,
            formAcceptTerms: false,

            formErrors: {},
            errorFeedback: null,
            formValidated: false,
        }

        this.googleJwtDecoded = jwt_decode(this.props.googleJwt);
        this.country = null;
        this.zipcode = null;
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        let sub = this.restPubSub.subscribeNoRefresh('country-list', null, (d, k)=>this.updateCountryList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('ip-location', null, (d, k)=>this.updateIpLocation(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    checkFreeUsername() {
        this.restApi.genericGetEndpointData('username-available', null, {'username': this.state.formUsername})
        .then(response => {
            this.updateAvailableUsername(response.data);
        }).catch(error => {
            console.error(error);
        });
    }

    updateAvailableUsername(response) {
        let isAvailable = null;
        if (response.result === 'available') {
            isAvailable = true;
        } else {
            isAvailable = false;
        }
        this.setState({formUsernameResultFor: response.username, formUsernameAvailable: isAvailable, formUsernameMessage: response.message});
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateCountryList(response, key) {
        console.debug('got country list');
        this.setState({countryList: response});
    }

    updateIpLocation(response, key) {
        console.debug(`got country ${response.country}`);
        let country = response.details.country_name || '';
        let zipcode = response.details.postal || '';
        this.setState({ipAddress: response.ip, ipCountry: country, ipZipcode: zipcode});
    }

    handleUsernameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('username', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('username', {...this.state.formErrors}, 'this username is too short');
        } else {
            if (this.usernameCheckTimeout !== null) clearTimeout(this.usernameCheckTimeout);
            setTimeout(()=>this.checkFreeUsername(), 500);
        }
        this.setState({formUsernameAvailable: null, formUsername: value, formErrors: errorState});
    }

    handlePhoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('phone', {...this.state.formErrors});
        if (value.length < 8) {
            errorState = setErrorState('phone', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formPhone: value, formErrors: errorState});
    }

    handleUnder13Change(event) {
        let value = event.target.checked;
        this.setState({formUnder13: !value});
    }

    handleAcceptTermsChange(event) {
        let value = event.target.checked;
        this.setState({formAcceptTerms: value});
    }

    onSubmit(event) {
        const form = event.currentTarget;
        let valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        let generatedName = null;
        if (this.googleJwtDecoded.hasOwnProperty('given_name')) {
            generatedName = this.googleJwtDecoded.given_name;
            if (this.googleJwtDecoded.hasOwnProperty('family_name')) {
                let lastInitial = this.googleJwtDecoded.family_name;
                if (lastInitial.length >= 1) {
                    lastInitial = lastInitial[0];
                    generatedName = `${generatedName} ${lastInitial}`;
                }
            }
        } else if (this.googleJwtDecoded.hasOwnProperty('name')) {
            generatedName = this.googleJwtDecoded.name;
        } else {
            valid = false;
            this.setState({errorFeedback: <Alert variant='danger'>Unable to determine real-life name from Google</Alert>});
        }

        this.zipcode = this.state.ipZipcode;
        this.country = this.state.ipCountry;
        if (!this.state.countryList.includes(this.country)) {
            this.country = null;
            this.zipcode = null;
        }

        if (valid) {
            this.setState({formValidated: true, updating: true});
            const postData = {
                username: this.state.formUsername.trimEnd(),
                password: 'generate-password',
                name: generatedName,
                phone: this.state.formPhone.trimEnd(),
                email: this.googleJwtDecoded.email,
                zipcode: this.zipcode,
                discord_id: null,
                longshanks_id: null,
                bandai_id: null,
                konami_id: null,
                wizards_id: null,
                pokemon_id: null,
                country: this.country,
                web_push_enabled: false,
                online_interest: false,
                latitude: null,
                longitude: null,
                timezone: null,
                urls: '',
                under_13: this.state.formUnder13,
                google_jwt: this.props.googleJwt,
            }
            this.restApi.genericPostEndpointData('new-user', null, postData)
            .then(response => {
                let loginToken = response.data.login_token;
                let userId = response.data.self.id;
                setCookie('LoginToken', loginToken, 358);
                setLocal('LoginToken', loginToken);
                setLocal('UserId', userId);
                delLocal('FirebaseToken');
                setCookie('RememberMe', true, 358);
                setTimeout(()=>this.setState({navigateTo: `/app/main`, updating: false}), 200);
            }).catch(error => {
                let errorMessage = getFailureMessage(error);
                this.setState({updating: false, errorFeedback: <Alert variant='danger'>{errorMessage}</Alert>});
                this.formUpdateError(error, null);
            });
        }
    }

    render() {
        if (this.state.countryList === null || this.state.countryList.length === 0) return(null);
        if (this.state.ipCountry === null || this.state.ipZipcode === null) return(null);
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);
        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // if any item has an error, is empty, or the passwords don't match, then the submit is disabled
        let disableSubmitButton = false;
        if (this.state.formUsernameAvailable === null || this.state.formUsernameAvailable === false ||
            this.state.formUsername.length < 6 ||
            this.state.formPhone.length < 8 ||
            this.state.formAcceptTerms === false ||
            hasFormErrors === true ||
            this.state.updating) {
            disableSubmitButton = true;
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>New User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorFeedback}
                        <Form id='new-user-form' onSubmit={(e)=>this.onSubmit(e)}>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Username<Required/></Form.Label>
                                <Form.Control id='username' name='username' type="text" disabled={this.state.updating} onChange={(event)=>this.handleUsernameChange(event)} autoComplete='username' value={this.state.formUsername} minLength={6} maxLength={20} required/>
                                <FormErrorText errorId='username' errorState={this.state.formErrors}/>
                                {this.state.formUsernameAvailable === null &&
                                <Form.Text className='text-muted'>This is your main identifier visible to others on the site.</Form.Text>}
                                {this.state.formUsernameAvailable === true && this.state.formUsername.length >= 6 && this.state.formUsernameResultFor === this.state.formUsername &&
                                <Form.Text className='text-primary'>{this.state.formUsernameMessage}</Form.Text>}
                                {this.state.formUsernameAvailable === false && this.state.formUsername.length >= 6 && this.state.formUsernameResultFor === this.state.formUsername &&
                                <Form.Text className='text-danger'>{this.state.formUsernameMessage}</Form.Text>}
                                <div>
                                    <Form.Text className='text-muted'><b>Retailers:</b> Create an account for yourself, not for your store or venue.</Form.Text>
                                </div>
                            </Form.Group>

                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Phone Number<Required/></Form.Label>
                                <Form.Control type="tel" disabled={this.state.updating} onChange={(event)=>this.handlePhoneChange(event)} autoComplete='tel' value={this.state.formPhone} minLength={8} maxLength={20} required/>
                                <FormErrorText errorId='phone' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>Only your contacts can see your phone number. Feel free to use a bogus number if desired.</Form.Text>
                            </Form.Group>

                            <Form.Group className="form-outline mb-3">
                                <Form.Check id="under_13" name="under_13" label="I am 13 years old or older" disabled={this.state.updating} onChange={(event)=>this.handleUnder13Change(event)} autoComplete='off' checked={!this.state.formUnder13}/>
                                <Form.Text className='text-muted'>Children are welcome on NerdHerder, but require parent or guardian permission. Don't use a parent's email account when registering children.</Form.Text>
                            </Form.Group>

                            <Form.Group className="form-outline mb-3">
                                <Form.Check id="accept_tandc" name="accept_tandc" label={<span>I accept NerdHerder's <a href='/terms' target='_blank'>Terms & Conditions</a> and other Policies</span>} disabled={this.state.updating} onChange={(event)=>this.handleAcceptTermsChange(event)} autoComplete='off' checked={this.state.acceptTerms}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.onCancel()}>Cancel</Button>
                        <Button variant="primary" type='submit' form='new-user-form' disabled={disableSubmitButton}>Sign Up!</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderUserProfileModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing prop.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');
        if (typeof this.props.userId === 'undefined' && typeof this.props.user === 'undefined') console.error('missing prop.userId or prop.user');

        let userData = null;
        let userId = null;
        if (typeof this.props.user !== 'undefined') {
            userData = this.props.user;
            userId = userData.id;
        } else {
            userId = this.props.userId;
        }

        // look for the special 'hide sensitive data' cookie, if its there we'll hide email and phone
        this.hideSensitiveData = getCookieParseBool('hide-sensitive-data', false);
        if (this.hideSensitiveData !== true) this.hideSensitiveData = false;

        this.state = {
            navigateTo: null,
            userId: userId,
            user: userData,
            userContact: null,
            userStatsData: null,
            userRankingData: null,
            updating: true,
            showMessageModal: false,
            timezoneList: null,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        let sub = null;
        if (this.state.user === null) {
            sub = this.restPubSub.subscribe('user', this.state.userId, (d, k)=>{this.updateUser(d, k)});
            this.restPubSubPool.add(sub);
        }

        sub = this.restPubSub.subscribe('self-contact', this.state.userId, (d, k)=>{this.updateUserContact(d, k)});
        this.restPubSubPool.add(sub);

        sub = this.restPubSub.subscribe('user-game-stats', this.state.userId, (d, k)=>{this.updateUserGameStats(d, k)});
        this.restPubSubPool.add(sub);

        sub = this.restPubSub.subscribe('user-global-ranking', this.state.userId, (d, k)=>{this.updateUserGameRanking(d, k)});
        this.restPubSubPool.add(sub);

        sub = this.restPubSub.subscribeNoRefresh('timezone-list', null, (d, k)=>{this.updateTimezoneList(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateUser(userData, key) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState({userId: newUser.id, user: newUser});
    }

    updateUserContact(userContactData, key) {
        this.setState({userContact: userContactData, updating: false});
        this.restPubSub.refresh('self');
    }

    updateUserGameStats(userStatsData, key) {
        this.setState({userStatsData: userStatsData});
    }

    updateUserGameRanking(userRankingData, key) {
        this.setState({userRankingData: userRankingData});
    }

    updateTimezoneList(response, key) {
        this.setState({timezoneList: response});
    }

    onContact(operation) {
        switch(operation) {
            case 'add':
                this.setState({updating: true});
                const prevState = this.props.localUser.getContactState(this.state.userId);
                if (prevState === 'soft') {
                    const patchData = {source_user_id: this.props.localUser.id, state: 'requested'};
                    this.restPubSub.patch('self-contact', this.state.userId, patchData);
                } else {
                    const postData = {
                        user1_id: this.state.userId,
                        user2_id: this.props.localUser.id,
                        source_user_id: this.props.localUser.id,
                        state: 'requested'
                    };
                    this.restPubSub.post('self-contact', this.state.userId, postData);
                }
                break;
            
            case 'accept':
                console.debug('accept contact button clicked');
                this.setState({updating: true});
                this.restPubSub.patch('self-contact', this.state.userId, {state: 'friends'});
                break;
            
            case 'reject':
                console.debug('reject contact button clicked');
                this.setState({updating: true});
                this.restPubSub.delete('self-contact', this.state.userId);
                break;
            
            default:
                console.error('hit unexpected default case in switch');
        }
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);
        if (this.state.user === null) return(<NerdHerderLoadingModal/>);

        const userData = this.state.user;
        const localUserData = this.props.localUser;
        const imageHref = userData.getImageUrl();
        let contactButton = null;
        let messageButton = null;

        let hasExternalIds = false;
        if (userData.wizards_id || userData.pokemon_id || userData.longshanks_id || userData.bandai_id || userData.konami_id) hasExternalIds = true;

        let joinedDateStamp = 'Not set';
        if (userData.created !== null) {
            joinedDateStamp = new Date(userData.created);
            joinedDateStamp = generateDateString(joinedDateStamp);
        }
        let loginDateStamp = 'Never logged in';
        if (userData.last_login !== null) {
            loginDateStamp = new Date(userData.last_login);
            loginDateStamp = generateDateString(loginDateStamp);
        }

        let contactState = 'none';
        let source_user_id = null;
        if (this.state.userContact) {
            contactState = this.state.userContact.state;
            source_user_id = this.state.userContact.source_user_id;
        }

        let contactStatusMessage = null;
        if (contactState === 'friends') {
            // eslint-disable-next-line eqeqeq
            if (userData.id == localUserData.id) {
                contactStatusMessage = <Alert className="my-2" variant='primary'>You know {userData.username}...right?</Alert>
            } else {
                contactStatusMessage = <Alert className="my-2" variant='primary'>{userData.username} is your contact</Alert>
                contactButton = <Button variant="danger" onClick={()=>{this.onContact('reject')}} disabled={this.state.updating}>Break Contact</Button>
            }
        } else if (contactState === 'requested') {
            // eslint-disable-next-line eqeqeq
            if (source_user_id == userData.id) {
                contactStatusMessage = <Alert className="my-2" variant='warning'>{userData.username} has sent a contact request</Alert>
                contactButton = <Button variant="primary" onClick={()=>{this.onContact('accept')}} disabled={this.state.updating}>Accept Contact</Button>
            } else {
                contactStatusMessage = <Alert className="my-2" variant='warning'>You have sent a contact request to {userData.username}</Alert>
                contactButton = <Button variant="primary" disabled>Contact</Button>
            }
        }
        else {
            contactStatusMessage = <Alert className="my-2" variant='danger'>{userData.username} is not on your contacts list</Alert>
            contactButton = <Button variant="primary" onClick={()=>{this.onContact('add')}} disabled={this.state.updating}>Add Contact</Button>
        }

        // no messaging yourself! no breaking contact with yourself!
        messageButton = <Button variant="primary" onClick={()=>{this.setState({showMessageModal: true})}} disabled={this.state.updating}>Message</Button>
        if (this.state.userId === this.props.localUser.id) {
            messageButton = null;
            contactButton = null;
        }

        if (this.state.showMessageModal) {
            return (
                <NerdHerderNewMessageModal user={this.state.user}
                                           localUser={this.props.localUser} 
                                           onCancel={()=>this.setState({showMessageModal: false})}/>
            );
        }

        // generate the table for game stats
        let gameStatsTables = [];
        let totalPlayedLeagues = 0;
        let totalManagedLeagues = 0;
        let totalGamesPlayed = 0;
        if (this.state.userStatsData) {
            for (const [topicId, topicStats] of Object.entries(this.state.userStatsData)) {
                totalPlayedLeagues += topicStats.player;
                totalManagedLeagues += topicStats.manager;
                let casualGamesPlayed = topicStats.casual.won + topicStats.casual.tie + topicStats.casual.lost;
                let eventGamesPlayed = topicStats.event.won + topicStats.event.tie + topicStats.event.lost;
                let tournamentGamesPlayed = topicStats.tournament.won + topicStats.tournament.tie + topicStats.tournament.lost;
                let totalTopicGamesPlayed = casualGamesPlayed + eventGamesPlayed + tournamentGamesPlayed;
                totalGamesPlayed += totalTopicGamesPlayed;
            
                if (topicStats.player > 0 || topicStats.manager > 0 || totalTopicGamesPlayed > 0) {
                    const gameStatsInnerTable =
                        <div key={`stats-topic-${topicId}`}>
                            <big><b>{topicStats.topic_name} ({topicId}) Statistics</b></big>
                            <Table size='sm' borderless>
                                <tbody>
                                    <tr><td>Leagues Played</td><td>{topicStats.player}</td></tr>
                                    <tr><td>Leagues Organized</td><td>{topicStats.manager}</td></tr>
                                    <tr><td>Total Games</td><td>{totalTopicGamesPlayed}</td></tr>
                                </tbody>
                            </Table>
                            {totalTopicGamesPlayed !== 0 && 
                            <Table striped>
                                <thead>
                                    <tr><th>Game Category</th><th>Record (W/T/L)</th></tr>
                                </thead>
                                <tbody>
                                    {casualGamesPlayed !== 0 &&
                                    <tr><td>Casual</td><td>{`${topicStats.casual.won} / ${topicStats.casual.tie} / ${topicStats.casual.lost}`}</td></tr>}
                                    {eventGamesPlayed !== 0 &&
                                    <tr><td>Event</td><td>{`${topicStats.event.won} / ${topicStats.event.tie} / ${topicStats.event.lost}`}</td></tr>}
                                    {tournamentGamesPlayed !== 0 &&
                                    <tr><td>Tournament</td><td>{`${topicStats.tournament.won} / ${topicStats.tournament.tie} / ${topicStats.tournament.lost}`}</td></tr>}
                                </tbody>
                            </Table>}
                        </div>
                    gameStatsTables.push(gameStatsInnerTable);
                }
            }
        }

        // generate the table for global ranking
        let globalRankingTable = null;
        if (this.state.userRankingData) {
            const tableRows = [];
            for (const [topicId, scores] of Object.entries(this.state.userRankingData)) {
                const finalScore = Math.floor(scores.fs);
                const rank = scores.place;
                if (finalScore !== 0) {
                    const row = <tr key={topicId}><td>{topicId}</td><td>{finalScore}</td><td>{rank}</td></tr>
                    tableRows.push(row);
                }
            }
            
            if (tableRows.length !== 0) {
                globalRankingTable =
                    <Table striped>
                        <thead>
                            <tr><th>Game</th><th>Score</th><th>Rank</th></tr>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </Table>
            }
        }

        let timezoneName = null;
        let utcOffsetHours = null;
        let localUserUtcOffsetHours = null;
        let utcOffsetString = null;
        let userHoursOffset = null;
        let userHoursOffsetMessage = null;
        let sameTimezoneAsLocalUser = false;
        if (this.state.user.timezone === this.props.localUser.timezone) {
            sameTimezoneAsLocalUser = true;
        }
        if (this.state.timezoneList !== null && !sameTimezoneAsLocalUser) {
            for (const timezone of this.state.timezoneList) {
                if (this.state.user.timezone === timezone.timezone_name) {
                    timezoneName = timezone.timezone_name;
                    if (timezone.localized_name) timezoneName += ` ${timezone.localized_name}`;
                    utcOffsetHours = timezone.utc_offset_hours;
                    utcOffsetString = timezone.utc_offset_string;
                }
                if (this.props.localUser.timezone === timezone.timezone_name) {
                    localUserUtcOffsetHours = timezone.utc_offset_hours;
                }
            }
        }
        if (utcOffsetHours !== null && localUserUtcOffsetHours !== null) {
            userHoursOffset = utcOffsetHours - localUserUtcOffsetHours;
            if (userHoursOffset === 1) userHoursOffsetMessage = `They are an hour ahead of you`;
            else if (userHoursOffset === -1) userHoursOffsetMessage = `They are an hour behind you`;
            else if (userHoursOffset > 0) userHoursOffsetMessage = `They are ${userHoursOffset} hours ahead of you`;
            else if (userHoursOffset < 0) userHoursOffsetMessage = `They are ${userHoursOffset * -1} hours behind you`;
            else userHoursOffsetMessage = `They are at the same time as you`;
        }

        let longshanks_a = 'None';
        let pokemon_a = 'None';
        let bandai_a = 'None';
        let wizards_a = 'None';
        let konami_a = 'None';
        if (hasExternalIds) {
            wizards_a = userData.wizards_id ? userData.wizards_id : 'None';
            pokemon_a = userData.pokemon_id ? userData.pokemon_id : 'None';
            bandai_a = userData.bandai_id ? userData.bandai_id : 'None';
            konami_a = userData.konami_id ? userData.konami_id : 'None';
            if (userData.longshanks_id) {
                longshanks_a = <LongshanksId id={userData.longshanks_id}/>
            }
            if (userData.wizards_id) {
                wizards_a = <a target='_blank' rel='noreferrer' href={`https://eventlink.wizards.com/`}>{userData.wizards_id}</a>
            }
            if (userData.pokemon_id) {
                pokemon_a = <a target='_blank' rel='noreferrer' href={`https://www.pokemon.com/`}>{userData.pokemon_id}</a>
            }
            if (userData.bandai_id) {
                bandai_a = <a target='_blank' rel='noreferrer' href={`https://www.bandai-tcg-plus.com/`}>{userData.bandai_id}</a>
            }
            if (userData.konami_id) {
                konami_a = <a target='_blank' rel='noreferrer' href={`https://cardgame-network.konami.net/`}>{userData.konami_id}</a>
            }
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{userData.username}'s Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <Image className="rounded-circle" height={150} width={150} src={imageHref} alt='user profile image'/>
                        </div>
                        {contactStatusMessage}
                        <Table size='sm'>
                            <tbody>
                                <tr><td>Username</td><td>{ userData.username }</td></tr>
                                <tr><td>Discord</td><td>{ userData.discord_id || 'Not provided'}</td></tr>
                                <tr><td>Name</td><td>{ userData.short_name || 'Hidden' }</td></tr>
                                {!this.hideSensitiveData &&
                                <tr><td>Phone</td><td>{ userData.phone || 'Hidden' }</td></tr>}
                                {this.hideSensitiveData &&
                                <tr><td>Phone</td><td>{ '(319)555-2368' }</td></tr>}
                                <tr><td>Country</td><td>{ userData.country || 'Hidden' }</td></tr>
                                {sameTimezoneAsLocalUser &&
                                <tr><td>Timezone</td><td>{this.props.localUser.timezone} (same as you)</td></tr>}
                                {!sameTimezoneAsLocalUser && timezoneName &&
                                <tr><td>Timezone</td><td>{timezoneName} ({utcOffsetString})<br/>{userHoursOffsetMessage}</td></tr>}
                                {totalManagedLeagues !== 0 &&
                                <tr><td>Leagues Organized</td><td>{totalManagedLeagues}</td></tr>}
                                {totalPlayedLeagues !== 0 &&
                                <tr><td>Leagues Played</td><td>{totalPlayedLeagues}</td></tr>}
                                {totalGamesPlayed !== 0 &&
                                <tr><td>Games Played</td><td>{totalGamesPlayed}</td></tr>}
                                <tr><td>Joined</td><td>{ joinedDateStamp }</td></tr>
                                <tr><td>Last Login</td><td>{ loginDateStamp }</td></tr>
                            </tbody>
                        </Table>
                        {userData.shared_league_id &&
                        <div>
                            <hr className='my-1'/>
                            <small className='text-muted'>
                                <i>{`*additional information displayed because you share a running league with ${userData.username}`}</i>
                            </small>
                        </div>}
                        {hasExternalIds &&
                        <div>
                            <big><b>External Site IDs</b></big>
                            <Table size='sm'>
                                <tbody>
                                    <tr><td>Wizards Event Link</td><td>{wizards_a}</td></tr>
                                    <tr><td>Pokemon</td><td>{pokemon_a}</td></tr>
                                    <tr><td>Bandai TCG+</td><td>{bandai_a}</td></tr>
                                    <tr><td>Konami</td><td>{konami_a}</td></tr>
                                    <tr><td>Longshanks</td><td>{longshanks_a}</td></tr>
                                </tbody>
                            </Table>
                        </div>}
                        {gameStatsTables.length !== 0 &&
                        <div className='mt-3'>
                            {gameStatsTables}
                        </div>}
                        {globalRankingTable &&
                        <div className='mt-3'>
                            <big><b>Global Rankings</b></big>
                            {globalRankingTable}
                        </div>}
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        {messageButton}
                        {contactButton}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderEditPostModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // simpler tracking if this is intended to be a 'new' post or an 'edit' post
        let isNewPost = false;
        let messageId = this.props.messageId;
        if (typeof this.props.messageId === 'undefined' || this.props.messageId === null) {
            isNewPost = true;
            messageId = null;
        }

        this.state = {
            updating: false,
            onUpdateSetTopPost: false,
            onUpdateCancel: false,

            isNewPost: isNewPost,
            messageId: messageId,
            message: null,
            postText: '',
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        let sub = null;
        if (this.state.isNewPost === false) {
            sub = this.restPubSub.subscribe('message', this.state.messageId, (d, k)=>{this.updateMessage(d, k)});
            this.restPubSubPool.add(sub);
            this.setState({updating: true});
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateMessage(messageData, key) {
        const newMessage = NerdHerderDataModelFactory('message', messageData);
        this.setState({messageId: newMessage.id,
                       message: newMessage,
                       postText: newMessage.text,
                       updating: false});
        
        if (this.state.onUpdateCancel) {
            undoOnBackCancelModal(); 
            this.props.onCancel();
        }

        if (this.state.onUpdateSetTopPost) {
            if (this.props.event) {
                this.restPubSub.patch('event', this.props.event.id, {top_post_id: newMessage.id});
            } else {
                this.restPubSub.patch('league', this.props.league.id, {top_post_id: newMessage.id});
            }
            this.setState({updating: true, onUpdateSetTopPost: false, onUpdateCancel: true});
        }
    }

    onPost() {
        if (this.props.setTopPost === true) {
            this.setState({updating: true, onUpdateSetTopPost: true});
        } else {
            this.setState({updating: true, onUpdateCancel: true});
        }
        
        let subject = 'league post';
        if (this.props.event) {
            subject = 'event post'
        }

        let eventId = null
        if (this.props.event) eventId = this.props.event.id;

        if (this.state.isNewPost) {
            const postData = {type: 'text',
                              to_user_id: null,
                              from_user_id: this.props.localUser.id,
                              text: this.state.postText.trimEnd(),
                              subject: subject,
                              parent_id: null,
                              league_id: this.props.league.id,
                              event_id: eventId,
                              filenames: null,
                              read: false,
                              state: 'sent'};
            this.restPubSub.postAndSubscribe('message', 'id', postData, (d, k)=>{this.updateMessage(d, k)});
        } else {
            this.restPubSub.patch('message', this.state.messageId, {text: this.state.postText.trimEnd()});
        }
    }

    handlePostInputChange(event) {
        this.setState({postText: event.target.value});
    }

    render() {
        if (!this.state.isNewPost && this.state.message == null) return(<NerdHerderLoadingModal/>);

        const maxLength = 4096;
        const trimmedText = this.state.postText.trimEnd();

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        {this.state.isNewPost &&
                        <Modal.Title>Create Post</Modal.Title>}
                        {!this.state.isNewPost &&
                        <Modal.Title>Edit Post</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        {!this.props.event &&
                        <Row className='mb-3'>
                            <Col xs={2}>
                                    <Image className="img-fluid rounded text-center" src={this.props.league.getImageUrl()}/>
                            </Col>
                            <Col>
                                <small className='text-muted'>You are posting in league</small>
                                <h5>{this.props.league.name}</h5>
                            </Col>
                        </Row>}
                        {this.props.event &&
                        <Row className='mb-3'>
                            <Col xs={2}>
                                    <Image className="img-fluid rounded text-center" src={this.props.league.getImageUrl()}/>
                            </Col>
                            <Col>
                                <small className='text-muted'>You are posting in event</small>
                                <h5>{this.props.event.name}</h5>
                            </Col>
                        </Row>}
                        <Form>
                            <Form.Group>
                                <div style={{position: 'relative'}}>
                                    <Form.Control as="textarea" rows={10} disabled={this.state.updating} onChange={(event)=>this.handlePostInputChange(event)} value={this.state.postText} minLength={1} maxLength={maxLength} required/>
                                    <FormTextInputLimit current={this.state.postText.length} max={maxLength}/>
                                </div>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>{this.onPost()}} disabled={this.state.updating || trimmedText.length < 4}>
                            {this.state.isNewPost &&
                            'Post'}
                            {!this.state.isNewPost &&
                            'Update'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderEditMessageModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.messageId === 'undefined') console.error('missing props.messageId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            onUpdateCancel: false,
            messageId: this.props.messageId,
            message: null,
            postText: '',
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        let sub = this.restPubSub.subscribe('message', this.state.messageId, (d, k)=>{this.updateMessage(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateMessage(messageData, key) {
        const newMessage = NerdHerderDataModelFactory('message', messageData);
        this.setState({messageId: newMessage.id,
                       message: newMessage,
                       postText: newMessage.text,
                       updating: false});
        
        if (this.state.onUpdateCancel) {
            this.doOnCancel();
        }
    }

    doOnCancel() {
        undoOnBackCancelModal(); 
        this.props.onCancel();
    }

    onSave() {
        this.setState({updating: true, onUpdateCancel: true});
        const trimmedText = this.state.postText.trimEnd();
        // messages with a chat channel need to go through the chat api, messages without a chat channel go through the message api
        if (this.state.message.channel === null || !this.state.message.channel.includes('chat')) {
            this.restPubSub.patch('message', this.state.messageId, {text: trimmedText});
        } else {
            // the api is slightly different for user or league chats
            const queryParams = {'message-id': this.state.message.id};
            if (this.state.message.channel.includes('chat-league')) {
                this.restApi.genericPatchEndpointData('league-chat', this.state.message.league_id, {text: trimmedText}, queryParams)
                .then((response)=>{
                    this.doOnCancel();
                })
                .catch((error)=>{
                    console.error('failed to edit league chat message');
                    console.error(error);
                    this.doOnCancel();
                });
            }
            else if (this.state.message.channel.includes('chat-user')) {
                this.restApi.genericPatchEndpointData('user-chat', this.state.message.channel, {text: trimmedText}, queryParams)
                .then((response)=>{
                    this.doOnCancel();
                })
                .catch((error)=>{
                    console.error('failed to edit user chat message');
                    console.error(error);
                    this.doOnCancel();
                });
            }
        }
    }

    handlePostInputChange(event) {
        this.setState({postText: event.target.value});
    }

    render() {
        if (this.state.message == null) return(<NerdHerderLoadingModal/>);

        const maxLength = this.props.maxLength || 512;
        const trimmedText = this.state.postText.trimEnd();

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Edit Message'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <div style={{position: 'relative'}}>
                                    <Form.Control as="textarea" rows={10} disabled={this.state.updating} onChange={(event)=>this.handlePostInputChange(event)} value={this.state.postText} minLength={1} maxLength={maxLength} required/>
                                    <FormTextInputLimit current={this.state.postText.length} max={maxLength}/>
                                </div>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>{this.onSave()}} disabled={this.state.updating || trimmedText.length === 0}>Update</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderNewMessageModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing prop.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');        

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // simpler tracking if this is intended to be a 'new' message or an 'edit' message
        let isNewMessage = false;
        let messageId = this.props.messageId;
        if (typeof this.props.messageId === 'undefined') {
            isNewMessage = true;
            messageId = null;
        }

        // it is possible to hand a userId or a user object
        let userId = null;
        let findUser = true;
        if (typeof this.props.user !== 'undefined') {
            userId = this.props.user.id;
            findUser = false;
        } else if (typeof this.props.userId !== 'undefined') {
            userId = this.props.userId;
            findUser = false;
        }

        this.state = {
            isNewMessage: isNewMessage,
            findUser: findUser,
            messageId: messageId,
            message: null,
            userId: userId,
            updating: false,
            messageText: '',
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        
        let sub = null;
        if (this.state.isNewMessage === false) {
            sub = this.restPubSub.subscribe('message', this.state.messageId, (d, k) => {this.updateMessage(d, k)});
            this.restPubSubPool.add(sub);
            this.setState({updating: true});
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateMessage(messageData, key) {
        const newMessage = NerdHerderDataModelFactory('message', messageData);
        this.setState({messageId: newMessage.id,
                       message: newMessage,
                       messageText: newMessage.text,
                       updating: false});
        if (this.state.user) this.setState({updating: false});
    }

    determinePrivateChannelName(userId1, userId2) {
        let firstId = userId1;
        let secondId = userId2;
        if (userId1 > userId2) {
            firstId = userId2;
            secondId = userId1;
        }
        
        let baseName = 'chat-user';
        return `${baseName}-${firstId}-${secondId}`;
    }

    onSend() {
        if (this.state.isNewMessage) {
            this.setState({updating: true});
            const postData = {type: 'chat',
                              to_user_id: this.state.userId,
                              from_user_id: this.props.localUser.id,
                              channel: this.determinePrivateChannelName(this.state.userId, this.props.localUser.id),
                              text: this.state.messageText,
                              subject: 'new message',
                              parent_id: null,
                              league_id: null,
                              event_id: null,
                              filenames: null,
                              read: false,
                              state: 'sent'};
            this.restPubSub.post('message', null, postData);
        } else {
            this.setState({updating: true});
            this.restPubSub.patch('message', this.state.messageId, {text: this.state.messageText.trimEnd()});
        }

        if (this.props.onSend) {
            undoOnBackCancelModal(); 
            this.props.onSend();
        }
        else {
            undoOnBackCancelModal();
            this.props.onCancel();
        }
    }

    handleChangeUsername(userDetails) {
        this.setState({userId: userDetails.id, user: null});
    }

    handleTextInputChange(event) {
        this.setState({messageText: event.target.value});
    }

    render() {
        if (!this.state.isNewMessage && this.state.message == null) return(<NerdHerderLoadingModal/>);

        const maxTextLength = 512;

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal backdrop='static' show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        {this.state.isNewMessage &&
                        <Modal.Title>Create Message</Modal.Title>}
                        {!this.state.isNewMessage &&
                        <Modal.Title>Edit Message</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {this.state.findUser &&
                            <Form.Group className="form-outline mb-2">
                                <FormTypeahead placeholder='Username' delay={300} endpoint='user' queryParams={{'username-similar': 'query'}} labelKey='username' onChange={(s)=>{this.handleChangeUsername(s)}} disabled={this.state.isSearching}/>
                            </Form.Group>}
                            <Form.Group className="form-outline mb-2">
                                <Form.Control as="textarea" rows={10} onChange={(event)=>this.handleTextInputChange(event)} placeholder={'Message'} value={this.state.messageText} maxLength={maxTextLength} required/>
                                <Form.Text className="text-muted float-end">
                                    <small>{`${this.state.messageText.length}/${maxTextLength}`}</small>
                                </Form.Text>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>{this.onSend()}} disabled={this.state.updating || this.state.messageText.length === 0}>
                            {this.state.isNewMessage &&
                            'Send'}
                            {!this.state.isNewMessage &&
                            'Update'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderSetTimerModal extends React.Component {
    constructor(props) {
        super(props);

        const duration = this.props.duration || 3600;
        const defaultHours = parseInt(duration / 3600);
        const defaultMinutes = parseInt((duration - (defaultHours * 3600))/60);

        this.state = {
            hours: defaultHours,
            minutes: defaultMinutes
        }
    }

    onHoursChange(event) {
        const newState = this.state;
        newState.hours = event.target.value;
        this.setState(newState);
    }

    onMinutesChange(event) {
        const newState = this.state;
        newState.minutes = event.target.value;
        this.setState(newState);
    }

    onAcceptInterceptor() {
        let duration = this.state.hours * 3600 + this.state.minutes * 60;
        this.props.onAccept(duration);
    }

    render() {
        // switch out the onAccept so we can add the time set in the modal
        let baseModalProps = {...this.props};
        baseModalProps.onAccept = () => this.onAcceptInterceptor();

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <NerdHerderModal {...baseModalProps}>
                    <Form>
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <Form.Group>
                                    <Form.Label>Hours</Form.Label>
                                    <Form.Select id="formHoursSelection" size="lg" aria-label="select hours" defaultValue={this.state.hours} onChange={(e) => this.onHoursChange(e)}>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group>
                                    <Form.Label>Minutes</Form.Label>
                                    <Form.Select id="formMinutesSelection" size="lg" aria-label="select minutes" defaultValue={this.state.minutes} onChange={(e) => this.onMinutesChange(e)}>
                                        <option value="0">00</option>
                                        <option value="1">01</option>
                                        <option value="2">02</option>
                                        <option value="3">03</option>
                                        <option value="4">04</option>
                                        <option value="5">05</option>
                                        <option value="6">06</option>
                                        <option value="7">07</option>
                                        <option value="8">08</option>
                                        <option value="9">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                        <option value="32">32</option>
                                        <option value="33">33</option>
                                        <option value="34">34</option>
                                        <option value="35">35</option>
                                        <option value="36">36</option>
                                        <option value="37">37</option>
                                        <option value="38">38</option>
                                        <option value="39">39</option>
                                        <option value="40">40</option>
                                        <option value="41">41</option>
                                        <option value="42">42</option>
                                        <option value="43">43</option>
                                        <option value="44">44</option>
                                        <option value="45">45</option>
                                        <option value="46">46</option>
                                        <option value="47">47</option>
                                        <option value="48">48</option>
                                        <option value="49">49</option>
                                        <option value="50">50</option>
                                        <option value="51">51</option>
                                        <option value="52">52</option>
                                        <option value="53">53</option>
                                        <option value="54">54</option>
                                        <option value="55">55</option>
                                        <option value="56">56</option>
                                        <option value="57">57</option>
                                        <option value="58">58</option>
                                        <option value="59">59</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </NerdHerderModal>
            </div>
        )
    }
}

export class NerdHerderPasswordModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');
        if (typeof this.props.userPassword !== 'undefined' && typeof this.props.username === 'undefined') console.error('must include props.username when props.userPassword is set');

        this.state = {
            password: '',
            matchPassword: '',
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleMatchPasswordChange(event) {
        this.setState({matchPassword: event.target.value});
    }

    onClickOk() {
        if (this.props.matchPasswords === true) {
            if (this.state.password === this.state.matchPassword) {
                undoOnBackCancelModal();
                this.props.onAccept(this.state.password);
            }
        }
        else {
            undoOnBackCancelModal();
            this.props.onAccept(this.state.password);
        }

    }

    render() {
        let passwordsMatch = true;
        if (this.props.matchPassword === true && this.state.password !== this.state.matchPassword) {
            passwordsMatch = false;
        }

        let okButtonDisabled = false;
        if (this.props.matchPassword === true && passwordsMatch === false) {
            okButtonDisabled = true;
        }

        let passwordsMatchElement = null;
        if (this.props.matchPassword === true && this.state.matchPassword.length > 4) {
            if (passwordsMatch) {
                passwordsMatchElement = <Form.Text className='text-primary' size='sm'>The passwords entered match.</Form.Text>
            } else {
                passwordsMatchElement = <Form.Text className='text-danger' size='sm'>The passwords entered do not match...</Form.Text>
            }
        }

        // enforce a min password length for user passwords
        let passwordsProblemsElement = null;
        if (this.props.userPassword === true && this.state.password.length < 6) {
            passwordsMatchElement = <Form.Text className='text-danger' size='sm'>Your password must be at least 6 characters long</Form.Text>
            okButtonDisabled = true;
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Enter Password'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {this.props.userPassword &&
                            <Form.Control id='username' name='username' autoComplete='username' value={this.props.username} readOnly hidden/>}
                            <Form.Group className="form-outline mb-2">
                                <Form.Label>{this.props.passwordLabel || 'A password is required to continue...'}</Form.Label>
                                <Form.Control id='password' name='password' type="password" onChange={(event)=>this.handlePasswordChange(event)} autoComplete={this.props.autocomplete || "off"} value={this.state.password} minLength={this.props.minLength || 6} maxLength={this.props.maxLength || 90} required/>
                                {passwordsProblemsElement}
                            </Form.Group>
                            {this.props.matchPassword &&
                            <Form.Group className="form-outline mb-2">
                                <Form.Label>{this.props.matchPasswordLabel || 're-type password...'}</Form.Label>
                                <Form.Control type="password" onChange={(event)=>this.handleMatchPasswordChange(event)} autoComplete={this.props.autocomplete || "off"} value={this.state.matchPassword} minLength={this.props.minLength || 6} maxLength={this.props.maxLength || 90} required/>
                                {passwordsMatchElement}
                            </Form.Group>}
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>this.onClickOk()} disabled={okButtonDisabled}>{this.props.acceptButtonText || 'Ok'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderAuthorizationKeyModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');
        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');

        this.restApi = new NerdHerderRestApi();

        this.state = {
            userFeedback: null,
            mode: 'resend',
            buttonDisabled: false,
            authKey: '',
        }
    }

    handleAuthKeyChange(event) {
        let value = event.target.value;
        let mode = 'resend';
        if (value.length > 0) {
            mode = 'verify';
        }
        this.setState({authKey: event.target.value, mode: mode});
    }

    onClickVerify() {
        this.setState({buttonDisabled: true});
        this.restApi.genericPatchEndpointData('self', null, {auth_key: this.state.authKey.trimEnd()})
        .then(response => {
            this.setState({userFeedback: <Alert variant='primary'>Verified</Alert>});
            setTimeout(()=>this.props.onAccept(), 1000);
        }).catch(error => {
            this.setState({buttonDisabled: false, authKey: '', mode: 'resend', userFeedback: <Alert variant='danger'>Incorrect verification code</Alert>});
        });
    }

    onClickResend() {
        this.restApi.genericPostEndpointData('resend-verification', null, {user_id: this.props.localUser.id})
        .then(response => {
            this.setState({buttonDisabled: false, userFeedback: <Alert variant='primary'>A new email has been sent</Alert>});
        }).catch(error => {
            console.error('got an unexpected error after resending verification email')
        });
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true}>
                    <Modal.Header>
                        <Modal.Title>{this.props.title || 'Email Verification'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.userFeedback}
                        <Form>
                            <Form.Group className="form-outline mb-2">
                                <Form.Label>{this.props.passwordLabel || 'Enter the verification code...'}</Form.Label>
                                <Form.Control type="text" onChange={(event)=>this.handleAuthKeyChange(event)} autoComplete="off" value={this.state.authKey} minLength={6} maxLength={90} required/>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.mode === 'resend' &&
                        <Button variant="primary" onClick={()=>this.onClickResend()} disabled={this.state.buttonDisabled}>Resend Verification Email</Button>}
                        {this.state.mode === 'verify' &&
                        <Button variant="primary" onClick={()=>this.onClickVerify()} disabled={this.state.buttonDisabled}>Verify Code</Button>}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderForgotPasswordModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderForgotPasswordModal'>
                <NerdHerderForgotPasswordModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderForgotPasswordModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');

        this.restApi = new NerdHerderRestApi();

        this.state = {
            userFeedback: null,
            formEmail: '',
            buttonDisabled: false,
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    handleEmailChange(event) {
        this.setState({formEmail: event.target.value});
        if (this.state.userFeedback !== null && this.state.buttonDisabled === true) {
            this.setState({buttonDisabled: false});
        }
    }

    onClickSend(event) {
        const form = event.currentTarget;
        const formIsValid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (formIsValid) {
            this.setState({userFeedback: null, buttonDisabled: true});
            this.restApi.genericPostEndpointData('forgot-password', null, {email: this.state.formEmail})
            .then(response => {
                this.setState({userFeedback: <Alert variant='primary'>A recovery email has been sent</Alert>});
                setTimeout(()=>this.props.onCancel(), 2000);
            }).catch(error => {
                this.setState({userFeedback: <Alert variant='danger'>That email doesn't correspond to an account</Alert>});
            });
        }
    }

    render() {
        let submitButtonDisabled = this.state.buttonDisabled;
        if (this.state.formEmail.length < 6) {
            submitButtonDisabled = true;
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}}  onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Forgot Password'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.userFeedback}
                        <Form id='forgot-password-form' onSubmit={(e)=>this.onClickSend(e)}>
                            <Form.Group className="form-outline mb-2">
                                <Form.Label>{this.props.messageLabel || 'Enter your email address'}</Form.Label>
                                <Form.Control type="email" onChange={(event)=>this.handleEmailChange(event)} autoComplete='email' value={this.state.formEmail} minLength={6} maxLength={90} required/>
                                <Form.Text size='sm'>A recovery email with a new password will be sent to this address</Form.Text>
                                <br/>
                                <Form.Text size='sm'>If you need further help, contact us: <EmailLink defaultSubject='Password Help'/></Form.Text>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button form='forgot-password-form' type="submit" variant="primary" disabled={submitButtonDisabled}>Send Recovery Email</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderEditFileModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderEditFileModal'>
                <NerdHerderEditFileModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderEditFileModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.fileId === 'undefined') console.error('missing props.fileId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            file: null,
            filename: '',
            description: '',
            access: 'managers',
            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        let sub = this.restPubSub.subscribe('file', this.props.fileId, (d, k)=>this.updateFile(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateFile(fileData, key) {
        const newFile = NerdHerderDataModelFactory('file', fileData);
        let description = newFile.description;
        if (description === null) description = '';
        this.setState({file: newFile,
                       filename: newFile.filename,
                       description: description,
                       access: newFile.access});
        
        if (this.state.updating) {
            undoOnBackCancelModal(); 
            this.props.onCancel();
        }
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    onUpdate() {
        this.setState({updating: true});
        let description = this.state.description.trimEnd();
        if (description.length === 0) description = null;
        const patchData = {filename: this.state.filename.trimEnd(),
                           description: description,
                           access: this.state.access}
        this.restPubSub.patch('file', this.props.fileId, patchData);
    }

    handleFilenameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('filename', {...this.state.formErrors});
        if (value.length < 4) {
            errorState = setErrorState('filename', {...this.state.formErrors}, 'this filename is too short');
        }
        this.setState({filename: value, formErrors: errorState});
    }

    handleDescriptionChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('description', {...this.state.formErrors});
        this.setState({description: value, formErrors: errorState});
    }

    handleAccessChange(event) {
        const value = event.target.value;
        let errorState = clearErrorState('access', {...this.state.formErrors});
        this.setState({access: value, formErrors: errorState});
    }

    render() {
        if (!this.state.file) return(<NerdHerderLoadingModal/>);

        const maxDescriptionLength = 200;

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        let disableUpdateButton = true;
        if (this.state.filename !== this.state.file.filename) disableUpdateButton = false;
        if (this.state.access !== this.state.file.access) disableUpdateButton = false;
        if (this.state.file.description === null && this.state.description.length > 0) disableUpdateButton = false;
        if (this.state.file.description !== null && this.state.file.description !== this.state.description) disableUpdateButton = false;
        if (hasFormErrors) disableUpdateButton = true;
        if (this.state.updating) disableUpdateButton = true;

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>File Name</Form.Label>
                                <Form.Control id='filename' name='filename' type="text" disabled={this.state.updating} onChange={(e)=>this.handleFilenameChange(e)} autoComplete='off' value={this.state.filename} minLength={4} maxLength={50} required/>
                                <FormErrorText errorId='filename' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Description</Form.Label>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='description' name='description' as="textarea" rows={5} disabled={this.state.updating} onChange={(e)=>this.handleDescriptionChange(e)} value={this.state.description} maxLength={maxDescriptionLength}/>
                                    <FormTextInputLimit current={this.state.description.length} max={maxDescriptionLength}/>
                                </div>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Access</Form.Label>
                                <Form.Select disabled={this.state.updating} onChange={(e)=>this.handleAccessChange(e)} value={this.state.access} required>
                                    <option value='managers'>Organizers</option>
                                    <option value='members'>Members</option>
                                    <option value='anyone'>Anyone</option>
                                </Form.Select>
                                {this.state.access === 'managers' &&
                                <Form.Text className='text-muted'><b>Organizers</b> - only organizers can view this file.</Form.Text>}
                                {this.state.access === 'members' &&
                                <Form.Text className='text-muted'><b>Members</b> - only organizers & players can view this file.</Form.Text>}
                                {this.state.access === 'anyone' &&
                                <Form.Text className='text-muted'><b>Anyone</b> - anyone can view this file.</Form.Text>}
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onUpdate()} disabled={disableUpdateButton}>Update</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderEditPollModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderEditPollModal'>
                <NerdHerderEditPollModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderEditPollModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.pollId === 'undefined') console.error('missing props.pollId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // arm the oneTimeMoveToPosted mechanic if this is a new poll
        let oneTimeMoveToPosted = null;
        if (this.props.pollId === null) {
            oneTimeMoveToPosted = false;
        }
        this.checkOneTimeMoveToPostedTimer = null;

        // minor differences in how the modal is presented if we are editing an existing poll or creating new
        this.createPresentation = false;
        if (this.props.pollId === null) {
            this.createPresentation = true;
        }

        this.state = {
            updating: false,
            onUpdateCompleteCancel: false,
            onUpdateEditPollOption: false,
            pollId: this.props.pollId,
            poll: null,
            showEditPollOptionModal: false,
            showPollDetailsModal: false,
            editPollOptionId: null,
            changedPollOptions: false,
            oneTimeMoveToPosted: oneTimeMoveToPosted,

            title: '',
            text: '',
            endDate: '',
            resultsShown: null,
            votersShown: true,
            allowedVoters: null,
            pollState: null,

            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        if (this.state.pollId !== null) {
            const sub = this.restPubSub.subscribe('poll', this.state.pollId, (d, k)=>this.updatePoll(d, k), (e, k)=>this.formUpdateError(e, k));
            this.restPubSubPool.add(sub);
        } else {
            const newPoll = NerdHerderDataModelFactory('poll', null, this.props.league.id);
            this.setState({poll: newPoll,
                title: newPoll.title,
                text: '',
                endDate: '',
                pollState: newPoll.state,
                resultsShown: newPoll.results_shown,
                votersShown: newPoll.voters_shown,
                allowedVoters: newPoll.allowed_voters});
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updatePoll(pollData, key) {
        const newPoll = NerdHerderDataModelFactory('poll', pollData);
        let text = newPoll.text;
        if (text === null) text = '';
        let date = newPoll.end_date;
        if (date === null) date = '';

        // the first time we get a poll update, if the poll is draft arm the oneTimeMoveToPosted mechanic
        let oneTimeMoveToPosted = this.state.oneTimeMoveToPosted;
        if (this.state.oneTimeMoveToPosted === null && newPoll.state === 'draft') {
            oneTimeMoveToPosted = false;
        }

        this.setState({poll: newPoll,
                       pollId: newPoll.id,
                       title: newPoll.title,
                       text: text,
                       endDate: date,
                       pollState: newPoll.state,
                       resultsShown: newPoll.results_shown,
                       votersShown: newPoll.voters_shown,
                       allowedVoters: newPoll.allowed_voters,
                       oneTimeMoveToPosted: oneTimeMoveToPosted,
                       updating: false});
        if (this.state.onUpdateCompleteCancel) {
            undoOnBackCancelModal(); 
            this.props.onCancel();
        }
        if (this.state.onUpdateEditPollOption) {
            this.setState({editPollOptionId: null, showEditPollOptionModal: true, onUpdateEditPollOption: false});
        }
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    onSave() {
        this.setState({onUpdateCompleteCancel: true});
        if (this.state.pollId === null) {
            this.onAdd()
        } else {
            this.onUpdate();
        }
    }

    onUpdate() {
        this.setState({updating: true});
        let text = this.state.text.trimEnd();
        if (text.length === 0) text = null;
        let endDate = this.state.endDate
        if (endDate.length === 0) endDate = null;
        const putData = {title: this.state.title.trimEnd(),
                         text: text,
                         state: this.state.pollState,
                         end_date: endDate,
                         results_shown: this.state.resultsShown,
                         voters_shown: this.state.votersShown,
                         allowed_voters: this.state.allowedVoters}
        this.restPubSub.put('poll', this.state.pollId, putData);
    }

    onAdd() {
        this.setState({updating: true});
        let text = this.state.text.trimEnd();
        if (text.length === 0) text = null;
        let endDate = this.state.endDate
        if (endDate.length === 0) endDate = null;
        const postData = {title: this.state.title.trimEnd(),
                          text: text,
                          state: this.state.pollState,
                          league_id: this.props.league.id,
                          end_date: endDate,
                          results_shown: this.state.resultsShown,
                          voters_shown: this.state.votersShown,
                          allowed_voters: this.state.allowedVoters}
        const sub = this.restPubSub.postAndSubscribe('poll', 'id', postData, (d, k)=>this.updatePoll(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    onEditPollOption(pollOptionId) {
        this.setState({editPollOptionId: pollOptionId, showEditPollOptionModal: true});
    }

    onAddPollOption() {
        // this is tricky - must already have a poll to add an option. if this is a new poll need to save it first and get the ID
        if (this.state.pollId === null) {
            this.setState({updating: true, onUpdateEditPollOption: true});
            this.onAdd();
        } else {
            // easy path - already have the ID
            this.setState({editPollOptionId: null, showEditPollOptionModal: true});
        } 
    }

    onCancelAddPollOption() {
        this.setState({editPollOptionId: null, showEditPollOptionModal: false, changedPollOptions: true, onUpdateEditPollOption: false});
        this.restPubSub.refresh('poll', this.state.pollId);
    }

    checkOneTimeMoveToPosted() {
        // once, when the form is basically all ready to go and the state is still draft, we'll move it to posted...
        if (this.state.oneTimeMoveToPosted === false) {
            if (this.state.pollState === 'draft') {
                if (this.state.title.length >= 6 && this.state.poll.option_ids.length >= 2) {
                    console.debug('trigger checkOneTimeMoveToPosted: draft->posted');
                    this.setState({pollState: 'posted', oneTimeMoveToPosted: true});
                }
            } else {
                // poll was already past draft - disarm the mechanic
                this.setState({oneTimeMoveToPosted: true});
            }
        }
    }

    handleTitleChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('title', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('title', {...this.state.formErrors}, 'this title is too short');
        }
        this.setState({title: value, formErrors: errorState});
    }

    handleTextChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('text', {...this.state.formErrors});
        this.setState({text: value, formErrors: errorState});
    }

    handleEndDateChange(event) {
        const value = event.target.value;
        let errorState = clearErrorState('end_date', {...this.state.formErrors});
        this.setState({endDate: value, formErrors: errorState});
    }

    handleStateChange(value) {
        let errorState = clearErrorState('state', {...this.state.formErrors});
        this.setState({pollState: value, formErrors: errorState});
    }

    handleResultsShownChange(value) {
        let errorState = clearErrorState('results_shown', {...this.state.formErrors});
        this.setState({resultsShown: value, formErrors: errorState});
    }

    handleAllowedVotersChange(value) {
        let errorState = clearErrorState('allowed_voters', {...this.state.formErrors});
        this.setState({allowedVoters: value, formErrors: errorState});
    }

    handleVotersShownChange(value) {
        let errorState = clearErrorState('voters_shown', {...this.state.formErrors});
        this.setState({votersShown: value, formErrors: errorState});
    }

    render() {
        if (!this.state.poll) return(<NerdHerderLoadingModal/>);
        if (this.state.showEditPollOptionModal) {
            return (
                <NerdHerderEditPollOptionModal pollOptionId={this.state.editPollOptionId} poll={this.state.poll} league={this.props.league} onCancel={()=>this.onCancelAddPollOption()} localUser={this.props.localUser}/>
            )
        }
        if (this.state.showPollDetailsModal) {
            return (
                <NerdHerderPollResultsModal onCancel={()=>this.setState({showPollDetailsModal: false})} pollId={this.props.pollId} league={this.props.league} localUser={this.props.localUser}/>
            )
        }

        // on every render, we'll check to see if we should automatically move the state to posted (can only trigger once)
        if (this.checkOneTimeMoveToPostedTimer !== null) clearTimeout(this.checkOneTimeMoveToPostedTimer);
        this.checkOneTimeMoveToPostedTimer = setTimeout(()=>this.checkOneTimeMoveToPosted(), 100);

        const maxTextLength = 200;
        const pollOptionItems = [];
        for (const optionId of this.state.poll.option_ids) {
            const optionItem = <PollOptionListItem key={optionId} pollOptionId={optionId} poll={this.state.poll} onClick={()=>this.onEditPollOption(optionId)} doNotSelectBorders={true} localUser={this.props.localUser}/>
            pollOptionItems.push(optionItem);
        }

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // the default state is that the user cannot save, but can add options
        let disableUpdateButton = true;
        let disableAddOptionButton = false;
        let specialOptionMessage = null;
        // if the form has been modified from what is on the server, allow the user to save
        if (this.state.pollId && !this.createPresentation) {
            if (this.state.title !== this.state.poll.title) disableUpdateButton = false;
            if (this.state.poll.text === null && this.state.text.length > 0) disableUpdateButton = false;
            if (this.state.poll.text !== null && this.state.poll.text !== this.state.text) disableUpdateButton = false;
            if (this.state.poll.end_date === null && this.state.endDate !== '') disableUpdateButton = false;
            if (this.state.poll.end_date !== null && this.state.endDate !== this.state.poll.end_date) disableUpdateButton = false;
            if (this.state.pollState !== this.state.poll.state) disableUpdateButton = false;
            if (this.state.resultsShown !== this.state.poll.results_shown) disableUpdateButton = false;
            if (this.state.votersShown !== this.state.poll.voters_shown) disableUpdateButton = false;
            if (this.state.allowedVoters !== this.state.poll.allowed_voters) disableUpdateButton = false;
        }
        // or if the form is not on the server yet (it's a new poll) the user can save
        else {
            disableUpdateButton = false;
        }
        // a little bit of a hack - if the user added or removed options the form should be considered 'modified' so let them save
        if (this.state.changedPollOptions) {
            disableUpdateButton = false;
        }
        // cannot save if the title is too short
        if (this.state.title.length < 6) {
            disableUpdateButton = true;
            disableAddOptionButton = true;
            specialOptionMessage = 'The poll needs a valid name before you can modify options.';
        }
        // cannot save if the form has errors
        if (hasFormErrors) {
            disableUpdateButton = true;
            disableAddOptionButton = true;
            specialOptionMessage = 'The errors must be corrected before you can modify options.';
        }
        // cannot save if there aren't enough options and the poll is past draft
        if (this.state.pollState !== 'draft' && pollOptionItems.length < 2) {
            disableUpdateButton = true;
        }
        // cannot save while a save is in progress
        if (this.state.updating) {
            disableUpdateButton = true;
            disableAddOptionButton = true;
        }

        let disableCompleteState = false;
        let disablePostedState = false;
        // if this is a brand new poll, can't go straight to completed
        if (this.state.pollId === null || this.createPresentation === true) disableCompleteState = true;
        // if this poll has no options, can't go to posted or completed
        if (pollOptionItems.length === 0) {
            disableCompleteState = true;
            disablePostedState = true;
        }

        // can get into a weird state where the cancel button is deceiving because we've actually already saved behind the scenes
        let showCancelButton = true;
        if (this.createPresentation && this.state.pollId) showCancelButton = false;

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        {this.createPresentation &&
                        <Modal.Title>Create New Poll</Modal.Title>}
                        {!this.createPresentation !== null &&
                        <Modal.Title>Edit Poll</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control id='title' name='title' type="text" placeholder="What's this poll about?" disabled={this.state.updating} onChange={(e)=>this.handleTitleChange(e)} autoComplete='off' value={this.state.title} minLength={6} maxLength={45} required/>
                                <FormErrorText errorId='title' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Description</Form.Label>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='text' name='text' as="textarea" rows={5} placeholder='Optionally add some details about this poll...' disabled={this.state.updating} onChange={(e)=>this.handleTextChange(e)} value={this.state.text} maxLength={maxTextLength}/>
                                    <FormTextInputLimit current={this.state.text.length} max={maxTextLength}/>
                                </div>
                                <FormErrorText errorId='text' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control id="end_date" name="end_date" type="date" disabled={this.state.updating} onChange={(event)=>this.handleEndDateChange(event)} autoComplete='off' value={this.state.endDate}/>
                                <FormErrorText errorId='end_date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>Optionally set a date. After this date the poll will automatically complete.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Ballot Options</Form.Label>
                                <br/>
                                {specialOptionMessage !== null &&
                                <Form.Text className='text-muted'>{specialOptionMessage}</Form.Text>}
                                {specialOptionMessage === null && pollOptionItems.length === 0 &&
                                <Form.Text className='text-muted'>Users have no options to vote on, you should add one...</Form.Text>}
                                {specialOptionMessage === null && pollOptionItems.length === 1 &&
                                <Form.Text className='text-muted'>Users need at least two options, you should add another...</Form.Text>}
                                {specialOptionMessage === null && pollOptionItems.length > 1 &&
                                <Form.Text className='text-muted'>Users may cast votes of one of the following:</Form.Text>}
                                {pollOptionItems}
                                <div className='text-end'>
                                    <Button size='sm' variant='primary' disabled={disableAddOptionButton} onClick={()=>this.onAddPollOption()}>Add Option</Button>
                                </div>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>This poll is...</Form.Label>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='poll-state' type="radio" value={this.state.pollState} onChange={(e)=>this.handleStateChange(e)}>
                                        <ToggleButton variant='outline-primary' id='toggle-state-adraft' value={'draft'}>A Draft</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-state-posted' disabled={disablePostedState} value={'posted'}>Posted</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-state-completed' disabled={disableCompleteState} value={'completed'}>Complete</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.pollState === 'draft' &&
                                <Form.Text className='text-muted'><b>Draft</b> - the poll is hidden. Use this state to get it properly setup.</Form.Text>}
                                {this.state.pollState === 'posted' &&
                                <Form.Text className='text-muted'><b>Posted</b> - the poll is 'active' & votes can be cast. This is the main interactive state for the poll.</Form.Text>}
                                {this.state.pollState === 'completed' &&
                                <Form.Text className='text-muted'><b>Complete</b> - the poll is concluded, no new votes can be cast.</Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Poll results are shown...</Form.Label>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='results-shown' type="radio" value={this.state.resultsShown} onChange={(e)=>this.handleResultsShownChange(e)}>
                                        <ToggleButton variant='outline-primary' id='toggle-results-always' value={'always'}>Always</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-results-postvote' value={'postvote'}>After Voting</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-results-completion' value={'completion'}>Poll Completion</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.resultsShown === 'always' &&
                                <Form.Text className='text-muted'><b>Always</b> - the current poll results are shown to voters while the poll is ongoing and after it is completed.</Form.Text>}
                                {this.state.resultsShown === 'postvote' &&
                                <Form.Text className='text-muted'><b>After Voting</b> - the current poll results are hidden until after a voter casts their vote. This is the recommended setting.</Form.Text>}
                                {this.state.resultsShown === 'completion' &&
                                <Form.Text className='text-muted'><b>Poll Completion</b> - the current poll results are hidden until the poll is completed.</Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Users can vote if they are...</Form.Label>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='voters-allowed' type="radio" value={this.state.allowedVoters} onChange={(e)=>this.handleAllowedVotersChange(e)}>
                                        <ToggleButton variant='outline-primary' id='toggle-voters-members' value={'members'}>Members</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-voters-players' value={'players'}>Players</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-voters-managers' value={'managers'}>Organizers</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-voters-anyone' value={'interested_users'}>Anyone</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.allowedVoters === 'members' &&
                                <Form.Text className='text-muted'><b>Members</b> - organizers and players can vote. Useful for most polls. This is the recommended setting.</Form.Text>}
                                {this.state.allowedVoters === 'players' &&
                                <Form.Text className='text-muted'><b>Players</b> - only the players can vote. Recommended for things like 'most improved player' or 'craziest list'.</Form.Text>}
                                {this.state.allowedVoters === 'managers' &&
                                <Form.Text className='text-muted'><b>Organizers</b> - only the organizers can vote. Recommended for things like painting competitions or other situations it is good to get the vote of the organizers recorded.</Form.Text>}
                                {this.state.allowedVoters === 'interested_users' &&
                                <Form.Text className='text-muted'><b>Anyone</b> - any user may vote (including those not in the league/tournament/event). This is useful for determining how things should work before opening for registration (otherwise discouraged).</Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Individual votes are...</Form.Label>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='voters-shown' type="radio" value={this.state.votersShown} onChange={(e)=>this.handleVotersShownChange(e)}>
                                        <ToggleButton variant='outline-primary' id='toggle-voters-true' value={true}>Public</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-voters-false' value={false}>Anonymous</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.votersShown === true &&
                                <Form.Text className='text-muted'><b>Public</b> - when results are shown, the votes of individual voters is public.</Form.Text>}
                                {this.state.votersShown === false &&
                                <Form.Text className='text-muted'><b>Anonymous</b> - how individuals voted is hidden (from players - organizers can see who voted for what).</Form.Text>}
                            </Form.Group>
                        </Form>
                        <div className='text-end'>
                            <Button size='sm' variant='primary' onClick={()=>this.setState({showPollDetailsModal : true})}>Show Details</Button>
                        </div>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        {showCancelButton &&
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>}
                        <Button variant="primary" onClick={()=>this.onSave()} disabled={disableUpdateButton}>{this.createPresentation ? 'Add Poll' : 'Update'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderEditPollOptionModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderEditPollOptionModal'>
                <NerdHerderEditPollOptionModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderEditPollOptionModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.pollOptionId === 'undefined') console.error('missing props.pollOptionId');
        if (typeof this.props.poll === 'undefined') console.error('missing props.poll');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            dzBusy: false,
            pollOptionId: this.props.pollOptionId,
            pollOption: null,
            poll: this.props.poll,

            title: '',
            text: '',
            userId: null,
            imageFile: null,

            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        if (this.state.pollOptionId !== null) {
            const sub = this.restPubSub.subscribe('poll-option', this.state.pollOptionId, (d, k)=>this.updatePollOption(d, k), (e, k)=>this.formUpdateError(e, k));
            this.restPubSubPool.add(sub);
        } else {
            const newPollOption = NerdHerderDataModelFactory('poll_option', null, this.props.poll.id);
            this.setState({pollOption: newPollOption,
                           title: newPollOption.title,
                           text: '',
                           imageFile: null,
                           userId: null,});
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updatePollOption(pollOptionData, key) {
        const newPollOption = NerdHerderDataModelFactory('poll_option', pollOptionData);
        let text = newPollOption.text;
        if (text === null) text = '';
        this.setState({pollOption: newPollOption,
                       title: newPollOption.title,
                       text: text,
                       imageFile: newPollOption.image_file,
                       userId: newPollOption.user_id});
        
        if (this.state.updating) {
            undoOnBackCancelModal(); 
            this.props.onCancel();
        }
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    onUpdate() {
        this.setState({updating: true});
        let text = this.state.text.trimEnd();
        if (text.length === 0) text = null;
        const putData = {title: this.state.title.trimEnd(),
                         text: text,
                         image_file: this.state.imageFile,
                         user_id: this.state.userId};
        this.restPubSub.put('poll-option', this.state.pollOptionId, putData);
    }

    onAdd() {
        this.setState({updating: true});
        let text = this.state.text.trimEnd();
        if (text.length === 0) text = null;
        const postData = {title: this.state.title.trimEnd(),
                          text: text,
                          state: this.state.pollState,
                          poll_id: this.state.poll.id,
                          user_id: this.state.userId,
                          image_file: this.state.imageFile};
        this.restApi.genericPostEndpointData('poll-option', null, postData)
        .then((response)=>{
            undoOnBackCancelModal(); 
            this.props.onCancel();
        })
        .catch((error)=>{
            this.formUpdateError(error, null);
            this.setState({updating: false});
        });
    }

    onDelete() {
        this.setState({updating: true});
        this.restPubSub.delete('poll-option', this.state.pollOptionId);
        undoOnBackCancelModal(); 
        this.props.onCancel();
    }

    handleTitleChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('title', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('title', {...this.state.formErrors}, 'this option title is too short');
        }
        this.setState({title: value, formErrors: errorState});
    }

    handleTextChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('text', {...this.state.formErrors});
        this.setState({text: value, formErrors: errorState});
    }

    handleEndDateChange(event) {
        const value = event.target.value;
        let errorState = clearErrorState('end_date', {...this.state.formErrors});
        this.setState({endDate: value, formErrors: errorState});
    }

    handleStateChange(value) {
        let errorState = clearErrorState('state', {...this.state.formErrors});
        this.setState({pollState: value, formErrors: errorState});
    }

    handleResultsShownChange(value) {
        let errorState = clearErrorState('results_shown', {...this.state.formErrors});
        this.setState({resultsShown: value, formErrors: errorState});
    }

    handleAllowedVotersChange(value) {
        let errorState = clearErrorState('allowed_voters', {...this.state.formErrors});
        this.setState({allowedVoters: value, formErrors: errorState});
    }

    handleVotersShownChange(value) {
        let errorState = clearErrorState('voters_shown', {...this.state.formErrors});
        this.setState({votersShown: value, formErrors: errorState});
    }

    onDzSuccess(file, response) {
        this.setState({imageFile: response, dzBusy: false});
    }

    onDzRemoveFile(file, response) {
        this.setState({imageFile: null, dzBusy: false});
    }

    onDzSending(file) {
        this.setState({dzBusy: true});
    }

    onDzError(file) {
        this.setState({dzBusy: false});
        console.error('DZ Error');
        console.error(file);
    }

    render() {
        if (this.state.pollOptionId && !this.state.pollOption) return(<NerdHerderLoadingModal/>);

        const maxTextLength = 200;

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        let disableUpdateButton = true;
        if (this.state.pollOptionId) {
            if (this.state.title !== this.state.pollOption.title) disableUpdateButton = false;
            if (this.state.pollOption.text === null && this.state.text.length > 0) disableUpdateButton = false;
            if (this.state.pollOption.text !== null && this.state.pollOption.text !== this.state.text) disableUpdateButton = false;
            if (this.state.userId !== this.state.pollOption.user_id) disableUpdateButton = false;
            if (this.state.imageFile !== this.state.pollOption.image_file) disableUpdateButton = false;
        } else {
            disableUpdateButton = false;
        }
        if (this.state.title.length < 3) disableUpdateButton = true;
        if (hasFormErrors) disableUpdateButton = true;
        if (this.state.updating) disableUpdateButton = true;
        if (this.state.dzBusy) disableUpdateButton = true;

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        {this.state.pollOptionId === null &&
                        <Modal.Title>Create New Poll Option</Modal.Title>}
                        {this.state.pollOptionId !== null &&
                        <Modal.Title>Edit Poll Option</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control id='title' name='title' type="text" placeholder="Give this option a name" disabled={this.state.updating} onChange={(e)=>this.handleTitleChange(e)} autoComplete='off' value={this.state.title} minLength={6} maxLength={45} required/>
                                <FormErrorText errorId='title' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Description</Form.Label>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='text' name='text' as="textarea" rows={5} placeholder='Optional: add any amplifying information about this option...' disabled={this.state.updating} onChange={(e)=>this.handleTextChange(e)} value={this.state.text} maxLength={maxTextLength}/>
                                    <FormTextInputLimit current={this.state.text.length} max={maxTextLength}/>
                                </div>
                                <FormErrorText errorId='text' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Image</Form.Label>
                                <NerdHerderDropzoneImageUploader
                                    ref={this.dzRef}
                                    localUser={this.props.localUser}
                                    message={'Drop file here to add to the poll option'}
                                    uploadUrl={`/rest/v1/dz-poll-option-image-upload/${this.state.pollOptionId}`}
                                    maxFiles={1}
                                    removeCallback={(f)=>this.onDzRemoveFile(f)}
                                    sendingCallback={(f)=>this.onDzSending(f)}
                                    successCallback={(f, r)=>this.onDzSuccess(f, r)}
                                    errorCallback={(f)=>this.onDzError(f)}/>
                                <Form.Text muted>Optionally drop an image here to add it next to the description for this option.</Form.Text>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.pollOptionId !== null &&
                        <Button variant="danger" onClick={()=>this.onDelete()}>Delete</Button>}
                        {this.state.pollOptionId !== null &&
                        <Button variant="primary" onClick={()=>this.onUpdate()} disabled={disableUpdateButton}>Update</Button>}
                        {this.state.pollOptionId === null &&
                        <Button variant="primary" onClick={()=>this.onAdd()} disabled={disableUpdateButton}>Add Option</Button>}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderAddGameModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderAddGameModal' onCancel={()=>this.props.onCancel()}>
                <NerdHerderAddGameModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderAddGameModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAddGame === 'undefined') console.error('missing props.onAddGame');

        // grab the league directly from props if possible
        let leagueId = null;
        let leagueData = null;
        let topicData = null;
        let leagueSelectedFromContext = false;
        let isBoardGameLeague = false;
        if (typeof this.props.league !== 'undefined') {
            leagueData = this.props.league;
            topicData = this.props.league.topic;
            leagueId = this.props.league.id;
            leagueSelectedFromContext = true;
            if (leagueData.topic_id === 'BG') isBoardGameLeague = true;
        } else if (typeof this.props.leagueId !== 'undefined') {
            leagueId = this.props.leagueId;
            leagueSelectedFromContext = true;
        }

        // grab the event directly from props if possible - and if the event is passed in then we are locked to this event
        let eventData = null;
        let isEventGame = false;
        let eventGameLocked = false;
        if (this.props.event != null) {
            isEventGame = true;
            eventGameLocked = true;
            eventData = this.props.event;
        }

        // grab the tournament directly from props if possible - and if the event is passed in then we are locked to this event
        let tournamentData = null;
        let isTournamentGame = false;
        let tournamentGameLocked = false;
        if (this.props.tournament != null) {
            isTournamentGame = true;
            tournamentGameLocked = true;
            tournamentData = this.props.tournament;
        }

        // add this user as a player unless it is a tournament game or they are not a player
        const defaultPlayerIds = [];
        const defaultPlayerDict = {};
        const initialUsersCache = {};
        let addThisUser = false;
        if (isTournamentGame) {
            addThisUser = false;
        } else {
            if (isEventGame) {
                addThisUser = this.props.event.isPlayer(this.props.localUser.id);
            } else if (this.props.league) {
                addThisUser = this.props.league.isPlayer(this.props.localUser.id);
            }
        }
        if (addThisUser) {
            defaultPlayerIds.push(this.props.localUser.id);
            defaultPlayerDict[this.props.localUser.id] = this.generateFormPlayersDict(this.props.localUser.id, 0, 0, 0, 0, false, true, 0, null, null, null);
            initialUsersCache[this.props.localUser.id] = this.props.localUser;
        }

        // normally the user needs to first select a league, however if the league was provided then we default to selecting players
        let defaultCenterSectionMode = 'leagues';
        if (leagueId !== null || leagueData !== null) defaultCenterSectionMode = 'players';

        let defaultCompletion = 'completed';

        const game = NerdHerderDataModelFactory('game', null);
        if (isTournamentGame) {
            game.tournament_id = this.props.tournament.id;
            game.round_id = this.props.tournamentRoundId || null;
            game.table_name = this.props.tableName || 'No Table Assigned';
            game.event_id = this.props.tournament.event_id;
            game.details = this.props.defaultDetails || null;
            game.completion = 'scheduled';
            defaultCompletion = 'scheduled';
        }

        let defaultGamePoints = '';
        if (leagueData !== null) {
            if (leagueData.topic.game_has_points && leagueData.topic.game_has_points_default && leagueData.topic.game_has_points_default !== 0) {
                defaultGamePoints = leagueData.topic.game_has_points_default;
            }
        }

        this.state = {
            // GUI presentation stuff
            navigateTo: null,
            showUserProfileModal: false,
            selectedUserIdForProfileModal: null,
            showAddNewBoardGameModal: false,
            updating: false,
            centerSectionMode: defaultCenterSectionMode,
            leagueContext: leagueSelectedFromContext,
            isBoardGameLeague: isBoardGameLeague,

            // game state for the server
            gameId: null,
            game: game,
            isEventGame: isEventGame,
            isTournamentGame: isTournamentGame,
            eventGameLocked: eventGameLocked,
            tournamentGameLocked: tournamentGameLocked,
            event: eventData,
            tournament: tournamentData,
            leagueId: leagueId,
            league: leagueData,
            leagueLastFactionDict: null,
            topic: topicData,

            // leagues & events loaded in the background
            leagues: {},
            events: {},
            boardGames: null,

            // keep a 'cache' of users that could be players to avoid repeated lookups
            usersCache: initialUsersCache,

            // stuff related to the form - only need to have these seperate from the gameData because we use this modal for 'new'
            formPlayerIds: defaultPlayerIds,
            formPlayerDict: defaultPlayerDict,
            formDate: convertTodaysLocalDateObjectToFormInput(),
            formDetails: this.props.defaultDetails || '',
            formCompletion: defaultCompletion,
            formBoardGameId: 0,
            formEventId: eventData ? eventData.id : 0,
            formFirstPlayer: 0,
            formGamePoints: defaultGamePoints,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        this.loadSelectableLeagues();
        if (this.state.league !== null) {
            this.loadSelectableEvents(this.state.league);
            this.initializeContextLeague();
        }

        // kind of a hack - the game will have the wrong event set if we are locked to an event (normally the game updated when the 
        // event is selected, but with the event locked the user cannot set the event). So we'll fake a change to that here.
        if (this.state.eventGameLocked) {
            const updatedGameData = {...this.state.game, league_id: this.state.leagueId, event_id: this.state.formEventId};
            this.setState({game: updatedGameData});
        }

        if (this.state.isBoardGameLeague) {
            const sub = this.restPubSub.subscribeNoRefresh('board-game', null, (d, k) => {this.updateSelectableBoardGames(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    loadSelectableLeagues() {
        for (const usersLeagues of this.props.localUser.users_leagues) {
            if (usersLeagues.player || usersLeagues.manager) {
                const sub = this.restPubSub.subscribeNoRefresh('league', usersLeagues.league_id, (d, k) => {this.updateSelectableLeague(d, k)}, null, usersLeagues.league_id);
                this.restPubSubPool.add(sub);
            } 
        }
    }

    loadSelectableEvents(leagueData) {
        for (const eventId of leagueData.event_ids) {
            const sub = this.restPubSub.subscribeNoRefresh('event', eventId, (d, k) => {this.updateSelectableEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        let isBoardGameLeague = false;
        if (newLeague.topic_id === 'BG') isBoardGameLeague = true;
        this.setState({league: newLeague, topic: newLeague.topic, isBoardGameLeague: isBoardGameLeague});
        this.loadSelectableEvents(newLeague);

        if (isBoardGameLeague && this.state.boardGames === null) {
            const sub = this.restPubSub.subscribeNoRefresh('board-game', null, (d, k) => {this.updateSelectableBoardGames(d, k)});
            this.restPubSubPool.add(sub);
        }

        // if the league/topic was updated and there is a default points value set it
        if (newLeague.topic.game_has_points && newLeague.topic.game_has_points_default && newLeague.topic.game_has_points_default !== 0) {
            if (this.state.formGamePoints === '') {
                this.setState({formGamePoints: newLeague.topic.game_has_points_default});
            }
        }
    }

    updateSelectableLeague(leagueData, leagueId) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);

        // only want to add it if the league is in a good state
        if (['open', 'running'].includes(newLeague.state)) {
            this.setState((state) => {
                return {leagues: {...state.leagues, [leagueId]: newLeague}}
            });
        }
    }

    updateEvent(eventData, key) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: newEvent});
    }

    updateSelectableEvent(eventData, eventId) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState((state) => {
            return {events: {...state.events, [eventId]: newEvent}}
        });
    }

    updateSelectableBoardGames(boardGameData, key) {
        this.setState({boardGames: boardGameData});
    }

    updateUser(userData, userId) {
        this.updateUsersCache(userData);
    }

    updateUsersCache(userData) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [newUser.id]: newUser}}
        });
    }

    updateLeagueLastFaction(leagueLastFactionDict, key) {
        this.setState({leagueLastFactionDict: leagueLastFactionDict});

        // some players may already be selected when this executes
        for (const playerId of this.state.formPlayerIds) {
            let defaultFactionId = null;
            if (leagueLastFactionDict !== null && this.state.topic !== null && this.state.topic.game_player_has_faction && !this.state.topic.game_player_multi_faction) {
                if (leagueLastFactionDict.hasOwnProperty(playerId)) {
                    const lastFactionId = leagueLastFactionDict[playerId];
                    const factionDict = this.state.topic.getFactionDict();
                    if (lastFactionId !== null && lastFactionId !== '' && factionDict.hasOwnProperty(lastFactionId)) {
                        defaultFactionId = lastFactionId;
                    }
                }
            }
            // if the last faction id was found, and is good, then we'll assign it to the player
            if (defaultFactionId !== null && this.state.formPlayerDict.hasOwnProperty(playerId)) {
                // if we get here, the last faction id is good - but we don't want to change it if already set
                const playerDict = this.state.formPlayerDict[playerId];
                if (playerDict.faction === null || playerDict.faction === '') {
                    // ok actually do the update
                    const newPlayerDict = {...playerDict};
                    newPlayerDict.faction = defaultFactionId;
                    this.setState((state) => {
                        return {formPlayerDict: {...state.formPlayerDict, [playerId]: newPlayerDict}}
                    });
                }
            }
        }
    }

    generateFormPlayersDict(userId, score, score1, score2, score3, winner, concur, gameId, faction, listPoints, list_id) {
        const result = {
            user_id: userId,
            score: score,
            score1: score1,
            score2: score2,
            score3: score3,
            winner: winner,
            concur_with_results: concur,
            concur_with_schedule: false,
            game_id: gameId,
            faction: faction,
            list_points: listPoints,
            list_id: list_id,
        }
        return result;
    }

    showUserProfile(userId) {
        this.setState({showUserProfileModal: true, selectedUserIdForProfileModal: userId});
    }

    hideUserProfile() {
        this.setState({showUserProfileModal: false, selectedUserIdForProfileModal: null});
    }

    showAddNewBoardGameModal() {
        this.setState({showAddNewBoardGameModal: true});
    }

    hideAddNewBoardGameModal(result) {
        if (result) {
            const updatedGameData = {...this.state.game, board_game_id: result};
            this.setState({showAddNewBoardGameModal: false, game: updatedGameData, formBoardGameId: result});
        } else {
            this.setState({showAddNewBoardGameModal: false});
        }        
    }

    onSwitchView(value) {
        this.setState({centerSectionMode: value});
    }

    onAddGameClicked() {
        const newGame = {...this.state.game};
        newGame.players = [];
        this.setState({updating: true});
        // add the players to the game
        for (const playerId of this.state.formPlayerIds) {
            const playerDict = this.state.formPlayerDict[playerId];
            // server will set game_id
            playerDict.game_id = null;
            // cleanup faction and list points
            if (playerDict.faction === '' || playerDict.faction === 'notset') playerDict.faction = null;
            if (playerDict.faction !== null && playerDict.length >= 200) playerDict.faction = null;
            if (playerDict.listPoints !== null && playerDict.listPoints < 0) playerDict.listPoints = null;
            if (playerDict.score === '') playerDict.score = 0;
            if (playerDict.score1 === '') playerDict.score1 = 0;
            if (playerDict.score2 === '') playerDict.score2 = 0;
            if (playerDict.score3 === '') playerDict.score3 = 0;
            playerDict.list_id = null;
            newGame.players.push(playerDict);
        }

        // do the POST
        this.restApi.genericPostEndpointData('game', null, newGame)
        .then((response)=>{
            const updatedGame = NerdHerderDataModelFactory('game', response.data);
            this.setState({gameId: updatedGame.id, game: updatedGame});
            this.props.onAddGame(updatedGame.gameId, updatedGame);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        })
    }

    onSelectLeague(newLeagueId, newLeagueData) {
        if (newLeagueId !== this.state.leagueId) {
            const updatedGameData = {...this.state.game, league_id: newLeagueId};

            // load the last faction data for the league
            const sub = this.restPubSub.subscribe('league-last-faction-list', newLeagueId, (d, k) => {this.updateLeagueLastFaction(d, k)});
            this.restPubSubPool.add(sub);

            let isBoardGameLeague = false;
            if (newLeagueData.topic_id === 'BG') isBoardGameLeague = true;
            if (isBoardGameLeague && this.state.boardGames === null) {
                const sub = this.restPubSub.subscribeNoRefresh('board-game', null, (d, k) => {this.updateSelectableBoardGames(d, k)});
                this.restPubSubPool.add(sub);
            }

            // if this is the first selection, take them to the players section
            if (this.state.leagueId === null) {
                this.setState({leagueId: newLeagueId, league: newLeagueData, topic: newLeagueData.topic, centerSectionMode: 'players', game: updatedGameData, isBoardGameLeague: isBoardGameLeague});
            } else {
                this.setState({leagueId: newLeagueId, league: newLeagueData, topic: newLeagueData.topic, game: updatedGameData, isBoardGameLeague: isBoardGameLeague});
            }

            // need to get all the players and load them in the cache
            for (const playerId of newLeagueData.player_ids) {
                const sub = this.restPubSub.subscribeNoRefresh('user', playerId, (d, k) => {this.updateUser(d, k)}, null, playerId);
                this.restPubSubPool.add(sub);
            }

            // need to get all the events and load them as well
            for (const eventId of newLeagueData.event_ids) {
                const sub = this.restPubSub.subscribeNoRefresh('event', eventId, (d, k) => {this.updateSelectableEvent(d, k)}, null, eventId);
                this.restPubSubPool.add(sub);
            }
        }
    }

    // if the game is added in the context of a league, this does the work completed in onSelectLeague() as if the user clicked the league
    initializeContextLeague() {
        const updatedGameData = {...this.state.game, league_id: this.state.league.id};
        this.setState({game: updatedGameData});

        // load the last faction data for the league
        const sub = this.restPubSub.subscribe('league-last-faction-list', this.state.leagueId, (d, k) => {this.updateLeagueLastFaction(d, k)});
        this.restPubSubPool.add(sub);

        // need to get all the players and load them in the cache
        for (const playerId of this.state.league.player_ids) {
            const sub = this.restPubSub.subscribeNoRefresh('user', playerId, (d, k) => {this.updateUser(d, k)}, null, playerId);
            this.restPubSubPool.add(sub);
        }

        // need to get all the events and load them as well
        for (const eventId of this.state.league.event_ids) {
            const sub = this.restPubSub.subscribeNoRefresh('event', eventId, (d, k) => {this.updateSelectableEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
    }

    onChangeScore(event, userId, scoreNum) {
        let newScore = event.target.value;
        const dict = this.state.formPlayerDict[userId];
        if (scoreNum === 3) {
            dict.score3 = newScore;
        } else if (scoreNum === 2) {
            dict.score2 = newScore;
        } else {
            dict.score = newScore;
            dict.score1 = newScore;
        }
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onSelectScore(event, userId, scoreNum) {
        const dict = this.state.formPlayerDict[userId];
        if (scoreNum === 3) {
            // eslint-disable-next-line eqeqeq
        if (dict.score3 == 0) {
            dict.score3 = '';
        }
        } else if (scoreNum === 2) {
            // eslint-disable-next-line eqeqeq
        if (dict.score2 == 0) {
            dict.score2 = '';
        }
        } else {
            // eslint-disable-next-line eqeqeq
            if (dict.score1 == 0) {
                dict.score1 = '';
                dict.score = '';
            }
        }
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerFaction(event, userId) {
        let newFaction = event.target.value;
        const dict = this.state.formPlayerDict[userId];
        dict.faction = newFaction;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerFactionMulti(event, userId) {
        let newFaction = event.target.value;
        const dict = this.state.formPlayerDict[userId];
        // need a list of factions, empty or from the csv string
        let listOfFactions = [];
        if (dict.faction !== null) {
            listOfFactions= dict.faction.split(',');
        }
        // if the faction is not already in the list add it, otherwise remove it
        const index = listOfFactions.indexOf(newFaction);
        if (index === -1) {
            listOfFactions.push(newFaction);
        } else {
            listOfFactions.splice(index, 1);
        }
        // rebuild the list of factions into a string of factions
        dict.faction = listOfFactions.join();
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerListPoints(event, userId) {
        let newListPoints = event.target.value;
        const dict = this.state.formPlayerDict[userId];
        dict.list_points = newListPoints;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerList(event, userId) {
        let newListId = event.target.value;
        if (newListId === 'none') newListId = null;
        const dict = this.state.formPlayerDict[userId];
        dict.list_id = newListId;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangeWinner(event, userId) {
        let winner = event.target.checked;
        const dict = this.state.formPlayerDict[userId];
        dict.winner = winner;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onPlayerAdded(event, playerId) {
        let checked = event.target.checked;
        let newPlayerDict = null;
        const modifiedPlayerIdList = [...this.state.formPlayerIds];
        // checked == adding player
        if (checked) {
            modifiedPlayerIdList.push(playerId);

            // see if the new player has a default faction - but only use that if the topic uses factions, and if the faction is legit
            let defaultFactionId = null;
            if (this.state.leagueLastFactionDict !== null && this.state.topic !== null && this.state.topic.game_player_has_faction && !this.state.topic.game_player_multi_faction) {
                if (this.state.leagueLastFactionDict.hasOwnProperty(playerId)) {
                    const lastFactionId = this.state.leagueLastFactionDict[playerId];
                    const factionDict = this.state.topic.getFactionDict();
                    if (lastFactionId !== null && lastFactionId !== '' && factionDict.hasOwnProperty(lastFactionId)) {
                        defaultFactionId = lastFactionId;
                    }
                }
            }

            if (!this.state.formPlayerDict.hasOwnProperty(playerId)) {
                newPlayerDict = this.generateFormPlayersDict(playerId, 0, 0, 0, 0, false, false, this.state.gameId, defaultFactionId, null, null);
            } else {
                newPlayerDict = this.state.formPlayerDict[playerId];
            }
            this.setState((state) => {
                return {formPlayerDict: {...state.formPlayerDict, [playerId]: newPlayerDict},
                        formPlayerIds: modifiedPlayerIdList,
                    }
            });
        }
        // not checked == removing player
        else {
            const index = modifiedPlayerIdList.indexOf(playerId);
            modifiedPlayerIdList.splice(index, 1);
            this.setState({formPlayerIds: modifiedPlayerIdList});
        }
    }

    handleFormDate(event) {
        const updatedGameData = {...this.state.game, date: event.target.value};
        this.setState({formDate: event.target.value, game: updatedGameData});
    }
    
    handleFormEvent(event) {
        // eslint-disable-next-line eqeqeq
        const gameEventValue = event.target.value == 0 ? null : event.target.value;
        const updatedGameData = {...this.state.game, event_id: gameEventValue};
        this.setState({formEventId: event.target.value, game: updatedGameData});
    }

    handleFormBoardGame(event) {
        let boardGameValue = event.target.value;
        let updatedGameData = null;
        let showAddNewBoardGameModal = false;
        // eslint-disable-next-line eqeqeq
        if (boardGameValue == -1) {
            updatedGameData = {...this.state.game, board_game_id: null};
            showAddNewBoardGameModal = true;
            boardGameValue = 0;
        }
        // eslint-disable-next-line eqeqeq
        else if (boardGameValue == 0) {
            updatedGameData = {...this.state.game, board_game_id: null};
        }
        else {
            updatedGameData = {...this.state.game, board_game_id: boardGameValue};
        }
        this.setState({formBoardGameId: boardGameValue, game: updatedGameData, showAddNewBoardGameModal: showAddNewBoardGameModal});  
    }

    handleFormDetails(event) {
        const updatedGameData = {...this.state.game, details: event.target.value};
        this.setState({formDetails: event.target.value, game: updatedGameData});
    }
    
    handleFormCompletion(value) {
        const updatedGameData = {...this.state.game, completion: value};
        this.setState({formCompletion: value, game: updatedGameData});
    }

    handleFormGamePoints(event) {
        let value = event.target.value;
        let updatedGameData = null;
        if (value === '') {
            updatedGameData = {...this.state.game, game_points: null};
        } else {
            updatedGameData = {...this.state.game, game_points: value};
        }
        this.setState({formGamePoints: value, game: updatedGameData});
    }

    handleFormFirstPlayer(event) {
        let value = event.target.value;
        let updatedGameData = null;
        if (value === 0) {
            updatedGameData = {...this.state.game, first_player_id: null};
        } else {
            updatedGameData = {...this.state.game, first_player_id: value};
        }
        this.setState({formFirstPlayer: value, game: updatedGameData});
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);

        let errorFeedback = null;
        let maxDetailsLength = 4096;

        // shortcut variables
        const isEventGame = this.state.isEventGame;
        const isTournamentGame = this.state.isTournamentGame;
        const isUpdating = this.state.updating;
        const leagueData = this.state.league;
        const topicData = this.state.topic;
        const eventData = this.state.event;
        const tournamentData = this.state.tournament;
        const localUserData = this.props.localUser;

        // if we have a league and topic, might also have special nouns...
        let listNoun = '';
        let listNounPlural = '';
        let factionNoun = '';
        let pointsNoun = '';
        let firstPlayerNoun = '';
        if (leagueData !== null && topicData !== null) {
            listNoun = topicData.list_noun;
            listNounPlural = pluralize(listNoun);
            factionNoun = topicData.faction_noun;
            pointsNoun = topicData.points_noun;
            firstPlayerNoun = topicData.first_player_noun;
        }

        // see if there are list containers
        let listContainers = null;
        if (isTournamentGame && tournamentData) {
            if (tournamentData.list_container_ids.length !== 0) listContainers = tournamentData.list_containers;
        } else if (isEventGame && eventData) {
            if (eventData.list_container_ids.length !== 0) listContainers = eventData.list_containers;
        } else if (leagueData) {
            // for leagues we need to filter out all the tournament and event containers
            if (leagueData.list_container_ids.length !== 0) {
                listContainers = [];
                for (const listContainer of leagueData.list_containers) {
                    if (listContainer.event_id === null && listContainer.tournament_id === null) {
                        listContainers.push(listContainer);
                    }
                }
            }
        }
        if (listContainers !== null && listContainers.length === 0) listContainers = null;

        // league managers may get special features?
        let isLeagueManager = false;
        if (leagueData !== null && leagueData.isManager(localUserData.id)) {
            isLeagueManager = true;
        }

        // also handy to know if this user is a player or manager of the associated event
        /*let isEventManager = false;
        let isEventPlayer = false;
        if (isEventGame) {
            if (eventData.isManager(localUserData.id)) isEventManager = true;
            if (eventData.isPlayer(localUserData.id)) isEventPlayer = true;
        }*/

        // the list of eligible players varies depending on if this is a event or regular league game...
        let eligiblePlayerIdList = [];
        if (leagueData !== null) {
            if (isTournamentGame) {
                eligiblePlayerIdList = tournamentData.player_ids;
            }
            else if (isEventGame) {
                eligiblePlayerIdList = eventData.player_ids;
            } else {
                eligiblePlayerIdList = leagueData.player_ids;
            }
        }
        
        // generate the events list
        // todo - if there is an event going on today, select that by default
        // todo - if the event changes we need to verify that all players are in the event
        let eventOptions = [];
        const nullEventItem = <option key={0} value={0}>No Minor Event</option>
        eventOptions.push(nullEventItem);
        if (leagueData !== null) {
            for (const eventId of leagueData.event_ids) {
                let eventItem = null;
                // if we've background loaded this event, then use its name, otherwise use the id
                if (this.state.events.hasOwnProperty(eventId)) {
                    eventItem = <option key={eventId} value={eventId}>{this.state.events[eventId].name}</option>
                } else {
                    eventItem = <option key={eventId} value={eventId}>Minor Event {eventId}</option>
                }
                eventOptions.push(eventItem);
            }
        }

        // generate the board game options - these only matter if the topic is board games
        let boardGameOptions = [];
        const nullBoardGameItem = <option key={0} value={0}>Not Selected</option>
        const newBoardGameItem = <option key={-1} value={-1}>Add New Board Game</option>
        boardGameOptions.push(nullBoardGameItem);
        boardGameOptions.push(newBoardGameItem);
        if (this.state.isBoardGameLeague) {
            let selectedBoardGameId = parseInt(this.state.formBoardGameId);
            if (selectedBoardGameId !== 0 && selectedBoardGameId !== -1 && this.state.boardGames !== null && leagueData && !leagueData.board_game_ids.includes(selectedBoardGameId)) {
                if (this.state.boardGames.hasOwnProperty(selectedBoardGameId)) {
                    const selectedBoardGame = this.state.boardGames[selectedBoardGameId];
                    const unlistedBoardGameItem = <option key={selectedBoardGameId} value={selectedBoardGameId}>{selectedBoardGame.name}</option>
                    boardGameOptions.push(unlistedBoardGameItem);
                }
            }
            for (const boardGame of leagueData.board_games) {
                let boardGameItem = <option key={boardGame.id} value={boardGame.id}>{boardGame.name}</option>
                boardGameOptions.push(boardGameItem);
            }
        }

        // generate the first player options - these only matter if we collect stats for this game, and if this is a stat collected
        let firstPlayerOptions = [];
        const nullFirstPlayerItem = <option key={0} value={0}>No {capitalizeFirstLetters(firstPlayerNoun)} Player</option>
        firstPlayerOptions.push(nullFirstPlayerItem);
        if (topicData !== null && topicData.collect_game_stats && topicData.game_has_first_player) {
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const userData = this.state.usersCache[playerId];
                let firstPlayerItem = <option key={playerId} value={playerId}>{userData.username}</option>
                firstPlayerOptions.push(firstPlayerItem);
            }
        }

        // generate the player's lists table
        const listsTableRows = [];
        if (topicData !== null && listContainers !== null) {
            // first make a dict of all the list containers with a list of players who have uploaded to that container as the value
            const listContainerDict = {};
            const listContainerFileIdDict = {};
            for (const listContainer of listContainers) {
                listContainerDict[listContainer.id] = [];
                for (const file of listContainer.files) {
                    listContainerDict[listContainer.id].push(file.user_id);
                    listContainerFileIdDict[`${listContainer.id}-${file.user_id}`] = file.id;
                }
            }
            // next go through each player, and create the JSX with that player's lists
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                const listOptions = [];
                const nullListItem = <option key={`${playerId}-none`} value={'none'}>No {capitalizeFirstLetters(listNoun)} Set</option>
                listOptions.push(nullListItem);
                for (const listContainer of listContainers) {
                    let listItem = null;
                    if (listContainerDict[listContainer.id].includes(playerId)) {
                        const fileId = listContainerFileIdDict[`${listContainer.id}-${playerId}`];
                        listItem = <option key={`${playerId}-${listContainer.id}-${fileId}`} value={fileId}>{listContainer.name}</option>
                    } else {
                        listItem = <option key={`${playerId}-${listContainer.id}-disabled`} value={'disabled'} disabled={true}>{listContainer.name}</option>
                    }
                    listOptions.push(listItem);
                }
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle">
                            <Form.Select size='sm' onChange={(e)=>this.onChangePlayerList(e, playerId)} value={thisPlayerDict.list_id || 'none'} disabled={isUpdating}>
                                {listOptions}
                            </Form.Select>
                        </td>
                    </tr>
                listsTableRows.push(newRow);
            }
        }

        // the user will either see a list of leagues, a list of players or a list of players with scores...they all come from this table
        const selectableTableRows = [];
        const selectableTableRows2 = [];
        const selectableTableRows3 = [];

        // generate the leagues table
        let noPossibleLeagues = false;
        if (this.state.centerSectionMode === 'leagues') {
            noPossibleLeagues = true;
            for (const [selLeagueId, selLeagueData] of Object.entries(this.state.leagues)) {
                // don't offer the league if the user can't create games there....
                if (!selLeagueData.players_create_games && !selLeagueData.isManager(this.props.localUser.id)) continue;
                const newRow = <LeagueListItem key={selLeagueId} leagueId={selLeagueId} league={selLeagueData} localUser={this.props.localUser} onClick={()=>this.onSelectLeague(selLeagueId, selLeagueData)} selected={selLeagueId===this.state.leagueId}/>
                selectableTableRows.push(newRow);
                noPossibleLeagues = false;
            }
        }

        // if there were no possible leagues, show a different modal
        if (noPossibleLeagues) return <NerdHerderNoLeaguesAddGameModalInner onCancel={this.props.onCancel} localUser={this.props.localUser}/>

        // generate the players table
        if (this.state.centerSectionMode === 'players') {
            for (const playerId of eligiblePlayerIdList) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const newRow = 
                    <Row key={playerId}>
                        <Col xs='auto' className="text-center align-middle pe-0">
                            <Form.Check aria-label="player" value={playerId} checked={this.state.formPlayerIds.includes(playerId)} disabled={isUpdating} onChange={(e)=>this.onPlayerAdded(e, playerId)}/>
                        </Col>
                        <Col className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </Col>
                    </Row>
                selectableTableRows.push(newRow);
            }
        }

        // generate the scores table
        if (this.state.centerSectionMode === 'scores') {
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle"><Form.Control size='sm' type="number" value={thisPlayerDict.score} disabled={isUpdating} onChange={(e)=>this.onChangeScore(e, playerId, 1)} onSelect={(e)=>this.onSelectScore(e, playerId, 1)}/></td>
                        <td className="text-center align-middle"><Form.Check aria-label="winner" value={playerId} checked={thisPlayerDict.winner} disabled={isUpdating} onChange={(e)=>this.onChangeWinner(e, playerId)}/></td>
                    </tr>
                selectableTableRows.push(newRow);
            }
            if (topicData.scores_recorded >= 2) {
                for (const playerId of this.state.formPlayerIds) {
                    if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                    const thisPlayerDict = this.state.formPlayerDict[playerId];
                    const newRow = 
                        <tr key={playerId}>
                            <td className="align-middle">
                                <div onClick={()=>this.showUserProfile(playerId)}>
                                    <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                    {this.state.usersCache[playerId].username}
                                    {this.state.usersCache[playerId].short_name &&
                                    <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                                </div>
                            </td>
                            <td className="text-center align-middle"><Form.Control size='sm' type="number" value={thisPlayerDict.score2} disabled={isUpdating} onChange={(e)=>this.onChangeScore(e, playerId, 2)} onSelect={(e)=>this.onSelectScore(e, playerId, 2)}/></td>
                        </tr>
                    selectableTableRows2.push(newRow);
                }
            }
            if (topicData.scores_recorded >= 3) {
                for (const playerId of this.state.formPlayerIds) {
                    if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                    const thisPlayerDict = this.state.formPlayerDict[playerId];
                    const newRow = 
                        <tr key={playerId}>
                            <td className="align-middle">
                                <div onClick={()=>this.showUserProfile(playerId)}>
                                    <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                    {this.state.usersCache[playerId].username}
                                    {this.state.usersCache[playerId].short_name &&
                                    <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                                </div>
                            </td>
                            <td className="text-center align-middle"><Form.Control size='sm' type="number" value={thisPlayerDict.score3} disabled={isUpdating} onChange={(e)=>this.onChangeScore(e, playerId, 3)} onSelect={(e)=>this.onSelectScore(e, playerId, 3)}/></td>
                        </tr>
                    selectableTableRows3.push(newRow);
                }
            }
        }

        // generate the factions table
        const factionTableRows = [];
        let showFactionSelection = false;
        if (topicData !== null && topicData.collect_game_stats && topicData.game_player_has_faction && this.state.centerSectionMode === 'scores') {
            const factionDict = topicData.getFactionDict();
            const factionOptions = [];
            if (!topicData.game_player_multi_faction) {
                const notsetOptionItem = <option key={'notset'} value={'notset'}>No {capitalizeFirstLetters(factionNoun)} Set</option>
                factionOptions.push(notsetOptionItem);
            }
            for (const [factionId, factionName] of Object.entries(factionDict)) {
                const optionItem = <option key={factionId} value={factionId}>{factionName}</option>
                factionOptions.push(optionItem);
                showFactionSelection = true;
            }

            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                let thisPlayerFactionList = [];
                if (topicData.game_player_multi_faction) {
                    if (thisPlayerDict.faction !== null) {
                        thisPlayerFactionList = thisPlayerDict.faction.split(',');
                    }
                }
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle">
                            {!topicData.game_player_multi_faction &&
                            <Form.Select size='sm' onChange={(e)=>this.onChangePlayerFaction(e, playerId)} value={thisPlayerDict.faction || 'notset'} disabled={isUpdating}>
                                {factionOptions}
                            </Form.Select>}
                            {topicData.game_player_multi_faction &&
                            <Form.Select size='sm' multiple={true} onChange={(e)=>this.onChangePlayerFactionMulti(e, playerId)} value={thisPlayerFactionList} disabled={isUpdating}>
                                {factionOptions}
                            </Form.Select>}
                        </td>
                    </tr>
                factionTableRows.push(newRow);
            }
        }

        // generate the points table
        const pointsTableRows = [];
        if (topicData !== null && topicData.collect_game_stats && topicData.game_player_has_points && this.state.centerSectionMode === 'scores') {
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle" style={{width: '80px'}}>
                            <Form.Control size='sm' type="number" placeholder={`${this.state.formGamePoints}?`} value={thisPlayerDict.list_points || ''} disabled={isUpdating} onChange={(e)=>this.onChangePlayerListPoints(e, playerId)} min={0}/>
                        </td>
                    </tr>
                pointsTableRows.push(newRow);
            }
        }

        // create the next, prev, and add buttons
        let nextButton = null;
        let addButton = null;
        let prevButton = null;
        let disableNext = true;

        switch (this.state.centerSectionMode) {
            case 'leagues':
                if (this.state.leagueId !== null) disableNext = false;
                nextButton = <Button size='sm' variant='primary' onClick={()=>this.onSwitchView('players')} disabled={isUpdating || disableNext}>Next</Button>
                break;
            
            case 'players':
                if (this.state.formPlayerIds.length > 0) disableNext = false;
                nextButton = <Button className='float-end' size='sm' variant='primary' onClick={()=>this.onSwitchView('details')} disabled={isUpdating || disableNext}>Next</Button>
                prevButton = <Button className='float-start' size='sm' variant='primary' onClick={()=>this.onSwitchView('leagues')} disabled={isUpdating}>Back</Button>
                break;

            case 'details':
                if (leagueData !== null && this.state.formPlayerIds.length > 0) disableNext = false;
                if (this.state.formCompletion === 'scheduled') {
                    addButton = <Button className='float-end' size='sm' variant='primary' onClick={()=>{this.onAddGameClicked()}} disabled={isUpdating || disableNext}>Add Game</Button>
                } else {
                    nextButton = <Button className='float-end' size='sm' variant='primary' onClick={()=>this.onSwitchView('scores')} disabled={isUpdating}>Next</Button>
                }
                prevButton = <Button className='float-start' size='sm' variant='primary' onClick={()=>this.onSwitchView('players')} disabled={isUpdating}>Back</Button>
                break;

            case 'scores':
                if (leagueData !== null && this.state.formPlayerIds.length > 0) disableNext = false;              
                addButton = <Button className='float-end' size='sm' variant='primary' onClick={()=>{this.onAddGameClicked()}} disabled={isUpdating || disableNext}>Add Game</Button>
                prevButton = <Button className='float-start' size='sm' variant='primary' onClick={()=>this.onSwitchView('details')} disabled={isUpdating}>Back</Button>
                break;
            
            default:
                console.error(`got invalid centerSectionMode: ${this.state.centerSectionMode}`);
        }

        // figure out which parts of the league/players/scores/details toggle are disabled
        let disablePlayers = true;
        let disableScores = true;
        let disableDetails = true;

        if (this.state.leagueId !== null) {
            disablePlayers = false;
            if (this.state.formPlayerIds.length > 0) {
                disableScores = false;
                disableDetails = false;
            }
        }

        if (this.state.formCompletion === 'scheduled') {
            disableScores = true;
        }
        
        // generate the text that goes under the scheduled/inprogress/completed slider
        let completionTitle = null;
        let completionText = null;
        switch (this.state.formCompletion) {
            case "scheduled":
            case 0:
                completionTitle = 'Scheduled';
                completionText = 'This game has not been started (even if the scheduled day is in the past).';
                break;
            case "in-progress":
            case 1:
                completionTitle = 'In-Progress';
                completionText = 'This game is being played, or is just about to begin.';
                break;
            case "completed":
            case 2:
                completionTitle = 'Completed';
                completionText = 'The game is over, and the details entered above are the final outcome (but may need review).';
                break;
            default:
                console.error(`got invalid formCompletion value: ${this.state.formCompletion}`);
                completionTitle = 'Scheduled';
                completionText = 'This game has not been started (even if the scheduled day is in the past).';
        }
        
        if (this.state.showUserProfileModal) {
            return (
                <NerdHerderUserProfileModal userId={this.state.selectedUserIdForProfileModal}
                                            localUser={this.props.localUser} 
                                            onCancel={()=>this.hideUserProfile()}/>
            );
        }

        if (this.state.showAddNewBoardGameModal) {
            return (
                <NerdHerderNewBoardGameModal localUser={this.props.localUser}
                                             league={this.state.league}
                                             onCancel={(v)=>this.hideAddNewBoardGameModal(v)}/>
            );
        }

        let score1Help = null;
        let score2Help = null;
        let score3Help = null;
        let pointsHelp = null;
        let playerPointsHelp = null;
        if (topicData) {
            if (topicData.scores_recorded >= 1 && topicData.score1_help) {
                score1Help = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={capitalizeFirstLetters(topicData.score1_noun)} message={topicData.score1_help} placement='bottom'/></span>
            }
            if (topicData.scores_recorded >= 2 && topicData.score2_help) {
                score2Help = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={capitalizeFirstLetters(topicData.score2_noun)} message={topicData.score2_help} placement='bottom'/></span>
            }
            if (topicData.scores_recorded >= 3 && topicData.score3_help) {
                score3Help = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={capitalizeFirstLetters(topicData.score3_noun)} message={topicData.score3_help} placement='bottom'/></span>
            }
            if (topicData.game_has_points_help) {
                pointsHelp = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={`${capitalizeFirstLetters(pointsNoun)} Level`} message={topicData.game_has_points_help} placement='bottom'/></span>
            }
            if (topicData.game_player_has_points_help) {
                playerPointsHelp = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={`${capitalizeFirstLetters(listNoun)} ${capitalizeFirstLetters(pointsNoun)}`} message={topicData.game_player_has_points_help} placement='bottom'/></span>
            }
        }

        let disableEventSelector = false;
        if (!isLeagueManager && this.state.eventGameLocked) disableEventSelector = true;

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal backdrop='static' scrollable={true} show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Game</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className='mb-2'>
                                    <div className='d-grid gap-2'>
                                        <ToggleButtonGroup size='sm' name='add-game-view' type="radio" value={this.state.centerSectionMode} onChange={(event)=>this.onSwitchView(event)}>
                                            {!this.state.leagueContext &&
                                            <ToggleButton variant='outline-primary' id='toggle-leagues-view' value={'leagues'}>League</ToggleButton>}
                                            <ToggleButton variant='outline-primary' id='toggle-players-view' value={'players'} disabled={disablePlayers}>Players</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-details-view' value={'details'} disabled={disableDetails}>Details</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-scores-view'  value={'scores'}  disabled={disableScores}>Scores</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        {errorFeedback}

                        <Form>
                            {this.state.centerSectionMode==='leagues' &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <b>This game was played in which league?</b>
                                        {selectableTableRows}
                                    </Form.Group>
                                </Col>
                            </Row>}

                            {this.state.centerSectionMode==='players' &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <b>Select the players for this game...</b>
                                        {selectableTableRows}
                                    </Form.Group>
                                </Col>
                            </Row>}

                            {this.state.centerSectionMode==='scores' &&
                            <div>
                                {selectableTableRows.length === 0 &&
                                <div>
                                    <b>This game has no players, you should add some!</b>
                                </div>}
                                {selectableTableRows.length !== 0 &&
                                <div>
                                    <b>Set the Scores and Winners...</b>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Table responsive size="sm" className='mb-1'>
                                                    <thead>
                                                        {topicData.score1_noun === 'score' &&
                                                        <tr>
                                                            <th>Player</th>
                                                            <th className='text-center' style={{width:'80px'}}>Score</th>
                                                            <th className='text-center' style={{width:'40px'}}>Won</th>
                                                        </tr>}
                                                        {topicData.score1_noun !== 'score' &&
                                                        <tr>
                                                            <th className='text-capitalize' >{topicData.score1_noun}{score1Help}</th>
                                                            <th className='text-center' style={{width:'80px'}}></th>
                                                            <th className='text-center' style={{width:'40px'}}>Won</th>
                                                        </tr>}
                                                    </thead>
                                                    <tbody>
                                                        {selectableTableRows}
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {selectableTableRows.length !== 0 && topicData && topicData.scores_recorded >= 2 &&
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Table responsive size="sm" className='mb-1'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-capitalize'>{topicData.score2_noun}{score2Help}</th>
                                                            <th style={{width:'80px'}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectableTableRows2}
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Col>
                                    </Row>}
                                    {selectableTableRows.length !== 0 && topicData && topicData.scores_recorded >= 3 &&
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Table responsive size="sm" className='mb-1'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-capitalize'>{topicData.score3_noun}{score3Help}</th>
                                                            <th style={{width:'80px'}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectableTableRows3}
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Col>
                                    </Row>}
                                </div>}
                                {selectableTableRows.length !== 0 && topicData && topicData.collect_game_stats && (showFactionSelection || topicData.game_player_has_points) &&
                                <div>
                                    <b>Optionally set Player Stats...</b>
                                </div>}
                                {selectableTableRows.length !== 0 && topicData && topicData.collect_game_stats && showFactionSelection &&
                                <div>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Table responsive size="sm" className='mb-1'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-capitalize'>{factionNoun}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {factionTableRows}
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>}
                                {selectableTableRows.length !== 0 && topicData && listContainers &&
                                <div>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Table responsive size="sm" className='mb-1'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-capitalize'>Player {listNounPlural}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listsTableRows}
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>}
                                {selectableTableRows.length !== 0 && topicData && topicData.collect_game_stats && topicData.game_player_has_points &&
                                <div>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Table responsive size="sm" className='mb-1'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-capitalize'>{listNoun} {pointsNoun}{playerPointsHelp}</th>
                                                            <th className='text-center' style={{width:'80px'}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pointsTableRows}
                                                    </tbody>
                                                </Table>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>}
                            </div>}
                            
                            {this.state.centerSectionMode==='details' &&
                            <div>
                                {this.state.isBoardGameLeague &&
                                <div>
                                    <b>What game did you play?</b>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Form.Select size='sm' onChange={(event)=>this.handleFormBoardGame(event)} value={this.state.formBoardGameId} disabled={isUpdating}>
                                                    {boardGameOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>}
                                {topicData && topicData.collect_game_stats &&
                                <div>
                                    <b>Optionally add Game stats...</b>
                                    <Row>
                                        {topicData.game_has_first_player &&
                                        <Col xs={6}>
                                            <Form.Label className='mb-0'><small><b>{capitalizeFirstLetters(firstPlayerNoun)} Player</b></small></Form.Label>
                                            <Form.Group className='mb-2'>
                                                <Form.Select size='sm' onChange={(event)=>this.handleFormFirstPlayer(event)} value={this.state.formFirstPlayer} disabled={isUpdating}>
                                                    {firstPlayerOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>}
                                        {topicData.game_has_points &&
                                        <Col xs={6}>
                                            <Form.Label className='mb-0'><small><b>{capitalizeFirstLetters(pointsNoun)} Level{pointsHelp}</b></small></Form.Label>
                                            <Form.Group className='mb-2'>
                                                <Form.Control size='sm' type="number" onChange={(event)=>this.handleFormGamePoints(event)} placeholder={`${capitalizeFirstLetters(pointsNoun)} Level?`} value={this.state.formGamePoints} disabled={isUpdating} min={0}/>
                                            </Form.Group>
                                        </Col>}
                                    </Row>
                                </div>}
                                <div>
                                    <b>Fill in relevant details...</b>
                                    {eventOptions.length === 0 &&
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Form.Control type="date" onChange={(event)=>this.handleFormDate(event)} value={this.state.formDate} disabled={isUpdating} required/>
                                            </Form.Group>
                                        </Col>
                                    </Row>}
                                    {eventOptions.length !== 0 &&
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group className='mb-2'>
                                                <Form.Control size='sm' type="date" onChange={(event)=>this.handleFormDate(event)} value={this.state.formDate} disabled={isUpdating} required/>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group className='mb-2'>
                                                <Form.Select size='sm' onChange={(event)=>this.handleFormEvent(event)} value={this.state.formEventId} disabled={isUpdating || disableEventSelector}>
                                                    {eventOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>}

                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className='mb-2'>
                                                <Form.Control as="textarea" rows={3} onChange={(event)=>this.handleFormDetails(event)} placeholder='(Optional) Add any details relevant to the game' value={this.state.formDetails} disabled={isUpdating} maxLength={maxDetailsLength}/>
                                                <Form.Text className="text-muted">
                                                    <small className='float-end'>{`${this.state.formDetails.length}/${maxDetailsLength}`}</small>
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12}>
                                            <b>Set the game state...</b>
                                            <Form.Group className='mb-2'>
                                                <div className='d-grid gap-2'>
                                                    <ToggleButtonGroup size='sm' name='game-completion' type="radio" value={this.state.formCompletion} onChange={(event)=>this.handleFormCompletion(event)}>
                                                        <ToggleButton variant='outline-primary' id='toggle-scheduled' value={'scheduled'} disabled={isUpdating}>Scheduled</ToggleButton>
                                                        <ToggleButton variant='outline-primary' id='toggle-in-progress' value={'in-progress'} disabled={isUpdating}>In-Progress</ToggleButton>
                                                        <ToggleButton variant='outline-primary' id='toggle-completed' value={'completed'} disabled={isUpdating}>Completed</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                                <div>
                                                    <Form.Text>
                                                        <p><small>
                                                            <b>{completionTitle}</b> - {completionText}
                                                        </small></p>
                                                    </Form.Text>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </div>}

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        {prevButton}
                        {nextButton}
                        {addButton}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

class NerdHerderNoLeaguesAddGameModalInner extends React.Component {
    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered scrollable={true} show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>No Leagues</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Unfortunately, you are not part of any league, tournament, or event where a game can be added.</p>
                        <p>This may mean that your organizer hasn't properly setup their event (or they have disabled adding games), or you need to join one to get some games in!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderPollResultsModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderPollResultsModal'>
                <NerdHerderPollResultsModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

export class NerdHerderPollResultsModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.pollId === 'undefined') console.error('missing props.pollId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            poll: null,
            results: null,
            resultsShown: false,
            votersShown: false
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        const sub = this.restPubSub.subscribeNoRefresh('poll', this.props.pollId, (d, k)=>this.updatePoll(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updatePoll(pollData, key) {
        const newPoll = NerdHerderDataModelFactory('poll', pollData);
        let votersShown = newPoll.canShowVoters();
        let resultsShown = newPoll.canShowResults(this.props.localUser.id);
        // managers can always see the results
        if (this.props.league.isManager(this.props.localUser.id)) {
            votersShown = true;
            resultsShown = true;
        }

        this.setState({poll: newPoll,
                       results: newPoll.results,
                       votersShown: votersShown,
                       resultsShown: resultsShown});
    }

    render() {
        if (!this.state.poll) return(<NerdHerderLoadingModal/>);

        const pollOptionItems = [];
        for (const optionId of this.state.poll.option_ids) {
            const voteCount = this.state.results[optionId].length;
            const userListItems = [];
            // only add the voters if enabled by the managers
            if (this.state.resultsShown && this.state.votersShown) {
                for (const userId of this.state.results[optionId]) {
                    const keyValue = `k-${optionId}-${userId}`
                    const userListItem = <UserListItem key={keyValue} slim={true} userId={userId} localUser={this.props.localUser}/>
                    userListItems.push(userListItem);
                }
            }
            const optionItem = 
                <PollOptionListItem key={optionId} pollOptionId={optionId} poll={this.state.poll} localUser={this.props.localUser}>
                    {this.state.resultsShown &&
                    <b className='mt-2'>{`Total Votes: ${voteCount}`}</b>}
                    {userListItems}
                </PollOptionListItem>
            pollOptionItems.push(optionItem);
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Poll Detailed Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div><b>{this.state.poll.title}</b></div>
                        {this.state.poll.text &&
                        <small>{this.state.poll.text}</small>}
                        {pollOptionItems}
                        {this.props.children}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderEditEventModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderEditEventModal'>
                <NerdHerderEditEventModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderEditEventModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.eventId === 'undefined') console.error('missing props.eventId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // minor differences in how the modal is presented if we are editing an existing poll or creating new
        this.createPresentation = false;
        if (this.props.eventId === null) {
            this.createPresentation = true;
        }

        const messageIds = []
        for (const messageSnippet of this.props.league.message_tree) {
            if (messageSnippet.state === 'sent' && messageSnippet.type === 'text') {
                messageIds.push(messageSnippet.id);
            }
        }

        this.state = {
            updating: false,
            onUpdateCheckPlayers: false,
            onUpdateCompleteCancel: false,
            eventId: this.props.eventId,
            event: null,
            messageIds: messageIds,

            name: '',
            summary: '',
            startDate: '',
            endDate: '',
            eventState: null,
            topPostId: null,
            allLeaguePlayers: true,
            playerIds: [],

            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        // sub to the league in case there is a new message posted to become the top post
        let sub = this.restPubSub.subscribeSilently('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);

        if (this.state.eventId !== null) {
            sub = this.restPubSub.subscribe('event', this.state.eventId, (d, k)=>this.updateEvent(d, k), (e, k)=>this.formUpdateError(e, k));
            this.restPubSubPool.add(sub);
        } else {
            const newEvent = NerdHerderDataModelFactory('event', null, this.props.league.id);
            this.setState({
                event: newEvent,
                name: newEvent.name,
                summary: newEvent.summary,
                startDate: '',
                endDate: '',
                allLeaguePlayers: newEvent.all_league_players,
                topPostId: newEvent.top_post_id,
                eventState: newEvent.state,
            });
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateEvent(eventData, key) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        let startDate = newEvent.start_date;
        if (startDate === null) startDate = '';
        let endDate = newEvent.end_date;
        if (endDate === null) endDate = '';

        const newMessageIdList = []
        for (const messageSnippet of newEvent.message_tree) {
            if (messageSnippet.state === 'sent' && messageSnippet.type === 'text') {
                newMessageIdList.push(messageSnippet.id);
            }
        }

        this.setState({event: newEvent,
                       eventId: newEvent.id,
                       name: newEvent.name,
                       summary: newEvent.summary,
                       startDate: startDate,
                       endDate: endDate,
                       eventState: newEvent.state,
                       allLeaguePlayers: newEvent.all_league_players,
                       messageIds: newMessageIdList,
                       topPostId: newEvent.top_post_id});

        // if this is an update where we need to evaluate the players on the client vs server - do that (but not on the update thread)
        if (this.state.onUpdateCheckPlayers) {
            setTimeout(()=>this.onCheckPlayers(), 500);
        }
        // but if this is a regular update, just take what the server has as our player ids for this league
        else {
            this.setState({playerIds: [...newEvent.player_ids]});
        }

        // if that was the final expected update just cancel
        if (this.state.onUpdateCompleteCancel) {
            undoOnBackCancelModal(); 
            this.props.onCancel();
        }

        if (this.state.onUpdateCheckPlayers === false && this.state.onUpdateCheckPlayers === false) {
            this.setState({updating: false});
        }
        
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    onSave() {
        this.setState({onUpdateCheckPlayers: true});
        if (this.state.eventId === null) {
            this.onAdd()
        } else {
            this.onUpdate();
        }
    }

    onUpdate() {
        this.setState({updating: true});
        let startDate = this.state.startDate
        if (startDate.length === 0) startDate = null;
        let endDate = this.state.endDate
        if (endDate.length === 0) endDate = null;
        const putData = {
            name: this.state.name.trimEnd(),
            summary: this.state.summary.trimEnd(),
            state: this.state.eventState,
            order: 1,
            start_date: startDate,
            end_date: endDate,
            all_league_players: this.state.allLeaguePlayers,
            top_post_id: this.state.topPostId,
        }
        this.restPubSub.put('event', this.state.eventId, putData);
    }

    onAdd() {
        this.setState({updating: true});
        let startDate = this.state.startDate
        if (startDate.length === 0) startDate = null;
        let endDate = this.state.endDate
        if (endDate.length === 0) endDate = null;
        const postData = {
            name: this.state.name.trimEnd(),
            league_id: this.props.league.id,
            summary: this.state.summary.trimEnd(),
            state: this.state.eventState,
            order: 1,
            start_date: startDate,
            end_date: endDate,
            all_league_players: this.state.allLeaguePlayers,
            top_post_id: this.state.topPostId,
        }
        const sub = this.restPubSub.postAndSubscribe('event', 'id', postData, (d, k)=>this.updateEvent(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    onCheckPlayers() {
        this.playerAddMessagesSent = 0;
        this.playerAddMessagesRecv = 0;
        this.playerDelMessagesSent = 0;
        this.playerDelMessagesRecv = 0;

        // the server automatically takes care of all_league_players - so we don't have to do anything if that is the setting
        if (this.state.allLeaguePlayers) {
            this.setState({onUpdateCheckPlayers: false, onUpdateCompleteCancel: true});
            this.restPubSub.refresh('event', this.state.eventId);
        } else {
            // for any player that has been added, send a POST to add them
            for (const playerId of this.state.playerIds) {
                if (!this.state.event.player_ids.includes(playerId)) {
                    this.playerAddMessagesSent++;
                    const queryParams = {'user-id': playerId, 'event-id': this.state.eventId};
                    this.restApi.genericPostEndpointData('user-event', null, {user_id: playerId, event_id: this.state.eventId}, queryParams)
                    .then((response)=>{
                        this.playerAddMessagesRecv++;
                        if (this.playerAddMessagesRecv === this.playerAddMessagesSent &&
                            this.playerDelMessagesRecv === this.playerDelMessagesSent) {
                                this.setState({onUpdateCheckPlayers: false, onUpdateCompleteCancel: true});
                                this.restPubSub.refresh('event', this.state.eventId, 200);
                            }
                    })
                    .catch((error)=>{
                        console.error(error);
                        this.setState({updating: false});
                    })
                }
            }
            // for any player that has been removed, send a DELETE to remove them
            for (const playerId of this.state.event.player_ids) {
                if (!this.state.playerIds.includes(playerId)) {
                    this.playerDelMessagesSent++;
                    const queryParams = {'user-id': playerId, 'event-id': this.state.eventId};
                    this.restApi.genericDeleteEndpointData('user-event', null, queryParams)
                    .then((response)=>{
                        this.playerDelMessagesRecv++;
                        if (this.playerAddMessagesRecv === this.playerAddMessagesSent &&
                            this.playerDelMessagesRecv === this.playerDelMessagesSent) {
                                this.setState({onUpdateCheckPlayers: false, onUpdateCompleteCancel: true});
                                this.restPubSub.refresh('event', this.state.eventId, 200);
                            }
                    })
                    .catch((error)=>{
                        console.error(error);
                        this.setState({updating: false});
                    })
                }
            }
            // if there are not player changes, just move on
            if (this.playerAddMessagesSent === 0 && this.playerDelMessagesSent === 0) {
                this.setState({onUpdateCheckPlayers: false, onUpdateCompleteCancel: true});
                this.restPubSub.refresh('event', this.state.eventId);
            }
        }
    }

    onDelete() {
        this.restPubSub.delete('event', this.state.eventId);
        undoOnBackCancelModal(); 
        this.props.onCancel();
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 4) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this name is too short');
        }
        this.setState({name: value, formErrors: errorState});
    }

    handleSummaryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('summary', {...this.state.formErrors});
        this.setState({summary: value, formErrors: errorState});
    }

    handleStartDateChange(event) {
        const value = event.target.value;
        let errorState = clearErrorState('start_date', {...this.state.formErrors});
        this.setState({startDate: value, formErrors: errorState});
        // do some checking - if the end date is not set, set it now
        if (this.state.endDate === '') {
            this.setState({endDate: value});
        }
        // and if the end date is before the new start date, set the start date as the end date
        else {
            const startDateObj = new Date(value);
            const endDateObj = new Date(this.state.endDate);
            if (startDateObj > endDateObj) this.setState({endDate: value});
        }
    }

    handleEndDateChange(event) {
        const value = event.target.value;
        let errorState = clearErrorState('end_date', {...this.state.formErrors});
        this.setState({endDate: value, formErrors: errorState});
    }

    handleStateChange(value) {
        let errorState = clearErrorState('state', {...this.state.formErrors});
        this.setState({eventState: value, formErrors: errorState});
    }

    handleTopPostIdChange(value) {
        this.setState({topPostId: value});
    }

    handleAllLeaguePlayersChange(value) {
        let errorState = clearErrorState('all_league_players', {...this.state.formErrors});
        this.setState({allLeaguePlayers: value, formErrors: errorState, playerIds: [...this.props.league.player_ids]});
    }

    handleIndividualPlayersChange(playerId, event) {
        const checked = event.target.checked;
        const newPlayerIds = [...this.state.playerIds];
        if (checked) {
            newPlayerIds.push(playerId);
        } else {
            for (let i=0; i<this.state.playerIds.length; i++) {
                if (newPlayerIds[i] === playerId) {
                    newPlayerIds.splice(i, 1);
                    break;
                }
            }
        }
        this.setState({playerIds: newPlayerIds});
    }

    render() {
        if (!this.state.event) return(<NerdHerderLoadingModal/>);

        const maxSummaryLength = 200;

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // the default state is that the user cannot save, but can add options
        let disableUpdateButton = true;
        // if the form has been modified from what is on the server, allow the user to save
        if (this.state.eventId && !this.createPresentation) {
            if (this.state.name !== this.state.event.name) disableUpdateButton = false;
            if (this.state.summary !== this.state.event.summary) disableUpdateButton = false;
            if (this.state.event.start_date === null && this.state.startDate !== '') disableUpdateButton = false;
            if (this.state.event.start_date !== null && this.state.startDate !== this.state.event.start_date) disableUpdateButton = false;
            if (this.state.event.end_date === null && this.state.endDate !== '') disableUpdateButton = false;
            if (this.state.event.end_date !== null && this.state.endDate !== this.state.event.end_date) disableUpdateButton = false;
            if (this.state.eventState !== this.state.event.state) disableUpdateButton = false;
            if (this.state.topPostId !== this.state.event.top_post_id) disableUpdateButton = false;
            if (this.state.allLeaguePlayers !== this.state.event.all_league_players) disableUpdateButton = false;
            
            // if the number of players is different than the server, can save...
            // ...or if the number is the same but an id is different then can also save
            if (this.state.allLeaguePlayers === false) {
                if (this.state.playerIds.length !== this.state.event.player_ids.length) {
                    disableUpdateButton = false;
                } else {
                    for (const statePlayerId of this.state.playerIds) {
                        if (!this.state.event.player_ids.includes(statePlayerId)) {
                            disableUpdateButton = false;
                            break;
                        }
                    }
                }
            }
        }
        // or if the form is not on the server yet (it's a new poll) the user can save
        else {
            disableUpdateButton = false;
        }
        // cannot save if the title is too short
        if (this.state.name.length < 4) {
            disableUpdateButton = true;
        }
        // cannot save if the summary is too short
        if (this.state.summary.length < 4) {
            disableUpdateButton = true;
        }
        // cannot save if the form has errors
        if (hasFormErrors) {
            disableUpdateButton = true;
        }
        // cannot save while a save is in progress
        if (this.state.updating) {
            disableUpdateButton = true;
        }

        let borderSelectedClass = null;
        if (this.state.topPostId === null) borderSelectedClass = 'border-selected-primary';
        const nullTopPost =
            <div key={'no-top-post'} className={`my-1 list-group-item list-group-item-action rounded align-middle ${borderSelectedClass}`} onClick={()=>this.handleTopPostIdChange(null)}>
                <Row className='align-items-center'>
                    <Col className='pe-0' xs='auto'>
                        <Image className="rounded-circle" alt="user icon" height={25} width={25} src={getStaticStorageImageFilePublicUrl('/not_allowed.png')}/>
                    </Col>
                    <Col className='ps-2'>
                        <b>No Minor Event Top Post Selected</b>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                            <small>{"No top post is set. Select this item to clear the event's top post (if any)."}</small>
                    </Col>
                </Row>
            </div>

        const messageCards = [nullTopPost];
        for (const messageId of this.state.messageIds) {
            let isSelected = false;
            // eslint-disable-next-line eqeqeq
            if (messageId == this.state.topPostId) {
                isSelected = true;
            }
            const item = <NerdHerderLeaguePostSnippet key={messageId} messageId={messageId} selected={isSelected} localUser={this.props.localUser} onClick={()=>this.handleTopPostIdChange(messageId)}/>
            messageCards.push(item);
        }

        const playerListItems = [];
        for (const playerId of this.props.league.player_ids) {
            const playerListItem = 
                <Row key={playerId} className='align-items-center'>
                    <Col xs='auto' className='pe-0'>
                        <Form.Check disabled={this.state.updating || this.state.allLeaguePlayers} onChange={(e)=>this.handleIndividualPlayersChange(playerId, e)} autoComplete='off' checked={this.state.playerIds.includes(playerId)}/>
                    </Col>
                    <Col>
                        <UserListItem slim={true} userId={playerId} localUser={this.props.localUser}/>
                    </Col>
                </Row>
            playerListItems.push(playerListItem);
        }

        // figure out the min and max days for startDate and endDate - default is +/- 1 year from now
        let todayDate = new Date();
        let minStartDate = new Date();
        let maxStartDate = new Date();
        let minEndDate = new Date();
        let maxEndDate = new Date();
        maxStartDate.setFullYear(todayDate.getFullYear() + 1);
        maxEndDate.setFullYear(todayDate.getFullYear() + 1);
        // if the league has a start date set - set the end date limits based on that
        if (this.props.league.start_date) {
            minStartDate = this.props.league.start_date;
            minEndDate = this.props.league.start_date;
        }
        // if the league has a end date set - set the end date limits based on that
        if (this.props.league.start_date) {
            maxStartDate = this.props.league.end_date;
            maxEndDate = this.props.league.end_date;
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        {this.createPresentation &&
                        <Modal.Title>New Minor Event</Modal.Title>}
                        {!this.createPresentation &&
                        <Modal.Title>Edit Minor Event</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Name<Required/></Form.Label>
                                <Form.Control id='name' name='name' type="text" placeholder="Name this event..." disabled={this.state.updating} onChange={(e)=>this.handleNameChange(e)} autoComplete='off' value={this.state.name} minLength={4} maxLength={45} required/>
                                <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Summary<Required/></Form.Label>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='summary' name='summary' as="textarea" rows={5} placeholder='Add a summary for this event...' disabled={this.state.updating} onChange={(e)=>this.handleSummaryChange(e)} value={this.state.summary} maxLength={maxSummaryLength}/>
                                    <FormTextInputLimit current={this.state.summary.length} max={maxSummaryLength}/>
                                </div>
                                <FormErrorText errorId='text' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control id="start_date" name="start_date" type="date" disabled={this.state.updating} onChange={(e)=>this.handleStartDateChange(e)} autoComplete='off' value={this.state.startDate} min={minStartDate} max={maxStartDate}/>
                                <FormErrorText errorId='start_date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>Highly Recommended: set a start date.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3"> 
                                <Form.Label>End Date</Form.Label>
                                <Form.Control id="end_date" name="end_date" type="date" disabled={this.state.updating} onChange={(e)=>this.handleEndDateChange(e)} autoComplete='off' value={this.state.endDate} min={minEndDate} max={maxEndDate}/>
                                <FormErrorText errorId='end_date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>Highly Recommended: set an end date.</Form.Text>
                            </Form.Group>
                            {!this.createPresentation &&<Form.Group className="form-outline mb-3">
                                <Form.Label>Select Top Post</Form.Label>
                                <br/>
                                <Form.Text muted>
                                    Optional: set a top post that contains details of the minor event. <i>Creating an event post can be accomplished on the minor event's main page.</i></Form.Text>
                                <NerdHerderVerticalScroller maxHeight={500}>
                                    {messageCards}
                                </NerdHerderVerticalScroller>
                            </Form.Group>}
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>The minor event includes...</Form.Label>
                                <div className='d-grid gap-2'>     
                                    <ToggleButtonGroup size='sm' name='all-league-players' type="radio" value={this.state.allLeaguePlayers} onChange={(v)=>this.handleAllLeaguePlayersChange(v)}>
                                        <ToggleButton variant='outline-primary' id='toggle-players-true' value={true}>Everyone</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-players-false' value={false}>Selected Players</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.allLeaguePlayers === true &&
                                <Form.Text className='text-muted'><b>Everyone</b> - everyone in the {this.props.league.getTypeWord()} is part of this minor event and can see its details.</Form.Text>}
                                {this.state.allLeaguePlayers === false &&
                                <Form.Text className='text-muted'><b>Selected</b> - only the selected players (from the {this.props.league.getTypeWord()}) are able to view the event's details.</Form.Text>}
                                <Collapse in={this.state.allLeaguePlayers === false}>
                                    <div>
                                        {playerListItems}
                                    </div>
                                </Collapse>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>This minor event is...</Form.Label>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='event-state' type="radio" value={this.state.eventState} onChange={(v)=>this.handleStateChange(v)}>
                                        <ToggleButton variant='outline-primary' id='toggle-state-draft' value={'draft'}>A Draft</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-state-posted' value={'posted'}>Posted</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.eventState === 'draft' &&
                                <Form.Text className='text-muted'><b>A Draft</b> - hidden from players. Get your event all set up or keep it a secret in this state.</Form.Text>}
                                {this.state.eventState === 'posted' &&
                                <Form.Text className='text-muted'><b>Posted</b> - visible to the players in the minor event.</Form.Text>}
                            </Form.Group>
                        </Form>
                        {!this.createPresentation &&
                        <TripleDeleteButton label={'Delete Event'} onFinalClick={()=>this.onDelete()}/>}
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onSave()} disabled={disableUpdateButton}>{this.createPresentation ? 'Add Event' : 'Update'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderNewTournamentModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderNewTournamentModal'>
                <NerdHerderNewTournamentModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderNewTournamentModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.tournamentHasDefaults = this.props.league.topic.tournament_has_defaults;
        this.tournamentSuggestion = 'swiss';
        if (this.tournamentHasDefaults && ['swiss', 'round-robin', 'elimination'].includes(this.props.league.topic.tournament_suggestion)) {
            this.tournamentSuggestion = this.props.league.topic.tournament_suggestion;
        }
        this.defaultScoringType = 'mp';
        this.tournamentRanking = null;
        if (this.tournamentHasDefaults) {
            if (this.props.league.topic.tournament_ranking.startsWith('mp')) {
                this.defaultScoringType = 'mp';
            }
            else if (this.props.league.topic.tournament_ranking.startsWith('vp')) {
                this.defaultScoringType = 'vp';
            }
            else if (this.props.league.topic.tournament_ranking.startsWith('mov')) {
                this.defaultScoringType = 'mov';
            }
            this.tournamentRanking = this.props.league.topic.tournament_ranking;
            if (this.tournamentRanking === '') this.tournamentRanking = null;
        }
        this.defaultByeResults = 'w:3:0:0:0:0:0:0';
        if (this.tournamentHasDefaults) {
            const byeVps1 = this.props.league.topic.tournament_bye_vps1;
            const byeLoserVps1 = this.props.league.topic.tournament_bye_loser_vps1;
            const byeVps2 = this.props.league.topic.tournament_bye_vps2;
            const byeLoserVps2 = this.props.league.topic.tournament_bye_loser_vps2;
            const byeVps3 = this.props.league.topic.tournament_bye_vps3;
            const byeLoserVps3 = this.props.league.topic.tournament_bye_loser_vps3;
            if (this.props.league.topic.tournament_bye_result === 'win') {
                this.defaultByeResults = `w:3:${byeVps1}:${byeLoserVps1}:${byeVps2}:${byeLoserVps2}:${byeVps3}:${byeLoserVps3}`;
            }
            else if (this.props.league.topic.tournament_bye_result === 'tie') {
                this.defaultByeResults = `t:1:${byeVps1}:${byeLoserVps1}:${byeVps2}:${byeLoserVps2}:${byeVps3}:${byeLoserVps3}`;
            }
            else if (this.props.league.topic.tournament_bye_result === 'loss') {
                this.defaultByeResults = `l:0:${byeVps1}:${byeLoserVps1}:${byeVps2}:${byeLoserVps2}:${byeVps3}:${byeLoserVps3}`;
            }
        }
        this.defaultSosMethod = 'average';
        if (this.tournamentHasDefaults) {
            this.defaultSosMethod = this.props.league.topic.tournament_sos_method;
        }

        // want to choose a default tournament date - pick league start date or today, whatever is later
        let defautTournamentDate = '';
        let luxonDate = DateTime.now();
        if (this.props.league.start_date) {
            let luxonStartDate = DateTime.fromISO(this.props.league.start_date);
            if (luxonStartDate > luxonDate) luxonDate = luxonStartDate;
        }
        defautTournamentDate = luxonDate.toISODate();

        let defaultTournamentName = '';
        if (this.props.league.type === 'tournament') {
            defaultTournamentName = 'Main Event';
        } else {
            defaultTournamentName = `${this.props.league.name} Tournament`;
            if (defaultTournamentName.length > 50) defaultTournamentName = '';
        }

        this.state = {
            navigateTo: null,
            updating: false,
            tournamentType: this.tournamentSuggestion,
            scoringType: this.defaultScoringType,
            eliminationType: 'double-elimination',
            tournamentName: defaultTournamentName,
            tournamentSummary: '',
            tournamentDate: defautTournamentDate,
            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, key) {
        // if we get a successful update, that means the post worked!
        this.setState({navigateTo: `/app/managetournament/${tournamentData.id}`});
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    onCreate() {
        let ranking = '';
        if (this.state.tournamentType === 'swiss' || this.state.tournamentType === 'round-robin') {
            // if we have defaults and the manager is sticking with them, use the default ranking
            if (this.tournamentHasDefaults && this.defaultScoringType === this.state.scoringType) {
                ranking = this.tournamentRanking;
            } else {
                if (this.state.scoringType === 'mp') ranking = 'mp>rec>sos>vp>mov';
                else if (this.state.scoringType === 'vp') ranking = 'vp>rec>sos>mov';
                else if (this.state.scoringType === 'mov') ranking = 'mov>rec>sos>vp';
            }
        }
        
        const postData =
            {
                type: this.state.tournamentType,
                name: this.state.tournamentName,
                summary: this.state.tournamentSummary.length === 0 ? null : this.state.tournamentSummary,
                state: "draft",
                ranking: ranking,
                sos_method: this.defaultSosMethod,
                num_rounds: 4,
                event_id: null,
                all_league_players: false,
                place_1_user_id: null,
                place_2_user_id: null,
                place_3_user_id: null,
                globally_ranked: true,
                require_checkin: false,
                bye_results: this.defaultByeResults,
                league_id: this.props.league.id,
                date: this.state.tournamentDate ? this.state.tournamentDate : null,
                subtype: this.state.tournamentType === 'elimination' ? this.state.eliminationType : this.state.scoringType,
                top_table_finals: false,
            };
        this.restPubSub.postAndSubscribe('tournament', 'id', postData, (d, k)=>this.updateTournament(d, k), (e, k)=>this.formUpdateError(e, k));
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this tournament name is too short');
        }
        this.setState({tournamentName: value, formErrors: errorState});
    }

    handleSummaryChange(event) {
        let value = event.target.value;
        this.setState({tournamentSummary: value});
    }

    handleDateChange(event) {
        let value = event.target.value;
        this.setState({tournamentDate: value});
    }

    handleTournamentTypeChange(value) {
        this.setState({tournamentType: value});
        if (value === 'round-robin') this.setState({scoringType: 'mp'});
    }

    handleEliminationTypeChange(value) {
        this.setState({eliminationType: value});
    }

    handleScoringTypeChange(value) {
        this.setState({scoringType: value});
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);
        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        let disableCreateButton = false;
        if (this.state.tournamentName.length < 6) disableCreateButton = true;
        if (this.state.updating) disableCreateButton = true;
        if (hasFormErrors) disableCreateButton = true;

        // figure out the min and max days for date - +/- 1 year and has to fit in the bounds of the league
        let todayDate = new Date();
        let minStartDate = new Date();
        let maxStartDate = new Date();
        minStartDate.setFullYear(todayDate.getFullYear() - 1);
        maxStartDate.setFullYear(todayDate.getFullYear() + 1);
        // if the league has a start/end date set - set the date limits based on that
        if (this.props.league.start_date) {
            minStartDate = this.props.league.start_date;
        }
        if (this.props.league.end_date) {
            maxStartDate = this.props.league.end_date;
        }
        
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Tournament</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>There are a lot of options when it comes tournaments. It's not hard to set one up or run one, but first we'll figure out these basics and create a draft tournament. Then you can edit that draft and off you go!</p>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Name<Required/></Form.Label>
                                <Form.Control id='name' name='name' type="text" placeholder="Name the tournament..." disabled={this.state.updating} onChange={(e)=>this.handleNameChange(e)} autoComplete='off' value={this.state.tournamentName} minLength={6} maxLength={50} required/>
                                <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                                <Form.Text muted>Pick a name for your tournament - you can change it later if desired.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Summary</Form.Label>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='summary' name='summary' as="textarea" rows={5} placeholder='Add a summary for the tournament...' disabled={this.state.updating} onChange={(e)=>this.handleSummaryChange(e)} value={this.state.tournamentSummary} maxLength={500}/>
                                    <FormTextInputLimit current={this.state.tournamentSummary.length} max={500}/>
                                </div>
                                <FormErrorText errorId='summary' errorState={this.state.formErrors}/>
                                <Form.Text muted>Optional: Add a summary for the tournament. This is the first thing the players see.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control id="date" name="date" type="date" disabled={this.state.updating} onChange={(e)=>this.handleDateChange(e)} autoComplete='off' value={this.state.tournamentDate} min={minStartDate} max={maxStartDate}/>
                                <FormErrorText errorId='date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>Optional: Add a start date - the end of the tournament is defined by the rounds.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                {this.tournamentHasDefaults &&
                                <Alert variant='primary'>NerdHerder has automatically set the recommended tournament configuration for {this.props.league.topic.id}</Alert>}
                                <Form.Label>
                                    What kind of tournament are you creating?
                                    <br/>
                                    <small className='text-danger'>This option cannot be changed after creating the tournament.</small>
                                </Form.Label>
                                <div className='d-grid gap-2'>     
                                    <ToggleButtonGroup size='sm' name='tournament-type' type="radio" value={this.state.tournamentType} onChange={(v)=>this.handleTournamentTypeChange(v)}>
                                        <ToggleButton variant='outline-primary' id='toggle-type-elimination' value={'elimination'}>Elimination</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-type-swiss' value={'swiss'}>Swiss</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-type-round-robin' value={'round-robin'}>Round-Robin</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.tournamentType === 'elimination' &&
                                <Form.Text className='text-muted'><b>Elimination</b> - what everyone thinks of when they imagine a tournament (regardless of activity). A bracket is generated and matches are played with losers being eliminated from the tournament. The last one standing is the champion. Only works well when the number of players is a power of 2 (2, 4, 8, 16, 32...).</Form.Text>}
                                {this.state.tournamentType === 'swiss' &&
                                <Form.Text className='text-muted'><b>Swiss</b> - a good choice to avoid eliminating players. The champion is determined through a points system (not all players will play each other). Works well when the number of players is higher than the desired number of rounds. This is the recommended option.</Form.Text>}
                                {this.state.tournamentType === 'round-robin' &&
                                <Form.Text className='text-muted'><b>Round-Robin</b> - no player elimination and each player will play every other player. The number of rounds will be one less than the number of players. A good option when the number of players is small or the tournament will strech over many weeks.</Form.Text>}
                            </Form.Group>
                            <Collapse in={this.state.tournamentType === 'elimination'}>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>
                                        What kind of elimination?
                                        <br/>
                                        <small className='text-danger'>This option cannot be changed after creating the tournament.</small>
                                    </Form.Label>
                                    <div className='d-grid gap-2'>     
                                        <ToggleButtonGroup size='sm' name='elimination-type' type="radio" value={this.state.eliminationType} onChange={(v)=>this.handleEliminationTypeChange(v)}>
                                            <ToggleButton variant='outline-primary' id='toggle-single-elimination' value={'single-elimination'}>Single Elimination</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-double-elimination' value={'double-elimination'}>Double Elimination</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    {this.state.eliminationType === 'single-elimination' &&
                                    <Form.Text className='text-muted'><b>Single</b> - A single loss eliminates a player from the tournament. Recommended when doing a 'top cut' from another tournament, otherwise discouraged.</Form.Text>}
                                    {this.state.eliminationType === 'double-elimination' &&
                                    <Form.Text className='text-muted'><b>Double</b> - There is a winners and losers bracket. All players begin in the winners bracket. A loss moves the player to the losers bracket. A player is eliminated when they lose a game in the losers bracket. This is the recommended option.</Form.Text>}
                                </Form.Group>
                            </Collapse>
                            <Collapse in={this.state.tournamentType === 'swiss' || this.state.tournamentType === 'round-robin'}>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>
                                        What kind of scoring method will be used?
                                        <br/>
                                        <small className='text-danger'>This option cannot be changed after creating the tournament.</small>
                                    </Form.Label>
                                    <div className='d-grid gap-2'>     
                                        <ToggleButtonGroup size='sm' name='scoring-type' type="radio" value={this.state.scoringType} onChange={(v)=>this.handleScoringTypeChange(v)}>
                                            <ToggleButton variant='outline-primary' id='toggle-scoring-mp' value={'mp'}>Match Points</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-scoring-vp' value={'vp'}>Victory Points</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-scoring-mov' value={'mov'}>Margin of Victory</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    {this.state.scoringType === 'mp' &&
                                    <Form.Text className='text-muted'><b>Match Points (MP)</b> - The scores or points of individual games are ignored. Players are assigned 3 MPs for a win, 1 MP for a tie, and 0 MP for a loss. <i>This option is highly recommended for round-robin tournaments.</i> It is also recommended for games with non-scoring win conditions (e.g. chess) or games where the win conditions can change (to remove win condition selection from the tournament outcome).</Form.Text>}
                                    {this.state.scoringType === 'vp' &&
                                    <Form.Text className='text-muted'><b>Victory Points (VP)</b> - The scores or points achieved by a player in each game are summed to determine player rank. This is the recommended option for games where there is little interaction between the players and the win conditions don't change (e.g. 'euro-style' games).</Form.Text>}
                                    {this.state.scoringType === 'mov' &&
                                    <Form.Text className='text-muted'><b>Margin of Victory (MoV)</b> - The difference between the winner and losers score are combined across all of a player's games. This is the recommended option for games where there is significant player conflict (e.g. wargames) and the win conditions don't change (e.g. most non-skirmish miniature wargames).</Form.Text>}
                                </Form.Group>
                            </Collapse>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onCreate()} disabled={disableCreateButton}>Create Tournament</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderEditTournamentModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderEditTournamentModal'>
                <NerdHerderEditTournamentModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderEditTournamentModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.tournament === 'undefined') console.error('missing props.tournament');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const tbResults = this.props.tournament.getByeResults();

        this.state = {
            navigateTo: null,
            updating: false,
            onUpdateCancel: false,
            events: {},
            showRoundsHelp: false,
            tournamentNumRounds: this.props.tournament.num_rounds,
            tournamentState: this.props.tournament.state,
            tournamentName: this.props.tournament.name,
            tournamentSummary: this.props.tournament.summary ? this.props.tournament.summary : '',
            tournamentDate: this.props.tournament.date ? this.props.tournament.date : '',
            tournamentEventId: this.props.tournament.event_id,
            tournamentByeRecord: tbResults.record,
            tournamentByeMatchPoints: tbResults.mp,
            tournamentByeVictoryPoints1: tbResults.vp1,
            tournamentByeLoserPoints1: tbResults.lp1,
            tournamentByeVictoryPoints2: tbResults.vp2,
            tournamentByeLoserPoints2: tbResults.lp2,
            tournamentByeVictoryPoints3: tbResults.vp3,
            tournamentByeLoserPoints3: tbResults.lp3,
            tournamentRanking: this.props.tournament.ranking,
            tournamentGloballyRanked: this.props.tournament.globally_ranked,
            tournamentTopTableFinals: this.props.tournament.top_table_finals,
            tournamentRequireCheckin: this.props.tournament.top_table_finals,
            tournamentSoSMethod: this.props.tournament.sos_method,
            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        let sub = this.restPubSub.subscribeNoRefresh('tournament', this.props.tournament.id, (d, k)=>this.updateTournament(d, k), (e, k)=>this.formUpdateError(e, k))
        this.restPubSubPool.add(sub);
        for (const eventId of this.props.league.event_ids) {
            sub = this.restPubSub.subscribeNoRefresh('event', eventId, (d, k) => {this.updateSelectableEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, key) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        const tbResults = newTournament.getByeResults();
        this.setState({
            updating: false,
            tournamentName: newTournament.name,
            tournamentState: this.props.tournament.state,
            tournamentSummary: newTournament.summary ? newTournament.summary : '',
            tournamentDate: newTournament.date ? newTournament.date : '',
            tournamentNumRounds: newTournament.num_rounds,
            tournamentEventId: newTournament.event_id ? newTournament.event_id : 0,
            tournamentByeRecord: tbResults.record,
            tournamentByeMatchPoints: tbResults.mp,
            tournamentByeVictoryPoints1: tbResults.vp1,
            tournamentByeLoserPoints1: tbResults.lp1,
            tournamentByeVictoryPoints2: tbResults.vp2,
            tournamentByeLoserPoints2: tbResults.lp2,
            tournamentByeVictoryPoints3: tbResults.vp3,
            tournamentByeLoserPoints3: tbResults.lp3,
            tournamentRanking: newTournament.ranking,
            tournamentGloballyRanked: newTournament.globally_ranked,
            tournamentTopTableFinals: newTournament.top_table_finals,
            tournamentRequireCheckin: newTournament.require_checkin,
            tournamentSoSMethod: newTournament.sos_method,
        });

        if (this.state.onUpdateCancel) {
            undoOnBackCancelModal();
            this.props.onCancel();
        }
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }
    
    updateSelectableEvent(eventData, eventId) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState((state) => {
            return {events: {...state.events, [eventId]: newEvent}}
        });
    }

    onUpdate() {
        this.setState({updating: true, onUpdateCancel: true});
        let eventId = parseInt(this.state.tournamentEventId);
        this.props.tournament.setByeResults(this.state.tournamentByeRecord, this.state.tournamentByeMatchPoints,
                                            this.state.tournamentByeVictoryPoints1, this.state.tournamentByeLoserPoints1,
                                            this.state.tournamentByeVictoryPoints2, this.state.tournamentByeLoserPoints2,
                                            this.state.tournamentByeVictoryPoints3, this.state.tournamentByeLoserPoints3);
        if (eventId === 0) eventId = null;
        const patchData =
            {
                name: this.state.tournamentName,
                summary: this.state.tournamentSummary.length === 0 ? null : this.state.tournamentSummary,
                state: this.state.tournamentState,
                event_id: eventId,
                date: this.state.tournamentDate ? this.state.tournamentDate : null,
                bye_results: this.props.tournament.bye_results,
                ranking: this.state.tournamentRanking,
                globally_ranked: this.state.tournamentGloballyRanked,
                top_table_finals: this.state.tournamentTopTableFinals,
                require_checkin: this.state.tournamentRequireCheckin,
                sos_method: this.state.tournamentSoSMethod,
            };
        if (this.props.tournament.type === 'swiss') patchData['num_rounds'] = this.state.tournamentNumRounds;
        this.restPubSub.patch('tournament', this.props.tournament.id, patchData, (d, k)=>this.updateTournament(d, k), (e, k)=>this.formUpdateError(e, k));
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this tournament name is too short');
        }
        this.setState({tournamentName: value, formErrors: errorState});
    }

    handleSummaryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('summary', {...this.state.formErrors});
        this.setState({tournamentSummary: value, formErrors: errorState});
    }

    handleNumRoundsChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('num_rounds', {...this.state.formErrors});
        if (value < 1 || value > 12) {
            errorState = setErrorState('num_rounds', {...this.state.formErrors}, 'swiss rounds must be between 1 and 12 (inclusive)');
        }
        this.setState({tournamentNumRounds: value, formErrors: errorState});
    }

    handleDateChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('date', {...this.state.formErrors});
        this.setState({tournamentDate: value, formErrors: errorState});
    }

    handleEventIdChange(event) {
        let value = parseInt(event.target.value);
        this.setState({tournamentEventId: value});
    }

    handleTournamentStateChange(value) {
        this.setState({tournamentState: value});
    }

    handleTournamentGloballyRankedChange(value) {
        this.setState({tournamentGloballyRanked: value});
    }

    handleTieBreakerChange(level, event) {
        let value = event.target.value;
        let rankingArray = parseRanking(this.state.tournamentRanking);
        rankingArray[level] = value;
        for (let index=0; index<rankingArray.length; index++) {
            if (index > level && rankingArray[index] === value) rankingArray[index] = 'na';
        }
        let rankingString = generateRanking(rankingArray);
        this.setState({tournamentRanking: rankingString});
    }

    handleTopTableFinalsChange(event) {
        let value = event.target.checked;
        this.setState({tournamentTopTableFinals: value});
    }

    handleRequireCheckinChange(event) {
        let value = event.target.checked;
        this.setState({tournamentRequireCheckin: value});
    }

    handleByeRecordChange(event) {
        let value = event.target.value;
        let matchPointsValue = 3;
        if (value === 't') matchPointsValue = 1;
        else if (value === 'l') matchPointsValue = 0;
        this.setState({tournamentByeRecord: value, tournamentByeMatchPoints: matchPointsValue});
    }

    handleByeVpsChange1(event) {
        let value = event.target.value;
        this.setState({tournamentByeVictoryPoints1: value});
    }

    handleByeLoserVpsChange1(event) {
        let value = event.target.value;
        this.setState({tournamentByeLoserPoints1: value});
    }

    handleByeVpsChange2(event) {
        let value = event.target.value;
        this.setState({tournamentByeVictoryPoints2: value});
    }

    handleByeLoserVpsChange2(event) {
        let value = event.target.value;
        this.setState({tournamentByeLoserPoints2: value});
    }

    handleByeVpsChange3(event) {
        let value = event.target.value;
        this.setState({tournamentByeVictoryPoints3: value});
    }

    handleByeLoserVpsChange3(event) {
        let value = event.target.value;
        this.setState({tournamentByeLoserPoints3: value});
    }

    handleTournamentSoSMethodChange(value) {
        this.setState({tournamentSoSMethod: value});
    }

    onDeleteTournament() {
        this.restPubSub.delete('tournament', this.props.tournament.id);
        this.setState({navigateTo: `/app/manageleague/${this.props.league.id}`});
    }

    generateTieBreakerOptions(listNum) {
        const rankingArray = parseRanking(this.state.tournamentRanking);
        const excludeList = rankingArray.slice(0, listNum);
        const options = [<option key={`na-${listNum}`} value='na'>None</option>];
        const topicData = this.props.league.topic;
        let score1Label = null;
        let score2Label = null;
        let score3Label = null;
        if (topicData.scores_recorded >= 1 && topicData.score1_noun !=='score') score1Label = ` (${capitalizeFirstLetters(topicData.score1_noun)})`;
        if (topicData.scores_recorded >= 2 && topicData.score2_noun !=='score') score2Label = ` (${capitalizeFirstLetters(topicData.score2_noun)})`;
        if (topicData.scores_recorded >= 3 && topicData.score3_noun !=='score') score3Label = ` (${capitalizeFirstLetters(topicData.score3_noun)})`;
        
        if (!excludeList.includes('rec')) options.push(<option key={`rec-${listNum}`} value='rec'>Record vs Opponent</option>);
        if (!excludeList.includes('sos')) options.push(<option key={`sos-${listNum}`} value='sos'>Strength of Schedule</option>);
        if (!excludeList.includes('vp1') && topicData.scores_recorded >= 1) options.push(<option key={`vp1-${listNum}`} value='vp1'>Victory Points{score1Label}</option>);
        if (!excludeList.includes('vp2') && topicData.scores_recorded >= 2) options.push(<option key={`vp2-${listNum}`} value='vp2'>Victory Points{score2Label}</option>);
        if (!excludeList.includes('vp3') && topicData.scores_recorded >= 3) options.push(<option key={`vp3-${listNum}`} value='vp3'>Victory Points{score3Label}</option>);
        if (!excludeList.includes('mov1') && topicData.scores_recorded >= 1) options.push(<option key={`mov1-${listNum}`} value='mov1'>Margin of Victory{score1Label}</option>);
        if (!excludeList.includes('mov2') && topicData.scores_recorded >= 2) options.push(<option key={`mov2-${listNum}`} value='mov2'>Margin of Victory{score2Label}</option>);
        if (!excludeList.includes('mov3') && topicData.scores_recorded >= 3) options.push(<option key={`mov3-${listNum}`} value='mov3'>Margin of Victory{score3Label}</option>);
        return options;
    }

    render() {
        // since this modal only navigates to delete, set replace=true
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo} replace={true}/>);

        // there are a few places where we refer to the lists
        const listNoun = this.props.league.topic.list_noun;
        const listNounCaps = capitalizeFirstLetters(listNoun);

        // generate the events list
        // todo - if there is an event going on today, select that by default
        // todo - if the event changes we need to verify that all players are in the event
        let eventOptions = [];
        const nullEventItem = <option key={0} value={0}>No Minor Event</option>
        eventOptions.push(nullEventItem);
        for (const eventId of this.props.league.event_ids) {
            let eventItem = null;
            // if we've background loaded this event, then use its name, otherwise use the id
            if (this.state.events.hasOwnProperty(eventId)) {
                eventItem = <option key={eventId} value={eventId}>{this.state.events[eventId].name}</option>
            } else {
                eventItem = <option key={eventId} value={eventId}>Minor Event {eventId}</option>
            }
            eventOptions.push(eventItem);
        }

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }


        // generate tiebreaker options
        const tiebreakerOptions1 = this.generateTieBreakerOptions(1, this.state.tournamentRanking);
        const tiebreakerOptions2 = this.generateTieBreakerOptions(2, this.state.tournamentRanking);
        const tiebreakerOptions3 = this.generateTieBreakerOptions(3, this.state.tournamentRanking);
        const tiebreakerOptions4 = this.generateTieBreakerOptions(4, this.state.tournamentRanking); 
        const rankingArray = parseRanking(this.state.tournamentRanking);

        const tournamentByeResults = this.props.tournament.getByeResults();

        let disableUpdateButton = true;
        if (this.state.tournamentName !== this.props.tournament.name) disableUpdateButton = false;
        if (this.state.tournamentState !== this.props.tournament.state) disableUpdateButton = false;
        if (this.props.tournament.summary === null && this.state.tournamentName !== '') disableUpdateButton = false;
        if (this.props.tournament.summary !== null && this.state.tournamentSummary !== this.props.tournament.summary) disableUpdateButton = false;
        if (this.props.tournament.date === null && this.state.tournamentDate !== '') disableUpdateButton = false;
        if (this.props.tournament.date !== null && this.state.tournamentDate !== this.props.tournament.date) disableUpdateButton = false;
        if (this.props.tournament.event_id === null && this.state.tournamentEventId !== 0) disableUpdateButton = false;
        if (this.props.tournament.event_id !== null && this.state.tournamentEventId !== this.props.tournament.event_id) disableUpdateButton = false;
        if (tournamentByeResults.record !== this.state.tournamentByeRecord) disableUpdateButton = false;
        // eslint-disable-next-line eqeqeq
        if (tournamentByeResults.vp1 != this.state.tournamentByeVictoryPoints1) disableUpdateButton = false;
        // eslint-disable-next-line eqeqeq
        if (tournamentByeResults.lp1 != this.state.tournamentByeLoserPoints1) disableUpdateButton = false;
        // eslint-disable-next-line eqeqeq
        if (tournamentByeResults.vp2 != this.state.tournamentByeVictoryPoints2) disableUpdateButton = false;
        // eslint-disable-next-line eqeqeq
        if (tournamentByeResults.lp2 != this.state.tournamentByeLoserPoints2) disableUpdateButton = false;
        // eslint-disable-next-line eqeqeq
        if (tournamentByeResults.vp3 != this.state.tournamentByeVictoryPoints3) disableUpdateButton = false;
        // eslint-disable-next-line eqeqeq
        if (tournamentByeResults.lp3 != this.state.tournamentByeLoserPoints3) disableUpdateButton = false;
        // eslint-disable-next-line eqeqeq
        if (this.props.tournament.num_rounds != this.state.tournamentNumRounds) disableUpdateButton = false;
        if (this.state.tournamentRanking !== this.props.tournament.ranking) disableUpdateButton = false;
        if (this.state.tournamentGloballyRanked !== this.props.tournament.globally_ranked) disableUpdateButton = false;
        if (this.state.tournamentTopTableFinals !== this.props.tournament.top_table_finals) disableUpdateButton = false;
        if (this.state.tournamentRequireCheckin !== this.props.tournament.require_checkin) disableUpdateButton = false;
        if (this.state.tournamentSoSMethod !== this.props.tournament.sos_method) disableUpdateButton = false;
        if (this.state.tournamentName.length < 6) disableUpdateButton = true;
        if (this.state.updating) disableUpdateButton = true;
        if (hasFormErrors) disableUpdateButton = true;

        let showByeTabulation = true;
        let showTieBreaking = true;
        let showTopTableFinals = false;
        let showTopTableFinalsWarning = false;
        let showRequireCheckinWarning = false;
        let showSosMethod = true;
        if (this.props.tournament.type === 'elimination') {
            showByeTabulation = false;
            showTieBreaking = false;
            showSosMethod = false;
        }
        if (this.props.league.topic.scores_recorded === 0) {
            showByeTabulation = false;
        }
        if (this.props.tournament.type === 'swiss') {
            showTopTableFinals = true;
            if (this.props.tournament.player_ids.length < 12) showTopTableFinalsWarning = true;
        }
        if (this.props.tournament.require_checkin === false && this.props.tournament.player_ids.length >= 10 && this.props.league.online === false) {
            showRequireCheckinWarning = true;
        }

        // if SoS is not being used for tie-breaking, no need to show that option
        if (!rankingArray.includes('sos')) showSosMethod = false;

        let primaryRankString = 'Bracket';
        if (this.props.tournament.type === 'swiss' || this.props.tournament.type === 'round-robin') {
            switch (this.props.tournament.subtype) {
                case 'mp':
                    primaryRankString = 'Match Points';
                    break;
                case 'vp':
                    primaryRankString = 'Victory Points';
                    break;
                case 'mov':
                    primaryRankString = 'Margin of Victory';
                    break;
                default:
                    primaryRankString = 'Unknown'
            }
        }

        // figure out the min and max days for date - +/- 1 year and has to fit in the bounds of the league
        let todayDate = new Date();
        let minStartDate = new Date();
        let maxStartDate = new Date();
        minStartDate.setFullYear(todayDate.getFullYear() - 1);
        maxStartDate.setFullYear(todayDate.getFullYear() + 1);
        // if the league has a start/end date set - set the date limits based on that
        if (this.props.league.start_date) {
            minStartDate = this.props.league.start_date;
        }
        if (this.props.league.end_date) {
            maxStartDate = this.props.league.end_date;
        }

        // figure out if the configuration matches the recommended configuration
        let showByeConfigurationIsRecommended = false;
        let showRankingConfigurationIsRecommended = false;
        let showSosMethodConfigurationIsRecommended = false;
        if (this.props.league.topic.tournament_has_defaults) {
            if (this.props.league.topic.tournament_suggestion === this.props.tournament.type) {
                let recommendedByeResult = null;
                let currentByeResult = `${this.state.tournamentByeRecord}:${this.state.tournamentByeMatchPoints}:${this.state.tournamentByeVictoryPoints1}:${this.state.tournamentByeLoserPoints1}:${this.state.tournamentByeVictoryPoints2}:${this.state.tournamentByeLoserPoints2}:${this.state.tournamentByeVictoryPoints3}:${this.state.tournamentByeLoserPoints3}`
                if (this.props.league.topic.tournament_bye_result === 'win') {
                    recommendedByeResult = `w:3:${this.props.league.topic.tournament_bye_vps1}:${this.props.league.topic.tournament_bye_loser_vps1}:${this.props.league.topic.tournament_bye_vps2}:${this.props.league.topic.tournament_bye_loser_vps2}:${this.props.league.topic.tournament_bye_vps3}:${this.props.league.topic.tournament_bye_loser_vps3}`
                }
                else if (this.props.league.topic.tournament_bye_result === 'tie') {
                    recommendedByeResult = `t:1:${this.props.league.topic.tournament_bye_vps1}:${this.props.league.topic.tournament_bye_loser_vps1}:${this.props.league.topic.tournament_bye_vps2}:${this.props.league.topic.tournament_bye_loser_vps2}:${this.props.league.topic.tournament_bye_vps3}:${this.props.league.topic.tournament_bye_loser_vps3}`
                }
                else if (this.props.league.topic.tournament_bye_result === 'loss') {
                    recommendedByeResult = `l:0:${this.props.league.topic.tournament_bye_vps1}:${this.props.league.topic.tournament_bye_loser_vps1}:${this.props.league.topic.tournament_bye_vps2}:${this.props.league.topic.tournament_bye_loser_vps2}:${this.props.league.topic.tournament_bye_vps3}:${this.props.league.topic.tournament_bye_loser_vps3}`
                }

                if (recommendedByeResult === currentByeResult) {
                    showByeConfigurationIsRecommended = true;
                }

                if (this.props.league.topic.tournament_ranking === this.state.tournamentRanking) {
                    showRankingConfigurationIsRecommended = true;
                }

                if (this.props.league.topic.tournament_sos_method === this.state.tournamentSoSMethod) {
                    showSosMethodConfigurationIsRecommended = true;
                }
            }
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tournament Configuration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control id='name' name='name' type="text" placeholder="Name the tournament..." disabled={this.state.updating} onChange={(e)=>this.handleNameChange(e)} autoComplete='off' value={this.state.tournamentName} minLength={6} maxLength={90} required/>
                                <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Summary</Form.Label>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='summary' name='summary' as="textarea" rows={5} placeholder='Optional: Add a summary for the tournament...' disabled={this.state.updating} onChange={(e)=>this.handleSummaryChange(e)} value={this.state.tournamentSummary} maxLength={500}/>
                                    <FormTextInputLimit current={this.state.tournamentSummary.length} max={500}/>
                                </div>
                                <FormErrorText errorId='summary' errorState={this.state.formErrors}/>
                            </Form.Group>
                            {this.props.tournament.type === 'swiss' &&
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Expected Number of Rounds</Form.Label>
                                <Form.Control id='num_rounds' name='num_rounds' type="number" placeholder="How many swiss rounds?" disabled={this.state.updating} onChange={(e)=>this.handleNumRoundsChange(e)} autoComplete='off' value={this.state.tournamentNumRounds} min={1} max={12} required/>
                                <FormErrorText errorId='num_rounds' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>Tell the players how many swiss rounds to expect - you can do more or less as needed.</Form.Text>
                                <Collapse in={this.state.showRoundsHelp}>
                                    <div>
                                        <div className='px-4 my-2'>
                                            <div className='px-2' style={{border: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0.25rem'}}>
                                                <Form.Text>
                                                    Guidance varies, but generally you can do the following:
                                                </Form.Text>
                                                <Table striped size='sm'>
                                                    <thead>
                                                        <tr><th><small>Players</small></th><th className='text-center'><small>Rounds</small></th><th className='text-center'><small>Top Cut</small></th></tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr><td><small>Less than 8</small></td><td className='text-center'><small>3</small></td><td className='text-center'><small>None</small></td></tr>
                                                        <tr><td><small>8-16</small></td><td className='text-center'><small>4</small></td><td className='text-center'><small>None</small></td></tr>
                                                        <tr><td><small>17-32</small></td><td className='text-center'><small>4 or 5</small></td><td className='text-center'><small>Top 4 or None</small></td></tr>
                                                        <tr><td><small>33-64</small></td><td className='text-center'><small>4 or 5</small></td><td className='text-center'><small>Top 8 or Top 4</small></td></tr>
                                                        <tr><td><small>65-128</small></td><td className='text-center'><small>5</small></td><td className='text-center'><small>Top 16</small></td></tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                                <div className='text-end'>
                                    <Button className='py-0' size='sm' variant='secondary' onClick={()=>this.setState({showRoundsHelp: !this.state.showRoundsHelp})}>How many rounds do I need?</Button>
                                </div>
                            </Form.Group>}
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control id="date" name="date" type="date" disabled={this.state.updating} onChange={(e)=>this.handleDateChange(e)} autoComplete='off' value={this.state.tournamentDate} min={minStartDate} max={maxStartDate}/>
                                <FormErrorText errorId='date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>Optional: Add a start date - the end of the tournament is defined by the rounds.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Event Assignment</Form.Label>
                                <Form.Select size='sm' onChange={(event)=>this.handleEventIdChange(event)} value={this.state.tournamentEventId} disabled={this.state.updating || this.props.league.event_ids.length === 0}>
                                    {eventOptions}
                                </Form.Select>
                                <Form.Text className='text-muted'>Optional: Tournaments can be assigned to events - only users playing in the event can view the tournament.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <div className='d-grid gap-2'>     
                                    <ToggleButtonGroup size='sm' name='tournament-state' type="radio" value={this.state.tournamentState} onChange={(v)=>this.handleTournamentStateChange(v)}>
                                        <ToggleButton variant='outline-primary' id='toggle-state-draft' value={'draft'}>Draft</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-state-posted' value={'posted'}>Posted</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-state-in-progress' value={'in-progress'}>In-Progress</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-state-complete' value={'complete'}>Completed</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.tournamentState === 'draft' &&
                                <Form.Text className='text-muted'><b>Draft</b> - this tournament is hidden from players (other tournament organizers can access it). Get your tournament all set up in this state.</Form.Text>}
                                {this.state.tournamentState === 'posted' &&
                                <Form.Text className='text-muted'><b>Posted</b> - the tournament is public but not yet started. Move your tournament to this state once you are ready for your players to see it.</Form.Text>}
                                {this.state.tournamentState === 'in-progress' &&
                                <Form.Text className='text-muted'><b>In-Progress</b> - the tournament is running! NerdHerder normally progresses the tournament to this state automatically - but you can move a completed tournament back to this state if needed.</Form.Text>}
                                {this.state.tournamentState === 'complete' &&
                                <Form.Text className='text-muted'><b>Completed</b> - this tournament is all done, it is still viewable but players can't participate, record games, etc.</Form.Text>}
                            </Form.Group>
                            {showTopTableFinals &&
                            <hr/>}
                            {showTopTableFinals &&
                            <Form.Group className="form-outline">
                                <Form.Label>Top Table Championship Match</Form.Label>
                                <Form.Text muted>
                                    <p>During the final round of a swiss tournament, it is possible to treat the top table as a 'Championship Match'. The players of this game will retain the first and second ranking regardless of other games in the final round.</p>
                                </Form.Text>
                                {showTopTableFinalsWarning &&
                                <Alert className='py-1' variant='warning'>Low ranked players may be assigned second place in small tournaments when enabled</Alert>}
                                <Row>
                                    <Col>
                                        <Form.Check size='sm' onChange={(e)=>this.handleTopTableFinalsChange(e)} checked={this.state.tournamentTopTableFinals} disabled={this.state.updating} label={"Top table is a 'Championship Match' in the final round"}/>
                                    </Col>
                                </Row>
                            </Form.Group>}
                            <hr/>
                            <Form.Group className="form-outline">
                                <Form.Label>Require Player Check-in</Form.Label>
                                <Form.Text muted>
                                    <p>For large tournaments, checking in every player is tedious - NerdHerder is here to help!</p>
                                    <p>NerdHerder will give you a QR code & 6-digit manual code that is used by your players to check in. You should keep these codes private, showing them only at the venue during the tournament (displayed on a screen or printed out). Players are given a prompt to 'check-in' when they arrive. This requires them to scan or enter the code. This way you can be sure the players are actually at your venue when they check in.</p>
                                    <p><i>If your tournament is played online, or over more than a few days, you probably should not enable this feature. If you require players to check-in in person for some other reason (e.g. payment) then you may not want to enable this feature.</i></p>
                                </Form.Text>
                                {showRequireCheckinWarning &&
                                <Alert className='py-1' variant='warning'>Given the size of your tournament, consider enabling this feature</Alert>}
                                <Row>
                                    <Col>
                                        <Form.Check size='sm' onChange={(e)=>this.handleRequireCheckinChange(e)} checked={this.state.tournamentRequireCheckin} disabled={this.state.updating} label={"Require Player Check-in"}/>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <hr/>
                            <Form.Group className="form-outline">
                                <Form.Label>Require {listNounCaps} Upload</Form.Label>
                                <Form.Text muted>
                                    <p>Your tournament can be configured to require players to upload their {listNoun}. This is accomplished globally from the {this.props.league.getTypeWord()} management page.</p>
                                    <a href={`/app/manageleague/${this.props.league.id}?tab=game&focus=manage-lists-card`}>Take me to the {this.props.league.getTypeWord()} management page!</a>
                                </Form.Text>
                            </Form.Group>
                            {showTieBreaking &&
                            <hr/>}
                            {showTieBreaking &&
                            <Form.Group className="form-outline">
                                <Form.Label>Tournament Rank & Tie Breaking</Form.Label>
                                {showRankingConfigurationIsRecommended &&
                                <Alert className='py-1' variant='primary'>This configuration matches the NerdHerder recommendations for {this.props.league.topic.id}</Alert>}
                                <Form.Text muted>
                                    <p>In this tournament, rank is primarily determined by {primaryRankString}. Tie breakers and their priority can be set below.</p>
                                    <ul>
                                        <li>Record - the player who defeated their tied opponent in a tournament game wins the tie</li>
                                        <li>Strength of Schedule - the player who faced tougher opponents wins the tie</li>
                                        <li>Victory Points - the player who achieved a higher cumulative score wins the tie</li>
                                        <li>Margin of Victory - the player who better defeated their opponents wins the tie</li>
                                    </ul>
                                </Form.Text>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Text muted>First Tiebreaker</Form.Text>
                                        <Form.Select size='sm' onChange={(e)=>this.handleTieBreakerChange(1, e)} value={rankingArray[1]} disabled={this.state.updating}>
                                            {tiebreakerOptions1}
                                        </Form.Select>
                                    </Col>
                                    <Col xs={6}>
                                    <Form.Text muted>Second Tiebreaker</Form.Text>
                                        <Form.Select size='sm' onChange={(e)=>this.handleTieBreakerChange(2, e)} value={rankingArray[2]} disabled={this.state.updating}>
                                            {tiebreakerOptions2}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Text muted>Third Tiebreaker</Form.Text>
                                        <Form.Select size='sm' onChange={(e)=>this.handleTieBreakerChange(3, e)} value={rankingArray[3]} disabled={this.state.updating}>
                                            {tiebreakerOptions3}
                                        </Form.Select>
                                    </Col>
                                    <Col xs={6}>
                                    <Form.Text muted>Fourth Tiebreaker</Form.Text>
                                        <Form.Select size='sm' onChange={(e)=>this.handleTieBreakerChange(4, e)} value={rankingArray[4]} disabled={this.state.updating}>
                                            {tiebreakerOptions4}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Form.Group>}
                            {showSosMethod &&
                            <hr/>}
                            {showSosMethod &&
                            <Form.Group className="form-outline">
                                <Form.Label>Strength Of Schedule Calculations</Form.Label>
                                {showSosMethodConfigurationIsRecommended &&
                                <Alert className='py-1' variant='primary'>This configuration matches the NerdHerder recommendations for {this.props.league.topic.id}</Alert>}
                                <Form.Text muted>
                                    <p>There are a few ways to calculate Strength of Schedule (SoS), none are perfect. Select the method that is best suited or recommended by the game publisher.</p>
                                </Form.Text>
                                <div className='d-grid gap-2 mt-2'>     
                                    <ToggleButtonGroup size='sm' name='tournament-sos-method' type="radio" value={this.state.tournamentSoSMethod} onChange={(v)=>this.handleTournamentSoSMethodChange(v)}>
                                        <ToggleButton variant='outline-primary' id='toggle-sos-sum' value='sum'>Sum</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-sos-average' value='average'>Average</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.tournamentSoSMethod === 'sum' &&
                                <Form.Text className='text-muted'><b>Sum</b> - a player's SoS is the sum of all of their opponents' scores (e.g. Match Points or Victory Points). This option works well in games where there are big differences in the scores. It does not work well with Match Points or when many players are expected to drop after losing a few rounds.</Form.Text>}
                                {this.state.tournamentSoSMethod === 'average' &&
                                <Form.Text className='text-muted'><b>Average</b> - a player's SoS is the sum of all of their opponents' average score (sum divided by the number of games played). This option works well in games where players may drop after losing a few rounds.</Form.Text>}
                            </Form.Group>}
                            {showByeTabulation &&
                            <hr/>}
                            {showByeTabulation &&
                            <Form.Group className="form-outline">
                                <Form.Label>BYE Tabulation</Form.Label>
                                {showByeConfigurationIsRecommended &&
                                <Alert className='py-1' variant='primary'>This configuration matches the NerdHerder recommendations for {this.props.league.topic.id}</Alert>}
                                <Row>
                                    <Col>
                                        <Form.Text muted>A BYE is considered a...</Form.Text>
                                        <Form.Select size='sm' onChange={(event)=>this.handleByeRecordChange(event)} value={this.state.tournamentByeRecord} disabled={this.state.updating}>
                                            <option value='w'>Win (3 Match Points)</option>
                                            <option value='t'>Tie (1 Match Point)</option>
                                            <option value='l'>Loss (0 Match Points)</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Text muted>
                                            <p>If this tournament is not using Match Points - the match point value above is ignored.</p>
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Form.Group>}
                            {showByeTabulation &&
                            <Form.Group>
                                {this.props.league.topic.scores_recorded >= 1 &&
                                <div>
                                    <Row className='mt-1'>
                                        <Col>
                                            <Form.Text><span className='text-capitalize'>{this.props.league.topic.score1_noun}</span></Form.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Text muted>How many VPs assigned?</Form.Text>
                                            <Form.Control type="number" disabled={this.state.updating} onChange={(e)=>this.handleByeVpsChange1(e)} autoComplete='off' value={this.state.tournamentByeVictoryPoints1} min={0} required/>
                                        </Col>
                                        <Col>
                                            <Form.Text className='text-muted'>How many VPs for the 'loser'?</Form.Text>
                                            <Form.Control type="number" disabled={this.state.updating} onChange={(e)=>this.handleByeLoserVpsChange1(e)} autoComplete='off' value={this.state.tournamentByeLoserPoints1} min={0} required/>
                                        </Col>
                                    </Row>
                                </div>}
                                {this.props.league.topic.scores_recorded >= 2 &&
                                <div>
                                    <Row className='mt-1'>
                                        <Col>
                                            <Form.Text><span className='text-capitalize'>{this.props.league.topic.score2_noun}</span></Form.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Text muted>How many VPs assigned?</Form.Text>
                                            <Form.Control type="number" disabled={this.state.updating} onChange={(e)=>this.handleByeVpsChange2(e)} autoComplete='off' value={this.state.tournamentByeVictoryPoints2} min={0} required/>
                                        </Col>
                                        <Col>
                                            <Form.Text className='text-muted'>How many VPs for the 'loser'?</Form.Text>
                                            <Form.Control type="number" disabled={this.state.updating} onChange={(e)=>this.handleByeLoserVpsChange2(e)} autoComplete='off' value={this.state.tournamentByeLoserPoints2} min={0} required/>
                                        </Col>
                                    </Row>
                                </div>}
                                {this.props.league.topic.scores_recorded >= 3 &&
                                <div>
                                    <Row className='mt-1'>
                                        <Col>
                                            <Form.Text><span className='text-capitalize'>{this.props.league.topic.score3_noun}</span></Form.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Text muted>How many VPs assigned?</Form.Text>
                                            <Form.Control type="number" disabled={this.state.updating} onChange={(e)=>this.handleByeVpsChange3(e)} autoComplete='off' value={this.state.tournamentByeVictoryPoints3} min={0} required/>
                                        </Col>
                                        <Col>
                                            <Form.Text className='text-muted'>How many VPs for the 'loser'?</Form.Text>
                                            <Form.Control type="number" disabled={this.state.updating} onChange={(e)=>this.handleByeLoserVpsChange3(e)} autoComplete='off' value={this.state.tournamentByeLoserPoints3} min={0} required/>
                                        </Col>
                                    </Row>
                                </div>}
                                <Row>
                                    <Col>
                                        <Form.Text muted>
                                            <p>
                                                'Loser' VPs are used for Margin of Victory calculations. Normally this should remain at the default value of zero. Optionally set this value to reduce the MoV given to the BYE player.
                                                For example, if VPs assigned is 14 and 'Loser' VPs is 0, the BYE player will get 14 VPs and a MoV of 14. If instead the 'Loser' VPs was 4, the BYE player would get 14 VPs and an MoV of 10.
                                            </p>
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Form.Group>}
                            <hr/>
                            <Form.Group className="form-outline mb-3">
                                <Row>
                                    <Col>
                                        <Form.Label>Global Ranking</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Text muted>In globally ranked tournaments players can accrue points that stack them against players of the same game worldwide.</Form.Text>
                                    </Col>
                                </Row>
                                <div className='d-grid gap-2 mt-2'>     
                                    <ToggleButtonGroup size='sm' name='tournament-ranked' type="radio" value={this.state.tournamentGloballyRanked} onChange={(v)=>this.handleTournamentGloballyRankedChange(v)}>
                                        <ToggleButton variant='outline-primary' id='toggle-ranked' value={true}>Globally Ranked</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-not-ranked' value={false}>Unranked</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {this.state.tournamentGloballyRanked === true &&
                                <Form.Text className='text-muted'><b>Globally Ranked</b> - players get points for playing games in the tournament, and doing well in this tournament will improver their global rank (this is the recommended setting).</Form.Text>}
                                {this.state.tournamentGloballyRanked === false &&
                                <Form.Text className='text-muted'><b>Unranked</b> - the tournament is not ranked and players do not get points for playing games in it. Use this when the tournament is using a non-standard format unapproved by the game publisher.</Form.Text>}
                            </Form.Group>
                        </Form>
                        <TripleDeleteButton label={'Delete Tournament'} onFinalClick={()=>this.onDeleteTournament()}/>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onUpdate()} disabled={disableUpdateButton}>Update</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderCompleteTournamentModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderCompleteTournamentModal'>
                <NerdHerderCompleteTournamentModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderCompleteTournamentModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.tournament === 'undefined') console.error('missing props.tournament');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.players === 'undefined') console.error('missing props.players');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            onUpdateCancel: false,
            place1UserId: this.props.tournament.place_1_user_id === null ? 0 : this.props.tournament.place_1_user_id,
            place2UserId: this.props.tournament.place_2_user_id === null ? 0 : this.props.tournament.place_3_user_id,
            place3UserId: this.props.tournament.place_2_user_id === null ? 0 : this.props.tournament.place_3_user_id,
        }
    }

    componentDidMount() {
        const sub = this.restPubSub.subscribeNoRefresh('tournament', this.props.tournament.id, (d, k)=>this.updateTournament(d, k));
        this.restPubSubPool.add(sub);
        this.autoSetWinners();
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, key) {
        this.setState({
            updating: false,
        });
        
        if (this.state.onUpdateCancel) {
            undoOnBackCancelModal();
            this.props.onCancel();
        }
    }

    onSave() {
        this.setState({updating: true, onUpdateCancel: true});
        const patchData =
            {
                // eslint-disable-next-line eqeqeq
                place_1_user_id: this.state.place1UserId == 0 ? null : this.state.place1UserId,
                // eslint-disable-next-line eqeqeq
                place_2_user_id: this.state.place2UserId == 0 ? null : this.state.place2UserId,
                // eslint-disable-next-line eqeqeq
                place_3_user_id: this.state.place3UserId == 0 ? null : this.state.place3UserId,
                state: 'complete',
            };
        this.restPubSub.patch('tournament', this.props.tournament.id, patchData);
    }

    autoSetWinners() {
        const hasPlayedDict = {};
        const recordDict = {};
        const matchPointsDict = {};
        const vpDict = {};
        const movDict = {};
        const metricPerOpponentDict = {};
        const strengthOfScheduleDict = {};
        const numberOfByesDict = {};
        const lastCompletedRound = getLastCompletedRound(this.props.tournament);
        let sortedUserIds = [];
        
        if (this.props.tournament.type === 'swiss' || this.props.tournament.type === 'round-robin') {
            tabulateTournamentData(this.props.tournament, lastCompletedRound, hasPlayedDict, recordDict, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict);
            sortedUserIds = sortTournamentPlayers(this.props.tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict);
        } else {
            // for elim tournaments, we unshift winners and push losers - puts the tournament winner first and 2nd place last
            for (const game of lastCompletedRound.games) {
                for (const player of game.players) {
                    if (player.winner) sortedUserIds.unshift(player.user_id);
                    else sortedUserIds.push(player.user_id);
                }
            }
            // then we add all the other players
            for (const userId of this.props.tournament.player_ids) {
                if (!sortedUserIds.includes(userId)) sortedUserIds.push(userId);
            }
        }

        let place = 0;
        let placeUserIds = [0, 0, 0];
        for (const playerId of sortedUserIds) {
            // show top 3 for round robin and swiss, but only top 2 for elimination because that's all we can predict
            if (this.props.tournament.type === 'swiss' && place >= 3) break;
            if (this.props.tournament.type === 'round-robin' && place >= 3) break;
            if (this.props.tournament.type === 'elimination' && place >= 2) break;
            placeUserIds[place] = playerId;
            place++;
        }
        this.setState({place1UserId: placeUserIds[0], place2UserId: placeUserIds[1], place3UserId: placeUserIds[2]});
    }

    handlePlaceChange(placeNum, event) {
        let value = event.target.value;
        switch(placeNum) {
            case 1:
                this.setState({place1UserId: value});
                break;
            case 2:
                this.setState({place2UserId: value});
                break;
            case 3:
                this.setState({place3UserId: value});
                break;
            default:
                console.error('hit unexpected switch default')
        }
    }

    render() {
        const nullPlayerItem = <option key={0} value={0}>None Assigned / Not Applicable</option>
        const playerOptions = [];
        playerOptions.push(nullPlayerItem);

        const hasPlayedDict = {};
        const recordDict = {};
        const matchPointsDict = {};
        const vpDict = {};
        const movDict = {};
        const metricPerOpponentDict = {};
        const strengthOfScheduleDict = {};
        const numberOfByesDict = {};
        const lastCompletedRound = getLastCompletedRound(this.props.tournament);
        tabulateTournamentData(this.props.tournament, lastCompletedRound, hasPlayedDict, recordDict, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict);
        const sortedUserIds = sortTournamentPlayers(this.props.tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict);

        for (const playerId of sortedUserIds) {
            let playerNameString = this.props.players[playerId].username;
            if (this.props.players[playerId].short_name) {
                playerNameString += ` (${this.props.players[playerId].short_name})`;
            }
            let playerItem = <option key={playerId} value={playerId}>{playerNameString}</option>
            playerOptions.push(playerItem);
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Winners & Complete Tournament</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>First Place Winner</Form.Label>
                                <Form.Select size='sm' onChange={(event)=>this.handlePlaceChange(1, event)} value={this.state.place1UserId} disabled={this.state.updating}>
                                    {playerOptions}
                                </Form.Select>
                                <Form.Text className='text-muted'>Optional: Select the 1st place winner of the tournament (if any).</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Second Place Winner</Form.Label>
                                <Form.Select size='sm' onChange={(event)=>this.handlePlaceChange(2, event)} value={this.state.place2UserId} disabled={this.state.updating}>
                                    {playerOptions}
                                </Form.Select>
                                <Form.Text className='text-muted'>Optional: Select the 2nd place winner of the tournament (if any).</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Third Place Winner</Form.Label>
                                <Form.Select size='sm' onChange={(event)=>this.handlePlaceChange(3, event)} value={this.state.place3UserId} disabled={this.state.updating}>
                                    {playerOptions}
                                </Form.Select>
                                <Form.Text className='text-muted'>Optional: Select the 3rd place winner of the tournament (if any).</Form.Text>
                            </Form.Group>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onSave()} disabled={this.state.updating}>Complete Tournament</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderGlobalRankingCalculationsModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderGlobalRankingCalculationsModal'>
                <NerdHerderGlobalRankingCalculationsModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderGlobalRankingCalculationsModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered scrollable={true} show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Global Ranking Calculations</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <b>Goals</b>
                        <div className='text-muted'>
                            <p>The global ranking system on NerdHerder aims to reward players for:</p>
                            <ul>
                                <li>Winning games</li>
                                <li>Playing more</li>
                                <li>Playing recently</li>
                                <li>Playing in person</li>
                                <li>Playing large tournaments</li>
                                <li>Playing different opponents</li>
                                <li>Playing tougher opponents</li>
                            </ul>
                            <p>The list above is in no particular order. The rank includes games from tournaments with 6 or more players, and only from tournaments with global ranking enabled. Casual and (narrative) minor-event games are not ranked.</p>
                            <p>Each game system is ranked separately - a Marvel: Crisis Protocol tournament has no impact on global rank for Warmachine & Hordes or Kill Team.</p>
                            <p><i>Note: We're not saying that the #1 ranked player is the GOAT, but a well ranked player is probably pretty good at the game.</i></p>
                        </div>
                        <b>Calculations</b>
                        <div className='text-muted'>
                            <p>A player's rank is determined by combining their Match Point Value (MPV), Tournament Point Value (TPV), and Schedule Point Value (SPV). The three values are weighted to form a final score as follows:</p>
                            <ul>
                                <li>MPV - 25%</li>
                                <li>TPV - 50%</li>
                                <li>SPV - 25%</li>
                            </ul>
                        </div>
                        <b>Match Point Value (MPV)</b>
                        <div className='text-muted'>
                            <p>MPV is all about winning games, playing more games, playing recently, and playing in person. There is also a bonus here for playing in large tournaments.</p>
                            <p>Playing a ranked game online is worth 10 points, and 20 if it is played in person. The bonus for the game being part of a large tournament is added next:</p>
                            <ul>
                                <li>Tournaments with 10+ players: +5 points</li>
                                <li>Tournaments with 30+ players: +5 points</li>
                                <li>Tournaments with 60+ players: +5 points</li>
                            </ul>
                            <p>Tournament bonuses are cumulative, so an in-person game in a 62 player tournament game would be worth 35 points.</p>
                            <p>However, you only get all these points the day you play the game...<i>and if you win!</i></p>
                            <p>Winning the game earns the player 100% of these points. The loser still gets 10%. If the game is a tie, 50% for each player.</p>
                            <p>Finally, there is <b>Point Decay</b>. Points earned decay away over the course of two years. A game worth 20 points is only worth 10 a year later.</p>
                            <p>To do well in this category you need to play in lots of ranked tournaments and win as much as possible!</p>
                        </div>
                        <b>Tournament Point Value (TPV)</b>
                        <div className='text-muted'>
                            <p>TPV is focused on placing in tournaments, and larger tournaments earn more points. Like MPV, tournament points decay over the same two year period.</p>
                            <p>Only the first, second, and third place finisher in a tournament earn points in this category. Unlike MPV, the number of points comes from an equation:</p>
                            <p>TPV = PM * ((S + 6) / 2)^1.5</p>
                            <p>Put another way, the number of tournament players (S) averaged with 6 (the minimum size of a tournament) raised to the power 1.5 becomes the TPV rewarded. PM is the place multiplier, which is set accordingly:</p>
                            <ul>
                                <li>1st Place - 4</li>
                                <li>2nd Place - 2</li>
                                <li>3rd Place - 1</li>
                            </ul>
                            <p>It looks complicated, so here's an example: Let's say a player goes 4-0 and wins 1st place in a 6 person local tournament. They'll get 80 MPV and 59 TPV. The individual games are a bigger reward than the tournament. However, if it had been a 64 person regional tournament they'd instead get 140 MPV and 828 TPV. If you win a huge tournament you will be on top for a while.</p>
                        </div>
                        <b>Schedule Point Value (SPV)</b>
                        <div className='text-muted'>
                            <p>SPV rewards playing against many different opponents as well as playing against skilled opponents...<i>but only if you win.</i></p>
                            <p>A player's win-loss percentage is calculated for each opponent they've faced in a ranked game (ties are ignored) over the last two years. SPV is simply the sum of each opponent's MPV and TPV times the win-loss percentage.</p>
                            <p>SPV rewards playing against skilled opponents because they'll have a higher MPV+TPV. However, there are no extra points for beating an opponent twice - therefore to maximize your SPV it is best to <i>crush</i> multiple opponents!</p>
                            <p>Unlike MPV and TPV there is no decay built into SPV - this is because MPV and TPV already decay (so SPV decays accordingly).</p>
                            <p>SPV has one extra benefit: if you beat a newb who joins the hobby, becomes an awesome player and racks up a high global score, you will get the SPV from beating them for up to two years. Therefore, SPV indirectly rewards growing the overall community!</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderUserPickerModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderUserPickerModal'>
                <NerdHerderUserPickerModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderUserPickerModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');
        if (typeof this.props.userIdList === 'undefined') console.error('missing props.userIdList');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            selectedUserId: null,
            updating: false,
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onSelect(userId) {
        this.setState({selectedUserId: userId});
    }

    onAccept() {
        undoOnBackCancelModal();
        this.props.onAccept(this.state.selectedUserId);
    }

    render() {
        const listItems = [];
        for (const userId of this.props.userIdList) {
            const listItem = <UserListItem key={userId} userId={userId} showSelected={this.state.selectedUserId===userId} localUser={this.props.localUser} onClick={()=>this.onSelect(userId)}/>
            listItems.push(listItem);
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Select A User'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small className='text-muted'>{this.props.bodyText || 'Select one of the users below'}</small>
                        {listItems}
                        {this.props.children}
                    </Modal.Body>
                    {this.state.selectedUserId !== null &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onAccept()}>{this.props.acceptButtonText || 'Select'}</Button>
                    </Modal.Footer>}
                </Modal>
            </div>
        );
    }
}

export class NerdHerderEditGameModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderEditGameModal' onCancel={()=>this.props.onCancel('no-change')}>
                <NerdHerderEditGameModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderEditGameModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.gameId === 'undefined' && typeof this.props.game === 'undefined') console.error('missing props.gameId or props.game');

        this.autoUpdateFactions = true;
        this.shadowTableName = '';

        let gameData = null;
        let gameId = null;
        if (typeof this.props.game !== 'undefined') {
            gameData = this.props.game;
            gameId = gameData.id;
        };

        // grab the league directly from props if possible
        let leagueId = null;
        let leagueData = null;
        let topicData = null;
        let isBoardGameLeague = false;
        if (typeof this.props.league !== 'undefined') {
            leagueData = this.props.league;
            topicData = this.props.league.topic;
            leagueId = this.props.league.id;
            if (leagueData.topic_id === 'BG') isBoardGameLeague = true;
            // if the topic doesn't use factions, don't try to set them (also don't try to set multi-faction)
            if (!topicData.game_player_has_faction) this.autoUpdateFactions = false;
            else if (topicData.game_player_has_faction && topicData.game_player_multi_faction) this.autoUpdateFactions = false;
        } else if (typeof this.props.leagueId !== 'undefined') {
            leagueId = this.props.leagueId;
        }

        // grab the tournament directly from props if possible
        let tournamentData = null;
        let isTournamentGame = false;
        if (this.props.tournament != null) {
            isTournamentGame = true;
            tournamentData = this.props.tournament;
        } else if (gameData != null && gameData.tournament_id != null) {
            isTournamentGame = true;
        }

        // grab the event directly from props if possible
        let eventData = null;
        let isEventGame = false;
        if (this.props.event != null) {
            isEventGame = true;
            eventData = this.props.event;
        }else if (gameData != null && gameData.event_id != null) {
            isEventGame = true;
        }

        const defaultPlayerIds = [];
        const defaultPlayerDict = {};
        const initialUsersCache = {};

        // load all the players from the gameData
        for (const player of gameData.players) {
            const userId = player.user_id;
            defaultPlayerIds.push(userId);
            defaultPlayerDict[userId] = this.generateFormPlayersDict(userId, player.score, player.score1, player.score2, player.score3, player.winner, player.concur_with_results, player.game_id, player.faction, player.list_points, player.list_id);
        }

        // some of the defaults don't mesh 100% with the game data...fix that here
        let defaultDate = null;
        let defaultDateTime = null;
        let defaultRoundId = null;
        let defaultTournamentId = null;
        let defaultEventId = null;
        let defaultBoardGameId = 0;
        if (gameData === null) {
            defaultDate = convertTodaysLocalDateObjectToFormInput();
            defaultTournamentId = 0;
            defaultRoundId = 0;
            defaultEventId = 0;
        } else {
            defaultDate = gameData.date;
            defaultDateTime = gameData.proposed_datetime;
            if (gameData.tournament_id === null) defaultTournamentId = 0;
            else defaultTournamentId = gameData.tournament_id;
            if (gameData.round_id === null) defaultRoundId = 0;
            else defaultRoundId = gameData.round_id;
            if (gameData.event_id === null) defaultEventId = 0;
            else defaultEventId = gameData.event_id;
        }
        
        // cache all the users in the gameData, plus the local user
        if (gameData !== null) {
            for (const player of gameData.players) {
                const userData = player.user_info;
                const userId = userData.id;
                initialUsersCache[userId] = NerdHerderDataModelFactory('user', userData);
            }
        }
        initialUsersCache[this.props.localUser.id] = this.props.localUser;

        if (isBoardGameLeague && gameData) defaultBoardGameId = gameData.board_game_id;

        // decide the view - if only a player then its just the player view
        let showWhichView = 'player';
        // if the localuser is a manager, then show that by default, unless the player is both then stick with player view
        if (leagueData !== null) {
            if (leagueData.isManager(this.props.localUser.id) && !leagueData.isPlayer(this.props.localUser.id)) {
                showWhichView = 'manager';
            }
        }

        let defaultGamePoints = '';
        if (leagueData !== null) {
            if (leagueData.topic.game_has_points && leagueData.topic.game_has_points_default && leagueData.topic.game_has_points_default !== 0) {
                defaultGamePoints = leagueData.topic.game_has_points_default;
            }
        }

        this.state = {
            // GUI presentation stuff
            navigateTo: null,
            showUserProfileModal: false,
            selectedUserIdForProfileModal: null,
            showProposeScheduleModal: false,
            showDeleteConfirmModal: false,
            showGameIncompleteModal: false,
            showIsGameCompleteModal: false,
            showIsTiedConfirmModal: false,
            showAddNewBoardGameModal: false,
            gameIncompleteJsx: null,
            updating: false,
            onUpdateCancel: false,
            closeResult: 'no-change',
            showWhichView: showWhichView,
            centerSectionMode: 'scores',
            sendInvites: false,

            // game state from the server
            gameId: gameId,
            game: gameData,
            isTournamentGame: isTournamentGame,
            tournament: tournamentData,
            isEventGame: isEventGame,
            event: eventData,
            leagueId: leagueId,
            league: leagueData,
            leagueLastFactionDict: null,
            topic: topicData,
            isBoardGameLeague: isBoardGameLeague,

            // tournaments, events, and possibly board games loaded in the background
            events: {},
            tournaments: {},
            tournamentRounds: {},
            boardGames: null,
            backgroundLoadIssued: false,

            // keep a 'cache' of users that could be players to avoid repeated lookups
            usersCache: initialUsersCache,

            // stuff related to the form
            formPlayerIds: defaultPlayerIds,
            formPlayerDict: defaultPlayerDict,
            formPlayerUpdatedIds: [],
            formDate: defaultDate,
            formProposedDateTime: defaultDateTime,
            formDetails: gameData.details || '',
            formCompletion: gameData.completion || 'completed',
            formBoardGameId: defaultBoardGameId,
            formEventId: defaultEventId,
            formTournamentId: defaultTournamentId,
            formTournamentRoundId: defaultRoundId,
            formState: gameData.state || 'posted',
            formTableName: gameData.table_name || '',
            formBye: gameData.bye || false,
            formFirstPlayer: gameData.first_player_id || 0,
            formGamePoints: gameData.game_points || defaultGamePoints,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.playersPubSubPool = new NerdHerderRestPubSubPool();

        // there are timers to timeout the typing or refreshing for some fields...
        this.scoreTimer = {};
        this.gameRefreshTimer = null;

        // when the scores are set, if the winner has not been selected do that automatically once
        this.autoSelectWinner = true;

        // when the scores are set, if the completion has not been change select completed once
        this.autoSelectCompletion = true;

        // after a score is updated, we'll need to check for auto selection
        this.autoCompletionTimeout = null;
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        let sub = null;
        if (this.state.game === null) {
            sub = this.restPubSub.subscribe('game', this.state.gameId, (d, k) => {this.updateGame(d, k)});
            this.restPubSubPool.add(sub);
        } else {
            // we'll want to register for updates, so do a no refresh subscribe...
            sub = this.restPubSub.subscribeNoRefresh('game', this.state.gameId, (d, k) => {this.updateGame(d, k)});
            this.restPubSubPool.add(sub);
            // this is an edit, and we have a game, but not a league load it...
            if (this.state.league === null) {
                sub = this.restPubSub.subscribe('league', this.state.leagueId, (d, k) => {this.updateLeague(d, k)});
                this.restPubSubPool.add(sub);
                sub = this.restPubSub.subscribe('league-last-faction-list', this.state.leagueId, (d, k) => {this.updateLeagueLastFaction(d, k)});
                this.restPubSubPool.add(sub);
            }
            // this is an edit, and we have a game, but not a tournament (but there is a tournament) load it...
            if (this.state.game.tournament_id !== null && this.state.tournament === null) {
                sub = this.restPubSub.subscribe('tournament', this.state.game.tournament_id, (d, k) => {this.updateTournament(d, k)});
                this.restPubSubPool.add(sub);
            }
            // this is an edit, and we have a game, but not an event (but there is an event) load it...
            if (this.state.game.event_id !== null && this.state.event === null) {
                sub = this.restPubSub.subscribe('event', this.state.game.event_id, (d, k) => {this.updateEvent(d, k)});
                this.restPubSubPool.add(sub);
            }

            if (this.state.isBoardGameLeague) {
                const sub = this.restPubSub.subscribeNoRefresh('board-game', null, (d, k) => {this.updateSelectableBoardGames(d, k)});
                this.restPubSubPool.add(sub);
            }
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
        this.playersPubSubPool.unsubscribe();
    }

    backgroundLoadSelectableModels(leagueData) {
        let sub = null;
        for (const eventId of leagueData.event_ids) {
            sub = this.restPubSub.subscribeNoRefresh('event', eventId, (d, k) => {this.updateSelectableEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
        if (leagueData.isManager(this.props.localUser.id)) {
            for (const tournamentId of leagueData.tournament_ids) {
                sub = this.restPubSub.subscribeNoRefresh('tournament', tournamentId, (d, k) => {this.updateSelectableTournament(d, k)}, null, tournamentId);
                this.restPubSubPool.add(sub);
            }
        }
        this.setState({backgroundLoadIssued: true});
    }

    updateGame(gameData, key) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        let isTournamentGame = false;
        let isEventGame = false;
        if (newGame.tournament_id !== null) {
            isTournamentGame = true;
        }
        if (newGame.event_id !== null) {
            isEventGame = true;
        }

        if (this.state.league === null) {
            let sub = this.restPubSub.subscribe('league', newGame.league_id, (d, k) => {this.updateLeague(d, k)});
            this.restPubSubPool.add(sub);
        }
        if (this.state.leagueLastFactionDict === null) {
            let sub = this.restPubSub.subscribe('league-last-faction-list', newGame.league_id, (d, k) => {this.updateLeagueLastFaction(d, k)});
            this.restPubSubPool.add(sub);
        }
        if (isTournamentGame) {
            if (this.state.tournament === null && newGame.tournament_id !== null) {
                let sub = this.restPubSub.subscribe('tournament', newGame.tournament_id, (d, k) => {this.updateTournament(d, k)}, null, newGame.tournament_id);
                this.restPubSubPool.add(sub);
            }
            // eslint-disable-next-line eqeqeq
            else if (this.state.tournament !== null && this.state.tournament.id != newGame.tournament_id) {
                let sub = this.restPubSub.subscribe('tournament', newGame.tournament_id, (d, k) => {this.updateTournament(d, k)}, null, newGame.tournament_id);
                this.restPubSubPool.add(sub);
            }
        }
        else {
            this.setState({tournament: null});
        }

        if (isEventGame) {
            if (this.state.event === null && newGame.event_id !== null) {
                let sub = this.restPubSub.subscribe('event', newGame.event_id, (d, k) => {this.updateEvent(d, k)}, null, newGame.event_id);
                this.restPubSubPool.add(sub);
            }
            // eslint-disable-next-line eqeqeq
            else if (this.state.event !== null && this.state.event.id != newGame.event_id) {
                let sub = this.restPubSub.subscribe('event', newGame.event_id, (d, k) => {this.updateEvent(d, k)}, null, newGame.event_id);
                this.restPubSubPool.add(sub);
            }
        } else {
            this.setState({event: null});
        }

        // tablename is null in the database but an empty string for the form
        let newTableName = newGame.table_name;
        if (newTableName === null) newTableName = '';
        if (newTableName !== 'No Table' && newTableName !== '') this.shadowTableName = newTableName;

        let newBoardGameId = this.state.formBoardGameId;
        if (this.state.isBoardGameLeague && newGame.board_game_id) newBoardGameId = newGame.board_game_id;
        
        // regenerate the player info
        const updatedPlayerIds = [];
        const updatedPlayerDict = {};
        for (const player of newGame.players) {
            const playerUserId = player.user_id;
            updatedPlayerIds.push(playerUserId);
            // but don't update the scores and winner state
            const score = player.score;
            const score1 = player.score1;
            const score2 = player.score2;
            const score3 = player.score3;
            const winner = player.winner;
            updatedPlayerDict[playerUserId] = this.generateFormPlayersDict(playerUserId, score, score1, score2, score3, winner, player.concur_with_results, player.game_id, player.faction, player.list_points, player.list_id);
        }

        // want to keep the player IDs in the same order - this means we need to do more of a coalescing than a straight up replacement
        // make a new array (finalPlayerIds)...
        // - first add any existing (stale) IDs that are in the updated list in the same order they are in the existing list
        //   this has the effect of removing deleted players
        // - next add any players from the updated list that don't already appear in the final player list
        //   this has the effect of adding new players
        const stalePlayerIds = this.state.formPlayerIds;
        const finalPlayerIds = [];
        for (const playerId of stalePlayerIds) {
            if (updatedPlayerIds.includes(playerId)) {
                finalPlayerIds.push(playerId);
            }
        }
        for (const playerId of updatedPlayerIds) {
            if (!finalPlayerIds.includes(playerId)) {
                finalPlayerIds.push(playerId);
            }
        }

        this.setState(
            {
                updating: false,
                gameId: newGame.id,
                game: newGame,
                isTournamentGame: isTournamentGame,
                isEventGame: isEventGame,
                formPlayerIds: finalPlayerIds,
                formPlayerDict: updatedPlayerDict,
                formDate: newGame.date,
                formDetails: newGame.details,
                formCompletion: newGame.completion,
                formEventId: newGame.event_id===null? 0 : newGame.event_id,
                formTournamentId: newGame.tournament_id===null? 0 : newGame.tournament_id,
                formTournamentRoundId: newGame.round_id===null? 0 : newGame.round_id,
                formState: newGame.state,
                formTableName: newTableName,
                formBye: newGame.bye,
                formBoardGameId: newBoardGameId,
                formFirstPlayer: newGame.first_player_id===null? 0 : newGame.first_player_id,
                formGamePoints: newGame.game_points===null? '' : newGame.game_points,
            }
        );

        if (this.state.onUpdateCancel) {
            undoOnBackCancelModal();
            this.props.onCancel(this.state.closeResult);
        }
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        let isBoardGameLeague = false;
        if (newLeague.topic_id === 'BG') isBoardGameLeague = true;
        
        // if the topic doesn't use factions, don't try to set them (also don't try to set multi-faction)
        if (!newLeague.topic.game_player_has_faction) this.autoUpdateFactions = false;
        else if (newLeague.topic.game_player_has_faction && newLeague.topic.game_player_multi_faction) this.autoUpdateFactions = false;

        this.setState({league: newLeague, topic: newLeague.topic, isBoardGameLeague: isBoardGameLeague});

        if (isBoardGameLeague && this.state.boardGames === null) {
            const sub = this.restPubSub.subscribeNoRefresh('board-game', null, (d, k) => {this.updateSelectableBoardGames(d, k)});
            this.restPubSubPool.add(sub);
        }

        // if the league/topic was updated and there is a default points value set it
        if (newLeague.topic.game_has_points && newLeague.topic.game_has_points_default && newLeague.topic.game_has_points_default !== 0) {
            if (this.state.formGamePoints === '') {
                this.setState({formGamePoints: newLeague.topic.game_has_points_default});
            }
        }
    }

    updateLeagueLastFaction(leagueLastFactionData, key) {
        this.setState({leagueLastFactionDict: leagueLastFactionData});

        // if there were no factions to update, don't try
        let hasLastFactions = false;
        for (const userId of Object.keys(leagueLastFactionData)) {
            const userLastFaction = leagueLastFactionData[userId];
            if (userLastFaction === null || userLastFaction.length === 0) continue;
            hasLastFactions = true;
            break;
        }
        if (!hasLastFactions) this.autoUpdateFactions = false;
    }

    updateTournament(tournamentData, key) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState({tournament: newTournament});
    }

    updateSelectableTournament(tournamentData, tournamentId) {
        console.debug(`background loaded tournament ${tournamentId}`);
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
        for (const tournamentRoundData of tournamentData.rounds) {
            const newTournamentRound = NerdHerderDataModelFactory('tournament-round', tournamentRoundData);
            this.setState((state) => {
                return {tournamentRounds: {...state.tournamentRounds, [newTournamentRound.id]: newTournamentRound}}
            });
        }
    }

    updateEvent(eventData, key) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: newEvent});
    }

    updateSelectableEvent(eventData, eventId) {
        console.debug(`background loaded event ${eventId}`);
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState((state) => {
            return {events: {...state.events, [eventId]: newEvent}}
        });
    }

    updateSelectableBoardGames(boardGameData, key) {
        this.setState({boardGames: boardGameData});
    }

    updateUser(userData, userId) {
        this.updateUsersCache(userData);
    }

    updateUsersCache(userData) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [newUser.id]: newUser}}
        });
    }

    updateDefaultFactions() {
        // only do this once
        this.autoUpdateFactions = false;

        // don't do an auto update if the game is already marked complete
        if (this.state.game.completion === 'completed') return;

        // don't do anything if this game doesn't use factions or is multi faction
        if (!this.state.topic.game_player_has_faction) return;
        if (this.state.game_player_multi_faction) return;

        const factionDict = this.state.topic.getFactionDict();

        // go through each player, set the factions
        for (const playerId of this.state.formPlayerIds) {
            // make sure that the player is in the dict, that the player's last faction isn't a 'none' and that the last faction id is legal
            if (!this.state.leagueLastFactionDict.hasOwnProperty(playerId)) continue;
            const lastFactionId = this.state.leagueLastFactionDict[playerId];
            if (lastFactionId === null || lastFactionId === '') continue;
            if (!factionDict.hasOwnProperty(lastFactionId)) continue;

            // if we get here, the last faction id is good - but we don't want to change it if already set
            const playerDict = this.state.formPlayerDict[playerId];
            if (playerDict.faction !== null && playerDict.faction !== '') continue;
            
            // ok actually do the update
            const newPlayerDict = {...playerDict};
            newPlayerDict.faction = lastFactionId;
            this.setState((state) => {
                return {formPlayerDict: {...state.formPlayerDict, [playerId]: newPlayerDict}}
            });
        }
    }

    generateFormPlayersDict(userId, score, score1, score2, score3, winner, concur, gameId, faction, listPoints, listId) {
        const result = {
            user_id: userId,
            score: score,
            score1: score1,
            score2: score2,
            score3: score3,
            winner: winner,
            concur_with_results: concur,
            concur_with_schedule: false,
            game_id: gameId,
            faction: faction,
            list_points: listPoints,
            list_id: listId
        }
        return result;
    }

    loadFormFromState() {
        this.setState({
            formDate: this.state.game.date,
            formDetails: this.state.game.details,
        });
    }

    forceGameRefresh(delay=0) {
        if (delay === 0) {
            this.restPubSub.refresh('game', this.state.gameId);
        } else {
            if (this.gameRefreshTimer) clearTimeout(this.gameRefreshTimer);
            this.game = setTimeout(()=>{this.restPubSub.refresh('game', this.state.gameId)}, delay);
        }
    }

    showUserProfile(userId) {
        this.setState({showUserProfileModal: true, selectedUserIdForProfileModal: userId});
    }

    hideUserProfile() {
        this.setState({showUserProfileModal: false, selectedUserIdForProfileModal: null});
    }

    showAddNewBoardGameModal() {
        this.setState({showAddNewBoardGameModal: true});
    }

    hideAddNewBoardGameModal(result) {
        if (result) {
            this.setState({showAddNewBoardGameModal: false, formBoardGameId: result});
        } else {
            this.setState({showAddNewBoardGameModal: false});
        }        
    }

    onSwitchView(value) {
        this.setState({showWhichView: value});
    }

    onAddPlayers() {
        this.setState({centerSectionMode: 'players'});
    }

    onCancelAddPlayers() {
        this.setState({centerSectionMode: 'scores'});
    }

    onPlayerAdded(userId) {
        const modifiedPlayerIdList = [...this.state.formPlayerIds];
        modifiedPlayerIdList.push(userId);
        
        // see if the new player has a default faction - but only use that if the topic uses factions, and if the faction is legit
        let defaultFactionId = null;
        if (this.state.leagueLastFactionDict !== null && this.state.topic.game_player_has_faction && !this.state.topic.game_player_multi_faction) {
            if (this.state.leagueLastFactionDict.hasOwnProperty(userId)) {
                const lastFactionId = this.state.leagueLastFactionDict[userId];
                const factionDict = this.state.topic.getFactionDict();
                if (lastFactionId !== null && lastFactionId !== '' && factionDict.hasOwnProperty(lastFactionId)) {
                    defaultFactionId = lastFactionId;
                }
            }
        }
        const newPlayerDict = this.generateFormPlayersDict(userId, 0, 0, 0, 0, false, false, this.state.gameId, defaultFactionId, null, null);
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: newPlayerDict},
                    formPlayerIds: modifiedPlayerIdList,
                    centerSectionMode:'scores',
                    formBye: false,
                }
        });

        // when this is called, we will have just listed all the players - which means we could cache them
        let eligiblePlayerIdList = null;
        if (this.state.isTournamentGame) {
            eligiblePlayerIdList = this.state.tournament.player_ids;
        } else if (this.state.isEventGame) {
            eligiblePlayerIdList = this.state.league.player_ids;
        } else {
            eligiblePlayerIdList = this.state.league.player_ids;
        }
        for (const eligibleUserId of eligiblePlayerIdList) {
            if (this.state.usersCache.hasOwnProperty(eligibleUserId)) continue;
            let sub = this.restPubSub.subscribeNoRefresh('user', eligibleUserId, (d, k) => {this.updateUser(d, k)}, null, eligibleUserId);
            this.playersPubSubPool.add(sub);
        }  
    }

    onChangeScore(event, userId, scoreNum) {
        let newScore = event.target.value;
        const dict = this.state.formPlayerDict[userId];
        if (scoreNum === 3) {
            dict.score3 = newScore;
        } else if (scoreNum === 2) {
            dict.score2 = newScore;
        } else {
            dict.score = newScore;
            dict.score1 = newScore;
        }
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
        this.resetAutoCompletionTimeout();
    }

    onSelectScore(event, userId, scoreNum) {
        const dict = this.state.formPlayerDict[userId];
        if (scoreNum === 3) {
            // eslint-disable-next-line eqeqeq
            if (dict.score3 == 0) {
                dict.score3 = '';
            }
        } else if (scoreNum === 2) {
            // eslint-disable-next-line eqeqeq
            if (dict.score2 == 0) {
                dict.score2 = '';
            }
        } else {
            // eslint-disable-next-line eqeqeq
            if (dict.score == 0) {
                dict.score = '';
                dict.score1 = '';
            }
        }
        
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerFaction(event, userId) {
        let newFaction = event.target.value;
        if (newFaction === 'notset') newFaction = null;
        const dict = this.state.formPlayerDict[userId];
        dict.faction = newFaction;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerFactionMulti(event, userId) {
        let newFaction = event.target.value;
        const dict = this.state.formPlayerDict[userId];
        // need a list of factions, empty or from the csv string
        let listOfFactions = [];
        if (dict.faction !== null) {
            listOfFactions= dict.faction.split(',');
        }
        // if the faction is not already in the list add it, otherwise remove it
        const index = listOfFactions.indexOf(newFaction);
        if (index === -1) {
            listOfFactions.push(newFaction);
        } else {
            listOfFactions.splice(index, 1);
        }
        // rebuild the list of factions into a string of factions
        dict.faction = listOfFactions.join();
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerListPoints(event, userId) {
        let newListPoints = event.target.value;
        if (newListPoints === '') newListPoints = null;
        const dict = this.state.formPlayerDict[userId];
        dict.list_points = newListPoints;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangePlayerList(event, userId) {
        let newListId = event.target.value;
        if (newListId === 'none') newListId = null;
        const dict = this.state.formPlayerDict[userId];
        dict.list_id = newListId;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });
    }

    onChangeWinner(event, userId) {
        let winner = event.target.checked;
        const dict = this.state.formPlayerDict[userId];
        dict.winner = winner;
        this.setState((state) => {
            return {formPlayerDict: {...state.formPlayerDict, [userId]: dict}}
        });

        // if a winner is changed manually, don't do any auto selection
        this.autoSelectWinner = false;
    }

    onRemovePlayer(userId) {
        const modifiedPlayerIdList = [...this.state.formPlayerIds];
        const modifiedPlayerDict = {...this.state.formPlayerDict};
        const index = modifiedPlayerIdList.indexOf(userId);
        modifiedPlayerIdList.splice(index, 1);
        delete modifiedPlayerDict[userId];
        this.setState({formPlayerIds: modifiedPlayerIdList, formPlayerDict: modifiedPlayerDict, formBye: false});
    }

    onHide() {
        // TODO if there are unsaved changes alert the user
        undoOnBackCancelModal();
        this.props.onCancel('no-change');
    }

    handleFormDate(event) {
        this.setState({formDate: event.target.value});
    }
    
    handleFormEvent(event) {
        this.setState({formEventId: event.target.value});
    }

    handleFormTournament(event) {
        let newTournamentId = event.target.value;
        // if the user clears the tournament, then need to send a null to the server and set the tournament round id to null as well
        // eslint-disable-next-line eqeqeq
        if (newTournamentId == 0) {
            this.setState({isTournamentGame: false, formTournamentId: 0, formTournamentRoundId: 0});
        }
        // user set it to something other than zero (set a tournament), don't send anything until the round is correctly set
        else {
            this.setState({isTournamentGame: true, formTournamentId: newTournamentId, formTournamentRoundId: 0});
            const sub = this.restPubSub.subscribeNoRefresh('tournament', newTournamentId, (d, k)=>{this.updateTournament(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    handleFormTournamentRound(event) {
        let newRoundId = event.target.value;
        // if the user removes the tournament round - also remove the tournament...
        // eslint-disable-next-line eqeqeq
        if (newRoundId == 0) {
            this.setState({isTournamentGame: false, formTournamentId: 0, formTournamentRoundId: 0});
        }
        // when the user sets the round, then we also set the tournament id with the server
        else {
            this.setState({isTournamentGame: true, formTournamentRoundId: event.target.value});
        }
    }

    handleFormBoardGame(event) {
        let boardGameValue = event.target.value;
        let showAddNewBoardGameModal = false;
        // eslint-disable-next-line eqeqeq
        if (boardGameValue == -1) {
            showAddNewBoardGameModal = true;
            boardGameValue = 0;
        }
        this.setState({formBoardGameId: boardGameValue, showAddNewBoardGameModal: showAddNewBoardGameModal});  
    }

    handleFormDetails(event) {
        this.setState({formDetails: event.target.value});
    }

    handleFormTableName(event) {
        this.setState({formTableName: event.target.value});
    }

    handleFormBye(event) {
        const isBye = event.target.checked
        let newTableName = this.state.formTableName;
        if (isBye) {
            this.shadowTableName = newTableName;
            newTableName = 'No Table';
        }
        else {
            if (this.shadowTableName !== 'No Table' && this.shadowTableName !== '') {
                newTableName = this.shadowTableName;
            } else {
                newTableName = 'New Table';
            }
        }
        this.setState({formBye: isBye, formTableName: newTableName});
    }
    
    handleFormCompletion(value) {
        this.setState({formCompletion: value});

        // if the completion is changed manually, don't do any auto selection
        this.autoSelectCompletion = false;
    }

    handleFormGamePoints(event) {
        let value = event.target.value;
        this.setState({formGamePoints: value});
    }

    handleFormFirstPlayer(event) {
        let value = event.target.value;
        this.setState({formFirstPlayer: value});
    }

    resetAutoCompletionTimeout() {
        if (this.autoCompletionTimeout) clearTimeout(this.autoCompletionTimeout);
        this.autoCompletionTimeout = setTimeout(()=>this.checkAutoCompletion(), 1000);
    }

    checkAutoCompletion() {
        // don't do anything automatic if this is a bye or if there are less than 2 players
        if (this.state.formBye) return;
        if (this.state.formPlayerIds.length < 2) return;

        // if we've already auto selected everything just get out
        if (this.autoSelectWinner === false && this.autoSelectCompletion === false) return;

        const newPlayersDict = {...this.state.formPlayerDict};

        // figure out if all players are scored
        let allPlayersScored = true;
        for (const playerDict of Object.values(newPlayersDict)) {
            // eslint-disable-next-line eqeqeq
            if (playerDict.score == 0) {
                allPlayersScored = false;
                break;
            }
        }

        // if all players are scored we can assume the highest score is the winner
        if (this.autoSelectWinner) {
            if (allPlayersScored) {
                let highScore = 0;
                for (const playerDict of Object.values(newPlayersDict)) {
                    const playerScore = parseInt(playerDict.score);
                    if (highScore === 0) highScore = playerScore;
                    if (playerScore > highScore) {
                        highScore = playerScore;
                    }
                }
                for (const playerDict of Object.values(newPlayersDict)) {
                    const playerScore = parseInt(playerDict.score);
                    if (playerScore === highScore) {
                        playerDict.winner = true;
                    } else {
                        playerDict.winner = false;
                    }
                }
                this.autoSelectWinner = false;
                this.setState({formPlayerDict: newPlayersDict});
            }
        }

        // figure out if there is a winner assigned - this might have occured as part of the previous section
        let haveWinnerAssigned = false;
        for (const playerDict of Object.values(newPlayersDict)) {
            if (playerDict.winner) {
                haveWinnerAssigned = true;
                break;
            }
        }

        // if all players are scored and there is a winner, we can assume the game is complete
        if (this.autoSelectCompletion) {
            // if the game is already completed then we're done
            if (this.state.formCompletion === 'completed') this.autoSelectCompletion = false;
            if (allPlayersScored && haveWinnerAssigned) {
                this.autoSelectCompletion = false;
                this.setState({formCompletion: 'completed'});
            } 
        }
    }

    onProposeScheduleClicked() {
        this.setState({showProposeScheduleModal: true});
    }

    onProposeSchedule(datetime) {
        if (datetime !== null && this.state.formProposedDateTime !== datetime) {
            this.setState({formProposedDateTime: datetime, sendInvites: true, showProposeScheduleModal: false});
        } else if (datetime === null) {
            this.setState({formProposedDateTime: null, sendInvites: false, showProposeScheduleModal: false});
        } else {
            this.setState({showProposeScheduleModal: false});
        }
    }

    onClickSave(checkGameState) {
        let newTableName = this.state.formTableName.trimEnd();
        if (newTableName.length === 0) newTableName = null;
        let newDetails = this.state.formDetails.trimEnd();

        if (checkGameState) {
            let gameCompleted = this.state.formCompletion === 'completed';
            let numPlayers = this.state.formPlayerIds.length;
            let numWinners = 0;
            for (const playerId of this.state.formPlayerIds) {
                const playerDict = this.state.formPlayerDict[playerId];
                if (playerDict.winner) {
                    numWinners++;
                }
            }

            // check for a tie condition during tournaments
            if (gameCompleted && this.state.formTournamentId && numPlayers === 2 && numPlayers === numWinners) {
                let tiedScore = null;
                let scoresAreTheSame = true;
                for (const playerId of this.state.formPlayerIds) {
                    const playerDict = this.state.formPlayerDict[playerId];
                    if (tiedScore === null) {
                        tiedScore = playerDict.score;
                    } else if (playerDict.score !== tiedScore) {
                        scoresAreTheSame = false;
                        break;
                    }
                }
                if (!scoresAreTheSame) {
                    this.setState({showIsTiedConfirmModal: true});
                    return;
                }
            }

            // check if the game looks incomplete
            let noPlayers = false;
            let shouldHaveTwoPlayers = false;
            let noWinner = false;
            if (numPlayers === 0) noPlayers = true;
            if (!noPlayers && numWinners === 0) noWinner = true;
            if (this.state.formTournamentId && !this.state.formBye && numPlayers !== 2) shouldHaveTwoPlayers = true;
                
            if (gameCompleted && (noPlayers || shouldHaveTwoPlayers || noWinner)) {
                const gameIncompleteJsx = 
                    <div>
                        You have marked this game complete, but it might be missing something:
                        <ul>
                            {noPlayers && <li>No players recorded</li>}
                            {noWinner && <li>No winners were reported (everyone lost)</li>}
                            {shouldHaveTwoPlayers && <li>Tournament games should normally have 2 players</li>}
                        </ul>
                    </div>
                this.setState({showGameIncompleteModal: true, gameIncompleteJsx: gameIncompleteJsx});
                return;
            }

            // check if the game looks complete
            if (!gameCompleted && numPlayers > 0 && numWinners > 0 && this.state.formDate !== '') {
                this.setState({showIsGameCompleteModal: true});
                return;
            }
        }
        
        // only set the board game id if this is a board game league, and then leave it null if the value selected isn't a legit value
        let newBoardGameId = null;
        if (this.state.isBoardGameLeague) {
            newBoardGameId = parseInt(this.state.formBoardGameId);
            if (newBoardGameId < 1) newBoardGameId = null;
        }

        // need to send null if the game points or first player aren't actually set
        let newGamePoints = this.state.formGamePoints;
        let newFirstPlayerId = this.state.formFirstPlayer;
        if (newGamePoints === '' || newGamePoints < 0) newGamePoints = null;
        // eslint-disable-next-line eqeqeq
        if (newFirstPlayerId == 0) newFirstPlayerId = null;

        let eventId = parseInt(this.state.formEventId);
        if (eventId === 0) eventId = null;

        const patchData = {
            completion: this.state.formCompletion,
            bye: this.state.formBye,
            table_name: newTableName,
            details: newDetails,
            date: this.state.formDate,
            proposed_datetime: this.state.formProposedDateTime,
            final_update: true,
            board_game_id: newBoardGameId,
            game_points: newGamePoints,
            first_player_id: newFirstPlayerId,
            players: [],
            event_id: eventId,
        }

        if (this.state.league.isManager(this.props.localUser.id)) {
            let tournamentId = parseInt(this.state.formTournamentId);
            if (tournamentId === 0) tournamentId = null;
            let roundId = parseInt(this.state.formTournamentRoundId);
            if (roundId === 0) roundId = null;
            // eslint-disable-next-line eqeqeq
            patchData['tournament_id'] = tournamentId;
            // eslint-disable-next-line eqeqeq
            patchData['round_id'] = roundId;
        }

        for (const playerId of this.state.formPlayerIds) {
            const playerDict = this.state.formPlayerDict[playerId];
            if (playerDict.score === '') playerDict.score = 0;
            if (playerDict.score1 === '') playerDict.score1 = 0;
            if (playerDict.score2 === '') playerDict.score2 = 0;
            if (playerDict.score3 === '') playerDict.score3 = 0;
            patchData.players.push(playerDict);
        }

        this.restPubSub.patch('game', this.state.gameId, patchData);
        this.setState({updating: true, onUpdateCancel: true, closeResult: 'updated'});

        // force a league refresh because we might have updated the board games
        if (this.state.isBoardGameLeague) this.restPubSub.refresh('league', this.state.league.id, 1000);
    }

    onDeleteClicked() {
        this.setState({showDeleteConfirmModal: true});
    }

    onDeleteGame() {
        this.restApi.genericDeleteEndpointData('game', this.state.gameId)
        .then((response)=>{
            undoOnBackCancelModal(); 
            this.props.onCancel('deleted');
        })
        .catch((error)=>{
            console.error(error);
            undoOnBackCancelModal(); 
            this.props.onCancel('error');
        });
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);
        if (this.state.game === null) return(<NerdHerderLoadingModal/>);
        if (this.state.league === null) return(<NerdHerderLoadingModal/>);
        if (this.state.topic === null)  return(<NerdHerderLoadingModal/>);
        if (this.state.isTournamentGame && this.state.tournament === null) return(<NerdHerderLoadingModal/>);
        if (this.state.isEventGame && this.state.event === null) return(<NerdHerderLoadingModal/>);
        if (this.state.topic.game_player_has_faction && this.state.leagueLastFactionDict === null)  return(<NerdHerderLoadingModal/>);
        if (this.state.showProposeScheduleModal) return(
            <NerdHerderScheduleGameModal
                                    localUser={this.props.localUser}
                                    league={this.state.league}
                                    game={this.state.game}
                                    playerIdList={this.state.formPlayerIds}
                                    onAccept={(dt)=>this.onProposeSchedule(dt)}
                                    onCancel={()=>this.setState({showProposeScheduleModal: false})}/>
        );
        if (this.state.showDeleteConfirmModal) return(
            <NerdHerderConfirmModal title='Delete Game?'
                                    message='The game will be removed and all players and scores information will be deleted. This cannot be undone. Are you sure you want to delete this game?'
                                    acceptButtonText='Delete Game'
                                    onAccept={()=>this.onDeleteGame()}
                                    onCancel={()=>this.setState({showDeleteConfirmModal: false})}/>
        );
        if (this.state.showIsTiedConfirmModal) return(
            <NerdHerderConfirmModal title='Tied Game?'
                                    message="You have reported a tie by ticking the 'won' box for all players. Did you intend to report a tie or do you want to go back and change it?"
                                    acceptButtonText='Report Tie'
                                    cancelButtonText='Go Back'
                                    onAccept={()=>this.onClickSave(false)}
                                    onCancel={()=>this.setState({showIsTiedConfirmModal: false})}/>
        );
        if (this.state.showIsGameCompleteModal) return(
            <NerdHerderConfirmModal title='Game Complete?'
                                    message="This game looks complete. Mark it completed before saving?"
                                    acceptButtonText='Yes'
                                    cancelButtonText='No'
                                    onAccept={()=>{this.setState({formCompletion: 'completed'}); setTimeout(()=>this.onClickSave(false, 100))}}
                                    onCancel={()=>this.onClickSave(false)}/>
        );
        if (this.state.showGameIncompleteModal) return(
            <NerdHerderConfirmModal title='Game Incomplete'
                                    message={this.state.gameIncompleteJsx}
                                    acceptButtonText='Save Anyway'
                                    cancelButtonText='Go Back'
                                    onAccept={()=>this.onClickSave(false)}
                                    onCancel={()=>this.setState({showGameIncompleteModal: false})}/>
        );

        // if we get here then we have enough data to begin rendering - check for auto setting the faction info
        if (this.autoUpdateFactions) {
            setTimeout(()=>this.updateDefaultFactions(), 0);
        }
        
        // if we get here then we have enough data to begin rendering - but grab anything outstanding
        if (this.state.backgroundLoadIssued === false) {
            setTimeout(()=>this.backgroundLoadSelectableModels(this.state.league), 1500);
        }

        let errorFeedback = null;
        let maxDetailsLength = 4096;

        // shortcut variables
        const gameData = this.state.game;
        const isTournamentGame = this.state.isTournamentGame;
        const isEventGame = this.state.isEventGame;
        const isUpdating = this.state.updating;
        const leagueData = this.state.league;
        const topicData = this.state.topic;
        const eventData = this.state.event;
        const tournamentData = this.state.tournament;
        const localUserData = this.props.localUser;
        const playersDict = this.state.game.getPlayersDict(leagueData);
        const gameFullyVerified = this.state.game.isFullyVerified();
        let disableScheduleButton = true;

        let listItem = null;
        listItem = <GameListItem game={gameData} tournament={tournamentData} event={eventData} league={leagueData} localUser={localUserData} onClick={null} noManageIcon={true} noVerifyIcon={true} noScheduleIcon={true}/>

        // if we have a league and topic, might also have special nouns...
        let listNoun = '';
        let listNounPlural = '';
        let factionNoun = '';
        let pointsNoun = '';
        let firstPlayerNoun = '';
        if (leagueData !== null && topicData !== null) {
            listNoun = topicData.list_noun;
            listNounPlural = pluralize(listNoun);
            factionNoun = topicData.faction_noun;
            pointsNoun = topicData.points_noun;
            firstPlayerNoun = topicData.first_player_noun;
        }

        // see if there are list containers
        let listContainers = null;
        if (isTournamentGame) {
            if (tournamentData.list_container_ids.length !== 0) listContainers = tournamentData.list_containers;
        } else if (isEventGame) {
            if (eventData.list_container_ids.length !== 0) listContainers = eventData.list_containers;
        } else {
            if (leagueData.list_container_ids.length !== 0) {
                listContainers = [];
                for (const listContainer of leagueData.list_containers) {
                    if (listContainer.event_id === null && listContainer.tournament_id === null) {
                        listContainers.push(listContainer);
                    }
                }
            }
        }
        if (listContainers !== null && listContainers.length === 0) listContainers = null;

        // league managers get all kinds of special features
        let isLeagueManager = false;
        let showManagerView = false;
        if (leagueData.isManager(localUserData.id)) {
            isLeagueManager = true;
            if (this.state.showWhichView === 'manager') {
                showManagerView = true;
            }
        }

        // also handy to know if this user is a player of the league or of the game
        let isLeaguePlayer = false;
        let isGamePlayer = false;
        if (leagueData.isPlayer(localUserData.id)) {
            isLeaguePlayer = true;
        }
        if (playersDict.winnerPlayerIds.includes(localUserData.id) || playersDict.loserPlayerIds.includes(localUserData.id)) {
            isGamePlayer = true;
        }

        // also handy to know if this user is a player or manager of the associated tournament
        let isTournamentManager = false;
        let isTournamentPlayer = false;
        if (isTournamentGame) {
            if (tournamentData.isManager(localUserData.id)) isTournamentManager = true;
            /*if (tournamentData.isPlayer(localUserData.id)) isTournamentPlayer = true;*/
        }

        // also handy to know if this user is a player or manager of the associated event
        /*let isEventManager = false;
        let isEventPlayer = false;
        if (isEventGame) {
            if (eventData.isManager(localUserData.id)) isEventManager = true;
            if (eventData.isPlayer(localUserData.id)) isEventPlayer = true;
        }*/

        // lockdown some stuff depending on the game type
        let lockAddDeletePlayerButtons = false;
        let lockEventFormField = false;
        if (isTournamentGame) {
            if (!isTournamentManager) {
                lockAddDeletePlayerButtons = true;
                lockEventFormField = true;
            }
        }

        // a game can be scheduled if the user is a player or manager, and if the game is not complete
        if ((isGamePlayer || isLeagueManager) && this.state.formCompletion !== 'completed') {
            disableScheduleButton = false;
        }

        // the list of eligible players varies depending on if this is a tournament, event, or regular league game...
        let eligiblePlayerIdList = null;
        if (isTournamentGame) {
            eligiblePlayerIdList = tournamentData.player_ids;
        } else if (isEventGame) {
            eligiblePlayerIdList = eventData.player_ids;
        } else {
            eligiblePlayerIdList = leagueData.player_ids;
        }

        // generate the add player button - but only if there are players left to add & not locked for tournament protection
        // in the case of tournaments hide the button when there are 2 players
        let addPlayersButton = null;
        if (!lockAddDeletePlayerButtons && this.state.formPlayerIds.length < eligiblePlayerIdList.length &&
            ( (isTournamentGame && this.state.formPlayerIds.length < 2) || (!isTournamentGame) ) ) {
            addPlayersButton =
                <div className='text-end'>
                    <Button size='sm' variant='primary' onClick={()=>{this.onAddPlayers()}} disabled={isUpdating}>Add Player</Button>
                </div>
        }
        let cancelAddPlayersButton = null;
        if (!lockAddDeletePlayerButtons) {
            cancelAddPlayersButton = 
                <div className='text-end'>
                    <Button size='sm' variant='secondary' onClick={()=>{this.onCancelAddPlayers()}} disabled={isUpdating}>Cancel</Button>
                </div>
        }
        
        // generate the events list
        // todo - if the event changes we need to verify that all players are in the event
        let eventOptions = [];
        const nullEventItem = <option key={0} value={0}>No Minor Event</option>
        eventOptions.push(nullEventItem);
        for (const eventId of leagueData.event_ids) {
            let eventItem = null;
            // if we've background loaded this event, then use its name, otherwise use the id
            if (this.state.events.hasOwnProperty(eventId)) {
                eventItem = <option key={eventId} value={eventId}>{this.state.events[eventId].name}</option>
            } else {
                eventItem = <option key={eventId} value={eventId}>Minor Event {eventId}</option>
            }
            eventOptions.push(eventItem);
        }

        // generate the tournaments list - for managers only
        // todo - if there is a tournament going on today, select that by default
        // todo - if the tournament changes we need to verify that all users in the game are in the tournament
        let tournamentOptions = [];
        const nullTournamentItem = <option key={0} value={0}>No Tournament</option>
        tournamentOptions.push(nullTournamentItem);
        if (isLeagueManager && showManagerView) {
            for (const tournamentId of leagueData.tournament_ids) {
                let tournamentItem = null;
                // if we've background loaded this tournament, then use its name, otherwise use the id
                if (this.state.tournaments.hasOwnProperty(tournamentId)) {
                    tournamentItem = <option key={tournamentId} value={tournamentId}>{this.state.tournaments[tournamentId].name}</option>
                } else {
                    tournamentItem = <option key={tournamentId} value={tournamentId}>Tournament {tournamentId}</option>
                }
                tournamentOptions.push(tournamentItem);
            }
        }

        // generate the tournament rounds list - for managers only, and only if a tournament is selected & showing & actually loaded
        let tournamentRoundOptions = [];
        const nullTournamentRoundItem = <option key={0} value={0}>Round Not Set</option>
        tournamentRoundOptions.push(nullTournamentRoundItem);
        if (isLeagueManager && showManagerView && this.state.formTournamentId !== 0 && this.state.tournaments.hasOwnProperty(this.state.formTournamentId)) {
            const selectedTournamentData = this.state.tournaments[this.state.formTournamentId];
            for (const tournamentRoundId of selectedTournamentData.round_ids) {
                const tournamentRoundItem = <option key={tournamentRoundId} value={tournamentRoundId}>{this.state.tournamentRounds[tournamentRoundId].name}</option>
                tournamentRoundOptions.push(tournamentRoundItem); 
            }
        }

        // it is 'bad' to change the tournament if the game is part of an elimination tournament (breaks stuff)
        let lockTournamentFormField = false;
        if (tournamentData && tournamentData.type === 'elimination') {
            lockTournamentFormField = true;
        }

        // generate the board game options - these only matter if the topic is board games
        let boardGameOptions = [];
        const nullBoardGameItem = <option key={0} value={0}>No Board Game Selected</option>
        const newBoardGameItem = <option key={-1} value={-1}>Add New Board Game</option>
        boardGameOptions.push(nullBoardGameItem);
        boardGameOptions.push(newBoardGameItem);
        if (this.state.isBoardGameLeague) {
            let selectedBoardGameId = parseInt(this.state.formBoardGameId);
            if (selectedBoardGameId !== 0 && selectedBoardGameId !== -1 && this.state.boardGames !== null && leagueData && !leagueData.board_game_ids.includes(selectedBoardGameId)) {
                if (this.state.boardGames.hasOwnProperty(selectedBoardGameId)) {
                    const selectedBoardGame = this.state.boardGames[selectedBoardGameId];
                    const unlistedBoardGameItem = <option key={selectedBoardGameId} value={selectedBoardGameId}>{selectedBoardGame.name}</option>
                    boardGameOptions.push(unlistedBoardGameItem);
                }
            }
            for (const boardGame of leagueData.board_games) {
                let boardGameItem = <option key={boardGame.id} value={boardGame.id}>{boardGame.name}</option>
                boardGameOptions.push(boardGameItem);
            }
        }

        // generate the first player options - these only matter if we collect stats for this game, and if this is a stat collected
        let firstPlayerOptions = [];
        const nullFirstPlayerItem = <option key={0} value={0}>No {capitalizeFirstLetters(firstPlayerNoun)} Player</option>
        firstPlayerOptions.push(nullFirstPlayerItem);
        if (topicData !== null && topicData.collect_game_stats && topicData.game_has_first_player) {
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const userData = this.state.usersCache[playerId];
                let firstPlayerItem = <option key={playerId} value={playerId}>{userData.username}</option>
                firstPlayerOptions.push(firstPlayerItem);
            }
        }

        // generate the scores tables
        const playerTableRows = [];
        const playerTableRows2 = [];
        const playerTableRows3 = [];
        if (this.state.centerSectionMode==='scores') {
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle"><Form.Control size='sm' type="number" value={thisPlayerDict.score} disabled={isUpdating} onChange={(e)=>this.onChangeScore(e, playerId, 1)} onSelect={(e)=>this.onSelectScore(e, playerId, 1)}/></td>
                        <td className="text-center align-middle"><Form.Check aria-label="winner" value={playerId} checked={thisPlayerDict.winner} disabled={isUpdating} onChange={(e)=>this.onChangeWinner(e, playerId)}/></td>
                        {!lockAddDeletePlayerButtons && 
                        <td className="text-center align-middle"><Button size='sm' variant='danger' disabled={isUpdating} onClick={()=>this.onRemovePlayer(playerId)}><NerdHerderFontIcon icon='flaticon-recycle-bin-filled-tool'/></Button></td>}
                    </tr>
                playerTableRows.push(newRow);

                if (topicData && topicData.scores_recorded >= 2) {
                    const newRow2 = 
                        <tr key={playerId}>
                            <td className="align-middle">
                                <div onClick={()=>this.showUserProfile(playerId)}>
                                    <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                    {this.state.usersCache[playerId].username}
                                    {this.state.usersCache[playerId].short_name &&
                                    <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                                </div>
                            </td>
                            <td className="text-center align-middle"><Form.Control size='sm' type="number" value={thisPlayerDict.score2} disabled={isUpdating} onChange={(e)=>this.onChangeScore(e, playerId, 2)} onSelect={(e)=>this.onSelectScore(e, playerId, 2)}/></td>
                        </tr>
                    playerTableRows2.push(newRow2);
                }

                if (topicData && topicData.scores_recorded >= 3) {
                    const newRow3 = 
                        <tr key={playerId}>
                            <td className="align-middle">
                                <div onClick={()=>this.showUserProfile(playerId)}>
                                    <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                    {this.state.usersCache[playerId].username}
                                    {this.state.usersCache[playerId].short_name &&
                                    <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                                </div>
                            </td>
                            <td className="text-center align-middle"><Form.Control size='sm' type="number" value={thisPlayerDict.score3} disabled={isUpdating} onChange={(e)=>this.onChangeScore(e, playerId, 3)} onSelect={(e)=>this.onSelectScore(e, playerId, 3)}/></td>
                        </tr>
                    playerTableRows3.push(newRow3);
                }
            }
        }

        // generate the players table
        const eligiblePlayersListItems = [];
        if (this.state.centerSectionMode==='players') {
            for (const playerId of eligiblePlayerIdList) {
                // don't add list items for players already in the game
                if (this.state.formPlayerIds.includes(playerId)) continue;

                const newItem = 
                    <UserListItem slim={true} key={playerId} userId={playerId} user={this.state.usersCache[playerId]} localUser={this.props.localUser} onClick={()=>this.onPlayerAdded(playerId)}/>
                eligiblePlayersListItems.push(newItem);
            }
        }

        // generate the factions table
        const factionTableRows = [];
        let showFactionSelection = false;
        if (topicData !== null && topicData.collect_game_stats && topicData.game_player_has_faction) {
            const factionDict = topicData.getFactionDict();
            const factionOptions = [];
            if (!topicData.game_player_multi_faction) {
                const notsetOptionItem = <option key={'notset'} value={'notset'}>No {capitalizeFirstLetters(factionNoun)} Set</option>
                factionOptions.push(notsetOptionItem);
            }
            for (const [factionId, factionName] of Object.entries(factionDict)) {
                const optionItem = <option key={factionId} value={factionId}>{factionName}</option>
                factionOptions.push(optionItem);
                showFactionSelection = true;
            }

            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                let thisPlayerFactionList = [];
                if (topicData.game_player_multi_faction) {
                    if (thisPlayerDict.faction !== null) {
                        thisPlayerFactionList = thisPlayerDict.faction.split(',');
                    }
                }
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle">
                            {!topicData.game_player_multi_faction &&
                            <Form.Select size='sm' onChange={(e)=>this.onChangePlayerFaction(e, playerId)} value={thisPlayerDict.faction || 'notset'} disabled={isUpdating}>
                                {factionOptions}
                            </Form.Select>}
                            {topicData.game_player_multi_faction &&
                            <Form.Select size='sm' multiple={true} onChange={(e)=>this.onChangePlayerFactionMulti(e, playerId)} value={thisPlayerFactionList} disabled={isUpdating}>
                                {factionOptions}
                            </Form.Select>}
                        </td>
                    </tr>
                factionTableRows.push(newRow);
            }
        }

        // generate the points table
        const pointsTableRows = [];
        if (topicData !== null && topicData.collect_game_stats && topicData.game_player_has_points) {
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle">
                            <Form.Control size='sm' type="number" value={thisPlayerDict.list_points || ''} disabled={isUpdating} onChange={(e)=>this.onChangePlayerListPoints(e, playerId)} min={0}/>
                        </td>
                    </tr>
                pointsTableRows.push(newRow);
            }
        }

        // generate the player's lists table
        const listsTableRows = [];
        if (topicData !== null && listContainers !== null) {
            // first make a dict of all the list containers with a list of players who have uploaded to that container as the value
            const listContainerDict = {};
            const listContainerFileIdDict = {};
            for (const listContainer of listContainers) {
                listContainerDict[listContainer.id] = [];
                for (const file of listContainer.files) {
                    listContainerDict[listContainer.id].push(file.user_id);
                    listContainerFileIdDict[`${listContainer.id}-${file.user_id}`] = file.id;
                }
            }
            // next go through each player, and create the JSX with that player's lists
            for (const playerId of this.state.formPlayerIds) {
                if (!this.state.usersCache.hasOwnProperty(playerId)) continue;
                const thisPlayerDict = this.state.formPlayerDict[playerId];
                const listOptions = [];
                const nullListItem = <option key={`${playerId}-none`} value={'none'}>No {capitalizeFirstLetters(listNoun)} Set</option>
                listOptions.push(nullListItem);
                for (const listContainer of listContainers) {
                    let listItem = null;
                    if (listContainerDict[listContainer.id].includes(playerId)) {
                        const fileId = listContainerFileIdDict[`${listContainer.id}-${playerId}`];
                        listItem = <option key={`${playerId}-${listContainer.id}-${fileId}`} value={fileId}>{listContainer.name}</option>
                    } else {
                        listItem = <option key={`${playerId}-${listContainer.id}-disabled`} value={'disabled'} disabled={true}>{listContainer.name}</option>
                    }
                    listOptions.push(listItem);
                }
                const newRow = 
                    <tr key={playerId}>
                        <td className="align-middle">
                            <div onClick={()=>this.showUserProfile(playerId)}>
                                <Image className='rounded-circle float-start me-1' src={this.state.usersCache[playerId].getImageUrl()} height='25px' width='25px' alt='player profile image'/>
                                {this.state.usersCache[playerId].username}
                                {this.state.usersCache[playerId].short_name &&
                                <small className='text-muted'> ({this.state.usersCache[playerId].short_name})</small>}
                            </div>
                        </td>
                        <td className="text-center align-middle">
                            <Form.Select size='sm' onChange={(e)=>this.onChangePlayerList(e, playerId)} value={thisPlayerDict.list_id || 'none'} disabled={isUpdating}>
                                {listOptions}
                            </Form.Select>
                        </td>
                    </tr>
                listsTableRows.push(newRow);
            }
        }

        // if there is anything other than exactly 1 player, the bye option is disabled
        let byeOptionDisabled = true;
        if (isTournamentGame && this.state.formPlayerIds.length === 1) {
            byeOptionDisabled = false;
        }

        // generate the text that goes under the scheduled/inprogress/completed slider
        let completionTitle = null;
        let completionText = null;
        switch (this.state.formCompletion) {
            case "scheduled":
            case 0:
                completionTitle = 'Scheduled';
                completionText = 'This game has not been started (even if the scheduled day is in the past).';
                break;
            case "in-progress":
            case 1:
                completionTitle = 'In-Progress';
                completionText = 'This game is being played, or is just about to begin.';
                break;
            case "completed":
            case 2:
                completionTitle = 'Completed';
                completionText = 'The game is over, and the details entered above are the final outcome (but may need review).';
                break;
            default:
                console.error(`got invalid formCompletion value: ${this.state.formCompletion}`);
                completionTitle = 'Scheduled';
                completionText = 'This game has not been started (even if the scheduled day is in the past).';
        }

        // if the user is a manager and they are in the middle of updating tournament/tournament round stuff, give them a little warning
        let setTournamentRoundMessage = null;
        // eslint-disable-next-line eqeqeq
        if (isLeagueManager && showManagerView && this.state.formTournamentId != 0 && this.state.formTournamentRoundId == 0) {
            setTournamentRoundMessage = <Form.Text className='text-danger' size='sm'>Tournament is not updated until valid round is set</Form.Text>
        }

        // gameData is from the server, and this.state.formXXXX is our local copy
        let serverMismatch = false;
        if (gameData.date !== this.state.formDate) serverMismatch = true;
        // eslint-disable-next-line eqeqeq
        if (gameData.proposed_datetime !== this.state.formProposedDateTime) serverMismatch = true;
        if (this.state.sendInvites) serverMismatch = true;
        // eslint-disable-next-line eqeqeq
        if (gameData.event_id === null && this.state.formEventId != 0) serverMismatch = true;
        // eslint-disable-next-line eqeqeq
        if (gameData.event_id !== null && gameData.event_id != this.state.formEventId) serverMismatch = true;
        // eslint-disable-next-line eqeqeq
        if (gameData.tournament_id === null && this.state.formTournamentId != 0) serverMismatch = true;
        // eslint-disable-next-line eqeqeq
        if (gameData.tournament_id !== null && gameData.tournament_id != this.state.formTournamentId) serverMismatch = true;
        // eslint-disable-next-line eqeqeq
        if (gameData.round_id === null && this.state.formTournamentRoundId != 0) serverMismatch = true;
        if (this.state.isBoardGameLeague) {
            // eslint-disable-next-line eqeqeq
            if (gameData.board_game_id === null && this.state.formBoardGameId != 0) serverMismatch = true;
            // eslint-disable-next-line eqeqeq
            if (gameData.board_game_id !== null && gameData.board_game_id != this.state.formBoardGameId) serverMismatch = true;
        }
        if (topicData && topicData.collect_game_stats && topicData.game_has_first_player) {
            // eslint-disable-next-line eqeqeq
            if (gameData.first_player_id === null && this.state.formFirstPlayer != 0) serverMismatch = true;
            // eslint-disable-next-line eqeqeq
            if (gameData.first_player_id !== null && gameData.first_player_id != this.state.formFirstPlayer) serverMismatch = true;
        }
        if (topicData && topicData.collect_game_stats && topicData.game_has_points) {
            // eslint-disable-next-line eqeqeq
            if (gameData.game_points === null && this.state.formGamePoints != 0) serverMismatch = true;
            // eslint-disable-next-line eqeqeq
            if (gameData.game_points !== null && gameData.game_points != this.state.formGamePoints) serverMismatch = true;
        }
        // eslint-disable-next-line eqeqeq
        if (gameData.round_id !== null && gameData.round_id != this.state.formTournamentRoundId) serverMismatch = true;
        if (gameData.completion !== this.state.formCompletion) serverMismatch = true;
        if (gameData.details !== this.state.formDetails) serverMismatch = true;
        if (gameData.table_name === null && this.state.formTableName !== '') serverMismatch = true;
        if (gameData.table_name !== null && gameData.table_name !== this.state.formTableName) serverMismatch = true;
        if (gameData.bye !== this.state.formBye) serverMismatch = true;
        // see if there are any players on the server not in our local copy
        for (const [serverPlayerId, serverPlayerData] of Object.entries(playersDict.usersGames)) {
            if (!this.state.formPlayerDict.hasOwnProperty(serverPlayerId)) {
                serverMismatch = true;
                continue;
            }
            const localPlayerData = this.state.formPlayerDict[serverPlayerId];
            if (localPlayerData.winner !== serverPlayerData.winner) serverMismatch = true;
            if (localPlayerData.list_id !== serverPlayerData.list_id) serverMismatch = true;
            if (localPlayerData.score !== serverPlayerData.score) serverMismatch = true;
            if (localPlayerData.score1 !== serverPlayerData.score1) serverMismatch = true;
            if (topicData.scores_recorded >= 2 && localPlayerData.score2 !== serverPlayerData.score2) serverMismatch = true;
            if (topicData.scores_recorded >= 3 && localPlayerData.score3 !== serverPlayerData.score3) serverMismatch = true;
            if (topicData && topicData.collect_game_stats && topicData.game_player_has_faction) {
                if (localPlayerData.faction !== serverPlayerData.faction) serverMismatch = true;
            }
            if (topicData && topicData.collect_game_stats && topicData.game_player_has_points) {
                if (localPlayerData.list_points !== serverPlayerData.list_points) serverMismatch = true;
            }
        }
        // see if there are any players in our local copy not on the server
        for (const localPlayerId of Object.keys(this.state.formPlayerDict)) {
            if (!playersDict.usersGames.hasOwnProperty(localPlayerId)) {
                serverMismatch = true;
                continue;
            }
        }

        // if there is a mismatch between what the server has and what the user has, allow an update
        let saveButtonDisabled = true;
        if (serverMismatch) saveButtonDisabled = false;

        if (this.state.showUserProfileModal) {
            return (
                <NerdHerderUserProfileModal userId={this.state.selectedUserIdForProfileModal}
                                            localUser={this.props.localUser} 
                                            onCancel={()=>this.hideUserProfile()}/>
            );
        }

        if (this.state.showAddNewBoardGameModal) {
            return (
                <NerdHerderNewBoardGameModal localUser={this.props.localUser}
                                             league={this.props.league}
                                             onCancel={(v)=>this.hideAddNewBoardGameModal(v)}/>
            );
        }

        let score1Help = null;
        let score2Help = null;
        let score3Help = null;
        let pointsHelp = null;
        let playerPointsHelp = null;
        if (topicData.scores_recorded >= 1 && topicData.score1_help) {
            score1Help = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={capitalizeFirstLetters(topicData.score1_noun)} message={topicData.score1_help} placement='bottom'/></span>
        }
        if (topicData.scores_recorded >= 2 && topicData.score2_help) {
            score2Help = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={capitalizeFirstLetters(topicData.score2_noun)} message={topicData.score2_help} placement='bottom'/></span>
        }
        if (topicData.scores_recorded >= 3 && topicData.score3_help) {
            score3Help = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={capitalizeFirstLetters(topicData.score3_noun)} message={topicData.score3_help} placement='bottom'/></span>
        }
        if (topicData.game_has_points_help) {
            pointsHelp = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={`${capitalizeFirstLetters(pointsNoun)} Level`} message={topicData.game_has_points_help} placement='bottom'/></span>
        }
        if (topicData.game_player_has_points_help) {
            playerPointsHelp = <span style={{fontWeight: 'normal'}}> <NerdHerderToolTipIcon title={`${capitalizeFirstLetters(listNoun)} ${capitalizeFirstLetters(pointsNoun)}`} message={topicData.game_player_has_points_help} placement='bottom'/></span>
        }

        let showDeleteButton = false;
        // to delete tournament games the user must be a league manager, and elimination games can't be deleted
        if (this.state.isTournamentGame) {
            if (isTournamentManager && tournamentData.type !== 'elimination') showDeleteButton = true;
        }
        // it is possible for regular players in the game to delete casual games unless the players can't create games
        else {
            if (isLeagueManager) showDeleteButton = true;
            if (leagueData.players_create_games && gameFullyVerified) {
                if (isGamePlayer) showDeleteButton = true;
            }
        }
        
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal backdrop='static' scrollable={true} show={this.props.show || true} onHide={()=>{this.onHide()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Game</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isLeagueManager &&
                        <Row>
                            <Col xs={12}>
                                <Form.Group className='mb-2'>
                                    <div className='d-grid gap-2'>
                                        <ToggleButtonGroup size='sm' name='game-view' type="radio" value={this.state.showWhichView} onChange={(event)=>this.onSwitchView(event)}>
                                            <ToggleButton variant='outline-primary' id='toggle-player-view' value={'player'}>Player View</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-manager-view' value={'manager'}>Organizer View</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>}
                        {errorFeedback}

                        <div>
                            <small className='text-muted'>You are editing...</small>
                            {listItem}
                            <hr/>
                        </div>

                        <Form>
                            {this.state.isBoardGameLeague &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <Form.Select size='sm' onChange={(event)=>this.handleFormBoardGame(event)} value={this.state.formBoardGameId} disabled={isUpdating}>
                                            {boardGameOptions}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>}
                            {eventOptions.length <= 1 && this.state.formProposedDateTime === null &&
                            <Row>
                                <Col>
                                    <Form.Group className='mb-2'>
                                        <Form.Control size='sm' type="date" onChange={(event)=>this.handleFormDate(event)} value={this.state.formDate} disabled={isUpdating} required/>
                                    </Form.Group>
                                </Col>
                                <Col xs='auto' className='ps-0'>
                                    <Form.Group className='mb-2'>
                                        <NerdHerderToolTipButton size='sm' icon='flaticon-calendar-to-organize-dates' placement='left' tooltipText='schedule game' onClick={()=>this.setState({showProposeScheduleModal: true})} disabled={isUpdating || this.state.formCompletion === 'completed'}/>
                                    </Form.Group>
                                </Col>
                            </Row>}
                            {eventOptions.length <= 1 && this.state.formProposedDateTime !== null &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <ScheduledGameListItem gameId={this.state.gameId} proposedDatetime={this.state.formProposedDateTime} localUserConcurWithSchedule={this.state.sendInvites} localUser={this.props.localUser} onClick={null} onRescheduleClick={()=>this.setState({showProposeScheduleModal: true})}/>
                                    </Form.Group>
                                </Col>
                            </Row>}
                            {eventOptions.length > 1 && this.state.formProposedDateTime === null &&
                            <Row>
                                <Col xs={6}>
                                    <Form.Group className='mb-2'>
                                        <Form.Select size='sm' onChange={(event)=>this.handleFormEvent(event)} value={this.state.formEventId} disabled={lockEventFormField || isUpdating}>
                                            {eventOptions}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className='mb-2'>
                                        <Form.Control size='sm' type="date" onChange={(event)=>this.handleFormDate(event)} value={this.state.formDate} disabled={isUpdating} required/>
                                    </Form.Group>
                                </Col>
                                <Col xs='auto' className='ps-0'>
                                    <Form.Group className='mb-2'>
                                        <NerdHerderToolTipButton size='sm' icon='flaticon-calendar-to-organize-dates' placement='left' tooltipText='schedule game' onClick={()=>this.setState({showProposeScheduleModal: true})}/>
                                    </Form.Group>
                                </Col>
                            </Row>}
                            {eventOptions.length > 1 && this.state.formProposedDateTime !== null &&
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className='mb-2'>
                                            <Form.Select size='sm' onChange={(event)=>this.handleFormEvent(event)} value={this.state.formEventId} disabled={lockEventFormField || isUpdating}>
                                                {eventOptions}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className='mb-2'>
                                            <ScheduledGameListItem gameId={this.state.gameId} proposedDatetime={this.state.formProposedDateTime} localUserConcurWithSchedule={this.state.sendInvites} localUser={this.props.localUser} onClick={null} onRescheduleClick={()=>this.setState({showProposeScheduleModal: true})}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>}
                            {isLeagueManager &&
                            <Collapse in={showManagerView}>
                                <div>
                                    <Row className='mb-2'>
                                        <Col xs={6}>
                                        <Form.Group>
                                                <Form.Select size='sm' onChange={(event)=>this.handleFormTournament(event)} value={this.state.formTournamentId} disabled={isUpdating || lockTournamentFormField}>
                                                    {tournamentOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Select size='sm' onChange={(event)=>this.handleFormTournamentRound(event)} value={this.state.formTournamentRoundId} disabled={isUpdating || lockTournamentFormField || (this.state.formTournamentId===0?true:false) }>
                                                    {tournamentRoundOptions}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        {setTournamentRoundMessage !== null &&
                                        <Col xs={12}>
                                            {setTournamentRoundMessage}
                                        </Col>}
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group className='mb-2'>
                                                <Form.Control size='sm' type="text" onChange={(event)=>this.handleFormTableName(event)} value={this.state.formTableName} disabled={isUpdating} placeholder='(Optional) Table Assignment' maxLength={20}/>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group className='mb-2 align-middle'>
                                                <Form.Check type='checkbox' label='Tournament BYE' onChange={(event)=>this.handleFormBye(event)} checked={this.state.formBye} disabled={byeOptionDisabled || isUpdating}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>}
                            {topicData.collect_game_stats &&
                            <Row>
                                {topicData.game_has_first_player &&
                                <Col xs={6}>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='mb-0'><small><b>{capitalizeFirstLetters(firstPlayerNoun)} Player</b></small></Form.Label>
                                        <Form.Select size='sm' onChange={(event)=>this.handleFormFirstPlayer(event)} value={this.state.formFirstPlayer} disabled={isUpdating}>
                                            {firstPlayerOptions}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>}
                                {topicData.game_has_points &&
                                <Col xs={6}>
                                    <Form.Label className='mb-0'><small><b>{capitalizeFirstLetters(pointsNoun)} Level{pointsHelp}</b></small></Form.Label>
                                    <Form.Group className='mb-2'>
                                        <Form.Control size='sm' type="number" onChange={(event)=>this.handleFormGamePoints(event)} placeholder={`${capitalizeFirstLetter(pointsNoun)} Level`} value={this.state.formGamePoints} disabled={isUpdating} min={0}/>
                                    </Form.Group>
                                </Col>}
                            </Row>}
                            {this.state.centerSectionMode==='scores' &&
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className='mb-2'>
                                            {playerTableRows.length > 0 &&
                                            <Table responsive size="sm" className='mb-1'>
                                                <thead>
                                                    <tr>
                                                        {topicData.scores_recorded <= 1 &&
                                                        <th>Player</th>}
                                                        {topicData.scores_recorded > 1 &&
                                                        <th className='text-capitalize'>{topicData.score1_noun}{score1Help}</th>}

                                                        {topicData.scores_recorded <= 1 &&
                                                        <th className='text-capitalize text-center' style={{width:'60px'}}>{topicData.score1_noun}</th>}
                                                        {topicData.scores_recorded > 1 &&
                                                        <th style={{width:'80px'}}></th>}

                                                        <th className='text-center' style={{width:'40px'}}>Won</th>

                                                        {!lockAddDeletePlayerButtons && 
                                                        <th style={{width:'40px'}}></th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {playerTableRows}
                                                </tbody>
                                            </Table>}
                                            {playerTableRows.length === 0 &&
                                            <p>This game has no players, you should add some!</p>}
                                            {addPlayersButton}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {playerTableRows2.length !== 0 && topicData && topicData.scores_recorded >= 2 &&
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className='mb-2'>
                                            <Table responsive size="sm" className='mb-1'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-capitalize'>{topicData.score2_noun}{score2Help}</th>
                                                        <th style={{width:'80px'}}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {playerTableRows2}
                                                </tbody>
                                            </Table>
                                        </Form.Group>
                                    </Col>
                                </Row>}
                                {playerTableRows3.length !== 0 && topicData && topicData.scores_recorded >= 3 &&
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className='mb-2'>
                                            <Table responsive size="sm" className='mb-1'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-capitalize'>{topicData.score3_noun}{score3Help}</th>
                                                        <th style={{width:'80px'}}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {playerTableRows3}
                                                </tbody>
                                            </Table>
                                        </Form.Group>
                                    </Col>
                                </Row>}
                            </div>}
                            {playerTableRows.length !== 0 && topicData && topicData.collect_game_stats && showFactionSelection &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <Table responsive size="sm" className='mb-1'>
                                            <thead>
                                                <tr>
                                                    <th className='text-capitalize'>{factionNoun}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {factionTableRows}
                                            </tbody>
                                        </Table>
                                    </Form.Group>
                                </Col>
                            </Row>}
                            {playerTableRows.length !== 0 && listContainers !== null &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <Table responsive size="sm" className='mb-1'>
                                            <thead>
                                                <tr>
                                                    <th className='text-capitalize'>Player {listNounPlural}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listsTableRows}
                                            </tbody>
                                        </Table>
                                    </Form.Group>
                                </Col>
                            </Row>}
                            {playerTableRows.length !== 0 && topicData && topicData.collect_game_stats && topicData.game_player_has_points &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <Table responsive size="sm" className='mb-1'>
                                            <thead>
                                                <tr>
                                                    <th className='text-capitalize'>{listNoun} {pointsNoun}{playerPointsHelp}</th>
                                                    <th className='text-center' style={{width:'80px'}}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pointsTableRows}
                                            </tbody>
                                        </Table>
                                    </Form.Group>
                                </Col>
                            </Row>}
                            {this.state.centerSectionMode==='players' &&
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <hr/>
                                        <b>Select a player to add:</b>
                                        {eligiblePlayersListItems}
                                        {cancelAddPlayersButton}
                                    </Form.Group>
                                </Col>
                            </Row>}
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <Form.Control as="textarea" rows={3} onChange={(event)=>this.handleFormDetails(event)} placeholder='(Optional) Add any details relevant to the game' value={this.state.formDetails} disabled={isUpdating} maxLength={maxDetailsLength}/>
                                        <Form.Text className="text-muted align-end">
                                            <small>{`${this.state.formDetails.length}/${maxDetailsLength}`}</small>
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className='mb-2'>
                                        <div className='d-grid gap-2'>
                                            <ToggleButtonGroup size='sm' name='game-completion' type="radio" value={this.state.formCompletion} onChange={(event)=>this.handleFormCompletion(event)}>
                                                <ToggleButton variant='outline-primary' id='toggle-scheduled' value={'scheduled'} disabled={isUpdating}>Scheduled</ToggleButton>
                                                <ToggleButton variant='outline-primary' id='toggle-in-progress' value={'in-progress'} disabled={isUpdating}>In-Progress</ToggleButton>
                                                <ToggleButton variant='outline-primary' id='toggle-completed' value={'completed'} disabled={isUpdating}>Completed</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                        <div>
                                            <Form.Text>
                                                <p><small>
                                                    <b>{completionTitle}</b> - {completionText}
                                                </small></p>
                                            </Form.Text>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {showDeleteButton &&
                        <Button className='float-start' variant="danger" onClick={()=>this.onDeleteClicked()} disabled={this.state.deleteButtonDisabled}>Delete</Button>}
                        <NerdHerderUpdateButton className='float-end' variant="primary" onClick={()=>this.onClickSave(true)} updating={this.state.updating} disabled={saveButtonDisabled}>{this.state.sendInvites ? 'Update & Invite' : 'Update'}</NerdHerderUpdateButton>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderScheduleGameModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderScheduleGameModal'>
                <NerdHerderScheduleGameModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderScheduleGameModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');
        if (typeof this.props.game === 'undefined') console.error('missing props.game');
        if (typeof this.props.playerIdList === 'undefined') console.error('missing props.playerIdList');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        let defaultDate = null;
        let defaultTime = null;
        let defaultTimezone = null;
        this.browserTimezone = null;
        this.profileTimezone = this.props.localUser.timezone;
        this.leagueTimezone = this.props.league.timezone;
        this.timezoneDict = {};

        // detect browser timezone - fallback to profile if it doesn't work
        let dateTime = DateTime.now();
        if (dateTime.isValid) this.browserTimezone = dateTime.zoneName;
        if (this.browserTimezone === null) this.browserTimezone = this.profileTimezone;
        defaultTimezone = this.browserTimezone;

        if (this.props.game.proposed_datetime) {
            let defaultDateTime = DateTime.fromISO(this.props.game.proposed_datetime, {zone: this.props.league.timezone});
            if (defaultTimezone !== this.props.league.timezone) {
                defaultDateTime = defaultDateTime.setZone(defaultTimezone);
            }
            defaultTime = defaultDateTime.toISOTime({includeOffset: false});
            defaultDate = defaultDateTime.toISODate();
        } else {
            defaultTime = '17:00';
            if (this.props.game.date) {
                defaultDate = this.props.game.date;
            } else {
                defaultDate = DateTime.local().toISODate();
            }
        }

        const defaultUsersCache = {};
        for (const playerId of this.props.playerIdList) {
            defaultUsersCache[playerId] = null;
        }
        defaultUsersCache[this.props.localUser.id] = this.props.localUser;

        this.state = {
            updating: false,
            timezoneList: null,
            formDate: defaultDate,
            formTime: defaultTime,
            formTimezone: defaultTimezone,
            usersCache: defaultUsersCache,
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        let sub = this.restPubSub.subscribeNoRefresh('timezone-list', null, (d, k)=>{this.updateTimezoneList(d, k)});
        this.restPubSubPool.add(sub);
        for (const playerId of this.props.playerIdList) {
            if (this.state.usersCache[playerId] === null) {
                sub = this.restPubSub.subscribeNoRefresh('user', playerId, (d, k)=>{this.updateUser(d, k)});
                this.restPubSubPool.add(sub);
            }
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTimezoneList(response, key) {
        this.timezoneDict = convertListToDict(response, 'timezone_name');
        this.setState({timezoneList: response});
    }

    updateUser(response, key) {
        const newUser = NerdHerderDataModelFactory('user', response);
        console.log('got new user', newUser.id);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [newUser.id]: newUser}}
        });
    }

    onSelect(userId) {
        this.setState({selectedUserId: userId});
    }

    onAccept() {
        undoOnBackCancelModal();
        // convert the proposed date/time to league timezone
        let proposedDateTime = DateTime.fromISO(`${this.state.formDate}T${this.state.formTime}`, {zone: this.state.formTimezone});
        if (this.props.league.timezone !== this.state.formTimezone) {
            proposedDateTime = proposedDateTime.setZone(this.props.league.timezone);
        }
        this.props.onAccept(proposedDateTime.toISO({includeOffset: false}));
    }

    onDelete() {
        undoOnBackCancelModal();
        this.props.onAccept(null);
    }

    handleDateChange(event) {
        let value = event.target.value;
        if (value !== '') this.setState({formDate: value});
    }

    handleTimeChange(event) {
        let value = event.target.value;
        this.setState({formTime: value});
    }

    handleFormTimezoneChange(event) {
        this.setState({formTimezone: event.target.value});
    }

    render() {
        if (this.state.timezoneList === null) return(<NerdHerderLoadingModal/>);

        // figure out the scheduled DateTime
        const scheduledDateTimeOpts = {zone: this.state.formTimezone};
        const scheduledDateTime = DateTime.fromISO(`${this.state.formDate}T${this.state.formTime}`, scheduledDateTimeOpts);

        // load all the timezones into the dropdown, put local country timezones first
        let allSameTimeZone = true;
        const userCountryTimezoneListItems = [];
        const otherTimezoneListItems = [];
        for (const timezone of this.state.timezoneList) {
            let timezoneName = timezone.timezone_name;
            if (timezone.localized_name) timezoneName += ` ${timezone.localized_name}`;
            const optionItem = <option key={timezone.id} value={timezone.timezone_name}>{timezoneName} ({timezone.utc_offset_string})</option>
            if (timezone.country_name === this.props.localUser.country) {
                userCountryTimezoneListItems.push(optionItem);
            } else {
                otherTimezoneListItems.push(optionItem);
            }
        }

        // figure out if everybody is in the same timezone - a reduced modal is used in this situation
        let warningAlert = null;
        if (this.browserTimezone !== this.state.formTimezone) {
            allSameTimeZone = false;
            warningAlert = <Alert variant='warning'>You are scheduling for a different timezone</Alert>
        }
        if (this.browserTimezone !== this.profileTimezone) {
            allSameTimeZone = false;
            warningAlert = <Alert variant='warning'>Your local timezone and profile don't match</Alert>
        }
        if (!this.props.league.online && this.browserTimezone !== this.leagueTimezone) {
            allSameTimeZone = false;
            warningAlert = <Alert variant='warning'>Your timezone and league's timezone don't match</Alert>
        }
        if (allSameTimeZone) {
            for (const playerId of this.props.playerIdList) {
                if (this.state.usersCache[playerId] !== null) {
                    const playerData = this.state.usersCache[playerId];
                    if (this.browserTimezone !== playerData.timezone) {
                        allSameTimeZone = false;
                        warningAlert = <Alert variant='warning'>Some players (or the {this.props.league.getTypeWord(true)}) are in different timezones</Alert>
                        break;
                    }
                }
            }
        }

        // build up the table with timezones
        const timezoneColumn = {};
        const proposedTimeColumn = {};
        for (const playerId of this.props.playerIdList) {
            if (this.state.usersCache[playerId] !== null) {
                const timezone = this.timezoneDict[this.state.usersCache[playerId].timezone];
                const playerDateTime = scheduledDateTime.setZone(timezone.timezone_name);
                let playerOffsetName = playerDateTime.toFormat('ZZZZ');
                let timezoneName = timezone.timezone_name;
                if (playerOffsetName) timezoneName = playerOffsetName;
                else if (timezone.localized_name) timezoneName = timezone.localized_name;
                timezoneColumn[playerId] = timezoneName;
                proposedTimeColumn[playerId] = playerDateTime.toLocaleString(DateTime.TIME_SIMPLE);
                // TODO update proposed time column with the time in that timezone
                // TODO update the Timezone column with the short name (CDT for example)
            } else {
                timezoneColumn[playerId] = 'unknown';
                proposedTimeColumn[playerId] = 'unknown';
            }
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule Game</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {allSameTimeZone &&
                        <Alert variant='primary'>All players & the {this.props.league.getTypeWord(true)} use the same timezone</Alert>}
                        {warningAlert}
                        <Collapse in={!allSameTimeZone}>
                            <div className='mb-2'>
                                <TableOfUsers localUser={this.props.localUser} userIds={this.props.playerIdList} users={this.state.usersCache} headers={['Player', 'Timezone', 'Proposed']} middleColumnContent={timezoneColumn} rightColumnContent={proposedTimeColumn}/>
                            </div>
                        </Collapse>
                        <Form.Group className="form-outline mb-2">
                            <Row className='mb-2'>
                                <Col xs={6}>
                                    <Form.Label>Proposed Date</Form.Label>
                                    <Form.Control id="proposed_date" name="proposed_date" type="date" disabled={this.state.updating} onChange={(e)=>this.handleDateChange(e)} autoComplete='off' value={this.state.formDate} required/>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label>Proposed Start Time</Form.Label>
                                    <Form.Control id="start_time" name="start_time" type="time" disabled={this.state.updating} onChange={(e)=>this.handleTimeChange(e)} autoComplete='off' value={this.state.formTime} required/>
                                </Col>
                            </Row>
                        </Form.Group>
                        <hr/>
                        <Form.Group className="form-outline mb-2">
                            <Form.Label>Schedule Timezone</Form.Label>
                            <Form.Select size='sm' onChange={(event)=>this.handleFormTimezoneChange(event)} value={this.state.formTimezone} disabled={this.state.updating}>
                                <optgroup label={this.props.localUser.country}>
                                    {userCountryTimezoneListItems}
                                </optgroup>
                                <optgroup label='Other Timezones'>
                                    {otherTimezoneListItems}
                                </optgroup>
                            </Form.Select>
                            <Form.Text muted>The timezone you are scheduling from, normally doesn't need to be changed</Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="danger" onClick={()=>this.onDelete()}>Remove Proposal</Button>
                        <Button variant="primary" onClick={()=>this.onAccept()}>Accept Proposal</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderNewBoardGameModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderNewBoardGameModal'>
                <NerdHerderNewBoardGameModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderNewBoardGameModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const initialDict = {};
        for (const boardGameData of this.props.league.board_games) {
            const newData = {...boardGameData};
            initialDict[boardGameData.id] = newData;
        }

        this.state = {
            updating: false,
            mode: 'select',
            leagueBoardGames: initialDict,
            serverBoardGames: {},
            serverBoardGamesBggId: {},
            selectedId: null,
            selectedBggItem: false,
            formSearch: '',
            formSearchClicked: false,
            bggSearchResults: [],
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        const sub = this.restPubSub.subscribe('board-game', null, (d, k) => {this.updateServerBoardGames(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateServerBoardGames(serverBoardGames, key) {
        const newServerBoardGamesBggId = {};
        for (const boardGame of Object.values(serverBoardGames)) {
            newServerBoardGamesBggId[boardGame.bgg_id] = boardGame;
        }
        this.setState({serverBoardGames: serverBoardGames, serverBoardGamesBggId: newServerBoardGamesBggId});
    }

    updatePostedBoardGame(serverBoardGame, key) {
        let idToReturnDuringCancel = parseInt(serverBoardGame.id);
        this.setState({updating: true});
        this.restPubSub.refresh('board-game', null);
        this.props.onCancel(idToReturnDuringCancel);
    }

    onSearch() {
        let searchString = this.state.formSearch;
        searchString.replaceAll(' ', '+');
        const queryParams = {type: 'boardgame', query: searchString};
        axios.get('https://boardgamegeek.com/xmlapi2/search', {params: queryParams})
        .then(response => {
            this.processBggSearchResponse(response.data);
        }).catch(error => {
            console.error('failed to get xml from BGG REST API');
        });
        this.setState({selectedId: null, formSearchClicked: true});
    }

    processBggSearchResponse(xmlString) {
        const newSearchResults = [];
        const xmlParser = new DOMParser();
        const xmlDoc = xmlParser.parseFromString(xmlString, "text/xml");

        let itemElements = xmlDoc.getElementsByTagName("item");
        for (const itemElement of itemElements) {
            let bggId = itemElement.getAttribute("id");
            let bggName = null;
            let bggYear = null;
            let nameElements = itemElement.getElementsByTagName("name");
            for (const nameElement of nameElements) {
                if (nameElement.getAttribute("type") === "primary") {
                    bggName = nameElement.getAttribute("value");
                    break;
                }
            }
            let yearElements = itemElement.getElementsByTagName("yearpublished");
            for (const yearElement of yearElements) {
                bggYear = yearElement.getAttribute("value");
                break;
            }
            const result = {id: bggId, name: bggName, year: bggYear};
            newSearchResults.push(result);
        }
        this.setState({bggSearchResults: newSearchResults});
    }

    onSwitchView(value) {
        this.setState({mode: value, selectedId: null});
    }

    onSelectBoardGame(boardGameId) {
        this.setState({selectedId: boardGameId, selectedBggItem: false});
    }

    onSelectSearchResult(bggBoardGameId) {
        this.setState({selectedId: bggBoardGameId, selectedBggItem: true})
    }

    onAddBoardGame() {
        let doServerPostFirst = false;
        // if they selected a game from the BGG search results and the game isn't already loaded into NH, need to do a POST first
        if (this.state.selectedBggItem) {
            if (!this.state.serverBoardGamesBggId.hasOwnProperty(this.state.selectedId)) {
                doServerPostFirst = true;
            }
        }

        if (doServerPostFirst) {
            this.restPubSub.postAndSubscribe('board-game', 'id', {bgg_id: this.state.selectedId}, (d, k)=>this.updatePostedBoardGame(d, k), null, null);
            this.setState({updating: true});
            return;
        }

        // if the user selected a server game, just take that (make it an int first)
        // but if they selected a BGG search result, convert that into a id from the server
        let idToReturnDuringCancel = parseInt(this.state.selectedId)
        if (this.state.selectedBggItem) {
            idToReturnDuringCancel = this.state.serverBoardGamesBggId[idToReturnDuringCancel].id;
        }

        this.setState({updating: true});
        this.props.onCancel(idToReturnDuringCancel);
    }

    handleSearchChange(event) {
        this.setState({formSearch: event.target.value});
    }

    render() {
        const listItems = [];
        if (this.state.mode === 'select') {
            const sortedList = Object.values(this.state.serverBoardGames);
            sortedList.sort(function(game1, game2) {
                const name1 = game1.name;
                const name2 = game2.name;
                return(name1.localeCompare(name2));
            });
            for (const boardGame of sortedList) {
                const boardGameId = boardGame.id;
                if (!this.props.league.board_game_ids.includes(boardGameId)) {
                    let selected = false;
                    if (!this.state.selectedBggItem && boardGameId === this.state.selectedId) selected = true;
                    const listItem = <BoardGameListItem key={boardGameId} boardGameId={boardGameId} boardGame={boardGame} selected={selected} onClick={()=>this.onSelectBoardGame(boardGameId)} localUser={this.props.localUser}/>
                    listItems.push(listItem);
                }
            }
        }

        if (this.state.mode === 'search') {
            for (const searchResult of this.state.bggSearchResults) {
                const bggId = searchResult.id;
                if (!searchResult.name || searchResult.name.length === 0) continue;
                const linkItem = <a target='_blank' rel='noreferrer' href={`https://boardgamegeek.com/boardgame/${bggId}`}>{bggId}</a>
                let selected = false;
                if (this.state.selectedBggItem && this.state.selectedId === bggId) selected = true;
                let loadedIcon = ' ';
                if (this.state.serverBoardGamesBggId.hasOwnProperty(bggId)) loadedIcon = <NerdHerderFontIcon variant='primary' icon='flaticon-bookmark-for-favorites'/>
                const row =
                    <tr key={bggId} className={selected ? 'border-selected-primary' : ''}>
                        <td><small>{linkItem}</small></td>
                        <td onClick={()=>this.onSelectSearchResult(bggId)}>{loadedIcon}</td>
                        <td onClick={()=>this.onSelectSearchResult(bggId)}><small>{searchResult.name}</small></td>
                        <td onClick={()=>this.onSelectSearchResult(bggId)}><small>{searchResult.year}</small></td>
                    </tr>
                listItems.push(row);
            }
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}}  onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Add Board Game to League'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='mb-2'>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='add-board-game-view' type="radio" value={this.state.mode} onChange={(event)=>this.onSwitchView(event)}>
                                        <ToggleButton variant='outline-primary' id='toggle-select-view' value={'select'}>Select Game</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-search-view' value={'search'}>Search BGG</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </Form.Group>

                        {this.state.mode === 'select' && listItems.length !== 0 &&
                        <div>
                            <Form.Text muted>Select from popular board games to add to the league</Form.Text>
                            {listItems}
                        </div>}
                        {this.state.mode === 'select' && listItems.length === 0 &&
                        <div>
                            <Form.Text muted>All the popular games have been added to the league, you could search Board Game Geek for more...</Form.Text>
                        </div>}
                        {this.state.mode === 'search' &&
                        <div>
                            <Row>
                                <Col>
                                    <Form.Text muted>Search Board Game Geek for a game to add to the league</Form.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleSearchChange(event)} value={this.state.formSearch} minLength={4} maxLength={45}/>
                                    {this.state.formSearch.length > 0 && this.state.formSearch < 4 &&
                                    <Form.Text className='text-danger'>Search term must be at least 4 characters</Form.Text>}
                                </Col>
                                <Col className='ps-0 ms-0' xs='auto'>
                                    <Button variant="primary" disabled={this.state.updating || this.state.formSearch.length < 4} onClick={()=>this.onSearch()}><NerdHerderFontIcon icon='flaticon-search'/></Button>
                                </Col>
                            </Row>
                            {listItems.length > 0 &&
                            <div>
                                <span><small><NerdHerderFontIcon variant='primary' icon='flaticon-bookmark-for-favorites'/> indicates a popular board game used in other leagues</small></span>
                                <Table size='sm' striped>
                                    <thead>
                                        <tr><th>BGG</th><th></th><th>Name</th><th>Year</th></tr>
                                    </thead>
                                    <tbody>
                                        {listItems}
                                    </tbody>
                                </Table>
                            </div>}
                            {listItems.length === 0 && this.state.formSearchClicked &&
                            <div>
                                Sorry - <a target='_blank' rel='noreferrer' href='https://boardgamegeek.com'>Board Game Geek</a> does not have any results for that.
                            </div>}
                        </div>}
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" disabled={this.state.updating} onClick={()=>this.props.onCancel(null)}>Cancel</Button>
                        <Button variant="primary" disabled={this.state.updating || this.state.selectedId === null} onClick={()=>this.onAddBoardGame()}>Accept</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderChooseLocationModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderChooseLocationModal'>
                <NerdHerderChooseLocationModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderChooseLocationModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.mobileDisabled = null;
        if (navigator.geolocation) {
            this.mobileDisabled = false;
        } else {
            this.mobileDisabled = true;
        }

        this.state = {
            updating: false,
            errorFeedback: null,
            locationOption: 'default',
            ipLocation: null,
            countryList: null,
            latitude: '',
            longitude: '',
            locationString: '',
            zipcode: this.props.localUser.zipcode,
            country: this.props.localUser.country,
            mobilePosition: null,
            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        let sub = this.restPubSub.subscribe('ip-location', null, (d, k) => {this.updateIpLocation(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('country-list', null, (d, k) => {this.updateCountryList(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateCountryList(response, key) {
        this.setState({countryList: response});
    }

    updateIpLocation(response, key) {
        this.setState({ipLocation: response});
    }

    onAccept() {
        let latitude = null;
        let longitude = null;
        let zipcode = null;
        let country = null;
        let fromString = null;

        switch (this.state.locationOption) {
            case "default":
                zipcode = this.props.localUser.zipcode;
                country = this.props.localUser.country;
                fromString = `profile location (${zipcode} ${country})`;
                break;
            case "enter":
                zipcode = this.state.zipcode;
                country = this.state.country;
                fromString = `${zipcode} ${country}`;
                break;
            case "guess":
                const ipDetails = this.state.ipLocation.details;
                if (ipDetails.latitude && ipDetails.longitude) {
                    latitude = ipDetails.latitude;
                    longitude = ipDetails.longitude;
                    fromString = `IP address (${latitude}, ${longitude})`;
                } else {
                    zipcode = ipDetails.postal;
                    country = ipDetails.country_name; 
                    fromString = `IP address (${zipcode} ${country})`;
                }
                break;
            case "mobile":
                latitude = this.state.mobilePosition.coords.latitude;
                longitude = this.state.mobilePosition.coords.longitude;
                fromString = `mobile location (${latitude}, ${longitude})`;
                break;
            default:
                console.error(`got invalid locationOption value: ${this.state.locationOption}`);
                zipcode = this.props.localUser.zipcode;
                country = this.props.localUser.country;
                fromString = `profile location (${zipcode} ${country})`;
        }

        undoOnBackCancelModal();
        this.props.onAccept(latitude, longitude, zipcode, country, fromString);
    }

    onSwitchLocationOption(value) {
        this.setState({locationOption: value});
    }

    doMobileGeolocation() {
        navigator.geolocation.getCurrentPosition((p)=>this.mobileLocationProvided(p), (e)=>this.mobileLocationFailure(e));
    }

    mobileLocationProvided(position) {
        this.setState({mobilePosition: position});
    }

    mobileLocationFailure(error) {
        this.mobileDisabled = true;
        switch(error.code) {
            case error.PERMISSION_DENIED:
                this.setState({locationOption: 'default', errorFeedback: 'mobile location position denied'});
                break;
            case error.POSITION_UNAVAILABLE:
                this.setState({locationOption: 'default', errorFeedback: 'mobile location position unavailable'});
                break;
            case error.TIMEOUT:
                this.setState({locationOption: 'default', errorFeedback: 'mobile location timed out'});
                break;
            case error.UNKNOWN_ERROR:
                this.setState({locationOption: 'default', errorFeedback: 'there was an unknown error with mobile location'});
                break;
            default:
                this.setState({locationOption: 'default', errorFeedback: 'there was an unexpected error with mobile location'});
        }
    }

    handleZipcodeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('zipcode', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('zipcode', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({zipcode: value, formErrors: errorState});
    }

    handleCountryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('country', {...this.state.formErrors});
        this.setState({country: value, formErrors: errorState});
    }

    render() {
        if (this.state.countryList === null) return(<NerdHerderLoadingModal/>);
        if (this.state.ipLocation === null) return(<NerdHerderLoadingModal/>);

        let ipLocationString = 'unknown';
        if (this.state.ipLocation.details) {
            const ipDetails = this.state.ipLocation.details;
            if (ipDetails.country_name) ipLocationString = ipDetails.country_name;
            else if (ipDetails.country) ipLocationString = ipDetails.country;
            if (ipDetails.postal) ipLocationString = ipLocationString + ' ' + ipDetails.postal;
            if (ipDetails.region) ipLocationString = ipDetails.region + ' ' + ipLocationString;
            if (ipDetails.city) ipLocationString = ipDetails.city + ' ' + ipLocationString;
        }

        const countryOptions = [];
        for (const countryName of this.state.countryList) {
            countryOptions.push(<option key={countryName} value={countryName}>{countryName}</option>)
        }

        let disableAcceptButton = false;
        if (this.state.updating) disableAcceptButton = true;

        // generate the text that goes under the toggle and some other tricks
        let optionTitle = null;
        let optionText = null;
        switch (this.state.locationOption) {
            case "default":
                optionTitle = 'Default';
                optionText = 'a rough location is calculated from your profile.';
                break;
            case "enter":
                optionTitle = 'Select';
                optionText = 'you can enter a postal code and country to search from in the fields below.';
                break;
            case "guess":
                optionTitle = 'IP Address';
                optionText = 'a global IP address database is used to estimate your location.';
                break;
            case "mobile":
                optionTitle = 'Mobile';
                optionText = 'NerdHerder will query your (mobile) device to get your precise location.';
                if (this.state.mobilePosition === null) disableAcceptButton = true;
                break;
            default:
                console.error(`got invalid locationOption value: ${this.state.locationOption}`);
                optionTitle = 'Default';
                optionText = 'a rough location is calculated from your profile.';
        }        

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Pick Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorFeedback &&
                        <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                        <Row>
                            <Col xs={12}>
                                <Form.Group className='mb-2'>
                                    <div>
                                        <p>How would you like to choose your current location?</p>
                                    </div>
                                    <div className='d-grid gap-2'>
                                        <ToggleButtonGroup size='sm' name='location-option' type="radio" value={this.state.locationOption} onChange={(v)=>this.onSwitchLocationOption(v)}>
                                            <ToggleButton variant='outline-primary' id='toggle-default-location' value={'default'}>Default</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-enter-location' value={'enter'}>Select</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-guess-location' value={'guess'}>IP Address</ToggleButton>
                                            <ToggleButton variant='outline-primary' id='toggle-mobile-location' value={'mobile'} disabled={this.mobileDisabled}>Mobile</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    <div>
                                        <Form.Text>
                                            <p><small>
                                                <b>{optionTitle}</b> - {optionText}
                                            </small></p>
                                        </Form.Text>
                                    </div>
                                </Form.Group>
                                {this.state.locationOption === 'default' &&
                                <Form.Group className="form-outline mb-3 text-center">
                                    <p>Location: {this.props.localUser.zipcode} {this.props.localUser.country}</p>
                                </Form.Group>}
                                {this.state.locationOption === 'guess' &&
                                <Form.Group className="form-outline mb-3 text-center">
                                    <p>Location: {ipLocationString}</p>
                                </Form.Group>}
                                {this.state.locationOption === 'enter' &&
                                <Form.Group className="form-outline mb-3">
                                    <Form.Control className='mb-1' type="text" disabled={this.state.updating} onChange={(event)=>this.handleZipcodeChange(event)} autoComplete='off' value={this.state.zipcode} minLength={3} maxLength={45} required/>
                                    <FormErrorText errorId='zipcode' errorState={this.state.formErrors}/>
                                    <Form.Select disabled={this.state.updating} onChange={(event)=>this.handleCountryChange(event)} value={this.state.country} required>
                                        {countryOptions}
                                    </Form.Select>
                                    <FormErrorText errorId='country' errorState={this.state.formErrors}/>
                                </Form.Group>}
                                {this.state.locationOption === 'mobile' && this.state.mobilePosition === null &&
                                <Form.Group className="form-outline mb-3 text-center">
                                    <Button size='lg' variant='primary' onClick={()=>this.doMobileGeolocation()}>Get Mobile Location</Button>
                                </Form.Group>}
                                {this.state.locationOption === 'mobile' && this.state.mobilePosition !== null &&
                                <Form.Group className="form-outline mb-3 text-center">
                                    <p>Latitude: {this.state.mobilePosition.coords.latitude}</p>
                                    <p>Longitude: {this.state.mobilePosition.coords.longitude}</p>
                                </Form.Group>}
                            </Col>
                        </Row>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onAccept()} disabled={disableAcceptButton}>Accept</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderListUploadModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderListUploadModal'>
                <NerdHerderListUploadModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderListUploadModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing prop.localUser');
        if (typeof this.props.league === 'undefined') console.error('missing prop.league');
        if (typeof this.props.listContainer === 'undefined') console.error('missing prop.listContainer');
        if (typeof this.props.onCancel === 'undefined') console.error('missing prop.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing prop.onAccept');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.dzRefDict = React.createRef();

        // if running in NPM on port 3000, switch to port 8080 for localhost testing
        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        let portnum = window.location.port;
        if (hostname === 'localhost' && portnum === '3000') {
            portnum = 8080;
        }
        this.uploadUrl = `${protocol}//${hostname}:${portnum}/rest/v1/dz-league-list-upload/${this.props.listContainer.id}`;

        // some of the language is different depending on what we're uploading to
        this.presentation = 'league';
        this.typeWord = 'league';
        if (this.props.listContainer.tournament_id) {
            this.presentation = 'tournament';
            this.typeWord = 'tournament';
        } else if (this.props.listContainer.eventId) {
            this.presentation = 'event';
            this.typeWord = 'event';
        }

        // save the list noun, we use it a lot
        this.listNoun = this.props.league.topic.list_noun;
        this.listNounCaps = capitalizeFirstLetters(this.listNoun);

        // we do special stuff for MCP and SWL
        this.isMCP = false;
        this.isSWL = false;
        this.isSWSP = false;
        let uploadMode = 'file';
        if (this.props.league.topic_id === 'MCP') {
            this.isMCP = true;
            uploadMode = 'cerebro';
        }
        if (this.props.league.topic_id === 'SWL') {
            this.isSWL = true;
            uploadMode = 'tabletop_admiral';
        }
        if (this.props.league.topic_id === 'SWSP') {
            this.isSWSP = true;
            uploadMode = 'point_break';
        }

        this.state = {
            updating: false,
            readyToAccept: false,
            uploadMode: uploadMode,
            selectedId: null,
            userFeedback: null,
            listText: '',
            listLink: '',
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    refresh(delay=0) {
        switch(this.presentation) {
            case 'league':
                this.restPubSub.refresh('league', this.props.league.id, delay);
                break;
            case 'event':
                this.restPubSub.refresh('event', this.props.listContainer.event_id, delay);
                break;
            case 'tournament':
                this.restPubSub.refresh('tournament', this.props.listContainer.tournament_id, delay);
                break;
            default:
                console.error(`got invalid this.presentation: ${this.presentation}`);
        }
    }

    handleListTextChange(event) {
        const value = event.target.value;
        this.setState({listText: value, readyToAccept: value.length !== 0, selectedId: null});
    }

    handleListLinkChange(event) {
        const value = event.target.value;
        this.setState({listLink: value, readyToAccept: value.length !== 0, selectedId: null});
    }

    onSwitchUploadMode(mode) {
        this.setState({uploadMode: mode, selectedId: null});
    }

    onShowFile(href, event) {
        window.open(href, '_blank');
        event.stopPropagation();
    }

    onAcceptLink(event) {
        console.debug('sending text file');
        const formData = new FormData();
        const text = this.state.listLink.trim();
        const blob = new Blob([text], { type: 'text/plain' });
        // special name list-link.lnk lets the server know we're encoding a link in the file and uploading it
        formData.append('file', blob, 'list-link.lnk');

        this.setState({updating: true});
        let headers = this.restApi.getApiHeaders();
        headers['Content-Type'] = "multipart/form-data";
        axios.post(`/rest/v1/dz-league-list-upload/${this.props.listContainer.id}`, formData, {headers: headers})
        .then((response) => {
            console.debug('successfully sent text file');
            this.refresh(100);
            undoOnBackCancelModal();
            this.props.onAccept();
        })
        .catch((error) => {
            console.error('failed to send text file');
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onAcceptTextFile(event) {
        console.debug('sending text file');
        const formData = new FormData();
        const text = this.state.listText;
        const blob = new Blob([text], { type: 'text/plain' });
        formData.append('file', blob, 'list.txt');

        this.setState({updating: true});
        let headers = this.restApi.getApiHeaders();
        headers['Content-Type'] = "multipart/form-data";
        axios.post(`/rest/v1/dz-league-list-upload/${this.props.listContainer.id}`, formData, {headers: headers})
        .then((response) => {
            console.debug('successfully sent text file');
            this.refresh(100);
            undoOnBackCancelModal();
            this.props.onAccept();
        })
        .catch((error) => {
            console.error('failed to send text file');
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onAcceptIntegratedFile(event) {
        // select the correct service, or get out
        let service = null;
        if (this.isMCP) service = 'cerebro';
        if (this.isSWL) service = 'tabletop_admiral';
        if (this.isSWSP) service = 'point_break';

        if (!service) return;

        this.setState({updating: true});
        // kinda strange, but this actually goes through GET
        this.restApi.genericGetEndpointData('list-integration', this.state.selectedId,
            {'service': service, 'list-container-id': this.props.listContainer.id})
        .then((response) => {
            console.debug(`successfully updated list container ${this.props.listContainer.id} using ${service}`);
            this.refresh(100);
            undoOnBackCancelModal();
            this.props.onAccept();
        })
        .catch((error) => {
            console.error(`failed to update list container ${this.props.listContainer.id} using ${service}`);
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onDzSending(file) {
        this.setState({updating: true});
    }

    onDzSuccess(file) {
        this.dzRefDict.current.clearUploadedFiles();
        this.setState({updating: false, listText: '', selectedId: null});
        this.refresh(100);
        undoOnBackCancelModal();
        this.props.onAccept();
    }

    onDzError(file) {
        this.dzRefDict.current.clearUploadedFiles();
        this.setState({updating: false});
        this.refresh(1000);
    }

    onAccept(event) {
        // if the user has selected an integrated file, we're going to take that first, if not then text, then link.
        if (this.state.selectedId !== null) {
            this.onAcceptIntegratedFile(event);
        } else if (this.state.listText.length !== 0) {
            this.onAcceptTextFile(event);
        } else if (this.state.listLink.length !== 0) {
            this.onAcceptLink(event);
        }
    }

    onIntegratedUploadSelect(selectedId) {
        console.debug('selected in modal:', selectedId);
        this.setState({selectedId: selectedId, readyToAccept: selectedId !== null});
    }

    render() {
        let maxTextLength = 4096;
        let isInvalidUrl = false;
        if (this.state.uploadMode === 'link' && this.state.listLink.length !== 0) {
            let trimmedLink = this.state.listLink.trim();
            let isValidUrl = isValidHttpUrl(trimmedLink);
            isInvalidUrl = !isValidUrl;
        }
        
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal backdrop='static' show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.listContainer.name} Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {!this.isSWL && !this.isMCP && !this.isSWSP &&
                            <small className='text-muted'>Here you can upload a {this.listNoun}. NerdHerder accepts a file, text, or a link.</small>}
                            {this.isMCP &&
                            <small className='text-muted'>Here you can upload a roster. The easiest way is to use <a target='_blank' rel='noreferrer' href='https://cerebromcp.com/'>Cerebro MCP</a> to build your roster, and then select it below. Alternatively, you can upload a file or text list, or provide a link.</small>}
                            {this.isSWL &&
                            <small className='text-muted'>Here you can upload an army. The easiest way is to use <a target='_blank' rel='noreferrer' href='https://tabletopadmiral.com/'>Tabletop Admiral</a> to build your army, and then select it below. Alternatively, you can upload a file or text list, or provide a link.</small>}
                            {this.isSWSP &&
                            <small className='text-muted'>Here you can upload a strike team. The easiest way is to use <a target='_blank' rel='noreferrer' href='https://www.nerdherder.app/app/swsp'>SWSP Strike Team Builder</a> or Point Break to build your strike team, and then select it below. Alternatively, you can upload a file or text list, or provide a link.</small>}
                        </p>
                        {this.props.listContainer.required &&
                        <p>
                            <small className='text-danger'>You must upload a {this.listNoun} for this {this.typeWord}.</small>
                        </p>}
                        <div className='d-grid gap-2 my-2'>
                            <ToggleButtonGroup size='sm' name='upload-list-view' type="radio" value={this.state.uploadMode} onChange={(event)=>this.onSwitchUploadMode(event)}>
                                {this.isSWL &&
                                <ToggleButton variant='outline-primary' id='toggle-tta-view' disabled={this.state.updating} value={'tabletop_admiral'}>Tabletop Admiral</ToggleButton>}
                                {this.isMCP &&
                                <ToggleButton variant='outline-primary' id='toggle-swspstb-view' disabled={this.state.updating} value={'cerebro'}>Cerebro</ToggleButton>}
                                {this.isSWSP &&
                                <ToggleButton variant='outline-primary' id='toggle-pointbreak-view' disabled={this.state.updating} value={'point_break'}>Point Break</ToggleButton>}
                                <ToggleButton variant='outline-primary' id='toggle-file-view' disabled={this.state.updating} value={'file'}>File</ToggleButton>
                                <ToggleButton variant='outline-primary' id='toggle-text-view' disabled={this.state.updating} value={'text'}>Text</ToggleButton>
                                <ToggleButton variant='outline-primary' id='toggle-link-view' disabled={this.state.updating} value={'link'}>Link</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <Row className='text-center align-items-center'>
                            {this.state.uploadMode === 'file' &&
                            <Col>
                                <NerdHerderDropzoneFileUploader
                                    ref={this.dzRefDict}
                                    localUser={this.props.localUser}
                                    message={'Drop list here to add'}
                                    maxFiles={1}
                                    uploadUrl={`/rest/v1/dz-league-list-upload/${this.props.listContainer.id}`}
                                    sendingCallback={(f)=>this.onDzSending(f)}
                                    successCallback={(f)=>this.onDzSuccess(f)}
                                    errorCallback={(f)=>this.onDzError(f)}/>
                            </Col>}
                            {this.state.uploadMode === 'text' &&
                            <Col>
                                <div className='mt-2' style={{position: 'relative'}}>
                                    <Form.Control as="textarea" placeholder='Enter or paste text here to upload' rows={7} disabled={this.state.updating} onChange={(e)=>this.handleListTextChange(e)} autoComplete='off' value={this.state.listText} maxLength={maxTextLength}/>
                                    <FormTextInputLimit current={this.state.listText.length} max={maxTextLength}/>
                                </div>
                            </Col>}
                            {this.state.uploadMode === 'link' &&
                            <Col className='text-start'>
                                <Form.Text muted>The link provided should be static - meaning if you change the list the link also changes. Also, be sure the link provided doesn't require the user to log into an external site!</Form.Text>
                                <Form.Control placeholder='Enter or paste link here' disabled={this.state.updating} onChange={(e)=>this.handleListLinkChange(e)} autoComplete='off' value={this.state.listLink} maxLength={maxTextLength}/>
                                {this.state.listLink.length !== 0 && isInvalidUrl &&
                                <Form.Text className='text-danger'><b>That link is not a valid URL.</b></Form.Text>}
                            </Col>}
                            {(this.state.uploadMode === 'cerebro' || this.state.uploadMode === 'tabletop_admiral' || this.state.uploadMode === 'point_break') &&
                            <Col>
                                <IntegratedListSelector
                                    key={this.state.uploadMode}
                                    league={this.props.league}
                                    localUser={this.props.localUser}
                                    service={this.state.uploadMode}
                                    listContainer={this.props.listContainer}
                                    selectCallback={(selectedId)=>this.onIntegratedUploadSelect(selectedId)}/>
                            </Col>}
                        </Row>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" disabled={!this.state.readyToAccept || this.state.updating || isInvalidUrl} onClick={(e)=>{this.onAccept(e)}}>Accept</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderVenueModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderVenueModal'>
                <NerdHerderVenueModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

export class NerdHerderVenueModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.venueId === 'undefined' && typeof this.props.venue === 'undefined') console.error('missing props.userId or props.user');

        let venueData = null;
        let venueId = null;
        if (typeof this.props.venue !== 'undefined') {
            venueData = this.props.venue;
            venueId = venueData.id;
        } else {
            venueId = this.props.venueId;
        }

        this.state = {
            navigateTo: null,
            venueId: venueId,
            venue: venueData,
            user: null,
            updating: true,
            showUserModal: false,
            userModalUserId: null,
            timezoneList: null,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);

        let sub = null;
        if (this.state.venue === null) {
            sub = this.restPubSub.subscribe('venue', this.state.venueId, (d, k)=>{this.updateVenue(d, k)});
            this.restPubSubPool.add(sub);
        }
        sub = this.restPubSub.subscribeNoRefresh('timezone-list', null, (d, k)=>{this.updateTimezoneList(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    isFavorite() {
        return(this.props.localUser.favorite_venue_ids.includes(this.state.venueId));
    }

    onClickFavorite() {
        const isFavorite = this.isFavorite();
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-venue', 'user-venue',
                                                                'user-id', this.props.localUser.id,
                                                                'venue-id', this.state.venueId);
        joinModelRestApi.patch({favorite: !isFavorite})
        .then((response)=>{
            this.restPubSub.refresh('self', null);
        })
        .catch((error)=>{
            console.error(error);
        });
    }

    updateVenue(venueData, key) {
        const newVenue = NerdHerderDataModelFactory('venue', venueData);
        this.setState({venueId: newVenue.id, venue: newVenue});
    }

    updateTimezoneList(response, key) {
        this.setState({timezoneList: response});
    }

    onManage() {
        this.setState({navigateTo: `/app/managevenue/${this.state.venueId}`});
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);
        if (this.state.venue === null) return(<NerdHerderLoadingModal/>);

        const venueData = this.state.venue;
        const imageHref = venueData.getImageUrl();
        const isManager = venueData.isManager(this.props.localUser.id);
        const managersList = [];
        const leaguesList = [];
        let hasDescription = false;
        if (venueData.description && venueData.description.length !== 0) hasDescription = true;

        if (this.state.showUserModal) {
            return (
                <NerdHerderUserProfileModal userId={this.state.userModalUserId}
                                            localUser={this.props.localUser} 
                                            onCancel={()=>this.setState({showUserModal: false, userModalUserId: null})}/>
            );
        }

        // populate the managers list and the leagues list
        for (const managerId of venueData.manager_ids) {
            const listItem = <UserListItem key={managerId} userId={managerId} localUser={this.props.localUser}/>
            // put the creator on top
            if (managerId === venueData.creator_id) managersList.unshift(listItem);
            else managersList.push(listItem);
        }

        for (const leagueId of venueData.league_ids) {
            const listItem = <LeagueListItem key={leagueId} leagueId={leagueId} localUser={this.props.localUser}/>
            leaguesList.push(listItem);
        }

        let timezoneName = null;
        let utcOffsetHours = null;
        let localUserUtcOffsetHours = null;
        let utcOffsetString = null;
        let userHoursOffset = null;
        let userHoursOffsetMessage = null;
        let sameTimezoneAsLocalUser = false;
        if (this.state.venue.timezone === this.props.localUser.timezone) {
            sameTimezoneAsLocalUser = true;
        }
        if (this.state.timezoneList !== null && !sameTimezoneAsLocalUser) {
            for (const timezone of this.state.timezoneList) {
                if (this.state.venue.timezone === timezone.timezone_name) {
                    timezoneName = timezone.timezone_name;
                    utcOffsetHours = timezone.utc_offset_hours;
                    utcOffsetString = timezone.utc_offset_string;
                }
                if (this.props.localUser.timezone === timezone.timezone_name) {
                    localUserUtcOffsetHours = timezone.utc_offset_hours;
                }
            }
        }
        if (utcOffsetHours !== null && localUserUtcOffsetHours !== null) {
            userHoursOffset = utcOffsetHours - localUserUtcOffsetHours;
            if (userHoursOffset === 1) userHoursOffsetMessage = `Venue is an hour ahead of you`;
            else if (userHoursOffset === -1) userHoursOffsetMessage = `Venue is an hour behind you`;
            else if (userHoursOffset > 0) userHoursOffsetMessage = `Venue is ${userHoursOffset} hours ahead of you`;
            else if (userHoursOffset < 0) userHoursOffsetMessage = `Venue is ${userHoursOffset * -1} hours behind you`;
            else userHoursOffsetMessage = `Venue is at the same time as you`;
        }

        let isFavorite = this.isFavorite();

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{venueData.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!hasDescription &&
                        <div className="text-center">
                            <Image className="rounded rounded-2" height={150} width={150} src={imageHref} alt='venue image'/>
                        </div>}
                        {hasDescription &&
                        <Row className='align-items-center'>
                            <Col xs='auto'>
                                <Image className="rounded rounded-2 float-start" height={150} width={150} src={imageHref} alt='venue image'/>
                            </Col>
                            <Col>
                                <Truncate height={150}>
                                    <LinkifyText><small>{venueData.description}</small></LinkifyText>
                                </Truncate>
                            </Col>
                        </Row>}
                        {venueData.private_venue &&
                        <Alert className='mt-3' variant='warning'>This is a private venue</Alert>}
                        <Table className='mt-3' size='sm'>
                            <tbody>
                                {venueData.discord_link &&
                                <tr><td>Discord</td><td><a target='_blank' rel='noreferrer' href={venueData.discord_link}>{venueData.discord_link}</a></td></tr>}
                                {venueData.website &&
                                <tr><td>Website</td><td><a target='_blank' rel='noreferrer' href={venueData.website}>{venueData.website}</a></td></tr>}
                                {venueData.email &&
                                <tr><td>Email</td><td><a target='_blank' rel='noreferrer' href={`mailto:${venueData.email}`}>{venueData.email}</a></td></tr>}
                                {venueData.phone &&
                                <tr><td>Phone</td><td>{venueData.phone}</td></tr>}
                                <tr><td>Location</td><td>{venueData.venue_string} <NerdHerderMapIcon location={venueData.venue_string}/></td></tr>
                                <tr><td>Country</td><td>{venueData.country}</td></tr>
                                {sameTimezoneAsLocalUser &&
                                <tr><td>Timezone</td><td>{this.props.localUser.timezone} (same as you)</td></tr>}
                                {!sameTimezoneAsLocalUser && timezoneName &&
                                <tr><td>Timezone</td><td>{timezoneName} ({utcOffsetString})<br/>{userHoursOffsetMessage}</td></tr>}
                            </tbody>
                        </Table>
                        {!isManager &&
                        <div>
                            <Row>
                                <Col>
                                    <big><b>Favorite</b></big>
                                </Col>
                                <Col xs='auto'>
                                    <NerdHerderFavoriteIcon favorite={isFavorite} onClick={()=>this.onClickFavorite()}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-muted'>You will be notified of all events & leagues at your favorite venues.</small>
                                </Col>
                            </Row>
                        </div>}
                        {managersList.length !== 0 &&
                        <div className='mt-3'>
                            <big><b>Managers</b></big>
                            {managersList}
                        </div>}
                        {leaguesList.length !== 0 &&
                        <div className='mt-3'>
                            <big><b>Leagues</b></big>
                            {leaguesList}
                        </div>}
                        {this.props.children}
                    </Modal.Body>
                    {isManager &&
                    <Modal.Footer>
                        <Button variant='danger' onClick={()=>this.onManage()}>Manage</Button>
                    </Modal.Footer>}
                </Modal>
            </div>
        )
    }
}

export class NerdHerderVenueSearchModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderVenueSearchModal'>
                <NerdHerderVenueSearchModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

export class NerdHerderVenueSearchModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');

        // a few backwards nations *heh* still use imperial units
        this.useImperialUnits = false;
        if (["United States", "Myanmar", "Liberia"].includes(this.props.localUser.country)) {
            this.useImperialUnits = true;
        }

        // normally searching is done to accept a selection, but it is possible to show the modal and not make anything selectable
        this.selectMode = true;
        if (!this.props.onAccept) this.selectMode = false;

        this.state = {
            navigateTo: null,
            updating: true,
            searching: true,
            showVenueModal: false,
            showVenueModalId: null,
            searchMode: 'nearby', // can be 'nearby' or 'name'
            searchString: null,
            venueIds: [],
            selectedVenueId: this.props.selectedVenueId || null,
            fromZipcode: null,
            fromCountry: null,
            fromLatitude: null,
            fromLongitude: null,
            venueDict: {}
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.triggerSearchTimeout = null;
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        this.triggerSearch(200);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateResults(resultList) {
        // should sort this list
        const newVenueIds = [];
        const newVenueDict = [];
        for (const venueData of resultList) {
            const newVenue = NerdHerderDataModelFactory('venue', venueData);
            let venueId = newVenue.id;
            newVenueDict[venueId] = newVenue;
            newVenueIds.push(venueId);
        }
        this.setState({venueIds: newVenueIds, venueDict: newVenueDict, updating: false})
    }

    onClickSearchSearch(search) {
        if (search !== null && search !== '') {
            this.setState({searchMode: 'name', updating: true, searchString: search})
        } else {
            this.setState({searchMode: 'nearby', updating: true, searchString: null})
        }
        this.triggerSearch(200);
    }

    onSelectVenue(venueId) {
        if (this.selectMode) {
            if (this.state.selectedVenueId === venueId) {
                this.setState({selectedVenueId: null});
            } else {
                this.setState({selectedVenueId: venueId});
            }
        }
        
    }

    triggerSearch(timeout=3000) {
        if (this.triggerSearchTimeout) clearTimeout(this.triggerSearchTimeout);
        this.triggerSearchTimeout = setTimeout(()=>this.doSearch(), timeout);
    }

    doSearch() {
        let filterParams = {};
        if (this.state.searchMode === 'nearby') {
            filterParams['postal_code'] = this.props.localUser.zipcode;
            
            if (this.useImperialUnits) {
                filterParams['range_mi'] = 500;
            } else {
                filterParams['range_km'] = 800;
            }

            if (this.state.fromLatitude && this.state.fromLongitude) {
                filterParams['from-latitude'] = this.state.fromLatitude;
                filterParams['from-longitude'] = this.state.fromLongitude;
            } else if (this.state.fromZipcode && this.state.fromCountry) {
                filterParams['postal_code'] = this.state.fromZipcode;
                filterParams['from-country'] = this.state.fromCountry;
            }
        } else {
            filterParams['name-similar'] = this.state.searchString.trim();
        }

        this.restApi.genericGetEndpointData('venue', null, filterParams)
        .then(response => {
            this.updateResults(response.data);
        }).catch(error => {
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);

        if (this.state.showVenueModal) {
            return (
                <NerdHerderNewMessageModal venudId={this.state.showVenueModalId}
                                           localUser={this.props.localUser} 
                                           onCancel={()=>this.setState({showVenueModal: false, showVenueModalId: null})}/>
            );
        }

        const venueList = [];
        for (const venueId of this.state.venueIds) {
            let listItem = null;
            if (this.selectMode) {
                listItem = <VenueListItem key={venueId} selected={venueId===this.state.selectedVenueId} venueId={venueId} venue={this.state.venueDict[venueId]} onClick={()=>this.onSelectVenue(venueId)} localUser={this.props.localUser}/>
            } else {
                listItem = <VenueListItem key={venueId} selected={venueId===this.state.selectedVenueId} venueId={venueId} venue={this.state.venueDict[venueId]} localUser={this.props.localUser}/>
            }
            venueList.push(listItem);
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Search Venues</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Text muted>Below is a list of venues that have been registered on NerdHerder. You can search for a specific name as well.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <FormControlSearch disabled={this.state.updating} placeholder='Venue name to search' onClick={(s)=>{this.onClickSearchSearch(s)}}/>
                            </Form.Group>
                        </Form>
                        <hr/>
                        {venueList.length === 0 &&
                        <div><small>There are no venues to show for this query.</small></div>}
                        {venueList.length !== 0 && this.state.searchMode === 'nearby' &&
                        <div className='mb-2'><small>Showing venues nearby.</small></div>}
                        {venueList.length !== 0 && this.state.searchMode === 'name' &&
                        <div className='mb-2'><small>Showing venues with names like "{this.state.searchString}".</small></div>}
                        {venueList}
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>{'Cancel'}</Button>
                        {this.selectMode &&
                        <Button variant="primary" disabled={this.state.selectedVenueId===null} onClick={()=>{undoOnBackCancelModal(); this.props.onAccept(this.state.selectedVenueId)}}>{'Select'}</Button>}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderStripePaymentModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderStripePaymentModal'>
                <NerdHerderStripePaymentModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderStripePaymentModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onPaid === 'undefined') console.error('missing props.onPaid');
        if (typeof this.props.onOptOut === 'undefined') console.error('missing props.onOptOut');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.stripeInfoSubscription = null;
        this.stripePromise = null;
        this.stripeOptions = null;

        this.state = {
            updating: false,
            errorFeedback: null,
            mode: 'initial', //'initial', 'stripe' are the modes
            stripeInfo: null,
            stripeOptions: null,
            currencyData: null,
            onUpdateCancel: false,
            showDiscountCodeInput: false,
            discountCodeResult: null,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('stripe-info', null, (d,k)=>this.updateStripeInfo(d,k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('currency-list', null, (d,k)=>this.updateCurrencyList(d,k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateStripeInfo(stripeInfo, key) {
        this.setState({stripeInfo: stripeInfo});
    }

    updateCurrencyList(currencyList, key) {
        let foundIt = false;
        for (const currencyItem of currencyList) {
            if (currencyItem.alpha_id === this.props.league.registration_fee_currency) {
                this.setState({currencyData: currencyItem});
                foundIt = true;
                break;
            }
        }
        if (!foundIt) {
            this.setState({errorFeedback: 'Unable to determine currency to use'});
        }
    }

    onDiscountCodeChange(value) {
        value = value.replaceAll('|', '');
        value = value.replaceAll('_', '');
        value = value.toUpperCase();
        return value;
    }

    onDiscountCode(value) {
        // see if the code is valid
        let trimmedValue = value.trim();
        const queryParams = {'discount-code': trimmedValue, 'league-id': this.props.league.id};
        this.restApi.genericGetEndpointData('discount-code-available', null, queryParams)
        .then((response)=>{
            const discountCodeResult = response.data;
            this.setState({discountCodeResult: discountCodeResult});
        })
        .catch((error)=>{
            console.error('got unexpected error from discount-code-available endpoint');
            console.error(error);
        });
    }

    onPayNow(amount) {
        let discountCode = null;
        if (this.state.discountCodeResult && this.state.discountCodeResult.available) {
            discountCode = this.state.discountCodeResult.code;
        }
        const postData = {
            user_id: this.props.localUser.id,
            league_id: this.props.league.id,
            venue_id: this.props.league.venue_id,
            reason: 'league reg fee',
            amount: amount,
            code: discountCode,
            currency: this.state.currencyData.alpha_id,
        };
        
        // if the amount was free, use the special payment endpoint
        if (amount === 0) {
            this.restApi.genericPostEndpointData('free-payment', null, postData)
            .then((response)=>{
                this.setState({updating: true});
                undoOnBackCancelModal();
                this.props.onPaid();
            })
            .catch((error)=>{
                this.setState({errorFeedback: 'Free payment request failed'});
                console.error('free payment request failed');
                console.error(error);
            });
        } else {
            this.restApi.genericPostEndpointData('stripe-payment-intent', null, postData)
            .then((response)=>{
                let stripeAccountId = null;
                let stripePaymentIntent = response.data;
                this.stripeOptions = {clientSecret: stripePaymentIntent.client_secret};
                // if there is a connected account, we need that id to pass into loadStripe
                if (stripePaymentIntent.hasOwnProperty('connected_acct_id')) {
                    stripeAccountId = stripePaymentIntent.connected_acct_id;
                    this.stripePromise = loadStripe(this.state.stripeInfo.stripe_public_key, {stripeAccount: stripeAccountId});
                } else {
                    this.stripePromise = loadStripe(this.state.stripeInfo.stripe_public_key);
                }
                this.setState({mode: 'stripe'});
            })
            .catch((error)=>{
                this.setState({errorFeedback: 'Payment request failed - you were not charged'});
                console.error('failed to send the league-payment-intent');
                console.error(error);
            });
        }
    }

    onPayLater() {
        undoOnBackCancelModal();
        this.props.onOptOut();
    }

    onSubmittedPayment(data) {
        console.log('Payment submitted', data);
    }

    render() {
        if (this.state.stripeInfo === null) return(<NerdHerderLoadingModal/>);
        if (this.state.currencyData === null) return(<NerdHerderLoadingModal/>);

        const registrationFeeOption = this.props.league.registration_fee_option;
        const registrationFeeMessage = this.props.league.registration_fee_message;

        // decide if we're using the normal fee or a discount fee
        let useDiscountFee = false;
        let fee = this.props.league.registration_fee_cost;
        if (this.state.discountCodeResult && this.state.discountCodeResult.available) {
            useDiscountFee = true;
            fee = this.state.discountCodeResult.amount;
        }
        
        let currencyAlpha = this.state.currencyData.alpha_id;
        let registrationFeeCost = getCurrency(fee, this.state.currencyData, this.props.localUser.country);
        const registrationFeeOverhead = this.props.league.registration_fee_overhead;
        if (registrationFeeOverhead === 'players') {
            const registrationFeeFlatPart = currency(this.state.stripeInfo.stripe_fee_flat_charge);
            registrationFeeCost = registrationFeeCost.add(registrationFeeFlatPart);
            let stripeFeePercent = this.state.stripeInfo.stripe_fee_percent_charge;
            stripeFeePercent = parseFloat(stripeFeePercent);
            let stripeFeeDivisor = 1.0 - stripeFeePercent;
            console.debug('stripeFeeDivisor', stripeFeeDivisor);
            console.debug('expected', 0.971);
            registrationFeeCost = registrationFeeCost.divide(0.971);
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered static='true' show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        {this.state.mode === 'initial' &&
                        <Modal.Title>{'Registration Fee'}</Modal.Title>}
                        {this.state.mode === 'stripe' &&
                        <Modal.Title>{'Stripe Payment'}</Modal.Title>}
                    </Modal.Header>
                    {this.state.mode === 'initial' &&
                    <Modal.Body>
                        {this.state.errorFeedback &&
                        <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                        {registrationFeeOption === 'required' &&
                        <p>This league requires new players to pay the registration fee online before joining. The fee is generally non-refundable, so before moving forward ensure you are ready to commit to this league!</p>}
                        {registrationFeeOption === 'optional' &&
                        <p>This league requires new players to pay a registration fee. You may pay it now, or it may be paid later.</p>}
                        <Row>
                            <Col>
                                <p>
                                    <b>Fee:</b> {registrationFeeCost.format()} {currencyAlpha}
                                    {useDiscountFee && <small className='text-muted'> <i>Discounted!</i></small>}
                                </p>
                            </Col>
                            <Col xs='auto'>
                                <Button size='sm' variant='primary' onClick={()=>this.setState({showDiscountCodeInput: !this.state.showDiscountCodeInput})}>Enter Code</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Collapse in={this.state.showDiscountCodeInput}>
                                    <div className='mb-3'>
                                        <Form>
                                            <Form.Group>
                                                <FormControlSubmit type='text' size='sm' value='' onClick={(v)=>this.onDiscountCode(v)} onChangeValueIntercept={(v)=>this.onDiscountCodeChange(v)}/>
                                                {this.state.discountCodeResult && this.state.discountCodeResult.available &&
                                                <Form.Text><small>{this.state.discountCodeResult.message}</small></Form.Text>}
                                                {this.state.discountCodeResult && !this.state.discountCodeResult.available &&
                                                <Form.Text className='text-danger'><small>{this.state.discountCodeResult.message}</small></Form.Text>}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </Collapse>
                            </Col>
                        </Row>
                        {registrationFeeMessage !== null &&
                        <p>{registrationFeeMessage}</p>}
                        {this.props.league && this.props.league.venue_id &&
                        <div>
                            <p>The registration fee will be paid to:</p>
                            <VenueListItem venueId={this.props.league.venue_id} localUser={this.props.localUser} onClick={null}/>
                        </div>
                        }
                    </Modal.Body>}
                    {this.state.mode === 'stripe' &&
                    <Modal.Body>
                        {this.state.errorFeedback &&
                        <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                        <Elements stripe={this.stripePromise} options={this.stripeOptions}>
                            <CheckoutForm/>
                        </Elements>
                    </Modal.Body>}
                    {this.state.mode === 'initial' &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        {registrationFeeOption === 'optional' &&
                        <Button variant="secondary" onClick={()=>this.onPayLater()} disabled={this.state.updating}>Pay Later</Button>}
                        <Button variant="primary" onClick={()=>this.onPayNow(registrationFeeCost.intValue)} disabled={this.state.updating || this.state.currencyData===null}>Pay Now</Button>
                    </Modal.Footer>}
                </Modal>
            </div>
        );
    }
}

export class NerdHerderStripePaymentDetailsModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderStripePaymentDetailsModal'>
                <NerdHerderStripePaymentDetailsModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderStripePaymentDetailsModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.userId === 'undefined') console.error('missing props.userId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            stripeAccount: null,
            showRefundModal: false,
            errorFeedback: null,
            user: null,
            paymentIntent: null,
            currencyDict: null,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('currency-list', null, (d,k)=>this.updateCurrencyDict(d,k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('user', this.props.userId, (d,k)=>this.updateUser(d,k));
        this.restPubSubPool.add(sub);
        if (this.props.league.venue_id) {
            sub = this.restPubSub.subscribe('venue-stripe', this.props.league.venue_id, (d, k)=>this.updateStripeAccount(d, k), (e, a)=>this.updateStripeAccountFailed(e, a));
            this.restPubSubPool.add(sub);
        }

        const queryParams = {user_id: this.props.userId, league_id: this.props.league.id, venue_id: this.props.league.venue_id};
        this.restApi.genericGetEndpointData('stripe-payment-intent', 'league-reg-fee', queryParams)
        .then((response)=>{
            const paymentIntent = response.data;
            this.setState({paymentIntent: paymentIntent});
        })
        .catch((error)=>{
            // and error means the user doesn't have a payment intent with stripe
            this.setState({paymentIntent: 'no payment intent'});
        });
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateUser(userData, key) {
        const user = NerdHerderDataModelFactory('user', userData);
        this.setState({user: user});
    }

    updateStripeInfo(stripeInfo, key) {
        this.setState({stripeInfo: stripeInfo});
    }

    updateStripeAccount(stripeAccountInfo, key) {
        this.setState({stripeAccount: stripeAccountInfo});
    }

    // it is possible the venue doesn't have a stripe account yet
    updateStripeAccountFailed(error, apiName) {
        this.setState({stripeAccount: null});
    }

    updateCurrencyDict(currencyList, key) {
        let newCurrencyDict = convertListToDict(currencyList, 'alpha_id');
        this.setState({currencyDict: newCurrencyDict});
    }

    onRefund() {
        this.setState({updating: true, showRefundModal: false});
        let connected_acct_id = null;
        if (this.state.paymentIntent.connected_acct_id) connected_acct_id = this.state.paymentIntent.connected_acct_id;
        let postData = {payment_intent_id: this.state.paymentIntent.id, connected_acct_id: connected_acct_id};
        this.restApi.genericPostEndpointData('stripe-payment-refund', null, postData)
        .then((response)=>{
            this.setState({updating: false, paymentIntent: 'refund issued'});
        })
        .catch((error)=>{
            let errorMessage = null;
            try {
                errorMessage = error.response.data.message;
            } catch {
                errorMessage = 'Unable to issue a refund';
            }
            this.setState({updating: false, errorFeedback: errorMessage});
        });
    }

    render() {
        if (this.state.user === null) return(<NerdHerderLoadingModal/>);
        if (this.state.paymentIntent === null) return(<NerdHerderLoadingModal/>);
        if (this.state.currencyDict === null) return(<NerdHerderLoadingModal/>);
        if (this.state.paymentIntent === 'no payment intent') return(
            <NerdHerderMessageModal
                title='League Registration Fee Details'
                message={`NerdHerder has no record (completed or failed) of a league registration fee payment from ${this.state.user.username}.`}
                onCancel={()=>{undoOnBackCancelModal(); this.props.onCancel()}}/>
        );
        if (this.state.paymentIntent === 'refund issued') return(
            <NerdHerderMessageModal
                title='League Registration Fee Details'
                message={`NerdHerder has issued a refund (less Stripe fees) for the league registration fee payment from ${this.state.user.username}.`}
                onCancel={()=>{undoOnBackCancelModal(); this.props.onCancel()}}/>
        );
        if (this.state.showRefundModal) return(
            <NerdHerderConfirmModal
                title='Refund?'
                message={<span>Are you sure you want to issue a refund to {this.state.user.username}? Once the refund is issued there is no undo.<br/><br/><i>Stripe fees will not be refunded.</i></span>}
                acceptButtonText='Issue Refund'
                onCancel={()=>this.setState({showRefundModal: false})}
                onAccept={()=>this.onRefund()}/>
        );

        const paymentIntent = this.state.paymentIntent;
        const stripeAccount = this.state.stripeAccount;
        const currencyAlphaId = paymentIntent.currency.toUpperCase();
        const currencyData = this.state.currencyDict[currencyAlphaId];
        const paymentAmount = getCurrency(paymentIntent.amount, currencyData, this.props.localUser.country);
        const amountReceived = getCurrency(paymentIntent.amount_received, currencyData, this.props.localUser.country);
        let feeAmount = null;
        let netAmount = null;
        let refundAmount = null;
        if (paymentIntent.hasOwnProperty('fee_amount') && paymentIntent.hasOwnProperty('net_amount')) {
            feeAmount = getCurrency(paymentIntent.fee_amount, currencyData, this.props.localUser.country);
            netAmount = getCurrency(paymentIntent.net_amount, currencyData, this.props.localUser.country);
        }
        if (paymentIntent.hasOwnProperty('refund_amount')) {
            refundAmount = getCurrency(paymentIntent.refund_amount, currencyData, this.props.localUser.country);
        }
        const stripeTimestamp = DateTime.fromSeconds(paymentIntent.created);
        let isCanceled = false;
        let cancelTimestamp = null;
        if (paymentIntent.canceled_at) isCanceled = true;
        if (paymentIntent.canceled_at) cancelTimestamp = DateTime.fromSeconds(paymentIntent.caneled_at);
        let paymentMethod = 'Unknown';
        if (paymentIntent.hasOwnProperty('payment_method')) {
            paymentMethod = paymentIntent.payment_method.type;
            if (paymentIntent.payment_method.hasOwnProperty('card_brand')) {
                paymentMethod = `${paymentMethod} (${paymentIntent.payment_method.card_brand})`; 
            }
        }

        // if there is already a refund, or no payment, don't show the refund button
        let allowRefund = true;
        let refundCurrencyMessage = null;
        if (paymentIntent === 'refund issued' || paymentIntent === 'no payment intent' || paymentIntent.refunded) {
            allowRefund = false;
        }
        // if we would allow a refund, but the currency doesn't match the stripe account currency, don't show the refund button but mention why
        if (allowRefund && stripeAccount && stripeAccount.currency !== paymentIntent.currency) {
            allowRefund = false;
            refundCurrencyMessage = `The payment was in ${paymentIntent.currency} but the Stripe account is in ${stripeAccount.currency}. NerdHerder cannot process refunds when a currency conversion is involved. Venue managers may issue a refund from within the Stripe account.`
        }

        let status = paymentIntent.status;
        if (paymentIntent.refunded) status = 'refunded';

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>League Registration Fee Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorFeedback &&
                        <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                        <Table striped size='sm' responsive>
                            <tbody>
                                <tr><td>Amount</td><td>{paymentAmount.format()} {currencyAlphaId} (Received: {amountReceived.format()})</td></tr>
                                {feeAmount &&
                                <tr><td>Stripe Fees</td><td>{feeAmount.format()}</td></tr>}
                                {paymentIntent.refunded &&
                                <tr><td>Refund</td><td>{refundAmount.format()}</td></tr>}
                                {netAmount &&
                                <tr><td>Net</td><td>{netAmount.format()} (amount venue will receive)</td></tr>}
                                <tr><td>Date</td><td>{stripeTimestamp.toLocaleString()}</td></tr>
                                <tr><td>Status</td><td className='text-capitalize'>{status}</td></tr>
                                <tr><td>Method</td><td className='text-capitalize'>{paymentMethod}</td></tr>
                                <tr><td>Canceled</td><td>{isCanceled?'Yes':'No'}</td></tr>
                                {isCanceled &&
                                <tr><td>Reason</td><td>{paymentIntent.canellation_reason}</td></tr>}
                                {isCanceled &&
                                <tr><td>Cancel Date</td><td>{cancelTimestamp.toLocaleString()}</td></tr>}
                                <tr><td>Description</td><td>{paymentIntent.description}</td></tr>
                                <tr><td>Descriptor</td><td>{paymentIntent.statement_descriptor}</td></tr>
                                <tr><td>Stripe ID</td><td>{paymentIntent.id}</td></tr>
                            </tbody>
                        </Table>
                        {refundCurrencyMessage &&
                        <div>
                            <p className='text-danger'>{refundCurrencyMessage}</p>
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        {allowRefund &&
                        <Button variant="danger" disabled={this.state.updating} onClick={()=>this.setState({showRefundModal: true})}>Refund</Button>}
                        <Button variant="primary" disabled={this.state.updating} onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderQrScanModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onScan === 'undefined') console.error('missing props.onScan');

        this.scanner = null;
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        setTimeout(()=>this.startScanner(), 300);
    }

    startScanner() {
        this.scanner = new Html5QrcodeScanner("scanner", { fps: 10, qrbox: {width: 300, height: 300} }, false);
        this.scanner.render((dt, dr)=>this.onScanSuccess(dt, dr), (e)=>this.onScanFailure(e));
    }

    stopScanner() {
        if (this.scanner) this.scanner.clear();
    }

    onScanSuccess(decodedText, decodedResult) {
        // handle the scanned code as you like, for example:
        console.log(`Code matched = ${decodedText}`, decodedResult);
        this.stopScanner();
        undoOnBackCancelModal();
        this.props.onScan(decodedText, decodedResult);
    }
      
    onScanFailure(error) {
    }

    render() {
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.stopScanner(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Scan QR Code'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        {this.props.children}
                        <div id="scanner" width="500px"></div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.stopScanner(); this.props.onCancel()}}>{this.props.buttonText || 'Cancel'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderQrLoginModal extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        this.loginToken = getCookie('LoginToken', 'this is not a valid token');
        this.ref = null;

        this.setRef = ref => {
            let oldref = this.ref;
            this.ref = ref;
            if (oldref === null) this.forceUpdate();
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    render() {
        let qrSize = 0;
        if (this.ref) {
            let modalRect = this.ref.getBoundingClientRect();
            let modalWidth = modalRect.width;
            if (window.innerHeight < modalWidth) qrSize = window.innerHeight * 0.75;
            else qrSize = modalWidth * 0.75;
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal centered size='lg' show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Share Device Sign In'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <p><small></small>Scan this QR code with your other device to sign in</p>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col xs={12} className="text-center" ref={this.setRef}>
                                <Row className='justify-content-center'>
                                    <Col xs='auto'>
                                        {qrSize !== 0 && this.props.simple &&
                                        <NerdHerderSimpleQrCode size={qrSize} value={this.loginToken}/>}
                                        {qrSize !== 0 && !this.props.simple &&
                                        <NerdHerderQrCode size={qrSize} value={this.loginToken}/>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {this.props.children}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderFileContainerContentsModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderFileContainerContentsModal' onCancel={()=>this.props.onCancel()}>
                <NerdHerderFileContainerContentsModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderFileContainerContentsModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.fileContainerId === 'undefined') console.error('missing props.fileContainerId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state ={
            updating: false,
            fileContainer: null,
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        const sub = this.restPubSub.subscribe('list-container', this.props.fileContainerId, (d, k)=>{this.updateFileContainer(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateFileContainer(fileContainerData, key) {
        this.setState({fileContainer: fileContainerData, updating: false});
    }

    onClickView(fileData, event) {
        let href = getStorageFilePublicUrl(`/${fileData.path}`);
        window.open(href, '_blank');
        event.stopPropagation();
    }

    onClickLock(fileData, toState) {
        this.setState({updating: true});
        const patchData = {};
        if (toState === 'lock') patchData['locked'] = true;
        else patchData['locked'] = false;
        this.restApi.genericPatchEndpointData('file', fileData.id, patchData)
        .then((response)=>{
            this.restPubSub.refresh('list-container', this.state.fileContainer.id);
        })
        .catch((error)=>{
            this.setState({updating: false});
            console.error('failed to change file.locked state');
            console.error(error);
        })
    }

    onClickDelete(fileData) {
        this.setState({updating: true});
        this.restApi.genericDeleteEndpointData('file', fileData.id)
        .then((response)=>{
            this.restPubSub.refresh('list-container', this.state.fileContainer.id);
        })
        .catch((error)=>{
            this.setState({updating: false});
            console.error('failed to delete file');
            console.error(error);
        })
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);
        if (this.state.fileContainer === null) return(<NerdHerderLoadingModal/>);
        const fileContainer = this.state.fileContainer;
        const listNoun = this.props.league.topic.list_noun;
        const listNounPlural = pluralize(listNoun);
        const userIdsList = [];
        const datesDict = {};
        const buttonsDict = {};

        let showTable = false;
        if (fileContainer.file_ids.length !== 0) showTable = true;

        if (showTable) {
            for (const fileData of fileContainer.files) {
                const userId = fileData.user_id;
                userIdsList.push(userId);
                let lockOrUnlock = 'lock';
                let lockOrUnlockIcon = 'flaticon-locked-black-rectangular-padlock';
                let lockOrUnlockVariant = 'primary'
                if (fileData.locked) {
                    lockOrUnlock = 'unlock';
                    lockOrUnlockVariant = 'outline-primary'
                    lockOrUnlockIcon = 'flaticon-locked-black-rectangular-padlock';
                }
                datesDict[userId] =
                <div>
                    <small>{fileData.filename}</small><br/>
                    <small>{fileData.date}</small>
                    {fileData.locked &&
                    <small> (locked)</small>}
                </div>
                buttonsDict[userId] = 
                    <span>
                        <NerdHerderToolTipButton size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.onClickView(fileData)} icon='flaticon-search' tooltipText={`view ${listNoun}`}/>
                        {' '}
                        <NerdHerderToolTipButton size='sm' variant={lockOrUnlockVariant} disabled={this.state.updating} onClick={()=>this.onClickLock(fileData, lockOrUnlock)} icon={lockOrUnlockIcon} tooltipText={`${lockOrUnlock} ${listNoun}`}/>
                        {' '}
                        <NerdHerderToolTipButton size='sm' variant='danger' disabled={this.state.updating} onClick={()=>this.onClickDelete(fileData)} icon='flaticon-recycle-bin-filled-tool' tooltipText={`delete ${listNoun}`}/>
                    </span>
            }
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{fileContainer.name} Contents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!showTable &&
                        <Row>
                            <Col xs={12}>
                                <small className='text-muted'>No users have added a {listNoun} to this folder</small>
                            </Col>
                        </Row>}
                        {showTable &&
                        <Row>
                            <Col xs={12}>
                                <TableOfUsers userIds={userIdsList} title={`Submitted ${listNounPlural}`} disable={this.state.updating} localUser={this.props.localUser}
                                      headers={['User','Info','Options']} middleColumnContent={datesDict} rightColumnContent={buttonsDict} 
                                      emptyMessage='There are users to show upload status.'/>
                            </Col>
                        </Row>}
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={this.state.updating} onClick={()=>{this.props.onCancel()}}>Done</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderRandomSelectorResultModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderRandomSelectorResultModal'>
                <NerdHerderRandomSelectorResultModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderRandomSelectorResultModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.onAccept === 'undefined') console.error('missing props.onAccept');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.randomSelectorId === 'undefined') console.error('missing props.randomSelectorId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.presentation = 'league';
        this.allPlayersIdList = this.props.league.player_ids;
        const allPlayersDict = {};
        if (this.props.tournament) {
            this.presentation = 'tournament';
            this.allPlayersIdList = this.props.tournament.player_ids;
        }
        else if (this.props.event) {
            this.presentation = 'event';
            this.allPlayersIdList = this.props.event.player_ids;
        }
        // unfortunately we will need to load the tournament and event in some cases
        else if (this.props.tournamentId) {
            this.presentation = 'tournament';
            this.allPlayersIdList = [];
        }
        else if (this.props.eventId) {
            this.presentation = 'event';
            this.allPlayersIdList = [];
        }

        for (const userId of this.allPlayersIdList) {
            allPlayersDict[userId] = false;
        }
        
        // selector types:
        // SUID - single user ID in form SUID:XX where XX is the ID
        // MUID - multiple user IDs in form MUID:XX:YY:ZZ... where XX, YY, ZZ are the user IDs
        // ORDR - multiple user IDs in form ORDR:XX:YY:ZZ... and all users in the league/event/tournament are part of the order
        // VALX - a random number in the form VAL:XXXX:YYYY:ZZZZ where x is min, y is max, z is selected value
        this.state = {
            randomSelector: null,
            methodOption: 'SUID',
            method: 'SUID',
            showSubsetSelector: false,
            resultJsx: null,
            result: null,
            showResult: false,
            updating: false,
            disableInput: false,
            formNumSelections: 2,
            formGroupSize: 2,
            formMinValue: 1,
            formMaxValue: 10,
            updatedDefaultName: null,
            includedPlayerDict: allPlayersDict,
            playersDict: null,
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        let sub = this.restPubSub.subscribeNoRefresh('random-selector', this.props.randomSelectorId, (d, k) => {this.updateRandomSelector(d, k)});
        this.restPubSubPool.add(sub);

        // if we had to do a load, get that now
        if (this.presentation === 'tournament' && this.allPlayersIdList.length === 0) {
            let sub = this.restPubSub.subscribeNoRefresh('tournament', this.props.tournamentId, (d, k) => {this.updateTournamentPlayers(d, k)});
            this.restPubSubPool.add(sub);
        }
        else if (this.presentation === 'event' && this.allPlayersIdList.length === 0) {
            let sub = this.restPubSub.subscribeNoRefresh('event', this.props.eventId, (d, k) => {this.updateEventPlayers(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateRandomSelector(randomSelectorData, key) {
        const newRandomSelector = NerdHerderDataModelFactory('random_selector', randomSelectorData);
        this.setState({randomSelector: newRandomSelector});
    }

    updateTournamentPlayers(tournamentData, key) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.allPlayersIdList = newTournament.player_ids;
        const allPlayersDict = {};
        for (const userId of this.allPlayersIdList) {
            allPlayersDict[userId] = false;
        }
        this.setState({includedPlayerDict: allPlayersDict});
    }

    updateEventPlayers(eventData, key) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.allPlayersIdList = newEvent.player_ids;
        const allPlayersDict = {};
        for (const userId of this.allPlayersIdList) {
            allPlayersDict[userId] = false;
        }
        this.setState({includedPlayerDict: allPlayersDict});
    }

    handleFormNumSelections(event) {
        let value = event.target.value;
        value = parseInt(value);
        if (isNaN(value)) value = '';
        else if (value < 1) value = 1;
        this.setState({formNumSelections: value});
    }

    handleFormGroupSize(event) {
        let value = event.target.value;
        value = parseInt(value);
        if (isNaN(value)) value = '';
        else if (value < 2) value = 2;
        this.setState({formGroupSize: value});
    }

    handleFormMinValue(event) {
        let value = event.target.value;
        value = parseInt(value);
        if (isNaN(value)) value = '';
        this.setState({formMinValue: value});
    }

    handleFormMaxValue(event) {
        let value = event.target.value;
        value = parseInt(value);
        if (isNaN(value)) value = '';
        this.setState({formMaxValue: value});
    }

    handleIncludePlayer(event, userId) {
        let checked = event.target.checked;
        const newDict = {...this.state.includedPlayerDict};
        newDict[userId] = checked;
        this.setState({includedPlayerDict: newDict});
    }

    onSelectMethod(event) {
        let newMethodOption = event.target.value;
        let newMethod = newMethodOption;
        let showSubsetSelector = false;
        if (newMethod === 'SUID-S') {
            newMethod = 'SUID';
            showSubsetSelector = true;
        } else if (newMethod === 'MUID-S') {
            newMethod = 'MUID';
            showSubsetSelector = true;
        }

        this.setState({method: newMethod, methodOption: newMethodOption, showSubsetSelector: showSubsetSelector, result: null, resultJsx: null, showResult: false});
    }

    onMakeRandomSelection() {
        let min = 0;
        let max = 0;
        let index = 0;
        let result = 0;
        let resultJsx = null;
        let resultJsxItems = [];
        let numSelections = 0;
        let groupSize = 2;
        let resultList = [];
        let usersList = null;
        if (this.state.showSubsetSelector) {
            usersList = [];
            for (const userId of Object.keys(this.state.includedPlayerDict)) {
                if (this.state.includedPlayerDict[userId]) usersList.push(userId);
            }
        } else {
            usersList = [...this.allPlayersIdList];
        }
        switch(this.state.method) {
            case 'SUID':
                max = usersList.length;
                index = getRandomInteger(min, max);
                result = usersList[index];
                resultJsx = 
                    <div>
                        <UserListItem userId={result} localUser={this.props.localUser}/>
                    </div>
                this.setState({result: `SUID:${result}`, resultJsx: resultJsx, showResult: false, updating: true, updatedDefaultName: 'Random Player'});
                break;
            case 'MUID':
                numSelections = parseInt(this.state.formNumSelections);
                if (isNaN(numSelections)) {
                    numSelections = 2;
                    this.setState({formNumSelections: numSelections});
                } 
                else if (numSelections > usersList.length) {
                    numSelections = usersList.length;
                    this.setState({formNumSelections: numSelections});
                } 
                for (let i=0; i<numSelections; i++) {
                    max = usersList.length;
                    index = getRandomInteger(min, max);
                    result = usersList[index];
                    resultList.push(result);
                    usersList.splice(index, 1);
                }
                for (const userId of resultList) {
                    const userListItem = <UserListItem key={userId} userId={userId} slim={true} localUser={this.props.localUser}/>
                    resultJsxItems.push(userListItem);
                }
                resultJsx = 
                    <div>
                        {resultJsxItems}
                    </div>
                this.setState({result: `MUID:${resultList.join(':')}`, resultJsx: resultJsx, showResult: false, updating: true, updatedDefaultName: 'Lucky Players'});
                break;
            case 'ORDR':
                numSelections = usersList.length;
                for (let i=0; i<numSelections; i++) {
                    max = usersList.length;
                    index = getRandomInteger(min, max);
                    result = usersList[index];
                    resultList.push(result);
                    usersList.splice(index, 1);
                }
                for (const userId of resultList) {
                    const userListItem = <UserListItem key={userId} userId={userId} slim={true} localUser={this.props.localUser}/>
                    resultJsxItems.push(userListItem);
                }
                resultJsx = 
                    <div>
                        {resultJsxItems}
                    </div>
                this.setState({result: `ORDR:${resultList.join(':')}`, resultJsx: resultJsx, showResult: false, updating: true, updatedDefaultName: 'Random Order'});
                break;
            case 'GRUP':
                groupSize = parseInt(this.state.formGroupSize);
                let groupIndex = 1;
                if (isNaN(groupSize)) {
                    groupSize = 2;
                    this.setState({formGroupSize: groupSize});
                } 
                numSelections = usersList.length;
                for (let i=0; i<numSelections; i++) {
                    max = usersList.length;
                    index = getRandomInteger(min, max);
                    result = usersList[index];
                    resultList.push(result);
                    usersList.splice(index, 1);
                }
                let groupCount = 0;
                for (const userId of resultList) {
                    if (groupCount === 0) {
                        resultJsxItems.push(<div key={`group-${groupIndex}`} className='mt-1'><b>Group {groupIndex}</b></div>);
                    }
                    const userListItem = <UserListItem key={userId} userId={userId} slim={true} localUser={this.props.localUser}/>
                    resultJsxItems.push(userListItem);
                    groupCount++;
                    if (groupCount >= groupSize) {
                        groupCount = 0;
                        groupIndex++;
                    }
                }
                resultJsx = 
                    <div>
                        {resultJsxItems}
                    </div>
                this.setState({result: `GRUP:${groupSize}:${resultList.join(':')}`, resultJsx: resultJsx, showResult: false, updating: true, updatedDefaultName: 'Random Groups'});
                break;
            case 'VALX':
                min = parseInt(this.state.formMinValue);
                if (isNaN(min)) {
                    min = 0;
                    this.setState({formMinValue: min});
                }
                max = parseInt(this.state.formMaxValue);
                if (isNaN(max)) {
                    max = 10;
                    this.setState({formMaxValue: max});
                }
                // if the user swaps min/max, just fix it
                if (max < min) {
                    let temp = min;
                    min = max;
                    max = temp;
                    this.setState({formMinValue: min, formMaxValue: max});
                }
                let value = getRandomInteger(min, max+1);
                resultJsx = <div className='text-center'><p style={{fontSize: 30}}><b>{value}</b></p></div>
                this.setState({result: `VALX:${min}:${max}:${value}`, resultJsx: resultJsx, showResult: false, updating: true, updatedDefaultName: 'Random Number'});
                break;
            default:
                console.error('reached invalid state.method in NerdHerderRandomSelectorResultModalInner');
        }
        setTimeout(()=>this.setState({showResult: true, updating: false}), 1000);
    }

    onAccept() {
        undoOnBackCancelModal();
        const patchData = {result: this.state.result};
        // if the random selector has the default name - save a better default name
        if (this.state.randomSelector.name === 'New Random Selection' && this.state.updatedDefaultName) {
            patchData['name'] = this.state.updatedDefaultName;
        }
        this.restPubSub.patch('random-selector', this.props.randomSelectorId, patchData);
        this.props.onAccept(this.state.result);
    }

    render() {
        if (this.state.randomSelector === null) return(null);

        const includedColumn = {};
        let numChecked = 0;
        if (this.state.showSubsetSelector) {
            for (const userId of this.allPlayersIdList) {
                if (this.state.includedPlayerDict[userId]) numChecked++;
                includedColumn[userId] = <Form.Check type='checkbox' onChange={(e)=>this.handleIncludePlayer(e, userId)} checked={this.state.includedPlayerDict[userId]}/>
            }
        }

        let disablePickButton = false;
        if (this.state.showSubsetSelector && numChecked === 0) disablePickButton = true;
        else if (this.state.method === 'VALX' && (this.state.formMinValue === '' || this.state.formMaxValue === '')) disablePickButton = true;
        else if (this.state.method === 'GRUP' && this.state.formGroupSize === '') disablePickButton = true;
        else if (this.state.method === 'MUID' && this.state.formNumSelections === '') disablePickButton = true;

        let buttonLabel = 'Pick One!';
        if (this.state.method === 'MUID') buttonLabel = 'Pick Them!';
        else if (this.state.method === 'GRUP') buttonLabel = 'Assign Groups!';
        else if (this.state.method === 'ORDR') buttonLabel = "Mix'em Up!"
        else if (this.state.method === 'VALX') buttonLabel = 'Pick It!'
        if (this.state.result !== null) buttonLabel = 'Re-Roll!'

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Randomizer!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='form-outline mb-3'>
                                <Form.Text>What kind of randomness do you need?</Form.Text>
                                <Form.Select value={this.state.methodOption} onChange={(e)=>this.onSelectMethod(e)} disabled={this.state.updating}>
                                    <option value='SUID'>Pick a player from all players</option>
                                    <option value='SUID-S'>Pick a player from a subset of players</option>
                                    <option value='MUID'>Pick multiple players from all players</option>
                                    <option value='MUID-S'>Pick multiple players from a subset of players</option>
                                    <option value='ORDR'>Make a randomly ordered list of all players</option>
                                    <option value='GRUP'>Assign all players to random groups</option>
                                    <option value='VALX'>Select a random number</option>
                                </Form.Select>
                            </Form.Group>
                            <Collapse in={this.state.showSubsetSelector && !this.state.result}>
                                <div>
                                    <Form.Text>Select the subset of players to pick from...</Form.Text>
                                    <TableOfUsers userIds={this.allPlayersIdList} headers={['Player','Included?']} rightColumnContent={includedColumn} localUser={this.props.localUser}/>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.method === 'MUID'}>
                                <div>
                                    <Form.Group className='form-outline mb-2'>
                                        <Form.Text muted>How many players to select?</Form.Text>
                                        <Form.Control type="number" onChange={(event)=>this.handleFormNumSelections(event)} value={this.state.formNumSelections} disabled={this.state.updating || this.state.disableInput} min={1}/>
                                    </Form.Group>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.method === 'GRUP'}>
                                <div>
                                    <Form.Group className='form-outline mb-2'>
                                        <Form.Text muted>How many players in each group?</Form.Text>
                                        <Form.Control type="number" onChange={(event)=>this.handleFormGroupSize(event)} value={this.state.formGroupSize} disabled={this.state.updating || this.state.disableInput} min={2}/>
                                    </Form.Group>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.method === 'VALX'}>
                                <div>
                                    <Form.Group className='form-outline mb-2'>
                                        <Form.Text muted>Minimum value?</Form.Text>
                                        <Form.Control type="number" onChange={(event)=>this.handleFormMinValue(event)} value={this.state.formMinValue} disabled={this.state.updating || this.state.disableInput}/>
                                        <Form.Text muted>Maximum value?</Form.Text>
                                        <Form.Control type="number" onChange={(event)=>this.handleFormMaxValue(event)} value={this.state.formMaxValue} disabled={this.state.updating || this.state.disableInput}/>
                                    </Form.Group>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.resultJsx !== null}>
                                <div className='my-3'>
                                    {!this.state.showResult &&
                                    <div className='text-center'>  
                                        <Spinner variant='primary' animation="border" role="status" style={{height: 50, width: 50}}/>
                                    </div>}
                                    {this.state.showResult &&
                                    <div>  
                                        {this.state.resultJsx}
                                    </div>}
                                </div>
                            </Collapse>
                            {!this.state.disableInput &&
                            <Form.Group className='form-outline'>
                                <div className='d-grid gap-2'>
                                    <Button variant="primary" disabled={disablePickButton || this.state.updating} onClick={()=>this.onMakeRandomSelection()}>{buttonLabel}</Button>
                                </div>
                            </Form.Group>}
                        </Form>  
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" disabled={this.state.updating || this.state.result === null} onClick={()=>this.onAccept()}>Accept</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderViewRandomSelectorViewResultModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderViewRandomSelectorViewResultModal'>
                <NerdHerderRandomSelectorViewResultModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderRandomSelectorViewResultModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.result === 'undefined') console.error('missing props.result');
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
    }

    render() {
        let resultJsx = generateRandomSelectorJsx(this.props.result, this.props.localUser)
        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} centered scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title || 'Randomizer Result'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {resultJsx}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export class NerdHerderEditCalendarDateModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderEditCalendarDateModal'>
                <NerdHerderEditCalendarDateModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderEditCalendarDateModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.league === 'undefined') console.error('missing props.league');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        let calendarDateId = null;
        if (this.props.calendarDateId) {
            calendarDateId = this.props.calendarDateId;
        }

        this.state = {
            updating: false,
            errorFeedback: null,
            onUpdateCancel: false,
            calendarDateId: calendarDateId,
            formName: '',
            formDescription: '',
            formDate: DateTime.now().toISODate(),
            formRepeatWeekly: false,
            formRepeatSchedule: '',
            formRepeatEndDate: '',
            formRepeatExceptions: [],
            formErrors: {},
        }
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        if (this.state.calendarDateId) {
            let sub = this.restPubSub.subscribe('calendar-date', this.state.calendarDateId, (d, k)=>{this.updateCalendarDate(d, k)}, (e, k)=>this.formUpdateError(e, k));
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateCalendarDate(dateData, key) {
        const newCalendarDate = NerdHerderDataModelFactory('calendar_date', dateData);
        this.setState({
            formName: newCalendarDate.name,
            formDescription: newCalendarDate.description || '',
            formDate: newCalendarDate.date,
            formRepeatWeekly: newCalendarDate.repeat_weekly,
            formRepeatSchedule: newCalendarDate.repeat_schedule || '',
            formRepeatEndDate: newCalendarDate.repeat_end_date || '',
            formErrors: {},
        });
        if (this.state.onUpdateCancel) this.props.onCancel();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
        this.setState({onUpdateCancel: false});
    }

    onAccept() {
        undoOnBackCancelModal();
        let formDescription = this.state.formDescription.trimEnd();
        if (formDescription.length === 0) formDescription = null;
        const patchData = {
            name: this.state.formName.trimEnd(),
            description: formDescription,
            date: this.state.formDate,
            repeat_weekly: this.state.formRepeatWeekly,
            repeat_schedule: this.state.formRepeatSchedule==='' ? null : this.state.formRepeatSchedule,
            repeat_end_date: this.state.formRepeatEndDate==='' ? null : this.state.formRepeatEndDate,
        }
        this.restPubSub.patch('calendar-date', this.state.calendarDateId, patchData);
        this.setState({onUpdateCancel: true});
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 2) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formName: value, formErrors: errorState});
    }

    handleDescriptionChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('description', {...this.state.formErrors});
        this.setState({formDescription: value, formErrors: errorState});
    }

    handleDateChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('date', {...this.state.formErrors});
        this.setState({formDate: value, formErrors: errorState});
    }

    handleEndDateChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('repeat_end_date', {...this.state.formErrors});
        this.setState({formRepeatEndDate: value, formErrors: errorState});
    }

    handleRepeatWeeklyChange(event) {
        let checked = event.target.checked;
        let newRepeatSchedule = '';
        // turn on this day by default
        if (checked && this.state.formDate !== '') {
            let luxonDate = DateTime.fromISO(this.state.formDate);
            let dayOfWeek = luxonDate.toFormat('E');
            switch(dayOfWeek) {
                case '1':
                    newRepeatSchedule = 'mo';
                    break;
                case '2':
                    newRepeatSchedule = 'tu';
                    break;
                case '3':
                    newRepeatSchedule = 'we';
                    break;
                case '4':
                    newRepeatSchedule = 'th';
                    break;
                case '5':
                    newRepeatSchedule = 'fr';
                    break;
                case '6':
                    newRepeatSchedule = 'sa';
                    break;
                case '7':
                    newRepeatSchedule = 'su';
                    break;
                default:
                    console.error('got unexpected day of the week from Luxon');
            }
        }
        let errorState = clearErrorState('name', {...this.state.formErrors});
        this.setState({formRepeatWeekly: checked, formRepeatSchedule: newRepeatSchedule, formErrors: errorState});
    }

    handleRepeatWeekdayChange(event, weekday) {
        let index = 0;
        const scheduleArray = this.state.formRepeatSchedule.split(',');
        if (scheduleArray.includes(weekday)) {
            index = scheduleArray.indexOf(weekday);
            scheduleArray.splice(index, 1);
        } else {
            scheduleArray.push(weekday);
        }
        // do a little cleanup on the array
        index = scheduleArray.indexOf('');
        if (index > -1) scheduleArray.splice(index, 1);
        this.setState({formRepeatSchedule: scheduleArray.join(',')});
    }

    render() {
        if (this.state.calendarDate === null) return(<NerdHerderLoadingModal/>);

        let disableAcceptButton = false;
        let hasFormErrors = Object.keys(this.state.formErrors).length !== 0;
        if (hasFormErrors) disableAcceptButton = true;

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        {this.state.calendarDateId &&
                        <Modal.Title>Edit Calendary Entry</Modal.Title>}
                        {!this.state.calendarDateId &&
                        <Modal.Title>Add Calendary Entry</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorFeedback &&
                        <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Date<Required/></Form.Label>
                                <Form.Control id='date' name='date' type="date" disabled={this.state.updating} onChange={(e)=>this.handleDateChange(e)} autoComplete='off' value={this.state.formDate} required/>
                                <FormErrorText errorId='date' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Name<Required/></Form.Label>
                                <Form.Control id='name' name='name' type="text" placeholder="League night, holiday, event..." disabled={this.state.updating} onChange={(e)=>this.handleNameChange(e)} autoComplete='off' value={this.state.formName} minLength={2} maxLength={20} required/>
                                <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Description</Form.Label>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='description' name='description' as="textarea" rows={3} placeholder='Optional: Add a description for this special day...' disabled={this.state.updating} onChange={(e)=>this.handleDescriptionChange(e)} value={this.state.formDescription} maxLength={200}/>
                                    <FormTextInputLimit current={this.state.formDescription.length} max={200}/>
                                </div>
                                <FormErrorText errorId='description' errorState={this.state.formErrors}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Check id='repeat_weekly' name='repeat_weekly' label='Repeats weekly' checked={this.state.formRepeatWeekly} disabled={this.state.isUpdating} onChange={(e)=>this.handleRepeatWeeklyChange(e)}/>
                            </Form.Group>
                            <Collapse in={this.state.formRepeatWeekly}>
                                <div>
                                    <Row className='justify-content-center'>
                                        <Col>
                                            <small className='text-muted'>Repeats on which days?</small>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col xs='auto'>
                                            <span className='cursor-pointer' onClick={(e)=>this.handleRepeatWeekdayChange(e, 'mo')}><Badge pill bg={this.state.formRepeatSchedule.includes('mo')?'primary':'secondary'}>Mon</Badge></span>
                                            {' '}
                                            <span className='cursor-pointer' onClick={(e)=>this.handleRepeatWeekdayChange(e, 'tu')}><Badge pill bg={this.state.formRepeatSchedule.includes('tu')?'primary':'secondary'}>Tue</Badge></span>
                                            {' '}
                                            <span className='cursor-pointer' onClick={(e)=>this.handleRepeatWeekdayChange(e, 'we')}><Badge pill bg={this.state.formRepeatSchedule.includes('we')?'primary':'secondary'}>Wed</Badge></span>
                                            {' '}
                                            <span className='cursor-pointer' onClick={(e)=>this.handleRepeatWeekdayChange(e, 'th')}><Badge pill bg={this.state.formRepeatSchedule.includes('th')?'primary':'secondary'}>Thu</Badge></span>
                                            {' '}
                                            <span className='cursor-pointer' onClick={(e)=>this.handleRepeatWeekdayChange(e, 'fr')}><Badge pill bg={this.state.formRepeatSchedule.includes('fr')?'primary':'secondary'}>Fri</Badge></span>
                                            {' '}
                                            <span className='cursor-pointer' onClick={(e)=>this.handleRepeatWeekdayChange(e, 'sa')}><Badge pill bg={this.state.formRepeatSchedule.includes('sa')?'primary':'secondary'}>Sat</Badge></span>
                                            {' '}
                                            <span className='cursor-pointer' onClick={(e)=>this.handleRepeatWeekdayChange(e, 'su')}><Badge pill bg={this.state.formRepeatSchedule.includes('su')?'primary':'secondary'}>Sun</Badge></span>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </Form>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>this.onAccept()} disabled={this.state.updating || disableAcceptButton}>{this.state.calendarDateId?'Update':'Add'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export class NerdHerderCompletedLeaguesModal extends React.Component {
    render() {
        return (
            <ModalErrorBoundary modalTypeName='NerdHerderCompletedLeaguesModal'>
                <NerdHerderCompletedLeaguesModalInner {...this.props}/>
            </ModalErrorBoundary>
        )
    }
}

class NerdHerderCompletedLeaguesModalInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.onCancel === 'undefined') console.error('missing props.onCancel');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            navigateTo: null,
            topicList: [],
            filterTopic: 'any',
            filterDate: '',
            results: [],
        }

        this.triggerSearchTimeout = null;
    }

    componentDidMount() {
        onBackCancelModal(this.props.onCancel);
        let sub = this.restPubSub.subscribe('topic', null, (d, k)=>{this.updateTopicsList(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTopicsList(topicsData, key) {
        this.setState({topicList: topicsData});
    }

    updateResults(resultsData, key) {
        this.setState({updating: false, results: resultsData});
    }

    handleTopicChange(event) {
        const newTopic = event.target.value;
        this.setState({filterTopic: newTopic});
        if (this.triggerSearchTimeout) clearTimeout(this.triggerSearchTimeout);
        this.triggerSearchTimeout = setTimeout(()=>this.triggerNewSearch(), 300);
    }

    handleDateChange(event) {
        const newDate = event.target.value;
        this.setState({filterDate: newDate});
        if (this.triggerSearchTimeout) clearTimeout(this.triggerSearchTimeout);
        this.triggerSearchTimeout = setTimeout(()=>this.triggerNewSearch(), 2000);
    }

    triggerNewSearch() {
        console.log('trigger search');
        let doSearch = false;
        let filterParams = {
            completed: true,
        };
        if (this.state.filterTopic !== 'any') {
            filterParams['topic'] = this.state.filterTopic;
            doSearch = true;
        }
        if (this.state.filterDate){
            filterParams['date'] = this.state.filterDate;
            doSearch = true;
        }
        if (doSearch) {
            this.setState({updating: true})
            this.restApi.genericGetEndpointData('league', null, filterParams)
            .then((response)=>{
                this.updateResults(response.data);
            })
            .catch((error)=>{
                console.error(error);
            });
        }
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo}/>);
        const anyTopicItem = <option key={'any'} value={'any'}>Any</option>
        const topicOptions = [];
        topicOptions.push(anyTopicItem);
        for (const topic of this.state.topicList) {
            const topicItem = <option key={topic.id} value={topic.id}>{topic.name} ({topic.id})</option>
            topicOptions.push(topicItem);
        }

        const leaguesListItems = [];
        for (const league of this.state.results) {
            const listItem = <AvailableLeagueListItem key={league.id} league={league} localUser={this.props.localUser}/>
            leaguesListItems.push(listItem); 
        }

        let noResultsMessageJsx = null;
        if (leaguesListItems.length === 0) {
            if (this.state.updating) {
                noResultsMessageJsx = <p><b>Searching...</b></p>
            } else if (this.state.filterDate === '' && this.state.filterTopic === 'any') {
                noResultsMessageJsx = <p><b>Set a topic or date to search for completed events or leagues.</b></p>
            } else {
                noResultsMessageJsx = <p><b>There are no available events or leagues that match your search criteria.</b></p>
            }
        }

        return (
            <div onClick={(e)=>e.stopPropagation()}>
                <Modal show={this.props.show || true} scrollable={true} onHide={()=>{undoOnBackCancelModal(); this.props.onCancel()}} onExit={()=>undoOnBackCancelModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Search Completed Events & Leagues</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Topic</Form.Label>
                                <Form.Select onChange={(event)=>this.handleTopicChange(event)} value={this.state.topic} disabled={this.state.updating}>
                                    {topicOptions}
                                </Form.Select>
                                <Form.Text muted>Optional: Filter results by topic.</Form.Text>
                            </Form.Group>
                            <Form.Group className="form-outline mb-3">
                                <Form.Label>Date</Form.Label>
                                <Form.Control id="filter_date" name="filter_date" type="date" disabled={this.state.updating} onChange={(event)=>this.handleDateChange(event)} autoComplete='off' value={this.state.filterDate}/>
                                <Form.Text muted>Optional: Only events or leagues that were running on this date will be included.</Form.Text>
                            </Form.Group>
                        </Form>
                        {leaguesListItems}
                        {noResultsMessageJsx}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>{undoOnBackCancelModal(); this.props.onCancel()}}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
