import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import withRouter from './withRouter';
import { DateTime, SystemZone, IANAZone } from 'luxon';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderLoadingModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderBadge } from './nerdherder-components/NerdHerderBadge';
import { LeagueListItem, UserListItem, FileListItem, EventListItem, GameListItem } from './nerdherder-components/NerdHerderListItems';
import { NerdHerderFontIcon, NerdHerderClickableFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { Truncate } from './nerdherder-components/NerdHerderTruncate';
import { FormCurrencyInput } from './nerdherder-components/NerdHerderFormHelpers';
import { getLocal, getLocalParseInt, getLocalParseBool, getLocalParseObject, setLocal } from './utilities';


class TestPage extends React.Component {
    constructor(props) {
        super(props);

        this.currencyRef = React.createRef();
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            feeCost: 0,
            localUser: null,
            otherUser: null,
            league: null,
            firebaseInfo: 'no data yet'
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('user', 2, (d, k) => {this.updateOtherUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('league', 1, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('timezone-list', null, (d, k) => {this.updateTimezones(d, k)});
        this.restPubSubPool.add(sub);
        this.restApi.firebaseSignin()
        .then(()=> {
            console.log('signed into firebase');
            this.doFirebaseTest2();
        })
        .catch(error => {
            console.error('failed to sign into firebase');
            console.log(error);
        });

        //setTimeout(()=>this.testCurrencyInput(1), 10000);
        //setTimeout(()=>this.doTimezoneTest(), 5000);
        //setTimeout(()=>this.doFirebaseTest(), 3000);
        //setTimeout(()=>this.doLocalStorageTest(), 3000);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    doTimezoneTest() {
        let nyDateTime = DateTime.fromISO('2023-05-10T10:54:00', {zone: 'America/New_York'});
        console.log('NY time', nyDateTime.toLocaleString(DateTime.DATETIME_FULL));
        let localDateTime = nyDateTime.setZone(new SystemZone());
        console.log('local time', localDateTime.toLocaleString(DateTime.DATETIME_FULL));
    }

    doFirebaseTest() {
        this.restApi.genericGetFirestoreData('leagues', 1)
        .then((response) => {
            console.log(response);
            this.setState({firebaseInfo: response.name});
        })
        .catch((error) => {
            this.setState({firebaseInfo: 'there was a firestore error'});
            console.error(error);
        });
    }

    doFirebaseTest2() {
        this.restPubSub.subscribeToFirestore('leagues', 1, (d,k)=>this.leagueUpdateFirestore(d,k), (e,k)=>this.leagueErrorFirestore(e,k), 1);
    }

    doLocalStorageTest() {
        let val = getLocal('testkey');
        console.log('expect null:', val);
        val = getLocal('testkey', 5);
        console.log('expect 5:', val);
        val = getLocalParseInt('testkey', 1);
        console.log('expect 1:', val);
        val = getLocalParseBool('testkey');
        console.log('expect false:', val);

        setLocal('testkey', 5);
        val = getLocal('testkey', 0);
        console.log('expect 5:', val);
        val = getLocalParseInt('testkey', 0);
        console.log('expect 5:', val);
        val = getLocalParseBool('testkey');
        console.log('expect false:', val);
        setLocal('testkey', 'test string');
        val = getLocal('testkey');
        console.log('expect test string:', val);
        setLocal('testkey', true);
        val = getLocal('testkey');
        console.log('expect true:', val);
        val = getLocalParseBool('testkey');
        console.log('expect true:', val);
        setLocal('testkey', {val1: "stringval", val2: 503, val3: false});
        val = getLocalParseObject('testkey');
        console.log('type:', typeof val, 'value:', val);
    }

    updateLocalUser(userData, key) {
        let newData = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: newData});
    }

    updateOtherUser(userData, key) {
        let newData = NerdHerderDataModelFactory('user', userData);
        this.setState({otherUser: newData});   
    }

    updateLeague(leagueData, key) {
        let newData = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newData});
    }

    leagueUpdateFirestore(leagueData, key) {
        //let newData = NerdHerderDataModelFactory('league', leagueData);
        //this.setState({league: newData});
        this.setState({firebaseInfo: leagueData.name});
    }

    leagueErrorFirestore(leagueData, key) {
        console.error('got error callback');
    }

    updateTimezones(timezoneList, key) {
        const triedItList = [];
        for (const timezoneData of timezoneList) {
            const timezoneName = timezoneData.timezone_name;
            if (triedItList.includes(timezoneName)) continue;
            triedItList.push(timezoneName);
            let luxonTimezone = new IANAZone(timezoneName);
            if (luxonTimezone.isValid) {
                //console.log(timezoneName, 'is valid');
            } else {
                console.warn(timezoneName, 'is invalid');
            }
        }
    }

    handleFeeCostChange(value, name) {
        this.setState({feeCost: value});
    }

    onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    testCurrencyInput(stage) {
        let setNewTimeout = true;
        let currentValue = -1
        let currencyVariable = null;
        console.log(`Testing currency input -- Stage ${stage}`);
        switch(stage) {
            case 1:
                console.log('disable=true');
                this.setState({updating: true});
                break;
            case 2:
                console.log('disable=false');
                this.setState({updating: false});
                break;
            case 3:
                console.log('get value');
                currentValue = this.currencyRef.current.getValue();
                console.log('value = ', currentValue);
                break;
            case 4:
                console.log('set value');
                this.currencyRef.current.setValue("1900");
                break;
            case 5:
                console.log('get value');
                currentValue = this.currencyRef.current.getValue();
                console.log('value = ', currentValue);
                break;
            case 6:
                console.log('get currency');
                currencyVariable = this.currencyRef.current.getCurrency();
                console.log('currency:', currencyVariable);
                console.log('currency intValue', currencyVariable.intValue);
                console.log('currency value', currencyVariable.value);
                break;
            default:
                console.log('All done testing currency input');
                setNewTimeout = false;
        }
        if (setNewTimeout) setTimeout(()=>this.testCurrencyInput(stage+1), 2000);
    }

    testOnClick() {
        console.log("tested sat!");
    }

    causeProblems() {
        const userIds = [1, 16, 17, 20];
        //const userIds = [1];
        for (const userId of userIds) {
            let queryParams = {'user-id': userId, 'game-id': 222};
            let winner = false;
            let score = 0;
            if (userId === 1) {
                winner = true;
                score = 13;
            }

            this.restApi.genericPatchEndpointData('user-gamessss', null, {winner: winner, score: score}, queryParams)
            .then((response)=>{
            })
            .catch((error)=>{
                console.log(error);
            });
        }
    }

    render() {
        if (!this.state.localUser) return (<NerdHerderLoadingModal />);
        if (!this.state.otherUser) return (<NerdHerderLoadingModal />);
        if (!this.state.league) return (<NerdHerderLoadingModal />);

        const organizerBadge = <NerdHerderBadge value="Player" color="green" shape="pill" position="bottom" size="small"/>
        const playerBadge = <NerdHerderBadge value="Player" color="green" shape="pill" position="bottom" size="medium"/>
        return (
            <NerdHerderStandardPageTemplate pageName='test' localUser={this.state.localUser}>
                <NerdHerderStandardCardTemplate title='Currency Input Tester'>
                    <Form id='stripe-registration-fees' name='stripe-registration-fees' onSubmit={(e)=>this.onSubmit(e)}>
                        <Form.Group className="form-outline mb-2">
                            <FormCurrencyInput
                                ref={this.currencyRef}
                                id='fee_cost'
                                name='fee_cost'
                                disabled={this.state.updating}
                                onChange={(e)=>this.handleFeeCostChange(e)}
                                minorDigits={2}
                                symbol='$'
                                decimalSeparator='.'/>
                        </Form.Group>
                    </Form>
                </NerdHerderStandardCardTemplate>
                <NerdHerderStandardCardTemplate title='Some Leagues'>
                    <LeagueListItem leagueId={1} localUser={this.state.localUser}/>
                    <p>{this.state.firebaseInfo}</p>
                    <Button variant='primary' onClick={()=>this.loginToFirebase()}>Login To Firebase</Button>
                </NerdHerderStandardCardTemplate>
                <NerdHerderStandardCardTemplate title='Some Games'>
                    <GameListItem gameId={14} selectLocalUser={true} localUser={this.state.localUser}/>
                    <GameListItem gameId={22} selectLocalUser={true} localUser={this.state.localUser}/>
                    <GameListItem gameId={45} selectLocalUser={true} localUser={this.state.localUser}/>
                    <GameListItem gameId={149} selectLocalUser={true} localUser={this.state.localUser} onClick={null}/>
                    <Button variant='danger' onClick={()=>this.causeProblems()}>Cause Problems</Button>
                </NerdHerderStandardCardTemplate>
                <NerdHerderStandardCardTemplate
                    user={this.state.localUser}
                    localUser={this.state.localUser}
                    dateStamp={DateTime.now()}
                    userTitle={true}
                    userIcon={true}
                    icon={true}
                    iconBadge={organizerBadge}
                    rightColumn={<span>right<br/>col</span>}
                    additionalContent={<EventListItem eventId={1} league={this.state.league} showSummary={true} localUser={this.state.localUser}/>}>
                    <p>This is card child stuff!</p>
                    <div>Wow this is on fire!<NerdHerderClickableFontIcon variant="danger" icon="flaticon-burn" fontSize="20px" float="right" href="https://www.google.com"/></div>
                    <div>Also inflamed!<NerdHerderClickableFontIcon tooltipText="FIRE!!!" variant="danger" icon="flaticon-burn" fontSize="20px" float="right" onClick={()=>this.testOnClick()}/></div>
                </NerdHerderStandardCardTemplate>
                <NerdHerderStandardCardTemplate
                    user={this.state.otherUser}
                    league={this.state.league}
                    localUser={this.state.localUser}
                    dateStamp={DateTime.now()}
                    userTitle={true}
                    leagueSubtitle={true}
                    userIcon={true}
                    icon={true}
                    iconBadge={playerBadge}
                    userFeedback={'Yipee! See this feedback!'}
                    additionalContent={<div><p><b>This is additional content!</b></p><FileListItem fileId={1}/><FileListItem fileId={17}/></div>}>
                    <p>This is card child stuff!</p>
                </NerdHerderStandardCardTemplate>
                <NerdHerderStandardCardTemplate
                    league={this.state.league}
                    localUser={this.state.localUser}
                    timeStamp={DateTime.now()}
                    leagueTitle={true}
                    leagueIcon={true}
                    icon={true}
                    errorMessage='Holy cow an error!'>
                    <Truncate>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus
                        nec feugiat in fermentum posuere. Vivamus at augue eget arcu dictum varius duis at. Habitant morbi tristique senectus et netus et 
                        malesuada. Dignissim sodales ut eu sem integer vitae justo eget magna. Tellus in hac habitasse platea dictumst. Quisque sagittis purus sit amet 
                        volutpat consequat mauris nunc. Aliquet risus feugiat in ante metus dictum. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum. Scelerisque 
                        fermentum dui faucibus in ornare quam viverra. Ipsum dolor sit amet consectetur adipiscing. Eget mi proin sed libero enim sed. Ornare suspendisse sed nisi 
                        lacus sed.

                        Nunc congue nisi vitae suscipit tellus mauris. Nulla facilisi etiam dignissim diam quis enim lobortis. Enim ut tellus elementum sagittis. 
                        Pellentesque habitant morbi tristique senectus et. Mattis aliquam faucibus purus in massa tempor nec feugiat nisl. Purus sit amet volutpat 
                        consequat mauris nunc congue. Pellentesque nec nam aliquam sem et tortor consequat id porta. Vitae turpis massa sed elementum tempus egestas sed 
                        sed. Sit amet commodo nulla facilisi nullam vehicula. Donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum arcu. Id velit ut 
                        tortor pretium viverra. Amet volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Vitae purus faucibus ornare suspendisse. Eget 
                        mauris pharetra et ultrices neque ornare. Mi ipsum faucibus vitae aliquet nec ullamcorper sit. Vitae purus faucibus ornare suspendisse sed nisi 
                        lacus. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus.
                    </Truncate>
                </NerdHerderStandardCardTemplate>
                <NerdHerderStandardCardTemplate title="Example Generic Card">
                    <UserListItem userId={15} slim={true} localUser={this.state.localUser}/>
                    <UserListItem userId={2} slim={true} localUser={this.state.localUser}/>
                    <Truncate>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Truncate>
                </NerdHerderStandardCardTemplate>
            </NerdHerderStandardPageTemplate>
        );
    }
}

export default withRouter(TestPage);
