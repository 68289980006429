import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';

export class NerdHerderUpdateButton extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.updating === 'undefined') console.error('missing props.updating');
        this.state = {
            isUpdating: false,
        }
        this.buttonRef = React.createRef();
        this.buttonHeight = null;
        this.buttonWidth = null;
    }

    render() {
        let buttonProps = {...this.props};
        let buttonChildren = this.props.children;
        delete buttonProps.children;

        if (buttonProps.hasOwnProperty('updating')) {
            // if updating doesn't match isUpdating, set a timer to change it
            if (this.props.updating === true && this.state.isUpdating === false) {
                setTimeout(()=>this.setState({isUpdating: true}), 10);
                // snapshot the existing height and width
                if (this.buttonRef.current) {
                    const rect = this.buttonRef.current.getBoundingClientRect();
                    this.buttonHeight = rect.height;
                    this.buttonWidth = rect.width;
                    console.debug(`capture button height & width (${this.buttonHeight} x ${this.buttonWidth})`);
                }
            } else if (this.props.updating === false && this.state.isUpdating === true) {
                setTimeout(()=>this.setState({isUpdating: false}), 10);
            }
            delete buttonProps.updating;
        }

        // disable the button if we're updating & set the height/width if possible
        if (this.state.isUpdating) {
            buttonProps.disabled = true;
            buttonChildren = null;
            if (this.buttonHeight !== null && this.buttonWidth !== null) {
                console.debug(`force button height & width (${this.buttonHeight} x ${this.buttonWidth})`);
                buttonProps.style = {height: this.buttonHeight, width: this.buttonWidth};
            }
        }

        return (
            <Button ref={this.buttonRef} {...buttonProps}>
                {this.props.icon && !this.state.isUpdating &&
                <NerdHerderFontIcon icon={this.props.icon}/>}
                {this.state.isUpdating &&
                <Spinner animation="border" variant="light" size="sm"/>}
                {buttonChildren}
            </Button>
        );
    }
}
