import React from 'react';
import withRouter from './withRouter';
import { Navigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderChooseLocationModal, NerdHerderCompletedLeaguesModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { hashCode, handleGlobalRestError, delCookieAfterDelay, getCookie, getLocalDateOnlyObject } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderContactRequestCard } from './nerdherder-components/NerdHerderContactCards';
import { NerdHerderLeagueInvitationCard } from './nerdherder-components/NerdHerderLeagueInvitationCards';
import { NerdHerderScheduledGameCard } from './nerdherder-components/NerdHerderScheduledGameCards';
import { NerdHerderMessageCard } from './nerdherder-components/NerdHerderMessageCards';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { LeagueListItem, AvailableLeagueListItem } from './nerdherder-components/NerdHerderListItems';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderTour } from './nerdherder-components/NerdHerderTour';

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        // priority notifications go first
        this.priorityNotificationsToUpdate = ['contact request', 'invite', 'game_schedule'];
        this.regularNotificationsToUpdate = ['message'];
        this.notificationsToUpdate = this.priorityNotificationsToUpdate.concat(this.regularNotificationsToUpdate);

        this.tourSteps = [
            {
                target: '#tour-info',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Important!',
                content:
                    <div>
                        <p>
                            There is no back button in NerdHerder
                        </p>
                        <p>
                            Instead use the browser's <i>back button</i> or <i>gesture</i>
                        </p>
                    </div>
            },
            {
                target: '#menu-button',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Main Menu',
                content: <span>You'll use this a lot - it opens the left menu to navigate around NerdHerder</span>
            },
            {
                target: '#header-home-nav',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Home Shortcut',
                content: <span>Shortcut to the home page - this is where you are now. If you get lost, click here!</span>
            },
            {
                target: '#header-messages-nav',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Messages Shortcut',
                content: <div>
                            <p>
                                You can send <b>Direct Messages</b> to other users on NerdHerder - basically a private chat
                            </p>
                            <p>
                                DM's are also emailed immediately, and are a good way to arrange games
                            </p>
                        </div>
            },
            {
                target: '#header-games-nav',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Games Shortcut',
                content: <span>Quickly add or manage games with this shortcut!</span>
            },
            {
                target: '#header-leagues-nav',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Leagues/Event Shortcut ',
                content: <div>
                            <p>
                                Jump to any league, tournament, or event you are playing in or managing
                            </p>
                            <p>
                                <i>Disabled if you aren't in any leagues, tournaments, or events</i>
                            </p>
                        </div>
            },
            {
                target: '#user-nag-card',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Suggestions',
                content: <span>We'll sometimes provide hints to help you get a better experience from NerdHerder</span>
            },
            {
                target: '#available-leagues-card',
                disableBeacon: true,
                placement: 'top',
                title: 'Available Events',
                content: <span>Find new leagues, events, or tournaments that interest you here</span>
            }
        ];

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            // this an array, and is the result of the list from /notification (not full notifications - just summaries)
            notificationList: [],
            // these are actual notification models
            notifications: {},
            errorFeedback: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k)=>{this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('notification', null, (d, k)=>{this.updateNotificationList(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true})
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API or Firebase access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateNotificationList(notificationList, key) {
        const newNotificationList = [];
        for (const notificationSummary of notificationList) {
            // don't add every single notification, only the types we care about updating
            if (this.notificationsToUpdate.includes(notificationSummary.type)) {
                newNotificationList.push(notificationSummary.id);
                // sub if we don't already have it, use a null as a placeholder until the notification arrives
                if (!this.state.notifications.hasOwnProperty(notificationSummary.id)) {
                    const sub = this.restPubSub.subscribe('notification', notificationSummary.id, (d, k)=>{this.updateNotification(d, k)}, null, notificationSummary.id);
                    this.restPubSubPool.add(sub);
                    this.setState((state) => {
                        return {notifications: {...state.notifications, [notificationSummary.id]: null}}
                    });
                }
            }
        }
        this.setState({notificationList: newNotificationList});
    }

    updateNotification(notificationData, notificationId) {
        const newNotification = NerdHerderDataModelFactory('notification', notificationData);
        this.setState((state) => {
            return {notifications: {...state.notifications, [notificationId]: newNotification}}
        });
    }

    generateCardFromNotification(notification, localUser) {
        switch(notification.type) {
            case 'contact request':
                return(<NerdHerderContactRequestCard key={notification.id} userId={notification.source_user_id} localUser={localUser}/>)
            case 'message':
                return(<NerdHerderMessageCard key={notification.id} messageId={notification.message_id} localUser={localUser}/>)
            case 'invite':
                return(<NerdHerderLeagueInvitationCard key={notification.id} leagueId={notification.league_id} fromUserId={notification.source_user_id} localUser={localUser}/>)
            case 'game_schedule':
                return(<NerdHerderScheduledGameCard key={notification.id} leagueId={notification.league_id} gameId={notification.game_id} fromUserId={notification.source_user_id} localUser={localUser}/>)
            default:
                return (
                    <NerdHerderStandardCardTemplate key={notification.id} title={`Note-${notification.id} ${notification.type}`}>
                        <p>{`${notification.title}`}</p>
                    </NerdHerderStandardCardTemplate>
                );
        }
    }

    render() {
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);

        const priorityCards = [];
        const regularCards = [];

        for (const notificationId of this.state.notificationList) {
            const notification = this.state.notifications[notificationId];
            if (notification === null) continue;
            const card = this.generateCardFromNotification(notification, this.state.localUser);
            if (this.priorityNotificationsToUpdate.includes(notification.type)) {
                priorityCards.push(card);
            } else if (this.regularNotificationsToUpdate.includes(notification.type)) {
                regularCards.push(card);
            }
        }

        return(
            <NerdHerderStandardPageTemplate pageName='main' headerSelection='main' 
                                            localUser={this.state.localUser}>
                <NerdHerderTour name='main' steps={this.tourSteps} localUser={this.state.localUser}/>
                {priorityCards}
                <UserNagCard localUser={this.state.localUser}/>
                <LeageManagementNagCard localUser={this.state.localUser}/>
                {regularCards}
                <UserLeaguesCard localUser={this.state.localUser}/>
                <AvailableLeaguesCard localUser={this.state.localUser}/>
                <CreateNewLeagueCard localUser={this.state.localUser}/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class UserNagCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='UserNagCard'>
                <UserNagCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class UserNagCardInner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            navigateTo: null,
        }
    }

    render() {
        const localUser = this.props.localUser;

        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);

        // we only show one at a time, and these go in order of 'most important to nag about' to least important
        // TAKE PARENTS TO THE COPPA PAGE IF NEEDED
        let childUserId = getCookie('ParentalPermissionUserId', null);
        let childUserName = getCookie('ParentalPermissionUserName', null);
        if (localUser.under_13 === false && childUserId !== null && childUserName !== null) {
            return(
                <NerdHerderStandardCardTemplate id='user-nag-card' title='Granting Permission?' titleIcon="propeller.png">
                    <p>It looks like a child named <b>{childUserName}</b> tried to login recently. Are you the parent or guardian? Do they have permission to use NerdHerder?</p>
                    <div className='text-end'>
                        <Button variant='primary' onClick={()=>this.setState({navigateTo: '/app/parents'})}>I am the parent!</Button>
                    </div>
                </NerdHerderStandardCardTemplate>
            );
        }

        // USERS SHOULD SET A PROFILE IMAGE
        if (localUser.profile_image === null || localUser.profile_image.includes('meeple.png')) {
            return(
                <NerdHerderStandardCardTemplate id='user-nag-card' title='Not Easy Being Green!' titleIcon="nerdherder-title-icon.png">
                    <p>Did you know you can set a custom profile image? It helps users recognize you - in real life as well as on NerdHerder!</p>
                    <div className='text-end'>
                        <Button variant='primary' onClick={()=>this.setState({navigateTo: '/app/profile?focus=profile-card'})}>Let's Do It!</Button>
                    </div>
                </NerdHerderStandardCardTemplate>
            );
        }

        // USER WANTS WEB PUSH, BUT NOT ENABLED OR DISABLED LOCALLY
        if (localUser.web_push_enabled && getCookie('EnableDeviceWebPush', null) === null) {
                return (
                <NerdHerderStandardCardTemplate id='user-nag-card' title='Web Push Notifications' titleIcon="text.png">
                    <p>It looks like you've enabled Web Push Notifications from NerdHerder, that's great!</p>
                    <p>However it appears push notifications are not enabled on this specific device (maybe it's a new device?)...</p>
                    <div className='text-end'>
                        <Button variant='primary' onClick={()=>this.setState({navigateTo: '/app/profile?focus=notifications-card'})}>Let's Fix It</Button>
                    </div>
                </NerdHerderStandardCardTemplate>
            );
        }

        // SELECT SOME TOPICS TO CARE ABOUT
        if (localUser.topic_ids.length === 0) {
            return (
                <NerdHerderStandardCardTemplate id='user-nag-card' title='Select Some Interests' titleIcon="nerdherder-title-icon.png">
                    <p>Setting some interests in your profile helps us help you!</p>
                    <ul>
                        <li>NerdHerder suggests leagues, events, and tournaments for you based on your interests</li>
                        <li>NerdHerder only tracks stats for games you are interested in</li>
                        <li>NerdHerder ignores updates on topics you're not interested in</li>
                    </ul>
                    <div className='text-end'>
                        <Button variant='primary' onClick={()=>this.setState({navigateTo: '/app/profile?focus=interests-card'})}>Take Me There</Button>
                    </div>
                </NerdHerderStandardCardTemplate>
            );
        }
        
        // this is the ideal state - no reason to nag the user!
        return(null);
    }
}

class LeageManagementNagCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeageManagementNagCard'>
                <LeageManagementNagCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeageManagementNagCardInner extends React.Component {

    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            navigateTo: null,
            leagues: {},
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('self', null, (d, k)=>this.updateLeaguesList(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeaguesList(userData, key) {
        // now make sure that every league on either list is in the dictionary
        for (const leagueId of userData.league_ids) {
            if (!this.state.leagues.hasOwnProperty(leagueId)) {
                let sub = this.restPubSub.subscribeNoRefresh('league', leagueId, (d, k)=>this.updateLeague(d, k), null, leagueId);
                this.restPubSubPool.add(sub);
                this.setState((state) => {
                    return {leagues: {...state.leagues, [leagueId]: null}}
                });
            }
        }
    }

    updateLeague(leagueData, leagueId) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState((state) => {
            return {leagues: {...state.leagues, [leagueId]: league}}
        });
    }

    addMessageToProblemDict(problemDict, leagueId, level, message) {
        if (!problemDict.hasOwnProperty(leagueId)) problemDict[leagueId] = [];
        const index = problemDict[leagueId].length;
        const problemMessage = <Alert className='py-1' key={`problem-${leagueId}-${index}`}variant={level}><small>{message}</small></Alert>
        problemDict[leagueId].push(problemMessage);
    }

    render() {
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);
        const problemsByLeagueId = {};
        let todayDate = new Date();

        // search through all leagues looking for problems - basically sniff test!
        for (const [leagueId, league] of Object.entries(this.state.leagues)) {
            // if the league isn't loaded yet, if it's already archived, or if this user can't manage the league just skip it
            if (league === null) continue;
            if (league.state === 'archived') continue;
            if (!league.isManager(this.props.localUser.id)) continue;
            
            let startDate = null;
            let endDate = null;
            if (league.start_date) startDate = getLocalDateOnlyObject(league.start_date);
            if (league.end_date) endDate = getLocalDateOnlyObject(league.end_date);
            if (endDate) endDate.setHours(23, 59, 0);
            
            if (league.state !== 'draft') {
                // league should have ended
                if (league.state !== 'complete' && endDate && todayDate > endDate) {
                    this.addMessageToProblemDict(problemsByLeagueId, leagueId, 'danger', `Past end date - recommend changing ${league.getTypeWord()} state to Complete`);
                }
                // league should have started
                else if ((league.state === 'interest' || league.state === 'open') && startDate && todayDate > startDate) {
                    this.addMessageToProblemDict(problemsByLeagueId, leagueId, 'danger', `Past start date - recommend changing ${league.getTypeWord()} state to Running`);
                }
            }
            if (league.state === 'draft') {
                this.addMessageToProblemDict(problemsByLeagueId, leagueId, 'warning', `${league.getTypeWordCaps()} is in Draft - nobody can view it or join it`);
            }
        }

        // if there are no issues, hide the card
        const problemLeaguesIdsList = Object.keys(problemsByLeagueId);
        if (problemLeaguesIdsList.length === 0) return(null);

        const listItems = [];
        for (const leagueId of problemLeaguesIdsList) {
            const listItem =
                <LeagueListItem key={leagueId} leagueId={leagueId} onClick='manage' localUser={this.props.localUser}>
                    {problemsByLeagueId[leagueId]}
                </LeagueListItem>
            listItems.push(listItem);
        }

        return (
            <NerdHerderStandardCardTemplate title='Organizer Issues' titleIcon="warning.png">
                <p>You have some leagues, events, or tournaments that may have issues. We're just going to show them here and let you decide what to do...</p>
                {listItems}
            </NerdHerderStandardCardTemplate>
        );
    }
}

class UserLeaguesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='UserLeaguesCard'>
                <UserLeaguesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class UserLeaguesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            leagueSummaries: null,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('header-leagues', null, (d, k) => {this.updateLeagueSummary(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeagueSummary(leagueSummaryData, key) {
        this.setState({leagueSummaries: leagueSummaryData.league_summary});
    }

    render() {
        if (this.props.localUser === null) return(<NerdHerderLoadingCard title="Your Events"/>);
        if (this.state.leagueSummaries === null) return(<NerdHerderLoadingCard title="Your Events"/>);

        const managedLeagues = [];
        const runningLeagues = [];
        const interestOpenLeagues = [];
        const completedLeagues = [];
        const otherLeagues = [];

        for (const leagueSummary of this.state.leagueSummaries) {
            // don't include archived leagues
            if (leagueSummary.state === 'archived') continue;

            // but include every other league
            const listItem = <LeagueListItem key={leagueSummary.id} leagueId={leagueSummary.id} localUser={this.props.localUser}></LeagueListItem>
            if (leagueSummary.state === 'complete') {
                completedLeagues.push(listItem)
            }
            else if (leagueSummary.manager) {
                managedLeagues.push(listItem)
            }
            else if (leagueSummary.state === "running") {
                if (leagueSummary.player) {
                    runningLeagues.push(listItem);
                } else {
                    otherLeagues.push(listItem);
                }
            }
            else if (["interest", "open"].includes(leagueSummary.state)) {
                interestOpenLeagues.push(listItem);
            } else {
                otherLeagues.push(listItem);
            }
        }

        // if this user has no leagues at all, hide this card
        if (managedLeagues.length === 0 && runningLeagues.length === 0 && interestOpenLeagues.length === 0 && completedLeagues.length === 0 && otherLeagues.length === 0) {
            return(null);
        }

        return(
            <NerdHerderStandardCardTemplate id="your-leagues-card" title="Your Events" titleIcon='group.png'>
                {managedLeagues.length > 0 &&
                <div>
                    <small className="text-muted">Leagues, tournaments, and events you manage</small>
                    {managedLeagues}
                </div>}
                {runningLeagues.length > 0 &&
                <div>
                    <small className="text-muted">Running leagues, tournaments, and events</small>
                    {runningLeagues}
                </div>}
                {interestOpenLeagues.length > 0 &&
                <div>
                    <small className="text-muted">Registering or gathering interest</small>
                    {interestOpenLeagues}
                </div>}
                {completedLeagues.length > 0 &&
                <div>
                    <small className="text-muted">Completed leagues, tournaments, and events</small>
                    {completedLeagues}
                </div>}
                {otherLeagues.length > 0 &&
                <div>
                    <small className="text-muted">Other leagues, tournaments, and events</small>
                    {otherLeagues}
                </div>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class AvailableLeaguesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='AvailableLeaguesCard'>
                <AvailableLeaguesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class AvailableLeaguesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.searchTimeout = null;

        // a few backwards nations *heh* still use imperial units
        let useImperialUnits = false;
        if (["United States", "Myanmar", "Liberia"].includes(this.props.localUser.country)) {
            useImperialUnits = true;
        }

        // if the user has specified their interests, automatically limit searches to those topics
        let interestsFilterChecked = false;
        if (this.props.localUser.topic_ids.length > 0) {
            interestsFilterChecked = true;
        }

        // some nations are huge or islands, when searching in those nations default to only searching those nations
        let countryFilterChecked = false;
        if (["United States", "United Kingdom", "Australia"].includes(this.props.localUser.country)) {
            countryFilterChecked = true;
        }

        this.state = {
            showChooseLocationPopupModal: false,
            showSearchCompletedLeaguesModal: false,
            useImperialUnits: useImperialUnits,
            distanceFromString: `profile location (${this.props.localUser.zipcode} ${this.props.localUser.country})`,
            countryFilterChecked: countryFilterChecked,
            interestsFilterChecked: interestsFilterChecked,
            onlineFilterChecked: this.props.localUser.online_interest,
            distanceSelection: "unlimited",
            leaguesList: null,
            leagues: {},
            stateHash: 0,
            delayedSearchTimeoutExpired: false,
            disableControls: true,
            searching: false,
            fromLatitude: null,
            fromLongitude: null,
            fromZipcode: null,
            fromCountry: null,
        }
    }

    componentDidMount() {
        setTimeout(()=>this.permitSearching(), 3000);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    permitSearching() {
        this.setState({delayedSearchTimeoutExpired: true});
    }

    triggerNewSearch() {
        const currentHash = hashCode(`${this.state.countryFilterChecked}:${this.state.interestsFilterChecked}:${this.state.onlineFilterChecked}:${this.state.distanceSelection}:${this.state.fromCountry}:${this.state.fromZipcode}:${this.state.fromLatitude}:${this.state.fromLongitude}`)
        if (this.state.stateHash !== currentHash) {
            console.debug('making a new request');
            let filterParams = {
                postal_code: this.props.localUser.zipcode,
                interests_only: this.state.interestsFilterChecked,
                include_online: this.state.onlineFilterChecked,
            };
            if (this.state.useImperialUnits) {
                filterParams['range_mi'] = this.state.distanceSelection;
            } else {
                filterParams['range_km'] = this.state.distanceSelection;
            }
            if (this.state.countryFilterChecked){
                filterParams['country'] = this.props.localUser.country;
            }
            if (this.state.fromLatitude && this.state.fromLongitude) {
                filterParams['from-latitude'] = this.state.fromLatitude;
                filterParams['from-longitude'] = this.state.fromLongitude;
            } else if (this.state.fromZipcode && this.state.fromCountry) {
                filterParams['postal_code'] = this.state.fromZipcode;
                filterParams['from-country'] = this.state.fromCountry;
            }
            this.restApi.genericGetEndpointData('league', null, filterParams)
            .then(response => {
                this.updateResults(response.data);
            }).catch(error => {
                console.error(error);
            });
            this.setState({stateHash: currentHash, searching: true});
        }
        if (this.state.disableControls) {
            this.setState({disableControls: false});
        }
    }

    updateResults(leagueData) {
        console.debug('got Available Leagues results!');
        const sortedUpdatedLeagueData = [];
        for (const league of leagueData) {
            sortedUpdatedLeagueData.push(league);
        }
        sortedUpdatedLeagueData.sort(function (a, b) {
            let ra = a.range;
            let rb = b.range;
            if (ra === null) {
                ra = 1000;
            } else {
                ra = parseInt(ra);
            }
            if (rb === null) {
                rb = 1000;
            } else {
                rb = parseInt(rb);
            }
            return ra - rb;
        });
        for (const league of sortedUpdatedLeagueData) {
            if (league.range !== null) {
                league.range = parseInt(league.range);
                if (league.range === 0) {
                    league.range = 'Nearby';
                } else {
                    if (this.state.useImperialUnits) {
                        league.range = `${league.range} mi`;
                    } else {
                        league.range = `${league.range} km`;
                    }
                }
            }
        }
        this.setState({leaguesList: sortedUpdatedLeagueData});
        setTimeout(()=>this.handleSearchComplete(), 1000)
    }

    onCancelLocationModal() {
        this.setState({showChooseLocationPopupModal: false});
    }

    onCancelSearchModal() {
        this.setState({showSearchCompletedLeaguesModal: false});
    }

    onAcceptLocationModal(latitude, longitude, zipcode, country, fromString) {
        // uncheck the country filter if it's not possibly correct
        let newCountryFilterChecked = this.state.countryFilterChecked;
        if (newCountryFilterChecked && country !== this.props.localUser.country) {
            newCountryFilterChecked = false;
        }

        this.setState({
            showChooseLocationPopupModal: false,
            distanceFromString: fromString,
            fromLatitude: latitude,
            fromLongitude: longitude,
            fromZipcode: zipcode,
            fromCountry: country,
            countryFilterChecked: newCountryFilterChecked,
        });
    }

    handleSearchComplete() {
        this.setState({searching: false});
    }

    handleDistanceChange(event) {
        this.setState({distanceSelection: event.target.value});
    }

    handleInterestsCheckboxChange(event) {
        this.setState({interestsFilterChecked: event.target.checked});
    }

    handleOnlineCheckboxChange(event) {
        this.setState({onlineFilterChecked: event.target.checked});
    }

    handleCountryCheckboxChange(event) {
        this.setState({countryFilterChecked: event.target.checked});
    }
    
    render() {
        if (this.props.localUser === null) return(<NerdHerderLoadingCard title="Available Events"/>)
        const useImperialUnits = this.state.useImperialUnits;
        if (this.state.delayedSearchTimeoutExpired) {
            if (this.searchTimeout !== null) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(()=>this.triggerNewSearch(), 1000);
        }
        let countryCheckedLabel = `${this.props.localUser.country} only`;

        const leaguesListItems = [];

        if (this.state.leaguesList !== null) {
            // sort the league list by range before generating the list items
            const sortedLeaguesList = [];
            const noRangeLeaguesList = [];
            for (const league of this.state.leaguesList) {
                if (league.range === null) {
                    noRangeLeaguesList.push(league);
                } else {
                    sortedLeaguesList.push(league);
                }
            }
            sortedLeaguesList.sort(function(league1, league2) {
                // if there are two leagues that are both online, the winner is whichever was created first
                if (league1.online && league2.online) {
                    if (league1.id < league2.id) return -1;
                    return 1;
                }
                // put online before in-person (assume zero miles basically)
                else if (league1.online && !league2.online) return -1;
                else if (league2.online && !league1.online) return 1;
                
                // otherwise its just whatever league is closer
                return(league1.range - league2.range);
            })
            
            // now make the list items, put the no-range items on the end
            for (const league of sortedLeaguesList) {
                const listItem = <AvailableLeagueListItem key={league.id} league={league} localUser={this.props.localUser}/>
                leaguesListItems.push(listItem);
            }
            for (const league of noRangeLeaguesList) {
                const listItem = <AvailableLeagueListItem key={league.id} league={league} localUser={this.props.localUser}/>
                leaguesListItems.push(listItem);
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="available-leagues-card" title="Available Events" titleIcon='search.png'>
                {this.state.showChooseLocationPopupModal &&
                <NerdHerderChooseLocationModal onCancel={()=>this.onCancelLocationModal()}
                                               onAccept={(lat, long, zip, country, string)=>this.onAcceptLocationModal(lat, long, zip, country, string)}
                                               localUser={this.props.localUser}/>}
                {this.state.showSearchCompletedLeaguesModal &&
                <NerdHerderCompletedLeaguesModal onCancel={()=>this.onCancelSearchModal()}
                                                 localUser={this.props.localUser}/>}
                {!this.state.searching && <p>Search for leagues, tournaments, and events you might be interested in</p>}
                {this.state.searching && <p>Searching... <Spinner as='span' variant="primary" animation="border" size="sm" role="status" aria-hidden="true"/></p>}
                {this.state.userFeedback}
                <Form>
                    <Form.Group className="mb-4">
                        <Row className='mb-2'>
                            <Col>
                                <Form.Label>Distance from {this.state.distanceFromString}</Form.Label>
                            </Col>
                            <Col xs='auto'>
                                <Button variant='primary'className='float-end' onClick={()=>this.setState({showChooseLocationPopupModal: true})}><NerdHerderFontIcon icon='flaticon-global-planetary-sphere-with-continent'/></Button>
                            </Col>
                        </Row>
                        <Form.Select aria-label="select distance" onChange={(event)=>this.handleDistanceChange(event)} value={this.state.distanceSelection} disabled={this.state.disableControls}>
                            <option value="unlimited">Unlimited</option>
                            {!useImperialUnits &&<option value="50">50 km</option>}
                            {!useImperialUnits &&<option value="80">80 km</option>}
                            {!useImperialUnits &&<option value="120">120 km</option>}
                            {!useImperialUnits &&<option value="300">300 km</option>}
                            {!useImperialUnits &&<option value="600">600 km</option>}
                            {useImperialUnits && <option value="30">30 miles</option>}
                            {useImperialUnits && <option value="50">50 miles</option>}
                            {useImperialUnits && <option value="80">80 miles</option>}
                            {useImperialUnits && <option value="250">250 miles</option>}
                            {useImperialUnits && <option value="500">500 miles</option>}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-outline mb-4">
                        <Form.Check type="checkbox" label="My Interests only" onChange={(e)=>this.handleInterestsCheckboxChange(e)} checked={this.state.interestsFilterChecked} disabled={this.state.disableControls}/>
                        <Form.Check type="checkbox" label={countryCheckedLabel} onChange={(e)=>this.handleCountryCheckboxChange(e)} checked={this.state.countryFilterChecked} disabled={this.state.disableControls}/>
                        <Form.Check type="checkbox" label="Include online leagues" onChange={(e)=>this.handleOnlineCheckboxChange(e)} checked={this.state.onlineFilterChecked} disabled={this.state.disableControls}/>
                    </Form.Group>
                </Form>
                {leaguesListItems}
                {leaguesListItems.length === 0 && !this.state.searching &&
                <p><b>There are no available events or leagues that match your search criteria.</b></p>}
                <hr/>
                <Row>
                    <Col>
                        <small>Search completed events or leagues</small>
                    </Col>
                    <Col xs='auto'>
                    <   Button size='sm' variant='secondary' onClick={()=>this.setState({showSearchCompletedLeaguesModal: true})}><NerdHerderFontIcon icon='flaticon-search'/></Button>
                    </Col>
                </Row>
                
            </NerdHerderStandardCardTemplate>
        )
    }
}

class CreateNewLeagueCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='CreateNewLeagueCard'>
                <CreateNewLeagueCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class CreateNewLeagueCardInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navigateTo: null,
        }
    }

    render() {
        if (this.state.navigateTo) return <Navigate to={this.state.navigateTo}/>

        return(
            <NerdHerderStandardCardTemplate id="create-league-card" title="Create a League or Event" titleIcon='add.png'>
                <p>If you are looking to start a league, host a tournament, or run an event this is the button for it! Getting setup is trivially easy, and being an organizer has never been simpler!</p>
                <div className="d-grid gap-2">
                    <Button size='lg' variant='primary' onClick={()=>{this.setState({navigateTo: '/app/newleague'})}}>Add Event</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(MainPage);
