import React from 'react';
import Joyride, { EVENTS }from 'react-joyride';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getCookie, setCookie, isProbablySafari } from '../utilities';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';

export class NerdHerderTour extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.name === 'undefined') console.error('missing props.name');
        if (typeof this.props.steps === 'undefined') console.error('missing props.steps');

        // showing the tour comes from a cookie - if the cookie is present and set, don't show the tour
        let showTour = false;
        this.cookieName = null;
        if (this.props.name) {
            this.cookieName = `tour-${this.props.name}`;
        }
        if (this.cookieName) {
            let cookieValue = getCookie(this.cookieName, 'show-it');
            if (cookieValue === 'show-it') {
                showTour = true;
            }
        }

        // safari only keeps the show tour cookie for 7 days, so never show the tour on safari (the user will be invited to the tour ever 7 days)
        let isSafari = isProbablySafari();
        if (isSafari) {
            console.debug('disabling tour on Safari');
            showTour = false;
        }
        
        // if running in NPM on port 3000 or localhost, turn on debug
        this.debug = false;
        let hostname = window.location.hostname;
        let portnum = window.location.port;
        if (hostname === 'localhost' || portnum === '3000') {
            this.debug = true;
        }

        // if one of the tour stops is #tour-info, then we need to make sure that is added to the DOM
        let showTourInfoDiv = false;
        if (this.props.steps) {
            for (const tourStop of this.props.steps) {
                if (tourStop.hasOwnProperty('target') && tourStop.target === '#tour-info') {
                    showTourInfoDiv = true;
                    break;
                }
            }
        }

        this.state = {
            showTour: showTour,
            showTourInfoDiv: showTourInfoDiv,
            steps: this.props.steps || [],
        };
    }

    setTourComplete() {
        setCookie(this.cookieName, 'complete', 360);
    }

    joyrideCallback(joyrideState) {
        if (joyrideState.type === EVENTS.TOUR_END) {
            this.setTourComplete();
            this.setState({showTour: false})
        };
    }

    render() {
        if (!this.state.showTour) return(null);

        const joyrideStyle = {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            beaconSize: 36,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#0d6efd',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            textColor: '#333',
            width: undefined,
            zIndex: 100,
        };
        
        const locale = { back: 'Back', close: 'Close', last: 'Last', next: 'Next', open: 'Open the dialog', skip: 'Skip Tour' };   
        
        return (
            <div>
                {this.state.showTourInfoDiv &&
                <div>
                    <Row className='justify-content-center p-2'>
                        <Col xs='auto'>
                            <div id='tour-info' className='rounded text-center text-primary bg-white px-5' style={{fontSize: '72px'}}>
                                <NerdHerderFontIcon icon='flaticon-information-in-circular-button'/>
                            </div>
                        </Col>
                    </Row>
                </div>}
                <Joyride 
                    steps={this.state.steps}
                    showProgress={true}
                    showSkipButton={true}
                    continuous={true}
                    debug={this.debug}
                    styles={joyrideStyle}
                    locale={locale}
                    callback={(s)=>this.joyrideCallback(s)}/>
            </div>
        )
    }
}
