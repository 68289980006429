import { NerdHerderDataModelFactory } from './nerdherder-models';
import { getStaticStorageImageFilePublicUrl, getRandomInteger } from './utilities';
import md5 from 'md5';

export function parseTournamentGetResponse(tournament, updatedUsersTournaments, updatedRounds, updatedGames, updatedUsersGames, updatedPlayers) {
    for (const usersTournamentsData of tournament.users_tournaments) {
        const newUsersTournament = NerdHerderDataModelFactory('user-tournament', usersTournamentsData);
        updatedUsersTournaments[newUsersTournament.user_id] = newUsersTournament;
    }

    for (const usersData of tournament.users) {
        const newUser = NerdHerderDataModelFactory('user', usersData);
        updatedPlayers[newUser.id] = newUser;
    }

    // inject user.id = 0 for byes
    const byeUsersData = {id: 0, username: "BYE", profile_image: getStaticStorageImageFilePublicUrl('/bye-image.png')}
    updatedPlayers[0] = NerdHerderDataModelFactory('user', byeUsersData);

    for (const roundData of tournament.rounds) {
        const newRound = NerdHerderDataModelFactory('tournament-round', roundData);
        updatedRounds[newRound.id] = newRound;
        for (const gameData of newRound.games) {
            const newGame = NerdHerderDataModelFactory('game', gameData);
            updatedGames[newGame.id] = newGame;
            for (const usersGames of newGame.players) {
                const playerId = usersGames.user_id;
                const gameId = usersGames.game_id;
                updatedUsersGames[usersGamesKey(playerId, gameId)] = usersGames;
                const playerInfo = usersGames.user_info;
                if (!updatedPlayers.hasOwnProperty(playerInfo.id)) {
                    updatedPlayers[playerInfo.id] = playerInfo;
                }
            }
        }
    }

    // not all games are attached to rounds
    for (const gameData of tournament.unattached_games) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        updatedGames[newGame.id] = newGame;
        for (const usersGames of newGame.players) {
            const playerId = usersGames.user_id;
            const gameId = usersGames.game_id;
            updatedUsersGames[usersGamesKey(playerId, gameId)] = usersGames;
            const playerInfo = usersGames.user_info;
            if (!updatedPlayers.hasOwnProperty(playerInfo.id)) {
                updatedPlayers[playerInfo.id] = playerInfo;
            }
        }
    }

    // for elimination tournaments, update tournament with bracket info
    if (tournament.type === 'elimination') {
        generateBracketIndexTables(tournament, updatedGames);
    }
}

export function parseElimCode(elimCode) {
    const result = {bracket: 'wb', roundIndex: 0, gameIndex: 0, topPrevIndex: 0, botPrevIndex: 0};
    if (elimCode) {
        // bracket, round_index, game_index, top_prev_index, bot_prev_index
        const splitArray = elimCode.split(':');
        if (splitArray.length >= 1) result['bracket'] = splitArray[0];
        if (splitArray.length >= 1) result['roundIndex'] = parseInt(splitArray[1]);
        if (splitArray.length >= 1) result['gameIndex'] = parseInt(splitArray[2]);
        if (splitArray.length >= 1) result['topPrevIndex'] = parseInt(splitArray[3]);
        if (splitArray.length >= 1) result['botPrevIndex'] = parseInt(splitArray[4]);
    }
    return (result);
}

export function parseRanking(ranking) {
    let splitArray = ranking.split('>');
    for (let i=0; i<4; i++) {
        if (splitArray.length < i) splitArray.push('na');
        if (!['na', 'mp', 'mov', 'mov1', 'mov2', 'mov3', 'rec', 'sos', 'vp', 'vp1', 'vp2', 'vp3'].includes(splitArray[i])) {
            splitArray[i] = 'na';
        } else if (splitArray[i] === 'mov') {
            splitArray[i] = 'mov1';
        } else if (splitArray[i] === 'vp') {
            splitArray[i] = 'vp1';
        }
    }
    return splitArray;
}

export function generateRanking(rankingArray) {
    let firstOne = true;
    let rankingString = '';
    for (const val of rankingArray) {
        if (val === 'na') continue;
        if (firstOne) {
            rankingString = `${val}`;
            firstOne = false;
        } else {
            rankingString += `>${val}`;
        }
    }
    return rankingString;
}

export function generateBracketIndexTables(tournament, gameDict) {
    const bracketTreeTable = {};
    const indexToIdTable = {};
    const idToIndexTable = {};
    for (const gameId of tournament.game_ids) {
        const game = gameDict[gameId];
        const elimCodeDict = parseElimCode(game.elim_code);
        elimCodeDict['nextIndex'] = null;
        elimCodeDict['nextLoserIndex'] = null;
        const gameIndex = elimCodeDict['gameIndex'];
        bracketTreeTable[gameIndex] = elimCodeDict;
        indexToIdTable[gameIndex] = gameId;
        idToIndexTable[gameId] = gameIndex;
    }
    for (const [gameIndex, game] of Object.entries(bracketTreeTable)) {
        const bracket = game.bracket;
        if (game.topPrevIndex !== null && game.topPrevIndex > 0) {
            if (bracketTreeTable.hasOwnProperty(game.topPrevIndex)) {
                if (bracket === 'wb') {
                    bracketTreeTable[game.topPrevIndex].nextIndex = parseInt(gameIndex);
                } else {
                    bracketTreeTable[game.topPrevIndex].nextLoserIndex = parseInt(gameIndex);
                }
            } else {
                console.log(`in generateTournamentIndexTable() tried to assign game.index=${game.topPrevIndex} a nextIndex of ${gameIndex}, but that index is missing`);
            }
        }
        if (game.botPrevIndex !== null && game.botPrevIndex > 0) {
            if (bracketTreeTable.hasOwnProperty(game.botPrevIndex)) {
                if (bracket === 'wb') {
                    bracketTreeTable[game.botPrevIndex].nextIndex = parseInt(gameIndex);
                } else {
                    bracketTreeTable[game.botPrevIndex].nextLoserIndex = parseInt(gameIndex);
                }
            } else {
                console.log(`in generateTournamentIndexTable() tried to assign game.index=${game.botPrevIndex} a nextIndex of ${gameIndex}, but that index is missing`);
            }
        }
    }
    tournament['bracketTreeTable'] = bracketTreeTable;
    tournament['indexToIdTable'] = indexToIdTable;
    tournament['idToIndexTable'] = idToIndexTable;
}

export function usersGamesKey(playerId, gameId) {
    return `uid:${playerId}-gid:${gameId}`;
}

export function generateTimeRemainingHMS(secondsRemaining) {
    let outputString = 'Unlimited';
    if (secondsRemaining !== null) {        
        let hoursRemaining = parseInt(secondsRemaining / 3600);
        secondsRemaining -= hoursRemaining * 3600;
        let minutesRemaining = parseInt(secondsRemaining / 60);
        secondsRemaining -= minutesRemaining * 60;
        let minutesRemainingString = minutesRemaining.toString();
        let secondsRemainingString = secondsRemaining.toString();
        if (minutesRemaining < 10) minutesRemainingString = '0' + minutesRemainingString;
        if (secondsRemaining < 10) secondsRemainingString = '0' + secondsRemainingString;
        outputString = `${hoursRemaining}:${minutesRemainingString}:${secondsRemainingString}`;
    }
    return(outputString);
}

// the current round is the lastest round that isn't in draft, if all rounds are in draft then the current round is the first round
export function getCurrentRound(tournament) {
    let currentRound = null;
    for (const roundData of tournament.rounds) {
        if (currentRound === null) currentRound = roundData;
        if (roundData.state !== 'draft') {
            currentRound = roundData;
        }
    }
    return currentRound;
}

export function geFirstDraftRound(tournament) {
    for (const roundData of tournament.rounds) {
        if (roundData.state === 'draft') {
            return(roundData);
        }
    }
    return null;
}

export function getFirstRound(tournament) {
    // rounds begin at 1 - return null if there isn't one
    return getTournamentRound(tournament, 1);
}

export function getLastRound(tournament) {
    if (tournament.rounds.length === 0) return (null);
    let lastIndex = tournament.rounds.length - 1;
    return (tournament.rounds[lastIndex]);
}

export function getLastCompletedRound(tournament) {
    if (tournament.rounds.length === 0) return (null);
    let lastCompletedRoundIndex = 0;
    for (const roundData of tournament.rounds) {
        if (roundData.state !== 'complete') {
            break;
        }
        lastCompletedRoundIndex = roundData.index;
    }
    // eslint-disable-next-line eqeqeq
    if (lastCompletedRoundIndex == 0) return (null);
    return getTournamentRound(tournament, lastCompletedRoundIndex);
}

export function getPreviousRound(tournament, roundData) {
    let prevRoundIndex = roundData.index - 1
    // rounds begin at 1, so if we're at 0 or lower then there's no previous
    if (prevRoundIndex <= 0) return (null);
    return getTournamentRound(tournament, prevRoundIndex);
}

export function getTournamentRound(tournament, roundNumber) {
    const baseZeroRoundNumber = roundNumber - 1;
    if (baseZeroRoundNumber < tournament.rounds.length) return tournament.rounds[baseZeroRoundNumber];
    return null;
}

export function tabulateTournamentData(tournament, roundData, hasPlayedDict, recordDict, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict) {
    const WINS = 0;
    const TIES = 1;
    const LOSSES = 2;
    const totalGamesPlayed = {};

    // build the hasPlayedDict, recordDict, and vps, mov, strength dicts - they will be populated later
    for (const usersTournaments of tournament.users_tournaments) {
        let userId = usersTournaments.user_id;
        hasPlayedDict[userId] = [];
        recordDict[userId] = [0, 0, 0];
        matchPointsDict[userId] = 0;
        vpDict[userId] = {score1: 0, score2: 0, score3: 0};
        movDict[userId] = {score1: 0, score2: 0, score3: 0};
        metricPerOpponentDict[userId] = {};
        strengthOfScheduleDict[userId] = 0;
        numberOfByesDict[userId] = 0;
        totalGamesPlayed[userId] = 0;
    }

    // populate the dicts - go through all the rounds up to this one, add players and save data in easier to handle formats
    let startRoundIndex = 1;
    let endRoundIndex = 1;
    if (roundData !== null) {
        if (roundData.state === 'complete') {
            endRoundIndex = roundData.index + 1;
        }
        else {
            endRoundIndex = roundData.index;
        }
    }

    for (let index=startRoundIndex; index<endRoundIndex; index++) {
        const tournamentRoundData = getTournamentRound(tournament, index);
        if (tournamentRoundData.state === 'complete') {
            for (const gameData of tournamentRoundData.games) {
                if (gameData.state === 'posted' && gameData.completion === 'completed') {
                    if (gameData.bye === false && gameData.players.length === 2) {
                        let user1Id = gameData.players[0].user_id;
                        let user1Score1 = gameData.players[0].score1;
                        let user1Score2 = gameData.players[0].score2;
                        let user1Score3 = gameData.players[0].score3;
                        let user2Id = gameData.players[1].user_id;
                        let user2Score1 = gameData.players[1].score1;
                        let user2Score2 = gameData.players[1].score2;
                        let user2Score3 = gameData.players[1].score3;
                        hasPlayedDict[user1Id].push(user2Id);
                        hasPlayedDict[user2Id].push(user1Id);
                        totalGamesPlayed[user1Id] += 1;
                        totalGamesPlayed[user2Id] += 1;

                        // assume player 1 won, player 2 lost...
                        let isTie = false;
                        let winnerId = user1Id;
                        let loserId = user2Id;

                        // correct the assumption if needed
                        if (gameData.players[0].winner && gameData.players[1].winner) {
                            isTie = true;
                        }
                        else if (!gameData.players[0].winner && !gameData.players[1].winner) {
                            isTie = true;
                        }
                        else if (gameData.players[1].winner) {
                            winnerId = user2Id;
                            loserId = user1Id;
                        }

                        // if it's a tie...
                        if (isTie) {
                            recordDict[user1Id][TIES] += 1;
                            recordDict[user2Id][TIES] += 1;
                            metricPerOpponentDict[winnerId][loserId] = 0;
                            metricPerOpponentDict[loserId][winnerId] = 0;
                            vpDict[user1Id].score1 += user1Score1;
                            vpDict[user2Id].score1 += user2Score1;
                            vpDict[user1Id].score2 += user1Score2;
                            vpDict[user2Id].score2 += user2Score2;
                            vpDict[user1Id].score3 += user1Score3;
                            vpDict[user2Id].score3 += user2Score3;
                        } else {
                            recordDict[winnerId][WINS] += 1;
                            recordDict[loserId][LOSSES] += 1;
                            vpDict[user1Id].score1 += user1Score1;
                            vpDict[user2Id].score1 += user2Score1;
                            vpDict[user1Id].score2 += user1Score2;
                            vpDict[user2Id].score2 += user2Score2;
                            vpDict[user1Id].score3 += user1Score3;
                            vpDict[user2Id].score3 += user2Score3;
                            let pointDelta1 = Math.abs(gameData.players[0].score1 - gameData.players[1].score1);
                            if (pointDelta1 === 0) {
                                pointDelta1 = 1
                            }
                            let pointDelta2 = Math.abs(gameData.players[0].score2 - gameData.players[1].score2);
                            if (pointDelta2 === 0) {
                                pointDelta2 = 1
                            }
                            let pointDelta3 = Math.abs(gameData.players[0].score3 - gameData.players[1].score3);
                            if (pointDelta3 === 0) {
                                pointDelta3 = 1
                            }
                            movDict[winnerId].score1 += pointDelta1;
                            movDict[loserId].score1 -= pointDelta1;
                            movDict[winnerId].score2 += pointDelta2;
                            movDict[loserId].score2 -= pointDelta2;
                            movDict[winnerId].score3 += pointDelta3;
                            movDict[loserId].score3 -= pointDelta3;
                            if ((tournament.type === 'swiss' || tournament.type === 'round-robin') && tournament.subtype === 'mov') {
                                metricPerOpponentDict[winnerId][loserId] = pointDelta1;
                                metricPerOpponentDict[loserId][winnerId] = pointDelta1 * -1;
                            } else {
                                metricPerOpponentDict[user1Id][user2Id] = user1Score1;
                                metricPerOpponentDict[user2Id][user1Id] = user2Score1;
                            }
                        }
                    } else if (gameData.bye && gameData.players.length === 1) {
                        let userId = gameData.players[0].user_id;
                        const byeResultDict = tournament.getByeResults();
                        if (byeResultDict.record === 'w') {
                            recordDict[userId][WINS] += 1;
                        } else if (byeResultDict.record === 't') {
                            recordDict[userId][TIES] += 1;
                        } else {
                            recordDict[userId][LOSSES] += 1;
                        }
                        vpDict[userId].score1 += byeResultDict.vp1;
                        vpDict[userId].score2 += byeResultDict.vp2;
                        vpDict[userId].score3 += byeResultDict.vp3;
                        movDict[userId].score1 += byeResultDict.vp1 - byeResultDict.lp1;
                        movDict[userId].score2 += byeResultDict.vp2 - byeResultDict.lp2;
                        movDict[userId].score3 += byeResultDict.vp3 - byeResultDict.lp3;
                        totalGamesPlayed[userId] += 1;
                        numberOfByesDict[userId] += 1;
                    }
                }
            }
        }
    }

    // here we know the record of each player, convert that into match points
    for (const usersTournaments of tournament.users_tournaments) {
        let userId = usersTournaments.user_id;
        let wins = recordDict[userId][WINS];
        let ties = recordDict[userId][TIES];
        let losses = recordDict[userId][LOSSES];
        matchPointsDict[userId] = wins * 3 + ties * 1 + losses * 0;
    }

    // go through each player, and for each completed round calculate strength of schedule
    for (const usersTournaments of tournament.users_tournaments) {
        let userId = usersTournaments.user_id
        // to calculate SoS, add up the 'score' of each opponent played
        for (const opponentUserId of hasPlayedDict[userId]) {
            // 'score' could be match points or metric
            // if we are using the 'sum' SoS method, set divisor to 1, otherwise set to opponent games played
            let divisor = 1;
            if (tournament.sos_method === 'average' && totalGamesPlayed[opponentUserId] > 0) {
                divisor = totalGamesPlayed[opponentUserId];
            }
            if (tournament.type === 'swiss' || tournament.type ==='round-robin') {
                if (tournament.subtype === 'mp') {
                    strengthOfScheduleDict[userId] += (matchPointsDict[opponentUserId]/divisor);
                } else if (tournament.subtype === 'vp') {
                    strengthOfScheduleDict[userId] += (vpDict[opponentUserId].score1/divisor);
                } else {
                    strengthOfScheduleDict[userId] += (movDict[opponentUserId].score1/divisor);
                }
            }
        }
        if (tournament.sos_method === 'average' && totalGamesPlayed[userId] > 0) {
            strengthOfScheduleDict[userId] = strengthOfScheduleDict[userId] / totalGamesPlayed[userId];
        }
    }

    // finally, add starting scores (if any)
    for (const usersTournaments of tournament.users_tournaments) {
        if (usersTournaments.metric) {
            if (tournament.type === 'swiss' || tournament.type === 'round-robin') {
                switch(tournament.subtype) {
                    case 'mp':
                        matchPointsDict[usersTournaments.user_id] += usersTournaments.metric;
                        break;
                    case 'vp':
                        vpDict[usersTournaments.user_id].score1 += usersTournaments.metric;
                        break;
                    case 'mov':
                        movDict[usersTournaments.user_id].score1 += usersTournaments.metric;
                        break;
                    default:
                        console.error('got unexpected tournament subtype');
                }
           }
        }
    }
}

export function sortTournamentPlayers(tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, forPairing=false) {
    const unsortedList = [];
    for (const usersTournaments of tournament.users_tournaments) {
        let userId = usersTournaments.user_id;
        let mpRandomValue = -1;
        if (tournament.type === 'swiss' && tournament.subtype === 'mp' && forPairing) {
            mpRandomValue = getRandomInteger(0, 10000);
        }
        let playerItem = {'user_id': userId,
                          'users_tournaments': usersTournaments,
                          'tournament_data': tournament,
                          'mp': matchPointsDict[userId],
                          'mpRandomValue': mpRandomValue,
                          'mov': movDict[userId].score1,
                          'vp': vpDict[userId].score1,
                          'mov1': movDict[userId].score1,
                          'vp1': vpDict[userId].score1,
                          'mov2': movDict[userId].score2,
                          'vp2': vpDict[userId].score2,
                          'mov3': movDict[userId].score3,
                          'vp3': vpDict[userId].score3,
                          'sos': strengthOfScheduleDict[userId],
                          'metric_per_opponent': metricPerOpponentDict[userId]};
        unsortedList.push(playerItem);
    }

    let tournamentSorter = swissTournamentSorter;
    if (tournament.type === 'elimination') tournamentSorter = eliminationTournamentSorter;
    unsortedList.sort(tournamentSorter);
    const resultingList = [];
    for (const player_item of unsortedList) {
        resultingList.unshift(player_item['user_id']);
    }

    // look for the final round condition in swiss tournaments, and adjust the rankings
    if (tournament.type === 'swiss' && tournament.top_table_finals) {
        let lastCompletedRound = getLastCompletedRound(tournament);
        if (lastCompletedRound !== null && lastCompletedRound.index === tournament.num_rounds) {
            if (lastCompletedRound.game_ids.length !== 0) {
                let topGameId = lastCompletedRound.game_ids[0];
                let topPlayer1Id = 0;
                let topPlayer2Id = 0;
                // figure out who top player 1 and 2 are
                for (const gameData of lastCompletedRound.games) {
                    if (gameData.id === topGameId) {
                        if (gameData.players.length === 2) {
                            topPlayer1Id = gameData.players[0].user_id;
                            topPlayer2Id = gameData.players[1].user_id;
                        }
                        else if (gameData.players.length === 1 && gameData.bye) {
                            topPlayer1Id = gameData.players[0].user_id;
                        }
                        break;
                    }
                }

                // if there was in fact a top match and it wasn't a bye, figure out who was ranked higher
                if (topPlayer1Id !== 0 && topPlayer2Id !== 0) {
                    for (const playerId of resultingList) {
                        if (playerId === topPlayer1Id) break;  // hit top player 1 first, the IDs are already in order
                        if (playerId === topPlayer2Id) {       // hit top player 2 first, so need to swap the ids to make 2 #1
                            topPlayer2Id = topPlayer1Id;
                            topPlayer1Id = playerId;
                            break;
                        }
                    }
                    // remove player 1 & 2 from the result
                    let index = resultingList.indexOf(topPlayer1Id);
                    resultingList.splice(index, 1);
                    index = resultingList.indexOf(topPlayer2Id);
                    resultingList.splice(index, 1);

                    // finally unshift player 2 then player 1 into the result to put them at the front
                    resultingList.unshift(topPlayer2Id);
                    resultingList.unshift(topPlayer1Id);
                } else if (topPlayer1Id !== 0 && topPlayer2Id === 0) {
                    // remove player 1 from the result and then unshift them into the front
                    let index = resultingList.indexOf(topPlayer1Id);
                    resultingList.splice(index, 1);
                    resultingList.unshift(topPlayer1Id);
                }
            }
        }
    }

    return (resultingList);
}

function swissTournamentSorter(player1, player2) {
    let tournament = player1['tournament_data'];
    let rankingOrder = parseRanking(tournament.ranking);
    let p1Id = player1.user_id;
    let p2Id = player2.user_id;

    // returning a 1 means P1 wins
    // returning a -1 means P2 wins

    // if a user has dropped, they are automatically the loser
    if (player2['users_tournaments'].dropped) return (1);
    else if (player1['users_tournaments'].dropped) return (-1);

    // iterate through ranking / tiebreakers in order
    for (const currentMetric of rankingOrder) {
        if (currentMetric === 'rec') {
            // if we get here look to see if the players played each other
            if (player1['metric_per_opponent'].hasOwnProperty(p2Id) && player2['metric_per_opponent'].hasOwnProperty(p1Id)) {
                if (player1['metric_per_opponent'][p2Id] > player2['metric_per_opponent'][p1Id]) return (1);
                if (player1['metric_per_opponent'][p2Id] < player2['metric_per_opponent'][p1Id]) return (-1);
            }
        } else {
            if (player1[currentMetric] > player2[currentMetric]) return (1);
            else if (player1[currentMetric] < player2[currentMetric]) return (-1);
        }

        // if we're sorting for pairing, the players will have mpRandomValue set - use it after evaluating match points
        // if we're not sorting for pairing, the values for mpRandomValue will be -1 for both players
        if (tournament.type === 'swiss' && tournament.subtype === 'mp' && currentMetric === 'mp') {
            if (player1['mpRandomValue'] > player2['mpRandomValue']) return (1);
            else if (player1['mpRandomValue'] < player2['mpRandomValue']) return (-1);
        }
    }
    
    // ok, so it's ties all the way down...lower userid wins
    if (p1Id < p2Id) return (1);
    else return (-1);
}

function eliminationTournamentSorter(player1, player2) {
    // eslint-disable-next-line no-unused-vars
    let tournament = player1['tournament_data'];
    let p1Id = player1.user_id;
    let p2Id = player2.user_id;

    // whoever has the lowest seed wins
    if (player1.users_tournaments.seed < player2.users_tournaments.seed) return (1);
    else if (player1.users_tournaments.seed > player2.users_tournaments.seed) return (-1);
    
    // lower userid wins
    if (p1Id < p2Id) return (1);
    else return (-1);
}

export function generatePairings(tournament, tournamentRound, hasPlayedDict, recordDict, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict, contactsDict, pairingMessages) {
    // tables_list needs to be filled with table dicts based on the tournament type and what round its on...
    let tablesList = [];

    // bye_tables_list is identical to tables_list, but filled with byes to be added at the end of tables_list
    let byeTablesList = [];

    // the players_list is all players eligible to play in the round
    let playersList = [];

    // the players_id_list is all player ids eligible to play in the round
    let playersIdList = [];

    // the has_table_list list is all players who have been given a table assignment this round (user ids only)
    let hasTableList = [];

    // usernames list is just a mapping from user ids to usernames for messages
    let userId2Username = {};

    // build the players_list - skip those that have dropped
    for (const usersTournaments of tournament.users_tournaments) {
        if (!usersTournaments.dropped) {
            playersList.push(usersTournaments);
            playersIdList.push(usersTournaments.user_id);
        }
    }

    // build the userIdTable
    for (const userData of tournament.users) {
        userId2Username[userData.id] = userData.username;
    }

    // create round-robin tables
    if (tournament.type === 'round-robin') {
        // to generate RR pairings we use a 'rotating' algorithm - this example is for 7 players (note the bye - BB)
        // to do the rotation, the last player from the top row becomes the last player on the bottom row
        // and then the first player in the bottom row moves up to the second player in the top row, the first
        // player in the top row never moves. The bye is essentially a false player
        // Round 1: P1 P2 P3 P4   Round 2: P1 P5 P2 P3   Round 2: P1 P6 P5 P2
        //          P5 P6 P7 BB            P6 P7 BB P4            P7 BB P4 P3
        let initialPlayerList = [];
        for (const users_tournaments of tournament.users_tournaments) {
            initialPlayerList.push(users_tournaments.user_id);
        }
        if (initialPlayerList.length % 2 !== 0) {
            initialPlayerList.push(0);
        }

        let topRowList = [];
        let botRowList = [];
        for (let i=0; i<initialPlayerList.length; i++) {
            let user_id = initialPlayerList[i];
            if (i < initialPlayerList.length / 2) {
                topRowList.push(user_id);
            }
            else {
                botRowList.push(user_id);
            }
        }

        // rotate the rows - round 1 there is no rotation
        for (let i=0; i<tournamentRound.index; i++) {
            let moveDownId = topRowList.pop();
            let moveUpId = botRowList.shift();
            botRowList.push(moveDownId);
            topRowList.splice(1, 0, moveUpId);
        }

        // create games
        let tableIndex = 1;
        for (let i=0; i<topRowList.length; i++) {
            let user1Id = topRowList[i];
            let user2Id = botRowList[i];

            // if either player has dropped, make a bye - this will be the league organizer's problem...
            for (const users_tournaments of tournament.users_tournaments) {
                // eslint-disable-next-line eqeqeq
                if (users_tournaments.user_id == user1Id && users_tournaments.dropped) {
                    user1Id = 0;
                }
                // eslint-disable-next-line eqeqeq
                if (users_tournaments.user_id == user2Id && users_tournaments.dropped) {
                    user2Id = 0;
                }
            }

            // if there are 2 players, create a regular game
            if (user1Id && user2Id) {
                const table_dict = buildTableDict(user1Id, user2Id, `Table ${tableIndex}`, 0, '');
                tablesList.push(table_dict);
                hasTableList.push(user1Id);
                hasTableList.push(user2Id);
                tableIndex += 1;
            }
            // if player 1 is a bye, create a bye
            else if (user1Id) {
                const table_dict = buildTableDict(user1Id, 0, 'No Table', 0, '');
                byeTablesList.push(table_dict);
                hasTableList.push(user1Id);
            }
            // if player 2 is a bye, create a bye
            else if (user2Id) {
                const table_dict = buildTableDict(user2Id, 0, 'No Table', 0, '');
                byeTablesList.push(table_dict);
                hasTableList.push(user2Id);
            }
        }
    }

    else if (tournament.type === 'swiss') {
        let tableIndex = 1;

        // sortedUserIds is the user IDs that are used to generate pairings - tiebreakers are not considered
        let sortedUserIds = null;

        // rankedUserIds are not for pairings, but for other situations where player's rank matters - in this case tiebreakers matter
        let rankedUserIds = null;

        // round 1 the players are randomized
        if (tournamentRound.index === 1) {
            const initialUserIds = [];
            for (const usersTournaments of playersList) {
                initialUserIds.push(usersTournaments.user_id);
            }
            // assign players to the list randomly
            sortedUserIds = [];
            for (let i=0; i<playersList.length; i++) {
                const index = getRandomInteger(0, initialUserIds.length);
                sortedUserIds.push(initialUserIds[index]);
                initialUserIds.splice(index, 1);
            }
            // look for contacts at the same table, then swap them if needed - it could be possible that there are no solutions, so bail out after 10 attempts
            for (let attempt=0; attempt<10; attempt++) {
                let didSwap = false;
                for (let index=0; index<sortedUserIds.length; index+=2) {
                    let p1Id = sortedUserIds[index];
                    let p2Id = 0;
                    if (index+1 < sortedUserIds.length) p2Id = sortedUserIds[index+1];
                    
                    // if either player is a bye then this table doesn't have a contact problem
                    if (p1Id === 0 || p2Id === 0) continue;

                    // if either player isn't in the contacts dict, they have no contacts and therefore there is no problem
                    if (!contactsDict.hasOwnProperty(p1Id)) continue;
                    if (!contactsDict.hasOwnProperty(p2Id)) continue;

                    // ok could be contacts - randomly swap players
                    if (contactsDict[p1Id].includes(p2Id) || contactsDict[p2Id].includes(p1Id)) {
                        didSwap = true;
                        let randomIndexToSwap = getRandomInteger(0, sortedUserIds.length);
                        let randomId = sortedUserIds[randomIndexToSwap];
                        sortedUserIds[randomIndexToSwap] = p1Id;
                        sortedUserIds[index] = randomId;
                    }
                }
                // if we did no swaps then can quit looking, no contacts are paired
                if (didSwap === false) break;
            }

        }
        // after round 1, pairing comes from the ranked list of players
        else {
            sortedUserIds = sortTournamentPlayers(tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, true);
        }

        // determine the ranking (just in case its needed)
        if (tournamentRound.index === 1) {
            rankedUserIds = [...sortedUserIds];
        } else {
            rankedUserIds = sortTournamentPlayers(tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, false);
        }

        // if there is going to be a bye, need to predict that and assign it to the lowest ranked player with the least byes
        let byeUserId = null;
        let minByeCount = -1;
        if (playersList.length % 2 !== 0) {
            // first figure out the 'least byes'
            for (const userId of sortedUserIds) {
                // ignore dropped players
                if (!playersIdList.includes(userId)) continue;
                // ignore first or second ranked if championship match is enabled
                if (tournament.table_top_finals && rankedUserIds.length >= 2 && (rankedUserIds[0] === userId || rankedUserIds[1] === userId)) continue;
                // if this is the first eligable player, they determine the initial 'least byes'
                if (minByeCount === -1) {
                    minByeCount = numberOfByesDict[userId];
                }
                else if (numberOfByesDict[userId] < minByeCount) {
                    minByeCount = numberOfByesDict[userId]
                }
            }
            // now find the lowest ranked player with that number of byes
            const reverseSortedIds = [...rankedUserIds];
            reverseSortedIds.reverse();
            for (const userId of reverseSortedIds) {
                // don't assign the bye to a dropped player
                if (!playersIdList.includes(userId)) continue;
                // also don't assign the bye to first or second ranked if table_top_finals is enabled
                if (tournament.table_top_finals && rankedUserIds.length >= 2 && (rankedUserIds[0] === userId || rankedUserIds[1] === userId)) continue;
                if (numberOfByesDict[userId] === minByeCount) {
                    byeUserId = userId;
                    break
                } else {
                    pairingMessages.push(`${userId2Username[userId]} should be assigned a BYE, they already have one.`);
                }
            }
        }

        // for swiss, we place players form highest to lowest
        while (hasTableList.length < playersList.length) {
            // find the highest rated unseated player
            let user1Id = null;
            let user2Id = null;
            for (const userId of sortedUserIds) {
                if (hasTableList.includes(userId)) continue;
                if (!playersIdList.includes(userId)) continue;
                user1Id = userId;
                break;
            }

            // somehow we couldn't get a player - exit this loop
            if (user1Id === null) break;

            // find an opponent who is not already assigned a table, and who this player has not played before
            for (const userId of sortedUserIds) {
                // this is a goofy way to do this, but if user1 was the bye player, stop looking through this loop
                if (user1Id === byeUserId ) break;

                // don't create tables where players play themselves, the bye player or seat a player twice
                if (user1Id === userId) continue;
                if (hasTableList.includes(userId)) continue;

                // don't create tables where one of the players is dropped
                if (!playersIdList.includes(userId)) {
                    pairingMessages.push(`Should pair ${userId2Username[user1Id]} vs ${userId2Username[userId]}, but they dropped.`);
                    continue;
                }

                // exception to basically all the remaining rules - if table_top_finals is enabled, we want 1 & 2 to play each other
                if (tournament.table_top_finals && rankedUserIds.length >= 2 && user1Id === rankedUserIds[0]) {
                    user2Id = rankedUserIds[1];
                    break;
                }

                // don't create tables where one of the players is the bye player
                if (userId === byeUserId) {
                    pairingMessages.push(`Should pair ${userId2Username[user1Id]} vs ${userId2Username[userId]}, they have a BYE.`);
                    continue;
                }

                // don't create tables where players play someone they've already played
                if (hasPlayedDict[user1Id].includes(userId)) {
                    pairingMessages.push(`Should pair ${userId2Username[user1Id]} vs ${userId2Username[userId]}, but already played.`);
                    continue;
                }

                // if no user has been found, then take this one
                user2Id = userId;
                break;
            }

            // if by this point an eligible opponent has been found, create the table and mark these players as assigned
            if (user1Id && user2Id) {
                const table_dict = buildTableDict(user1Id, user2Id, `Table ${tableIndex}`, 0, '');
                tablesList.push(table_dict);
                hasTableList.push(user1Id);
                hasTableList.push(user2Id);
                tableIndex += 1;
            }
            // if we get here and no opponent was assigned, then this player needs a bye (e.g. odd number of players)
            else {
                if (user1Id !== byeUserId) pairingMessages.push(`Cannot pair ${userId2Username[user1Id]} per swiss rules, TO pair manually.`);
                const table_dict = buildTableDict(user1Id, 0, 'No Table', 0, '');
                byeTablesList.push(table_dict);
                hasTableList.push(user1Id);
            }
        }
    }

    tablesList = tablesList.concat(byeTablesList);
    return (tablesList);
}

export function buildTableDict(user1_id, user2_id, table_name, game_id, elim_code) {
    const table_dict = {
        user1Id: user1_id,
        user2Id: user2_id,
        playersList: [user1_id, user2_id],
        tableName: table_name,
        gameId: game_id,
        elimCode: elim_code
    };
    return table_dict;
}

export function allRoundGamesAreCompleteAndVerified(tournamentRound) {
    for (const gameData of tournamentRound.games) {
        if (gameData.state !== 'posted') return false;
        if (gameData.completion !== 'completed') return false;
        for (const usersGames of gameData.users) {
            if (usersGames.concur_with_results === false) return false;
        }
    }
    return true;
}

export function evaluateRoundGames(tournamentRound) {
    const statusDict = {'totalGames': 0, 'draft': 0, 'complete': 0, 'reviewed': 0, 'ties': 0, 'scored': 0, 'byes': 0};
    for (const gameData of tournamentRound.games) {
        statusDict['totalGames'] += 1;
        if (gameData.state !== 'posted') {
            statusDict['draft'] += 1;
            continue;
        }

        if (gameData.completion !== 'completed') continue;
        else statusDict['complete'] += 1;

        if (gameData.bye) statusDict['byes'] += 1;

        // evaluate the users_games part
        let allReviewed = true
        let winsRecorded = 0
        let scoreRecorded = false

        for (const usersGames of gameData.players) {
            if (usersGames.concur_with_results === false) allReviewed = false;
            if (usersGames.winner) winsRecorded += 1;
            if (usersGames.score !== 0) scoreRecorded = true;
        }

        if (allReviewed) statusDict['reviewed'] += 1;
        if (winsRecorded !== 1 && !gameData.bye) statusDict['ties'] += 1;
        if (scoreRecorded) statusDict['scored'] += 1;
    }
    return statusDict;
}

export function generateTournamentCheckinCode(tournamentId) {
    let code = md5(`tournament-${tournamentId}`);
    code = code.slice(0, 3) + '-' + code.slice(3, 6);
    code = code.toUpperCase();
    return code;
}