import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import pluralize from 'pluralize';
import { Navigate } from 'react-router-dom';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderVerticalScroller } from './NerdHerderScroller';
import { getStorageFilePublicUrl, getFileUiIconUrl, capitalizeFirstLetter, getStaticStorageImageFilePublicUrl } from '../utilities';
import { NerdHerderUserPickerModal, NerdHerderListUploadModal, NerdHerderMessageModal, NerdHerderConfirmModal } from './NerdHerderModals';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './NerdHerderStandardCardTemplate';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';
import { Required } from './NerdHerderBadge';
import { NerdHerderToolTipButton} from './NerdHerderToolTip';


export class NerdHerderListUploadCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='NerdHerderListUploadCard'>
                <NerdHerderListUploadCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class NerdHerderListUploadCardInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.leagueId === 'undefined') console.error('missing props.leagueId');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.presentation = 'league';
        if (this.props.tournamentId) {
            this.presentation = 'tournament';
        } else if (this.props.eventId) {
            this.presentation = 'event';
        }

        // we do special stuff for MCP and SWL
        this.isMCP = false;
        this.isSWL = false;

        // on-time switch to show players rosters
        this.switchToListViewDone = false;

        this.state = {
            navigateTo: null,
            showShareFileModal: false,
            shareContainerId: null,
            showListUploadModal: false,
            showWhichView: 'upload',      // can be upload or list
            uploadContainerId: null,
            showClipboardMessageModal: false,
            shareFileId: null,
            leagueId: this.props.leagueId,
            league: null,
            eventId: this.props.eventId || null,
            event: null,
            tournamentId: this.props.tournamentId || null,
            tournament: null,
            listContainerIds: [],
            listContainers: [],
            typeWord: 'league',
            updating: false,
            hasError: false,
            userFeedback: null,
        };
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('leagues', this.state.leagueId, (d, k)=>{this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
        if (this.state.eventId) {
            let sub = this.restPubSub.subscribeToFirestore('events', this.state.eventId, (d, k)=>{this.updateEvent(d, k)});
            this.restPubSubPool.add(sub);
        }
        if (this.state.tournamentId) {
            let sub = this.restPubSub.subscribeToFirestore('tournaments', this.state.tournamentId, (d, k)=>{this.updateTournament(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague});
        
        if (this.presentation === 'league') {
            // the league provides list_container_ids and list_containers for the league, as well as tournaments, and events in the league
            //...so need to only include those without events or tournaments
            const reducedListContainerIds = [];
            const reducedListContainers = [];
            for (const listContainer of newLeague.list_containers) {
                if (listContainer.tournament_id === null && listContainer.event_id === null) {
                    reducedListContainerIds.push(listContainer.id);
                    reducedListContainers.push(listContainer);
                }
            }
            this.setState({listContainerIds: reducedListContainerIds, listContainers: reducedListContainers, typeWord: 'league'});
        }

        // if this was the update that matters for our presentation, clear the update flag
        if (this.presentation === 'league') {
            this.setState({updating: false});
        }
    }

    updateEvent(eventData, key) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: newEvent});
        if (this.presentation === 'event') {
            this.setState({listContainerIds: newEvent.list_container_ids, listContainers: newEvent.list_containers, typeWord: 'event'});
        }

        // if this was the update that matters for our presentation, clear the update flag
        if (this.presentation === 'event') {
            this.setState({updating: false});
        }
    }

    updateTournament(tournamentData, key) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState({tournament: newTournament});
        if (this.presentation === 'tournament') {
            this.setState({listContainerIds: newTournament.list_container_ids, listContainers: newTournament.list_containers, typeWord: 'tournament'});
        }

        // make sure the event and tournament match
        if (newTournament.event_id !== this.state.eventId) {
            this.setState({eventId: newTournament.event_id});
            let sub = this.restPubSub.subscribeNoRefresh('event', newTournament.event_id, (d, k)=>{this.updateEvent(d, k)});
            this.restPubSubPool.add(sub);
        }

        // if this was the update that matters for our presentation, clear the update flag
        if (this.presentation === 'tournament') {
            this.setState({updating: false});
        }
    }

    refresh(delay=0) {
        switch(this.presentation) {
            case 'league':
                this.restPubSub.refresh('league', this.state.leagueId, delay);
                break;
            case 'event':
                this.restPubSub.refresh('event', this.state.eventId, delay);
                break;
            case 'tournament':
                this.restPubSub.refresh('tournament', this.state.tournamentId, delay);
                break;
            default:
                console.error(`got invalid this.presentation: ${this.presentation}`);
        }
    }

    onShowFile(containerId, fileId, href, event) {
        window.open(href, '_blank');
        event.stopPropagation();
    }

    onCopyTtsData(ttsCodes) {
        navigator.clipboard.writeText(ttsCodes);
        this.setState({showClipboardMessageModal: true});
    }

    onDeleteFile(containerId, fileId, event) {
        this.setState({updating: true});
        this.restPubSub.delete('file', fileId);
        this.refresh(1000);
        event.stopPropagation();
    }

    onShareFile(containerId, fileId, event) {
        this.setState({showShareFileModal: true, shareContainerId: containerId, shareFileId: fileId});
        event.stopPropagation();
    }

    onAcceptShareFileModal(userId) {
        this.restPubSub.patch('file', this.state.shareFileId, {share_user_id: userId});
        this.setState({showShareFileModal: false, shareContainerId: null, shareFileId: null});
    }

    onCancelShareFileModal() {
        this.setState({showShareFileModal: false, shareContainerId: null, shareFileId: null});
    }

    onAcceptListUploadModal() {
        this.refresh(1000);
        this.setState({showListUploadModal: false, uploadContainerId: null, updating: true});
    }

    onCancelListUploadModal() {
        this.setState({showListUploadModal: false, uploadContainerId: null});
    }

    onAcceptLockWarningModal() {
        this.setState({showListUploadModal: true, showLockWarningModal: false});
    }

    onCancelLockWarningModal() {
        this.setState({showListUploadModal: false, showLockWarningModal: false, uploadContainerId: null});
    }

    onClickUploadButton(containerId, lockOnSubmit) {
        if (lockOnSubmit) {
            this.setState({uploadContainerId: containerId, showLockWarningModal: true});
        } else {
            this.setState({uploadContainerId: containerId, showListUploadModal: true});
        }
    }

    onSwitchView(view) {
        this.setState({showWhichView: view});
    }

    render() {
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);
        if (this.state.league === null) return(<NerdHerderLoadingCard title="Player Lists..."/>);
        const listNoun = this.props.league.topic.list_noun;
        const listNounCaps = capitalizeFirstLetter(listNoun);
        const listNounPlural = pluralize(listNoun);
        const listNounCapsPlural = pluralize(listNounCaps);
        if (this.state.eventId && this.state.event === null) return(<NerdHerderLoadingCard title={`Player ${listNounCapsPlural}...`}/>);
        if (this.state.tournamentId && this.state.tournament === null) return(<NerdHerderLoadingCard title={`Player ${listNounCapsPlural}...`}/>);

        let localUserIsPlayer = false;
        let localUserIsManager = false;
        let userIdList = null;
        switch(this.presentation) {
            case 'league':
                userIdList = this.state.league.player_ids;
                localUserIsPlayer = this.state.league.isPlayer(this.props.localUser.id);
                localUserIsManager = this.state.league.isManager(this.props.localUser.id);
                break;
            case 'event':
                userIdList = this.state.event.player_ids;
                localUserIsPlayer = this.state.event.isPlayer(this.props.localUser.id);
                localUserIsManager = this.state.event.isManager(this.props.localUser.id);
                break;
            case 'tournament':
                userIdList = this.state.tournament.player_ids;
                localUserIsPlayer = this.state.tournament.isPlayer(this.props.localUser.id);
                localUserIsManager = this.state.tournament.isManager(this.props.localUser.id);
                break;
            default:
                console.error(`got invalid presentation: ${this.presentation}`);
        }

        const listContainerIds = this.state.listContainerIds;
        const listContainers = this.state.listContainers;
        // if there are no containers, just hide the card
        if (listContainerIds.length === 0) return(null);

        // if there are containers, but they are all draft we'll just have a little message
        let draftOnlyMessage = <p>This {this.state.typeWord} doesn't accept any {listNounPlural}...yet.</p>
        for (const listContainer of listContainers) {
            if (listContainer.state === 'posted') {
                draftOnlyMessage = null;
                break;
            }
        }

        // we add a special blurb when files have tts codes in the JSX section...most of the time there aren't any
        let someFilesHaveTts = false;
        const ttsIconUrl = getStaticStorageImageFilePublicUrl('/tts.png');

        // this is the Your Lists section
        let playerMissingRequiredUpload = false;
        let playerMissingOptionalUpload = false;
        const uploadContainerRows = [];
        const displayContainerRows = [];
        if (draftOnlyMessage === null) {
            if (localUserIsPlayer) {
                for (const listContainer of listContainers) {
                    if (listContainer.state === 'posted') {
                        const containerId = listContainer.id;
                        let userHasUploaded = false;
                        let usersFileData = null;
                        for (const fileData of listContainer.files) {
                            if (fileData.user_id === this.props.localUser.id) {
                                usersFileData = fileData;
                                userHasUploaded = true;
                                break;
                            }
                        }
                        // if the user has uploaded, give them a button to see what they've uploaded, if not give them a button to open the upload modal
                        let row = null;
                        if (userHasUploaded) {
                            let href = getStorageFilePublicUrl(`/${usersFileData.path}`);
                            let iconUrl = getFileUiIconUrl(usersFileData.path);
                            let extraDescription = null;
                            if (listContainer.hidden && usersFileData.locked) {
                                extraDescription = <small className='text-muted'> (hidden & locked)</small>
                            } else if (listContainer.hidden) {
                                extraDescription = <small className='text-muted'> (hidden)</small>
                            } else if (usersFileData.locked) {
                                extraDescription = <small className='text-muted'> (locked)</small>
                            }
                            row = 
                                <Form.Group key={containerId} className="form-outline mb-3">
                                    <b>{listContainer.name}</b>{extraDescription}
                                    {listContainer.description && listContainer.description.length !== 0 &&
                                    <div>
                                        <Form.Text muted>{listContainer.description}</Form.Text>
                                    </div>}
                                        <div className="list-group-item list-group-item-action rounded align-middle" onClick={(e)=>this.onShowFile(containerId, usersFileData.id, href, e)}>
                                            <Row>
                                                <Col xs='auto' className="text-center px-0 mx-0">
                                                    <img src={iconUrl} alt="file icon" height="30"/>
                                                </Col>
                                                <Col>
                                                    <b>{usersFileData.filename}</b>
                                                </Col>
                                                <Col xs='auto' className="text-center px-0 mx-0">
                                                    <NerdHerderToolTipButton size='sm' variant='primary' disabled={this.state.updating} onClick={(e)=>this.onShareFile(containerId, usersFileData.id, e)} icon="flaticon-share" tooltipText={`share ${listNoun}`}/>
                                                </Col>
                                                {usersFileData.locked === false &&
                                                <Col xs='auto' className="text-center ps-1 pe-0 mx-0">
                                                    <NerdHerderToolTipButton size='sm' variant='danger' disabled={this.state.updating} onClick={(e)=>this.onDeleteFile(containerId, usersFileData.id, e)} icon="flaticon-recycle-bin-filled-tool" tooltipText={`delete ${listNoun}`}/>
                                                </Col>}
                                            </Row>
                                        </div>
                                </Form.Group>
                        }

                        // if the user has not uploaded, give them a button to do the upload
                        else {
                            if (listContainer.required) {
                                playerMissingRequiredUpload = true;
                            } else {
                                playerMissingOptionalUpload = true;
                            }
                            let extraDescription = null;
                            if (listContainer.hidden && listContainer.lock_on_submit) {
                                extraDescription = <small className='text-muted'> (hidden & locking)</small>
                            } else if (listContainer.hidden) {
                                extraDescription = <small className='text-muted'> (hidden)</small>
                            } else if (listContainer.lock_on_submit) {
                                extraDescription = <small className='text-muted'> (locking)</small>
                            }
                            row = 
                                <Form.Group key={containerId} className="form-outline mb-2">
                                    <Row className='align-items-center'>
                                        <Col>
                                            <b>{listContainer.name}</b>{listContainer.required && <Required/>}{extraDescription}
                                            {listContainer.description && listContainer.description.length !== 0 &&
                                            <div>
                                                <Form.Text muted>{listContainer.description}</Form.Text>
                                            </div>}
                                            {listContainer.required &&
                                            <p>You have not uploaded a <span className='text-danger'>required</span> {listNoun}. Click the button to upload one!</p>}
                                            {!listContainer.required &&
                                            <p>You have not uploaded a {listNoun} yet. Click the button to upload one!</p>}
                                        </Col>
                                        <Col xs='auto'>
                                            <NerdHerderToolTipButton variant='primary' disabled={this.state.updating} onClick={()=>this.onClickUploadButton(containerId, listContainer.lock_on_submit)} icon='flaticon-upload' tooltipText={`upload and submit ${listNoun}`}/>
                                        </Col>
                                    </Row>
                                </Form.Group>
                        }
                        uploadContainerRows.push(row);
                    }
                }
            }

            // this is the Player Lists section
            for (const listContainer of listContainers) {
                if (listContainer.hidden) continue;
                const tableRows = [];
                if (listContainer.state === 'posted') {
                    const containerId = listContainer.id;
                    for (const fileData of listContainer.files) {
                        let href = getStorageFilePublicUrl(`/${fileData.path}`);
                        let iconUrl = getFileUiIconUrl(fileData.path);
                        if (fileData.tts_codes !== null) someFilesHaveTts = true;
                        let lockedIcon = null;
                        if (fileData.locked) lockedIcon = <span> <small className='text-muted'><NerdHerderFontIcon icon='flaticon-locked-black-rectangular-padlock'/></small></span>
                        const row = 
                            <tr key={`${listContainer.id}-${fileData.id}`}>
                                <td>
                                    {fileData.uploaded_by}{lockedIcon}
                                </td>
                                {!fileData.tts_codes &&
                                <td align='right'>
                                    <div className='cursor-pointer' onClick={(e)=>this.onShowFile(containerId, fileData.id, href, e)}>
                                        <span className='cursor-pointer' onClick={(e)=>this.onShowFile(containerId, fileData.id, href, e)}>
                                            <img src={iconUrl} alt="file icon" height="25"/>
                                        </span>
                                    </div>
                                </td>}
                                {fileData.tts_codes &&
                                <td align='right'>
                                    <div>
                                        <span className='cursor-pointer' onClick={(e)=>this.onCopyTtsData(fileData.tts_codes)}>
                                            <Image src={ttsIconUrl} rounded={true} alt="tts icon" height="25"/>
                                        </span>
                                        <span className='cursor-pointer' onClick={(e)=>this.onShowFile(containerId, fileData.id, href, e)}>
                                            <img src={iconUrl} alt="file icon" height="25"/>
                                        </span>
                                    </div>
                                </td>}
                            </tr>
                        tableRows.push(row);
                    }
                    const section = 
                        <Row key={`${listContainer.id}-sectionHeader`}>
                            <Col>
                                <b>{listContainer.name}</b>
                                {tableRows.length !== 0 &&
                                <div>
                                    <NerdHerderVerticalScroller maxHeight={300}>
                                        <Table striped>
                                            <tbody>
                                                {tableRows}
                                            </tbody>
                                        </Table>
                                    </NerdHerderVerticalScroller>
                                </div>}
                                {tableRows.length === 0 &&
                                <div>
                                    <small className='text-muted'>Nobody has uploaded a {listNoun} in this category yet</small>
                                </div>}
                            </Col>
                        </Row>
                    displayContainerRows.push(section)
                }
            }
        }

        let uploadListContainer = null;
        if (this.state.showListUploadModal) {
            for (const listContainer of this.state.listContainers) {
                if (listContainer.id === this.state.uploadContainerId) {
                    uploadListContainer = listContainer;
                    break;
                }
            }
        }

        // determine which tab is shown / disabled
        let uploadDisabled = false;
        let listDisabled = false;
        let showWhichView = this.state.showWhichView;
        if (!localUserIsPlayer) uploadDisabled = true;
        if (this.state.league.state === 'complete' || this.state.league.state === 'archived') uploadDisabled = true;
        if (playerMissingRequiredUpload) listDisabled = true;
        if (uploadDisabled && showWhichView === 'upload') showWhichView = 'list';
        if (listDisabled && showWhichView === 'list') showWhichView = 'upload';
        if (uploadDisabled && listDisabled) return (null);
        if (showWhichView !== this.state.showWhichView) setTimeout(()=>this.setState({showWhichView: showWhichView}));

        // do a one-time switch to list view if all the player's lists are uploaded
        if (!this.switchToListViewDone && !playerMissingOptionalUpload && !playerMissingRequiredUpload) {
            this.switchToListViewDone = true;
            setTimeout(()=>this.setState({showWhichView: 'list'}));
        }

        let manageOptions = null;
        if (this.state.league.isManager(this.props.localUser.id) && this.state.league.state !== 'archived') {
            manageOptions = {
                url: `/app/manageleague/${this.state.league.id}`,
                focusTab: 'game',
                focusCard: 'manage-lists-card',
            }
        }

        return (
            <NerdHerderStandardCardTemplate id="player-lists-card" title={`Player ${listNounCapsPlural}`} titleIcon='checklist.png' manageOptions={manageOptions}>
                {this.state.showShareFileModal &&
                <NerdHerderUserPickerModal title={`Share ${listNounCaps}`}
                                           bodyText={`Select a user to share your ${listNoun} with`}
                                           userIdList={userIdList}
                                           onCancel={()=>this.onCancelShareFileModal()}
                                           onAccept={(userId)=>this.onAcceptShareFileModal(userId)}
                                           localUser={this.props.localUser}/>}
                {this.state.showLockWarningModal &&
                <NerdHerderConfirmModal title='Lock On Submit'
                                        message={`The ${listNoun} you submit here will be locked in. You will not be able to change this ${listNoun} later (without contacting an organizer). Continue?`}
                                        onCancel={()=>this.onCancelLockWarningModal()}
                                        onAccept={()=>this.onAcceptLockWarningModal()}
                                        acceptButtonText='Continue'
                                        localUser={this.props.localUser}/>}
                {this.state.showListUploadModal &&
                <NerdHerderListUploadModal league={this.props.league}
                                           listContainer={uploadListContainer}
                                           onCancel={()=>this.onCancelListUploadModal()}
                                           onAccept={(filename)=>this.onAcceptListUploadModal(filename)}
                                           localUser={this.props.localUser}/>}
                {this.state.showClipboardMessageModal &&
                <NerdHerderMessageModal title='Copied To Clipboard'
                                        message='Tabletop Simulator codes have been copied to the Clipboard'
                                        autoCancelDuration={2000}
                                        onCancel={()=>this.setState({showClipboardMessageModal: false})}/>}
                {draftOnlyMessage}
                {!draftOnlyMessage &&
                <div>
                    <Row>
                        <Col xs={12}>
                            <Form.Group className='mb-2'>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='file-upload-view' type="radio" value={showWhichView} onChange={(event)=>this.onSwitchView(event)}>
                                        <ToggleButton variant='outline-primary' id='toggle-list-view' disabled={listDisabled} value={'list'}>Player {listNounCapsPlural}</ToggleButton>
                                        <ToggleButton variant='outline-primary' id='toggle-upload-view' disabled={uploadDisabled} value={'upload'}>Your {listNounCapsPlural}</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {showWhichView==='list' &&
                    <div>
                        <Row>
                            <Col>
                                <small className='text-muted'>Below you will find {listNounPlural} uploaded by players.</small>
                            </Col>
                        </Row>
                        {someFilesHaveTts &&
                        <Row className='my-2 align-items-center'>
                            <Col className='pe-0' xs='auto'>
                                <Image src={ttsIconUrl} rounded={true} alt="tts icon" height="20"/>
                            </Col>
                            <Col>
                                <small className='text-muted'>These {listNounPlural} have Tabletop Simulator codes - click these icons in the tables below to copy the codes to the clipboard.</small>
                            </Col>
                        </Row>}
                        <Row>
                            <Col xs={12}>
                                {displayContainerRows}
                            </Col>
                        </Row>
                    </div>}
                    {showWhichView==='upload' &&
                    <div>
                        {playerMissingRequiredUpload &&
                        <Alert variant='warning'>After you upload all required {listNounPlural}, Player {listNounCapsPlural} will be viewable</Alert>}
                        <Row>
                            <Col xs={12}>
                                <small className='text-muted'>Here you can upload and share your {listNounPlural}.</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {uploadContainerRows}
                            </Col>
                        </Row>
                    </div>}
                </div>}
            </NerdHerderStandardCardTemplate>
        )
    }
}
