import React from 'react';
import axios from 'axios';
import { Dropzone } from 'dropzone';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { getRandomString } from '../utilities';

export class NerdHerderDropzoneImageUploader extends React.Component {

    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();

        if (typeof this.props.localUser === 'undefined') console.error('localUser not included in props.localUser');
        if (typeof this.props.message === 'undefined') console.error('message not included in props.message');

        this.dzId = `dz-div-${getRandomString(10)}`;
        this.dzObj = null;
        
        if (typeof this.props.uploadUrl === 'undefined') {
            this.uploadUrl = '/rest/v1/dz-image-upload';
            if (this.props.crop) {
                this.uploadUrl = '/rest/v1/dz-image-upload-crop';
            }
        } else {
            this.uploadUrl = this.props.uploadUrl;
        }

        // if running in NPM on port 3000, switch to port 8080 for localhost testing
        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        let portnum = window.location.port;
        if (hostname === 'localhost' && portnum === '3000') {
            portnum = 8080;
        }
        this.uploadUrl = `${protocol}//${hostname}:${portnum}${this.uploadUrl}`;
        this.uploadedFiles = [];

        this.state = {
            highlight: false,
            errorFeedback: null,
        }
    }

    componentDidMount() {
        let self = this;
        const headers = this.restApi.getApiHeaders();

        this.dzObj = new Dropzone(`div#${this.dzId}`, {
            paramName: "file",
            maxFilesize: 20, // MB
            maxFiles: this.props.maxFiles || 12,
            resizeWidth: 800,
            resizeHeight: 800,
            resizeMethod: "contain",
            acceptedFiles: "image/*",
            addRemoveLinks: true,
            url: self.uploadUrl,
            headers: headers,
    
            init: function() {
                console.debug('running DZ init function')
                this.on("addedfile", (f)=>self.addedFile(f));
                this.on("removedfile", (f)=>self.removedFile(f));
                this.on("sending", (f)=>self.sending(f));
                this.on("success", (f)=>self.success(f));
                this.on("error", (f)=>self.error(f));
                this.on("dragover", ()=>self.onDragEnter());
                this.on("dragleave", ()=>self.onDragLeave());
            }
        });  
    }

    componentWillUnmount() {
    }

    getUploadedFiles() {
        return this.uploadedFiles;
    }

    clearUploadedFiles() {
        this.dzObj.removeAllFiles();
        this.uploadedFiles = [];
    }

    addedFile(file) {
        const extension = file.upload.filename.split('.').pop();
        file.upload.filename = (Math.random() + 1).toString(36).substring(7);
        file.upload.filename += '.' + extension;
        console.debug(`a DZ file has been added: ${file.name} as ${file.upload.filename}`);
        if (this.props.addCallback) this.props.addCallback(file);
    }

    removedFile(file) {
        console.debug(`a DZ file has been removed: ${file.name} as ${file.upload.filename}`);
        for (let i=0; i<this.uploadedFiles.length; i++) {
            if (file.upload.filename === this.uploadedFiles[i].upload.filename) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }
        if (this.props.removeCallback) this.props.removeCallback(file);
    }

    sending(file) {
        console.debug("DZ Sending");
        if (this.props.sendingCallback) this.props.sendingCallback(file);
    }

    success(file) {
        console.debug(`a DZ file has been uploaded successfully: ${file.name} as ${file.upload.filename}`);
        this.uploadedFiles.push(file);
        let responseFilename = file.xhr.response.replaceAll('"', '');
        console.log(`responseFilename: ${responseFilename}`);
        if (this.props.successCallback) this.props.successCallback(file, responseFilename);
    }

    error(file) {
        console.debug("a DZ file has upload has failed: " + file.name);
        if (this.props.errorCallback) this.props.errorCallback(file);
    }

    onDragEnter() {
        if (!this.state.highlight) {
            this.setState({highlight: true});
        }
    }

    onDragLeave() {
        if (this.state.highlight) {
            this.setState({highlight: false});
        } 
    }

    render() {
        let style = {
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        };

        if (this.state.highlight) {
            style.borderColor = '#0d6efd';
        }

        return (
            <div className='list-group-item rounded p-3'>
                <div id={this.dzId}
                    className="dropzone text-center"
                    style={style}>
                </div>
            </div>
        )
    }
}

export class NerdHerderDropzoneFileUploader extends React.Component {

    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();

        if (typeof this.props.localUser === 'undefined') console.error('localUser not included in props.localUser');
        if (typeof this.props.message === 'undefined') console.error('message not included in props.message');

        this.dzId = `dz-div-${getRandomString(10)}`;
        this.dzObj = null;
        
        if (typeof this.props.uploadUrl === 'undefined') {
            this.uploadUrl = '/rest/v1/dz-file-upload';
        } else {
            this.uploadUrl = this.props.uploadUrl;
        }

        // if running in NPM on port 3000, switch to port 8080 for localhost testing
        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        let portnum = window.location.port;
        if (hostname === 'localhost' && portnum === '3000') {
            portnum = 8080;
        }
        this.uploadUrl = `${protocol}//${hostname}:${portnum}${this.uploadUrl}`;
        this.uploadedFiles = [];

        this.state = {
            highlight: false,
            errorFeedback: null,
        }
    }

    componentDidMount() {
        let self = this;
        const headers = this.restApi.getApiHeaders();

        this.dzObj = new Dropzone(`div#${this.dzId}`, {
            paramName: "file",
            maxFilesize: 20, // MB
            maxFiles: this.props.maxFiles || 12,
            addRemoveLinks: true,
            url: self.uploadUrl,
            headers: headers,
            init: function() {
                console.debug('running DZ init function')
                this.on("addedfile", (f)=>self.addedFile(f));
                this.on("removedfile", (f)=>self.removedFile(f));
                this.on("sending", (f)=>self.sending(f));
                this.on("success", (f)=>self.success(f));
                this.on("error", (f)=>self.error(f));
                this.on("dragover", ()=>self.onDragEnter());
                this.on("dragleave", ()=>self.onDragLeave());
            }
        });  
    }

    componentWillUnmount() {
    }

    getUploadedFiles() {
        return this.uploadedFiles;
    }

    clearUploadedFiles() {
        this.dzObj.removeAllFiles();
        this.uploadedFiles = [];
    }

    addedFile(file) {
        const extension = file.upload.filename.split('.').pop();
        file.upload.filename = (Math.random() + 1).toString(36).substring(7);
        file.upload.filename += '.' + extension;
        console.debug(`a DZ file has been added: ${file.name} as ${file.upload.filename}`);
        if (this.props.addCallback) this.props.addCallback(file);
    }

    removedFile(file) {
        console.debug(`a DZ file has been removed: ${file.name} as ${file.upload.filename}`);
        for (let i=0; i<this.uploadedFiles.length; i++) {
            if (file.upload.filename === this.uploadedFiles[i].upload.filename) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }
        if (this.props.removeCallback) this.props.removeCallback(file);
    }

    sending(file) {
        console.debug("DZ Sending");
        if (this.props.sendingCallback) this.props.sendingCallback(file);
    }

    success(file) {
        console.debug(`a DZ file has been uploaded successfully: ${file.name} as ${file.upload.filename}`);
        this.uploadedFiles.push(file);
        let responseFilename = file.xhr.response.replaceAll('"', '');
        console.log(`responseFilename: ${responseFilename}`);
        if (this.props.successCallback) this.props.successCallback(file, responseFilename);
    }

    error(file) {
        console.debug("a DZ file has upload has failed: " + file.name);
        if (this.props.errorCallback) this.props.errorCallback(file);
    }

    onDragEnter() {
        if (!this.state.highlight) {
            this.setState({highlight: true});
        }
    }

    onDragLeave() {
        if (this.state.highlight) {
            this.setState({highlight: false});
        } 
    }

    render() {
        let style = {
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        };

        if (this.state.highlight) {
            style.borderColor = '#0d6efd';
        }

        return (
            <div className='list-group-item rounded p-3'>
                <div id={this.dzId}
                    className="dropzone text-center"
                    style={style}>
                </div>
            </div>
        )
    }
}

export function NerdHerderSingleImageUpload(uploadFile, addCallback, sendingCallback, successCallback, errorCallback) {
    const file = {'name': uploadFile.name, 'upload': {filename: uploadFile.name}};
    const extension = file.upload.filename.split('.').pop();
    const restApi = new NerdHerderRestApi();
    file.upload.filename = (Math.random() + 1).toString(36).substring(7);
    file.upload.filename += '.' + extension;
    console.debug(`single image upload file has been added: ${file.name} as ${file.upload.filename}`);
    if (addCallback) addCallback(file);

    let formData = new FormData();
    let fileData = new File([uploadFile], file.upload.filename, {type: uploadFile.type, lastModified: uploadFile.lastModified});
    formData.append('file', fileData);

    // if running in NPM on port 3000, switch to port 8080 for localhost testing
    let uploadUrl = '/rest/v1/dz-image-upload';
    let protocol = window.location.protocol;
    let hostname = window.location.hostname;
    let portnum = window.location.port;
    if (hostname === 'localhost' && portnum === '3000') {
        portnum = 8080;
    }
    uploadUrl = `${protocol}//${hostname}:${portnum}${uploadUrl}`;

    if (sendingCallback) sendingCallback(file);

    const headers = restApi.getApiHeaders();
    axios.post(uploadUrl, formData, {headers: headers})
    .then((response)=>{
        console.log('upload success');
        if (successCallback) successCallback(file, response);
    })
    .catch((error)=>{
        console.error(error);
        if (successCallback) errorCallback(file, error);
    });
}