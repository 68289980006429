import React from 'react';
import withRouter from './withRouter';
import Button from 'react-bootstrap/Button';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderNewMessageModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderBadge } from './nerdherder-components/NerdHerderBadge';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { UserListItem } from './nerdherder-components/NerdHerderListItems';
import { ChatCard } from './nerdherder-components/NerdHerderChat';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';

class MessagesPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.pageTemplateRef = React.createRef();

        // discard any existing subs
        this.restPubSub.clear();

        // get the message id if there is one
        let selectedMessageId = null;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.message_id) {
            selectedMessageId = params.message_id;
        }
        
        this.state = {
            errorFeedback: null,
            selectedMessageId: selectedMessageId,
            selectedUserId: null,
            showNewMessageModal: false,
            isUpdating: false,
            firebaseSigninComplete: false,
            localUser: null,
            messages: {},
            users: {},
            messageCount: {},
            hasUnreadMessage: {},
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        let sub = null;
        this.restPubSub.subscribeGlobalErrorHandler((e, a)=>this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        sub = this.restPubSub.subscribe('self', null, (d, k)=>{this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);

        // get the root messages for this user
        sub = this.restPubSub.subscribe('header-messages', null, (d, k)=>{this.updateHeaderMessage(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});

        // if there was a selected message id, grab it so we can find what user its from
        if (this.state.selectedMessageId) {
            const sub = this.restPubSub.subscribe('message', this.state.selectedMessageId, (d, k)=>{this.updateSelectedMessage(d, k)}, null, this.state.selectedMessageId);
            this.restPubSubPool.add(sub);
        }
    }

    updateSelectedMessage(messageData, messageId) {
        let fromUserId = messageData.from_user_id;
        let toUserId = messageData.to_user_id;
        if (toUserId && fromUserId) {
            if (fromUserId === this.state.localUser.id) {
                this.setState({selectedUserId: toUserId});
                const sub = this.restPubSub.subscribeNoRefresh('user', toUserId, (d, k)=>{this.updateUser(d, k)}, null, toUserId);
                this.restPubSubPool.add(sub);
            }
            else {
                this.setState({selectedUserId: fromUserId});
                const sub = this.restPubSub.subscribeNoRefresh('user', fromUserId, (d, k)=>{this.updateUser(d, k)}, null, fromUserId);
                this.restPubSubPool.add(sub);
            }
        } 
    }

    updateUser(userData, userId) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {users: {...state.users, [userId]: newUser}}
        });
    }

    updateHeaderMessage(response, key) {
        const newMessageCount = {};
        const newHasUnreadMessage = {};
        for (const [key, val] of Object.entries(response.message_numbers_by_user_id)) {
            const keyInt = parseInt(key);
            newMessageCount[keyInt] = parseInt(val);
            newHasUnreadMessage[keyInt] = false;
        }
        for (const val of response.unread_messages_user_ids) {
            const valInt = parseInt(val);
            newHasUnreadMessage[valInt] = true;
        }
        this.setState({messageCount: newMessageCount, hasUnreadMessage: newHasUnreadMessage})
    }

    updateMessage(messageData, key) {
        const newMessage = NerdHerderDataModelFactory('message', messageData);
        this.setState((state) => {
            return {messages: {...state.messages, [newMessage.id]: newMessage}}
        });
        return newMessage;
    }

    onSelectUser(userId) {
        if (!this.state.users.hasOwnProperty(userId)) {
            const sub = this.restPubSub.subscribeNoRefresh('user', userId, (d, k)=>{this.updateUser(d, k)}, null, userId);
            this.restPubSubPool.add(sub);
        }
        this.setState({selectedUserId: userId});
    }

    onNewMessage() {
        this.setState({showNewMessageModal: true});
    }

    onNewMessageSent() {
        this.setState({showNewMessageModal: false});
        this.restPubSub.refresh('header-messages', null, 1000);
    }

    onModalCancel() {
        this.setState({showNewMessageModal: false});
        this.restPubSub.refresh('header-messages', null, 1000);
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);
        if (this.state.selectedUserId && !this.state.users.hasOwnProperty(this.state.selectedUserId)) return (<NerdHerderLoadingModal/>);
        
        let selectedUser = null;
        if (this.state.selectedUserId) selectedUser = this.state.users[this.state.selectedUserId];

        const userListItems = [];

        for (const [userId, numMessages] of Object.entries(this.state.messageCount)) {
            let hasUnread = this.state.hasUnreadMessage[userId];
            let color = 'grey';
            if (hasUnread) color = 'red';
            const item =
                <div key={userId} className="mb-3" style={{position: 'relative'}}>
                    <UserListItem userId={userId} localUser={this.state.localUser} slim={true} onClick={()=>this.onSelectUser(userId)}/>
                    <NerdHerderBadge position='top-right' shape='round' color={color} size='medium' zIndex={2}>{numMessages}</NerdHerderBadge>
                </div>
            
            // put new messages at top, put no new at bottom
            if (hasUnread) userListItems.unshift(item);
            else userListItems.push(item);
        }

        return(
            <NerdHerderStandardPageTemplate ref={this.pageTemplateRef}  pageName='messages' headerSelection='messages'
                                            navPath={[{icon: 'flaticon-new-email-filled-back-envelope', label: 'Messages', href: '/app/messages'}]}
                                            localUser={this.state.localUser}>
                {this.state.showNewMessageModal && <NerdHerderNewMessageModal localUser={this.state.localUser}
                                                                               onAccept={()=>this.onNewMessageSent()}
                                                                               onCancel={()=>this.onModalCancel()}/>}
                {!selectedUser && 
                <NerdHerderStandardCardTemplate id='messages-card'>
                    <div className="mb-3 d-grid gap-2">
                        <Button variant='primary' onClick={()=>this.onNewMessage()}>New Message Chain</Button>
                    </div>
                    {userListItems}
                </NerdHerderStandardCardTemplate>}
                {selectedUser &&
                <ChatCard title={`Messages with ${selectedUser.username}`} user={selectedUser} localUser={this.state.localUser}/>}
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

export default withRouter(MessagesPage);
