import React from 'react';
import withRouter from './withRouter';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import Image from 'react-bootstrap/Image';
import { FormTypeahead } from './nerdherder-components/NerdHerderFormHelpers';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderAddGameModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay, capitalizeFirstLetter, getStaticStorageImageFilePublicUrl } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { GameListItem } from './nerdherder-components/NerdHerderListItems';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { NerdHerderTour } from './nerdherder-components/NerdHerderTour';


class GamesPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.tourSteps = [
            {
                target: '#tour-info',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Game Concept #1',
                content: 
                    <div className='text-start'>
                        NerdHerder has 3 types of games:
                        <br/>
                        <small>
                            <ol>
                                <li>Casual - often pick-up games</li>
                                <li>Minor Event - from narrative events</li>
                                <li>Tournament - which may be ranked</li>
                            </ol>
                        </small>
                        Players normally enter their own casual games, and organizers typically add event games. NerdHerder automatically generates games for tournaments, players just update the game outcome.
                    </div>
            },
            {
                target: '#tour-info',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Game Concept #2',
                content: <span>Games in NerdHerder are recorded within a league, tournament, or event. There's no way to add a game if you're not in one. You can create a private league (or tournament / event) to hold your games if desired!</span>
            },
            {
                target: '#tour-info',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Game Items',
                content:
                    <div>
                        <Image fluid src={getStaticStorageImageFilePublicUrl('/example_game_item.png')} alt='example game item'/>
                        <p>Games will appear similar to the image above - click them to edit or view details</p>
                    </div>
            },
            {
                target: '#add-games-card',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Add Casual Games',
                content: <span>Use this button to easily add a casual game to a league, tournament, or event</span>
            },
            {
                target: 'label[for=toggle-quick-filter]',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Quick Filter',
                content: <span>Filters anyone might use - games you played, upcoming games, games needing review, etc.</span>
            },
            {
                target: 'label[for=toggle-advanced-filter]',
                disableBeacon: true,
                placement: 'bottom',
                title: 'Advanced Filter',
                content: <span>Drill down to a particular league, event, tournament, opponent, game system, etc. Statistics are also provided with this filter.</span>
            }
        ];

        this.state = {
            errorFeedback: null,
            showAddGameModal: false,
            isUpdating: false,
            isSearching: true,
            firebaseSigninComplete: false,
            localUser: null,
            tournaments: {},
            leagues: {},
            gameIdFilterList: [],
            boardGames: null,
            topics: null,
            statistics: null,
            selectedTopic: null,

            showWhichFilter: 'quick',
            showStatsSection: false,
            formQuickFilter: 'O0',
            formLeague: 'any',
            formEvent: 'any',
            formTournament: 'any',
            formCompletion: 'completed',
            formTopic: 'any',
            formBoardGame: 'any',
            formGameType: 'any',
            formUsernameSelected: null,
        }

        // don't immediatly do a search everytime the filter changes
        this.filterTimeout = null;

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('topic', null, (d, k) => {this.updateTopicDict(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('board-game', null, (d, k) => {this.updateBoardGameDict(d, k)});
        this.restPubSubPool.add(sub);

        // get the header alerts - if the user has game issues default to the quick filter for verification
        this.restApi.genericGetEndpointData('header-alerts')
        .then((response)=>{
            const headerAlerts = response.data;
            if (headerAlerts.game_issues_review_count > 0) {
                this.setState({formQuickFilter: 'O2'});
            } else if (headerAlerts.game_issues_schedule_count > 0) {
                this.setState({formQuickFilter: 'O1'});
            }
        })
        .catch((error)=>{
            console.error(error);
        })
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
        for (const leagueId of userData.league_ids) {
            this.setState((state) => {
                return {leagues: {...state.leagues, [leagueId]: null}}
            });
            const sub = this.restPubSub.subscribe('league', leagueId, (d, k) => {this.updateLeague(d, k)}, null, leagueId);
            this.restPubSubPool.add(sub);
        }
        
        for (const tournamentId of userData.tournament_ids) {
            this.setState((state) => {
                return {tournaments: {...state.tournaments, [tournamentId]: null}}
            });
            const sub = this.restPubSub.subscribe('tournament', tournamentId, (d, k) => {this.updateTournament(d, k)}, null, tournamentId);
            this.restPubSubPool.add(sub);
        }

        if (this.filterTimeout !== null) clearTimeout(this.filterTimeout);
            this.filterTimeout = setTimeout(()=>this.triggerSearch(), 1500);
    }

    updateLeague(leagueData, leagueId) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState((state) => {
            return {leagues: {...state.leagues, [leagueId]: newLeague}}
        });
    }

    updateTopicDict(topicList, key) {
        const newTopicDict = {};
        for (const topic of topicList) {
            newTopicDict[topic.id] = topic;
        }
        this.setState({topics: newTopicDict});
    }

    updateBoardGameDict(boardGameDict, key) {
        const newBoardGameDict = boardGameDict;
        this.setState({boardGames: newBoardGameDict});
    }

    updateTournament(tournamentData, tournamentId) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState((state) => {
            return {tournaments: {...state.tournaments, [tournamentId]: newTournament}}
        });
    }

    updateGame(gameData, gameId) {
        const newGame = NerdHerderDataModelFactory('game', gameData);
        this.setState((state) => {
            return {games: {...state.games, [gameId]: newGame}}
        });
    }

    onSwitchFilter(value) {
        // when we switch filters, we can always just re-do the quick search
        // however the advanced one is more difficult, so instead we'll just reset it...
        if (this.filterTimeout !== null) clearTimeout(this.filterTimeout);
        if (value === 'quick') {
            this.setState({showWhichFilter: 'quick', gameIdFilterList: [], isSearching: true});
            this.filterTimeout = setTimeout(()=>this.triggerSearch(), 1500);
        } else {
            this.setState({showWhichFilter: 'advanced', gameIdFilterList: [], isSearching: false,
                           formLeague: 'any', formEvent: 'any', formTournament: 'any', formGameType: 'any',
                           formCompletion: 'completed', formMyGames: false, formUsernameSelected: null});
        }
    }

    onAddGameComplete() {
        this.restPubSub.refresh('self');
        this.triggerSearch();
        this.setState({showAddGameModal: false});
    }

    onAddGameCancel() {
        this.setState({showAddGameModal: false});
    }

    triggerSearch() {
        if (this.state.showWhichFilter === 'quick') {
            this.triggerQuickSearch();
        } else {
            this.triggerAdvancedSearch();
        }
    }

    triggerQuickSearch() {
        // my games
        if (this.state.formQuickFilter === 'O0') {
            let result = this.state.localUser.game_ids;
            this.sortGameResults(result);
            this.updateGameStatistics(null);
            this.setState({gameIdFilterList: result, isSearching: false});
        }

        // my scheduled games
        else if (this.state.formQuickFilter === 'O1') {
            let result1 = null;
            let result2 = null;
            this.setState({gameIdFilterList: [], isSearching: true});

            // search for both scheduled and in-progress games
            const queryParams1 = {
                'user-id': this.state.localUser.id,
                'state': 'posted',
                'completion': 'scheduled'}
            this.restApi.genericGetEndpointData('game', null, queryParams1)
            .then((response)=>{
                result1 = response.data.game_ids;
                if (result2 !== null) {
                    const fullResult = result1.concat(result2);
                    this.sortGameResults(fullResult);
                    this.updateGameStatistics(null);
                    this.setState({gameIdFilterList: fullResult, isSearching: false});
                }
            })
            .catch((error)=>{
                console.error(error);
            });

            const queryParams2 = {
                'user-id': this.state.localUser.id,
                'state': 'posted',
                'completion': 'in-progress'}
            this.restApi.genericGetEndpointData('game', null, queryParams2)
            .then((response)=>{
                result2 = response.data.game_ids;
                if (result1 !== null) {
                    const fullResult = result1.concat(result2);
                    this.sortGameResults(fullResult);
                    this.updateGameStatistics(null);
                    this.setState({gameIdFilterList: fullResult, isSearching: false});
                }
            })
            .catch((error)=>{
                console.error(error);
            });
        }

        // my games to review
        else if (this.state.formQuickFilter === 'O2') {
            let result = null;
            this.setState({gameIdFilterList: [], isSearching: true});
            const queryParams = {
                'user-id': this.state.localUser.id,
                'state': 'posted',
                'completion': 'completed',
                'needs-concur': true}
            this.restApi.genericGetEndpointData('game', null, queryParams)
            .then((response)=>{
                result = response.data.game_ids;
                this.sortGameResults(result);
                this.updateGameStatistics(null);
                this.setState({gameIdFilterList: result, isSearching: false});
            })
            .catch((error)=>{
                console.error(error);
            });
        }

        // my tournament games in progress
        else if (this.state.formQuickFilter === 'O3') {
            let result = null;
            this.setState({gameIdFilterList: [], isSearching: true});
            const queryParams = {
                'user-id': this.state.localUser.id,
                'state': 'posted',
                'completion': 'in-progress',
                'is-tournament': true}
            this.restApi.genericGetEndpointData('game', null, queryParams)
            .then((response)=>{
                result = response.data.game_ids;
                this.sortGameResults(result);
                this.updateGameStatistics(null);
                this.setState({gameIdFilterList: result, isSearching: false});
            })
            .catch((error)=>{
                console.error(error);
            });
        }

        // this is a league quick filter
        else {
            let result = null;
            this.setState({gameIdFilterList: [], isSearching: true});
            // strip the value down to the league id
            let leagueId = this.state.formQuickFilter;
            leagueId = leagueId.replace("league-", "");
            const queryParams = {
                'state': 'posted',
                'league-id': leagueId}
            this.restApi.genericGetEndpointData('game', null, queryParams)
            .then((response)=>{
                result = response.data.game_ids;
                this.sortGameResults(result);
                this.updateGameStatistics(response.data.statistics || null);
                this.setState({gameIdFilterList: result, isSearching: false});
            })
            .catch((error)=>{
                console.error(error);
                this.setState({gameIdFilterList: [], isSearching: false});
            });
        }
    }

    triggerAdvancedSearch() {
        const queryParams = {'state': 'posted', 'calculate-stats': true};
        let result = null;
        this.setState({gameIdFilterList: [], isSearching: true});
        
        // league-id
        if (this.state.formLeague !== 'any') {
            let id = this.state.formLeague;
            id = id.replace("league-", "");
            queryParams['league-id'] = id;
        }

        // event-id
        if (this.state.formEvent !== 'any') {
            let id = this.state.formEvent;
            id = id.replace("event-", "");
            queryParams['event-id'] = id;
        }

        // tournament-id
        if (this.state.formTournament !== 'any') {
            let id = this.state.formTournament;
            id = id.replace("tournament-", "");
            queryParams['tournament-id'] = id;
        }

        // topic-id
        if (this.state.formTopic !== 'any') {
            let id = this.state.formTopic;
            id = id.replace("topic-", "");
            queryParams['topic-id'] = id;
        }

        // board-game-id
        if (this.state.formBoardGame !== 'any') {
            let id = this.state.formBoardGame;
            id = id.replace("bg-", "");
            queryParams['board-game-id'] = id;
        }

        // is-tournament
        if (this.state.formGameType !== 'any') {
            if (this.state.formGameType === 'tournament') {
                queryParams['is-tournament'] = true;
            } else {
                queryParams['is-tournament'] = false;
            }
        }

        // completion
        if (this.state.formCompletion !== 'any') {
            queryParams['completion'] = this.state.formCompletion;
        }

        // mygamesonly
        if (this.state.formMyGames) {
            queryParams['local-user'] = this.state.localUser.id;
        }

        // username search
        if (this.state.formUsernameSelected) {
            queryParams['user-id'] = this.state.formUsernameSelected.id;
        }
        
        this.restApi.genericGetEndpointData('game', null, queryParams)
        .then((response)=>{
            result = response.data.game_ids;
            this.sortGameResults(result);
            this.updateGameStatistics(response.data.statistics || null);
            this.setState({gameIdFilterList: result, isSearching: false});
        })
        .catch((error)=>{
            console.error(error);
            this.updateGameStatistics(null);
            this.setState({gameIdFilterList: [], isSearching: false});
        });
    }

    sortGameResults(gamesList) {
        gamesList.sort((a,b)=>{return b-a});
        return gamesList;
    }

    updateGameStatistics(statistics) {
        if (!statistics) {
            this.setState({statistics: null, selectedTopic: null, showStatsSection: false});
        } else {
            let newTopic = null;
            if (statistics.hasOwnProperty('topic')) {
                newTopic = NerdHerderDataModelFactory('topic', statistics.topic);
            }
            this.setState({statistics: statistics, selectedTopic: newTopic, showStatsSection: true});
        }
    }

    onToggleHideStatistics() {
        if (this.state.showStatsSection) {
            this.setState({showStatsSection: false});
        } else {
            this.setState({showStatsSection: true});
        }
    }

    handleChangeQuickFilter(event) {
        if (this.state.formQuickFilter !== event.target.value) {
            this.setState({formQuickFilter: event.target.value, gameIdFilterList: [], isSearching: true});
            if (this.filterTimeout !== null) clearTimeout(this.filterTimeout);
            this.filterTimeout = setTimeout(()=>this.triggerSearch(), 1500);
        }
    }

    handleChangeAdvancedFilter(input, value) {
        let triggerSearch = false;
        switch(input) {
            case 'league':
                if (value !== 'any') {
                    let leagueId = value.replace("league-", "");
                    leagueId = parseInt(leagueId);
                    let newTopic = `topic-${this.state.leagues[leagueId].topic.id}`;
                    this.setState({formTopic: newTopic});
                }
                this.setState({formLeague: value});
                if (value !== 'any') triggerSearch = true;
                break;
            case 'tournament':
                this.setState({formTournament: value});
                if (value !== 'any') triggerSearch = true;
                break;
            case 'game-type':
                this.setState({formGameType: value});
                break;
            case 'completion':
                this.setState({formCompletion: value});
                break;
            case 'topic':
                this.setState({formTopic: value});
                if (value !== 'topic-BG') this.setState({formBoardGame: 'any'});
                if (value !== 'any') triggerSearch = true;
                break;
            case 'boardgame':
                this.setState({formBoardGame: value});
                if (value !== 'any') this.setState({formTopic: 'topic-BG'});
                if (value !== 'any') triggerSearch = true;
                break;
            case 'mygamesonly':
                this.setState({formMyGames: value});
                if (value === true) triggerSearch = true;
                break;
            case 'user':
                this.setState({formUsernameSelected: value});
                if (value) triggerSearch = true;
                break
            default:
                console.error(`got unexpected advanced filter input: ${input}`);
        }

        // this is kind of a mess - need to trigger a search if league, event, tournament, username, or my games is set to something other than 'any'
        // but can't just use state because state won't be updated yet from the filter change.
        if (this.state.formLeague !== 'any' || this.state.formEvent !== 'any' ||
            this.state.formTournament !== 'any' || this.state.formTopic !== 'any' || this.state.formBoardGame !== 'any' ||
            this.state.formMyGames === true) {
            triggerSearch = true;
        }
            
        if (triggerSearch) {
            this.setState({gameIdFilterList: [], isSearching: true});
            if (this.filterTimeout !== null) clearTimeout(this.filterTimeout);
            this.filterTimeout = setTimeout(()=>this.triggerSearch(), 1500);
        } else {
            this.setState({gameIdFilterList: [], isSearching: false});
        }
        
    }

    sortStats(a, b) {
        if (a === 'not set') return 1;
        if (b === 'not set') return -1;
        else return(b - a);
    }

    calcWinPercentage(winTieLossArray) {
        const wins = winTieLossArray[0]
        const ties = winTieLossArray[1]
        const losses = winTieLossArray[2]
        const total = wins + ties + losses;
        if (total === 0) return('--');
        const pct = Math.floor((100 * wins) / (total));
        return(`${pct}%`);
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);
        if (!this.state.topics) return (<NerdHerderLoadingModal/>);
        if (!this.state.boardGames) return (<NerdHerderLoadingModal/>);

        const gameListItems = [];
        const leagueOptions = [];
        const tournamentOptions = [];
        const topTopicOptions = [];
        const allTopicOptions = [];
        const boardGameOptions = [];
        const statsData = this.state.statistics;
        const selectedTopic = this.state.selectedTopic;

        let factionNoun = 'faction';
        let pointsNoun = 'points';
        let firstPlayerNoun = 'first';
        let factionDict =  {};
        if (selectedTopic !== null) {
            factionNoun = selectedTopic.faction_noun;
            pointsNoun = selectedTopic.points_noun;
            firstPlayerNoun = selectedTopic.first_player_noun;
            factionDict = selectedTopic.getFactionDict();
        }

        // select the local user anytime the filter isn't selecting the local user explicitly
        let selectLocalUser = true;
        if (this.state.showWhichFilter === 'quick') {
            if (['O0', 'O1', 'O2', 'O3'].includes(this.state.formQuickFilter)) selectLocalUser = false;
        } else {
            if (this.state.formMyGames) selectLocalUser = false;
            // eslint-disable-next-line eqeqeq
            if (this.state.formUsernameSelected && this.state.formUsernameSelected.id == this.state.localUser.id) selectLocalUser = false;
        }

        for (const [leagueId, leagueData] of Object.entries(this.state.leagues)) {
            if (leagueData === null) {
                const option = <option key={`league-${leagueId}`} value={`league-${leagueId}`}>{`League ${leagueId}`}</option>
                leagueOptions.push(option);
            } else {
                const option = <option key={`league-${leagueId}`} value={`league-${leagueId}`}>{leagueData.name}</option>
                leagueOptions.push(option);
            }
        }

        for (const [tournamentId, tournamentData] of Object.entries(this.state.tournaments)) {
            if (tournamentData === null) {
                const option = <option key={`tournament-${tournamentId}`} value={`tournament-${tournamentId}`}>{`Tournament ${tournamentId}`}</option>
                tournamentOptions.push(option);
            }
            else {
                const option = <option key={`tournament-${tournamentId}`} value={`tournament-${tournamentId}`}>{tournamentData.name}</option>
                tournamentOptions.push(option);
            }
        }

        for (const topicId of this.state.localUser.filter_topic_ids) {
            const option = <option key={`toptopic-${topicId}`} value={`topic-${topicId}`}>{this.state.topics[topicId].name} ({topicId})</option>
            topTopicOptions.push(option);
        }

        for (const topicId of Object.keys(this.state.topics)) {
            const option = <option key={`alltopic-${topicId}`} value={`topic-${topicId}`}>{this.state.topics[topicId].name} ({topicId})</option>
            allTopicOptions.push(option);
        }

        for (const boardGameId of this.state.localUser.filter_board_game_ids) {
            const option = <option key={`bg-${boardGameId}`} value={`bg-${boardGameId}`}>{this.state.boardGames[boardGameId].name}</option>
            boardGameOptions.push(option);
        }

        for (const gameId of this.state.gameIdFilterList) {
            const item = <GameListItem key={gameId} gameId={gameId} localUser={this.state.localUser} selectLocalUser={selectLocalUser}/>
            gameListItems.push(item);
        }

        // generate the text that goes under the filter selector
        let selectionTitle = null;
        let selectionText = null;
        switch (this.state.formQuickFilter) {
            case 'O0':
                selectionTitle = 'My Games';
                selectionText = "These are games you've played/playing/will play, regardless of league, event, or tournament.";
                break;
            case 'O1':
                selectionTitle = 'My Scheduled & In-Progress Games';
                selectionText = "Games coming up. If you are looking to record a score for a scheduled game this is an easy way to find it.";
                break;
            case 'O2':
                selectionTitle = 'My Games Needing Review';
                selectionText = "Completed games that need a review by a player. That player might be you, or it could be an opponent."
                break;
            case 'O3':
                selectionTitle = 'My Tournament Games In-Progress';
                selectionText = "Tournament games you are supposed to be playing right now. These games need you to record a score when completed.";
                break;
            default:
                selectionTitle = 'League Games Filter';
                selectionText = "These are all the games in a league, which may include some that you didn't play in.";
        }

        // generate rows for local_user_faction_stats
        const localUserFactionStatsRows = [];
        if (statsData && statsData.local_user_faction_stats) {
            // add a 'cumulative' row at top
            if (statsData.local_user_stats) {
                let rowItem = 
                    <tr key='cumulative'>
                        <td align='left'>Any {factionNoun}</td>
                        <td align='center'>{statsData.local_user_stats[0] + statsData.local_user_stats[1] + statsData.local_user_stats[2]}</td>
                        <td align='center'>{statsData.local_user_stats[0]} / {statsData.local_user_stats[1]} / {statsData.local_user_stats[2]}</td>
                        <td align='right'>{this.calcWinPercentage(statsData.local_user_stats)}</td>
                    </tr>
                localUserFactionStatsRows.push(rowItem);
            }
            for (const factionKey of Object.keys(statsData.local_user_faction_stats).sort()) {
                const factionRecord = statsData.local_user_faction_stats[factionKey];
                let label = factionKey;
                if (label === 'not set') label = 'Not Set';
                else if (factionDict.hasOwnProperty(label)) label = factionDict[label];
                let rowItem = 
                    <tr key={factionKey}>
                        <td align='left'>{label}</td>
                        <td align='center'>{factionRecord[0] + factionRecord[1] + factionRecord[2]}</td>
                        <td align='center'>{factionRecord[0]} / {factionRecord[1]} / {factionRecord[2]}</td>
                        <td align='right'>{this.calcWinPercentage(factionRecord)}</td>
                    </tr>
                localUserFactionStatsRows.push(rowItem);
            }
        }

        // generate rows for local_user_board_game_stats
        const localUserBoardGameStatsRows = [];
        if (statsData && statsData.local_user_board_game_stats) {
            // add a 'cumulative' row at top
            if (statsData.local_user_stats) {
                let rowItem = 
                    <tr key='cumulative'>
                        <td align='left'>Any Board Game</td>
                        <td align='center'>{statsData.local_user_stats[0] + statsData.local_user_stats[1] + statsData.local_user_stats[2]}</td>
                        <td align='center'>{statsData.local_user_stats[0]} / {statsData.local_user_stats[1]} / {statsData.local_user_stats[2]}</td>
                        <td align='right'>{this.calcWinPercentage(statsData.local_user_stats)}</td>
                    </tr>
                localUserBoardGameStatsRows.push(rowItem);
            }
            for (const boardGameKey of Object.keys(statsData.local_user_board_game_stats)) {
                const boardGameRecord = statsData.local_user_board_game_stats[boardGameKey];
                let label = boardGameKey;
                if (label === 'not set') label = 'Not Set';
                else if (this.state.boardGames.hasOwnProperty(label)) label = this.state.boardGames[label].name;
                let rowItem = 
                    <tr key={boardGameKey}>
                        <td align='left'>{label}</td>
                        <td align='center'>{boardGameRecord[0] + boardGameRecord[1] + boardGameRecord[2]}</td>
                        <td align='center'>{boardGameRecord[0]} / {boardGameRecord[1]} / {boardGameRecord[2]}</td>
                        <td align='right'>{this.calcWinPercentage(boardGameRecord)}</td>
                    </tr>
                localUserBoardGameStatsRows.push(rowItem);
            }
        }

        // generate rows for player_faction_stats
        const playerFactionStatsRows = [];
        if (statsData && statsData.player_faction_stats) {
            for (const factionKey of Object.keys(statsData.player_faction_stats).sort()) {
                const factionRecord = statsData.player_faction_stats[factionKey];
                let label = factionKey;
                if (label === 'not set') label = 'Not Set';
                else if (factionDict.hasOwnProperty(label)) label = factionDict[label];
                let rowItem = 
                    <tr key={factionKey}>
                        <td align='left'>{label}</td>
                        <td align='center'>{factionRecord[0] + factionRecord[1] + factionRecord[2]}</td>
                        <td align='center'>{factionRecord[0]} / {factionRecord[1]} / {factionRecord[2]}</td>
                        <td align='right'>{this.calcWinPercentage(factionRecord)}</td>
                    </tr>
                playerFactionStatsRows.push(rowItem);
            }
        }

        // generate rows for game_points_stats
        const gamePointsStatsRows = [];
        if (statsData && statsData.game_points_stats) {
            for (const statKey of Object.keys(statsData.game_points_stats).sort((a,b)=>this.sortStats(a,b))) {
                const statValue = statsData.game_points_stats[statKey];
                let label = statKey;
                if (label === 'not set') label = 'Not Set';
                let rowItem = 
                    <tr key={statKey}>
                        <td align='left'>{label}</td>
                        <td align='right'>{statValue}</td>
                    </tr>
                gamePointsStatsRows.push(rowItem);
            }
        }

        // generate rows for game_points_stats
        const playerPointsStatsRows = [];
        if (statsData && statsData.player_points_stats) {
            for (const statKey of Object.keys(statsData.player_points_stats).sort((a,b)=>this.sortStats(a,b))) {
                const statValue = statsData.player_points_stats[statKey];
                let label = statKey;
                if (label === 'not set') label = 'Not Set';
                let rowItem = 
                    <tr key={statKey}>
                        <td align='left'>{label}</td>
                        <td align='right'>{statValue}</td>
                    </tr>
                playerPointsStatsRows.push(rowItem);
            }
        }

        // generate rows for board_game_stats
        const boardGameStatsRows = [];
        if (statsData && statsData.board_game_stats) {
            for (const statKey of Object.keys(statsData.board_game_stats)) {
                const statValue = statsData.board_game_stats[statKey];
                let label = statKey;
                if (label === 'not set') label = 'Not Set';
                else if (this.state.boardGames.hasOwnProperty(label)) label = this.state.boardGames[label].name;
                let rowItem = 
                    <tr key={statKey}>
                        <td align='left'>{label}</td>
                        <td align='right'>{statValue}</td>
                    </tr>
                boardGameStatsRows.push(rowItem);
            }
        }

        let showFirstPlayerStats = false;
        if (statsData && statsData.first_player_won && statsData.first_player_lost && statsData.first_player_won != 0 && statsData.first_player_lost != 0) {
            showFirstPlayerStats = true;
        }

        let tableFontSize = '12px';

        return(
            <NerdHerderStandardPageTemplate  pageName='games' headerSelection='games'
                                             navPath={[{icon: 'flaticon-sport-dices', label: 'Games & Stats', href: '/app/games'}]}
                                             localUser={this.state.localUser}>
                <NerdHerderTour name='games' steps={this.tourSteps} localUser={this.state.localUser}/>
                {this.state.showAddGameModal && <NerdHerderAddGameModal localUser={this.state.localUser}
                                                                        onCancel={()=>this.onAddGameCancel()} 
                                                                        onAddGame={()=>this.onAddGameComplete()}/>}
                <NerdHerderStandardCardTemplate id="add-games-card">
                    <div className="d-grid gap-2">
                        <Button variant='primary' onClick={()=>this.setState({showAddGameModal: true})}>Add Casual Game</Button>
                    </div>
                </NerdHerderStandardCardTemplate>
                <NerdHerderStandardCardTemplate id="search-games-card">
                    <Form>
                        <Form.Group className="form-outline mb-2">
                            <div className='d-grid gap-2'>
                                <ToggleButtonGroup size='sm' name='filter-view' type="radio" value={this.state.showWhichFilter} onChange={(event)=>this.onSwitchFilter(event)}>
                                    <ToggleButton variant='outline-primary' id='toggle-quick-filter' value={'quick'}>Quick Filter</ToggleButton>
                                    <ToggleButton variant='outline-primary' id='toggle-advanced-filter' value={'advanced'}>Advanced Filter</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Form.Group>
                        {this.state.showWhichFilter === 'quick' &&
                        <Form.Group className="form-outline mb-2">
                            <Form.Select onChange={(event)=>this.handleChangeQuickFilter(event)} value={this.state.formQuickFilter} disabled={this.state.isSearching}>
                                <option value="O0">My Games</option>
                                <option value="O1">My Scheduled & In-Progress Games</option>
                                <option value="O2">My Games Needing Review</option>
                                <option value="O3">My Tournament Games In-Progress</option>
                                {leagueOptions}
                            </Form.Select>
                            <Form.Text>
                                <p><small>
                                    <b>{selectionTitle}</b> - {selectionText}
                                </small></p>
                            </Form.Text>
                        </Form.Group>}
                        {this.state.showWhichFilter === 'advanced' &&
                        <div>
                            <Form.Group className="form-outline mb-2">
                                <Form.Select onChange={(event)=>this.handleChangeAdvancedFilter('topic', event.target.value)} value={this.state.formTopic} disabled={this.state.isSearching}>
                                    <optgroup label='Everything'>
                                        <option value="any">Any Topic</option>
                                    </optgroup>
                                    <optgroup label='Your Interests'>
                                        {topTopicOptions}
                                    </optgroup>
                                    <optgroup label='All Topics'>
                                        {allTopicOptions}
                                    </optgroup>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="form-outline mb-2">
                                <Form.Select onChange={(event)=>this.handleChangeAdvancedFilter('league', event.target.value)} value={this.state.formLeague} disabled={this.state.isSearching}>
                                    <option value="any">Any League or Event</option>
                                    {leagueOptions}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="form-outline mb-2">
                                <Form.Select onChange={(event)=>this.handleChangeAdvancedFilter('tournament', event.target.value)} value={this.state.formTournament} disabled={this.state.isSearching}>
                                    <option value="any">Any Tournament</option>
                                    {tournamentOptions}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="form-outline mb-2">
                                <Form.Select onChange={(event)=>this.handleChangeAdvancedFilter('boardgame', event.target.value)} value={this.state.formBoardGame} disabled={this.state.isSearching || this.state.formTopic !== 'topic-BG'}>
                                    <option value="any">Any Board Game</option>
                                    {boardGameOptions}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="form-outline mb-2">
                                <Form.Select onChange={(event)=>this.handleChangeAdvancedFilter('game-type', event.target.value)} value={this.state.formGameType} disabled={this.state.isSearching}>
                                    <option value="any">Casual & Tournament Games</option>
                                    <option value="tournament">Tournament Games Only</option>
                                    <option value="casual">Casual Games Only</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="form-outline mb-2">
                                <Form.Select onChange={(event)=>this.handleChangeAdvancedFilter('completion', event.target.value)} value={this.state.formCompletion} disabled={this.state.isSearching}>
                                    <option value="any">Any Game Category</option>
                                    <option value="completed">Completed Games</option>
                                    <option value="in-progress">In-Progress Games</option>
                                    <option value="scheduled">Scheduled Games</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="form-outline mb-2">
                                <FormTypeahead placeholder='Username to filter on' delay={300} endpoint='user' queryParams={{'username-similar': 'query'}} labelKey='username' onChange={(s)=>{this.handleChangeAdvancedFilter('user', s)}} disabled={this.state.isSearching}/>
                            </Form.Group>
                            <Form.Group className="form-outline mb-2">
                                <Form.Check type='checkbox' label='My Games Only' onChange={(event)=>this.handleChangeAdvancedFilter('mygamesonly', event.target.checked)} value={this.state.formMyGames} disabled={this.state.isSearching}/>
                            </Form.Group>
                        </div>}
                    </Form>
                    
                    {statsData && this.state.showWhichFilter === 'advanced' &&
                    <div>
                        <hr/>
                        <Row>
                            <Col>
                                <h5>Filtered Statistics</h5>
                            </Col>
                            <Col xs='auto'>
                                <Button size='sm' variant='secondary' disabled={this.state.isSearching} onClick={()=>this.onToggleHideStatistics()}>{this.state.showStatsSection? 'Hide' : 'Show'}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Collapse in={this.state.showStatsSection && !this.state.isSearching}>
                                    <div>
                                        <div>
                                            <b>Games Recorded</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <tbody>
                                                    <tr><td align='left'>Total Games</td><td align='right'>{statsData.total_games || '--'}</td></tr>
                                                    <tr><td align='left'>Casual</td><td align='right'>{statsData.total_casual_games || '--'}</td></tr>
                                                    <tr><td align='left'>Tournament</td><td align='right'>{statsData.total_tournament_games || '--'}</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        {statsData.local_user_stats && !statsData.local_user_faction_stats && !statsData.local_user_board_game_stats &&
                                        <div>
                                            <b>Your Record</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <thead>
                                                    <tr><th className='text-start'>Games</th><th className='text-center'>W / T / L</th><th className='text-end'>Win %</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align='left'>{statsData.local_user_stats[0] + statsData.local_user_stats[1] + statsData.local_user_stats[2]}</td>
                                                        <td align='center'>{statsData.local_user_stats[0]} / {statsData.local_user_stats[1]} / {statsData.local_user_stats[2]}</td>
                                                        <td align='right'>{this.calcWinPercentage(statsData.local_user_stats)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>}
                                        {statsData.local_user_stats && statsData.local_user_board_game_stats &&
                                        <div>
                                            <b>Your Record</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <thead>
                                                    <tr><th className='text-start'>Board Game</th><th className='text-center'>Games</th><th className='text-center'>W / T / L</th><th className='text-end'>Win %</th></tr>
                                                </thead>
                                                <tbody>
                                                    {localUserBoardGameStatsRows}
                                                </tbody>
                                            </Table>
                                        </div>}
                                        {statsData.local_user_stats && statsData.local_user_faction_stats &&
                                        <div>
                                            <b>Your Record</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <thead>
                                                    <tr><th className='text-start'>{capitalizeFirstLetter(factionNoun)}</th><th className='text-center'>Games</th><th className='text-center'>W / T / L</th><th className='text-end'>Win %</th></tr>
                                                </thead>
                                                <tbody>
                                                    {localUserFactionStatsRows}
                                                </tbody>
                                            </Table>
                                        </div>}
                                        {statsData.player_faction_stats &&
                                        <div>
                                            <b>{capitalizeFirstLetter(factionNoun)} Record</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <thead>
                                                    <tr><th className='text-start'>{capitalizeFirstLetter(factionNoun)}</th><th className='text-center'>Games</th><th className='text-center'>W / T / L</th><th className='text-end'>Win %</th></tr>
                                                </thead>
                                                <tbody>
                                                    {playerFactionStatsRows}
                                                </tbody>
                                            </Table>
                                        </div>}
                                        {showFirstPlayerStats &&
                                        <div>
                                            <b>{capitalizeFirstLetter(firstPlayerNoun)} Player Record</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <tbody>
                                                    <tr><td align='left'>{capitalizeFirstLetter(firstPlayerNoun)} Player Won</td><td align='right'>{statsData.first_player_won}</td></tr>
                                                    <tr><td align='left'>{capitalizeFirstLetter(firstPlayerNoun)} Player Lost</td><td align='right'>{statsData.first_player_lost}</td></tr>
                                                    <tr><td align='left'>{capitalizeFirstLetter(firstPlayerNoun)} Player Win %</td><td align='right'>{Math.floor((100 * statsData.first_player_won) / (statsData.first_player_won + statsData.first_player_lost))}%</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>}
                                        {statsData.game_points_stats &&
                                        <div>
                                            <b>{capitalizeFirstLetter(pointsNoun)} Level</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <tbody>
                                                    {gamePointsStatsRows}
                                                </tbody>
                                            </Table>
                                        </div>}
                                        {statsData.player_points_stats &&
                                        <div>
                                            <b>Player {capitalizeFirstLetter(pointsNoun)}</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <tbody>
                                                    {playerPointsStatsRows}
                                                </tbody>
                                            </Table>
                                        </div>}
                                        {statsData.board_game_stats &&
                                        <div>
                                            <b>Board Game Statistics</b>
                                            <Table size='sm' borderless responsive striped style={{fontSize: tableFontSize}}>
                                                <thead>
                                                    <tr><th className='text-start'>Game</th><th className='text-end'>Times Played</th></tr>
                                                </thead>
                                                <tbody>
                                                    {boardGameStatsRows}
                                                </tbody>
                                            </Table>
                                        </div>}
                                    </div>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>}

                    <hr/>
                    {this.state.isSearching &&
                    <b>
                        Searching... <Spinner as='span' variant="primary" animation="border" size="sm" role="status" aria-hidden="true"/>
                    </b>}
                    {!this.state.isSearching && gameListItems.length === 0 &&
                    <b>
                        The filter resulted in no results. Either there's nothing to show or the result set is enormous.
                    </b>}
                    <div id='tour-games-location'/>
                    {gameListItems}
                </NerdHerderStandardCardTemplate>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

export default withRouter(GamesPage);
