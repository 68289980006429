import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { DateTime, SystemZone } from 'luxon';
import { InView } from 'react-intersection-observer';
import { Howl } from 'howler';
import { NerdHerderSingleImageUpload } from './NerdHerderDropzone';
import { NerdHerderRealtimePushChannel } from '../NerdHerder-RealtimePushChannel';
import { getStaticStorageSoundFilePublicUrl, getStorageFilePublicUrl, determinePrivateChatChannelName, getUrlFromText } from '../utilities';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './NerdHerderStandardCardTemplate';
import { NerdHerderVerticalScroller } from './NerdHerderScroller';
import { FormattedText, FormTextInputLimit } from './NerdHerderFormHelpers';
import { ChatBubbleReactions, NerdHerderMessageReactMenu } from './NerdHerderMessageCards';
import { OpenGraphCard } from './NerdHerderOpenGraph';

export class ChatCard extends React.Component {

    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('localUser not included in props.localUser');
        if (typeof this.props.league === 'undefined' && this.props.event === 'undefined' && this.props.user === 'undefined') console.error('user, league, or event not included in props');

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.divRef = React.createRef();
        this.enterTextDivRef = React.createRef();

        this.isLeagueChat = false;
        this.isEventChat = false;
        this.isUserChat = false;
        this.chatChannelName = null;
        this.chatEndpointName = null;
        this.chatEndpointIndex = null;
        if (this.props.league) {
            this.isLeagueChat = true;
            this.chatEndpointName = 'league-chat';
            this.chatEndpointIndex = this.props.league.id;
            this.chatChannelName = this.props.league.chat_channel_name;
            if (this.chatChannelName === null) {
                this.chatChannelName = `chat-league-${this.props.league.id}`;
            }
        } else if (this.props.event) {
            this.isEventChat = true;
            this.chatEndpointName = 'event-chat';
            this.chatEndpointIndex = this.props.event.id;
            this.chatChannelName = this.props.event.chat_channel_name;
            if (this.chatChannelName === null) {
                this.chatChannelName = `chat-event-${this.props.league.id}`;
            }
        } else if (this.props.user) {
            this.isUserChat = true;
            this.chatChannelName = determinePrivateChatChannelName(this.props.user.id, this.props.localUser.id);
            this.chatEndpointName = 'user-chat';
            this.chatEndpointIndex = this.chatChannelName;
        } else {
            console.error('cannot determine chat channel name - not a user, league, or event chat channel')
        }

        // cache users so we're not constantly fetching images and usernames
        const usersCache = {};
        usersCache[this.props.localUser.id] = this.props.localUser;

        this.state = {
            navigateTo: null,
            uploadAlert: null,
            isUpdating: false,
            realTimePushConnectionComplete: false,
            usersCache: usersCache,
            messages: [],
            formChatInput: '',
            formChatInputRows: 2,
            inView: false,
        }

        this.channel = null;
        this.sound = new Howl({src: [getStaticStorageSoundFilePublicUrl('/notification.mp3')], preload: true});
    }

    componentDidMount() {
        // get the initial chat convo
        this.restApi.genericGetEndpointData(this.chatEndpointName, this.chatEndpointIndex)
        .then((response)=>{
            for (const messageData of response.data) {
                const newMessage = NerdHerderDataModelFactory('message', messageData);
                this.addMessageToChat(newMessage);
            }
            // force a refresh to header alerts, as any newly read posts will clear some alerts
            this.restPubSub.refresh('header-alerts');
        })
        .catch((error)=>{
            console.error(error);
        })
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    subscribeToRealtimePushes() {
        // only connect to pusher when we have the props, and only if not already subscribed
        if (this.channel === null) {
            this.channel = new NerdHerderRealtimePushChannel(this.chatChannelName);
            this.channel.bind('chat-message-add', (eventData)=>this.onChatMessageAddEvent(eventData));
            this.channel.bind('chat-message-delete', (eventData)=>this.onChatMessageDeleteEvent(eventData));
            this.channel.bind('chat-message-edit', (eventData)=>this.onChatMessageEditEvent(eventData));
            this.channel.bind('chat-message-react', (eventData)=>this.onChatMessageReactEvent(eventData));
            this.setState({realTimePushConnectionComplete: true});
        }
    }

    addMessageToChat(messageData) {
        // see if the message is unique
        for (const message of this.state.messages) {
            if (message.id === messageData.id) {
                console.error('duplicate message.id', message.id);
            }
        }

        // if the user isn't in the cache, fetch them
        const fromUserId = messageData.from_user_id;
        if (!this.state.usersCache.hasOwnProperty(fromUserId)) {
            const sub = this.restPubSub.subscribe('user', fromUserId, (d, k) => {this.updateUser(d, k)}, null, fromUserId);
            this.restPubSubPool.add(sub);
        }
        this.setState(state => ({
            messages: [...state.messages, messageData]
        }));
    }

    updateUser(userData, userId) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [userId]: newUser}}
        });
    }

    onChangeView(inView, entry) {
        if (inView && this.state.inView === false) {
            this.setState({inView: true});
        } else if (!inView && this.state.inView === true) {
            this.setState({inView: false});
        }
    }

    onChatMessageAddEvent(eventData) {
        console.debug('onChatMessageAddEvent()');
        console.debug(eventData);
        const messageData = eventData;
        const newMessage = NerdHerderDataModelFactory('message', messageData);
        this.addMessageToChat(newMessage);
        this.sound.play();
    }

    onChatMessageDeleteEvent(eventData) {
        console.debug('onChatMessageDeleteEvent()');
        console.debug(eventData);
        const messageData = eventData;
        for (let i=0; i<this.state.messages.length; i++) {
            const localMessage = this.state.messages[i];
            // eslint-disable-next-line eqeqeq
            if (localMessage.id == messageData.id) {
                const newMessageList = [...this.state.messages];
                newMessageList.splice(i, 1);
                this.setState(state => ({
                    messages: newMessageList
                }));
                break;
            }
        }
    }

    onChatMessageEditEvent(eventData) {
        console.debug('onChatMessageEditEvent()');
        console.debug(eventData);
        const messageData = eventData;
        const newMessage = NerdHerderDataModelFactory('message', messageData);
        for (let i=0; i<this.state.messages.length; i++) {
            const localMessage = this.state.messages[i];
            // eslint-disable-next-line eqeqeq
            if (localMessage.id == messageData.id) {
                const newMessageList = [...this.state.messages];
                newMessage.reactions = [...localMessage.reactions];
                newMessageList.splice(i, 1, newMessage);
                this.setState(state => ({
                    messages: newMessageList
                }));
                break;
            }
        }
    }

    onChatMessageReactEvent(eventData) {
        console.debug('onChatMessageReactEvent()');
        console.debug(eventData);
        const reactMessageData = eventData;
        for (let i=0; i<this.state.messages.length; i++) {
            const localMessage = this.state.messages[i];
            // eslint-disable-next-line eqeqeq
            if (localMessage.id == reactMessageData.id) {
                const newMessageList = [...this.state.messages];
                const newMessage = NerdHerderDataModelFactory('message', localMessage);
                newMessage.reactions = reactMessageData.reactions
                newMessageList.splice(i, 1, newMessage);
                this.setState(state => ({
                    messages: newMessageList
                }));
                break;
            }
        }
        this.sound.play();
    }

    onSendChatMessage() {
        console.debug('onSendChatMessage()');
        if (this.isLeagueChat) {
            this.onSendLeagueChatMessage();
        } else if (this.isEventChat) {
            this.onSendEventChatMessage();
        } else if (this.isUserChat) {
            this.onSendUserChatMessage();
        }
    }

    onSendLeagueChatMessage() {
        console.debug('onSendLeagueChatMessage()');
        const postData = {
            from_user_id: this.props.localUser.id,
            to_user_id: null,
            league_id: this.props.league.id,
            filenames: null,
            type: 'chat',
            channel: `chat-league-${this.props.league.id}`,
            text: this.state.formChatInput.trimEnd()}
        this.restApi.genericPostEndpointData('league-chat', this.props.league.id, postData)
        .then((response)=>{
            console.debug('chat message sent successfully');
            this.setState({formChatInput: '', formChatInputRows: 2, isUpdating: false});
        })
        .catch((error)=>{
            console.error(error);
            this.setState({isUpdating: false});
        })
        this.setState({isUpdating: true});
    }

    onSendUserChatMessage() {
        console.debug('onSendUserChatMessage()');
        const postData = {
            from_user_id: this.props.localUser.id,
            to_user_id: this.props.user.id,
            league_id: null,
            filenames: null,
            type: 'chat',
            channel: this.chatChannelName,
            text: this.state.formChatInput.trimEnd()}
        this.restApi.genericPostEndpointData(this.chatEndpointName, this.chatEndpointIndex, postData)
        .then((response)=>{
            this.setState({formChatInput: '', formChatInputRows: 2, isUpdating: false, uploadAlert: null});
        })
        .catch((error)=>{
            console.error(error);
            this.setState({isUpdating: false});
        })
        this.setState({isUpdating: true});
    }

    handleChatInput(event) {
        let value = event.target.value;
        let inputRows = event.target.rows;
        // this will automatically grow the textarea to fit the input
        if (event.target.clientHeight < event.target.scrollHeight) {
            inputRows += 1;
        }
        // if the user deletes all the text, put the rows back to 2
        if (value === '') {
            inputRows = 2;
        }
        this.setState({formChatInput: value, formChatInputRows: inputRows});
    }

    handlePaste(event) {
        if (event.clipboardData.files.length !== 0) {
            event.preventDefault();
            this.handleUpload(event.clipboardData.files[0]);
        }
    }

    handleDrop(event) {
        if (event.dataTransfer.files.length !== 0) {
            event.preventDefault();
            this.handleUpload(event.dataTransfer.files[0]);
        }
    }

    handleUpload(uploadFile) {
        NerdHerderSingleImageUpload(uploadFile, (f)=>this.onSingleFileAdd(f), null, (f,r)=>this.onSingleFileUploadSuccess(f,r), (f,e)=>this.onSingleFileUploadFailure(f,e));
    }

    onSingleFileAdd(file) {
        const uploadAlert = <Alert className='py-1 mb-1' variant='warning'>Uploading...</Alert>
        this.setState({updating: true, uploadAlert: uploadAlert});
    }

    onSingleFileUploadSuccess(file, response) {
        let toUserId = null;
        let toLeagueId = null;
        let chatChannelName = null;
        if (this.isLeagueChat) {
            toLeagueId = this.props.league.id;
            chatChannelName = `chat-league-${this.props.league.id}`;
        } else if (this.isEventChat) {
            // there is no event chat
            return;
        } else if (this.isUserChat) {
            toUserId = this.props.user.id;
            chatChannelName = this.chatChannelName;
        }

        const postData = {
            from_user_id: this.props.localUser.id,
            to_user_id: toUserId,
            league_id: toLeagueId,
            filenames: file.upload.filename,
            type: 'chat',
            channel: chatChannelName,
            text: this.state.formChatInput.trimEnd()}
        this.restApi.genericPostEndpointData(this.chatEndpointName, this.chatEndpointIndex, postData)
        .then((response)=>{
            this.setState({formChatInput: '', isUpdating: false, uploadAlert: null});
        })
        .catch((error)=>{
            console.error(error);
            const uploadAlert = <Alert className='py-1 mb-1' variant='danger'>Chat message rejected by server</Alert>
            this.setState({isUpdating: false, uploadAlert: uploadAlert});
        })
        this.setState({isUpdating: true});
    }

    onSingleFileUploadFailure(file, error) {
        const uploadAlert = <Alert className='py-1 mb-1' variant='danger'>File rejected by server</Alert>
        this.setState({updating: false, uploadAlert: uploadAlert});
        this.props.cancelReply();
    }
    
    render() {
        const maxChatInputLength = 512;

        if (!this.state.realTimePushConnectionComplete) {
            setTimeout(()=>this.subscribeToRealtimePushes(), 0);
        }

        const chatMessageBubbles = [];
        for(const messageData of this.state.messages) {
            const fromUserId = messageData.from_user_id;
            let fromUserData = null;
            let filename = null;

            // if the user is in the cache give that to the bubble
            if (this.state.usersCache.hasOwnProperty(fromUserId)) {
                fromUserData = this.state.usersCache[fromUserId];
            }

            if (messageData.filenames !== null) {
                const filenames = messageData.filenames.split(" ");
                
                // if there's no image, just hide the reply
                if (filenames.length === 0) {
                    continue;
                }
                else if (filenames.length === 1) {
                    filename = filenames[0];
                } else {
                    continue;
                }
            }

            const bubbleItem = <ChatBubble key={messageData.id} userId={fromUserId} user={fromUserData} localUser={this.props.localUser} date={messageData.date_sent} text={messageData.text} filename={filename} message={messageData}/>
            chatMessageBubbles.push(bubbleItem);
        }

        let maxBubblesHeight = window.innerHeight - 300;
        let fixedDivStyle = null;
        if (this.divRef.current) {
            let boundingRect = this.divRef.current.getBoundingClientRect();
            let remToPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
            fixedDivStyle = {
                width: boundingRect.width + 2 * remToPixels,
                border: '1px solid rgba(0, 0, 0, 0.125)',
                borderTopRightRadius: '0.5rem',
                borderTopLeftRadius: '0.5rem',
                backgroundColor: 'white',
                padding: '1.0rem',
                boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
                position: 'fixed',
                left: boundingRect.left - 0.75 * remToPixels,
                bottom: 0
            };
        }
        let bottomSpacerDivHeight = 110;
        /*if (fixedDivStyle && this.state.inView) {
            if (this.enterTextDivRef.current) {
                let boundingRect = this.enterTextDivRef.current.getBoundingClientRect();
                bottomSpacerDivHeight = boundingRect.height;
            } else {
                this.forceUpdate();
            }
        }*/

        return(
            <div>
                <NerdHerderStandardCardTemplate title={this.props.title || 'Chat'} titleIcon={this.props.titleIcon || 'chat.png'} manageOptions={this.props.manageOptions}>
                    <NerdHerderVerticalScroller height={maxBubblesHeight} autoscroll={true}>
                        {chatMessageBubbles}
                    </NerdHerderVerticalScroller>                
                    <div ref={this.divRef}/>
                    {fixedDivStyle !== null && this.state.inView &&
                    <div ref={this.enterTextDivRef} style={fixedDivStyle}>
                        <Row>
                            <Col className='align-self-middle pe-0'>
                                {this.state.uploadAlert}
                                <div style={{position: 'relative'}}>
                                    <Form.Control as="textarea" rows={this.state.formChatInputRows} onChange={(e)=>this.handleChatInput(e)} onPaste={(e)=>this.handlePaste(e)} onDrop={(e)=>this.handleDrop(e)} placeholder='add a comment...' autoComplete='off' disabled={this.state.isUpdating} value={this.state.formChatInput} maxLength={maxChatInputLength} style={{resize: 'none'}}/>
                                    <FormTextInputLimit current={this.state.formChatInput.length} max={maxChatInputLength}/>
                                </div>
                            </Col>
                            <Col xs='auto'>
                                <Button size='sm' variant="primary" onClick={()=>{this.onSendChatMessage()}} disabled={this.state.isUpdating || this.state.formChatInput.length === 0}>Send</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{height: 20}}></div>
                            </Col>
                        </Row>
                    </div>}
                </NerdHerderStandardCardTemplate>
                <InView onChange={(inView)=>{this.onChangeView(inView)}}>
                    <div></div>
                </InView>
                <div style={{height: bottomSpacerDivHeight}}></div>
            </div>
        )
    }
}

class ChatBubble extends React.Component {

    openImageInNewTab(filename, event) {
        event.preventDefault();
        let url = getStorageFilePublicUrl(`/user_images/${filename}`);
        window.open(url, '_blank');
    }

    render() {
        // icons go on the left unless the user id is the local user
        let iconLeft = true;
        let textLeft = true;
        // eslint-disable-next-line eqeqeq
        if (this.props.userId == this.props.localUser.id) {
            iconLeft = false;
            textLeft = false;
        }

        // if the text contains line breaks, align it left no matter what
        if (this.props.text.includes('\n')) {
            textLeft = true;
        }

        let icon = null;
        let style= null;
        if (iconLeft) {
            style = {position: 'absolute', top: '-6px', left: '-4px', border: '4px solid white', width: '35px', height: '35px'};
        } else {
            style = {position: 'absolute', top: '-6px', right: '-4px', border: '4px solid white', width: '35px', height: '35px'};
        }
        if (this.props.user === null) {
            icon = <Spinner style={style} variant="primary" animation="border" size="sm" role="status" aria-hidden="true"/>
        } else {
            icon = <Image style={style} className="rounded-circle" alt="user icon" src={this.props.user.getImageUrl()}/>
        }

        const luxonDate = DateTime.fromISO(this.props.date, {zone: 'utc'});

        let button = null;
        if (this.props.message) {
            button = <NerdHerderMessageReactMenu direction={iconLeft ? 'left' : 'right'} message={this.props.message} localUser={this.props.localUser} />
        }

        let image = null;
        if (this.props.filename)
        image = 
            <div className='text-center'>
                <Image className='d-block w-100 mb-1 rounded' height={200} onClick={(e)=>this.openImageInNewTab(this.props.filename, e)} style={{objectFit: 'contain'}} src={getStorageFilePublicUrl(`/user_images/${this.props.filename}`)} alt='user uploaded image'/>
            </div>

        let urlSnippet = null;
        const urlText = getUrlFromText(this.props.text);
        if (urlText) {
            urlSnippet = <div className='my-1'><OpenGraphCard url={urlText}/></div>
        }

        return (
            <div style={{overflowX: 'hidden'}}>
                <Row>
                    {!iconLeft && <Col xs={1}></Col>}
                    <Col xs={11}>
                        <div className="chat-bubble shadow-sm my-2" style={{position: 'relative'}}>
                            <Row className={iconLeft ? 'ps-3' : 'pe-3'}>
                                {!iconLeft &&
                                <Col xs="auto">
                                    {button}
                                </Col>}
                                <Col>
                                    <div>
                                        {this.props.user && 
                                        <div className={iconLeft ? "text-start" : "text-end"}>
                                            <small className={`text-muted m-0 ${iconLeft ? 'ps-4' : 'pe-4'}`}>
                                                <small className="m-0 p-0">{this.props.user.username} at {luxonDate.setZone(new SystemZone()).toLocaleString(DateTime.DATETIME_SHORT)}</small>
                                            </small>
                                        </div>}
                                        {image}
                                        <div className={textLeft ? "text-start text-break" : "text-end text-break"}>
                                            <small>
                                                <FormattedText text={this.props.text}/>
                                            </small>
                                        </div>
                                        {urlSnippet}
                                        {icon}
                                    </div>
                                </Col>
                                {iconLeft &&
                                <Col xs="auto">
                                    {button}
                                </Col>}
                            </Row>
                            <ChatBubbleReactions message={this.props.message} localUser={this.props.localUser} direction={iconLeft ? 'right' : 'left'}/>
                        </div>
                    </Col>
                    {iconLeft && <Col xs={1}></Col>}
                </Row>
            </div>
        );
    }
}
