import React from 'react';
import withRouter from './withRouter';
import { Navigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Collapse from 'react-bootstrap/Collapse';
import Spinner from 'react-bootstrap/Spinner';
import pluralize from 'pluralize';
import { NerdHerderDropzoneImageUploader, NerdHerderDropzoneFileUploader } from './nerdherder-components/NerdHerderDropzone';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { Truncate } from './nerdherder-components/NerdHerderTruncate';
import { NerdHerderLoadingModal, NerdHerderVenueSearchModal, NerdHerderErrorModal, NerdHerderNewTournamentModal, NerdHerderEditPollModal, NerdHerderEditEventModal, NerdHerderEditFileModal, NerdHerderConfirmModal, NerdHerderStripePaymentDetailsModal, NerdHerderMessageModal, NerdHerderFileContainerContentsModal, NerdHerderRandomSelectorResultModal, NerdHerderViewRandomSelectorViewResultModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderJoinModelRestApi } from './NerdHerder-JoinModelRestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { convertListToDict, generateDateString, convertDateObjectToFormInput, handleGlobalRestError, getStaticStorageImageFilePublicUrl, getFileUiIconUrl, delCookieAfterDelay, capitalizeFirstLetter, capitalizeFirstLetters, getCurrency, decimalSeparator, getFailureMessage, isValidHttpUrl } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderLeaguePostSnippet } from './nerdherder-components/NerdHerderMessageCards';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderVerticalScroller } from './nerdherder-components/NerdHerderScroller';
import { EventListItem, TournamentListItem, VenueListItem } from './nerdherder-components/NerdHerderListItems';
import { FormErrorText, getFormErrors, setErrorState, clearErrorState, FormTextInputLimit, FormTypeahead, FormCurrencyInput, FormControlSubmit } from './nerdherder-components/NerdHerderFormHelpers';
import { TableOfUsers } from './nerdherder-components/NerdHerderTableHelpers';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { Required } from './nerdherder-components/NerdHerderBadge';
import { NerdHerderToolTip, NerdHerderToolTipButton, NerdHerderToolTipIcon } from './nerdherder-components/NerdHerderToolTip';

class ManageLeaguePage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.leagueNavBarRef = React.createRef();
        this.scrollFunction = null;

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            navbarSticky: false,
            navbarCollapseShow: true,
            firebaseSigninComplete: false,
            localUser: null,
            leagueId: this.props.params.leagueId,
            league: null,
            errorFeedback: null,
        }

        // set the default active key from the query params, but set it to info if the query param has a bogus value
        this.defaultActiveKey = 'info';
        if (this.props.query.get('tab')) this.defaultActiveKey = this.props.query.get('tab');
        if (!['info', 'join', 'event', 'game', 'chat', 'players'].includes(this.defaultActiveKey)) this.defaultActiveKey = 'info';

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('league', this.state.leagueId, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);

        // bind the scroll function
        this.scrollFunction = this.handleScroll.bind(this);
        window.addEventListener('scroll', this.scrollFunction);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
        if (this.scrollFunction) window.removeEventListener('scroll', this.scrollFunction);
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league});
    }

    handleTabChange(activeKey) {
        console.log(activeKey);
    }

    handleScroll(event) {
        if (this.leagueNavBarRef.current) {
            if (this.state.navbarSticky === false) {
                if (this.leagueNavBarRef.current.getBoundingClientRect().top <= -70) {
                    this.setState({navbarCollapseShow: false});
                    setTimeout(()=>this.setState({navbarSticky: true}), 500);
                    setTimeout(()=>this.setState({navbarCollapseShow: true}), 1000);
                }
            } else {
                if (this.leagueNavBarRef.current.getBoundingClientRect().top > -50) {
                    this.setState({navbarSticky: false, navbarCollapseShow: true});
                }
            }
        }
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal />);
        if (!this.state.league) return (<NerdHerderLoadingModal />);
        if (this.state.showErrorFeedbackModal) return(<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);

        // only allow managers to see this stuff
        if (!this.state.league.isManager(this.state.localUser.id)) {
            return(<NerdHerderErrorModal errorFeedback='You are not a League Organizer'/>);
        }

        // if this league is archived and there is an attempt to manage the league, send the user back to the league page
        if (this.state.league.state === 'archived') return(<Navigate to={`/app/league/${this.state.league.id}`} replace={true}/>);

        
        let infoPageDisabled = false;
        let joinPageDisabled = false;
        let eventPageDisabled = false;
        let gamePageDisabled = false;
        let chatPageDisabled = false;
        let playersPageDisabled = false;

        let outerDivClassName = 'my-2';
        let outerStyle={};
        let innerDivClassName = 'bg-white p-3';
        let innerStyle={borderRadius: '0.5rem', border: '1px solid rgba(0, 0, 0, 0.125)', boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)'};

        if (this.state.navbarSticky) {
            outerDivClassName = 'fixed-top';
            innerStyle={borderBottom: '1px solid rgba(0, 0, 0, 0.125)'};
        }

        return(
            <NerdHerderStandardPageTemplate pageName='league_management' headerSelection='settings' dropdownSelection={`manageleague-${this.state.leagueId}`} 
                                            navPath={[{icon: 'flaticon-team', label: this.state.league.name, href: `/app/league/${this.state.leagueId}`},
                                                      {icon: 'flaticon-configuration-with-gear', label: 'Manage', href: `/app/manageleague/${this.state.leagueId}`}]}
                                            disableRefresh={false} league={this.state.league} localUser={this.state.localUser}>
                <Tab.Container id="tab-container" defaultActiveKey={this.defaultActiveKey} onSelect={(k)=>this.handleTabChange(k)}>
                    <div ref={this.leagueNavBarRef}/>
                    <div className={outerDivClassName} style={outerStyle}>
                        <Collapse in={this.state.navbarCollapseShow}>
                            <div className={innerDivClassName} style={innerStyle}>
                                <Nav fill variant="pills" className="text-center">
                                    {this.state.navbarSticky &&
                                    <div style={{width: '60px'}}></div>}
                                    <NerdHerderToolTip text='Announcements & Social'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="info" disabled={infoPageDisabled}>
                                                <NerdHerderFontIcon icon="flaticon-tower-with-signal-emission"/>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Joining & Payments'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="join" disabled={joinPageDisabled}>
                                                <NerdHerderFontIcon icon="flaticon-question-sign-in-a-circle"/>
                                                {' / '}
                                                <NerdHerderFontIcon icon="flaticon-credit-card-back"/>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Calendar & Minor Events'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="event" disabled={eventPageDisabled}>
                                                <NerdHerderFontIcon icon="flaticon-calendar-to-organize-dates"/>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Tournaments & Games'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="game" disabled={gamePageDisabled}>
                                                <NerdHerderFontIcon icon="flaticon-trophy-cup-black-shape"/>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Chat'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="chat" disabled={chatPageDisabled}>
                                                <NerdHerderFontIcon icon="flaticon-chat-of-two-rounded-rectangular-filled-speech-bubbles"/>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                    <NerdHerderToolTip text='Player Info & Files'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="players" disabled={playersPageDisabled}>
                                                <NerdHerderFontIcon icon="flaticon-personal-card"/>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </NerdHerderToolTip>
                                </Nav>
                            </div>
                        </Collapse>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="info" mountOnEnter unmountOnExit>
                            <LeagueInfoTabContent localUser={this.state.localUser}
                                                  league={this.state.league}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="join" mountOnEnter unmountOnExit>
                            <LeagueJoinTabContent localUser={this.state.localUser}
                                                  league={this.state.league}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="event" mountOnEnter unmountOnExit>
                            <LeagueEventTabContent localUser={this.state.localUser}
                                                   league={this.state.league}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="game" mountOnEnter unmountOnExit>
                            <LeagueGameTabContent localUser={this.state.localUser}
                                                  league={this.state.league}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="chat" mountOnEnter unmountOnExit>
                            <LeagueChatTabContent localUser={this.state.localUser}
                                                  league={this.state.league}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="players" mountOnEnter unmountOnExit>
                            <LeaguePlayersTabContent localUser={this.state.localUser}
                                                     league={this.state.league}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class LeagueInfoTabContent extends React.Component {

    render() {

        return(
            <div>
                <LeagueBasicsManagementCard {...this.props}/>
                <LeagueImageUpdloadCard {...this.props}/>
                <LeagueStateManagementCard {...this.props}/>
                <LeagueVenueManagementCard {...this.props}/>
                <LeagueTopPostManagementCard {...this.props}/>
                <LeaguePollsManagementCard {...this.props}/>
                <LeagueRandomSelectorManagementCard {...this.props}/>
                <LeagueGoogleIntegrationCard {...this.props}/>
                <LeagueDeleteTheLeagueCard {...this.props}/>
            </div>
        )
    }
}

class LeagueJoinTabContent extends React.Component {

    render() {

        let showPaymentsCard = this.props.league.registration_fee_option !== 'disabled';

        return(
            <div>
                <LeagueOptionsManagementCard {...this.props}/>
                <LeaguePlayerFeeManagementCard {...this.props}/>
                {showPaymentsCard &&
                <LeaguePlayerPaymentsCard {...this.props}/>}
            </div>
        )
    }
}

class LeagueEventTabContent extends React.Component {
    
    /* cards needed:
       - A descriptive card that explains what Events are
       - Manage Calendar Entries card - allow league managers to add entries on the calendar
         - league nights
         - holidays
         - whatever - just messages basically tied to a date
       - Manage Events
    */

    render() {
        return(
            <div>
                <EventsDescriptionCard {...this.props}/>
                <LeagueEventsManagementCard {...this.props}/>
            </div>
        )
    }
}

class LeagueGameTabContent extends React.Component {

    /* cards needed:
       x A descriptive card that explains touraments vs casual games
       x A card to select if regular players can add casual games (or if only the managers can do it)
       - Tournament Management Card
       - A 'games' management card where all the stats are shown in a table and the manager can look at them all and select them to edit
    */
    
    render() {
        return(
            <div>
                <GameTypesDescriptionCard/>
                <LeagueTournamentsManagementCard {...this.props}/>
                <LeagueListContainerManagementCard {...this.props}/>
                <LeagueCasualGamesAddConfigCard {...this.props}/>
                <LeagueCasualGamesReviewCard {...this.props}/>
                <LeagueEloConfigurationCard {...this.props}/>
            </div>
        )
    }
}

class LeagueChatTabContent extends React.Component {
    
    /* cards needed:
       x Enable or disable chat setting card...but not much else
    */

    render() {
        return(
            <div>
                <LeagueChatManagementCard {...this.props}/>
            </div>
        )
    }
}

class LeaguePlayersTabContent extends React.Component {
    
    /* cards needed:
       x Manage Managers Card
       x Manage & Invite Players Card
    */
    render() {

        return(
            <div>
                <ManageManagersCard {...this.props} />
                <PlayersUsersCard {...this.props} />
                <LeagueExternalLinksManagementCard {...this.props} />
                <LeagueFilesManagementCard {...this.props}/>
            </div>
        )
    }
}

class LeagueBasicsManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueBasicsManagementCard'>
                <LeagueBasicsManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueBasicsManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            countryList: [],
            timezoneList: [],
            topicList: [],

            formName: '',
            formType: '',
            formTopicId: 0,
            formSummary: '',
            formCountry: 'United States',
            formZipcode: '',
            formContact: '',
            formOnline: false,
            formTimezone: 'America/Chicago',
            formStartDate: '',
            formEndDate: '',
            formStartTime: '',
            formOpenTime: '',
            formRoughDates: '',

            formErrors: {},
            formValidated: false,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);

        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('country-list', null, (d, k)=>this.updateCountryList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('timezone-list', null, (d, k)=>this.updateTimezoneList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('topic', null, (d, k)=>this.updateTopicList(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}, updating: false}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateCountryList(response, key) {
        this.setState({countryList: response});
    }

    updateTimezoneList(response, key) {
        this.setState({timezoneList: response});
    }

    updateTopicList(response, key) {
        this.setState({topicList: response});
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({
            formName: updatedLeague.name,
            formType: updatedLeague.type,
            formTopicId: updatedLeague.topic_id,
            formCountry: updatedLeague.country,
            formZipcode: updatedLeague.zipcode,
            formContact: updatedLeague.contact,
            formSummary: updatedLeague.summary,
            formOnline: updatedLeague.online,
            formTimezone: updatedLeague.timezone,
            formStartDate: updatedLeague.start_date || '',
            formEndDate: updatedLeague.end_date || '',
            formStartTime: updatedLeague.start_time || '',
            formOpenTime: updatedLeague.open_time || '',
            formRoughDates: updatedLeague.rough_dates,
            formHasBeenUpdated: false,
            updating: false,
        })
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, updating: true, formHasBeenUpdated: false});
            const patchData = {
                name: this.state.formName.trimEnd(),
                type: this.state.formType,
                topic_id: this.state.formTopicId,
                country: this.state.formCountry,
                timezone: this.state.formTimezone,
                zipcode: this.state.formZipcode.trimEnd(),
                contact: this.state.formContact.trimEnd(),
                online: this.state.formOnline,
                summary: this.state.formSummary.trimEnd(),
                start_date: this.state.formStartDate!=='' ? this.state.formStartDate : null,
                end_date: this.state.formEndDate!=='' ? this.state.formEndDate : null,
                start_time: this.state.formStartTime!=='' ? this.state.formStartTime : null,
                open_time: this.state.formOpenTime!=='' ? this.state.formOpenTime : null,
                rough_dates: this.state.formRoughDates,
            }
            this.restPubSub.patch('league', this.props.league.id, patchData);
        }
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this league name is too short');
        }
        this.setState({formName: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleTypeChange(value) {
        let errorState = clearErrorState('type', {...this.state.formErrors});
        this.setState({formType: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleTopicChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('topic_id', {...this.state.formErrors});
        this.setState({formTopicId: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleZipcodeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('zipcode', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('zipcode', {...this.state.formErrors}, 'this value is too short');
        }
        this.setState({formZipcode: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleCountryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('country', {...this.state.formErrors});
        this.setState({formCountry: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleTimezoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('timezone', {...this.state.formErrors});
        this.setState({formTimezone: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleContactChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('contact', {...this.state.formErrors});
        if (value.length < 10) {
            errorState = setErrorState('contact', {...this.state.formErrors}, 'this contact information is too short');
        }
        this.setState({formContact: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleSummaryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('summary', {...this.state.formErrors});
        if (value.length < 30) {
            errorState = setErrorState('summary', {...this.state.formErrors}, 'this summary is too short');
        }
        this.setState({formSummary: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleStartDateChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('start_date', {...this.state.formErrors});
        this.setState({formStartDate: value, formHasBeenUpdated: true, formErrors: errorState});
        // do some checking - if the end date is not set, set it now
        if (this.state.formEndDate === '') {
            this.setState({formEndDate: value});
        }
        // and if the end date is before the new start date, set the start date as the end date
        else {
            const startDateObj = new Date(value);
            const endDateObj = new Date(this.state.formEndDate);
            if (startDateObj > endDateObj) this.setState({formEndDate: value});
        }
    }

    handleEndDateChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('end_date', {...this.state.formErrors});
        this.setState({formEndDate: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleStartTimeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('start_time', {...this.state.formErrors});
        this.setState({formStartTime: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleOpenTimeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('open_time', {...this.state.formErrors});
        this.setState({formOpenTime: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleRoughDatesChange(event) {
        let value = event.target.checked;
        let errorState = clearErrorState('rough_dates', {...this.state.formErrors});
        this.setState({formRoughDates: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    handleOnlineChange(value) {
        let errorState = clearErrorState('online', {...this.state.formErrors});
        this.setState({formOnline: value, formHasBeenUpdated: true, formErrors: errorState});
    }

    render() {
        const countryOptions = [];
        for (const countryName of this.state.countryList) {
            countryOptions.push(<option key={countryName} value={countryName}>{countryName}</option>)
        }

        // create timezone priority options if the timezone matches the user's country
        const timezoneNamesPriority = [];
        const timezoneNamesOthers = [];
        for (const timezone of this.state.timezoneList) {
            if (timezone.country_name === this.props.league.country) {
                timezoneNamesPriority.push(timezone.timezone_name);
            } else {
                timezoneNamesOthers.push(timezone.timezone_name);
            } 
        }
        timezoneNamesPriority.sort();
        timezoneNamesOthers.sort();
        const timezoneOptions = [];
        for (const timezoneName of timezoneNamesPriority) {
            const timezoneItem = <option key={timezoneName} value={timezoneName}>{timezoneName}</option>
            timezoneOptions.push(timezoneItem);
        }
        for (const timezoneName of timezoneNamesOthers) {
            const timezoneItem = <option key={timezoneName} value={timezoneName}>{timezoneName}</option>
            timezoneOptions.push(timezoneItem);
        }

        const topicOptions = [];
        for (const topicData of this.state.topicList) {
            const topicId = topicData.id;
            const topicName = topicData.name;
            topicOptions.push(<option key={topicId} value={topicId}>{topicName}</option>)
        }

        // if the league has games, don't allow the topic to change
        let disableTopicChange = false;
        if (this.props.league.game_ids.length !== 0) disableTopicChange = true;

        let postalCodeText = 'Postal Code';
        if (this.state.formCountry === 'United States') {
            postalCodeText = 'Zipcode';
        }

        let onlineTitle = null;
        let onlineCurrentBlurb = null;
        let onlineDescription = null;
        if (this.state.formOnline) {
            onlineTitle = 'Online';
            onlineDescription = `Games are played over the Internet (using TTS, BGO, Google Hangouts, etc) and players are never expected to meet in person. When players are searching for ${this.props.league.getTypeWord()}, if they are looking for online ${this.props.league.getTypeWord()} yours will appear!`;
        } else {
            onlineTitle = 'In-Person';
            onlineDescription = `Games are mainly played in-person. Of course exceptions can be made, but this ${this.props.league.getTypeWord()} is not primarily focused on online play.`;
        }
        if (this.state.formOnline === this.props.league.online) onlineCurrentBlurb = " (the current setting)";

        let typeTitle = null;
        let typeCurrentBlurb = null;
        let typeDescription = null;
        if (this.state.formType === 'league') {
            typeTitle = 'League';
            typeDescription = "Typically spread over multiple weeks/months, may or may not include tournaments and narrative events. Often players are not required to participate in all weeks/events/games. If in doubt, this is the recommended setting.";
        } else if (this.state.formType === 'tournament') {
            typeTitle = 'Competitive Event';
            typeDescription = 'An event that is focused around a competitive tournament. Most competitive events have just a day or two of games. Unless players are cut or drop, they are normally expected to participate in all rounds. A "competitive event" can actually be composed of multiple tournaments (e.g. swiss + top cut).';
        }else if (this.state.formType === 'event') {
            typeTitle = 'Other Event';
            typeDescription = "Anything that isn't a league or a tournament specific event. This is a good choice if you are running an event that is a little outside the normal rules but will complete in a single day.";
        }
        if (this.state.formType === this.props.league.type) typeCurrentBlurb = " (the current setting)";
        
        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
        }

        // if any item has an error, is empty, or the passwords don't match, then the submit is disabled
        let disableSubmitButton = false;
        if (this.state.formName.length < 6 ||
            this.state.formSummary.length < 30 ||
            this.state.formZipcode.length < 3 ||
            this.state.formContact.length < 10 ||
            hasFormErrors === true || this.state.formHasBeenUpdated === false || this.state.updating) {
            disableSubmitButton = true;
        }

        // once a league is past the gathering interest/open for registration part no more rough dates
        let showRoughDatesOption = false;
        if (['draft', 'interest', 'open'].includes(this.props.league.state)) {
            showRoughDatesOption = true;
        }

        // figure out the min and max days for startDate and endDate - default is +/- 1 year from now
        let todayDate = new Date();
        let minStartDate = new Date();
        let maxStartDate = new Date();
        let minEndDate = new Date();
        let maxEndDate = new Date();
        minStartDate.setFullYear(todayDate.getFullYear() - 1);
        maxStartDate.setFullYear(todayDate.getFullYear() + 1);
        minEndDate.setFullYear(todayDate.getFullYear() - 1);
        maxEndDate.setFullYear(todayDate.getFullYear() + 1);
        // if the league has a start date set - set the end date limits based on that
        if (this.state.formStartDate) {
            minEndDate = new Date(this.state.formStartDate);
            maxEndDate = new Date(this.state.formStartDate);
            maxEndDate.setFullYear(minEndDate.getFullYear() + 1);
        }

        // set required state on dates based on league state
        let startDateRequired = false;
        let endDateRequired = false;
        switch(this.props.league.state) {
            case 'open':
                startDateRequired = true;
                break;
            case 'running':
                startDateRequired = true;
                endDateRequired = true;
                break;
            case 'complete':
                startDateRequired = true;
                endDateRequired = true;
                break;
            default:
                // nothing required
        }

        return(
            <NerdHerderStandardCardTemplate id="basics-card" title={`${this.props.league.getTypeWordCaps()} Basics`} titleIcon="cogwheel.png">
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{capitalizeFirstLetter(this.state.formType)} Name</Form.Label>
                        <Form.Control id='name' name='name' type="text" disabled={this.state.updating} onChange={(e)=>this.handleNameChange(e)} autoComplete='off' value={this.state.formName} minLength={6} maxLength={50} required/>
                        <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>This is the main identifier for the {this.props.league.getTypeWord()}.</Form.Text>
                    </Form.Group>

                    <Form.Group className='form-outline mb-2'>
                        <Form.Label>Focus</Form.Label>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-type' type="radio" value={this.state.formType} onChange={(v)=>this.handleTypeChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-league' value={'league'}>League</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-tournament' value={'tournament'}>Competitive Event</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-event' value={'event'}>Other Event</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-3'>
                        <Form.Text>
                            <p><b>{typeTitle}{typeCurrentBlurb}</b> - {typeDescription}</p>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Topic</Form.Label>
                        <Form.Select disabled={this.state.updating || disableTopicChange} onChange={(event)=>this.handleTopicChange(event)} value={this.state.formTopicId} required>
                            {topicOptions}
                        </Form.Select>
                        <FormErrorText errorId='topic' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>When users are searching, they can filter by topic. Once a game has been added to the {this.props.league.getTypeWord()} the topic cannot be changed.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Summary</Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='summary' name='summary' as="textarea" rows={4}  disabled={this.state.updating} onChange={(e)=>this.handleSummaryChange(e)} autoComplete='off' value={this.state.formSummary} minLength={30} maxLength={200} required/>
                            <FormTextInputLimit current={this.state.formSummary.length} max={200}/>
                        </div>
                        <FormErrorText errorId='summary' errorState={this.state.formErrors}/>
                        
                        <Form.Text className='text-muted'>A brief summary of the {this.props.league.getTypeWord()}. Slow-grow? Casual? Open-ended? This is the first description users see about your {this.props.league.getTypeWord()}.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Contact</Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control id='contact' name='contact' as="textarea" rows={3}  disabled={this.state.updating} onChange={(e)=>this.handleContactChange(e)} autoComplete='off' value={this.state.formContact} minLength={10} maxLength={200} required/>
                            <FormTextInputLimit current={this.state.formContact.length} max={200}/>
                        </div>
                        <FormErrorText errorId='contact' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>A way for potential participants to ask questions. Might be an email or phone number, discord, a facebook group.</Form.Text>
                    </Form.Group>

                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-online' type="radio" value={this.state.formOnline} onChange={(v)=>this.handleOnlineChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-in-person' value={false}>In-Person</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-online' value={true}>Online</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-3'>
                        <Form.Text>
                            <p><b>{onlineTitle}{onlineCurrentBlurb}</b> - {onlineDescription}</p>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{`${postalCodeText}, Country & Timezone`}</Form.Label>
                        <Form.Control id='zipcode' name='zipcode'className='mb-1' type="text" disabled={this.state.updating} onChange={(event)=>this.handleZipcodeChange(event)} autoComplete='postal-code' value={this.state.formZipcode} minLength={3} maxLength={45} required/>
                        <FormErrorText errorId='zipcode' errorState={this.state.formErrors}/>
                        <Form.Select id='country' name='country' className='mb-1' disabled={this.state.updating} onChange={(event)=>this.handleCountryChange(event)} value={this.state.formCountry} required>
                            {countryOptions}
                        </Form.Select>
                        <Form.Select id='timezone' name='timezone' disabled={this.state.updating} onChange={(event)=>this.handleTimezoneChange(event)} value={this.state.formTimezone} required>
                            {timezoneOptions}
                        </Form.Select>
                        <FormErrorText errorId='country' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Used to find players for your {this.props.league.getTypeWord()}, and present time & date information to them in their local timezone.</Form.Text>
                    </Form.Group>

                    <Form.Group className="form-outline mb-3">
                        <Row className='mb-2'>
                            <Col xs={6}>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control id="start_date" name="start_date" type="date" disabled={this.state.updating} onChange={(event)=>this.handleStartDateChange(event)} autoComplete='off' value={this.state.formStartDate} min={convertDateObjectToFormInput(minStartDate)} max={convertDateObjectToFormInput(maxStartDate)} required={startDateRequired}/>
                                <FormErrorText errorId='start_date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>When does the {this.props.league.getTypeWord()} start?</Form.Text>
                            </Col>
                            <Col xs={6}>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control id="end_date" name="end_date" type="date" disabled={this.state.updating} onChange={(event)=>this.handleEndDateChange(event)} autoComplete='off' value={this.state.formEndDate} min={convertDateObjectToFormInput(minEndDate)} max={convertDateObjectToFormInput(maxEndDate)} required={endDateRequired}/>
                                <FormErrorText errorId='end_date' errorState={this.state.formErrors}/>
                                <Form.Text className='text-muted'>When does the {this.props.league.getTypeWord()} end?</Form.Text>
                            </Col>
                        </Row>
                        {showRoughDatesOption &&
                        <Row className='mb-2'>
                            <Col>
                                <Form.Check id="rough_dates" name="rough_dates" label='Approximate Dates' disabled={this.state.updating} onChange={(event)=>this.handleRoughDatesChange(event)} autoComplete='off' checked={this.state.formRoughDates}/>
                                <Form.Text className='text-muted'>Select if the start & end dates are not yet solid.</Form.Text>
                            </Col>
                        </Row>}
                        <Collapse in={this.state.formType === 'tournament' || this.state.formType === 'event'}>
                            <div>
                                <Row className='mb-2'>
                                    <Col xs={6}>
                                        <Form.Label>Doors Open Time</Form.Label>
                                        <Form.Control id="open_time" name="open_time" type="time" disabled={this.state.updating || this.state.formStartDate===''} onChange={(event)=>this.handleOpenTimeChange(event)} autoComplete='off' value={this.state.formOpenTime}/>
                                        <FormErrorText errorId='open_time' errorState={this.state.formErrors}/>
                                        <Form.Text className='text-muted'>When can players begin arriving?</Form.Text>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Label>Start Time</Form.Label>
                                        <Form.Control id="start_time" name="start_time" type="time" disabled={this.state.updating || this.state.formStartDate===''} onChange={(event)=>this.handleStartTimeChange(event)} autoComplete='off' value={this.state.formStartTime}/>
                                        <FormErrorText errorId='start_time' errorState={this.state.formErrors}/>
                                        <Form.Text className='text-muted'>When is the event expected to kick-off?</Form.Text>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                    </Form.Group>
                    <Button type='submit' className="float-end" variant='primary' disabled={disableSubmitButton}>Update</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueStateManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueStateManagementCard'>
                <LeagueStateManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueStateManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            stateOption: '',
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({stateOption: updatedLeague.state, updating: false})
    }

    onUpdateState() {
        const patchData = {
            state: this.state.stateOption
        }

        // automatically turn off rough dates for open, running, and completed leagues
        if (['open', 'running', 'completed'].includes(this.state.stateOption)) {
            patchData['rough_dates'] = false;
        }

        this.restPubSub.patch('league', this.props.league.id, patchData);
        this.setState({updating: true})
    }

    handleStateChange(value) {
        this.setState({stateOption: value})
    }

    render() {
        const currentState = this.props.league.state;

        let stateTitle = null;
        let stateDescription = null;
        let stateRequirements = null;
        let requirementsMet = false;
        let typeWord = this.props.league.getTypeWord();
        switch(this.state.stateOption) {
            case 'draft':
                stateTitle = "Draft";
                stateDescription = `The ${typeWord} is hidden from other users. Get setup in this state. If you want to hide your ${typeWord} while you make changes or to save it for later, return it to this state.`;
                requirementsMet = true;
                break;

            case 'interest':
                stateTitle = "Gathering Interest";
                stateDescription = `Use this state to see if users will join your ${typeWord}. In this state users can see the ${typeWord}, it's summary, dates, etc. This is essentially a promotional state announcing that you'd like to start a ${typeWord} but want to get input before making any commitments. If you know your ${typeWord} will move forward, skip this state and go straight to open.`;
                stateRequirements = `To switch to this state, your ${typeWord} must have a non-default image set.`;
                if (this.props.league.image !== null && !this.props.league.image.includes('kraken.png')) {
                    requirementsMet = true;
                }
                break;

            case 'open':
                stateTitle = "Open for Registration";
                stateDescription = `Users can join your ${typeWord}, dates are solid...Basically it's a go and just looking to get people signed up. Casual games can be played in this state.`;
                stateRequirements = `To switch to this state, your ${typeWord} must have a non-default image and a start date set.`;
                if (this.props.league.image !== null && !this.props.league.image.includes('kraken.png') && 
                    this.props.league.start_date !== null) {
                    requirementsMet = true;
                }
                break;

            case 'running':
                stateTitle = "Running";
                stateDescription = `The main participation state of the ${typeWord}. In addition to casual games, events and tournaments can be run as well. It is still possible to register new players in this state.`;
                stateRequirements = `To switch to this state, your ${typeWord} must have a non-default image and its start & end dates set.`;
                if (this.props.league.image !== null && !this.props.league.image.includes('kraken.png') && 
                    this.props.league.start_date !== null && this.props.league.end_date !== null) {
                    requirementsMet = true;
                }
                break;

            case 'complete':
                stateTitle = "Completed";
                stateDescription = `The ${typeWord} is basically done. Games, events, and tournaments may be updated, but not added.`;
                stateRequirements = `To switch to this state, your ${this.props.league.getTypeWordPossessive()} end date must have passed.`;
                let date = new Date();
                date.setHours(0,0,0,0);
                if (this.props.league.image !== null && !this.props.league.image.includes('kraken.png') && 
                    this.props.league.start_date !== null && this.props.league.end_date !== null) {
                    let endDate = new Date(this.props.league.end_date);
                    if (endDate <= date) {
                        requirementsMet = true;
                    }
                }
                break;
            
            default:
                stateTitle = null;
                stateDescription = null;
                stateRequirements = null;
        }

        let disableUpdateButton = true;
        if (requirementsMet && this.state.updating === false && this.props.league.state !== this.state.stateOption) {
            disableUpdateButton = false;
        }

        let currentBlurb = null;
        if (this.state.stateOption === currentState) currentBlurb = " (the current state)"

        let leagueNotPublicAlert = null;
        if (this.props.league.state === 'draft') leagueNotPublicAlert = `Draft ${this.props.league.getTypeWordPlural()} are hidden from users. Switch to another state to make it public!`;

        let updateButtonText = 'Update';
        if (this.props.league.state === 'draft' && this.state.stateOption !== 'draft') updateButtonText = 'Update & Make Public'

        return(
            <NerdHerderStandardCardTemplate id="state-card" title={`${this.props.league.getTypeWordCaps()} State`} titleIcon="loading.png" errorFeedback={leagueNotPublicAlert}>
                {leagueNotPublicAlert &&
                <div className='mt-3'></div>}
                <Form>
                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-state' type="radio" value={this.state.stateOption} onChange={(e)=>this.handleStateChange(e)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-state-draft' value={'draft'}>Draft</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-state-interest' value={'interest'}>Interest</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-state-open' value={'open'}>Open</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-state-running' value={'running'}>Running</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-state-complete' value={'complete'}>Complete</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{stateTitle}{currentBlurb}</b> - {stateDescription}</p>
                            {requirementsMet &&
                            <p>{stateRequirements}</p>}
                            {!requirementsMet &&
                            <p className='text-danger'><b>{stateRequirements}</b></p>}
                        </Form.Text>
                    </Form.Group>
                    <Button type='button' className="float-end" variant='primary' disabled={disableUpdateButton} onClick={()=>this.onUpdateState()}>{updateButtonText}</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueImageUpdloadCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueImageUpdloadCard'>
                <LeagueImageUpdloadCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueImageUpdloadCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.dzRef = React.createRef();

        this.state = {
            updating: false,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        this.setState({updating: false})
    }

    onDzSending(file) {
        this.setState({updating: true})
    }

    onDzSuccess(file) {
        this.dzRef.current.clearUploadedFiles();
        this.restPubSub.refresh('league', this.props.league.id);
    }

    onDzError(file) {
        this.dzRef.current.clearUploadedFiles();
        this.restPubSub.refresh('league', this.props.league.id);
    }

    render() {
        return(
            <NerdHerderStandardCardTemplate id="image-upload-card" title={`${this.props.league.getTypeWordCaps()} Image`} titleIcon='picture.png'>
                <Form>
                    <Form.Group className="mb-2">
                        <Row>
                            <Col>
                                <p><small className='text-muted'>Players use this image to quickly identify your {this.props.league.getTypeWord()}. Make it a good one! It must be updated from the default to make your {this.props.league.getTypeWord()} public.</small></p>
                            </Col>
                        </Row>
                        <Row className='text-center align-items-center'>
                            <Col xs='auto'>
                                {!this.state.updating &&
                                <Image className='rounded mb-1' src={this.props.league.getImageUrl()} width='100px' alt='league image'/>}
                                {this.state.updating &&
                                <Spinner variant="primary" animation="border" role="status" aria-hidden="true" style={{width: '100px', height: '100px'}}/>}
                            </Col>
                            <Col>
                                <NerdHerderDropzoneImageUploader
                                    ref={this.dzRef}
                                    localUser={this.props.localUser}
                                    message={'Drop file here to update'}
                                    uploadUrl={`/rest/v1/dz-league-image-upload/${this.props.league.id}`}
                                    sendingCallback={(f)=>this.onDzSending(f)}
                                    successCallback={(f)=>this.onDzSuccess(f)}
                                    errorCallback={(f)=>this.onDzError(f)}/>
                            </Col>
                        </Row>
                    </Form.Group>

                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueVenueManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueVenueManagementCard'>
                <LeagueVenueManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueVenueManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            navigateTo: null,
            updating: false,
            showSelectVenueModal: false,
            formVenueId: null,
            formVenueString: '',
            formErrors: {},
            formValidated: false,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);

        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}, updating: false}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({
            formVenueId: updatedLeague.venue_id,
            formVenueString: updatedLeague.venue_string,
            updating: false,
        })
    }

    onSetVenue(id) {
        // setting a venue removes the need for venue_string
        let errorState = clearErrorState('venue_string', {...this.state.formErrors});
        this.setState({formVenueId: id, updating: true, showSelectVenueModal: false, formErrors: errorState});
        this.restPubSub.patch('league', this.props.league.id, {venue_id: id, venue_string: ''});
    }

    onRemoveVenue() {
        // if the venue is removed, need to set error state on the venue_string
        let errorState = null;
        if (this.state.formVenueString.length === 0) {
            errorState = setErrorState('venue_string', {...this.state.formErrors}, 'you must select a registered venue or add the venue information here');
        }
        else if (this.state.formVenueString.length < 10) {
            errorState = setErrorState('venue_string', {...this.state.formErrors}, 'this venue information is too short');
        }
        if (errorState !== null) {
            this.setState({formErrors: errorState});
        }

        this.setState({formVenueId: null, updating: true, showSelectVenueModal: false});
        this.restPubSub.patch('league', this.props.league.id, {venue_id: null});
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, updating: true, formHasBeenUpdated: false});
            const patchData = {
                venue_id: this.state.formVenueId,
                venue_string: this.state.formVenueString.trimEnd(),
            }
            this.restPubSub.patch('league', this.props.league.id, patchData);
        }
    }

    handleVenueIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('venue_id', {...this.state.formErrors});
        
        this.setState({formVenueId: value, formErrors: errorState});
    }

    handleVenueStringChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('venue_string', {...this.state.formErrors});
        if (value.length === 0 && this.state.formVenueId === null) {
            errorState = setErrorState('venue_string', {...this.state.formErrors}, 'you must select a registered venue or add the venue information here');
        }
        else if (value.length !== 0 && value.length < 10) {
            errorState = setErrorState('venue_string', {...this.state.formErrors}, 'this venue information is too short');
        }
        this.setState({formVenueString: value, formErrors: errorState});
    }

    render() {
        if (this.state.navigateTo) return (<Navigate to={this.state.navigateTo}/>);

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
            break;
        }

        // if any item has an error, is empty, or the passwords don't match, then the submit is disabled
        let disableSubmitButton = false;
        if ((!this.state.formVenueId && this.state.formVenueString.length < 10) ||
            this.state.formVenueString === this.props.league.venue_string ||
            hasFormErrors === true || this.state.updating) {
            disableSubmitButton = true;
        }

        return(
            <NerdHerderStandardCardTemplate id="venue-management-card" title={`${this.props.league.getTypeWordCaps()} Venue`} titleIcon="building_config.png">
                {this.state.showSelectVenueModal &&
                <NerdHerderVenueSearchModal localUser={this.props.localUser}
                                            onAccept={(id)=>this.onSetVenue(id)}
                                            onCancel={()=>this.setState({showSelectVenueModal: false})}
                                            selectedVenueId={this.state.formVenueId}/>}
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className="form-outline mb-3">
                        {!this.props.league.online &&
                        <Form.Text className='muted'>Where will games be played? You may select a venue registered on NerdHerder, or manually enter an address below. Venues on NerdHerder may accept online player registration fees when players join the {this.props.league.getTypeWord()}.</Form.Text>}
                        {this.props.league.online &&
                        <Form.Text className='muted'>Online leagues may select a registered venue to enable collecting payments from players when they sign up. Additionally, you must add details how the games will be played below (e.g. Tabletop Simulator, Board Games Online, Zoom).</Form.Text>}
                    </Form.Group>
                    <Form.Group className="form-outline mb-3">
                        {!this.state.formVenueId &&
                        <Row>
                            <Col sm={6}>
                                <Form.Label>Registered Venue</Form.Label>
                                <br/>
                                <Form.Text><b>No registered venue selected</b></Form.Text>
                            </Col>
                            <Col sm={6}>
                                <div className='d-grid gap-2'>
                                    <Button variant='primary' size='sm' onClick={()=>this.setState({showSelectVenueModal: true})}>Select Existing Venue</Button>
                                    <Button variant='primary' size='sm' onClick={()=>this.setState({navigateTo: `/app/newvenue?league-id=${this.props.league.id}`})}>Register New Venue</Button>
                                </div>
                            </Col>
                        </Row>}
                        {this.state.formVenueId &&
                        <div>
                            <Row>
                                <Col>
                                    <VenueListItem key={this.state.formVenueId} venueId={this.state.formVenueId} localUser={this.props.localUser}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs='auto'>
                                    <Button variant='danger' size='sm' onClick={()=>this.onRemoveVenue()}>Remove</Button>
                                    {' '}
                                    <Button variant='primary' size='sm' onClick={()=>this.setState({showSelectVenueModal: true})}>Change</Button>
                                </Col>
                            </Row>
                        </div>}
                    </Form.Group>
                    <Form.Group className="form-outline mb-3">
                        {!this.props.league.online && !this.state.formVenueId &&
                        <div className='mb-2'>
                            <hr/>
                            <Form.Label>Manual Venue Location</Form.Label>
                            <br/>
                            <Form.Text className='mb-1'>Not all venues are registered (maybe you just play at home), you can simply enter an address here instead.</Form.Text>
                        </div>}
                        {!this.props.league.online && this.state.formVenueId &&
                        <div className='mb-2'>
                            <Form.Label>Override Venue Location</Form.Label>
                            <br/>
                            <Form.Text>If games will not be played at the venue (e.g. a special event at a different location), you can override the venue's location by entering a different address below.</Form.Text>
                        </div>}
                        {this.props.league.online &&
                        <Form.Label>Online System (e.g. TTS, BGO, Zoom)</Form.Label>}
                        <div style={{position: 'relative'}}>
                            <Form.Control id='venue_string' name='venue_string' as="textarea" rows={2}  disabled={this.state.updating} onChange={(e)=>this.handleVenueStringChange(e)} autoComplete='off' value={this.state.formVenueString} minLength={10} maxLength={100}/>
                            <FormTextInputLimit current={this.state.formVenueString.length} max={100}/>
                        </div>
                        <FormErrorText errorId='venue_string' errorState={this.state.formErrors}/>
                    </Form.Group>
                    <Button type='submit' className="float-end" variant='primary' disabled={disableSubmitButton}>Update</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeaguePlayerFeeManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeaguePlayerFeeManagementCard'>
                <LeaguePlayerFeeManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeaguePlayerFeeManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        let registrationFeeMessage = '';
        if (this.props.league.registration_fee_message) registrationFeeMessage = this.props.league.registration_fee_message;

        this.state = {
            updating: false,
            stripeInfo: null,
            stripeAccount: null,
            league: this.props.league,
            venue: null,
            countryCurrencyList: null,
            currencyList: null,
            currencyDict: null,
            codes: [],
            codeAmounts: [],
            codeOneTimeUses: [],
            codesUpdated: false,
            formValidated: false,
            formErrors: {},
            formRegistrationFeeOption:      this.props.league.registration_fee_option,
            formRegistrationFeeOverhead:    this.props.league.registration_fee_overhead,
            formRegistrationFeeCurrency:    this.props.league.registration_fee_currency,
            formRegistrationFeeCost:        this.props.league.registration_fee_cost,
            formRegistrationFeeCostUpdated: false,
            formRegistrationFeeMessage:     registrationFeeMessage,
        }

        // make sure we only get the currency lists once
        this.fetchCurrencyListsOnce = true;
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('stripe-info', null, (d,k)=>this.updateStripeInfo(d,k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
        if (this.props.league.venue_id) {
            sub = this.restPubSub.subscribe('venue', this.props.league.venue_id, (d, k)=>this.updateVenue(d, k), null, this.props.league.venue_id);
            this.restPubSubPool.add(sub);
            sub = this.restPubSub.subscribe('venue-stripe', this.props.league.venue_id, (d, k)=>this.updateStripeAccount(d, k), (e, a)=>this.updateStripeAccountFailed(e, a), this.props.league.venue_id);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague, updating: false});
        
        // wipe out the venue if the venue id has changed or if the new league has no venue
        if (this.state.venue !== null && this.state.venue.id !== newLeague.venue_id) {
            this.setState({venue: null});
        } else if (newLeague.venue_id === null) {
            this.setState({venue: null});
        }
    }

    updateVenue(venueData, key) {
        // only accept the venue if it's for this league
        if (this.state.league.venue_id !== null && key === this.state.league.venue_id) {
            const newVenue = NerdHerderDataModelFactory('venue', venueData);
            this.setState({venue: newVenue})
            this.fetchCurrencyLists();
        }
    }

    updateStripeInfo(stripeInfo, key) {
        this.setState({stripeInfo: stripeInfo});
    }

    updateStripeAccount(stripeAccountInfo, key) {
        // only accept the stripe account info if it's for this league
        if (this.state.league.venue_id !== null && key === this.state.league.venue_id) {
            this.setState({stripeAccount: stripeAccountInfo});
        }
    }

    // it is possible the venue doesn't have a stripe account yet
    updateStripeAccountFailed(error, apiName) {
        this.setState({stripeAccount: null});
    }

    fetchCurrencyLists() {
        if (this.fetchCurrencyListsOnce) {
            let sub = this.restPubSub.subscribe('country-currency-list', null, (d, k)=>this.updateCountryCurrencyList(d, k));
            this.restPubSubPool.add(sub);
            sub = this.restPubSub.subscribe('currency-list', null, (d, k)=>this.updateCurrencyList(d, k));
            this.restPubSubPool.add(sub);
            this.fetchCurrencyListsOnce = false;
        }
    }

    updateCountryCurrencyList(listData, key) {
        let currency = this.state.formRegistrationFeeCurrency;
        // the default is USD - if the currency is set to USD & the registration cost is 0, auto set the currency
        if (currency === 'USD' && this.state.formRegistrationFeeCost === 0) {
            for (const currencyRow of listData) {
                if (currencyRow.country_name === this.state.venue.country) {
                    currency = currencyRow.alpha_id;
                    break;
                }
            }
        }
        this.setState({countryCurrencyList: listData, formRegistrationFeeCurrency: currency});
    }

    updateCurrencyList(listData, key) {
        const newCurrencyDict = convertListToDict(listData, 'alpha_id');
        this.setState({currencyList: listData, currencyDict: newCurrencyDict});
        setTimeout(()=>this.parseCodeString(this.props.league.registration_fee_codes), 100);
    }

    parseCodeString(codeText) {
        const newCodes = [];
        const newAmounts = [];
        const newOneTimeUses = [];
        if (codeText.length !== 0) {
            const lines = codeText.split('\n');
            for (const line of lines) {
                if (line.length === 0) continue;
                if (line.includes('|')) {
                    const result = line.split('|', 3);
                    if (result.length !== 3) continue;
                    let code = result[0];
                    let amount = parseInt(result[1].trim());
                    let oneTimeUse = result[2];
                    newCodes.push(code.trim());
                    newAmounts.push(amount);
                    newOneTimeUses.push(oneTimeUse.trim());
                }
            }
            this.setState({codes: newCodes, codeAmounts: newAmounts, codeOneTimeUses: newOneTimeUses, codesUpdated: false});
        }
    }

    getCodeString() {
        let newCodeString = '';
        for (let i=0; i<this.state.codes.length; i++) {
            let code = this.state.codes[i].trim();
            let amount = this.state.codeAmounts[i];
            let oneTimeUse = this.state.codeOneTimeUses[i];
            newCodeString += `${code}|${amount}|${oneTimeUse}\n`;
        }
        return newCodeString;
    }

    onAddRow() {
        const newCodes = [...this.state.codes];
        const newAmounts = [...this.state.codeAmounts];
        const newOneTimeUses = [...this.state.codeOneTimeUses];
        newCodes.push('NEWCODE');
        newAmounts.push(this.state.formRegistrationFeeCost);
        newOneTimeUses.push('u');
        this.setState({codes: newCodes, codeAmounts: newAmounts, codeOneTimeUses: newOneTimeUses, codesUpdated: true});
    }

    onDeleteRow(index) {
        const newCodes = [...this.state.codes];
        const newAmounts = [...this.state.codeAmounts];
        const newOneTimeUses = [...this.state.codeOneTimeUses];
        newCodes.splice(index, 1);
        newAmounts.splice(index, 1);
        newOneTimeUses.splice(index, 1);
        this.setState({codes: newCodes, codeAmounts: newAmounts, codeOneTimeUses: newOneTimeUses, codesUpdated: true});
    }

    handleCodeChange(index, event) {
        let value = event.target.value;
        // don't let the user add a |, space, or _ character, and make all characters upper case
        value = value.replaceAll('|', '');
        value = value.replaceAll('_', '');
        value = value.toUpperCase();
        const newCodes = [...this.state.codes];
        newCodes.splice(index, 1, value);
        this.setState({codesUpdated: true, codes: newCodes});
    }

    handleCodeAmountChange(value, name, index) {
        // the FormCurrencyInput sometimes calls this when setting the default value, so don't change anything if the value is the same
        if (value === this.state.codeAmounts[index]) return;

        const newAmounts = [...this.state.codeAmounts];
        newAmounts.splice(index, 1, value);
        this.setState({codesUpdated: true, codeAmounts: newAmounts});
    }

    handleCodeOneTimeUseChange(index, event) {
        let value = event.target.value;
        const newOneTimeUses = [...this.state.codeOneTimeUses];
        newOneTimeUses.splice(index, 1, value);
        this.setState({codesUpdated: true, codeOneTimeUses: newOneTimeUses});
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        // need to convert the codes into a string
        let registrationFeeCodeString = this.getCodeString();

        if (valid) {
            this.setState({formValidated: true, updating: true, codesUpdated: false});
            let registrationMessage = this.state.formRegistrationFeeMessage.trim();
            if (registrationMessage.length === 0) registrationMessage = null;
            const patchData = {
                registration_fee_option: this.state.formRegistrationFeeOption,
                registration_fee_overhead: this.state.formRegistrationFeeOverhead,
                registration_fee_currency: this.state.formRegistrationFeeCurrency,
                registration_fee_cost: this.state.formRegistrationFeeCost,
                registration_fee_codes: registrationFeeCodeString,
                registration_fee_message: registrationMessage,
            }
            this.restPubSub.patch('league', this.props.league.id, patchData);
        }
    }

    handleOptionChange(value) {
        this.setState({formRegistrationFeeOption: value});
    }

    handleOverheadChange(value) {
        this.setState({formRegistrationFeeOverhead: value});
    }

    handleRegistrationFeeCostChange(value, name) {
        this.setState({formRegistrationFeeCost: value, formRegistrationFeeCostUpdated: true});
    }

    handleRegistrationFeeCurrencyChange(event) {
        let value = event.target.value;
        this.setState({formRegistrationFeeCurrency: value});
    }

    handleRegistrationMessageChange(event) {
        let value = event.target.value;
        this.setState({formRegistrationFeeMessage: value});
    }

    render() {
        if (this.state.stripeInfo === null) return(null);

        /* This render is divided into 2 parts:
           Part 1 - determine if the league has a stripe account and we have the currency info required to render the form
           Part 2 - the form where all the league registration fee options are set */
        
        // ***** PART 1 *****
        // Determine if the league is in a position to allow registration fee payments
        //    To show the payment options, a league must:
        //    1) Have a Venue
        //    2) The Venue must have a stripe account (this is part of venue.isPaymentEnabled)
        //    3) Stripe onboarding process must be complete (this is part of venue.isPaymentEnabled)
        //    4) The league must not be using 'join via request'
        let venuePaymentsEnabled = false;
        if (this.state.venue) venuePaymentsEnabled = this.state.venue.isPaymentEnabled();
        let showRegistrationFeeOptions = venuePaymentsEnabled;
        if (this.props.league.join_league_via_request) showRegistrationFeeOptions = false;

        const metStyle = {color: 'blue'};
        const notMetStyle = {color: 'red'};
        
        let condition1Style = notMetStyle;
        let condition1Met = false;
        let condition1Link = null;
        if (this.state.league.venue_id !== null) {
            condition1Style = metStyle;
            condition1Met = true;
        } else {
            condition1Link = <span> (<a href={`/app/manageleague/${this.state.league.id}?focus=venue-management-card&tab=info`}>fix it</a>)</span>
        }
        
        let condition2Style = notMetStyle;
        let condition2Met = false;
        let condition2Link = null;
        if (this.state.league.venue_id && this.state.venue && this.state.venue.stripe_account_id) {
            condition2Style = metStyle;
            condition2Met = true;
        } else {
            if (condition1Met) {
                condition2Link = <span> (<a href={`/app/managevenue/${this.state.league.venue_id}?focus=manage-stripe-card`}>fix it</a>)</span>
            }
        }
        
        let condition3Style = notMetStyle;
        let condition3Met = false;
        let condition3Link = null;
        if (this.state.league.venue_id && this.state.venue && venuePaymentsEnabled) {
            condition3Style = metStyle;
            condition3Met = true;
        } else {
            if (condition1Met && condition2Met) {
                condition3Link = <span> (<a href={`/app/managevenue/${this.state.league.venue_id}?focus=manage-stripe-card`}>fix it</a>)</span>
            }
        }

        let condition4Style = notMetStyle;
        let condition4Met = false;
        let condition4Link = null;
        if (!this.state.league.join_league_via_request) {
            condition4Style = metStyle;
            condition4Met = true;
        } else {
            if (condition1Met && condition2Met && condition3Met) {
                condition4Link = <span> (<a href={`/app/manageleague/${this.state.league.id}?focus=privacy-card&tab=join`}>fix it</a>)</span>
            }
        }

        // could get into situation where the user has set a venue, but cannot manage the venue and therefore cannot fix stripe details
        let notVenueManagerAlert = null;
        if (condition1Met && (!condition2Met || !condition3Met) && this.state.venue && !this.state.venue.isManager(this.props.localUser.id)) {
            condition1Link = null;
            condition2Link = null;
            condition3Link = null;
            condition4Link = null;
            notVenueManagerAlert = <Alert variant='warning'>You are not a manager of the venue ({this.state.venue.name}), and therefore will not be able to add or configure a Stripe account!</Alert>
        }
        
        // here we make the determination if we show only the conditions or show the main form
        // in addition to not being able to show the main form if the league doesn't have the right settings it is
        // also possible that we don't have currency info yet...so hold off on showing the form
        let showMainForm = false;
        if (condition1Met && condition2Met && condition3Met && condition4Met && showRegistrationFeeOptions &&
            this.state.countryCurrencyList !== null && this.state.currencyList !== null) {
            showMainForm = true;
        }

        // this is the end of part 1 - if we can't show the main form we show this 'conditions' render instead
        // NOTE: the id needs to be the same between both renders!
        if (!showMainForm) {
            return(
                <NerdHerderStandardCardTemplate id="registration-fees-card" title={`${this.props.league.getTypeWordCaps()} Registration Fees`} titleIcon="stripe.png">
                    <div>
                        {notVenueManagerAlert}
                        <small className='text-muted'>This league is not configured to accept player registration fees through NerdHerder. It is not difficult to set this up, it takes just a few minutes.</small>
                        <br/>
                        <small className='text-muted'>To collect fees, do the following:</small>
                        <small className='text-muted'>
                            <ol>
                                <li style={condition1Style}>Set the {this.props.league.getTypeWordPossessive()} venue to one registered on NerdHerder {condition1Link}</li>
                                <li style={condition2Style}>Add a Stripe account to the venue {condition2Link}</li>
                                <li style={condition3Style}>Complete the Stripe account onboarding {condition3Link}</li>
                                <li style={condition4Style}>Ensure your league is <b>not</b> configured to join "Via Request" {condition4Link}</li>
                            </ol>
                        </small>
                    </div>
                </NerdHerderStandardCardTemplate>
            );
        }
        
        // ***** PART 2 *****
        // if we have reached this point then we have what is needed to show the main form
        
        // the FormCurrencyInput fields won't regenerate their symbols from props, so if they need to change we generate a new key
        let currentCurrencyAlphaId = this.props.league.registration_fee_currency;
        let currencyMinorDigits = 2;
        let currencyDecimal = '.';
        let currencySymbol = '';
        let currencyData = null;
        let minimumAmountValue = 0;
        let minimumAmountCurrency = null;
        let minimumFeeError = null;
        let minimumCodeFeeError = null;
        if (this.state.currencyDict !== null && this.state.formRegistrationFeeCurrency) {
            currencyData = this.state.currencyDict[this.state.formRegistrationFeeCurrency];
            currencyMinorDigits = currencyData.minor_digits;
            currencySymbol = currencyData.symbol
            currencyDecimal = decimalSeparator(this.props.localUser.country);
        }

        // setup for checking against minimum amounts later - it's lower for china because a Yuan has more value
        if (this.state.formRegistrationFeeCurrency === 'CNY') {
            minimumAmountValue = 50;
        } else {
            minimumAmountValue = 200;
        }
        if (minimumAmountValue !== 0 && currencyData) {
            minimumAmountCurrency = getCurrency(minimumAmountValue, currencyData, this.props.localUser.country);
        }

        // create currency options
        const currencyOptions = [];
        for (const currency of this.state.currencyList) {
            let currencyName = currency.currency_name;
            if (currencyName !== 'Won' && currencyName !== 'Yen' && currencyName !== 'Yuan' && currencyName !== 'Pound Sterling') {
                currencyName = pluralize(currencyName);
            }
            const currencyItem = <option key={currency.alpha_id} value={currency.alpha_id}>{currencyName} ({currency.alpha_id})</option>
            currencyOptions.push(currencyItem);
        }

        let registrationFeeTitle = null;
        let registrationFeeDescription = null;
        let registrationFeeCurrentBlurb = null;
        switch(this.state.formRegistrationFeeOption) {
            case 'disabled':
                registrationFeeTitle = "Disabled";
                registrationFeeDescription = `No registration fees are collected by NerdHerder. If the ${this.props.league.getTypeWord()} has registration fees, they are collected some other way.`;
                break;

            case 'optional':
                registrationFeeTitle = "Optional";
                registrationFeeDescription = `When a player joins the ${this.props.league.getTypeWord()}, they are given the option of paying the registration fee through NerdHerder (it is implied that payment in person is also an option).`;

                break;

            case 'required':
                registrationFeeTitle = "Required";
                registrationFeeDescription = `When a player joins the ${this.props.league.getTypeWord()}, they are required to immediately pay the registration fee through NerdHerder.`;
                break;
            
            default:
                registrationFeeTitle = null;
                registrationFeeDescription = null;
        }
        if (this.state.formRegistrationFeeOption === this.state.league.registration_fee_option) registrationFeeCurrentBlurb = " (the current setting)"

        let registrationFeeOverheadTitle = null;
        let registrationFeeOverheadDescription = null;
        let registrationFeeOverheadCurrentBlurb = null;
        switch(this.state.formRegistrationFeeOverhead) {
            case 'venue':
                registrationFeeOverheadTitle = "Venue";
                registrationFeeOverheadDescription = <span>The venue covers the Stripe processing charge by having Stripe take the fee from each transaction. The effect of paying online is hidden from the player (this is the recommended setting).<br/><i>Example with a $15 registration fee: The player pays $15, Stripe takes $0.75 and the venue collects $14.25.</i></span>;
                break;

            case 'players':
                registrationFeeOverheadTitle = "Player";
                registrationFeeOverheadDescription = <span>The player's cost is increased to offset the Stripe processing charge. The effect of paying online is apparent to the player, which may discourage online payments.<br/><i>Example with a $15 registration fee: The player pays $15.75, Stripe takes $0.75 and the venue collects $15.</i></span>;

                break;
            
            default:
                registrationFeeOverheadTitle = null;
                registrationFeeOverheadDescription = null;
        }
        if (this.state.registrationFeeOverheadCurrentBlurb === this.state.league.registration_fee_overhead) registrationFeeCurrentBlurb = " (the current setting)"

        let hasFormErrors = false;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.state.formErrors)) {
            hasFormErrors = true;
            break;
        }

        // if the user is trying to make fees disabled, we only stop that if they're already disabled
        let disableSubmitButton = false;
        if (this.state.formRegistrationFeeOption === 'disabled') {
            if (this.props.league.registration_fee_option === 'disabled') disableSubmitButton = true;
        }
        // if the user is trying to enable fees, do all the checks
        else {
            let hasModification = false;
            if (this.state.formRegistrationFeeOption !== this.props.league.registration_fee_option) hasModification = true;
            if (this.props.league.registration_fee_message === null && this.state.formRegistrationFeeMessage !== '') hasModification = true;
            if (this.props.league.registration_fee_message !== null && this.state.formRegistrationFeeMessage !== this.props.league.registration_fee_message) hasModification = true;
            if (this.props.league.registration_fee_cost !== this.state.formRegistrationFeeCost) hasModification = true;
            if (this.props.league.registration_fee_overhead !== this.state.formRegistrationFeeOverhead) hasModification = true;
            if (this.props.league.registration_fee_currency !== this.state.formRegistrationFeeCurrency) hasModification = true;

            // if the codes are updated, allow pushing the submit button
            if (this.state.codesUpdated) hasModification = true;

            // if there are no modifications, or there are errors, disable the submit button
            if (!hasModification) disableSubmitButton = true;
            if (hasFormErrors) disableSubmitButton = true;

            // also don't let the user have a fee of less than 200 for most cases
            if (minimumAmountCurrency && this.state.formRegistrationFeeCost < minimumAmountValue) {
                disableSubmitButton = true;
                minimumFeeError = <div><small className='text-danger'>The fee must be at least {minimumAmountCurrency.format()}</small></div>
            }

            // similar to above, discount codes must have a fee above the minimum or zero
            for (let i=0; i<this.state.codeAmounts.length; i++) {
                const codeAmount = this.state.codeAmounts[i];
                if (minimumAmountCurrency && codeAmount !== 0 && codeAmount < minimumAmountValue) {
                    disableSubmitButton = true;
                    minimumCodeFeeError = <div><small className='text-danger'>One or more discount codes are below {minimumAmountCurrency.format()}</small></div>
                }
            }

            // can't submit if join requests are enabled
            if (this.props.league.join_league_via_request) disableSubmitButton = true;
        }

        const codeRows = [];
        const codeList2 = [];
        // put in a 'header row'
        if (this.state.codes.length !== 0) {
            const row =
                <div key='header'>
                    <Row className='my-0'>
                        <Col>
                            <Form.Text>Discount Code</Form.Text>
                        </Col>
                        <Col xs={2}>
                            <Form.Text>Fee ({currentCurrencyAlphaId})</Form.Text>
                        </Col>
                        <Col>
                            <Form.Text>Uses</Form.Text>
                        </Col>
                        <Col xs='auto'>
                            <div style={{width: '30px'}}>
                            </div>
                        </Col>
                    </Row>
                    <hr className='my-1'/>
                </div>
            codeRows.push(row);
        }
        for (let i=0; i<this.state.codes.length; i++) {
            const code = this.state.codes[i];
            const amount = this.state.codeAmounts[i];
            const oneTimeUse = this.state.codeOneTimeUses[i];
            let codeIssue = false;
            let codeDuplicate = false;

            if (code.length === 0 || code.length > 20) {
                codeIssue = true;
                disableSubmitButton = true;
            } else if (codeList2.includes(code)) {
                codeIssue = true;
                disableSubmitButton = true;
                codeDuplicate = true;
            }
            codeList2.push(code);

            const row =
                <div key={`dc-${i}-${currentCurrencyAlphaId}`}>
                    <Row className='my-0'>
                        <Col className='pe-0'>
                            <Form.Control size='sm' type="text" placeholder='DISCOUNT CODE' disabled={this.state.updating} onChange={(e)=>this.handleCodeChange(i, e)} autoComplete='off' value={code} minLength={1} maxLength={20} required/>
                            {codeIssue && !codeDuplicate &&
                            <Form.Text className='text-danger'>The dicount code must be between 1 and 20 characters long.</Form.Text>}
                            {codeIssue && codeDuplicate &&
                            <Form.Text className='text-danger'>This discount code is duplicated.</Form.Text>}
                        </Col>
                        <Col className='pe-0' xs={2}>
                            <FormCurrencyInput 
                                id={`dicount-code-input-${i}`}
                                name={`dicount-code-input-${i}`}
                                size='sm'
                                disabled={this.state.updating}
                                placeholder='discount price'
                                onChange={(v, n)=>this.handleCodeAmountChange(v, n, i)}
                                defaultValue={amount}
                                minorDigits={currencyMinorDigits}
                                symbol={currencySymbol}
                                decimalSeparator={currencyDecimal}
                                required/>
                        </Col>
                        <Col className='pe-0'>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.handleCodeOneTimeUseChange(i, e)} autoComplete='off' value={oneTimeUse}>
                                <option value='u'>Unlimited Use</option>
                                <option value='s'>Single Use</option>
                            </Form.Select>
                        </Col>
                        <Col xs='auto'>
                            <Button size='sm' variant='danger' disabled={this.state.updating} onClick={()=>this.onDeleteRow(i)}><NerdHerderFontIcon icon='flaticon-recycle-bin-filled-tool'/></Button>
                        </Col>
                    </Row>
                    <hr className='my-1'/>
                </div>
            codeRows.push(row);
        }

        // we don't support player pays if the currency isn't USD & the stripe account isn't USD
        let disablePlayerPays = false;
        if (this.state.stripeAccount === null) {
            disablePlayerPays = true;
        } else if (this.state.formRegistrationFeeCurrency !== 'USD') {
            disablePlayerPays = true;
        } else if (this.state.stripeAccount && this.state.stripeAccount.currency !== 'USD') {
            disablePlayerPays = true;
        }

        // if somehow we get here and the player pays option is on but shouldn't be, disable it
        if (disablePlayerPays && this.state.formRegistrationFeeOverhead === 'players') {
            setTimeout(()=>this.setState({formRegistrationFeeOverhead: 'venue'}), 0);
        }

        return(
            <NerdHerderStandardCardTemplate id="registration-fees-card" title={`${this.props.league.getTypeWordCaps()} Registration Fees`} titleIcon="stripe.png">
                <div>
                    <Form id='stripe-registration-fees' name='stripe-registration-fees' onSubmit={(e)=>this.onSubmit(e)}>
                        <Form.Group className="form-outline mb-2">
                            <div className='d-grid gap-2'>
                                <ToggleButtonGroup size='sm' name='league-registration-fees' type="radio" value={this.state.formRegistrationFeeOption} onChange={(v)=>this.handleOptionChange(v)}>
                                    <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-fee-disabled' value={'disabled'}>Disabled</ToggleButton>
                                    <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-fee-optional' value={'optional'}>Optional</ToggleButton>
                                    <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-fee-required' value={'required'}>Required</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Form.Group>
                        <Form.Group className='form-outline mb-3'>
                            <Form.Text>
                                <p><b>{registrationFeeTitle}{registrationFeeCurrentBlurb}</b> - {registrationFeeDescription}</p>
                            </Form.Text>
                        </Form.Group>
                        <Collapse in={this.state.formRegistrationFeeOption !== 'disabled'}>
                            <div>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>Registration Fee<Required/></Form.Label>
                                    <Row>
                                        <Col>
                                            <FormCurrencyInput
                                                key={`reg_fee_cost-${currentCurrencyAlphaId}`}
                                                id='registration_fee_cost'
                                                name='registration_fee_cost'
                                                disabled={this.state.updating}
                                                onChange={(e)=>this.handleRegistrationFeeCostChange(e)}
                                                defaultValue={this.state.formRegistrationFeeCost}
                                                minorDigits={currencyMinorDigits}
                                                symbol={currencySymbol}
                                                decimalSeparator={currencyDecimal}
                                                required/>
                                            {minimumFeeError}
                                            <Form.Text className='text-muted'>What is the cost to join the {this.props.league.getTypeWord()}?</Form.Text>
                                        </Col>
                                        <Col>
                                            <Form.Select id='registration_fee_currency' name='registration_fee_currency' disabled={this.state.updating} onChange={(e)=>this.handleRegistrationFeeCurrencyChange(e)} value={this.state.formRegistrationFeeCurrency} required>
                                                {currencyOptions}
                                            </Form.Select>
                                            {this.state.stripeAccount && this.state.stripeAccount.currency !== this.state.formRegistrationFeeCurrency &&
                                            <div>
                                                <Form.Text className='text-danger'>Does not match Stripe account - refunds will need to be processed through Stripe instead of NerdHerder (this is not a big deal)</Form.Text>
                                            </div>}
                                            {currentCurrencyAlphaId !== this.state.formRegistrationFeeCurrency &&
                                            <Form.Text muted><i>Currency will be updated after submission...</i></Form.Text>}
                                        </Col>
                                    </Row>
                                    <FormErrorText errorId='registration_fee_cost' errorState={this.state.formErrors}/>
                                    <FormErrorText errorId='registration_fee_currency' errorState={this.state.formErrors}/>
                                </Form.Group>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>Registration Message</Form.Label>
                                    <div style={{position: 'relative'}}>
                                        <Form.Control id='registration_fee_message' name='registration_fee_message' as="textarea" rows={2}  placeholder={'Optional'} disabled={this.state.updating} onChange={(e)=>this.handleRegistrationMessageChange(e)} autoComplete='off' value={this.state.formRegistrationFeeMessage} maxLength={200}/>
                                        <FormTextInputLimit current={this.state.formRegistrationFeeMessage.length} max={200}/>
                                    </div>
                                    <FormErrorText errorId='registration_fee_message' errorState={this.state.formErrors}/>
                                    <Form.Text className='text-muted'>An optional message provided to players when paying the registration fee. How will the fees be used (e.g. prize support)?</Form.Text>
                                </Form.Group>
                                <hr/>
                                <Form.Group className="form-outline mb-2">
                                    <div>
                                        <Form.Label>Registration Discount Codes</Form.Label>
                                    </div>
                                    <div>
                                        <Form.Text>It is possible to give discount codes to your (potential) players. The codes can be used by anyone, so be sure to only give them to the desired users. To limit abuse, you can set a code to 'Single Use' if desired.</Form.Text>
                                    </div>
                                    {minimumCodeFeeError}
                                    {codeRows.length !== 0 &&
                                    <Row className='mt-3 px-2'>
                                        <Col xs={12}>
                                            {codeRows}
                                        </Col>
                                    </Row>}
                                    {codeRows.length === 0 &&
                                    <Row className='mt-3 px-2'>
                                        <Col>
                                            <small className='text-muted'>This {this.props.league.getTypeWord()} has no discount codes. Click the + to add some!</small>
                                        </Col>
                                        <Col xs='auto'>
                                            <Button size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.onAddRow()}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                                        </Col>
                                    </Row>}
                                    {codeRows.length !== 0 && codeRows.length < 50 &&
                                    <Row className='mt-1 px-2'>
                                        <Col></Col>
                                        <Col xs='auto'>
                                            <Button size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.onAddRow()}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                                        </Col>
                                    </Row>}
                                </Form.Group>
                                <hr/>
                                <Form.Group className="form-outline mb-2">
                                    <div>
                                        <Form.Label>Stripe Processing Charges</Form.Label>
                                    </div>
                                    <div>
                                        <Form.Text>{this.state.stripeInfo.stripe_fee_statement}. How should that charge be covered?</Form.Text>
                                    </div>
                                    <div className='d-grid gap-2 mt-2'>
                                        <ToggleButtonGroup size='sm' name='league-registration-fee-overhead' type="radio" value={this.state.formRegistrationFeeOverhead} onChange={(v)=>this.handleOverheadChange(v)}>
                                            <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-overhead-venue' value={'venue'}>Venue Pays</ToggleButton>
                                            <ToggleButton variant='outline-primary' disabled={this.state.updating || disablePlayerPays} id='toggle-overhead-player' value={'players'}>Player Pays</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </Form.Group>
                                <Form.Group className='form-outline mb-3'>
                                    <Form.Text>
                                        <p><b>{registrationFeeOverheadTitle}{registrationFeeOverheadCurrentBlurb}</b> - {registrationFeeOverheadDescription}</p>
                                    </Form.Text>
                                    {disablePlayerPays &&
                                    <div>
                                        <Form.Text muted>NerdHerder is only able to provide the Player Pays option when using USD.</Form.Text>
                                    </div>}
                                </Form.Group>
                            </div>
                        </Collapse>
                        <Form.Group className='form-outline'>
                            <div className='text-end'>
                                <Button type='submit' variant='primary' disabled={disableSubmitButton}>Update</Button>
                            </div>
                        </Form.Group>
                    </Form>
                </div>
            </NerdHerderStandardCardTemplate>
        );
    }
}

class LeagueTopPostManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueTopPostManagementCard'>
                <LeagueTopPostManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueTopPostManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            currentTopPostMessageId: this.props.league.top_post_id,
            messageIds: [],
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        const newMessageIdList = []
        for (const messageSnippet of updatedLeague.message_tree) {
            if (messageSnippet.state === 'sent' && messageSnippet.type === 'text') {
                newMessageIdList.push(messageSnippet.id);
            }
        }
        this.setState({messageIds: newMessageIdList, currentTopPostMessageId: updatedLeague.top_post_id, updating: false});
    }

    onUpdateTopPost(messageId) {
        const patchData = {
            top_post_id: messageId
        }

        this.restPubSub.patch('league', this.props.league.id, patchData);
        this.setState({updating: true})
    }

    handleStateChange(value) {
        this.setState({currentTopPostMessageId: value})
    }

    render() {
        const currentMessageId = this.props.league.top_post_id;

        let borderSelectedClass = null;
        if (currentMessageId === null) borderSelectedClass = 'border-selected-primary';
        const nullTopPost =
            <div key={'no-top-post'} className={`my-1 list-group-item rounded align-middle ${borderSelectedClass}`} onClick={()=>this.onUpdateTopPost(null)}>
                <Row className='align-items-center'>
                    <Col className='pe-0' xs='auto'>
                        <Image className="rounded-circle" alt="user icon" height={25} width={25} src={getStaticStorageImageFilePublicUrl('/not_allowed.png')}/>
                    </Col>
                    <Col className='ps-2'>
                        <b>No Top Post Selected</b>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                            <small>{'No top post is set. Select this item to clear the top post (if any).'}</small>
                    </Col>
                </Row>
            </div>

        const messageCards = [nullTopPost];
        for (const messageId of this.state.messageIds) {
            let isSelected = false;
            // eslint-disable-next-line eqeqeq
            if (messageId == currentMessageId) {
                isSelected = true;
            }
            const item = <NerdHerderLeaguePostSnippet key={messageId} messageId={messageId} selected={isSelected} localUser={this.props.localUser} onClick={()=>this.onUpdateTopPost(messageId)}/>
            messageCards.push(item);
        }


        return(
            <NerdHerderStandardCardTemplate id="select-top-post-card" title="Select Top Post" titleIcon="star.png">
                <p className='text-muted'><small>Here are all posts eligible to be your {this.props.league.getTypeWordPossessive()} top post. The current top post is highlighted, click to select a new one.</small></p>
                <p className='text-muted'><small><i>Creating a post can be accomplished on the {this.props.league.getTypeWordPossessive()} main page.</i></small></p>
                <NerdHerderVerticalScroller maxHeight={500}>
                    {messageCards}
                </NerdHerderVerticalScroller>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueOptionsManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueOptionsManagementCard'>
                <LeagueOptionsManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueOptionsManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            errorFeedback: null,
            updating: false,
            privateLeague: false,
            joinLeagueViaRequest: false,
            joinLeagueViaPasswordBoolean: false,
            joinLeagueViaPasswordString: '',
            joinLeagueViaPasswordRequired: false,
            maxPlayersBoolean: false,
            maxPlayersInteger: 0,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.handleError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        let privateLeague = updatedLeague.private_league;
        let joinLeagueViaRequest = updatedLeague.join_league_via_request;
        let joinLeagueViaPasswordBoolean = false;
        let joinLeagueViaPasswordString = updatedLeague.join_league_via_password;
        if (joinLeagueViaPasswordString === false) {
            joinLeagueViaPasswordString = '';
        } else {
            joinLeagueViaPasswordBoolean = true;
        }

        let maxPlayersInteger = updatedLeague.max_players;
        let maxPlayersBoolean = false;
        if (maxPlayersInteger === null) {
            maxPlayersInteger = 0;
        } else {
            maxPlayersBoolean = true;
        }

        this.setState({privateLeague: privateLeague,
                       joinLeagueViaRequest: joinLeagueViaRequest,
                       joinLeagueViaPasswordBoolean: joinLeagueViaPasswordBoolean,
                       joinLeagueViaPasswordString: joinLeagueViaPasswordString,
                       maxPlayersBoolean: maxPlayersBoolean,
                       maxPlayersInteger: maxPlayersInteger,
                       updating: false})
    }

    handleError(error, key) {
        console.error(error);
        // don't propagate the error up...
        return true;
    }

    onUpdateSettings(event) {
        
        const patchData = {
            private_league: this.state.privateLeague,
            join_league_via_request: this.state.joinLeagueViaRequest,
            max_players: this.state.maxPlayersBoolean ? this.state.maxPlayersInteger : null,
        }
        if (this.state.joinLeagueViaPasswordBoolean && this.state.joinLeagueViaPasswordString.length > 0) {
            patchData['join_league_via_password'] = this.state.joinLeagueViaPasswordString.trimEnd();
        } else if (!this.state.joinLeagueViaPasswordBoolean) {
            patchData['join_league_via_password'] = null;
        }
        this.restPubSub.patch('league', this.props.league.id, patchData);
        this.setState({updating: true})
    }

    handlePrivateLeagueChange(isPrivate) {
        this.setState({privateLeague: isPrivate});
    }

    handleUseRequestsChange(useRequests) {
        this.setState({joinLeagueViaRequest: useRequests});
    }

    handleUsePasswordsChange(usePasswords) {
        this.setState({joinLeagueViaPasswordBoolean: usePasswords});
        // when toggling from false to true, and the server also has a false, a password is required
        if (this.props.league.join_league_via_password === false &&
            this.state.joinLeagueViaPasswordBoolean === false &&
            usePasswords === true) {
            this.setState({joinLeagueViaPasswordRequired: true});
        } else if (usePasswords === false) {
            this.setState({joinLeagueViaPasswordRequired: false});
        }
    }

    handlePasswordChange(event) {
        const value = event.target.value;
        this.setState({joinLeagueViaPasswordString: value});
    }

    handleUseMaxPlayersChange(value) {
        let updatedMaxPlayersInteger = this.state.maxPlayersInteger;
        if (updatedMaxPlayersInteger < 2) updatedMaxPlayersInteger = 4;
        this.setState({maxPlayersBoolean: value, maxPlayersInteger: updatedMaxPlayersInteger});
    }

    handleMaxPlayersChange(event) {
        let value = event.target.value;
        this.setState({maxPlayersInteger: value});
    }

    render() {
        let invalidSettingMessage = null;

        let jrTitle = null;
        let jrDescription = null;
        let jrCurrentBlurb = null;
        switch(this.state.joinLeagueViaRequest) {
            case false:
                jrTitle = "Simple";
                jrDescription = `When a user joins the ${this.props.league.getTypeWord()}, they automatically become a player - no effort is required by the organizers. This is the recommended setting.`;
                break;

            case true:
                jrTitle = "Via Request";
                jrDescription = `When a user tries to join a ${this.props.league.getTypeWord()}, a request is sent to the organizers to approve. Only after approval does the user become a ${this.props.league.getTypeWord()} player. Use this if you need to screen players. This option is not compatible with Stripe registration fees.`;
                break;
            
            default:
                jrTitle = null;
                jrDescription = null;
        }
        if (this.state.joinLeagueViaRequest === this.props.league.join_league_via_request) jrCurrentBlurb = " (the current setting)";

        let jpTitle = null;
        let jpDescription = null;
        let jpCurrentBlurb = null;
        switch(this.state.joinLeagueViaPasswordBoolean) {
            case false:
                jpTitle = "No Password";
                jpDescription = `When a user joins the ${this.props.league.getTypeWord()}, they automatically become a player - no password is required. This is the recommended setting.`;
                break;

            case true:
                jpTitle = "Password";
                jpDescription = `When a user tries to join a ${this.props.league.getTypeWord()} they are presented with a password prompt. Only after entering a correct password does the user become a player. This is a good option if players are only added after paying locally.`;
                break;
            
            default:
                jpTitle = null;
                jpDescription = null;
        }
        if (this.state.joinLeagueViaPasswordBoolean === this.props.league.join_league_via_password) jpCurrentBlurb = " (the current setting)";

        let plTitle = null;
        let plDescription = null;
        let plCurrentBlurb = null;
        switch(this.state.privateLeague) {
            case false:
                plTitle = "Public";
                plDescription = `The ${this.props.league.getTypeWord()} is generally visible to the public. It will appear when users search for leagues, tournaments, or events. This is the recommended setting.`;
                break;

            case true:
                plTitle = "Private";
                plDescription = `The ${this.props.league.getTypeWord()} is not visible to the public. It will not appear during searches (players must be invited). Use this if the ${this.props.league.getTypeWord()} is just for you and your cohorts.`;
                break;
            
            default:
                plTitle = null;
                plDescription = null;
        }
        if (this.state.privateLeague === this.props.league.private_league) plCurrentBlurb = " (the current setting)";

        let mpTitle = null;
        let mpDescription = null;
        let mpCurrentBlurb = null;
        switch(this.state.maxPlayersBoolean) {
            case false:
                mpTitle = "Unlimited";
                mpDescription = `Any number of players may join the ${this.props.league.getTypeWord()}. This is normally the best option unless the ${this.props.league.getTypeWord()} is focused on an elimination-style tournament or you have limited space.`;
                break;

            case true:
                mpTitle = "Capped";
                mpDescription = `The number of players automatically accepted into the ${this.props.league.getTypeWord()} is capped. Additional players may attempt to join, they are instead added to a waitlist. This is a good option when the league is focused on an elimination-style tournament or when space is limited.`;
                break;
            
            default:
                mpTitle = null;
                mpDescription = null;
        }
        if (this.state.maxPlayersBoolean === false && this.props.league.max_players === null) mpCurrentBlurb = " (the current setting)";
        if (this.state.maxPlayersBoolean === true && this.props.league.max_players !== null) mpCurrentBlurb = " (the current setting)";


        let disableUpdateButton = true;
        if (this.state.updating === false) {
            if (this.props.league.private_league !== this.state.privateLeague) disableUpdateButton = false;
            if (this.props.league.join_league_via_request !== this.state.joinLeagueViaRequest) disableUpdateButton = false;
            if (this.props.league.join_league_via_password !== this.state.joinLeagueViaPasswordBoolean) disableUpdateButton = false;
            if (this.state.joinLeagueViaPasswordBoolean && this.state.joinLeagueViaPasswordString.length > 0) disableUpdateButton = false;

            if (this.props.league.max_players === null && this.state.maxPlayersBoolean !== false) disableUpdateButton = false;
            if (this.props.league.max_players !== null && this.state.maxPlayersBoolean !== true) disableUpdateButton = false;
            // eslint-disable-next-line eqeqeq
            if (this.props.league.max_players !== null && this.props.league.max_players != this.state.maxPlayersInteger) disableUpdateButton = false;
        }

        if (this.state.privateLeague && this.state.joinLeagueViaRequest) {
            disableUpdateButton = true;
            invalidSettingMessage = 'Private, Via Request, and Password are mutually exclusive (pick one)';
        }
        if (this.state.privateLeague && this.state.joinLeagueViaPasswordBoolean) {
            disableUpdateButton = true;
            invalidSettingMessage = 'Private, Via Request, and Password are mutually exclusive (pick one)';
        }
        if (this.state.joinLeagueViaPasswordBoolean && this.state.joinLeagueViaRequest) {
            disableUpdateButton = true;
            invalidSettingMessage = 'Private, Via Request, and Password are mutually exclusive (pick one)';
        }
        if (this.state.joinLeagueViaPasswordRequired && this.state.joinLeagueViaPasswordString.length === 0) {
            disableUpdateButton = true;
            invalidSettingMessage = 'To use the Password option, a password must be set';
        }
        if (this.state.maxPlayersBoolean && (this.state.maxPlayersInteger < 2 || this.state.maxPlayersInteger > 9999)) {
            disableUpdateButton = true;
            invalidSettingMessage = 'The maximum player value must be set between 2 and 9999';
        }

        return(
            <NerdHerderStandardCardTemplate id="privacy-card" title="Privacy & Screening Options" titleIcon="security.png">
                {this.state.errorFeedback}
                <Form>
                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-private' type="radio" value={this.state.privateLeague} onChange={(v)=>this.handlePrivateLeagueChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-public' value={false}>Public</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-private' value={true}>Private</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{plTitle}{plCurrentBlurb}</b> - {plDescription}</p>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-join-request' type="radio" value={this.state.joinLeagueViaRequest} onChange={(v)=>this.handleUseRequestsChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-jr-disable' value={false}>Simple</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-jr-enable' value={true}>Via Request</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{jrTitle}{jrCurrentBlurb}</b> - {jrDescription}</p>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-password' type="radio" value={this.state.joinLeagueViaPasswordBoolean} onChange={(v)=>this.handleUsePasswordsChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-jp-disable' value={false}>No Password</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-jp-enable' value={true}>Password</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Collapse in={this.state.joinLeagueViaPasswordBoolean}>
                        <Form.Group className='form-outline mb-2'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handlePasswordChange(event)} autoComplete='off' value={this.state.joinLeagueViaPasswordString} minLength={1} maxLength={90}/>
                        </Form.Group>
                    </Collapse>
                                        <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{jpTitle}{jpCurrentBlurb}</b> - {jpDescription}</p>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-maxplayers' type="radio" value={this.state.maxPlayersBoolean} onChange={(v)=>this.handleUseMaxPlayersChange(v)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-mp-disable' value={false}>Unlimited</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-mp-enable' value={true}>Capped</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Collapse in={this.state.maxPlayersBoolean}>
                        <Form.Group className='form-outline mb-2'>
                            <Form.Label>Maximum Players Allowed</Form.Label>
                            <Form.Control type="number" disabled={this.state.updating} onChange={(e)=>this.handleMaxPlayersChange(e)} autoComplete='off' value={this.state.maxPlayersInteger} min={2} max={9999}/>
                        </Form.Group>
                    </Collapse>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{mpTitle}{mpCurrentBlurb}</b> - {mpDescription}</p>
                        </Form.Text>
                    </Form.Group>
                    {invalidSettingMessage &&
                    <b className='text-danger'>{invalidSettingMessage}</b>}
                    <div className='text-end'>
                        <Button type='button' variant='primary' disabled={disableUpdateButton} onClick={()=>this.onUpdateSettings()}>Update</Button>
                    </div>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueGoogleIntegrationCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueGoogleIntegrationCard'>
                <LeagueGoogleIntegrationCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueGoogleIntegrationCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showExample: false,
            googlesheet: '',
            googlesheetUrl: '',
            googlesheetLabel: '',
            googlesheetRange: '',
            formErrors: {},
            formValidated: false,
            sheetData: null,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);

        sub = this.restPubSub.subscribe('league-google-data', this.props.league.id, (d, k)=>{this.updateSheetData(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({googlesheet: updatedLeague.googlesheet || '',
                       googlesheetUrl: this.getUrlFromId(updatedLeague.googlesheet),
                       googlesheetLabel: updatedLeague.googlesheet_label || '',
                       googlesheetRange: updatedLeague.googlesheet_range || '',
                       updating: false});
    }

    updateSheetData(newSheetData, key) {
        this.setState({sheetData: newSheetData});
    }

    getUrlFromId(sheetId) {
        if (sheetId == null || sheetId.length === 0) {
            return '';
        }
        return `https://docs.google.com/spreadsheets/d/${sheetId}/view`;
    }

    getIdFromUrl(sheetUrl) {
        // the url will look like this https://docs.google.com/spreadsheets/d/1VkXv4w8hrTXXBrVMJsnHr7kZ3mwzcv14CYXnvRxR9Xs/edit?usp=sharing
        let basicPart = sheetUrl.replace('https://docs.google.com/spreadsheets/d/', '');
        let result = basicPart.split('/');
        console.debug('getIdFromUrl:', result[0]);
        return result[0];
    }

    onShowExample() {
        this.setState({showExample: !this.state.showExample});
    }

    handleGooglesheetUrlChange(event) {
        const value = event.target.value;
        const sheetId = this.getIdFromUrl(value);
        let errorState = clearErrorState('googlesheet', {...this.state.formErrors});
        if (value.length < 40) {
            errorState = setErrorState('googlesheet', {...this.state.formErrors}, 'this URL is too short');
        }
        else if (value.length >= 40 && (sheetId === null || sheetId.length < 10)) {
            errorState = setErrorState('googlesheet', {...this.state.formErrors}, 'URL is not properly formatted - cannot find sheet ID');
        }
        this.setState({ googlesheetUrl: value, googlesheet: sheetId, formErrors: errorState})
    }

    handleGooglesheetLabelChange(event) {
        let errorState = clearErrorState('googlesheet_label', {...this.state.formErrors});
        this.setState({googlesheetLabel: event.target.value, formErrors: errorState})
    }

    handleGooglesheetRangeChange(event) {
        this.setState({googlesheetRange: event.target.value})
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    onSubmit(event) {
        const form = event.currentTarget;
        const valid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            this.setState({formValidated: true, sheetData: null, updating: true});
            const patchData = {
                googlesheet: this.state.googlesheet.length > 0 ? this.state.googlesheet.trimEnd() : null,
                googlesheet_range: this.state.googlesheetRange.length > 0 ? this.state.googlesheetRange.trimEnd() : null,
                googlesheet_label: this.state.googlesheetLabel.length > 0 ? this.state.googlesheetLabel.trimEnd() : null,
            }
            console.debug('submit form now');
            console.debug(patchData);
            this.restApi.genericPatchEndpointData('league', this.props.league.id, patchData)
            .then(response => {
                this.restPubSub.refresh('league', this.props.league.id);
                this.restPubSub.refresh('league-google-data', this.props.league.id, 500);
            }).catch(error => {
                this.formUpdateError(error, null);
                this.setState({updating: false});
            });
        }
    }

    onDisable() {
        this.setState({formValidated: true, updating: true});
        const patchData = {
            googlesheet: null,
            googlesheet_range: null,
            googlesheet_label: null,
        }
        this.restApi.genericPatchEndpointData('league', this.props.league.id, patchData)
        .then(response => {
            this.restPubSub.refresh('league', this.props.league.id);
        }).catch(error => {
            this.formUpdateError(error, null);
        });
    }

    render() {
        let disableUpdateButton = true;
        if (this.state.updating === false) {
           if (this.props.league.googlesheet === null && this.state.googlesheet !== '') disableUpdateButton = false;
           if (this.props.league.googlesheet !== null && this.props.league.googlesheet !== this.state.googlesheet) disableUpdateButton = false;
        
           if (this.props.league.googlesheet_label === null && this.state.googlesheetLabel !== '') disableUpdateButton = false;
           if (this.props.league.googlesheet_label !== null && this.props.league.googlesheet_label !== this.state.googlesheetLabel) disableUpdateButton = false;

           if (this.props.league.googlesheet_range === null && this.state.googlesheetRange !== '') disableUpdateButton = false;
           if (this.props.league.googlesheet_range !== null && this.props.league.googlesheet_range !== this.state.googlesheetRange) disableUpdateButton = false;
        }

        let disableClearButton = true;
        if (this.state.updating === false &&
            (this.state.googlesheet.length > 0 ||
            this.state.googlesheetLabel.length > 0 ||
            this.state.googlesheetRange.length > 0)) {
                disableClearButton = false;
        }

        // this is all about figuring out if the current stuff works - not actually displaying it
        let testDataMessage = null;
        if (this.state.googlesheet !== null && this.state.googlesheet !== '' && this.state.sheetData !== null) {
            if (this.state.sheetData.error_message !== null) {
                testDataMessage = <Alert variant='danger'>{this.state.sheetData.error_message}</Alert>;
            } else if (this.state.sheetData.data === null) {
                testDataMessage = <Alert variant='warning'>No data was returned.</Alert>;
            } else if (this.state.sheetData.data.length === 0) {
                testDataMessage = <Alert variant='warning'>Was able to access the Google sheet, but got no data.</Alert>;
            } else {
                testDataMessage = <Alert variant='primary'>Success! Retrieved {this.state.sheetData.data.length} rows of data.</Alert>;
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="google-integration-card" title="Googlesheet Integration" titleIcon="excel.png">
                <Form onSubmit={(e)=>this.onSubmit(e)}>
                    <Form.Group className='form-outline'>
                    <p>
                        <small className='text-muted'>The {this.props.league.getTypeWord()} can be setup to get scores (or any other data) from a google sheet. In this section, you may optionally link the {this.props.league.getTypeWord()} and the sheet. NerdHerder will not modify the sheet - it is used 'read only'.</small>
                    </p>
                    <p>
                        <small className='text-muted'>For integration to work, the sheet must have link sharing enabled. Any mode works, but 'view only' is recommended. You can disable integration at any time.</small>
                    </p>
                    <div className="text-end">
                        <Button type='button' size='sm' variant='primary' onClick={()=>this.onShowExample()}>{this.state.showExample ? 'Hide Example' : 'Show Example'}</Button>
                    </div>
                    <Collapse in={this.state.showExample}>
                        <div className='text-center my-2'>
                            <Image fluid alt='enable link sharing example' src={getStaticStorageImageFilePublicUrl('/google_link_sharing_example1.png')}/>
                            <Image fluid alt='copy link example' src={getStaticStorageImageFilePublicUrl('/google_link_sharing_example2.png')}/>
                        </div>
                    </Collapse>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Label>Googlesheet URL</Form.Label>
                        <Form.Control type="url" disabled={this.state.updating} onChange={(event)=>this.handleGooglesheetUrlChange(event)} autoComplete='off' value={this.state.googlesheetUrl} minLength={40} maxLength={200}/>
                        <FormErrorText errorId='googlesheet' errorState={this.state.formErrors}/>
                        <Form.Text>
                            Provide the Google sheet URL. Open the sheet and copy the URL from the address bar, or use the sharing Copy Link button (see example above).
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Label>Googlesheet Range</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleGooglesheetRangeChange(event)} autoComplete='off' value={this.state.googlesheetRange} minLength={5} maxLength={45}/>
                        <FormErrorText errorId='googlesheet_range' errorState={this.state.formErrors}/>
                        <Form.Text>
                            Put the range (including the sheet name using 'A1' notation) with the data the league participants should see. The first row in this range becomes table headers. You can include a wider range than you have data - empty rows and columns are ignored.
                            <br/>
                            For example, if the sheet was called 'Scores' and the data was in A1 through C13 enter: Scores!A1:C13
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Label>Googlesheet Label</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleGooglesheetLabelChange(event)} autoComplete='off' value={this.state.googlesheetLabel} maxLength={45}/>
                        <FormErrorText errorId='googlesheet_label' errorState={this.state.formErrors}/>
                        <Form.Text>
                            Optional: provide a label or title for the sheet when it is displayed to players. This can be whatever you want, it doesn't come from Google.
                        </Form.Text>
                    </Form.Group>
                    <div>
                        {testDataMessage}
                    </div>
                    <div className="float-end">
                        <Button type='button' variant='secondary' disabled={disableClearButton} onClick={()=>this.onDisable()}>Disable</Button>
                        {' '}
                        <Button type='submit' variant='primary' disabled={disableUpdateButton}>Update</Button>
                    </div>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class EventsDescriptionCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='EventsDescriptionCard'>
                <EventsDescriptionCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class EventsDescriptionCardInner extends React.Component {

    render() {
        return(
            <NerdHerderStandardCardTemplate id="event-information-card" title="Minor Event Information" titleIcon="information.png">
                <p>
                    Minor events are a nebulous concept in NerdHerder. They express to the players that "there's something different going on".
                    What that "something different" means is totally up to you! They were originally conceived as a way to support narrative
                    play, but have been expanded to be more flexible.
                </p>
                <p>
                    Minor events are normally (but not always) tied to a date, or week, or otherwise time-boxed. If the minor event has dates set, it will show up on the player's calendar.
                </p>
                <p>
                    Imagine minor events as a sub-{this.props.league.getTypeWord()} within the {this.props.league.getTypeWord()}.
                    Each minor event may have its own list of players, only players on the list can view the minor event. Here are some possible uses for minor events:
                </p>
                <ul>
                    <li>Narrative play events that change week to week</li>
                    <li>Campaigns with multiple groups of players (perhaps the groups are adversarial)</li>
                    <li>Multiweek leagues with list building rules that vary as the league continues (i.e. a growth league)</li>
                </ul>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueEventsManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueEventsManagementCard'>
                <LeagueEventsManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueEventsManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const defaultEvents = {};
        for (const eventId of this.props.league.event_ids) {
            defaultEvents[eventId] = null;
        }

        this.state = {
            showEditEventModal: false,
            editEventId: null,
            events: defaultEvents,
        }
    }

    componentDidMount() {
        // are only subscribing to the league so that if a new event is added we can sub to it
        const sub = this.restPubSub.subscribeSilently('league', this.props.league.id, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
        for (const eventId of this.props.league.event_ids) {
            const sub = this.restPubSub.subscribe('event', eventId, (d, k) => {this.updateEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        for (const eventId of newLeague.event_ids) {
            // if there are any new events, subscribe to them
            if (!this.state.events.hasOwnProperty(eventId)) {
                const sub = this.restPubSub.subscribe('event', eventId, (d, k)=>{this.updateEvent(d, k)}, (e, k)=>{this.updateEventError(e, k)}, eventId);
                this.restPubSubPool.add(sub);
                this.setState((state) => {
                    return {events: {...state.events, [eventId]: null}}
                });
            }
        }
    }

    updateEvent(eventData, eventId) {
        if (eventData === 'DELETED') {
            this.setState((state) => {
                return {events: {...state.events, [eventId]: null}}
            });
            return;
        }

        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState((state) => {
            return {events: {...state.events, [eventId]: newEvent}}
        });
    }

    updateEventError(error, eventId) {
        this.setState((state) => {
            return {events: {...state.events, [eventId]: null}}
        });
    }

    onEditEvent(eventId) {
        this.setState({showEditEventModal: true, editEventId: eventId});

    }

    onAddEvent() {
        this.setState({showEditEventModal: true, editEventId: null});
    }

    onCancelModal() {
        this.setState({showEditEventModal: false, editEventId: null});
        this.restPubSub.refresh('league', this.props.league.id, 200);
    }
    
    render() {
        const unscheduledEventListItems = [];
        const upcomingEventListItems = [];
        const pastEventListItems = [];
        const currentEventListItems = [];

        for (const event of Object.values(this.state.events)) {
            if (event === null) continue;
            const listItem = <EventListItem key={event.id} onClick={()=>this.onEditEvent(event.id)} noManageIcon={true} eventId={event.id} localUser={this.props.localUser} league={this.props.league} showSummary={true} showTopPost={true}/>
            let startDate = null;
            let endDate = null;

            // if there is a start/end date, get just the date part
            if (event.start_date) {
                startDate = DateTime.fromISO(event.start_date);
            }
            if (event.end_date) {
                endDate = DateTime.fromISO(event.end_date);
            }

            // get today
            const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            
            // if somehow there is a end but no start, then assume the end is the start
            if (startDate === null && endDate !== null) {
                startDate = endDate;
                endDate = null;
            }

            // if there is a start but no end, assume this is a 1-day event
            if (startDate !== null && endDate === null) {
                endDate = new Date(startDate)
                endDate.setDate(endDate.getDate() + 1);
            }

            // no start means unscheduled
            if (startDate === null) {
                unscheduledEventListItems.push(listItem);
            }

            // there is a start, so this is scheduled...
            else {
                // if today is before the start, then this is a upcoming event
                if (today < startDate) upcomingEventListItems.push(listItem);
                // if today is after the end, then this is a past event
                else if (today > endDate) pastEventListItems.push(listItem);
                // if the event is not upcoming or past, it must be current
                else currentEventListItems.push(listItem);
            }
        }

        // tournament events don't use minor events
        if (this.props.league.type === 'tournament') {
            return(
                <NerdHerderStandardCardTemplate id="manage-events-card" title="Manage Minor Events" titleIcon='calendar-event.png'>
                    <Alert variant='warning'>Minor events are not available when running a competitive event</Alert>
                    <p><small className='text-muted'>
                        To maintain consistency for players, NerdHerder doesn't allow minor events in competitive events. Players in these events are expecting to focus on a tournament, not minor events. If you wish to add minor events, switch your focus to "Other Event" or "League".
                    </small></p>
                </NerdHerderStandardCardTemplate>
            )
        }

        return(
            <NerdHerderStandardCardTemplate id="manage-events-card" title="Manage Minor Events" titleIcon='calendar-event.png'>
                {this.state.showEditEventModal &&
                <NerdHerderEditEventModal eventId={this.state.editEventId} league={this.props.league} onCancel={()=>this.onCancelModal()} localUser={this.props.localUser}/>}
                <p>Click on a minor event to manage it</p>
                {unscheduledEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Unscheduled Events</small>
                    {unscheduledEventListItems}
                </div>}
                {currentEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Current Events</small>
                    {currentEventListItems}
                </div>}
                {upcomingEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Upcoming Events</small>
                    {upcomingEventListItems}
                </div>}
                {pastEventListItems.length > 0 &&
                <div>
                    <small className="text-muted">Past Events</small>
                    {pastEventListItems}
                </div>}
                {this.props.league.event_ids.length === 0 &&
                <p>This {this.props.league.getTypeWord()} has no minor events planned. You should add one!</p>}
                <div className="text-end">
                    <Button variant='primary' onClick={()=>this.onAddEvent()}>Add Minor Event</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class GameTypesDescriptionCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='GameTypesDescriptionCard'>
                <GameTypesDescriptionCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class GameTypesDescriptionCardInner extends React.Component {

    render() {
        return(
            <NerdHerderStandardCardTemplate id="game-information-card" title="Game Types" titleIcon="information.png">
                <p>
                    NerdHerder supports 3 game types (in increasing order of competitive-ness):
                </p>
                <ul>
                    <li>Casual Games</li>
                    <li>Minor Event Games</li>
                    <li>Tournament Games</li>
                </ul>
                <Truncate height={50}>
                    <p>
                        <b>Casual Games</b> are the least competitive and least strict kind of game. Typically they are added/managed by the players themselves (e.g. pick-up games on league night). However, it is possible for organizers to enable the same features that event and tournament games have so that they can be used in a more competitive manner.
                    </p>
                </Truncate>
                <Truncate height={50}>
                    <p>
                        <b>Minor Event Games</b> are tied to a minor event. This is mostly to support narrative play, however minor events can be used for more than just narrative play. Other than being tied to a minor event, these games are identical to casual games.
                    </p>
                </Truncate>
                <Truncate height={50}>
                    <p>
                        <b>Tournament Games</b> are the most competitive type. They are almost exclusively created by the tournament systems within NerdHerder (although it is possible for organizers to turn a casual game into a tournament game). Players are limited to updating & reviewing these kinds of games (they cannot create tournament games). After being fully reviewed they are 'locked' and only organizers can modify them.
                    </p>
                </Truncate>
            </NerdHerderStandardCardTemplate>
        )
    }
}


class LeagueCasualGamesAddConfigCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueCasualGamesAddConfigCard'>
                <LeagueCasualGamesAddConfigCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueCasualGamesAddConfigCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showDetails: false,
            playersCreateGames: true,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({playersCreateGames: updatedLeague.players_create_games, updating: false})
    }

    onUpdatePlayersCreateGames() {
        const patchData = {
            players_create_games: this.state.playersCreateGames
        }
        this.restPubSub.patch('league', this.props.league.id, patchData);
        this.setState({updating: true})
    }

    handleStateChange(value) {
        this.setState({playersCreateGames: value})
    }

    render() {
        const currentPlayersCreateGames = this.props.league.players_create_games;

        let title = null;
        let description = null;
        switch(this.state.playersCreateGames) {
            case true:
                title = "Players";
                description = `Players (and organizers) are able to add casual games in this ${this.props.league.getTypeWord()}. This is the recommended setting.`;
                break;

            case false:
                title = "Organizers";
                description = `Only organizers can add casual games. Use this setting if all games are setup by the organizers (or if there are no casual games permitted).`;
                break;
            
            default:
                title = null;
                description = null;
        }

        let disableUpdateButton = true;
        if (this.state.updating === false && currentPlayersCreateGames !== this.state.playersCreateGames) {
            disableUpdateButton = false;
        }

        let currentBlurb = null;
        if (this.state.playersCreateGames === currentPlayersCreateGames) currentBlurb = " (the current setting)"

        return(
            <NerdHerderStandardCardTemplate id="add-casual-games-card" title="Adding Casual Games" titleIcon="configuration.png">
                <Button size='sm' variant='secondary' onClick={()=>this.setState({showDetails: !this.state.showDetails})}>Why limit Casual Games?</Button>
                <Form className='mt-2'>
                    <Collapse in={this.state.showDetails}>
                        <div>
                        <Form.Group className='form-outline mb-2'>
                            <Form.Text>
                                Casual games are normally pick-up or ad-hoc games and are arranged by players. In some leagues or events, there is no reason for the organizers to be involved here - just let players do their thing!<br/>
                                However, sometimes organizers need more control over casual games (perhaps they aren't entirely casual), or the event has no concept of a pick-up game.<br/>
                                NerdHerder puts the organizers in control, and lets them decided how games can be added to their event or league.
                            </Form.Text>
                        </Form.Group>
                        <hr/>
                        </div>
                    </Collapse>
                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-players-create-games' type="radio" value={this.state.playersCreateGames} onChange={(e)=>this.handleStateChange(e)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-games-players' value={true}>Players</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-games-organizers' value={false}>Organizers</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{title}{currentBlurb}</b> - {description}</p>
                        </Form.Text>
                    </Form.Group>
                    <Button type='button' className="float-end" variant='primary' disabled={disableUpdateButton} onClick={()=>this.onUpdatePlayersCreateGames()}>Update</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueCasualGamesReviewCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueCasualGamesReviewCard'>
                <LeagueCasualGamesReviewCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueCasualGamesReviewCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showDetails: false,
            playersReviewGames: true,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({playersReviewGames: updatedLeague.use_concur_reg_games, updating: false})
    }

    onUpdatePlayersReviewGames() {
        const patchData = {
            use_concur_reg_games: this.state.playersReviewGames
        }
        this.restPubSub.patch('league', this.props.league.id, patchData);
        this.setState({updating: true})
    }

    handleStateChange(value) {
        this.setState({playersReviewGames: value})
    }

    render() {
        const currentPlayersReviewGames = this.props.league.use_concur_reg_games;

        let title = null;
        let description = null;
        switch(this.state.playersReviewGames) {
            case true:
                title = "Casual Games Reviewed";
                description = `Like tournament games, casual games must be reviewed/verified by all players. Use this if the casual games are more than 'just for fun' in your ${this.props.league.getTypeWord()}, or if an incorrectly entered game impacts the ${this.props.league.getTypeWord()}.`;
                break;

            case false:
                title = "No Casual Games Review";
                description = `Casual games are not reviewed/verified by all players. Normally casual games have little or no impact, and if a few details are incorrect that's OK. This is the recommended setting.`;
                break;
            
            default:
                title = null;
                description = null;
        }

        let disableUpdateButton = true;
        if (this.state.updating === false && currentPlayersReviewGames !== this.state.playersReviewGames) {
            disableUpdateButton = false;
        }

        let currentBlurb = null;
        if (this.state.playersReviewGames === currentPlayersReviewGames) currentBlurb = " (the current setting)"

        return(
            <NerdHerderStandardCardTemplate id="review-casual-games-card" title="Casual Game Review" titleIcon="configuration.png">
                <Button size='sm' variant='secondary' onClick={()=>this.setState({showDetails: !this.state.showDetails})}>What are Game Reviews?</Button>
                <Form className='mt-2'>
                    <Collapse in={this.state.showDetails}>
                        <div>
                        <Form.Group className='form-outline mb-2'>
                            <Form.Text>
                                NerdHerder supports a concept of 'reviewing' games. Primarily, this is used to prevent a mistake made when entering scores during a tournament from impacting the tournament outcome. When a player enters a score and clicks update, a notification is given to the other player(s) to concur with the values entered or update them.<br/>
                                Normally, this is only done for tournaments. However, it is possible to also require reviews on casual games.
                            </Form.Text>
                        </Form.Group>
                        <hr/>
                        </div>
                    </Collapse>
                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-players-review-games' type="radio" value={this.state.playersReviewGames} onChange={(e)=>this.handleStateChange(e)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-players-review' value={true}>Games Reviewed</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-no-players-review' value={false}>Not Reviewed</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{title}{currentBlurb}</b> - {description}</p>
                        </Form.Text>
                    </Form.Group>
                    <Button type='button' className="float-end" variant='primary' disabled={disableUpdateButton} onClick={()=>this.onUpdatePlayersReviewGames()}>Update</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueEloConfigurationCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueEloConfigurationCard'>
                <LeagueEloConfigurationCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueEloConfigurationCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showDetails: false,
            eloConfiguration: null,
            eloRatioEnabled: false,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({eloConfiguration: updatedLeague.elo_configuration, eloRatioEnabled: updatedLeague.elo_ratio_enabled, updating: false})
    }

    onUpdateEloConfiguration() {
        const patchData = {
            elo_configuration: this.state.eloConfiguration,
            elo_ratio_enabled: this.state.eloRatioEnabled
        }
        this.restPubSub.patch('league', this.props.league.id, patchData);
        this.setState({updating: true})
    }

    handleStateChange(value) {
        if (value === 'disabled') {
            this.setState({eloConfiguration: value, eloRatioEnabled: false});
        } else {
            this.setState({eloConfiguration: value});
        }
    }

    handleRatioChange(value) {
        this.setState({eloRatioEnabled: value});
    }

    render() {
        const currentEloConfiguration = this.props.league.elo_configuration;
        const currentRatioEnabled = this.props.league.elo_ratio_enabled;

        let title = null;
        let description = null;
        switch(this.state.eloConfiguration) {
            case 'disabled':
                title = "Disabled";
                description = `ELO style ratings will not be calculated or displayed for this ${this.props.league.getTypeWord()}.`;
                break;

            case 'casual':
                title = "Casual Games Only";
                description = `ELO style ratings will only be calculated and displayed for casual games. When ELO is used, this is the most common setting.`;
                break;

            case 'event':
                title = "Minor Event Games Only";
                description = `ELO style ratings will only be calculated and displayed for minor event games. This can be useful if you are running narrative events and you need to rank players (e.g. battle royale narratives).`;
                break;

            case 'tournament':
                title = "Tournament Games Only";
                description = `ELO style ratings will only be calculated and displayed for tournament games. The only reason to enable this is to compare a game's normal ranking system against a more generic ELO system.`;
                break;

            case 'all':
                title = `All ${this.props.league.getTypeWordCaps(true)} Games`;
                description = `ELO style ratings will be calculated and displayed for all games in this ${this.props.league.getTypeWord()}.`;
                break;
            
            default:
                title = null;
                description = null;
        }

        let ratioTitle = null;
        let ratioDescription = null;
        switch(this.state.eloRatioEnabled) {
            case false:
                ratioTitle = "Win-Loss";
                ratioDescription = 'ELO style ratings are calculated from player wins and losses, scores are ignored.';
                break;

            case true:
                ratioTitle = "Margin of Victory";
                ratioDescription = 'ELO style ratings are calculated from player scores (using MoV) when possible. At least 6 games must be logged before MoV can be included in the calculations.'
                break;
            
            default:
                ratioTitle = null;
                ratioDescription = null;
        }

        let currentBlurb = null;
        if (this.state.eloConfiguration === currentEloConfiguration) currentBlurb = " (the current setting)"
        let ratioCurrentBlurb = null;
        if (this.state.eloRatioEnabled === currentEloConfiguration) ratioCurrentBlurb = " (the current setting)"

        let disableUpdateButton = true;
        if (this.state.updating === false && currentEloConfiguration !== this.state.eloConfiguration) {
            disableUpdateButton = false;
        }
        if (this.state.updating === false && currentRatioEnabled !== this.state.eloRatioEnabled) {
            disableUpdateButton = false;
        }

        return(
            <NerdHerderStandardCardTemplate id="elo-configuration-card" title="ELO Style Rating" titleIcon="configuration.png">
                <Button size='sm' variant='secondary' onClick={()=>this.setState({showDetails: !this.state.showDetails})}>What are ELO Style Ratings?</Button>
                <Form className='mt-2'>
                    <Collapse in={this.state.showDetails}>
                        <div>
                        <Form.Group className='form-outline mb-2'>
                            <Form.Text>
                                For those not familiar, ELO (pronounced E-low) is a statistical or mathmatical approach to determining a player's skill at a game or sport and is often associated with chess. That skill is represented by a number like 15.45 instead of Match Points or Victory Points. ELO on NerdHerder is not identical to that used in chess, instead it is powered by OpenSkill. OpenSkill has been slightly modified to consider MoV when appropriate.<br/>
                                Check out these links for more information:
                                <ul>
                                    <li><a href='https://en.wikipedia.org/wiki/Elo_rating_system' target='_blank' rel="noreferrer">ELO on Wikipedia</a></li>
                                    <li><a href='https://github.com/OpenDebates/openskill.py' target='_blank' rel="noreferrer">OpenSkill</a></li>
                                    <li><a href='https://www.csie.ntu.edu.tw/~cjlin/papers/online_ranking/online_journal.pdf' target='_blank' rel="noreferrer">A Bayesian Approximation Method for Online Ranking</a></li>
                                </ul>
                                Note that enabling ELO doesn't override or take away other scoring mechanisms, it is calculated and shown in addition to other scores.
                            </Form.Text>
                        </Form.Group>
                        <hr/>
                        </div>
                    </Collapse>
                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-players-review-games' type="radio" value={this.state.eloConfiguration} onChange={(e)=>this.handleStateChange(e)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-elo-disabled' value='disabled'>Disabled</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-elo-casual' value='casual'>Casual</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-elo-event' value='event'>Minor Event</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-elo-tournament' value='tournament'>Tournament</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-elo-all' value='all'>All</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{title}{currentBlurb}</b> - {description}</p>
                        </Form.Text>
                    </Form.Group>
                    <Collapse in={this.state.eloConfiguration !== 'disabled'}>
                        <div>
                            <Form.Group className='form-outline mb-2'>
                                <div className='d-grid gap-2'>
                                    <ToggleButtonGroup size='sm' name='league-elo-ratio-enabled' type="radio" value={this.state.eloRatioEnabled} onChange={(e)=>this.handleRatioChange(e)}>
                                        <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-ratio-disabled' value={false}>Win-Loss</ToggleButton>
                                        <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-ratio-enabled' value={true}>Margin of Victory</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </Form.Group>
                            <Form.Group className='form-outline mb-2'>
                                <Form.Text>
                                    <p><b>{ratioTitle}{ratioCurrentBlurb}</b> - {ratioDescription}</p>
                                </Form.Text>
                            </Form.Group>
                        </div>
                    </Collapse>
                    <Button type='button' className="float-end" variant='primary' disabled={disableUpdateButton} onClick={()=>this.onUpdateEloConfiguration()}>Update</Button>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueTournamentsManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueTournamentsManagementCard'>
                <LeagueTournamentsManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueTournamentsManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.externalSiteControlRef = React.createRef();
        this.externalSiteCodeControlRef = React.createRef();

        this.state = {
            showNewTournamentModal: false,
            showTournamentReasonModal: false,
            navigateTo: null,
            formExternalSite: this.props.league.external_site || '',
            formExternalSiteCode: this.props.league.external_site_code || '',
            errorFeedback: null,
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onEditTournament(tournamentId) {
        this.setState({navigateTo: `/app/managetournament/${tournamentId}`});
    }

    onAddTournament() {
        this.setState({showNewTournamentModal: true});
    }

    onCancelModal() {
        this.setState({showNewTournamentModal: false, showTournamentReasonModal: false});
        this.restPubSub.refresh('league', this.props.league.id, 200);
    }

    handleExternalSiteChange(value) {
        let serverValue = value;
        if (serverValue === '') serverValue = null;
        this.restApi.genericPatchEndpointData('league', this.props.league.id, {external_site: serverValue})
        .then((response) => {
            this.restPubSub.refresh('league', this.props.league.id);
            this.setState({formExternalSite: value});
        })
        .catch((error) => {
            console.error('failed to update league external site');
            console.error(error);
            let errorMessage = getFailureMessage(error);
            this.setState({errorFeedback: errorMessage});
            if (this.externalSiteControlRef.current) this.externalSiteControlRef.current.reset();
        });
        this.setState({errorFeedback: null});
    }

    handleExternalSiteCodeChange(value) {
        let serverValue = value;
        if (serverValue === '') serverValue = null;
        this.restApi.genericPatchEndpointData('league', this.props.league.id, {external_site_code: serverValue})
        .then((response) => {
            this.restPubSub.refresh('league', this.props.league.id);
            this.setState({formExternalSiteCode: value});
        })
        .catch((error) => {
            console.error('failed to update league external site code');
            console.error(error);
            let errorMessage = getFailureMessage(error);
            this.setState({errorFeedback: errorMessage});
            if (this.externalSiteControlRef.current) this.externalSiteControlRef.current.reset();
        });
        this.setState({errorFeedback: null});
    }
    
    render() {
        if (this.state.navigateTo) return (<Navigate to={this.state.navigateTo}/>);
        const tournamentListItems = [];

        for (const tournamentId of this.props.league.tournament_ids) {
            const listItem = <TournamentListItem key={tournamentId} onClick={()=>this.onEditTournament(tournamentId)} localUser={this.props.localUser} league={this.props.league} tournamentId={tournamentId} showSummary={true}/>
            tournamentListItems.push(listItem);
        }

        let showExternalSiteOption = false;
        let showExternalSiteCodeOption = false;
        if (this.props.league.topic.external_site !== null) {
            showExternalSiteOption = true;
            if (this.props.league.topic.id === 'MtG') showExternalSiteCodeOption = true;
        }

        return(
            <NerdHerderStandardCardTemplate id="manage-tournaments-card" title="Tournament Management" titleIcon='tournament.png'>
                {this.state.showNewTournamentModal &&
                <NerdHerderNewTournamentModal league={this.props.league} onCancel={()=>this.onCancelModal()} localUser={this.props.localUser}/>}
                {this.state.showTournamentReasonModal &&
                <NerdHerderMessageModal title='Nerdherder Tournaments' buttonText='Got It' onCancel={()=>this.onCancelModal()} localUser={this.props.localUser}
                    message={
                        <div>
                            <p>
                                The competitive event you are editing in Nerdherder is a container for tournaments (in the same way that a league or other event may also contain tournaments). That
                                might seem strange, but it works this way to allow a competitive event to be composed of one or more tournaments. Right now, your competitive event has no tournaments within it.
                            </p>
                            But why would one want to do this? Some examples:
                            <ul>
                                <li>Run several round-robin tournaments with invididual pools of players, then take the top of each pool and put them into a elimination tournament.</li>
                                <li>With a large swiss tournament, take the top 8 or 16 and run a double-elimination tournament to determine first and second place.</li>
                                <li>Mixed formats! Run a swiss tournament to determine the best player, and a single elimination to determine the best hobbiest.</li>
                            </ul>
                            <p>
                                In summary - Nerdherder is a sandbox, you can do whatever you want. Unfortunately, with flexiblity comes a little complexity.
                            </p>
                            <p>
                                To add a tournament, simply click the Add Tournament button on the underlying page. You can add as many individual tournaments as you need.
                            </p>
                        </div>}/>}
                {showExternalSiteOption &&
                <div>
                    {this.state.errorFeedback &&
                    <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                    <p>You may be required to use {this.props.league.topic.getExternalSiteName()} to manage tournaments for {this.props.league.topic.name}. If so, paste a link to the event below and NerdHerder will show users additional information related to that event.</p>
                    <Form>
                        <div>
                            <FormControlSubmit ref={this.externalSiteControlRef} disabled={this.state.updating} type='text' placeholder="https://www.tournamentsite.com/event/3345" autoComplete='off' onClick={(v)=>this.handleExternalSiteChange(v)} value={this.state.formExternalSite} minLength={10} maxLength={1024}/>
                        </div>
                        {showExternalSiteCodeOption &&
                        <div>
                            <Form.Text muted>Tournament Code</Form.Text>
                            <FormControlSubmit ref={this.externalSiteCodeControlRef} disabled={this.state.updating} type='text' placeholder="e.g. J4ZG4GW" autoComplete='off' onClick={(v)=>this.handleExternalSiteCodeChange(v)} value={this.state.formExternalSiteCode} minLength={7} maxLength={7}/>
                        </div>}
                    </Form>
                    <hr/>
                </div>}
                {this.props.league.tournament_ids.length !== 0 &&
                <p>Click on a Tournament to manage it</p>}
                {this.props.league.tournament_ids.length === 0 && this.props.league.type === 'tournament' && !(this.props.league.external_site || this.props.league.external_site_code) &&
                <div>
                    <p>
                        No tournaments have been configured yet. You must first add one, then configure it.
                    </p>
                    <p>
                        <a href='/app/main' onClick={(e)=>{e.preventDefault(); this.setState({showTournamentReasonModal: true})}}>Why do I need to do this?</a>
                    </p>
                </div>}
                {this.props.league.tournament_ids.length === 0 && this.props.league.type === 'tournament' && (this.props.league.external_site || this.props.league.external_site_code) &&
                <p>This {this.props.league.getTypeWord()} is using an external site to run tournaments - however it is possible to run a NerdHerder tournament alongside an external tournament. Add one if you want.</p>}
                {this.props.league.tournament_ids.length === 0 && this.props.league.type !== 'tournament' &&
                <p>This {this.props.league.getTypeWord()} has no tournaments planned. You should add one!</p>}
                
                {tournamentListItems}
                
                <div className="text-end">
                    <Button variant='primary' onClick={()=>this.onAddTournament()}>Add Tournament</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueChatManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueChatManagementCard'>
                <LeagueChatManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueChatManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            chatChannelEnabled: 'members',
            formAltChatText: '',
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        let newFormAltChatText = updatedLeague.alt_chat_text;
        if (newFormAltChatText === null) newFormAltChatText = '';
        this.setState({chatChannelEnabled: updatedLeague.chat_channel_enabled, formAltChatText: newFormAltChatText, updating: false})
    }

    onUpdateChatEnabled() {
        const patchData = {
            chat_channel_enabled: this.state.chatChannelEnabled
        }
        if (this.state.chatChannelEnabled === 'disabled') {
            patchData['alt_chat_text'] = this.state.formAltChatText.length===0 ? null : this.state.formAltChatText.trimEnd();
        }
        this.restPubSub.patch('league', this.props.league.id, patchData);
        this.setState({updating: true})
    }

    handleStateChange(value) {
        this.setState({chatChannelEnabled: value})
    }

    handleAltChatTextChange(event) {
        let value = event.target.value;
        this.setState({formAltChatText: value});
    }

    render() {
        const currentChatChannelEnabled = this.props.league.chat_channel_enabled;

        let title = null;
        let description = null;
        switch(this.state.chatChannelEnabled) {
            case 'members':
                title = "Members Only";
                description = `Initially, anyone can see the chat channel. Once the ${this.props.league.getTypeWord()} is running, only the players and organizers can view the chat channel and leave comments. This is the recommended setting.`;
                break;

            case 'anyone':
                title = "Anyone";
                description = `Anyone (including those not participating in the ${this.props.league.getTypeWord()}) can view the league's chat channel and leave comments.`;
                break;

            case 'disabled':
                title = "Disabled";
                description = "This chat channel is disabled.";
                break;
            
            default:
                title = null;
                description = null;
        }
        let currentBlurb = null;
        if (this.state.chatChannelEnabled === currentChatChannelEnabled) currentBlurb = " (the current setting)";

        let disableUpdateButton = true;
        if (currentChatChannelEnabled !== this.state.chatChannelEnabled) disableUpdateButton = false;
        if (this.props.league.alt_chat_text === null && this.state.formAltChatText !== '') disableUpdateButton = false;
        if (this.props.league.alt_chat_text !== null && this.state.formAltChatText !== this.props.league.alt_chat_text) disableUpdateButton = false;

        return(
            <NerdHerderStandardCardTemplate id="chat-card" title={`${this.props.league.getTypeWordCaps()} Chat Channel`} titleIcon="chat.png">
                <Form>
                    <Form.Group className='form-outline mb-2'>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup size='sm' name='league-chat' type="radio" value={this.state.chatChannelEnabled} onChange={(e)=>this.handleStateChange(e)}>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-members' value={'members'}>Members</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-anyone' value={'anyone'}>Anyone</ToggleButton>
                                <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-disabled' value={'disabled'}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                    <Form.Group className='form-outline mb-2'>
                        <Form.Text>
                            <p><b>{title}{currentBlurb}</b> - {description}</p>
                        </Form.Text>
                    </Form.Group>
                    <Collapse in={this.state.chatChannelEnabled==='disabled'}>
                        <div>
                            <hr/>
                            <Form.Group className='form-outline mb-2'>
                                <div>
                                    <Form.Label><b>Chat Disabled Message</b> (Optional)</Form.Label>
                                </div>
                                <div className='mb-2'>
                                    <Form.Text>
                                        If you have an alternate chat running, tell your players about it here. You are encouraged to add links (e.g. Discord channel).
                                    </Form.Text>
                                </div>
                                <div style={{position: 'relative'}}>
                                    <Form.Control id='alt_chat_text' name='alt_chat_text' as="textarea" rows={4}  disabled={this.state.updating} onChange={(e)=>this.handleAltChatTextChange(e)} autoComplete='off' value={this.state.formAltChatText} maxLength={500}/>
                                    <FormTextInputLimit current={this.state.formAltChatText.length} max={500}/>
                                </div>
                            </Form.Group>
                        </div>
                    </Collapse>
                    <div className='text-end'>
                        <Button type='button' variant='primary' disabled={disableUpdateButton || this.state.updating} onClick={()=>this.onUpdateChatEnabled()}>Update</Button>
                    </div>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class ManageManagersCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ManageManagersCard'>
                <ManageManagersCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ManageManagersCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.typeaheadRef = React.createRef();

        this.state = {
            updating: false,
            creatorUserId: this.props.league.creator_id,
            managerUserIds: [],
            addUserId: null,
            managerIsPlayer: {},
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        const newManagerIsPlayer = {};
        for (const userId of updatedLeague.manager_ids) {
            if (updatedLeague.player_ids.includes(userId)) {
                newManagerIsPlayer[userId] = true;
            } else {
                newManagerIsPlayer[userId] = false;
            }
        }
        this.setState({managerUserIds: updatedLeague.manager_ids, managerIsPlayer: newManagerIsPlayer, creatorUserId: updatedLeague.creator_id, updating: false});
    }

    onChangeManager(managerId, isManager) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', managerId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({manager: isManager})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
            if (this.typeaheadRef && this.typeaheadRef.current) {
                this.typeaheadRef.current.clear();
            }
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onChangePlayer(managerId, isPlayer) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', managerId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({player: isPlayer})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    handleChangeUsername(userDetails) {
        if (userDetails === null) {
            this.setState({addUserId: null});
        } else {
            this.setState({addUserId: userDetails.id});
        }
    }

    render() {
        // can't add the user as a manger if we are already adding a user, if the user isn't set, or if the user is already a manager
        let addUserDisabled = false;
        if (this.state.updating) addUserDisabled = true;
        if (this.state.addUserId === null) addUserDisabled = true;
        if (this.state.managerUserIds.includes(this.state.addUserId)) addUserDisabled = true;

        // hide the delete button for the creator and add checkboxes to make managers players
        const deleteButtonList = []
        const playerCheckboxList = {}
        const leagueIsFull = this.props.league.isFull();
        for (const userId of this.state.managerUserIds) {
            // eslint-disable-next-line eqeqeq
            if (userId != this.props.league.creator_id) {
                deleteButtonList.push(userId);
            }
            const disableBecauseFull = leagueIsFull && !this.state.managerIsPlayer[userId];
            playerCheckboxList[userId] = <Form.Check disabled={this.state.updating || disableBecauseFull} onChange={(event)=>this.onChangePlayer(userId, event.target.checked)} autoComplete='off' checked={this.state.managerIsPlayer[userId]}/>
        }

        // show extra Ids if needed
        let extraTableId = null;
        if (this.props.league.topic.external_site && this.props.league.external_site) extraTableId = this.props.league.topic.external_site;

        // don't allow a league to go past 5 managers
        const managerLimit = 5;
        let showAddButton = true;
        if (this.state.managerUserIds.length >= managerLimit) showAddButton = false;

        return(
            <NerdHerderStandardCardTemplate id="manage-organizers-card" title="Manage Organizers" titleIcon="helmet.png">
                <p className='text-muted'>
                    <small>Manage your organizers here. You may assign up to {managerLimit} organizers. Tick the box if the manager is also a player.</small>
                </p>
                {this.props.league.max_players && leagueIsFull &&
                <div>
                    <Alert variant='danger'><b>This {this.props.league.getTypeWordCaps()} Is Full</b><br/><small>This {this.props.league.getTypeWord()} is capped at {this.props.league.max_players} players. It is not possible to include additional managers as players when the cap is reached (you may remove the player checkmark to allow for additional non-manager players).</small></Alert>
                </div>}
                <Form>
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.managerUserIds} title='Organizers' disable={this.state.updating} headers={['Manager', 'Player?']} middleColumnContent={playerCheckboxList} showDeleteButton={deleteButtonList} extraId={extraTableId} onDelete={(userId)=>this.onChangeManager(userId, false)} localUser={this.props.localUser}/>
                    </Form.Group>
                    {showAddButton &&
                    <Form.Group className='form-outline mb-3'>
                        <Row>
                            <Col>
                                <Form.Label>Add Organizer</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pe-0'>
                                <FormTypeahead ref={this.typeaheadRef} placeholder='Username' delay={300} endpoint='user' queryParams={{'username-similar': 'query'}} labelKey='username' onChange={(s)=>{this.handleChangeUsername(s)}} disabled={this.state.updating}/>
                            </Col>
                            <Col xs='auto'>
                                <Button className='mt-1 float-end' size='sm' type='button' variant='primary' disabled={addUserDisabled} onClick={()=>this.onChangeManager(this.state.addUserId, true)}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                            </Col>
                        </Row>
                    </Form.Group>}
                    {!showAddButton &&
                    <Form.Group className='form-outline mb-3'>
                        <Row>
                            <Col>
                                <b className='text-danger'>This {this.props.league.getTypeWord()} has reached the maximum number of managers</b>
                            </Col>
                        </Row>
                    </Form.Group>}
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class PlayersUsersCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='PlayerUsersCard'>
                <PlayersUsersCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class PlayersUsersCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.typeaheadRef = React.createRef();

        this.state = {
            updating: false,
            creatorUserId: this.props.league.creator_id,
            managerUserIds: [],
            playerUserIds: [],
            invitedUserIds: [],
            requestedUserIds: [],
            interestedUserIds: [],
            interestedUserLevels: {},
            waitlistUserIds: [],
            waitlistUserPositions: {},
            pseudoContactUserIds: [],
            pseudoContactDict: {},
            invitedUserId: null,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('self-pseudocontacts', null, (d, k)=>this.updatePseudoContacts(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);

        const modifiedInterestedUserIdList = [];
        const modifiedInterestedUserLevels = {};
        for (const userId of updatedLeague.interested_user_ids) {
            if (!updatedLeague.manager_ids.includes(userId) && !updatedLeague.player_ids.includes(userId) &&
                !updatedLeague.invite_sent_user_ids.includes(userId) && !updatedLeague.join_request_user_ids.includes(userId) &&
                !updatedLeague.waitlist_user_ids.includes(userId)) {
                modifiedInterestedUserIdList.push(userId);
                modifiedInterestedUserLevels[userId] = 'none';
                for (const usersLeagues of updatedLeague.interested_users_leagues) {
                    // eslint-disable-next-line eqeqeq
                    if (usersLeagues.user_id == userId) {
                        modifiedInterestedUserLevels[userId] = usersLeagues.level_of_interest;
                        break;
                    }
                }
            }
        }

        const modifiedWaitlistUserPositions = {};
        for (const usersLeagues of updatedLeague.waitlist_users_leagues) {
            modifiedWaitlistUserPositions[usersLeagues.user_id] = usersLeagues.waitlist_position;
        }

        this.setState({managerUserIds: updatedLeague.manager_ids,
                       creatorUserId: updatedLeague.creator_id,
                       playerUserIds: updatedLeague.player_ids,
                       invitedUserIds: updatedLeague.invite_sent_user_ids,
                       requestedUserIds: updatedLeague.join_request_user_ids,
                       interestedUserIds: modifiedInterestedUserIdList,
                       interestedUserLevels: modifiedInterestedUserLevels,
                       waitlistUserIds: updatedLeague.waitlist_user_ids,
                       waitlistUserPositions: modifiedWaitlistUserPositions, 
                       updating: false});
        
    }

    updatePseudoContacts(pseudoContactData, key) {
        const newPseudoContactsUserIds = [];
        const newPseudoContactsDict = {};
        
        for (const userId of pseudoContactData.contacts) {
            newPseudoContactsUserIds.push(userId);
            newPseudoContactsDict[userId] = {reason: 'contact'};
        }

        for (const contactContact of pseudoContactData.contact_contacts) {
            let userId = contactContact.user_id;
            newPseudoContactsUserIds.push(userId);
            newPseudoContactsDict[userId] = {reason: 'shared contact'};
        }

        for (const recentGameUser of pseudoContactData.recent_games) {
            let userId = recentGameUser.user_id;
            newPseudoContactsUserIds.push(recentGameUser.user_id);
            newPseudoContactsDict[userId] = {reason: 'prior opponent'}
        }

        for (const recentLeagueUser of pseudoContactData.recent_leagues) {
            let userId = recentLeagueUser.user_id;
            newPseudoContactsUserIds.push(recentLeagueUser.user_id);
            newPseudoContactsDict[userId] = {reason: 'prior league'}
        }

        for (const manageVenueUser of pseudoContactData.manage_venue) {
            let userId = manageVenueUser.user_id;
            newPseudoContactsUserIds.push(manageVenueUser.user_id);
            newPseudoContactsDict[userId] = {reason: 'venue patron'}
        }

        this.setState({pseudoContactUserIds: newPseudoContactsUserIds, pseudoContactDict: newPseudoContactsDict});
    }

    onSendInvite(userId) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({invite_sent: true})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
            if (this.typeaheadRef && this.typeaheadRef.current) {
                this.typeaheadRef.current.clear();
            }
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onCancelInvite(userId) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({invite_sent: false})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onRemovePlayer(userId) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({player: false})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onRejectJoin(userId) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({join_request: false})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onAcceptJoin(userId) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({player: true, join_request: false})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onAddWaiting(userId) {
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-league', 'user-league',
                                                                'user-id', userId,
                                                                'league-id', this.props.league.id);
        joinModelRestApi.patch({player: true, waitlist_position: null})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    handleChangeUsername(userDetails) {
        if (userDetails === null) {
            this.setState({invitedUserId: null});
        } else {
            this.setState({invitedUserId: userDetails.id});
        }
    }

    render() {
        // can't invite users that are players, managers, or already invited, or if the league is full
        let leagueIsFull = this.props.league.isFull();
        let inviteDisableMessage = null;
        let inviteUserDisabled = false;
        if (this.state.updating) inviteUserDisabled = true;
        if (this.state.invitedUserId === null) inviteUserDisabled = true;
        if (this.state.playerUserIds.includes(this.state.invitedUserId)) {
            inviteDisableMessage = 'That user is already a player';
            inviteUserDisabled = true;
        }
        if (this.state.managerUserIds.includes(this.state.invitedUserId)) {
            inviteDisableMessage = 'That user is already a manager';
            inviteUserDisabled = true;
        }
        if (this.state.invitedUserIds.includes(this.state.invitedUserId)) {
            inviteDisableMessage = 'That user is already invited';
            inviteUserDisabled = true;
        }

        let showDisabled = false;
        if (['draft', 'archived'].includes(this.props.league.state)) {
            showDisabled = true;
        }

        // generate invite buttons for interested users
        const interestedUserInviteButtons = {};
        for (const userId of this.state.interestedUserIds) {
            interestedUserInviteButtons[userId] = <NerdHerderToolTipButton size='sm' type='button' variant='primary' placement='left' icon='flaticon-add' tooltipText='send invitation' disabled={this.state.updating || leagueIsFull || showDisabled} onClick={()=>this.onSendInvite(userId)}/>
        }

        const interestLevelString = {some: <small>Somewhat<br/>Interested</small>,
                                     nominal: <small>Interested</small>,
                                     very: <small>Very<br/>Interested</small>}
        const interestMiddleRowContent = {};
        for (const [userId, level] of Object.entries(this.state.interestedUserLevels)) {
            interestMiddleRowContent[userId] = interestLevelString[level];
        }

        const requestRightRowContent = {};
        const requestMiddleRowContent = {};
        for (const userId of this.state.requestedUserIds) {
            requestMiddleRowContent[userId] = <NerdHerderToolTipButton size='sm' type='button' variant='primary' placement='left' icon='flaticon-add' tooltipText='accept user' disabled={this.state.updating || showDisabled} onClick={()=>this.onAcceptJoin(userId)}/>
            requestRightRowContent[userId] =  <NerdHerderToolTipButton size='sm' type='button' variant='danger'  placement='left' icon='flaticon-recycle-bin-filled-tool' tooltipText='reject user' disabled={this.state.updating} onClick={()=>this.onRejectJoin(userId)}/>
        }

        // generate add buttons for interested users
        const waitingUserAddButtons = {};
        const waitingUserPositions = {};
        for (const userId of this.state.waitlistUserIds) {
            waitingUserAddButtons[userId] = <NerdHerderToolTipButton size='sm' type='button' variant='primary' placement='left' icon='flaticon-add' tooltipText='add waiting user' disabled={this.state.updating || leagueIsFull || showDisabled} onClick={()=>this.onAddWaiting(userId)}/>
            waitingUserPositions[userId] = <span>{this.state.waitlistUserPositions[userId]}</span>
        }

        // generate add buttons for 'you may know' users
        const mayKnowUserIdList = [];
        const mayKnowAddButtons = {};
        const mayKnowMiddleRowContent = {};
        for (const userId of this.state.pseudoContactUserIds) {
            // leave out players on the other lists
            if (this.state.requestedUserIds.includes(userId) ||
                this.state.interestedUserIds.includes(userId) ||
                this.state.invitedUserIds.includes(userId) ||
                this.state.managerUserIds.includes(userId) ||
                this.state.playerUserIds.includes(userId)) {
                continue;
            }

            const userDict = this.state.pseudoContactDict[userId];
            mayKnowUserIdList.push(userId);
            mayKnowAddButtons[userId] = <NerdHerderToolTipButton size='sm' type='button' variant='primary' placement='left' icon='flaticon-add' tooltipText='send invitation' disabled={this.state.updating || leagueIsFull || showDisabled} onClick={()=>this.onSendInvite(userId)}/>
            mayKnowMiddleRowContent[userId] = userDict.reason;
        }

        // show extra Ids if needed
        let extraTableId = null;
        if (this.props.league.topic.external_site && this.props.league.external_site) extraTableId = this.props.league.topic.external_site;

        return(
            <NerdHerderStandardCardTemplate id="manage-players-card" title="Manage Players & Invites" titleIcon="team-management.png">
                {!showDisabled &&
                <p className='text-muted'><small>Remove players, or invite new ones below.</small></p>}
                {showDisabled && this.props.league.state === 'draft' &&
                <p className='text-muted'><small>Once the {this.props.league.getTypeWord()} is out of the draft state you can invite players to join.</small></p>}
                {showDisabled && this.props.league.state === 'archived' &&
                <p className='text-muted'><small>You cannot invite players to an archived {this.props.league.getTypeWord()}.</small></p>}
                {this.props.league.max_players && !leagueIsFull &&
                <div>
                    <Alert variant='warning'><small>This {this.props.league.getTypeWord()} is capped at {this.props.league.max_players} players. Invitations are considered a 'for sure' pass into the {this.props.league.getTypeWord()}, therefore invited players count against the capped limit.</small></Alert>
                </div>}
                {this.props.league.max_players && leagueIsFull &&
                <div>
                    <Alert variant='danger'><b>This {this.props.league.getTypeWordCaps()} Is Full</b><br/><small>This {this.props.league.getTypeWord()} is capped at {this.props.league.max_players} players. Invitations are considered a 'for sure' pass into the {this.props.league.getTypeWord()}, therefore invited players count against the capped limit. More players cannot be invited unless the cap is increased.</small></Alert>
                </div>}
                <Form>
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.playerUserIds} title='Current Players' disable={this.state.updating} extraId={extraTableId} maxHeight='350px' showTotalUsers={true} totalUsersNoun='players'
                                      showDeleteButton={true} onDelete={(userId)=>this.onRemovePlayer(userId)} localUser={this.props.localUser}
                                      deleteButtonToolTipText={'remove player'}
                                      emptyMessage={`No users have joined this ${this.props.league.getTypeWord()}.`}/>
                    </Form.Group>
                    {this.state.requestedUserIds.length > 0 &&
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.requestedUserIds} title='Users Requesting to Join' disable={this.state.updating} extraId={extraTableId} maxHeight='350px' showTotalUsers={true}
                                      showDeleteButton={false} headers={['','button','button']} middleColumnContent={requestMiddleRowContent} rightColumnContent={requestRightRowContent} localUser={this.props.localUser}/>
                    </Form.Group>}
                    {this.state.invitedUserIds.length > 0 &&
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.invitedUserIds} title='Invited Users' disable={this.state.updating} extraId={extraTableId} maxHeight='350px' showTotalUsers={true}
                                      showDeleteButton={true} deleteButtonToolTipText={'recind invitation'} onDelete={(userId)=>this.onCancelInvite(userId)} localUser={this.props.localUser}/>
                    </Form.Group>}
                    {this.state.waitlistUserIds.length > 0 &&
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.waitlistUserIds} title='Waitlisted Users' disable={this.state.updating} extraId={extraTableId} maxHeight='350px' showTotalUsers={true}
                                      showDeleteButton={false} headers={['', '','button']} leftColumnContent={waitingUserPositions} rightColumnContent={waitingUserAddButtons} localUser={this.props.localUser}/>
                    </Form.Group>}
                    {this.state.interestedUserIds.length > 0 &&
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.interestedUserIds} title='Interested Users' disable={this.state.updating} extraId={extraTableId} maxHeight='350px' showTotalUsers={true}
                                      showDeleteButton={false} headers={['','','button']} middleColumnContent={interestMiddleRowContent} rightColumnContent={interestedUserInviteButtons} localUser={this.props.localUser}/>
                    </Form.Group>}
                    {this.state.pseudoContactUserIds.length > 0 && mayKnowUserIdList.length > 0 && !leagueIsFull &&
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={mayKnowUserIdList} title='Users You May Know' disable={this.state.updating} extraId={extraTableId} maxHeight='350px' showTotalUsers={true}
                                      showDeleteButton={false} headers={['','','button']} middleColumnContent={mayKnowMiddleRowContent} rightColumnContent={mayKnowAddButtons} localUser={this.props.localUser}/>
                    </Form.Group>}
                    {!leagueIsFull && 
                    <Form.Group className='form-outline mb-3'>
                        <Row>
                            <Col>
                                <Form.Label>Send Invitation to Join League</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pe-0'>
                                <FormTypeahead ref={this.typeaheadRef} placeholder='Username' delay={300} endpoint='user' queryParams={{'username-similar': 'query'}} labelKey='username' onChange={(s)=>{this.handleChangeUsername(s)}} disabled={this.state.updating || showDisabled}/>
                                {inviteDisableMessage &&
                                <Form.Text>{inviteDisableMessage}</Form.Text>}
                            </Col>
                            <Col xs='auto'>
                                <Button className='mt-1 float-end' size='sm' type='button' variant='primary' disabled={inviteUserDisabled || showDisabled} onClick={()=>this.onSendInvite(this.state.invitedUserId)}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                            </Col>
                        </Row>
                    </Form.Group>}
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueFilesManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueFilesManagementCard'>
                <LeagueFilesManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueFilesManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.dzRef = React.createRef();

        this.state = {
            updating: false,
            showEditFileModal: false,
            editFileId: null,
            successUploads: {},
            fileIds: [],
            files: {},
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        for (const fileData of leagueData.files) {
            if (this.state.successUploads.hasOwnProperty(fileData.filename)) {
                setTimeout(()=>this.doFileRename(fileData.id, fileData.filename), 200);
            }
            this.setState((state) => {
                return {files: {...state.files, [fileData.id]: fileData}}
            });
        }
        this.setState({updating: false, fileIds: leagueData.file_ids});
    }

    onDzSending(file) {
        this.setState({updating: true})
    }

    onDzSuccess(file) {
        this.dzRef.current.clearUploadedFiles();
        this.setState((state) => {
            return {successUploads: {...state.successUploads, [file.upload.filename]: file.name}}
        });
        this.restPubSub.refresh('league', this.props.league.id);
    }

    onDzError(file) {
        this.dzRef.current.clearUploadedFiles();
        this.restPubSub.refresh('league', this.props.league.id);
    }

    doFileRename(fileId, randomFileName) {
        let desiredFileName = this.state.successUploads[randomFileName];
        // if the filename is too long we shorten it
        if (desiredFileName.length > 50) {
            let extension = desiredFileName.split('.').pop();
            let trimLength = 49 - extension.length;
            desiredFileName = desiredFileName.slice(0, trimLength);
            desiredFileName = `${desiredFileName}.${extension}`;
        }
        this.setState({updating: true});
        this.restApi.genericPatchEndpointData('file', fileId, {filename: desiredFileName})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
            this.restPubSub.refresh('league', this.props.league.id);
        });
    }

    onChangeAccess(fileId, event) {
        const value = event.target.value;
        this.setState({updating: true});
        this.restApi.genericPatchEndpointData('file', fileId, {access: value})
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
            this.restPubSub.refresh('league', this.props.league.id);
        });
    }

    onDelete(fileId) {
        this.setState({updating: true});
        this.restApi.genericDeleteEndpointData('file', fileId)
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
            this.restPubSub.refresh('league', this.props.league.id);
        });
    }

    onShowEditFileModal(fileId) {
        this.setState({editFileId: fileId, showEditFileModal: true});
    }

    cancelEditFileModal() {
        this.setState({updating: true, editFileId: null, showEditFileModal: false});
        this.restPubSub.refresh('league', this.props.league.id);
    }

    render() {
        const fileRows = [];
        for (const fileId of this.state.fileIds) {
            const fileData = this.state.files[fileId];
            const iconUrl = getFileUiIconUrl(fileData.path);
            const row =
                <div key={fileId}>
                    <Row className='my-0'>
                        <Col>
                            <div className='cursor-pointer' onClick={()=>this.onShowEditFileModal(fileId)}>
                                <Image src={iconUrl} alt='file icon' width='20px'/>
                                <small> {fileData.filename}</small>
                            </div>
                        </Col>
                        <Col className='px-0' xs='auto'>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.onChangeAccess(fileId, e)} value={fileData.access} required>
                                <option value='managers'>Organizers</option>
                                <option value='members'>Members</option>
                                <option value='anyone'>Anyone</option>
                            </Form.Select>
                        </Col>
                        <Col xs='auto'>
                            <Button size='sm' variant='danger' disabled={this.state.updating} onClick={()=>this.onDelete(fileId)}><NerdHerderFontIcon icon='flaticon-recycle-bin-filled-tool'/></Button>
                        </Col>
                    </Row>
                    <Row className='my-0'>
                        <Col className='pe-1' xs='auto'>
                            <div style={{width: '20px'}}/>
                        </Col>
                        <Col className='px-0' xs='auto'>
                            <div className='cursor-pointer' onClick={()=>this.onShowEditFileModal(fileId)}>
                                <small><small className='text-muted'>{fileData.description}</small></small>
                            </div>
                        </Col>
                    </Row>
                    <hr className='my-1'/>
                </div>
            fileRows.push(row);
        }

        return(
            <NerdHerderStandardCardTemplate id="manage-files-card" title={`${this.props.league.getTypeWordCaps()} Files`} titleIcon='folder-config.png'>
                {this.state.showEditFileModal &&
                <NerdHerderEditFileModal fileId={this.state.editFileId} onCancel={()=>this.cancelEditFileModal()} localUser={this.props.localUser}/>}
                <Form>
                    <Form.Group className="mb-2">
                        <Row className='text-center align-items-center'>
                            <Col>
                                <NerdHerderDropzoneFileUploader
                                    ref={this.dzRef}
                                    localUser={this.props.localUser}
                                    message={'Drop file here to add'}
                                    maxFiles={1}
                                    uploadUrl={`/rest/v1/dz-league-file-upload/${this.props.league.id}`}
                                    sendingCallback={(f)=>this.onDzSending(f)}
                                    successCallback={(f)=>this.onDzSuccess(f)}
                                    errorCallback={(f)=>this.onDzError(f)}/>
                            </Col>
                        </Row>
                    </Form.Group>
                    {fileRows.length > 0 &&
                    <Form.Group className="mb-2">
                        {fileRows}
                    </Form.Group>}
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueExternalLinksManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueExternalLinksManagementCard'>
                <LeagueExternalLinksManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueExternalLinksManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.dzRef = React.createRef();

        this.state = {
            updating: false,
            isUpdated: false,
            links: [],
            texts: [],
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const newLinks = [];
        const newTexts = [];
        if (leagueData.external_links !== null) {
            const lines = leagueData.external_links.split('\n');
            for (const line of lines) {
                if (line.length === 0) continue;
                if (line.startsWith('http://') || line.startsWith('https://')) {
                    newLinks.push(line.trim());
                    newTexts.push('');
                } else if (line.includes('http://')) {
                    const result = line.split('http://', 2);
                    let text = result[0];
                    let link = `http://${result[1]}`;
                    newLinks.push(link.trim());
                    newTexts.push(text.trim());
                } else if (line.includes('https://')) {
                    const result = line.split('https://', 2);
                    let text = result[0];
                    let link = `https://${result[1]}`;
                    newLinks.push(link.trim());
                    newTexts.push(text.trim());
                } else {
                    newLinks.push('');
                    newTexts.push(line.trim());
                }
            }
            this.setState({links: newLinks, texts: newTexts, updating: false, isUpdated: false});
        }
    }

    onUpdateLeague() {
        let newExternalLinks = '';
        for (let i=0; i<this.state.links.length; i++) {
            const link = this.state.links[i].trim();
            const text = this.state.texts[i].trim();
            if (newExternalLinks.length > 0) newExternalLinks += '\n';
            if (text.length > 0 && link.length > 0) {
                newExternalLinks += `${text} ${link}`;
            } else if (link.length > 0) {
                newExternalLinks += link;
            } else if (text.length > 0) {
                newExternalLinks += text;
            }
        }
        if (newExternalLinks.length === 0) newExternalLinks = null;
        this.setState({updating: true});
        this.restPubSub.patch('league', this.props.league.id, {external_links: newExternalLinks});
    }

    onAddRow() {
        const newLinks = [...this.state.links];
        const newTexts = [...this.state.texts];
        newLinks.push('');
        newTexts.push('');
        this.setState({isUpdated: true, links: newLinks, texts: newTexts});
    }

    onDeleteRow(index) {
        const newLinks = [...this.state.links];
        const newTexts = [...this.state.texts];
        newLinks.splice(index, 1);
        newTexts.splice(index, 1);
        this.setState({isUpdated: true, links: newLinks, texts: newTexts});
    }

    handleLinkChange(index, event) {
        let value = event.target.value;
        const newLinks = [...this.state.links];
        newLinks.splice(index, 1, value);
        this.setState({isUpdated: true, links: newLinks});
    }

    handleTextChange(index, event) {
        let value = event.target.value;
        const newTexts = [...this.state.texts];
        newTexts.splice(index, 1, value);
        this.setState({isUpdated: true, texts: newTexts});
    }

    render() {
        const linkRows = [];
        let disableUpdateButton = false;

        for (let i=0; i<this.state.links.length; i++) {
            const link = this.state.links[i];
            const text = this.state.texts[i];
            let textIssue = false;
            let noLink = false;
            let linkIssue = false;

            if (text.length !== 0) {
                if (text.length < 6) {
                    textIssue = true;
                    disableUpdateButton = true;
                } else if (link.length === 0) {
                    noLink = true;
                    disableUpdateButton = true;
                } else if (!isValidHttpUrl(link)) {
                    linkIssue = true;
                    disableUpdateButton = true;
                }
            } else if (link.length !== 0) {
                if (!isValidHttpUrl(link)) {
                    linkIssue = true;
                    disableUpdateButton = true;
                }
            }

            const row =
                <div key={i}>
                    <Row className='my-0'>
                        <Col>
                            <Form.Control size='sm' type="text" placeholder='Description' disabled={this.state.updating} onChange={(e)=>this.handleTextChange(i, e)} autoComplete='off' value={text} minLength={4} maxLength={100}/>
                            {textIssue &&
                            <Form.Text className='text-danger'>This description is not long enough.</Form.Text>}
                        </Col>
                        <Col>
                            <Form.Control size='sm' type="text" placeholder='URL' disabled={this.state.updating} onChange={(e)=>this.handleLinkChange(i, e)} autoComplete='off' value={link} minLength={6} maxLength={300}/>
                            {noLink &&
                            <Form.Text className='text-danger'>Enter a URL beginning with http or https.</Form.Text>}
                            {linkIssue &&
                            <Form.Text className='text-danger'>This URL doesn't look right. It should start with http or https.</Form.Text>}
                        </Col>
                        <Col xs='auto'>
                            <Button size='sm' variant='danger' disabled={this.state.updating} onClick={()=>this.onDeleteRow(i)}><NerdHerderFontIcon icon='flaticon-recycle-bin-filled-tool'/></Button>
                        </Col>
                    </Row>
                    <hr className='my-1'/>
                </div>
            linkRows.push(row);
        }

        if (this.state.updating) disableUpdateButton = true;
        if (this.state.isUpdated === false) disableUpdateButton = true;

        // create the example
        const leagueLinks = [];
        if (this.state.links.length > 0) {
            let index = 0;
            for (let i=0; i<this.state.links.length; i++) {
                const link = this.state.links[i];
                const text = this.state.texts[i];
                if (text.length === 0 && link.length !== 0) {
                    leagueLinks.push(<div key={index}><a href={link} rel="noreferrer" target='_blank'>{link}</a></div>)
                } else if (text.length !== 0 && link.length !== 0) {
                    leagueLinks.push(<div key={index}><a href={link} rel="noreferrer" target='_blank'>{text}</a></div>)
                } else if (text.length !== 0 && link.length === 0) {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    leagueLinks.push(<div key={index}><a href={'#'} rel="noreferrer" target='_blank'>{text}</a></div>)
                }
                index++;
            }
        }

        return(
            <NerdHerderStandardCardTemplate id="manage-links-card" title={`${this.props.league.getTypeWordCaps()} External Links`} titleIcon='link.png'>
                <Form>
                    {linkRows.length > 0 &&
                    <Form.Group className="mb-2">
                        {linkRows}
                    </Form.Group>}
                    {leagueLinks.length === 0 &&
                    <Row>
                        <Col>
                            <small className='text-muted'>This league has no external links. Click the + to add some!</small>
                        </Col>
                        <Col xs='auto'>
                            <Button size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.onAddRow()}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                        </Col>
                    </Row>}
                    {leagueLinks.length !== 0 &&
                    <Row>
                        <Col></Col>
                        <Col xs='auto'>
                            <Button size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.onAddRow()}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                        </Col>
                    </Row>}
                </Form>
                <hr/>
                {leagueLinks.length > 0 &&
                <small className='text-muted'>How external links will appear to users:</small>}
                {leagueLinks.length > 0 &&
                <div className='mb-2 p-2' style={{border: '1px solid #ced4da', borderRadius: '3px'}}>
                    {leagueLinks}
                </div>}
                <div className='text-end'>
                    <Button variant='primary' disabled={disableUpdateButton} onClick={()=>this.onUpdateLeague()}>Update</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeaguePollsManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeaguePollsManagementCard'>
                <LeaguePollsManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeaguePollsManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.dzRef = React.createRef();

        this.state = {
            updating: false,
            showEditPollModal: false,
            editPollId: null,
            pollIds: [],
            polls: {},
            pollOptions: {},
            pollVotes: {},
        }

        this.pollStateChoices = {draft: 'Draft', posted: 'Posted', completed: 'Complete'};
        this.resultsShownChoices = {always: 'Always', postvote: 'After voting', completion: 'When poll completes'};
        this.votersShownChoices = {false: 'Poll is anonymous', true: "Voters are displayed"};
        this.allowedVoterChoices = {members: 'Players & managers', players: 'Players only', managers: 'Managers only', interested_users: 'Anyone'};
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const newPollIds = [...leagueData.poll_ids];
        this.setState({pollIds: newPollIds, updating: false});
        for (const pollId of newPollIds) {
            if (!this.state.polls.hasOwnProperty(pollId)) {
                this.setState((state) => {
                    return {polls: {...state.polls, [pollId]: null}}
                });
            }
            const sub = this.restPubSub.subscribe('poll', pollId, (d, k)=>this.updatePoll(d, k), null, pollId);
            this.restPubSubPool.add(sub);
        }
    }

    updatePoll(pollData, pollId) {
        const newPoll = NerdHerderDataModelFactory('poll', pollData);
        this.setState((state) => {
            return {polls: {...state.polls, [pollId]: newPoll}}
        });
    }

    onCancelModal() {
        this.setState({showEditPollModal: false, editPollId: null, updating: true});
        this.restPubSub.refresh('league', this.props.league.id, 200);
    }

    onAddPoll() {
        this.setState({showEditPollModal: true, editPollId: null});
    }

    onDeletePoll(index, event) {
        event.stopPropagation();
        this.restApi.genericDeleteEndpointData('poll', index)
        .then((response)=>{
            this.restPubSub.refresh('league', this.props.league.id);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        })
    }

    render() {
        const pollRows = [];

        for (const pollId of this.state.pollIds) {
            const pollData = this.state.polls[pollId];
            if (pollData === null) continue;
            
            let endDate = pollData.end_date;
            if (endDate !== null) {
                let date = new Date(endDate);
                endDate = generateDateString(date);
            }

            const row =
                <div key={pollId} className="my-1" onClick={()=>this.setState({showEditPollModal: true, editPollId: pollId})}>
                    <div className="list-group-item list-group-item-action rounded align-middle">
                        <Row  className=' align-items-center'>
                            <Col>
                                <div>
                                    <b>{pollData.title}</b><small className='text-muted'> ({this.pollStateChoices[pollData.state]})</small>
                                </div>
                                {pollData.text &&
                                <div>
                                    <small>{pollData.text}</small>
                                </div>}
                                <div>
                                    {endDate !== null &&
                                    <div>
                                        <small><b>Ends:</b> {endDate}</small>
                                    </div>}
                                    <div>
                                        <small><b>Voters:</b> {this.allowedVoterChoices[pollData.allowed_voters]}</small>
                                    </div>
                                    <div>
                                        <small><b>Results Displayed:</b> {`${this.resultsShownChoices[pollData.results_shown]} (${this.votersShownChoices[pollData.voters_shown]})`}</small>
                                    </div>
                                </div>
                            </Col>
                            <Col className='ps-0' xs='auto'>
                                <Button size='sm' variant='danger' disabled={this.state.updating} onClick={(e)=>this.onDeletePoll(pollData.id, e)}><NerdHerderFontIcon icon='flaticon-recycle-bin-filled-tool'/></Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            pollRows.push(row);
        }

        return(
            <NerdHerderStandardCardTemplate id="manage-polls-card" title="Manage Polls" titleIcon='voting-config.png'>
                {this.state.showEditPollModal &&
                <NerdHerderEditPollModal pollId={this.state.editPollId} league={this.props.league} onCancel={()=>this.onCancelModal()} localUser={this.props.localUser}/>}
                {pollRows}
                {pollRows.length === 0 &&
                <p>This {this.props.league.getTypeWord()} has no polls. You should add one!</p>}
                <div className='text-end'>
                    <Button variant='primary' disabled={this.state.updating} onClick={()=>this.onAddPoll()}>Add Poll</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueListContainerManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueListContainerManagementCard'>
                <LeagueListContainerManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueListContainerManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showContentsModal: false,
            contentsModalContainerId: null,
            listContainerIdToDelete: null,
            listContainers: {},
            tournaments:{},
            events:{},
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        for (const listContainerId of leagueData.list_container_ids) {
            const alreadyHaveList = Object.keys(this.state.listContainers);
            if (!alreadyHaveList.includes(listContainerId.toString())) {
                let sub = this.restPubSub.subscribe('list-container', listContainerId, (d, k)=>this.updateListContainer(d, k), null, listContainerId);
                this.restPubSubPool.add(sub);
                this.setState((state) => {
                    return {listContainers: {...state.listContainers, [listContainerId]: null}}
                });
            }
        }
        this.setState({updating: false});
    }

    updateListContainer(listContainerData, listContainerId) {
        if (listContainerData === 'DELETED') {
            this.setState((state) => {
                return {listContainers: {...state.listContainers, [listContainerId]: null}, updating: false}
            });
        } else {
            this.setState((state) => {
                return {listContainers: {...state.listContainers, [listContainerId]: listContainerData}, updating: false}
            });
        } 
    }

    onUpdateContainer(containerId) {
        const container = this.state.listContainers[containerId];
        const patchData = {
            name: container.name.trimEnd(),
            description: container.description,
            state: container.state,
            required: container.required,
            hidden: container.hidden,
            lock_on_submit: container.lock_on_submit,
            event_id: container.event_id,
            tournament_id: container.tournament_id
        }
        this.restPubSub.patch('list-container', containerId, patchData);
        this.setState({updating: true});
    }

    handleNameChange(containerId, event) {
        let value = event.target.value;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].name = value;
        newListContainers[containerId].modified = true;
        this.setState({listContainers: newListContainers});
    }

    handleDescriptionChange(containerId, event) {
        let value = event.target.value;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].description = value;
        newListContainers[containerId].modified = true;
        this.setState({listContainers: newListContainers});
    }

    handleStateChange(containerId, event) {
        let value = event.target.value;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].state = value;
        newListContainers[containerId].modified = true;
        this.setState({listContainers: newListContainers});
    }

    handleEventChange(containerId, event) {
        let value = event.target.value;
        // eslint-disable-next-line eqeqeq
        if (value == 0) value = null;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].event_id = value;
        newListContainers[containerId].modified = true;
        // if they select an event, reset the tournament
        newListContainers[containerId].tournament_id = null;
        this.setState({listContainers: newListContainers});
    }

    handleTournamentChange(containerId, event) {
        let value = event.target.value;
        // eslint-disable-next-line eqeqeq
        if (value == 0) value = null;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].tournament_id = value;
        newListContainers[containerId].modified = true;
        // if they select a tournament that is part of an event, also select the event
        for (const tournamentSummary of this.props.league.tournament_summary) {
            // eslint-disable-next-line eqeqeq
            if (tournamentSummary.id == value) {
                if (tournamentSummary.event_id === null) {
                    newListContainers[containerId].event_id = null;
                } else {
                    newListContainers[containerId].event_id = tournamentSummary.event_id;
                }
                break;
            }
        }
        this.setState({listContainers: newListContainers});
    }

    handleRequiredChange(containerId, event) {
        let value = event.target.checked;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].required = value;
        newListContainers[containerId].modified = true;
        this.setState({listContainers: newListContainers});
    }

    handleHiddenChange(containerId, event) {
        let value = event.target.checked;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].hidden = value;
        newListContainers[containerId].modified = true;
        this.setState({listContainers: newListContainers});
    }

    handleLockOnSubmitChange(containerId, event) {
        let value = event.target.checked;
        const newListContainers = {...this.state.listContainers};
        newListContainers[containerId].lock_on_submit = value;
        newListContainers[containerId].modified = true;
        this.setState({listContainers: newListContainers});
    }

    onAddRow() {
        this.setState({updating: true});
        const postData = {
            state: 'posted',
            required: false,
            hidden: false,
            lock_on_submit: false,
            league_id: this.props.league.id,
            event_id: null,
            tournament_id: null,
            name: 'New List',
            description: null,
        };
        this.restPubSub.post('list-container', null, postData);
        this.restPubSub.refresh('league', this.props.league.id, 2000);
    }

    onDeleteRow(containerId) {
        this.setState({listContainerIdToDelete: containerId});
    }

    onAcceptDeleteRow() {
        this.restPubSub.delete('list-container', this.state.listContainerIdToDelete);
        this.restPubSub.refresh('league', this.props.league.id, 2000);
        this.setState({updating: true, listContainerIdToDelete: null});
    }

    onCancelDeleteRow() {
        this.setState({listContainerIdToDelete: null});
    }

    onShowContentsModal(containerId) {
        this.setState({showContentsModal: true, contentsModalContainerId: containerId});
    }

    onCancelContentsModal(containerId) {
        this.setState({showContentsModal: false, contentsModalContainerId: null});
    }

    render() {
        const listNoun = this.props.league.topic.list_noun;
        const listNounCaps = capitalizeFirstLetters(listNoun);
        const listNounPlural = pluralize(listNoun);

        const stateOptions = [
            <option key='posted' value='posted'>Posted</option>,
            <option key='draft' value='draft'>Draft</option>
        ];

        const eventOptions = [
            <option key={0} value={0}>No Minor Event</option>
        ];
        for (const eventSummary of this.props.league.event_summary) {
            const option = <option key={eventSummary.id} value={eventSummary.id}>{eventSummary.name}</option>
            eventOptions.push(option);
        }

        const tournamentOptions = [
            <option key={0} value={0}>No Tournament</option>
        ];
        for (const tournamentSummary of this.props.league.tournament_summary) {
            const option = <option key={tournamentSummary.id} value={tournamentSummary.id}>{tournamentSummary.name}</option>
            tournamentOptions.push(option);
        }

        const rows = [];
        for (const listContainerId of this.props.league.list_container_ids) {
            const listContainer = this.state.listContainers[listContainerId];
            if (!listContainer) continue;

            const requiredLabel = <span>Required <NerdHerderToolTipIcon title={`Required ${listNounCaps}`} message={`Only after player uploads all required ${listNounPlural} are they are able to view other players' ${listNounPlural}`}/></span>
            const hiddenLabel = <span>Hidden <NerdHerderToolTipIcon title={`Hidden ${listNounCaps}`} message={`These ${listNounPlural} are hidden from other players, when a player needs to show their ${listNoun} to another player they may use the share button`}/></span>
            const lockingLabel = <span>Lock On Submit <NerdHerderToolTipIcon title={`Locking ${listNounCaps}`} message={`After a player submits a ${listNoun}, it is locked and the player cannot change it`}/></span>

            const row =
                <div key={listContainerId}>
                    <Row>
                        <Col>
                            <b>{listNounCaps} Folder</b>
                        </Col>
                        <Col className='px-0' xs='auto'>
                            <NerdHerderToolTipButton size='sm' variant='primary' disabled={this.state.updating || listContainer.name.length < 1 || listContainer.modified !== true} onClick={()=>this.onUpdateContainer(listContainerId)} icon='flaticon-diskette' tooltipText='save'/>
                        </Col>
                        <Col className='px-0 ps-1' xs='auto'>
                            <NerdHerderToolTipButton size='sm' variant='primary' disabled={this.state.updating || listContainer.file_ids.length === 0} onClick={()=>this.onShowContentsModal(listContainerId)} icon='flaticon-folder-filled-computer-tool' tooltipText='view contents'/>
                        </Col>
                        <Col className='ps-1' xs='auto'>
                            <NerdHerderToolTipButton size='sm' variant='danger' disabled={this.state.updating} onClick={()=>this.onDeleteRow(listContainerId)} icon='flaticon-recycle-bin-filled-tool' tooltipText='delete'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control size='sm' type="text" placeholder='Name' disabled={this.state.updating} onChange={(e)=>this.handleNameChange(listContainerId, e)} autoComplete='off' value={listContainer.name} minLength={1} maxLength={20} required/>
                            {listContainer.name.length < 1 &&
                            <Form.Text className='text-danger'>This name is not long enough.</Form.Text>}
                        </Col>
                        <Col sm={4}>
                            <div>
                                <Form.Check label={requiredLabel} disabled={this.state.updating} onChange={(e)=>this.handleRequiredChange(listContainerId, e)} autoComplete='off' checked={listContainer.required}/>
                            </div>
                            <div>
                                <Form.Check label={hiddenLabel} disabled={this.state.updating} onChange={(e)=>this.handleHiddenChange(listContainerId, e)} autoComplete='off' checked={listContainer.hidden}/>
                            </div>
                            <div>
                                <Form.Check label={lockingLabel} disabled={this.state.updating} onChange={(e)=>this.handleLockOnSubmitChange(listContainerId, e)} autoComplete='off' checked={listContainer.lock_on_submit}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className='my-1'>
                        <Col>
                            <div style={{position: 'relative'}}>
                                <Form.Control size='sm' type="text" as="textarea" rows={2} placeholder="(Optional) Description of what the list should or shouldn't contain" disabled={this.state.updating} onChange={(e)=>this.handleDescriptionChange(listContainerId, e)} autoComplete='off' value={listContainer.description || ''} maxLength={200} required/>
                                <FormTextInputLimit current={listContainer.description ? listContainer.description.length : 0} max={200}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className='my-1'>
                        <Col sm={4}>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.handleEventChange(listContainerId, e)} value={listContainer.event_id || 0} required>
                                {eventOptions}
                            </Form.Select>
                        </Col>
                        <Col sm={4}>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.handleTournamentChange(listContainerId, e)} value={listContainer.tournament_id || 0} required>
                                {tournamentOptions}
                            </Form.Select>
                        </Col>
                        <Col sm={4}>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.handleStateChange(listContainerId, e)} value={listContainer.state} required>
                                {stateOptions}
                            </Form.Select>
                        </Col>
                    </Row>
                    <hr/>
                </div>
            rows.push(row);
        }

        return(
            <NerdHerderStandardCardTemplate id="manage-lists-card" title={`${listNounCaps} Management`} titleIcon='checklist.png'>
                {this.state.listContainerIdToDelete !== null &&
                <NerdHerderConfirmModal title='Delete List?' message={`Are you sure you want to delete list ${this.state.listContainers[this.state.listContainerIdToDelete].name}? Any lists uploaded by players will be deleted. This cannot be undone.`}
                    acceptButtonText='Delete' onAccept={()=>this.onAcceptDeleteRow()} onCancel={()=>this.onCancelDeleteRow()} localUser={this.props.localUser}/>}
                {this.state.showContentsModal &&
                <NerdHerderFileContainerContentsModal fileContainerId={this.state.contentsModalContainerId} league={this.props.league} onCancel={()=>this.onCancelContentsModal()} localUser={this.props.localUser}/>}
                <small className='text-muted'>If your league, events, or tournaments require users to upload a {listNoun}, this is where you set that up. Create a folder for each {listNoun} a player should upload. If your league (or event or tournament) requires 1 {listNoun} per player, create one folder. If instead players are required to upload 2 {listNounPlural}, create 2 folders, etc.</small>
                <hr/>
                <Form>
                    {rows.length !== 0 &&
                    <Form.Group className="mb-2">
                        {rows}
                    </Form.Group>}
                    <div>
                        {rows.length === 0 &&
                        <small className='text-muted'>This {this.props.league.getTypeWord()} has no folder to upload player {listNounPlural} into. Click the + to add one!  </small>}
                        <Button className='float-end' size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.onAddRow()}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                    </div>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueDeleteTheLeagueCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueDeleteTheLeagueCard'>
                <LeagueDeleteTheLeagueCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueDeleteTheLeagueCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();

        this.state = {
            updating: false,
            delete1: false,
            enable2: false,
            delete2: false,
            enable3: false,
        }
    }

    onDeleteButton1Clicked() {
        this.setState({delete1: true, updating: true});
        setTimeout(()=>this.setState({enable2: true, updating: false}), 1500);
    }

    onDeleteButton2Clicked() {
        if (!this.state.enable2) return;
        
        this.setState({delete2: true, updating: true});
        setTimeout(()=>this.setState({enable3: true, updating: false}), 1500);
    }

    onDeleteButton3Clicked() {
        if (!this.state.enable3) return;

        this.setState({updating: true});
        this.restApi.genericDeleteEndpointData('league', this.props.league.id)
        .then((response)=>{
            window.location.replace('/app/main');
        })
        .catch((error)=>{
            this.setState({updating: false});
            console.error('error when deleting league', this.props.league.id);
            console.error(error);
        });
    }

    render() {
        // only show this to league creator
        if (this.props.localUser.id !== this.props.league.creator_id) return(null);

        let buttonText = `Delete ${this.props.league.getTypeWordCaps()}`

        return(
            <NerdHerderStandardCardTemplate id="delete-league-card" title={`Delete ${this.props.league.getTypeWordCaps()}`} titleIcon="bin.png">
                <div>
                    <p>Only the {this.props.league.getTypeWord()} creator may delete it. <b>There is no undo</b>.</p>
                    If you delete the {this.props.league.getTypeWord()}, the following will also be deleted:
                    <ul>
                        <li>Associated games & tournaments</li>
                        <li>Associated minor events</li>
                        <li>Polls, teams, posts, &, and files associated with the {this.props.league.getTypeWord(true)}</li>
                        <li>Army lists uploaded by players</li>
                    </ul>
                    {this.state.enable2 &&
                    <p className='text-danger'>Click each button below to delete the league.</p>}
                </div>
                <div className='d-grid gap-2'>
                    <Button variant='danger' disabled={this.state.updating || this.state.enable2 || this.state.enable3} onClick={()=>this.onDeleteButton1Clicked()}>{buttonText}</Button>
                </div>
                <Collapse in={this.state.enable2}>
                    <div>
                        <div className='mt-2 d-grid gap-2'>
                            <Button variant='danger' disabled={this.state.updating || this.state.enable3} onClick={()=>this.onDeleteButton2Clicked()}>{buttonText}</Button>
                        </div>
                    </div>
                </Collapse>
                <Collapse in={this.state.enable3}>
                    <div>
                        <div className='mt-2 d-grid gap-2'>
                            <Button variant='danger' disabled={this.state.updating} onClick={()=>this.onDeleteButton3Clicked()}>{buttonText}</Button>
                        </div>
                    </div>
                </Collapse>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeaguePlayerPaymentsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeaguePlayerPaymentsCard'>
                <LeaguePlayerPaymentsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeaguePlayerPaymentsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.backgroundFetchPaymentIntents = null;

        this.state = {
            userFeedback: null,
            errorFeedback: null,
            updating: false,
            showPaymentDetailsModal: false,
            showPaymentDetailsModalUserId: null,
            playerUserIds: [],
            usersHasPaid: {},
            usersPaymentAmount: {},
            usersPaymentCurrency: {},
            usersPaymentCode: {},
            currencyDict: null,
            playerPaymentIntents: {},
            venue: null,
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('currency-list', null, (d,k)=>this.updateCurrencyDict(d,k));
        this.restPubSubPool.add(sub);
        if (this.props.league.venue_id !== null) {
            sub = this.restPubSub.subscribeNoRefresh('venue', this.props.league.venue_id, (d, k)=>this.updateVenue(d, k));
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        const updatedLeague = NerdHerderDataModelFactory('league', leagueData);
        const newUsersHasPaid = {};
        const newUsersPaymentAmount = {};
        const newUsersPaymentCurrency = {};
        const newUsersPaymentCode = {};
        const newPlayerPaymentIntents = {...this.state.playerPaymentIntents};
        for (const usersLeagues of updatedLeague.players_users_leagues) {
            newUsersHasPaid[usersLeagues.user_id] = usersLeagues.paid;
            newUsersPaymentAmount[usersLeagues.user_id] = usersLeagues.paid_amount;
            newUsersPaymentCurrency[usersLeagues.user_id] = usersLeagues.paid_currency;
            newUsersPaymentCode[usersLeagues.user_id] = usersLeagues.paid_code;
            if (!newPlayerPaymentIntents.hasOwnProperty(usersLeagues.user_id)) {
                newPlayerPaymentIntents[usersLeagues.user_id] = null;
            }
        }
        this.setState({
            playerUserIds: updatedLeague.player_ids,
            usersHasPaid: newUsersHasPaid,
            usersPaymentAmount: newUsersPaymentAmount,
            usersPaymentCurrency: newUsersPaymentCurrency,
            usersPaymentCode: newUsersPaymentCode,
            playerPaymentIntents: newPlayerPaymentIntents,
            updating: false
        });
        if (this.backgroundFetchPaymentIntents !== null) clearTimeout(this.backgroundFetchPaymentIntents)
        this.backgroundFetchPaymentIntents = setTimeout(()=>this.fetchPaymentIntents(), 1000);
    }

    updateVenue(venueData, key) {
        const updatedVenue = NerdHerderDataModelFactory('venue', venueData);
        this.setState({venue: updatedVenue});
    }

    updateCurrencyDict(currencyList, key) {
        let newCurrencyDict = convertListToDict(currencyList, 'alpha_id');
        this.setState({currencyDict: newCurrencyDict});
    }

    doStripeAccountLinkRedirect() {
        this.setState({userFeedback: 'Redirecting to Stripe...', updating: false, errorFeedback:null});
        window.open('https://connect.stripe.com/login');
    }

    onShowPaymentDetails(userId) {
        this.setState({showPaymentDetailsModal: true, showPaymentDetailsModalUserId: userId});
    }

    onCancelModal() {
        let userIdToRefresh = this.state.showPaymentDetailsModalUserId;
        const newPlayerIntents = {...this.state.playerPaymentIntents, [userIdToRefresh]: null};
        this.setState({showPaymentDetailsModal: false, showPaymentDetailsModalUserId: null, playerPaymentIntents: newPlayerIntents});
        this.restPubSub.refresh('league', this.props.league.id, 1000);
    }

    fetchPaymentIntents() {
        for (const [userId, paymentIntent] of Object.entries(this.state.playerPaymentIntents)) {
            if (paymentIntent === null) {
                const queryParams = {user_id: userId, league_id: this.props.league.id, venue_id: this.props.league.venue_id};
                this.restApi.genericGetEndpointData('stripe-payment-intent', 'league-reg-fee', queryParams)
                .then((response)=>{
                    const paymentIntent = response.data;
                    this.setState((state) => {
                        return {playerPaymentIntents: {...state.playerPaymentIntents, [userId]: paymentIntent}}
                    });
                })
                .catch((error)=>{
                    // and error means the user doesn't have a payment intent with stripe
                    this.setState((state) => {
                        return {playerPaymentIntents: {...state.playerPaymentIntents, [userId]: 'no payment intent'}}
                    });
                })
            }
        }
    }

    render() {
        if (this.state.currencyDict === null) return(null);
        if (this.props.league.venue_id !== null && this.state.venue === null) return(null);

        // generate 'view details' buttons
        const viewDetailsButtons = {};
        for (const userId of this.state.playerUserIds) {
            viewDetailsButtons[userId] = 
                <Button size='sm' type='button' variant='primary' disabled={this.state.updating} onClick={()=>this.onShowPaymentDetails(userId)}>
                    <NerdHerderFontIcon icon='flaticon-search'/>
                </Button>
        }

        const paymentStatusContent = {};
        for (const [userId, hasPaid] of Object.entries(this.state.usersHasPaid)) {
            let content = null;
            if (hasPaid) {
                let paidAmount = this.state.usersPaymentAmount[userId];
                let paidCurrency = this.state.usersPaymentCurrency[userId];
                let paidCode = this.state.usersPaymentCode[userId];
                let paidText = getCurrency(paidAmount, this.state.currencyDict[paidCurrency], this.props.localUser.country);
                const tooltipText = `${paidText.format()} ${paidCurrency}`;
                content =
                    <NerdHerderToolTip placement={'left'} text={tooltipText}>
                        <div>
                            <Badge bg='primary'><span className='text-capitalize cursor-help'>paid</span></Badge>
                            {paidCode &&
                            <div>
                                <small><small>{paidCode}</small></small>
                            </div>}
                        </div>
                        
                    </NerdHerderToolTip>
            } else if (this.state.playerPaymentIntents.hasOwnProperty(userId) && this.state.playerPaymentIntents[userId] === 'no payment intent') {
                content =
                    <Badge bg='light' text='dark'><span className='text-capitalize cursor-default'>no payment</span></Badge>
            } else if (this.state.playerPaymentIntents.hasOwnProperty(userId) && this.state.playerPaymentIntents[userId] !== null) {
                const paymentIntent = this.state.playerPaymentIntents[userId];
                let status = paymentIntent.status;
                let refunded = paymentIntent.refunded;
                if (status === 'succeeded') status = 'paid';
                if (refunded) status = 'refunded';
                let amount = paymentIntent.amount;
                let paidCurrency = paymentIntent.currency.toUpperCase();
                let paidText = getCurrency(amount, this.state.currencyDict[paidCurrency], this.props.localUser.country);
                let tooltipText = null;
                if (refunded) {
                    let refund_amount = paymentIntent.refund_amount;
                    let refundText = getCurrency(refund_amount, this.state.currencyDict[paidCurrency], this.props.localUser.country);
                    tooltipText = `Refund: ${refundText.format()} ${paidCurrency}`;
                } else {
                    tooltipText = `${paidText.format()} ${paidCurrency}`;
                }
                content = 
                    <NerdHerderToolTip placement={'left'} text={tooltipText}>
                        <div>
                            {status === 'paid' &&
                            <Badge bg='primary'><span className='text-capitalize cursor-help'>paid</span></Badge>}
                            {status !== 'paid' && refunded &&
                            <Badge bg='danger'><span className='text-capitalize cursor-help'>refunded</span></Badge>}
                            {status !== 'paid' && !refunded &&
                            <Badge bg='warning' text='dark'><span className='text-capitalize'>{status}</span></Badge>}
                        </div>
                    </NerdHerderToolTip>
            } else {
                content = 
                    <NerdHerderToolTip placement={'left'} text='Fetching stripe payment...'>
                        <Spinner variant='primary' size='sm' animation="border" role="status"/>
                    </NerdHerderToolTip>
            }
            paymentStatusContent[userId] = content;
        }

        return(
            <NerdHerderStandardCardTemplate id="stripe-player-payments" title="Registration Fee Status" titleIcon="team-management.png">
                {this.state.showPaymentDetailsModal &&
                <NerdHerderStripePaymentDetailsModal
                    localUser={this.props.localUser}
                    league={this.props.league}
                    userId={this.state.showPaymentDetailsModalUserId}
                    onCancel={()=>this.onCancelModal()}/>}
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.userFeedback &&
                <Alert variant='primary'>{this.state.userFeedback}</Alert>}
                <Form>
                    <Form.Group className='form-outline mb-3'>
                        <TableOfUsers userIds={this.state.playerUserIds} title='Registration Fee Status' disable={this.state.updating} localUser={this.props.localUser}
                                      headers={['User','Status','Details']} middleColumnContent={paymentStatusContent} rightColumnContent={viewDetailsButtons} 
                                      emptyMessage='There are no players to show registration fee status.'/>
                    </Form.Group>
                </Form>
                {this.state.venue && this.state.venue.isManager(this.props.localUser.id) &&
                <Row>
                    <Col>
                        <p><small className='text-muted'>Additional details & controls are available in the venue's stripe account.</small></p>
                    </Col>
                    <Col xs='auto'>
                        <Button size='sm' variant='primary' onClick={()=>this.doStripeAccountLinkRedirect()}>Stripe Account</Button>
                    </Col>
                </Row>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class LeagueRandomSelectorManagementCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='LeagueRandomSelectorManagementCard'>
                <LeagueRandomSelectorManagementCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class LeagueRandomSelectorManagementCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showSelectResultModal: false,
            showViewResultModal: false,
            selectedId: null,
            randomSelectorIdToDelete: null,
            randomSelectors: {},
            tournaments:{},
            events:{},
        }
    }

    componentDidMount() {
        this.updateLeague(this.props.league, this.props.league.id);
        let sub = this.restPubSub.subscribeNoRefresh('league', this.props.league.id, (d, k)=>this.updateLeague(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        for (const randomSelectorId of leagueData.random_selector_ids) {
            const alreadyHaveList = Object.keys(this.state.randomSelectors);
            if (!alreadyHaveList.includes(randomSelectorId.toString())) {
                let sub = this.restPubSub.subscribe('random-selector', randomSelectorId, (d, k)=>this.updateRandomSelector(d, k), null, randomSelectorId);
                this.restPubSubPool.add(sub);
                this.setState((state) => {
                    return {randomSelectors: {...state.randomSelectors, [randomSelectorId]: null}}
                });
            }
        }
        this.setState({updating: false});
    }

    updateRandomSelector(randomSelectorData, randomSelectorId) {
        if (randomSelectorData === 'DELETED') {
            this.setState((state) => {
                return {randomSelectors: {...state.randomSelectors, [randomSelectorId]: null}, updating: false}
            });
        } else {
            this.setState((state) => {
                return {randomSelectors: {...state.randomSelectors, [randomSelectorId]: randomSelectorData}, updating: false}
            });
        } 
    }

    onUpdateRandomSelector(randomSelectorId) {
        const randomSelector = this.state.randomSelectors[randomSelectorId];
        let eventId = randomSelector.event_id;
        if (eventId !== null) eventId = parseInt(eventId);
        const patchData = {
            name: randomSelector.name.trimEnd(),
            description: randomSelector.description,
            state: randomSelector.state,
            result: randomSelector.result,
            event_id: eventId,
            tournament_id: randomSelector.tournament_id
        }
        this.restPubSub.patch('random-selector', randomSelectorId, patchData);
        this.setState({updating: true});
    }

    handleNameChange(randomSelectorId, event) {
        let value = event.target.value;
        const newRandomSelectors = {...this.state.randomSelectors};
        newRandomSelectors[randomSelectorId].name = value;
        newRandomSelectors[randomSelectorId].modified = true;
        this.setState({listContainers: newRandomSelectors});
    }

    handleDescriptionChange(containerId, event) {
        let value = event.target.value;
        const newRandomSelectors = {...this.state.randomSelectors};
        newRandomSelectors[containerId].description = value;
        newRandomSelectors[containerId].modified = true;
        this.setState({listContainers: newRandomSelectors});
    }

    handleStateChange(containerId, event) {
        let value = event.target.value;
        const newRandomSelectors = {...this.state.randomSelectors};
        newRandomSelectors[containerId].state = value;
        newRandomSelectors[containerId].modified = true;
        this.setState({listContainers: newRandomSelectors});
    }

    handleEventChange(containerId, event) {
        let value = event.target.value;
        // eslint-disable-next-line eqeqeq
        if (value == 0) value = null;
        const newRandomSelectors = {...this.state.randomSelectors};
        newRandomSelectors[containerId].event_id = value;
        newRandomSelectors[containerId].modified = true;
        // if they select an event, reset the tournament
        newRandomSelectors[containerId].tournament_id = null;
        this.setState({listContainers: newRandomSelectors});
    }

    handleTournamentChange(containerId, event) {
        let value = event.target.value;
        // eslint-disable-next-line eqeqeq
        if (value == 0) value = null;
        const newRandomSelectors = {...this.state.randomSelectors};
        newRandomSelectors[containerId].tournament_id = value;
        newRandomSelectors[containerId].modified = true;
        // if they select a tournament that is part of an event, also select the event
        for (const tournamentSummary of this.props.league.tournament_summary) {
            // eslint-disable-next-line eqeqeq
            if (tournamentSummary.id == value) {
                if (tournamentSummary.event_id === null) {
                    newRandomSelectors[containerId].event_id = null;
                } else {
                    newRandomSelectors[containerId].event_id = tournamentSummary.event_id;
                }
                break;
            }
        }
        this.setState({listContainers: newRandomSelectors});
    }

    onAddRow() {
        this.setState({updating: true});
        const postData = {
            state: 'posted',
            result: null,
            league_id: this.props.league.id,
            event_id: null,
            tournament_id: null,
            name: 'New Random Selection',
            description: null,
        };
        this.restPubSub.post('random-selector', null, postData);
        this.restPubSub.refresh('league', this.props.league.id, 2000);
    }

    onDeleteRow(randomSelectorId) {
        this.setState({randomSelectorIdToDelete: randomSelectorId});
    }

    onAcceptDeleteRow() {
        this.restPubSub.delete('random-selector', this.state.randomSelectorIdToDelete);
        this.restPubSub.refresh('league', this.props.league.id, 2000);
        this.setState({updating: true, randomSelectorIdToDelete: null});
    }

    onCancelDeleteRow() {
        this.setState({randomSelectorIdToDelete: null});
    }

    showSelectResultModal(randomSelectorId) {
        this.setState({showSelectResultModal: true, selectedId: randomSelectorId});
    }

    onAcceptResultModal(result) {
        this.restPubSub.refresh('league', this.props.league.id, 500);
        this.setState({showSelectResultModal: false, selectedId: null});
    }

    onCancelResultModal() {
        this.setState({showSelectResultModal: false, selectedId: null});
    }

    showViewResultModal(randomSelectorId) {
        this.setState({showViewResultModal: true, selectedId: randomSelectorId});
    }

    onCancelViewModal() {
        this.setState({showViewResultModal: false, selectedId: null});
    }

    render() {

        const stateOptions = [
            <option key='posted' value='posted'>Visible to All</option>,
            <option key='draft' value='draft'>Managers Only</option>
        ];

        const eventOptions = [
            <option key={0} value={0}>No Minor Event</option>
        ];
        for (const eventSummary of this.props.league.event_summary) {
            const option = <option key={eventSummary.id} value={eventSummary.id}>{eventSummary.name}</option>
            eventOptions.push(option);
        }

        const tournamentOptions = [
            <option key={0} value={0}>No Tournament</option>
        ];
        for (const tournamentSummary of this.props.league.tournament_summary) {
            const option = <option key={tournamentSummary.id} value={tournamentSummary.id}>{tournamentSummary.name}</option>
            tournamentOptions.push(option);
        }

        const rows = [];
        for (const randomSelectorId of this.props.league.random_selector_ids) {
            const randomSelector = this.state.randomSelectors[randomSelectorId];
            if (!randomSelector) continue;

            const row =
                <div key={randomSelectorId}>
                    <Row>
                        <Col>
                            {randomSelector.result === null &&
                            <span><b>Random Selection</b> <small className='text-muted'>(incomplete)</small></span>}
                            {randomSelector.result !== null &&
                            <span><b>Random Selection</b> <small className='text-muted'>(complete)</small></span>}
                        </Col>
                        <Col className='px-0' xs='auto'>
                            <NerdHerderToolTipButton size='sm' variant='primary' disabled={this.state.updating || randomSelector.name.length < 1 || randomSelector.modified !== true} onClick={()=>this.onUpdateRandomSelector(randomSelectorId)} icon='flaticon-diskette' tooltipText='save'/>
                        </Col>
                        {randomSelector.result === null &&
                        <Col className='px-0 ps-1' xs='auto'>
                            <NerdHerderToolTipButton size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.showSelectResultModal(randomSelectorId)} icon='flaticon-circular-arrows' tooltipText='make random selection'/>
                        </Col>}
                        {randomSelector.result !== null &&
                        <Col className='px-0 ps-1' xs='auto'>
                            <NerdHerderToolTipButton size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.showViewResultModal(randomSelectorId)} icon='flaticon-search' tooltipText='view selection'/>
                        </Col>}
                        <Col className='ps-1' xs='auto'>
                            <NerdHerderToolTipButton size='sm' variant='danger' disabled={this.state.updating} onClick={()=>this.onDeleteRow(randomSelectorId)} icon='flaticon-recycle-bin-filled-tool' tooltipText='delete'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-1'>
                            <Form.Control size='sm' type="text" placeholder='Random Selection Title' disabled={this.state.updating} onChange={(e)=>this.handleNameChange(randomSelectorId, e)} autoComplete='off' value={randomSelector.name} minLength={1} maxLength={40} required/>
                            {randomSelector.name.length < 1 &&
                            <Form.Text className='text-danger'>This name is not long enough.</Form.Text>}
                        </Col>
                    </Row>
                    <Row className='my-1'>
                        <Col>
                            <div style={{position: 'relative'}}>
                                <Form.Control size='sm' type="text" as="textarea" rows={2} placeholder="(Optional) Description of what this random selection is for (e.g. draft order, goes first, wins something)" disabled={this.state.updating} onChange={(e)=>this.handleDescriptionChange(randomSelectorId, e)} autoComplete='off' value={randomSelector.description || ''} maxLength={200} required/>
                                <FormTextInputLimit current={randomSelector.description ? randomSelector.description.length : 0} max={200}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className='my-1'>
                        <Col sm={4}>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.handleEventChange(randomSelectorId, e)} value={randomSelector.event_id || 0} required>
                                {eventOptions}
                            </Form.Select>
                        </Col>
                        <Col sm={4}>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.handleTournamentChange(randomSelectorId, e)} value={randomSelector.tournament_id || 0} required>
                                {tournamentOptions}
                            </Form.Select>
                        </Col>
                        <Col sm={4}>
                            <Form.Select size='sm' disabled={this.state.updating} onChange={(e)=>this.handleStateChange(randomSelectorId, e)} value={randomSelector.state} required>
                                {stateOptions}
                            </Form.Select>
                        </Col>
                    </Row>
                    <hr/>
                </div>
            rows.push(row);
        }

        return(
            <NerdHerderStandardCardTemplate id="manage-random-selectors-card" title='Randomizer!' titleIcon='shuffle.png'>
                {this.state.randomSelectorIdToDelete !== null &&
                <NerdHerderConfirmModal title='Delete Random Value?' message={`Are you sure you want to delete ${this.state.randomSelectors[this.state.randomSelectorIdToDelete].name}? The result (if set) will be lost. This cannot be undone.`}
                    acceptButtonText='Delete' onAccept={()=>this.onAcceptDeleteRow()} onCancel={()=>this.onCancelDeleteRow()} localUser={this.props.localUser}/>}
                {this.state.showSelectResultModal &&
                <NerdHerderRandomSelectorResultModal randomSelectorId={this.state.selectedId}
                                                     league={this.props.league}
                                                     eventId={this.state.randomSelectors[this.state.selectedId].event_id}
                                                     tournamentId={this.state.randomSelectors[this.state.selectedId].tournament_id}
                                                     onCancel={()=>this.onCancelResultModal()}
                                                     onAccept={(r)=>this.onAcceptResultModal(r)}
                                                     localUser={this.props.localUser}/>}
                {this.state.showViewResultModal &&
                <NerdHerderViewRandomSelectorViewResultModal result={this.state.randomSelectors[this.state.selectedId].result}
                                                             title={this.state.randomSelectors[this.state.selectedId].name}
                                                             onCancel={()=>this.onCancelViewModal()}
                                                             localUser={this.props.localUser}/>}
                <small className='text-muted'>If your league, event, minor-events, or tournaments need some kind of random selection, you can do that here. The result can be for managers only or visible to everyone. You can create as many random selections as you need.</small>
                <hr/>
                <Form>
                    {rows.length !== 0 &&
                    <Form.Group className="mb-2">
                        {rows}
                    </Form.Group>}
                    <div>
                        {rows.length === 0 &&
                        <small className='text-muted'>This {this.props.league.getTypeWord()} has no random selections. Click the + to add one!  </small>}
                        <Button className='float-end' size='sm' variant='primary' disabled={this.state.updating} onClick={()=>this.onAddRow()}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                    </div>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(ManageLeaguePage);
