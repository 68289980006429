import React from 'react';
import { DateTime } from 'luxon';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Placeholder from 'react-bootstrap/Placeholder'
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import pluralize from 'pluralize';
import { InView } from 'react-intersection-observer';
import { LinkifyText } from './NerdHerderFormHelpers';
import { Truncate } from './NerdHerderTruncate';
import { NerdHerderFontIcon, NerdHerderMapIcon, NerdHerderFavoriteIcon } from './NerdHerderFontIcon';
import { NerdHerderToolTipButton } from './NerdHerderToolTip';
import { NerdHerderBadge, NerdHerderBadgeInjector, NerdHerderTopicBadge } from './NerdHerderBadge';
import { getCookieParseBool, getStaticStorageImageFilePublicUrl, getStorageFilePublicUrl, generateDateString, getFileDownloadTarget, getFileUiIconUrl, capitalizeFirstLetter } from '../utilities';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderJoinModelRestApi } from '../NerdHerder-JoinModelRestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderUserProfileModal, NerdHerderEditGameModal, NerdHerderVenueModal, NerdHerderEditEventModal, NerdHerderEditCalendarDateModal } from './NerdHerderModals';
import { NerdHerderNavigate } from './NerdHerderNavigate';
import { ListItemErrorBoundary } from './NerdHerderErrorBoundary';
import { LongshanksId } from './NerdHerderLongshanks';

class NerdHerderListItemContainer extends React.Component {

    render() {
        let listGroupItemActionClass = 'list-group-item-action cursor-pointer';
        if (this.props.onClick === null) {
            listGroupItemActionClass = '';
        }

        let borderSelectedClass = '';
        if (this.props.border === 'primary' || this.props.border === 'selected') {
            borderSelectedClass = 'border-selected-primary';
        } else if (this.props.border === 'warning') {
            borderSelectedClass = 'border-selected-warning';
        } else if (this.props.border === 'danger') {
            borderSelectedClass = 'border-selected-danger';
        }

        return (
            <div className={`list-group-item ${listGroupItemActionClass} rounded align-middle ${borderSelectedClass}`} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        );
    }
}

export class LeagueListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inView: false,
        }
    }

    onChangeView(inView, entry) {
        if (inView && this.state.inView === false) {
            this.setState({inView: true});
        }
    }

    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='LeagueListItem' listItemId={this.props.leagueId || this.props.league.id || null}>
                <InView onChange={(inView, entry)=>{this.onChangeView(inView, entry)}}>
                    {!this.state.inView &&
                    <PlaceholderLeagueListItem {...this.props}/>}
                    {this.state.inView &&
                    <LeagueListItemInner {...this.props}/>}
                </InView>
            </ListItemErrorBoundary>
        )
    }
}

class PlaceholderLeagueListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1 cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-1 my-0">
                                <Placeholder as="div" animation="glow" style={{width: '60px', height: '60px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                </Placeholder>
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class LeagueListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.leagueId === 'undefined' && typeof this.props.league === 'undefined') console.error('missing props.leagueId or props.league');

        let leagueId = null;
        if (typeof this.props.league !== 'undefined') {
            leagueId = this.props.league.id;
        } else {
            leagueId = this.props.leagueId;
        }

        this.state = {
            navigateTo: null,
            leagueId: leagueId,
            league: null,
            deleted: false,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('league-summary', this.state.leagueId, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeague(leagueData, key) {
        if (leagueData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({leagueId: newLeague.id, league: newLeague});
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            this.setState({navigateTo: `/app/league/${this.state.leagueId}`});
        } else if (this.props.onClick === 'manage') {
            this.setState({navigateTo: `/app/manageleague/${this.state.leagueId}`});
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    onClickManage(event) {
        event.stopPropagation();
        this.setState({navigateTo: `/app/manageleague/${this.state.leagueId}`});
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.league === null) return(<PlaceholderLeagueListItem/>);

        const leagueData = this.state.league;
        let isManager = false;
        if (this.props.localUser) isManager = leagueData.isManager(this.props.localUser.id);
        const numPlayers = leagueData.player_ids.length;

        let showMaxPlayers = false;
        let showFull = false;
        if (leagueData.max_players !== null) {
            showMaxPlayers = true;
            if (numPlayers >= leagueData.max_players) {
                showFull = true;
            }
        }

        // if the image is not set, it will be kraken.png - get it from static storage
        let imageHref = leagueData.image;
        if (imageHref === 'kraken.png') {
            imageHref = getStaticStorageImageFilePublicUrl(imageHref);
        } else if (imageHref === '/static/kraken.png') {
            imageHref = getStorageFilePublicUrl(imageHref);
        }

        // we adjust the border based on props
        let border = null;
        if (this.props.selected === true) {
            border = 'primary';
        } else if (this.props.border) {
            border = this.props.border;
        }

        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderNavigate to={this.state.navigateTo}/>
                    <NerdHerderListItemContainer border={border} onClick={this.props.onClick===null ? null : ()=>this.onClick()}>
                        <Row>
                            <Col xs="auto" className="text-center">
                                <Image className="rounded rounded-2 text-center" height={60} width={60} src={imageHref}/><br/>
                                <NerdHerderTopicBadge topicId={leagueData.topic_id} topicData={leagueData.topic}/>
                                {!showMaxPlayers && <div className="m-0 p-0"><small><small className="text-muted">{ numPlayers } players</small></small></div>}
                                {showMaxPlayers &&  <div className="m-0 p-0"><small><small className="text-muted">{ numPlayers }/{ leagueData.max_players } filled</small></small></div>}
                            </Col>
                            <Col>
                                <b>{ leagueData.name }</b><br/>
                                <small>{leagueData.getTypeWordCaps()}: {leagueData.getStatusString()}</small><br/>
                                <small>{leagueData.getScheduleString()}</small><br/>
                                {showMaxPlayers && <small>{ leagueData.max_players } player limit</small>}
                                {showFull && <small className="text-danger"> <b>(full)</b></small>}
                                {this.props.topMessage && 
                                <div>
                                    {this.props.topMessage}
                                </div>}
                            </Col>
                            {isManager && this.props.onClick !== 'manage' && !this.props.noManageIcon &&
                            <Col xs='auto'>
                                <Button variant='danger' size='sm' onClick={(e)=>this.onClickManage(e)}><NerdHerderFontIcon icon='flaticon-configuration-with-gear'/></Button>
                            </Col>}
                        </Row>
                        {this.props.showSummary &&
                        <Row>
                            <Col xs={12}>
                                <small>{ this.props.league.summary }</small>
                            </Col>
                        </Row>}
                        {this.props.message &&
                        <Row>
                            <Col xs={12}>
                                {this.props.message}
                            </Col>
                        </Row>}
                        {this.props.children}
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        )
    }
}

export class AvailableLeagueListItem extends React.Component {
    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='AvailableLeagueListItem'>
                <AvailableLeagueListItemInner {...this.props}/>
            </ListItemErrorBoundary>
        )
    }
}

class AvailableLeagueListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.league === 'undefined') console.error('missing props.onCancel');
        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        
        this.state = {
            navigateTo: null,
            showVenueModal: false,
            venueModalId: null,
        }
    }

    onClickVenue(modalId, event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({showVenueModal: true, venueModalId: modalId});
    }

    onCancel() {
        this.setState({showVenueModal: false, venueModalId: null});
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            this.setState({navigateTo: `/app/league/${this.props.league.id}`});
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    render() {
        if (this.state.deleted) return (null);

        const leagueData = this.props.league;
        const fakeLeagueData = NerdHerderDataModelFactory('league', leagueData);

        // if the image is not set, it will be kraken.png - get it from static storage
        let imageHref = leagueData.image;
        if (imageHref === 'kraken.png') {
            imageHref = getStaticStorageImageFilePublicUrl(imageHref);
        } else if (imageHref === '/static/kraken.png') {
            imageHref = getStorageFilePublicUrl(imageHref);
        }

        let resolvedVenueName = leagueData.resolved_venue_name;
        if (resolvedVenueName && leagueData.venue_id) {
            resolvedVenueName = <span><span className='btn-link' onClick={(e)=>this.onClickVenue(leagueData.venue_id, e)}>{resolvedVenueName}</span> at </span>
        }

        let venueIconHtml = null;
        if (!leagueData.online) {
            venueIconHtml = <NerdHerderMapIcon location={leagueData.resolved_venue}/>
        }

        let rangeOrOnline = leagueData.range;
        if (leagueData.online) rangeOrOnline = 'Online';

        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderNavigate to={this.state.navigateTo}/>
                    {this.state.showVenueModal &&
                    <NerdHerderVenueModal venueId={this.state.venueModalId} localUser={this.props.localUser} onCancel={()=>this.onCancel()}/>}
                    <div className="list-group-item list-group-item-action rounded align-middle cursor-pointer" onClick={()=>this.onClick()}>
                        <Row>
                            <Col xs="auto" className="text-center">
                                <Image className="rounded rounded-2 text-center" height={60} width={60} src={imageHref}/><br/>
                                <NerdHerderTopicBadge topicId={leagueData.topic_id} topicData={leagueData.topic}/>
                                <div><small><b>{rangeOrOnline}</b></small></div>
                            </Col>
                            <Col>
                                <b>{leagueData.name}</b><br/>
                                <small>{fakeLeagueData.getTypeWordCaps()}: {fakeLeagueData.getStatusString()}</small><br/>
                                <small>{fakeLeagueData.getScheduleString()}</small><br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='my-0'/>
                                <Truncate>
                                    {leagueData.summary}
                                </Truncate>
                                <hr className='my-0'/>
                                <small><b>Venue: </b>{resolvedVenueName}{leagueData.resolved_venue}{venueIconHtml}</small>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

export class EventListItem extends React.Component {
    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='EventListItem'>
                <EventListItemInner {...this.props}/>
            </ListItemErrorBoundary>
        )
    }
}

class PlaceholderEventListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1  cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-1 my-0">
                                {this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '25px', height: '25px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                </Placeholder>}
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '50px', height: '50px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                </Placeholder>}
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class EventListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.eventId === 'undefined' && typeof this.props.event === 'undefined') console.error('missing props.eventId or props.event');

        // grab the event directly from props, or if given an ID look it up
        let eventId = null;
        if (typeof this.props.event !== 'undefined') {
            eventId = this.props.event.id;
        } else {
            eventId = this.props.eventId;
        }

        // grab the league directly from props if possible
        let leagueData = null;
        if (typeof this.props.league !== 'undefined') {
            leagueData = this.props.league;
        }

        this.state = {
            navigateTo: null,
            eventId: eventId,
            event: null,
            league: leagueData,
            message: null,
            showEditEventModal: false,
            deleted: false,
        }

        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('events', this.state.eventId, (d, k) => {this.updateEvent(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateEvent(eventData, key) {
        if (eventData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState({eventId: eventData.id, event: newEvent});

        let sub = this.restPubSub.subscribeToFirestore('league-summary', newEvent.league_id, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);

        if (newEvent.top_post_id !== null) {
            sub = this.restPubSub.subscribe('message', newEvent.top_post_id, (d, k) => {this.updateMessage(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague});
    }

    updateMessage(messageData, key) {
        const newMessage = NerdHerderDataModelFactory('message', messageData);
        this.setState({message: newMessage});
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            this.setState({navigateTo: `/app/event/${this.state.eventId}`});
        } else if (this.props.onClick === 'manage') {
            this.setState({showEditEventModal: true});
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    onClickManage(event) {
        event.stopPropagation();
        this.setState({showEditEventModal: true});
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.event === null || this.state.league === null) return(<PlaceholderEventListItem/>);

        const eventData = this.state.event;
        const leagueData = this.state.league;
        let isManager = false;
        if (this.props.localUser) isManager = leagueData.isManager(this.props.localUser.id);

        // if the image is not set, it will be kraken.png - get it from static storage
        let imageHref = leagueData.image;
        if (imageHref === 'kraken.png') {
            imageHref = getStaticStorageImageFilePublicUrl(imageHref);
        } else if (imageHref === '/static/kraken.png') {
            imageHref = getStorageFilePublicUrl(imageHref);
        }

        let schedule = eventData.getScheduleString();

        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderNavigate to={this.state.navigateTo}/>
                    {this.state.showEditEventModal &&
                    <NerdHerderEditEventModal eventId={this.state.eventId} onCancel={()=>this.setState({showEditEventModal: false})} localUser={this.props.localUser}/>}
                    <NerdHerderListItemContainer onClick={this.props.onClick===null ? null : ()=>this.onClick()}>
                        <Row>
                            <Col xs="auto" className="text-center">
                                <NerdHerderBadgeInjector badge={<NerdHerderBadge color='yellow' shape='round' size="small" position='bottom' value='Minor Event'/>}>
                                    <Image className="rounded rounded-2 text-center" height={50} width={50} src={imageHref}/><br/>
                                </NerdHerderBadgeInjector>
                            </Col>
                            <Col>
                                <div><b>{eventData.name}</b></div>
                                {schedule &&
                                <div><small className='text-muted'>{schedule}</small></div>}
                                {this.props.showSummary &&
                                <div>{ this.state.event.summary }</div>}
                            </Col>
                            {isManager && this.props.onClick !== 'manage' && !this.props.noManageIcon &&
                            <Col xs='auto'>
                                <Button variant='danger' size='sm' onClick={(e)=>this.onClickManage(e)}><NerdHerderFontIcon icon='flaticon-configuration-with-gear'/></Button>
                            </Col>}
                        </Row>
                        
                        {this.props.showTopPost && this.state.message &&
                        <Row className="mt-2">
                            <Col xs={12}>
                                <Truncate>
                                    {this.state.message.text}
                                </Truncate>
                            </Col>
                        </Row>}
                        {this.props.children}
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        )
    }
}

export class UserListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inView: false,
        }
    }

    onChangeView(inView, entry) {
        if (inView && this.state.inView === false) {
            this.setState({inView: true});
        }
    }

    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='UserListItem' listItemId={this.props.userId || this.props.user.id || null}>
                <InView onChange={(inView, entry)=>{this.onChangeView(inView, entry)}}>
                    {!this.state.inView &&
                    <PlaceholderUserListItem {...this.props}/>}
                    {this.state.inView &&
                    <UserListItemInner {...this.props}/>}
                </InView>
            </ListItemErrorBoundary>
        )
    }
}

class PlaceholderUserListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1 cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-1 py-0 my-0">
                                {this.props.slim &&
                                <Spinner variant="primary" animation="grow" size="sm" role="status" aria-hidden="true" style={{width: '25px', height: '25px'}}/>}
                                {!this.props.slim &&
                                <Spinner variant="primary" animation="grow" size="sm" role="status" aria-hidden="true" style={{width: '50px', height: '50px'}}/>}
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class UserListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.userId === 'undefined' && typeof this.props.user === 'undefined') console.error('missing props.userId or props.user');

        let userId = null;
        if (typeof this.props.user !== 'undefined') {
            userId = this.props.user.id;
        } else {
            userId = this.props.userId;
        }

        this.state = {
            navigateTo: null,
            userId: userId,
            user: null,
            showUserProfileModal: false,
            deleted: false,
        }

        // look for the special 'hide sensitive data' cookie, if its there we'll hide email and phone
        this.hideSensitiveData = getCookieParseBool('hide-sensitive-data', false);
        if (this.hideSensitiveData !== true) this.hideSensitiveData = false;

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('users', this.state.userId, (d, k) => {this.updateUser(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateUser(userData, key) {
        if (userData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        console.debug('UserListItem - updateUser()')
        const newUser = NerdHerderDataModelFactory('user', userData);
        newUser.mergeContactInfo(this.props.localUser);
        this.setState({userId: newUser.id, user: newUser});
    }

    onCancelModal() {
        this.setState({showUserProfileModal: false});
        this.setState({showUserMessageModal: false});
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            this.setState({showUserProfileModal: true});
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.user === null) return(<PlaceholderUserListItem slim={this.props.slim}/>);

        const userData = this.state.user;

        // if the image is not set, it will be meeple.png - get it from static storage
        let imageHref = userData.profile_image;
        if (imageHref === 'meeple.png') {
            imageHref = getStaticStorageImageFilePublicUrl(imageHref);
        } else if (imageHref === '/static/meeple.png') {
            imageHref = getStorageFilePublicUrl(imageHref);
        }

        let border = null;
        if (this.props.showSelected) {
            border = 'primary';
        }

        let contactBadge = null;
        const contactState = this.props.localUser.getContactState(this.state.userId);
        if (contactState) {
            if (this.props.slim) {
                if (contactState === 'friends') {
                    const fontIcon = <NerdHerderFontIcon icon='flaticon-invisible-person-of-clothes'></NerdHerderFontIcon>
                    contactBadge = <NerdHerderBadge size='tiny' color='blue' shape='round' position='bottom-right' value={fontIcon}/>
                }
            } else {
                if (this.props.detailedContactBadge) {
                    switch(contactState) {
                        case 'friends':
                            contactBadge = <NerdHerderBadge color='blue' shape='round' position='bottom' value='Contact'/>
                            break;
        
                        case 'pending':
                            contactBadge = <NerdHerderBadge color='yellow' shape='round' position='bottom' value='Sent'/>
                            break;
                        
                        case 'requested':
                            contactBadge = <NerdHerderBadge color='yellow' shape='round' position='bottom' value='Requested'/>
                            break;
        
                        default:
                            // no badge
                    }
                } else {
                    // this is the normal badge
                    if (contactState === 'friends') {
                        contactBadge = <NerdHerderBadge color='blue' shape='round' position='bottom' value='Contact'/>
                    }
                }
            }
        }

        let joinedDateStamp = 'Not set';
        if (userData.created !== null) {
            joinedDateStamp = new Date(userData.created);
            joinedDateStamp = generateDateString(joinedDateStamp);
        }
        let loginDateStamp = 'Never logged in';
        if (userData.last_login !== null) {
            loginDateStamp = new Date(userData.last_login);
            loginDateStamp = generateDateString(loginDateStamp);
        }

        let extraIdJsx = null;
        if (this.props.extraId) {
            switch(this.props.extraId) {
                case 'wizards':
                    extraIdJsx = <span><small className="text-muted">{`Event Link ID: ${userData.wizards_id || 'Not set'}`}</small></span>;
                    break;
                case 'pokemon':
                    extraIdJsx = <span><small className="text-muted">{`Pokemon Trainer ID: ${userData.pokemon_id || 'Not set'}`}</small></span>;
                    break;
                case 'bandai':
                    extraIdJsx = <span><small className="text-muted">{`Bandai TGC+ ID: ${userData.bandai_id || 'Not set'}`}</small></span>;
                    break;
                case 'konami':
                    extraIdJsx = <span><small className="text-muted">{`Konami ID: ${userData.konami_id || 'Not set'}`}</small></span>;
                    break;
                case 'longshanks':
                    extraIdJsx = <LongshanksId id={userData.longshanks_id} noLink={true}/>
                    break;
                default:
            }
        }

        if (this.props.slim) {
            return (
                <Row className="my-1 align-items-center">
                    <Col xs={12}>
                        <NerdHerderNavigate to={this.state.navigateTo}/>
                        {this.state.showUserProfileModal &&
                        <NerdHerderUserProfileModal userId={this.state.userId} localUser={this.props.localUser} onCancel={()=>this.onCancelModal()}/>}
                        <NerdHerderListItemContainer border={border} onClick={()=>this.onClick()}>
                            <Row>
                                <Col xs="auto" className="text-center px-1 py-0 my-0">
                                    <NerdHerderBadgeInjector badge={contactBadge}>
                                        <Image className="rounded-circle text-center" height={25} width={25} src={imageHref} alt='user profile image'/>
                                    </NerdHerderBadgeInjector>
                                </Col>
                                <Col>
                                    <div>
                                        <b>{ userData.username }</b> {userData.short_name && <small>({userData.short_name})</small>}
                                    </div>
                                    {this.props.extraId && extraIdJsx &&
                                    <div>
                                        {extraIdJsx}
                                    </div>}
                                    {!this.props.extraId && userData.discord_id &&
                                    <div>
                                        <small className="text-muted">Discord: {userData.discord_id}</small>
                                    </div>}
                                </Col>
                            </Row>
                            {this.props.children}
                        </NerdHerderListItemContainer>
                    </Col>
                </Row>
            )
        }

        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderNavigate to={this.state.navigateTo}/>
                    {this.state.showUserProfileModal &&
                    <NerdHerderUserProfileModal userId={this.state.userId} localUser={this.props.localUser} onCancel={()=>this.onCancelModal()}/>}
                    <NerdHerderListItemContainer border={border} onClick={()=>this.onClick()}>
                        <Row>
                            <Col xs="auto" className="text-center px-1">
                                <NerdHerderBadgeInjector badge={contactBadge}>
                                    <Image className="rounded-circle text-center" height={50} width={50} src={imageHref} alt='user profile image'/>
                                </NerdHerderBadgeInjector>
                            </Col>
                            <Col>
                                <div>
                                    <b>{ userData.username }</b> {userData.short_name && <small>({userData.short_name})</small>}
                                </div>
                                {this.props.extraId && extraIdJsx &&
                                    <div>
                                        {extraIdJsx}
                                    </div>}
                                {userData.discord_id && !this.props.extraId &&
                                <div>
                                    <small className="text-muted">Discord: {userData.discord_id}</small>
                                </div>}
                                {this.props.showContact && userData.phone && !this.hideSensitiveData &&
                                <div>
                                    <small className="text-muted">Phone: {userData.phone}</small>
                                </div>}
                                {this.props.showDates &&
                                <div>
                                    <small className="text-muted">Joined: {joinedDateStamp}</small><br/>
                                    <small className="text-muted">Last Login: {loginDateStamp}</small>
                                </div>}
                                <small className="text-muted">{userData.country}</small>
                            </Col>
                            {this.props.children &&
                            <Row>
                                <Col xs={12}>
                                    {this.props.children}
                                </Col>
                            </Row>}
                        </Row>
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        )
    }
}

export class FileListItem extends React.Component {
    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='FileListItem'>
                <FileListItemInner {...this.props}/>
            </ListItemErrorBoundary>
        )
    }
}

class FileListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.fileId === 'undefined' && typeof this.props.file === 'undefined') console.error('missing props.fileId or props.file');

        let fileData = null;
        let fileId = null;
        if (typeof this.props.file !== 'undefined') {
            fileData = this.props.file;
            fileId = fileData.id;
        } else {
            fileId = this.props.fileId;
        }

        this.state = {
            fileId: fileId,
            file: fileData,
            deleted: false,
        }

        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        if (this.state.file === null) {
            let sub = this.restPubSub.subscribe('file', this.state.fileId, (d, k) => {this.updateFile(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateFile(fileData, key) {
        if (fileData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newFile = NerdHerderDataModelFactory('file', fileData);
        this.setState({fileId: newFile.id, file: newFile});
    }

    render() {
        if (this.state.file === null) return(null);
        if (this.state.deleted) return (null);
        
        let description = <p className='mb-1'>{this.state.file.description}</p>
        let datestamp = new Date(this.state.file.date);
        let href = getStorageFilePublicUrl(`/${this.state.file.path}`);
        let targetOption = getFileDownloadTarget(this.state.file.path);
        let iconUrl = getFileUiIconUrl(this.state.file.path);

        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <a className="list-group-item list-group-item-action rounded align-middle" target={targetOption} href={href} download={this.state.file.filename}>
                        <Row>
                            <div className="col-auto text-center">
                                <img src={iconUrl} alt="file icon" width="40"/><br/>
                            </div>
                            <div className="col">
                                <b>{this.state.file.filename}</b><br/>
                                <small>{description}</small>
                            </div>
                            <small><small className="text-muted">Uploaded by {this.state.file.uploaded_by} on {generateDateString(datestamp)}</small></small>
                        </Row>
                    </a>
                    {this.props.children}
                </Col>
            </Row>
        )
    }
}

export class TournamentListItem extends React.Component {
    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='TournamentListItem'>
                <TournamentListItemInner {...this.props}/>
            </ListItemErrorBoundary>
        )
    }
}

class PlaceholderTournamentListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1 cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-2 my-0">
                                {this.props.slim &&
                                <Placeholder as="div" animation="glow">
                                    <Placeholder.Button bg='primary' xs={12} size='xs' style={{width: '25px', height: '25px'}}/>
                                </Placeholder>}
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '50px', height: '50px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                    <Placeholder bg='primary' xs={12} size='sm'/>
                                </Placeholder>}
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>}
                            </Col>
                        </Row>
                        {!this.props.slim && 
                        <Row className='mt-2'>
                            <Col xs={12}>
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={12}/>
                                </Placeholder>
                            </Col>
                        </Row>}
                    </div>
                </Col>
            </Row>
        )
    }
}

class TournamentListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.tournamentId === 'undefined' && typeof this.props.tournament === 'undefined') console.error('missing props.eventId or props.event');

        // grab the event directly from props, or if given an ID look it up
        let tournamentId = null;
        if (typeof this.props.tournament !== 'undefined') {
            tournamentId = this.props.tournament.id;
        } else {
            tournamentId = this.props.tournamentId;
        }

        this.state = {
            navigateTo: null,
            tournamentId: tournamentId,
            tournament: null,
            league: null,
            winningPlayer: null,
            event: null,
            showSlimExpandButton:  false,
            slim: this.props.slim || false,
            deleted: false,
        }

        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('tournaments', this.state.tournamentId, (d, k) => {this.updateTournament(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, key) {
        if (tournamentData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState({tournamentId: tournamentData.id, tournament: newTournament});
        let sub = this.restPubSub.subscribeToFirestore('leagues', tournamentData.league_id, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
        if (tournamentData.event_id !== null) {
            sub = this.restPubSub.subscribeToFirestore('events', tournamentData.event_id, (d, k) => {this.updateEvent(d, k)});
            this.restPubSubPool.add(sub);
        }
        if (tournamentData.place_1_user_id !== null) {
            let sub = this.restPubSub.subscribeToFirestore('user-summary', tournamentData.place_1_user_id, (d, k) => {this.updateWinningUser(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague});
    }

    updateEvent(eventData, key) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: newEvent});
    }

    updateWinningUser(userData, key) {
        if (key === this.state.tournament.place_1_user_id) {
            const newUser = NerdHerderDataModelFactory('user', userData);
            this.setState({winningPlayer: newUser});
        }
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            this.setState({navigateTo: `/app/tournament/${this.state.tournamentId}`});
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.tournament === null || this.state.league === null) return(<PlaceholderTournamentListItem {...this.props}/>);

        const tournamentData = this.state.tournament;
        const imageHref = tournamentData.getImageUrl();
        const schedule = tournamentData.getScheduleString();
        const tournamentShortStatus = tournamentData.getShortStatusString();
        let tournamentRoundStatus = tournamentData.getRoundStatusDescription();
        const tournamentTypeDescription = tournamentData.getTypeDescription();

        let roundsSummary = null;
        let numPlayers = tournamentData.player_ids.length;

        let numRounds = tournamentData.num_rounds;
        if (tournamentData.rounds.length > numRounds) numRounds = tournamentData.rounds.length;
        if (numRounds > 1) roundsSummary = <small>{numRounds} rounds ({numPlayers} players)</small>
        else roundsSummary = <small>{numPlayers} players</small>

        if (tournamentData.state === 'complete' && this.state.winningPlayer !== null) {
            const winner = this.state.winningPlayer;
            const winnerImageUrl = winner.getImageUrl()
            roundsSummary = <div><Image className="rounded-circle" height={15} width={15} src={winnerImageUrl}/> {winner.username} won!</div>
        }

        if (tournamentData.state === 'complete') {
            tournamentRoundStatus = <small className='text-danger'><b>{tournamentRoundStatus}</b></small>
        } else if (tournamentData.state === 'in-progress') {
            tournamentRoundStatus = <small className='text-warning'><b>{tournamentRoundStatus}</b></small>
        } else {
            tournamentRoundStatus = <small className='text-primary'><b>{tournamentRoundStatus}</b></small>
        }

        if (this.state.slim) {
            return(
                <Row className="my-1 align-items-center">
                    <Col xs={12}>
                        <NerdHerderNavigate to={this.state.navigateTo}/>
                        <NerdHerderListItemContainer onClick={this.props.onClick===null ? null : ()=>this.onClick()}>
                            <Row>
                                <Col xs="auto" className="text-center">
                                        <Image className="rounded rounded-2 text-center" height={25} width={25} src={imageHref}/><br/>
                                </Col>
                                <Col>
                                    <div>
                                        <b>{tournamentData.name}</b>
                                        {schedule &&
                                        <small className='text-muted'> ({schedule})</small>}
                                    </div>
                                    <div>{tournamentRoundStatus}</div>
                                </Col>
                            </Row>
                        </NerdHerderListItemContainer>
                    </Col>
                </Row>
            );
        }
       
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderNavigate to={this.state.navigateTo}/>
                    <NerdHerderListItemContainer onClick={this.props.onClick===null ? null : ()=>this.onClick()}>
                        <Row>
                            <Col xs="auto" className="text-center">
                                    <Image className="rounded rounded-2 text-center" height={50} width={50} src={imageHref}/><br/>
                                    <NerdHerderBadge color='blue' shape='square' size="medium" value={tournamentShortStatus}/>
                            </Col>
                            <Col>
                                <div>
                                    <b>{tournamentData.name}</b>
                                    {schedule &&
                                    <small className='text-muted'> ({schedule})</small>}
                                </div>
                                <div><small>{tournamentTypeDescription}</small></div>
                                <div>{roundsSummary}</div>
                                <div>{tournamentRoundStatus}</div>
                            </Col>
                        </Row>
                        
                        {this.props.showSummary &&
                        <Row className="mt-2">
                            <Col xs={12}>
                                <Truncate>
                                    {this.state.tournament.summary}
                                </Truncate>
                            </Col>
                        </Row>}
                        {this.state.event &&
                        <small><small className="text-muted">Event: {this.state.event.name}</small></small>}
                        {this.props.children}
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        );
    }
}

export class GameListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inView: false,
        }
    }

    onChangeView(inView, entry) {
        if (inView && this.state.inView === false) {
            this.setState({inView: true});
        }
    }

    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='GameListItem' listItemId={this.props.gameId || this.props.game.id || null}>
                <InView onChange={(inView, entry)=>{this.onChangeView(inView, entry)}}>
                    {!this.state.inView &&
                    <PlaceholderGameListItem/>}
                    {this.state.inView &&
                    <GameListItemInner {...this.props}/>}
                </InView>
            </ListItemErrorBoundary>
        )
    }
}

export class PlaceholderGameListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1 cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-2 my-0">
                                {this.props.slim &&
                                <Placeholder as="div" animation="glow">
                                    <Placeholder.Button bg='primary' xs={12} size='xs' style={{width: '25px', height: '25px'}}/>
                                </Placeholder>}
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '50px', height: '50px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                    <Placeholder bg='primary' xs={12} size='sm'/>
                                </Placeholder>}
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class GameListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.gameId === 'undefined' && typeof this.props.game === 'undefined') console.error('missing props.gameId or props.game');

        let gameId = null;
        if (typeof this.props.game !== 'undefined') {
            gameId = this.props.game.id;
        } else {
            gameId = this.props.gameId;
        }

        // grab the league directly from props if possible
        let leagueData = null;
        this.factionDict = {};
        if (typeof this.props.league !== 'undefined') {
            leagueData = this.props.league;
            this.factionDict = leagueData.topic.getFactionDict();
        }

        // grab the tournament directly from props if possible
        let tournamentData = null;
        if (typeof this.props.tournament !== 'undefined') {
            tournamentData = this.props.tournament;
        }

        // grab the event directly from props if possible
        let eventData = null;
        if (typeof this.props.event !== 'undefined') {
            eventData = this.props.event;
        }

        this.state = {
            gameId: gameId,
            game: null,
            isTournamentGame: false,
            tournament: tournamentData,
            isEventGame: false,
            event: eventData,
            league: leagueData,
            showEditGameModal: false,
            showDetails: false,
            disableVerifyButton: false,
            disableScheduleButton: false,
            deleted: false,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('games', this.state.gameId, (d, k) => {this.updateGame(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateGame(gameData, key) {
        if (gameData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newGame = NerdHerderDataModelFactory('game', gameData);
        let isTournamentGame = false;
        let isEventGame = false;
        if (newGame.tournament_id !== null) {
            isTournamentGame = true;
        }
        if (newGame.event_id !== null) {
            isEventGame = true;
        }

        if (this.state.league === null) {
            let sub = this.restPubSub.subscribeToFirestore('leagues', newGame.league_id, (d, k) => {this.updateLeague(d, k)});
            this.restPubSubPool.add(sub);
        }

        if (isTournamentGame) {
            if (this.state.tournament === null && newGame.tournament_id !== null) {
                let sub = this.restPubSub.subscribeToFirestore('tournaments', newGame.tournament_id, (d, k) => {this.updateTournament(d, k)}, null, newGame.tournament_id);
                this.restPubSubPool.add(sub);
            // eslint-disable-next-line eqeqeq
            } else if (this.state.tournament !== null && this.state.tournament.id != newGame.tournament_id) {
                let sub = this.restPubSub.subscribeToFirestore('tournaments', newGame.tournament_id, (d, k) => {this.updateTournament(d, k)}, null, newGame.tournament_id);
                this.restPubSubPool.add(sub);
            }
        }
        if (this.state.tournament !== null && newGame.tournament_id === null) {
            this.setState({tournament: null});
        }

        if (isEventGame) {
            if (this.state.event === null && newGame.event_id !== null) {
                let sub = this.restPubSub.subscribeToFirestore('events', newGame.event_id, (d, k) => {this.updateEvent(d, k)}, null, newGame.event_id);
                this.restPubSubPool.add(sub);
            // eslint-disable-next-line eqeqeq
            } else if (this.state.event !== null && this.state.event.id != newGame.event_id) {
                let sub = this.restPubSub.subscribeToFirestore('events', newGame.event_id, (d, k) => {this.updateEvent(d, k)}, null, newGame.event_id);
                this.restPubSubPool.add(sub);
            }
        }
        if (this.state.event !== null && newGame.event_id === null) {
            this.setState({event: null});
        }
        this.setState({gameId: newGame.id, game: newGame, isTournamentGame: isTournamentGame, isEventGame: isEventGame});
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.factionDict = newLeague.topic.getFactionDict();
        this.setState({league: newLeague});
    }

    updateTournament(tournamentData, key) {
        const newTournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState({tournament: newTournament});
    }

    updateEvent(eventData, key) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: newEvent});
    }

    refreshDependentModels() {
        // the game might have been modified - update the league, event, and tournament if there is one
        this.restPubSub.refresh('league', this.state.league.id);
        if (this.state.event) this.restPubSub.refresh('event', this.state.event.id, 200);
        if (this.state.tournament) this.restPubSub.refresh('tournament', this.state.tournament.id, 200);
        this.restPubSub.refresh('header-alerts', null, 500);
        this.restPubSub.refresh('league-alerts', this.state.game.league_id, 500);
    }

    getTournamentRound() {
        if (this.state.tournament !== null) {
            for (const roundData of this.state.tournament.rounds) {
                if (roundData.id === this.state.game.round_id) {
                    return roundData;
                }
            }
        }

        return null;
    }

    userCanEditGame() {
        // until the game & league are loaded we can't make a determiniation...assume the worst!
        if (this.state.game === null || this.state.league === null) return false;

        let result = false;
        if (this.state.isTournamentGame && this.state.tournament !== null) {
            // managers of tournaments can basically always edit
            if (this.state.tournament.isManager(this.props.localUser.id)) {
                result = true;
            }
            // players of the tournament can edit tournament games, but only if they are also one of the players in the game
            else if (this.state.tournament.isPlayer(this.props.localUser.id) && this.state.game.player_ids.includes(this.props.localUser.id)) {
                if (this.state.game.isFullyVerified()) {
                    result = false;
                } else if (this.state.game.bye) {
                    result = false;
                } else {
                    result = true;
                }
            }
            // users not in the tournament can't edit
            else {
                result = false;
            }
        } else if (this.state.isEventGame && this.state.event !== null) {
            // managers of events can basically always edit
            if (this.state.event.isManager(this.props.localUser.id)) {
                result = true;
            }
            // players can also edit until fully verified
            else if (this.state.event.isPlayer(this.props.localUser.id) && this.state.game){
                result = true;
            }
            // if the user isn't a player or a manager of the event, they can't edit
            else {
                result = false;
            }
        } else {
            // managers of leagues can basically always edit
            if (this.state.league.isManager(this.props.localUser.id)) {
                result = true;
            } 
            // a player of a league can generally edit, but there are some limitations
            else if (this.state.league.isPlayer(this.props.localUser.id)) {
                // if the league isn't concurring on casual games, then any league player can edit
                if (!this.state.league.use_concur_reg_games) {
                    result = true;
                }
                // if the league is concurring on casual games, then players can edit until fully verified
                else if (this.state.game.isFullyVerified()) {
                    result = false;
                } else {
                    result = true;
                }
                
            }
            // if the user isn't a player or a manager of the league, they can't edit
            else {
                result = false;
            }
        }

        return result;
    }

    onCancelModal(result) {
        this.setState({showEditGameModal: false});

        // it is possible the game was deleted
        if (result === 'deleted') {
            this.setState({deleted: true});
        }

        this.refreshDependentModels();
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            let userCanEdit = this.userCanEditGame();
            // if the user can edit and the game isn't completed yet, assume they want to edit
            if (userCanEdit && this.state.game.completion !== 'completed') {
                this.setState({showEditGameModal: true});
            } else {
                this.setState({showDetails: !this.state.showDetails});
            }
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    onClickManage(event) {
        event.stopPropagation();
        this.setState({showEditGameModal: true});
    }

    onConcur(event) {
        event.stopPropagation();
        const patchData = {concur_with_results: true};
        this.restApi.genericPatchEndpointData('game-self', this.state.gameId, patchData);
        this.restPubSub.refresh('game', this.state.gameId, 500);
        this.refreshDependentModels();
        this.setState({disableVerifyButton: true});
    }

    onAccept(event) {
        event.stopPropagation();
        const patchData = {concur_with_schedule: true};
        this.restApi.genericPatchEndpointData('game-self', this.state.gameId, patchData);
        this.restPubSub.refresh('game', this.state.gameId, 500);
        this.refreshDependentModels();
        this.setState({disableScheduleButton: true});
    }

    generateGameTitle() {
        const gameData = this.state.game;
        let elimGameElement = null;
        let roundData = null;
        let titleElement = null;
        let elimCodeString = '';
        if (this.state.isTournamentGame && this.state.tournament !== null) {
            if (this.state.game.round_id !== null) {
                roundData = this.getTournamentRound();
                let roundName = null;
                if (roundData !== null) roundName = roundData.name;
                if (gameData.elim_code) {
                    const elimDict = gameData.parseElimCode(gameData.elim_code)
                    //bracketCode, roundIndex, gameIndex, topPrevGame, botPrevGame
                    if (elimDict['bracketCode'] === 'wb') {
                        // eslint-disable-next-line eqeqeq
                        if (elimDict['gameIndex'] != 0) {
                            elimCodeString = `Winners Bracket - Game ${elimDict['gameIndex']}`
                        } else {
                            elimCodeString = `Winners Bracket - BYE`
                        }
                    } else if (elimDict['bracketCode'] === 'lb') {
                        // eslint-disable-next-line eqeqeq
                        if (elimDict['gameIndex'] != 0) {
                            elimCodeString = `Losers Bracket - Game ${elimDict['gameIndex']}`
                        } else {
                            elimCodeString = `Losers Bracket - BYE`
                        }
                    } else {
                        // eslint-disable-next-line eqeqeq
                        if (elimDict['gameIndex'] != 0) {
                            elimCodeString = `Game ${elimDict['gameIndex']}`
                        } else {
                            elimCodeString = `BYE`
                        }
                    }
                    elimGameElement = <b>{elimCodeString}</b>
                }
                if (gameData.table_name) {
                    titleElement = <div><b>{this.state.tournament.name} {roundName}</b> {elimGameElement}<br/>{gameData.table_name} <small className="text-muted">({gameData.getDateString()})</small></div>
                } else {
                    titleElement = <div><b>{this.state.tournament.name} {roundName}</b> {elimGameElement}<br/><small className="text-muted">({gameData.getDateString()})</small></div>
                }
            } else {
                titleElement = <div><b>{this.state.tournament.name}</b> <small className="text-muted">({gameData.getDateString()})</small></div>
            }
        } else if (this.state.isEventGame && this.state.event !== null) {
            titleElement = <div><b>{this.state.event.name}</b> <small className="text-muted">({gameData.getDateString()})</small></div>
        } else if (this.state.game.board_game_id !== null && this.state.league && this.state.league.board_game_ids.includes(this.state.game.board_game_id)) {
            let boardGameName = 'Board Game';
            for (const boardGame of this.state.league.board_games) {
                if (boardGame.id === this.state.game.board_game_id) boardGameName = boardGame.name;
            }
            titleElement = <div><b>{boardGameName}</b></div>
        } else {
            titleElement = <div><b>{gameData.getDateString()}</b></div>
        }
        return titleElement
    }

    onShowFile(href, event) {
        window.open(href, '_blank');
        event.stopPropagation();
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.game === null || this.state.league === null) return(<PlaceholderGameListItem {...this.props}/>);
        if (this.state.isTournamentGame && this.state.tournament === null) return(<PlaceholderGameListItem {...this.props}/>);
        if (this.state.isEventGame && this.state.event === null) return(<PlaceholderGameListItem {...this.props}/>); 

        let editGameModal = null;
        if (this.state.showEditGameModal) {
            editGameModal = <NerdHerderEditGameModal game={this.state.game}
                                                     tournament={this.state.tournament}
                                                     event={this.state.event}
                                                     league={this.state.league}
                                                     localUser={this.props.localUser}
                                                     onCancel={(r)=>this.onCancelModal(r)}/>
        }

        const title = this.generateGameTitle();
        const gameData = this.state.game;
        const leagueData = this.state.league;
        const topicData = this.state.league.topic;
        const playersDict = gameData.getPlayersDict(this.state.league);
        const numWinners = playersDict.winnerPlayerIds.length;
        const numLosers = playersDict.loserPlayerIds.length;
        let playerScoresSection = null;
        let singleWinnerUser = null;
        let needsConcur = false;
        let needsScheduleConcur = false;
        let thisUserNeedsConcur = false;
        let thisUserNeedsScheduleConcur = false;
        let thisUserIsPlayer = false;
        const userCanEdit = this.userCanEditGame();

        // the game score section is created in a special way if there is exactly 2 players
        let twoPlayerFormat = false;
        if (numWinners === 1 && numLosers === 1) twoPlayerFormat = true;
        else if (numWinners === 2 && numLosers === 0) twoPlayerFormat = true;
        else if (numWinners === 0 && numLosers === 2) twoPlayerFormat = true;

        // if there is a single game winner, save that for later
        if (numWinners === 1) {
            const winnerId = playersDict.winnerPlayerIds[0];
            singleWinnerUser = playersDict.users[winnerId];
        }

        // check for needed verification
        if (playersDict.needsConcurIds.length > 0) {
            needsConcur = true;
            if (playersDict.needsConcurIds.includes(this.props.localUser.id)) {
                thisUserNeedsConcur = true;
            }
        }

        // check for schedule acceptance
        if (playersDict.needsScheduleConcurIds.length > 0) {
            needsScheduleConcur = true;
            if (playersDict.needsScheduleConcurIds.includes(this.props.localUser.id)) {
                thisUserNeedsScheduleConcur = true;
            }
        }

        // may need to do extra stuff if this user is a player of the game
        if (playersDict.winnerPlayerIds.includes(this.props.localUser.id) || playersDict.loserPlayerIds.includes(this.props.localUser.id)) {
            thisUserIsPlayer = true;
        }

        // build a dict with all the user images
        const imageDict = {}
        for (const playerId of playersDict.winnerPlayerIds) {
            imageDict[playerId] = <Image className="rounded-circle" height={15} width={15} src={playersDict.users[playerId].getImageUrl()} alt={playersDict.users[playerId].username}/>
        }
        for (const playerId of playersDict.loserPlayerIds) {
            imageDict[playerId] = <Image className="rounded-circle" height={15} width={15} src={playersDict.users[playerId].getImageUrl()} alt={playersDict.users[playerId].username}/>
        }

        if (twoPlayerFormat) {
            // if there is a clear winner and loser...
            if (numWinners === 1 && numLosers === 1) {
                const winnerId = playersDict.winnerPlayerIds[0];
                const loserId = playersDict.loserPlayerIds[0];
                if (playersDict.isScored) {
                    playerScoresSection = <small>{imageDict[winnerId]} <b>{playersDict.users[winnerId].username}({playersDict.usersGames[winnerId].score})</b> vs. {imageDict[loserId]} {playersDict.users[loserId].username}({playersDict.usersGames[loserId].score})</small>
                } else {
                    playerScoresSection = <small>{imageDict[winnerId]} <b>{playersDict.users[winnerId].username}</b> vs. {imageDict[loserId]} {playersDict.users[loserId].username}</small>
                }
            } 
            // not a clear winner or loser
            else {
                let player1Id = null;
                let player2Id = null;
                if (numWinners > numLosers) {
                    player1Id = playersDict.winnerPlayerIds[0];
                    player2Id = playersDict.winnerPlayerIds[1];
                } else {
                    player1Id = playersDict.loserPlayerIds[0];
                    player2Id = playersDict.loserPlayerIds[1];
                }
                if (playersDict.isScored) {
                    playerScoresSection = <small>{imageDict[player1Id]} {playersDict.users[player1Id].username}({playersDict.usersGames[player1Id].score}) vs. {imageDict[player2Id]} {playersDict.users[player2Id].username}({playersDict.usersGames[player2Id].score})</small>
                } else {
                    playerScoresSection = <small>{imageDict[player1Id]} {playersDict.users[player1Id].username} vs. {imageDict[player2Id]} {playersDict.users[player2Id].username}</small>
                }
            }
        } else {
            const playersScoresSectionArray = []
            let playerScoreItem = null;
            for (const playerId of playersDict.winnerPlayerIds) {
                if (playersDict.isScored) {
                    playerScoreItem = <div key={playerId}>{imageDict[playerId]} <b>{playersDict.users[playerId].username}({playersDict.usersGames[playerId].score})</b></div>
                } else {
                    playerScoreItem = <div key={playerId}>{imageDict[playerId]} <b>{playersDict.users[playerId].username}</b></div>
                }
                playersScoresSectionArray.push(playerScoreItem);
            }
            for (const playerId of playersDict.loserPlayerIds) {
                if (playersDict.isScored) {
                    playerScoreItem = <div key={playerId}>{imageDict[playerId]} {playersDict.users[playerId].username}({playersDict.usersGames[playerId].score})</div>
                } else {
                    playerScoreItem = <div key={playerId}>{imageDict[playerId]} {playersDict.users[playerId].username}</div>
                }
                playersScoresSectionArray.push(playerScoreItem);
            }
            playerScoresSection = <small>{playersScoresSectionArray}</small>
        }

        let needsGameConcurSection = null;
        if (needsConcur && !thisUserNeedsConcur) {
            let needsConcurString = 'has not reviewed this game';
            let needsConcurNames = '';
            if (playersDict.needsConcurIds.length > 1) needsConcurString = 'have not reviewed this game';
            for (const needsConcurId of playersDict.needsConcurIds) {
                if (needsConcurNames.length === 0) needsConcurNames += `${playersDict.users[needsConcurId].username}`;
                else needsConcurNames += `, ${playersDict.users[needsConcurId].username}`;
            }
            needsConcurString = `${needsConcurNames} ${needsConcurString}`;
            needsGameConcurSection = <NerdHerderBadge color='yellow'><NerdHerderFontIcon icon='flaticon-checked-filled-list'/> {needsConcurString}</NerdHerderBadge>
        }

        let needsScheduleConcurSection = null;
        if (needsScheduleConcur && !thisUserNeedsScheduleConcur) {
            let needsConcurString = 'has not accepted the schedule for this game';
            let needsConcurNames = '';
            if (playersDict.needsScheduleConcurIds.length > 1) needsConcurString = 'have not accepted the schedule for this game';
            for (const needsConcurId of playersDict.needsScheduleConcurIds) {
                if (needsConcurNames.length === 0) needsConcurNames += `${playersDict.users[needsConcurId].username}`;
                else needsConcurNames += `, ${playersDict.users[needsConcurId].username}`;
            }
            needsConcurString = `${needsConcurNames} ${needsConcurString}`;
            needsScheduleConcurSection = <NerdHerderBadge color='blue'><NerdHerderFontIcon icon='flaticon-calendar-to-organize-dates'/> {needsConcurString}</NerdHerderBadge>
        }

        // most of the time the game image is the league image
        let gameImageUrl = this.state.league.getImageUrl();
        // however, if this is a board game league, we'll try and use the board game image instead
        if (this.state.league.topic_id === 'BG' && gameData.board_game_id !== null) {
            if (leagueData.board_game_ids.includes(gameData.board_game_id)) {
                for (const boardGame of leagueData.board_games) {
                    if (gameData.board_game_id === boardGame.id) {
                        gameImageUrl = getStorageFilePublicUrl(`/${boardGame.bgg_thumbnail_url}`);
                        break;
                    }
                }
            }
        }

        // if the game is complete and the winner's image is added to the corner
        // if the game is complete and it's a two player format, add the X to the corner
        let gameImage = null;
        if (gameData.state === 'posted' && gameData.completion === 'completed') {
            if (numWinners === 1) {
                gameImage =
                    <div>
                        <Image className="rounded rounded-2 text-center" height={50} width={50} src={gameImageUrl} style={{position: 'relative', objectFit: 'contain'}}/>
                        <Image className="rounded-circle" height={30} width={30} src={singleWinnerUser.getImageUrl()} style={{border: '1px solid white', position: 'absolute', left: -5, top: -5}}/>
                    </div>
            } else if (twoPlayerFormat && numWinners === 2) {
                gameImage =
                    <div>
                            <Image className="rounded rounded-2 text-center" height={50} width={50} src={gameImageUrl} style={{position: 'relative', objectFit: 'contain'}}/>
                            <Image className="rounded-circle" height={30} width={30} src={getStaticStorageImageFilePublicUrl('/tied-game-diag.png')} style={{border: '1px solid white', position: 'absolute', left: -5, top: -5}}/>
                    </div>
            }
        }
        // otherwise, use the league image
        if (gameImage === null) {
            gameImage = <Image className="rounded rounded-2 text-center" height={50} width={50} src={gameImageUrl} style={{objectFit: 'contain'}}/>
        }
        
        let badgeColor = 'blue';
        if (gameData.state === 'posted') {
            if (gameData.completion === 'completed') badgeColor = 'blue';
            else if (gameData.completion === 'in-progress') badgeColor = 'yellow';
            else if (gameData.completion === 'scheduled') badgeColor = 'grey';
        } else {
            badgeColor = 'white';
        }
        let statusBadge = <NerdHerderBadge size='small' position='bottom' color={badgeColor} shape='pill'>{gameData.getStatusString()}</NerdHerderBadge>
        
        // we adjust the border based on props and the need for verification
        let border = null;
        if (thisUserNeedsConcur || thisUserNeedsScheduleConcur) {
            border = 'warning';
        } else if (this.props.selectLocalUser && thisUserIsPlayer) {
            border = 'primary';
        }

        // show details if the prop is set or if the state is set
        let showDetails = this.props.showDetails || this.state.showDetails;

        // always show details if this user needs to concur
        if (thisUserNeedsConcur || thisUserNeedsScheduleConcur) showDetails = true;

        // figure out what to do with a click
        // 1) if props.onClick is null - do nothing
        // 2) if props.onClick is undefined - show/hide details (handled by this.onClick())
        // 3) if props.onClick is defined - do that (also handled by this.onClick())
        // 4) if thisUserNeedsConcur and props.onClick is undefined, do nothing
        let onClickHandler = ()=>this.onClick();
        if (this.props.onClick === null) onClickHandler = null;
        if (thisUserNeedsConcur && typeof this.props.onClick === 'undefined') onClickHandler = null;

        // going to need some nouns
        let firstPlayerNoun = this.state.league.topic.first_player_noun;
        firstPlayerNoun = capitalizeFirstLetter(firstPlayerNoun);
        let gamePointsNoun = this.state.league.topic.points_noun;
        gamePointsNoun = capitalizeFirstLetter(gamePointsNoun);
        let factionNoun = this.state.league.topic.faction_noun;
        factionNoun = capitalizeFirstLetter(factionNoun);
        let listNoun = this.state.league.topic.list_noun;
        listNoun = capitalizeFirstLetter(listNoun);
        listNoun = pluralize(listNoun);

        // if we're showing details - see if game points and player faction stats should be included
        let gamePointsDetails = null;
        let gameFirstPlayerDetails = null;
        let playerFactionDetails = null;
        let listDetails = null;
        let score1Details = [];
        let score2Details = [];
        let score3Details = [];

        if (topicData.game_has_first_player && this.state.game.first_player_id !== null) {
            let firstPlayerId = this.state.game.first_player_id;
            if (playersDict.winnerPlayerIds.includes(firstPlayerId) || playersDict.loserPlayerIds.includes(firstPlayerId)) {
                let username = playersDict.users[firstPlayerId].username;
                gameFirstPlayerDetails = <div><small>{firstPlayerNoun} Player: {username}</small></div>
            }
        }
        if (topicData.game_has_points && this.state.game.game_points !== null) {
            gamePointsDetails = <div><small>{gamePointsNoun} Level: {this.state.game.game_points}</small></div>
        }
        if (topicData.game_player_has_faction) {
            playerFactionDetails = [];
            for (const playerId of playersDict.winnerPlayerIds) {
                let factionName = playersDict.usersGames[playerId].faction;
                if (this.factionDict.hasOwnProperty(factionName)) factionName = this.factionDict[factionName];
                if (factionName === '' || factionName === null) factionName = 'Unknown';
                const playerFactionRow = <div key={`faction-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {factionName}</small></div>
                playerFactionDetails.push(playerFactionRow);
            }
            for (const playerId of playersDict.loserPlayerIds) {
                let factionName = playersDict.usersGames[playerId].faction;
                if (this.factionDict.hasOwnProperty(factionName)) factionName = this.factionDict[factionName];
                if (factionName === '' || factionName === null) factionName = 'Unknown';
                const playerFactionRow = <div key={`faction-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {factionName}</small></div>
                playerFactionDetails.push(playerFactionRow);
            }
        }

        for (const player of gameData.players) {
            if (player.list_id !== null && player.list_name !== null && player.list_path !== null) {
                if (listDetails === null) listDetails = [];
                let playerId = player.user_id;
                let href = getStorageFilePublicUrl(`/${player.list_path}`);
                let iconUrl = getFileUiIconUrl(player.list_path);
                let listPointsJsx = null;
                if (player.list_points) {
                    listPointsJsx = <span> ({player.list_points})</span>
                }
                const fileListItem =
                    <div key={player.list_name}>
                        <span onClick={(e)=>this.onShowFile(href, e)}>
                            <img className='cursor-pointer' src={iconUrl} alt="file icon" height="15"/> <small>{playersDict.users[playerId].username}{listPointsJsx}</small>
                        </span>
                    </div>
                listDetails.push(fileListItem);
            }
        }

        if (topicData.scores_recorded >= 2) {
            for (const playerId of playersDict.winnerPlayerIds) {
                const playerScore1Row = <div key={`score1-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {playersDict.usersGames[playerId].score1}</small></div>
                const playerScore2Row = <div key={`score2-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {playersDict.usersGames[playerId].score2}</small></div>
                const playerScore3Row = <div key={`score3-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {playersDict.usersGames[playerId].score3}</small></div>
                score1Details.push(playerScore1Row);
                score2Details.push(playerScore2Row);
                score3Details.push(playerScore3Row);
            }
            for (const playerId of playersDict.loserPlayerIds) {
                const playerScore1Row = <div key={`score1-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {playersDict.usersGames[playerId].score1}</small></div>
                const playerScore2Row = <div key={`score2-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {playersDict.usersGames[playerId].score2}</small></div>
                const playerScore3Row = <div key={`score3-${this.state.gameId}-${playerId}`}><small>{playersDict.users[playerId].username}: {playersDict.usersGames[playerId].score3}</small></div>
                score1Details.push(playerScore1Row);
                score2Details.push(playerScore2Row);
                score3Details.push(playerScore3Row);
            }
        }

        let proposedDateTime = null;
        if (gameData.proposed_datetime) {
            let dateTime = DateTime.now();
            let browserTimezone = this.props.localUser.timezone;
            if (dateTime.isValid) browserTimezone = dateTime.zoneName;
            proposedDateTime = DateTime.fromISO(gameData.proposed_datetime, {zone: leagueData.timezone});
            if (browserTimezone !== this.state.league.timezone) proposedDateTime = proposedDateTime.setZone(browserTimezone);
            proposedDateTime = `${proposedDateTime.toLocaleString(DateTime.DATE_SHORT)} ${proposedDateTime.toLocaleString(DateTime.TIME_SIMPLE)} ${proposedDateTime.toFormat('ZZZZ')}`;
        }


        let gameDetails = gameData.details;
        if (gameDetails) gameDetails = <div><LinkifyText><small>{gameData.details}</small></LinkifyText></div>
       
        return (
            <Row className="my-1 align-items-center" style={{position: 'relative'}}>
                <Col xs={12}>
                    <NerdHerderListItemContainer border={border} onClick={onClickHandler}>
                        {editGameModal}
                        <Row>
                            <Col xs="auto" className="text-center">
                                <NerdHerderBadgeInjector badge={statusBadge}>
                                    {gameImage}
                                </NerdHerderBadgeInjector>
                            </Col>
                            <Col>
                                <div>{title}</div>
                                {playerScoresSection}
                                <div>{needsGameConcurSection}</div>
                                <div>{needsScheduleConcurSection}</div>
                                <Collapse in={showDetails}>
                                    <div>
                                        {(gamePointsDetails || gameFirstPlayerDetails) &&
                                        <Row>
                                            <Col xs={12}>
                                                <b><small>Game Stats</small></b>
                                            </Col>
                                            <Col xs={1}/>
                                            <Col>
                                                {gamePointsDetails}
                                                {gameFirstPlayerDetails}
                                            </Col>
                                        </Row>}
                                        {topicData.scores_recorded >= 2 &&
                                        <Row>
                                            <Col xs={12}>
                                                <b><small className='text-capitalize'>{topicData.score1_noun}</small></b>
                                            </Col>
                                            <Col xs={1}/>
                                            <Col>
                                                {score1Details}
                                            </Col>
                                        </Row>}
                                        {topicData.scores_recorded >= 2 &&
                                        <Row>
                                            <Col xs={12}>
                                                <b><small className='text-capitalize'>{topicData.score2_noun}</small></b>
                                            </Col>
                                            <Col xs={1}/>
                                            <Col>
                                                {score2Details}
                                            </Col>
                                        </Row>}
                                        {topicData.scores_recorded >= 3 &&
                                        <Row>
                                            <Col xs={12}>
                                                <b><small className='text-capitalize'>{topicData.score3_noun}</small></b>
                                            </Col>
                                            <Col xs={1}/>
                                            <Col>
                                                {score3Details}
                                            </Col>
                                        </Row>}
                                        {playerFactionDetails &&
                                        <Row>
                                            <Col xs={12}>
                                                <b><small>{factionNoun}s</small></b>
                                            </Col>
                                            <Col xs={1}/>
                                            <Col>
                                                {playerFactionDetails}
                                            </Col>
                                        </Row>}
                                        {listDetails &&
                                        <Row>
                                            <Col xs={12}>
                                                <b><small>{listNoun}</small></b>
                                            </Col>
                                            <Col xs={1}/>
                                            <Col>
                                                {listDetails}
                                            </Col>
                                        </Row>}
                                        {gameDetails &&
                                        <Row>
                                            <Col xs={12}>
                                                <b><small>Details</small></b>
                                            </Col>
                                            <Col xs={1}/>
                                            <Col>
                                                {gameDetails}
                                            </Col>
                                        </Row>}
                                        <Row>
                                            <Col xs={12}>
                                                <b><small>Shortcuts</small></b>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={1}/>
                                            <Col>
                                                <small>
                                                    <a href={`/app/league/${this.state.league.id}`}><NerdHerderFontIcon icon='flaticon-team'/> {this.state.league.name}</a>
                                                </small>
                                            </Col>
                                        </Row>
                                        {this.state.isTournamentGame &&
                                        <Row>
                                            <Col xs={1}/>
                                            <Col>
                                                <small>
                                                    <a href={`/app/tournament/${this.state.tournament.id}`}><NerdHerderFontIcon icon='flaticon-trophy-cup-black-shape'/> {this.state.tournament.name}</a>
                                                </small>
                                            </Col>
                                        </Row>}
                                    </div>
                                </Collapse>
                            </Col>
                        </Row>
                        {thisUserNeedsConcur && !this.props.noVerifyIcon &&
                        <div>
                            <hr/>
                            <Row>
                                <Col xs='auto'>
                                    <Image height={30} width={30} src={getStaticStorageImageFilePublicUrl('/cv.png')}/>
                                </Col>
                                <Col>
                                    <small><i>Does this game look correct to you? </i></small>
                                </Col>
                                <Col xs='auto'>
                                    <Button size='sm' variant='warning' onClick={(e)=>this.onConcur(e)} disabled={this.state.disableVerifyButton}>Verify</Button>
                                </Col>
                            </Row>
                        </div>}
                        {thisUserNeedsScheduleConcur && !this.props.noScheduleIcon &&
                        <div>
                            <hr/>
                            <Row className='align-items-center'>
                                <Col xs='auto'>
                                    <Image height={30} width={30} src={getStaticStorageImageFilePublicUrl('/calendar.png')}/>
                                </Col>
                                <Col>
                                    <div>
                                        <small>{proposedDateTime}</small>
                                    </div>
                                    <div>
                                        <small><i>Will this time and date work for you? </i></small>
                                    </div>
                                </Col>
                                <Col xs='auto'>
                                    <Button size='sm' variant='primary' onClick={(e)=>this.onAccept(e)} disabled={this.state.disableScheduleButton}>Accept</Button>
                                </Col>
                            </Row>
                        </div>}
                        {this.props.children}
                    </NerdHerderListItemContainer>
                    {userCanEdit && showDetails && this.props.onClick !== 'manage' && !this.props.noManageIcon &&
                    <div style={{position: 'absolute', top: '8px', right: '12px', width: '40px', zIndex: 1010}}>
                        <Button variant='danger' size='sm' onClick={(e)=>this.onClickManage(e)}><NerdHerderFontIcon icon='flaticon-configuration-with-gear'/></Button>
                    </div>}
                </Col>
            </Row>
        );
    }
}

export class PollOptionListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inView: false,
        }
    }

    onChangeView(inView, entry) {
        if (inView && this.state.inView === false) {
            this.setState({inView: true});
        }
    }

    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='PollOptionListItem' listItemId={this.props.pollOptionId || this.props.pollOption.id || null}>
                <InView onChange={(inView, entry)=>{this.onChangeView(inView, entry)}}>
                    {!this.state.inView &&
                    <PlaceholderPollOptionListItem/>}
                    {this.state.inView &&
                    <PollOptionListItemInner {...this.props}/>}
                </InView>
            </ListItemErrorBoundary>
        )
    }
}

export class PlaceholderPollOptionListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1 cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-2 my-0">
                                {this.props.slim &&
                                <Placeholder as="div" animation="glow">
                                    <Placeholder.Button bg='primary' xs={12} size='xs' style={{width: '25px', height: '25px'}}/>
                                </Placeholder>}
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '50px', height: '50px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                    <Placeholder bg='primary' xs={12} size='sm'/>
                                </Placeholder>}
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class PollOptionListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.pollOptionId === 'undefined' && typeof this.props.pollOption === 'undefined') console.error('missing props.pollOptionId or props.pollOption');

        // grab the poll option directly from props, or if given an ID look it up
        let pollOptionData = null;
        let pollOptionId = null;
        if (typeof this.props.pollOption !== 'undefined') {
            pollOptionData = this.props.pollOption;
            pollOptionId = pollOptionData.id;
        } else {
            pollOptionId = this.props.pollOptionId;
        }

        // grab the poll directly from props if possible
        let pollData = null;
        if (typeof this.props.poll !== 'undefined') {
            pollData = this.props.poll;
        }

        this.state = {
            pollOptionId: pollOptionId,
            pollOption: pollOptionData,
            poll: pollData,
            deleted: false,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        if (this.state.pollOption === null) {
            let sub = this.restPubSub.subscribe('poll-option', this.state.pollOptionId, (d, k)=>{this.updatePollOption(d, k)});
            this.restPubSubPool.add(sub);
        } else {
            let sub = this.restPubSub.subscribeNoRefresh('poll-option', this.state.pollOptionId, (d, k)=>{this.updatePollOption(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updatePollOption(pollOptionData, key) {
        if (pollOptionData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newPollOption = NerdHerderDataModelFactory('poll_option', pollOptionData);
        let sub = this.restPubSub.subscribeNoRefresh('poll', newPollOption.poll_id, (d, k) => {this.updatePoll(d, k)});
        this.restPubSubPool.add(sub);
        this.setState({pollOptionId: newPollOption.id, pollOption: newPollOption});
    }

    updatePoll(pollData, key) {
        const newPoll = NerdHerderDataModelFactory('poll', pollData);
        this.setState({poll: newPoll});
    }

    onCastVote() {
        if (this.state.poll.hasUserVoted(this.props.localUser.id)) {
            const queryParams = {'user-id': this.props.localUser.id, 'poll-id': this.state.poll.id}
            this.restApi.genericPatchEndpointData('poll-vote', null, {poll_option_id: this.state.pollOptionId}, queryParams)
            .then((response)=>{
                this.restPubSub.refresh('poll', this.state.poll.id);
            })
            .catch((error)=>{
                console.error(error);
            });
        } else {
            const queryParams = {'user-id': this.props.localUser.id, 'poll-id': this.state.poll.id}
            const postData = {user_id: this.props.localUser.id, poll_id: this.state.poll.id, comment: '', poll_option_id: this.state.pollOptionId};
            this.restApi.genericPostEndpointData('poll-vote', null, postData, queryParams)
            .then((response)=>{
                this.restPubSub.refresh('poll', this.state.poll.id);
            })
            .catch((error)=>{
                console.error(error);
            });
        }
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.pollOption === null || this.state.poll === null) return(<PlaceholderPollOptionListItem {...this.props}/>); 

        // we adjust the border to selected if the user has voted
        let border = null;
        if (this.props.doNotSelectBorders !== true && this.state.poll.results[this.state.pollOptionId].includes(this.props.localUser.id)) {
            border = 'primary';
        }

        let optionImage = null;
        if (this.state.pollOption.image_file) {
            optionImage = <Image fluid className="rounded text-center" src={this.state.pollOption.getImageUrl()}/>
        }

        let onClickHandler = this.props.onClick || null;
        if (onClickHandler === null && this.props.onClickVote) {
            const canUserVote = this.state.poll.canUserVote(this.props.localUser.id, this.props.league);
            if (canUserVote) {
                onClickHandler = ()=>this.onCastVote();
            }
        }
        
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderListItemContainer border={border} onClick={onClickHandler}>
                        <Row>
                            {optionImage &&
                            <Col xs={4}>
                                {optionImage}
                            </Col>}
                            <Col>
                                <b>{this.state.pollOption.title}</b><br/>
                                <small>{this.state.pollOption.text}</small>
                            </Col>
                        </Row>
                        {this.props.children}
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        );
    }
}

export class BoardGameListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inView: false,
        }
    }

    onChangeView(inView, entry) {
        if (inView && this.state.inView === false) {
            this.setState({inView: true});
        }
    }

    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='BoardGameListItem' listItemId={this.props.boardGameId || this.props.boardGame.id || null}>
                <InView onChange={(inView, entry)=>{this.onChangeView(inView, entry)}}>
                    {!this.state.inView &&
                    <PlaceholderBoardGameListItem/>}
                    {this.state.inView &&
                    <BoardGameListItemInner {...this.props}/>}
                </InView>
            </ListItemErrorBoundary>
        )
    }
}

export class PlaceholderBoardGameListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1 cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-2 my-0">
                                <Placeholder as="div" animation="glow">
                                    <Placeholder.Button bg='primary' xs={12} size='xs' style={{width: '50px', height: '50px'}}/>
                                </Placeholder>
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class BoardGameListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.boardGameId === 'undefined' && typeof this.props.boardGame === 'undefined') console.error('missing props.boardGameId or props.boardGame');

        // grab the board game directly from props, or if given an ID look it up
        let boardGameData = null;
        let boardGameId = null;
        if (typeof this.props.boardGame !== 'undefined') {
            boardGameData = this.props.boardGame;
            boardGameId = boardGameData.id;
        } else {
            boardGameId = this.props.boardGameId;
        }

        this.state = {
            boardGameId: boardGameId,
            boardGame: boardGameData,
            deleted: false,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        if (this.state.boardGame === null) {
            let sub = this.restPubSub.subscribe('board-game', this.state.boardGameId, (d, k)=>{this.updateBoardGame(d, k)});
            this.restPubSubPool.add(sub);
        } else {
            let sub = this.restPubSub.subscribeNoRefresh('board-game', this.state.boardGameId, (d, k)=>{this.updateBoardGame(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateBoardGame(boardGameData, key) {
        if (boardGameData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        this.setState({boardGameId: boardGameData.id, boardGame: {...boardGameData}});
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.boardGame === null) return(<PlaceholderBoardGameListItem {...this.props}/>); 

        let boardGameImage = null;
        if (this.state.boardGame.bgg_thumbnail_url) {
            const url = getStorageFilePublicUrl(`/${this.state.boardGame.bgg_thumbnail_url}`);
            boardGameImage = <Image className="rounded text-center" width={50} height={50} src={url} style={{objectFit: 'contain'}}/>
        }

        // we adjust the border to selected if needed
        let border = null;
        if (this.props.selected) border = 'primary';

        let onClickHandler = this.props.onClick || null;
        
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderListItemContainer border={border} onClick={onClickHandler}>
                        <Row>
                            {boardGameImage &&
                            <Col xs='auto' className='text-center'>
                                {boardGameImage}
                            </Col>}
                            <Col>
                                <b>{this.state.boardGame.name}</b><br/>
                                <span onClick={(e)=>e.stopPropagation()}>
                                    <small className='text-muted'>BGG: <a target='_blank' rel='noreferrer' href={`https://boardgamegeek.com/boardgame/${this.state.boardGame.bgg_id}`}>{this.state.boardGame.bgg_id}</a></small>
                                </span>
                            </Col>
                        </Row>
                        {this.props.children}
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        );
    }
}

export class VenueListItem extends React.Component {
    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='VenueListItem'>
                <VenueListItemInner {...this.props}/>
            </ListItemErrorBoundary>
        )
    }
}

class PlaceholderVenueListItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1 cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-1 my-0">
                                {this.props.slim &&
                                <Placeholder as="div" animation="glow">
                                    <Placeholder.Button bg='primary' xs={3} size='sm'/>
                                </Placeholder>}
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '50px', height: '50px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                </Placeholder>}
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class VenueListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.venueId === 'undefined' && typeof this.props.venue === 'undefined') console.error('missing props.venueId or props.venue');

        let venueData = null;
        let venueId = null;
        if (typeof this.props.venue !== 'undefined') {
            venueData = this.props.venue;
            venueId = venueData.id;
        } else {
            venueId = this.props.venueId;
        }

        this.state = {
            navigateTo: null,
            showVenueModal: false,
            deleted: false,
            venueId: venueId,
            venue: venueData,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        if (this.state.venue === null) {
            let sub = this.restPubSub.subscribe('venue', this.state.venueId, (d, k) => {this.updateVenue(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateVenue(venueData, key) {
        if (venueData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newVenue = NerdHerderDataModelFactory('venue', venueData);
        this.setState({venueId: newVenue.id, venue: newVenue});
    }

    isFavorite() {
        return(this.props.localUser.favorite_venue_ids.includes(this.state.venueId));
    }

    onCancelModal() {
        this.setState({showVenueModal: false});
    }

    onClickManage(event) {
        event.stopPropagation();
        this.setState({navigateTo: `/app/managevenue/${this.props.venueId}`});
    }

    onClickFavorite() {
        const isFavorite = this.isFavorite();
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-venue', 'user-venue',
                                                                'user-id', this.props.localUser.id,
                                                                'venue-id', this.props.venueId);
        joinModelRestApi.patch({favorite: !isFavorite})
        .then((response)=>{
            this.restPubSub.refresh('self', null);
        })
        .catch((error)=>{
            console.error(error);
        });
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            this.setState({showVenueModal: true});
        } else if (this.props.onClick === 'manage') {
            this.setState({navigateTo: `/app/managevenue/${this.props.venueId}`});
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.venue === null) return(<PlaceholderVenueListItem slim={this.props.slim}/>);

        const venueData = this.state.venue;
        let isManager = false;
        if (this.props.localUser) isManager = venueData.isManager(this.props.localUser.id);
        const isFavorite = this.isFavorite();

        let venueModal = null;
        if (this.state.showVenueModal) {
            venueModal = <NerdHerderVenueModal venue={this.state.venue}
                                               localUser={this.props.localUser}
                                               onCancel={(r)=>this.onCancelModal(r)}/>
        }

        // if the image is not set, it will be kraken.png - get it from static storage
        let imageHref = venueData.image;
        if (imageHref === 'venue_image.png') {
            imageHref = getStaticStorageImageFilePublicUrl(imageHref);
        } else if (imageHref === '/static/venue_image.png') {
            imageHref = getStorageFilePublicUrl(imageHref);
        }

        // we adjust the border based on props
        let border = null;
        if (this.props.selected === true) {
            border = 'primary';
        } else if (this.props.border) {
            border = this.props.border;
        }

        let hideLocation = false;
        if (this.props.hideLocation) hideLocation = true;

        if (this.props.slim) {
            return (
                <Row className="my-1 align-items-center">
                    <Col xs={12}>
                        <NerdHerderNavigate to={this.state.navigateTo}/>
                        {venueModal}
                        <NerdHerderListItemContainer border={border} onClick={this.props.onClick===null ? null : ()=>this.onClick()}>
                            <Row className='align-items-center'>
                                <Col xs="auto" className="text-center">
                                    <Image className="rounded rounded-2 text-center" height={30} width={30} src={imageHref}/>
                                </Col>
                                <Col>
                                    <b>{venueData.name}</b>
                                </Col>
                            </Row>
                            {venueData.venue_string && !hideLocation &&
                            <Row>
                                <Col>
                                    <LinkifyText><small>{venueData.venue_string}</small></LinkifyText>
                                </Col>
                                <Col xs='auto'>
                                    <NerdHerderMapIcon location={venueData.venue_string}/>
                                </Col>
                            </Row>}
                            {this.props.children}
                        </NerdHerderListItemContainer>
                    </Col>
                </Row>
            );
        }

        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderNavigate to={this.state.navigateTo}/>
                    {venueModal}
                    <NerdHerderListItemContainer border={border} onClick={this.props.onClick===null ? null : ()=>this.onClick()}>
                        <Row className='align-items-center'>
                            <Col xs="auto" className="text-center">
                                <Image className="rounded rounded-2 text-center" height={60} width={60} src={imageHref}/>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <b>{venueData.name}</b>
                                    </Col>
                                    {isManager && this.props.onClick !== 'manage' && !this.props.noManageIcon &&
                                    <Col xs='auto'>
                                        <Button variant='danger' size='sm' onClick={(e)=>this.onClickManage(e)}><NerdHerderFontIcon icon='flaticon-configuration-with-gear'/></Button>
                                    </Col>}
                                    {!isManager &&
                                    <Col xs='auto'>
                                        <NerdHerderFavoriteIcon favorite={isFavorite} onClick={()=>this.onClickFavorite()}/>
                                    </Col>}
                                </Row>
                                {venueData.phone &&
                                <div>
                                    <small className='text-muted'>{venueData.phone}</small>
                                </div>}
                                {venueData.website &&
                                <div>
                                    <span onClick={(e)=>{e.stopPropagation()}}>
                                        <small className='text-muted'><a target='_blank' rel='noreferrer' href={venueData.website}>{venueData.website}</a></small>
                                    </span>
                                </div>}
                                {venueData.email && !venueData.website &&
                                <div>
                                    <span onClick={(e)=>{e.stopPropagation()}}>
                                        <small className='text-muted'><a target='_blank' rel='noreferrer' href={`mailto:${venueData.email}`}>{venueData.email}</a></small>
                                    </span>
                                </div>}
                            </Col>
                        </Row>
                        {venueData.description && venueData.description.length !== 0 &&
                        <Row>
                            <Col>
                                <LinkifyText><small>{venueData.description}</small></LinkifyText>
                            </Col>
                        </Row>}
                        {venueData.venue_string && venueData.description && venueData.description.length !== 0 &&
                        <hr className='my-2'/>}
                        {venueData.venue_string &&
                        <Row>
                            <Col>
                                <LinkifyText><small>{venueData.venue_string}</small></LinkifyText>
                            </Col>
                            <Col xs='auto'>
                                <NerdHerderMapIcon location={venueData.venue_string}/>
                            </Col>
                        </Row>}
                        {this.props.children}
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        )
    }
}



export class DateListItem extends React.Component {
    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='DateListItem'>
                <DateListItemInner {...this.props}/>
            </ListItemErrorBoundary>
        )
    }
}

class PlaceholderDateListItemItem extends React.Component {
    render() {
        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <div className="list-group-item rounded align-middle py-1  cursor-wait">
                        <Row>
                            <Col xs="auto" className="text-center px-2 py-1 my-0">
                                {this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '25px', height: '25px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                </Placeholder>}
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" style={{width: '50px', height: '50px'}}>
                                    <Placeholder.Button bg='primary' xs={12} size='lg'/>
                                </Placeholder>}
                            </Col>
                            <Col>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8}/>
                                </Placeholder>
                                {!this.props.slim &&
                                <Placeholder as="div" animation="glow" size="xs">
                                    <Placeholder xs={6}/>
                                </Placeholder>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

class DateListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.dateId === 'undefined' && typeof this.props.date === 'undefined') console.error('missing props.dateId or props.date');

        // grab the date directly from props, or if given an ID look it up
        let calendarDateData = null;
        let calendarDateId = null;
        if (typeof this.props.date !== 'undefined') {
            calendarDateData = this.props.date;
            calendarDateId = calendarDateData.id;
        } else {
            calendarDateId = this.props.dateId;
        }

        // grab the league directly from props if possible
        let leagueData = null;
        if (typeof this.props.league !== 'undefined') {
            leagueData = this.props.league;
        }

        this.state = {
            navigateTo: null,
            showEditCalendarDateModal: false,
            calendarDateId: calendarDateId,
            calendarDate: calendarDateData,
            league: leagueData,
            deleted: false,
        }

        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        if (this.state.calendarDate === null) {
            let sub = this.restPubSub.subscribe('calendar-date', this.state.calendarDateId, (d, k) => {this.updateCalendarDate(d, k)});
            this.restPubSubPool.add(sub);
        }

        if (this.state.league === null) {
            let sub = this.restPubSub.subscribeNoRefresh('league', this.state.leagueId, (d, k) => {this.updateLeague(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateCalendarDate(dateData, key) {
        if (dateData === "DELETED") {
            this.setState({deleted: true});
            return
        }

        const newCalendarDate = NerdHerderDataModelFactory('calendar_date', dateData);
        this.setState({calendarDateId: dateData.id, calendarDate: newCalendarDate});
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague});
    }

    onClick() {
        if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    onClickManage(event) {
        event.stopPropagation();
        this.setState({showEditCalendarDateModal: true});
    }

    render() {
        if (this.state.deleted) return (null);
        if (this.state.calendarDate === null || this.state.league === null) return(<PlaceholderDateListItemItem/>);

        const calendarDateData = this.state.calendarDate;
        const leagueData = this.state.league;
        let isManager = false;
        if (this.props.localUser) isManager = leagueData.isManager(this.props.localUser.id);
        let imageHref = getStaticStorageImageFilePublicUrl('/calendar.png');
        let schedule = calendarDateData.getScheduleString();

        return (
            <Row className="my-1 align-items-center" style={{position: 'relative'}}>
                <Col xs={12}>
                    {this.state.showEditCalendarDateModal &&
                    <NerdHerderEditCalendarDateModal calendarDateId={this.state.calendarDateId} onCancel={()=>this.setState({showEditCalendarDateModal: false})} league={this.state.league} localUser={this.props.localUser}/>}
                    <NerdHerderListItemContainer onClick={this.props.onClick ? ()=>this.onClick() : null}>
                        <Row>
                            <Col xs="auto" className="text-center">
                                <Image className="rounded rounded-2 text-center" height={50} width={50} src={imageHref}/>
                            </Col>
                            <Col>
                                <div><b>{calendarDateData.name}</b></div>
                                {schedule &&
                                <div><small className='text-muted'>{schedule}</small></div>}
                                <div>{calendarDateData.description}</div>
                            </Col>
                        </Row>
                        {this.props.children}
                    </NerdHerderListItemContainer>
                    {isManager && this.props.onClick !== 'manage' && !this.props.noManageIcon &&
                    <div style={{position: 'absolute', top: '8px', right: '12px', width: '40px', zIndex: 1010}}>
                        <Button variant='danger' size='sm' onClick={(e)=>this.onClickManage(e)}><NerdHerderFontIcon icon='flaticon-configuration-with-gear'/></Button>
                    </div>}
                </Col>
            </Row>
        )
    }
}

export class ScheduledGameListItem extends React.Component {
    render() {
        return (
            <ListItemErrorBoundary listItemTypeName='ScheduledGameListItem'>
                <ScheduledGameListItemInner {...this.props}/>
            </ListItemErrorBoundary>
        )
    }
}

class ScheduledGameListItemInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.gameId === 'undefined') console.error('missing props.gameId');

        this.state = {
            navigateTo: null,
            updating: false,
            accepted: null,
            game: null,
            leagueId: null,
            league: null,
            deleted: false,
        }

        // detect browser timezone - fallback to profile if it doesn't work
        let dateTime = DateTime.now();
        if (dateTime.isValid) this.browserTimezone = dateTime.zoneName;
        if (this.browserTimezone === null) this.browserTimezone = this.props.localUser.timezone;

        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeToFirestore('games', this.props.gameId, (d, k) => {this.updateGame(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateGame(gameData, key) {
        if (gameData === "DELETED") {
            this.setState({deleted: true, accepted: null});
            return
        }

        const newGame = NerdHerderDataModelFactory('game', gameData);
        let newAccepted = this.state.accepted;
        let newLeagueId = this.state.leagueId;
        let newLeague = this.state.league;
        if (newAccepted === null) {
            for (const player of newGame.players) {
                if (player.user_id === this.props.localUser.id) {
                    newAccepted = player.concur_with_schedule;
                    break;
                }
            }
        }
        if (newGame.league_id !== newLeagueId) {
            newLeagueId = newGame.league_id;
            newLeague = null;
            let sub = this.restPubSub.subscribeToFirestore('leagues', newLeagueId, (d, k) => {this.updateLeague(d, k)});
            this.restPubSubPool.add(sub);
        }
        this.setState({game: newGame, accepted: newAccepted, leagueId: newLeagueId, league: newLeague, updating: false});
    }

    updateLeague(leagueData, key) {
        const newLeague = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: newLeague});
    }

    onClick() {
        if (typeof this.props.onClick === 'undefined') {
            return;
        } else if (this.props.onClick === 'manage') {
            return;
        } else if (this.props.onClick !== null) {
            this.props.onClick();
        }
    }

    onAccept() {
        this.setState({accepted: true});
        const joinModelRestApi = new NerdHerderJoinModelRestApi('user-game', 'user-game',
                                                                'user-id', this.props.localUser.id,
                                                                'game-id', this.state.game.id);
        joinModelRestApi.patch({concur_with_schedule: true})
        .then((response)=>{
            this.restPubSub.refresh('game', this.props.gameId);
        })
        .catch((error)=>{
            console.error(error);
            this.setState({updating: false});
        });
        this.setState({updating: true});
    }

    onReschedule() {
        if (this.props.onRescheduleClick) {
            this.props.onRescheduleClick();
        } else {
            this.setState({showProposeScheduleModal: true});
        }
    }

    render() {
        if (this.state.deleted) return(null);
        if (this.state.game === null) return(null);
        if (this.state.league === null) return(null);
        let proposedDatetime = this.state.game.proposed_datetime;
        if (typeof this.props.proposedDatetime !== 'undefined') {
            proposedDatetime = this.props.proposedDatetime;
        }

        proposedDatetime = DateTime.fromISO(proposedDatetime, {zone: this.state.league.timezone});
        if (this.browserTimezone !== this.state.league.timezone) proposedDatetime = proposedDatetime.setZone(this.browserTimezone);

        let proposalJsx = null;
        if (proposedDatetime) {
            proposalJsx = `${proposedDatetime.toLocaleString(DateTime.DATE_SHORT)} ${proposedDatetime.toLocaleString(DateTime.TIME_SIMPLE)} ${proposedDatetime.toFormat('ZZZZ')}`;
        } else {
            proposalJsx = <small>No Schedule Proposed</small>
        }

        let acceptanceMicrotext = 'Proposed Time';
        let localUserAccepted = false;
        let localUserIsPlayer = false;
        let allPlayersAccepted = true;
        let numPlayersAccepted = 0;
        for (const player of this.state.game.players) {
            if (player.user_id === this.props.localUser.id) {
                localUserIsPlayer = true;
                localUserAccepted = player.concur_with_schedule;
            }
            if (player.concur_with_schedule) {
                numPlayersAccepted++;
            } else {
                allPlayersAccepted = false;
            }
        }

        if (this.props.localUserConcurWithSchedule) {
            localUserAccepted = true;
        }

        if (allPlayersAccepted) {
            acceptanceMicrotext = 'All Players Accepted';
        } else {
            if (localUserIsPlayer) {
                if (localUserAccepted) {
                    acceptanceMicrotext = 'Accepted';
                } else {
                    acceptanceMicrotext = 'Proposed Time';
                }
            } else {
                if (numPlayersAccepted === 0) acceptanceMicrotext = 'Proposed Time';
                else acceptanceMicrotext = `${numPlayersAccepted} Players Accepted`;
            }
        }

        return (
            <Row className="my-1 align-items-center">
                <Col xs={12}>
                    <NerdHerderNavigate to={this.state.navigateTo}/>
                    <NerdHerderListItemContainer onClick={this.props.onClick===null ? null : ()=>this.onClick()}>
                        <Row className='align-items-center'>
                            <Col xs="auto">
                                <Image height={30} width={30} src={getStaticStorageImageFilePublicUrl('/calendar.png')}/>
                            </Col>
                            <Col className="px-0">
                                <div className='text-muted' style={{fontSize: '10px'}}>
                                    {acceptanceMicrotext}
                                </div>
                                <div>
                                    {proposalJsx}
                                </div>
                            </Col>
                            <Col xs="auto">
                                <NerdHerderToolTipButton variant='primary' size='sm' icon='flaticon-verification-sign' placement='left' tooltipText='accept proposed time' disabled={this.state.updating || localUserAccepted} onClick={()=>this.onAccept()}/>
                                {' '}
                                <NerdHerderToolTipButton variant='secondary' size='sm' icon='flaticon-calendar-to-organize-dates' placement='left' tooltipText='reschedule game' disabled={this.state.updating} onClick={()=>this.onReschedule()}/>
                            </Col>
                        </Row>
                        {this.props.children}
                    </NerdHerderListItemContainer>
                </Col>
            </Row>
        )
    }
}
