import React from 'react';
import withRouter from './withRouter';
import { Navigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import Badge from 'react-bootstrap/Badge';
import { DateTime, SystemZone } from 'luxon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { FormControlCountdown } from './nerdherder-components/NerdHerderCountdown';
import { NerdHerderTwoColumnPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderConfirmModal, NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderEditTournamentModal, NerdHerderAddGameModal, NerdHerderCompleteTournamentModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { getDateIsoFormat, handleGlobalRestError, getRandomInteger, getRandomString, delCookieAfterDelay } from './utilities';
import { parseTournamentGetResponse, parseRanking, usersGamesKey, getCurrentRound, geFirstDraftRound, getLastRound, getLastCompletedRound, getTournamentRound, tabulateTournamentData, sortTournamentPlayers, generatePairings, buildTableDict, evaluateRoundGames, generateTimeRemainingHMS } from './tournament_utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { GameListItem} from './nerdherder-components/NerdHerderListItems';
import { getFormErrors, FormControlSubmit, TripleDeleteButton } from './nerdherder-components/NerdHerderFormHelpers';
import { TableOfUsers } from './nerdherder-components/NerdHerderTableHelpers';
import { NerdHerderToolTip, NerdHerderToolTipIcon } from './nerdherder-components/NerdHerderToolTip';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import Spinner from 'react-bootstrap/esm/Spinner';

class ManageTournamentPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            league: null,
            event: null,
            tournamentId: this.props.params.tournamentId,
            tournament: null,
            tournamentRoundIds: [],
            tournamentRounds: {},
            games: {},
            usersTournaments: {},
            usersGames: {},
            usersCache: {},
            players: {},
            contacts: {},
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('tournament', this.state.tournamentId, (d, k) => {this.updateTournament(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateTournament(tournamentData, key) {
        const tournament = NerdHerderDataModelFactory('tournament', tournamentData);

        // update the league - should be a one-time thing
        if (this.state.league === null) {
            const sub = this.restPubSub.subscribe('league', tournament.league_id, (d, k) => {this.updateLeague(d, k)});
            this.restPubSubPool.add(sub);
        }

        // update the managers
        for (const managerUserId of tournament.manager_ids) {
            if (this.state.usersCache.hasOwnProperty(managerUserId)) continue;
            const sub = this.restPubSub.subscribe('user', managerUserId, (d, k) => {this.updateUser(d, k)}, null, managerUserId);
            this.restPubSubPool.add(sub);
        }

        // update the contacts
        for (const playerUserId of tournament.player_ids) {
            if (this.state.contacts.hasOwnProperty(playerUserId)) continue;
            const sub = this.restPubSub.subscribe('user-contacts', playerUserId, (d, k) => {this.updateContacts(d, k)}, null, playerUserId);
            this.restPubSubPool.add(sub);
        }

        // update the event if there is one or if it changed somehow
        if (tournament.event_id !== null && (this.state.event === null || this.state.event.id !== tournament.event_id)) {
            const sub = this.restPubSub.subscribe('event', tournament.event_id, (d, k) => {this.updateEvent(d, k)});
            this.restPubSubPool.add(sub);
        }

        // for simplicity, keep our own copy of the round IDs
        const updatedRoundIds = [...tournament.round_ids];

        // get the users_tournaments, round, users_games, game, and user info out of the tournament response
        const updatedUsersTournaments = {};
        const updatedRounds = {};
        const updatedGames = {};
        const updatedUsersGames = {};
        const updatedPlayers = {};

        parseTournamentGetResponse(tournament, updatedUsersTournaments, updatedRounds, updatedGames, updatedUsersGames, updatedPlayers);
        
        this.setState({
            tournament: tournament,
            usersTournaments: updatedUsersTournaments,
            tournamentRoundIds: updatedRoundIds,
            tournamentRounds: updatedRounds,
            games: updatedGames,
            usersGames: updatedUsersGames,
            players: updatedPlayers
        });
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league});
    }

    updateEvent(eventData, key) {
        const event = NerdHerderDataModelFactory('event', eventData);
        this.setState({event: event});
    }

    updateUser(userData, userId) {
        this.updateUsersCache(userData);
    }

    updateUsersCache(userData) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {usersCache: {...state.usersCache, [newUser.id]: newUser}}
        });
    }

    updateContacts(contactsData, userId) {
        this.setState((state) => {
            return {contacts: {...state.contacts, [userId]: contactsData.contact_ids}}
        });
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal />);
        if (!this.state.tournament) return (<NerdHerderLoadingModal />);
        if (this.state.tournament.event_id && !this.state.event) return (<NerdHerderLoadingModal />);
        if (!this.state.league) return (<NerdHerderLoadingModal />);

        // only allow managers to see this stuff
        if (!this.state.tournament.isManager(this.state.localUser.id)) {
            return(<NerdHerderErrorModal errorFeedback='You are not a Tournament Manager'/>);
        }

        // if this league is archived and there is an attempt to manage the tournament, send the user back to the tournament page
        if (this.state.league.state === 'archived') return(<Navigate to={`/app/tournament/${this.state.tournament.id}`} replace={true}/>);

        let localUserIsManager = this.state.tournament.isManager(this.state.localUser.id);
        let localUserIsPlayer = this.state.tournament.isPlayer(this.state.localUser.id);
        let localuserIsMember = localUserIsManager || localUserIsPlayer;

        const allCardsProps = {
            tournament: this.state.tournament,
            tournamentRoundIds: this.state.tournamentRoundIds,
            tournamentRounds: this.state.tournamentRounds,
            games: this.state.games,
            usersTournaments: this.state.usersTournaments,
            usersGames: this.state.usersGames,
            usersCache: this.state.usersCache,
            players: this.state.players,
            event: this.state.event,
            league: this.state.league,
            isTournamentMember: localuserIsMember,
            localUser: this.state.localUser,
            contacts: this.state.contacts,
        }

        /* ok general layout goes like this
         * --------------------------
         * | NH header              |
         * |------------------------|
         * | bas  | rounds          |
         * | ics  | (accordian)     |
         * |------|                 |
         * | play |                 |
         * | ers  |                 |
         * |      |                 |
         * |      |                 |
         * |      |                 |
         * |      |                 |
         * |      |                 |
         * |      |                 |
         * --------------------------
         * - the basics section is 'read only' - there is a button to open a modal to change anything there
         * - the players section has 3 modes
         *   - view rankings w/t/l MP VPs etc.
         *   - adjust players (change starting score for swiss/round-robin and assign seeds for elim & drop players)
         *   - add players (bring in players from the league or event) this is where the option to match tournament to event/league lives
         * - the rounds section is an accordian, each round in an accordian with a little button on the bottom to add another round
         */
        return(
            <NerdHerderTwoColumnPageTemplate pageName='tournament_management' leftColumnSize={5} headerSelection='leagues' dropdownSelection={this.state.league.name} disableRefresh={false}
                                             navPath={[{icon: 'flaticon-team', label: this.state.league.name, href: `/app/league/${this.state.league.id}`},
                                                       {icon: 'flaticon-trophy-cup-black-shape', label: this.state.tournament.name, href: `/app/tournament/${this.state.tournament.id}`},
                                                       {icon: 'flaticon-configuration-with-gear', label: 'Manage', href: `/app/managetournament/${this.state.tournament.id}`}]}
                                             league={this.state.league} localUser={this.state.localUser}>
                <ManageTournamentConfigurationCard {...allCardsProps} nerdHerderTwoColumnPageTemplateSide='left'/>
                <ManageTournamentPlayersCard {...allCardsProps} nerdHerderTwoColumnPageTemplateSide='left'/>
                <ManageTournamentRoundsCard {...allCardsProps} nerdHerderTwoColumnPageTemplateSide='right'/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderTwoColumnPageTemplate>
        );
    }
}

class ManageTournamentConfigurationCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ManageTournamentConfigurationCard'>
                <ManageTournamentConfigurationCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ManageTournamentConfigurationCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            showEditTournamentModal: false,
            events: {},
        }
    }

    componentDidMount() {
        for (const eventId of this.props.league.event_ids) {
            const sub = this.restPubSub.subscribeNoRefresh('event', eventId, (d, k) => {this.updateSelectableEvent(d, k)}, null, eventId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateSelectableEvent(eventData, eventId) {
        const newEvent = NerdHerderDataModelFactory('event', eventData);
        this.setState((state) => {
            return {events: {...state.events, [eventId]: newEvent}}
        });
    }

    onCancelEditTournamentModal() {
        this.setState({showEditTournamentModal: false});
        this.restPubSub.refresh('tournament', this.props.tournament.id);
    }

    render() {
        // for the number of rounds we use the larger of num_rounds or how many rounds actually exist
        let numberOfRounds = this.props.tournament.num_rounds;
        if (this.props.tournament.type === 'elimination') {
            numberOfRounds = 'Determined by Bracket';
        } else {
            if (this.props.tournament.round_ids.length > numberOfRounds) numberOfRounds = this.props.tournament.round_ids.length;
        }

        let eventString = 'No Minor Event Assigned';
        if (this.props.tournament.event_id) {
            if (this.state.events.hasOwnProperty(this.props.tournament.event_id)) {
                eventString = this.state.events[this.props.tournament.event_id].name;
            }
        }

        const byeResultsString = this.props.tournament.getByeResultsString();
        
        // slight difference in button sizes if the require checkin option is on
        let buttonSize = 6;
        if (this.props.tournament.require_checkin) buttonSize = 4;

        return(
            <NerdHerderStandardCardTemplate id='tournament-configuration-card' title="Tournament Configuration" titleIcon="cogwheel.png">
                {this.state.showEditTournamentModal &&
                <NerdHerderEditTournamentModal onCancel={()=>this.onCancelEditTournamentModal()} tournament={this.props.tournament} league={this.props.league} localUser={this.props.localUser}/>}
                <Row>
                    <Col xs={12}>
                        <h4>{this.props.tournament.name}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <div>
                            <Image className="img-fluid rounded text-center" src={this.props.tournament.getImageUrl()}/>
                        </div>
                        <div className='text-center'>
                            {this.props.tournament.type === 'elimination' && this.props.subtype === 'single-elimination' &&
                            <b><small>Single Elimination</small></b>}
                            {this.props.tournament.type === 'elimination' && this.props.subtype === 'double-elimination' &&
                            <b><small>Double Elimination</small></b>}
                            {this.props.tournament.type === 'swiss' &&
                            <b><small>Swiss</small></b>}
                            {this.props.tournament.type === 'round-robin' &&
                            <b><small>Round-Robin</small></b>}
                        </div>
                        <div className='text-center'>
                            {this.props.tournament.type === 'swiss' && this.props.tournament.subtype === 'mp' &&
                            <small>Match Point Pairing</small>}
                            {this.props.tournament.type === 'swiss' && this.props.tournament.subtype === 'vp' &&
                            <small>Victory Point Pairing</small>}
                            {this.props.tournament.type === 'swiss' && this.props.tournament.subtype === 'mov' &&
                            <small>Margin of Victory Pairing</small>}
                            {this.props.tournament.type === 'round-robin' && this.props.tournament.subtype === 'mp' &&
                            <small>Match Point Scoring</small>}
                            {this.props.tournament.type === 'round-robin' && this.props.tournament.subtype === 'vp' &&
                            <small>Victory Point Scoring</small>}
                            {this.props.tournament.type === 'round-robin' && this.props.tournament.subtype === 'mov' &&
                            <small>Margin of Victory Scoring</small>}
                        </div>
                    </Col>
                    <Col>
                        <b><small>{this.props.tournament.summary}</small></b>
                        <Table size='sm'>
                            <tbody>
                                <tr><td><small>State</small></td><td><small>{this.props.tournament.getShortStatusString()}</small></td></tr>
                                <tr><td><small>Schedule</small></td><td><small>{this.props.tournament.getScheduleString()}</small></td></tr>
                                <tr><td><small>Event</small></td><td><small>{eventString}</small></td></tr>
                                <tr><td><small>Ranking</small></td><td><small>{this.props.tournament.getRankingString()}</small></td></tr>
                                <tr><td><small>Rounds</small></td><td><small>{numberOfRounds}</small></td></tr>
                                <tr><td><small>BYEs</small></td><td><small>{byeResultsString}</small></td></tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    {this.props.tournament.require_checkin &&
                    <Col className="my-1" sm={buttonSize}>
                        <div className="d-grid gap-2">
                            <Button variant="primary" size='sm' target='_blank' href={`/app/tournamentcheckin/${this.props.tournament.id}`}>Check-In Page</Button>
                        </div>
                    </Col>}
                    <Col className="my-1" sm={buttonSize}>
                        <div className="d-grid gap-2">
                            <Button variant="primary" size='sm' target='_blank' href={`/app/tournamenttimer/${this.props.tournament.id}`}>Real-Time Page</Button>
                        </div>
                    </Col>
                    <Col className="my-1" sm={buttonSize}>
                        <div className="d-grid gap-2">
                            <Button variant='primary' size='sm' onClick={()=>this.setState({showEditTournamentModal: true})}>Edit Configuration</Button>
                        </div>
                    </Col>
                </Row>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class ManageTournamentPlayersCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ManageTournamentPlayersCard'>
                <ManageTournamentPlayersCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ManageTournamentPlayersCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        let defaultMode = 'score';
        if (this.props.tournament.type === 'elimination') defaultMode = 'stats';
        if (this.props.tournament.player_ids.length === 0) defaultMode = 'add';
        
        this.requirementsTimeout = null;

        this.state = {
            updating: false,
            event: null,
            checkinJsxDict: {},
            listJsxDict: {},
            mode: defaultMode,
        }
    }

    componentDidMount() {
        // we are only subscribing so we can see when a refresh occurs and clear updating
        const sub = this.restPubSub.subscribeNoRefresh('tournament', this.props.tournament.id, (d, k)=>this.updateTournament(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, key) {
        const tournament = NerdHerderDataModelFactory('tournament', tournamentData);
        const checkinStatusDict = {};
        const checkinJsxDict = {};
        const listJsxDict = {};

        // look at who has checked in
        for (const usersTournaments of tournament.users_tournaments) {
            checkinStatusDict[usersTournaments.user_id] = usersTournaments.checked_in;
        }

        // setup the column contents
        const playerIds = [];
        for (const playerId of tournament.player_ids) {
            let missingListsString = '';
            playerIds.push(playerId);
            checkinJsxDict[playerId] = this.generateCheckinJsx(playerId, checkinStatusDict[playerId]);
            let allRequiredLists = true;
            let listCount = 0;
            let totalListCount = 0;
            for (const listContainer of tournament.list_containers) {
                totalListCount++;
                let hasIt = false
                if (listContainer.player_ids.includes(playerId)) hasIt = true;
                if (hasIt) listCount++;
                if (listContainer.required && !hasIt) {
                    allRequiredLists = false;
                    if (missingListsString.length !== 0) missingListsString += ', ';
                    missingListsString += listContainer.name;
                }
            }
            if (allRequiredLists) {
                listJsxDict[playerId] = <span className='cursor-default'><Badge bg='primary'>{`${listCount}/${totalListCount}`}</Badge></span>
            } else {
                listJsxDict[playerId] = 
                    <NerdHerderToolTip text={`Missing: ${missingListsString}`}>
                        <span className='cursor-help'>
                            <Badge bg='danger'>{`${listCount}/${totalListCount}`}</Badge>
                        </span>
                    </NerdHerderToolTip>
            } 
        }

        this.setState({checkinJsxDict: checkinJsxDict, listJsxDict: listJsxDict, updating: false});
    }

    handleModeChange(value) {
        this.setState({mode: value});

        // start refreshing the tournament players when we switch to the requirements tab
        if (this.requirementsTimeout === null && value === 'requirements') {
            this.requirementsTimeout = setTimeout(()=>this.handleRequirementsTimeout(), 3000);
        }
        
        // stop refreshing the tournament players when we switch away from the requirements tab
        if (this.requirementsTimeout !== null && value !== 'requirements') {
            clearTimeout(this.requirementsTimeout);
            this.requirementsTimeout = null;
        }
    }

    handleRequirementsTimeout() {
        console.debug('handleRequirementsTimeout()');
        this.requirementsTimeout = setTimeout(()=>this.handleRequirementsTimeout(), 30000);
        this.restApi.genericGetEndpointData('tournament', this.props.tournament.id)
        .then((response)=>{
            console.debug('handleRequirementsTimeout() updating tournament');
            let tournamentData = response.data;
            this.updateTournament(tournamentData);
        })
        .catch((error)=>{
            console.error('failed to refresh tournament players');
            console.error(error);
        });
    }

    generateCheckinJsx(playerId, checkedIn) {
        let result = null;
        if (checkedIn) {
            result = <span className='cursor-pointer' onClick={()=>this.updatePlayerCheckin(playerId, false)}><Badge bg='primary'><NerdHerderFontIcon icon='flaticon-verification-sign'/></Badge></span>;
        } else {
            result = <span className='cursor-pointer' onClick={()=>this.updatePlayerCheckin(playerId, true)}><Badge bg='danger'><NerdHerderFontIcon icon='flaticon-cross-sign'/></Badge></span>;
        }
        return(result);
    }

    onScoreInput(playerId, value) {
        const queryParams = {'user-id': playerId, 'tournament-id': this.props.tournament.id};
        const patchData = {metric: value};
        this.setState({updating: true});
        this.restApi.genericPatchEndpointData('user-tournament', null, patchData, queryParams)
        .then((response)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
        })
        .catch((error)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
            this.setState({updating: false});
        });
    }

    onSeedInput(playerId, value) {
        const queryParams = {'user-id': playerId, 'tournament-id': this.props.tournament.id};
        const patchData = {seed: value};
        this.setState({updating: true});
        this.restApi.genericPatchEndpointData('user-tournament', null, patchData, queryParams)
        .then((response)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
        })
        .catch((error)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
            this.setState({updating: false});
        });
    }

    onDroppedPlayer(playerId, event) {
        let checked = event.target.checked;
        const queryParams = {'user-id': playerId, 'tournament-id': this.props.tournament.id};
        const patchData = {dropped: checked};
        this.setState({updating: true});
        this.restApi.genericPatchEndpointData('user-tournament', null, patchData, queryParams)
        .then((response)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
        })
        .catch((error)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
            this.setState({updating: false});
        });
    }

    onDeletePlayer(playerId) {
        const queryParams = {'user-id': playerId, 'tournament-id': this.props.tournament.id};
        this.setState({updating: true});
        this.restApi.genericDeleteEndpointData('user-tournament', null, queryParams)
        .then((response)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
        })
        .catch((error)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
            this.setState({updating: false});
        });
    }

    onAddPlayer(playerId) {
        const queryParams = {'user-id': playerId, 'tournament-id': this.props.tournament.id};
        const postData = {
            tournament_id: this.props.tournament.id,
            user_id: playerId,
            seed: 0,
            metric: 0,
            dropped: false,
        };
        this.setState({updating: true});
        this.restApi.genericPostEndpointData('user-tournament', null, postData, queryParams)
        .then((response)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
        })
        .catch((error)=>{
            this.restPubSub.refresh('tournament', this.props.tournament.id);
            this.setState({updating: false});
        });
    }

    onAddAllLeaguePlayers(event) {
        let checked = event.target.checked;
        this.setState({updating: true});
        this.restPubSub.patch('tournament', this.props.tournament.id, {all_league_players: checked})
    }

    updatePlayerCheckin(userId, checkedIn) {
        console.debug('update player checkin for id', userId, checkedIn);
        const postData = {user_id: userId, checked_in: checkedIn};
        this.restApi.genericPostEndpointData('tournament-checkin', this.props.tournament.id, postData)
        .then((response)=>{
            console.debug('user checked in');
            this.restPubSub.refresh('tournament', this.props.tournament.id, 0);
        })
        .catch((error)=>{
            console.error('failed to change user checkin status');
            console.error(error);
        });

        // update the copy in state immediately
        const newJsx = this.generateCheckinJsx(userId, checkedIn);
        const newCheckinJsxDict = {...this.state.checkinJsxDict};
        newCheckinJsxDict[userId] = newJsx;
        this.setState({checkinJsxDict: newCheckinJsxDict});
    }

    render() {
        const tournament = this.props.tournament;
        let rankTable = null;
        let statsTable = null;
        let modifyTable = null;
        let addTable = null;
        let requirementsTable = null;
        let disableInputs = false;   

        const hasPlayedDict = {};
        const recordDict = {};
        const matchPointsDict = {};
        const vpDict = {};
        const movDict = {};
        const metricPerOpponentDict = {};
        const strengthOfScheduleDict = {};
        const numberOfByesDict = {};

        // there are no scores to show for elim tournaments
        let showScoreTab = true;
        if (tournament.type === 'elimination') showScoreTab = false;

        // normally we show the tab for checkin & lists - however if the tournament is done we don't, and if the option
        // for checkin is not used and there are no lists don't show it
        let showRequirementsTab = true;
        if (tournament.state === 'complete' ||
            (tournament.require_checkin === false && tournament.list_container_ids.length === 0)) showRequirementsTab = false;

        if (tournament.state === 'complete') {
            disableInputs = true;
            if (this.state.mode === 'add' || this.state.mode === 'modify') {
                setTimeout(()=>this.setState({mode: 'score'}), 10);
            }
        }
        
        if (this.state.mode === 'score') {
            const lastCompletedRound = getLastCompletedRound(this.props.tournament);
            tabulateTournamentData(tournament, lastCompletedRound, hasPlayedDict, recordDict, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict);
            const sortedUserIds = sortTournamentPlayers(tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict);

            // setup the column contents
            const rank = {};
            const rightColumnData = {};
            let rankIndex = 1;
            const playerIds = [];
            const droppedIds = [];
            let middleColumnData = {};
            let middleColumnTitle = null;
            let rightColumnTitle = null;

            // determine the tiebreakers - drop the first ranking because it's the tournament subtype & not a tiebreaker
            let tiebreakersList = parseRanking(tournament.ranking);
            if (tiebreakersList.length > 1) tiebreakersList.shift();
            // remove all instances of rec and na from tiebreakers
            let doRemove = true;
            while (doRemove) {
                doRemove = false;
                for (let i=0; i<tiebreakersList.length; i++) {
                    if (tiebreakersList[i] === 'rec' || tiebreakersList[i] === 'na') {
                        tiebreakersList.splice(i, 1);
                        doRemove = true;
                        break;
                    }
                }
            }
            // only want tiebreakers to be 3 long max
            if (tiebreakersList.length > 3) tiebreakersList = tiebreakersList.slice(0, 3);

            // the middle column title depends on the tournament subtype
            if (tournament.subtype === 'mp') {
                middleColumnTitle = 'MPs'
            } else if (tournament.subtype === 'vp') {
                middleColumnTitle = 'VPs';
            } else if (tournament.subtype === 'vp') {
                middleColumnTitle = 'MoV';
            } else {
                middleColumnTitle = '';
            }

            // the right column title depends on the tiebreakers
            const rightTitleList = [];
            for (const [i, tiebreaker] of tiebreakersList.entries()) {
                let titleJsx = null;
                let sep = null;
                if (i > 0) sep = ' / ';
                switch (tiebreaker) {
                    case 'mov':
                        titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                        break;
                    case 'mov1':
                        titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                        break;
                    case 'mov2':
                        titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                        break;
                    case 'mov3':
                        titleJsx = <span key={tiebreaker}>{sep}MoV</span>
                        break;
                    case 'sos':
                        titleJsx = <span key={tiebreaker}>{sep}SoS</span>
                        break;
                    case 'vp':
                        titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                        break;
                    case 'vp1':
                        titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                        break;
                    case 'vp2':
                        titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                        break;
                    case 'vp3':
                        titleJsx = <span key={tiebreaker}>{sep}VPs</span>
                        break;
                    default:
                        console.error(`hit unexpected tiebreaker condition ${tiebreaker}`);
                }
                rightTitleList.push(titleJsx);
            }
            if (rightTitleList.length === 0) {
                rightColumnTitle = '';
            } else {
                rightColumnTitle = <span>{rightTitleList}</span>
            }

            for (const playerId of sortedUserIds) {
                if (this.props.usersTournaments[playerId].dropped) {
                    droppedIds.push(playerId);
                } else {
                    playerIds.push(playerId);
                }
    
                // the middle column data is determined by the subtype of the tournament
                if (tournament.subtype === 'mp') {
                    middleColumnData[playerId] = <span>{matchPointsDict[playerId]}</span>
                } else if (tournament.subtype === 'vp') {
                    middleColumnData[playerId] = <span>{vpDict[playerId].score1}</span>
                } else if (tournament.subtype === 'mov'){
                    middleColumnData[playerId] = <span>{movDict[playerId].score1}</span>
                } else {
                    middleColumnData[playerId] = '';
                }
    
                // the right column data is determined by the tiebreakers
                const rightDataList = [];
                for (const [i, tiebreaker] of tiebreakersList.entries()) {
                    let jsx = null;
                    let sep = null;
                    if (i > 0) sep = ' / ';
                    let keyId = `${tiebreaker}-${playerId}`;
                    switch (tiebreaker) {
                        case 'mov':
                            jsx = <span key={keyId}><small>{sep}{movDict[playerId].score1}</small></span>
                            break;
                        case 'mov1':
                            jsx = <span key={keyId}><small>{sep}{movDict[playerId].score1}</small></span>
                            break;
                        case 'mov2':
                            jsx = <span key={keyId}><small>{sep}{movDict[playerId].score2}</small></span>
                            break;
                        case 'mov3':
                            jsx = <span key={keyId}><small>{sep}{movDict[playerId].score3}</small></span>
                            break;
                        case 'sos':
                            let sosString = strengthOfScheduleDict[playerId];
                            if (tournament.sos_method === 'average') sosString = strengthOfScheduleDict[playerId].toFixed(2);
                            jsx = <span key={keyId}><small>{sep}{sosString}</small></span>
                            break;
                        case 'vp':
                            jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score1}</small></span>
                            break;
                        case 'vp1':
                            jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score1}</small></span>
                            break;
                        case 'vp2':
                            jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score2}</small></span>
                            break;
                        case 'vp3':
                            jsx = <span key={keyId}><small>{sep}{vpDict[playerId].score3}</small></span>
                            break;
                        default:
                            console.error(`hit unexpected tiebreaker condition ${tiebreaker}`);
                    }
                    rightDataList.push(jsx);
                }
                if (rightDataList.length === 0) {
                    rightColumnData[playerId] = '';
                } else {
                    rightColumnData[playerId] = <span>{rightDataList}</span>
                }
    
                rank[playerId] = rankIndex;
                rankIndex++;
            }

            rankTable =
                <div>
                    <TableOfUsers userIds={playerIds} showDeleteButton={false} headers={['Rank', 'Player', middleColumnTitle, rightColumnTitle]}
                                    leftColumnContent={rank} middleColumnContent={middleColumnData} rightColumnContent={rightColumnData} localUser={this.props.localUser} emptyMessage='This tournament has no players - add some!'/>
                    {droppedIds.length !== 0 &&
                    <div className='my-3'>
                        <TableOfUsers title='Dropped Players' userIds={droppedIds} showDeleteButton={false} headers={['Rank', 'Player', middleColumnTitle, rightColumnTitle]}
                                        leftColumnContent={rank} middleColumnContent={middleColumnData} rightColumnContent={rightColumnData} localUser={this.props.localUser} emptyMessage='This tournament has no players - add some!'/>
                    </div>}
                </div>
        }

        if (this.state.mode === 'stats') {
            const lastCompletedRound = getLastCompletedRound(this.props.tournament);
            tabulateTournamentData(tournament, lastCompletedRound, hasPlayedDict, recordDict, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict);
            const sortedUserIds = sortTournamentPlayers(tournament, matchPointsDict, vpDict, movDict, metricPerOpponentDict, strengthOfScheduleDict);

            // setup the column contents
            const rank = {};
            const record = {};
            const byes = {};
            let rankIndex = 1;
            const playerIds = [];
            const droppedIds = [];
            for (const playerId of sortedUserIds) {
                if (this.props.usersTournaments[playerId].dropped) {
                    droppedIds.push(playerId);
                } else {
                    playerIds.push(playerId);
                }
                rank[playerId] = rankIndex;
                let wins = recordDict[playerId][0];
                let ties = recordDict[playerId][1];
                let loss = recordDict[playerId][2];
                record[playerId] = `${wins} / ${ties} / ${loss}`;
                byes[playerId] = numberOfByesDict[playerId];
                rankIndex++;
            }

            let rankOrSeedHeader = 'Rank';
            if (this.props.tournament.type === 'elimination') {
                rankOrSeedHeader = 'Seed';
            }

            rankTable =
                <div>
                    <TableOfUsers userIds={playerIds} showDeleteButton={false} headers={[rankOrSeedHeader, 'Player', 'W / T / L', 'BYEs']}
                                    leftColumnContent={rank} middleColumnContent={record} rightColumnContent={byes} localUser={this.props.localUser} emptyMessage='This tournament has no players - add some!'/>
                    {droppedIds.length !== 0 &&
                    <div className='my-3'>
                        <TableOfUsers title='Dropped Players' userIds={droppedIds} showDeleteButton={false} headers={[rankOrSeedHeader, 'Player', 'W / T / L', 'BYEs']}
                                        leftColumnContent={rank} middleColumnContent={record} rightColumnContent={byes} localUser={this.props.localUser} emptyMessage='This tournament has no players - add some!'/>
                    </div>}
                </div>
        }

        if (this.state.mode === 'modify') {
            const metric = {};
            const seed={};
            const dropped = {};
            let modifyTableInternalTable = null;

            // if the tournament has not really started, players can be deleted instead of dropped
            const numTournamentGames = this.props.tournament.game_ids.length;
            let canDeletePlayers = false;
            let disableModifySeeds = true;
            if (numTournamentGames === 0 && ['draft', 'posted'].includes(this.props.tournament.state) && !this.props.tournament.all_league_players) {
                canDeletePlayers = true;
            }

            // seeds can't be changed after any game has started
            if (numTournamentGames === 0 && ['draft', 'posted'].includes(this.props.tournament.state)) {
                disableModifySeeds = false;
            }

            for (const playerId of this.props.tournament.player_ids) {
                const usersTournaments = this.props.usersTournaments[playerId];
                const metricInput = <FormControlSubmit size='sm' disabled={this.state.updating} type='number' value={usersTournaments.metric} onClick={(v)=>this.onScoreInput(playerId, v)}/>
                //const metricInput = <Form.Control size='sm' disabled={this.state.updating} type='number' value={usersTournaments.metric} onChange={(e)=>this.handleScoreInputChange(playerId, e)}/>
                const seedInput = <FormControlSubmit size='sm'disabled={this.state.updating || disableModifySeeds} type='number' value={usersTournaments.seed} onClick={(v)=>this.onSeedInput(playerId, v)}/>
                const droppedInput = <Form.Check disabled={this.state.updating} onChange={(e)=>this.onDroppedPlayer(playerId, e)} checked={usersTournaments.dropped}/>
                metric[playerId] = metricInput;
                seed[playerId] = seedInput;
                dropped[playerId] = droppedInput;
            }

            if (this.props.tournament.type === 'elimination') {
                if (canDeletePlayers) {
                    modifyTableInternalTable =
                        <TableOfUsers userIds={this.props.tournament.player_ids} showDeleteButton={true} onDelete={(playerId)=>this.onDeletePlayer(playerId)} headers={['Player', 'Seed']} middleColumnContent={seed} localUser={this.props.localUser}/>
                } else {
                    modifyTableInternalTable =
                        <TableOfUsers userIds={this.props.tournament.player_ids} showDeleteButton={false} headers={['Player', 'Seed', 'Drop']} middleColumnContent={seed} rightColumnContent={dropped} localUser={this.props.localUser}/>
                }
            } else {
                const initialScoreJsx = <span>Initial Score <NerdHerderToolTipIcon title='Initial Score' message="Enter a value here to modify a player's score. This is most often used to include an handicap or initial score for players, however it can be used to modify scores as needed."/></span>
                const dropPlayerJsx = <span>Drop <NerdHerderToolTipIcon title='Drop Player' message="If a player needs to drop, tick their box below. Only affects pairing - if a player drops mid-game you should follow the game's organized play rules to score that game in addition to ticking this box."/></span>
                if (canDeletePlayers) {
                    modifyTableInternalTable =
                        <TableOfUsers userIds={this.props.tournament.player_ids} showDeleteButton={true} onDelete={(playerId)=>this.onDeletePlayer(playerId)} headers={['Player', initialScoreJsx]} middleColumnContent={metric} localUser={this.props.localUser}/>
                } else {
                    modifyTableInternalTable =
                        <TableOfUsers userIds={this.props.tournament.player_ids} showDeleteButton={false} headers={['Player', initialScoreJsx, dropPlayerJsx]} middleColumnContent={metric} rightColumnContent={dropped} localUser={this.props.localUser}/>
                }
            }

            modifyTable =
                <div>
                    <Form>
                        {modifyTableInternalTable}
                    </Form>
                </div>
        }

        if (this.state.mode === 'add') {
            let playerIdList = [];
            if (this.props.tournament.event_id === null) {
                playerIdList = [...this.props.league.player_ids];
            } else {
                if (this.props.event !== null) {
                    playerIdList = [...this.props.event.player_ids];
                }
            }

            const addDict = {};
            for (const playerId of playerIdList) {
                let disableAddButton = false;
                if (this.props.tournament.player_ids.includes(playerId)) disableAddButton = true;
                if (this.props.tournament.all_league_players) disableAddButton = true;
                if (disableInputs) disableAddButton = true;
                addDict[playerId] = <Button size='sm' variant='primary' disabled={this.state.updating || disableAddButton} onClick={()=>this.onAddPlayer(playerId)}><NerdHerderFontIcon icon='flaticon-add'/></Button>
            }

            let allLeaguePlayersLabel = `All ${this.props.league.getTypeWord()} players will play in this tournament`;
            if (this.props.tournament.event_id) allLeaguePlayersLabel = 'All event players will play in this tournament';

            addTable =
                        <div>
                            <Form>
                                <Form.Group className="form-outline my-2">
                                    <Form.Check label={allLeaguePlayersLabel} disabled={this.state.updating || disableInputs} onChange={(e)=>this.onAddAllLeaguePlayers(e)} checked={this.props.tournament.all_league_players}/>
                                </Form.Group>
                                <TableOfUsers userIds={playerIdList} showDeleteButton={false} headers={['Player', 'button']} rightColumnContent={addDict} localUser={this.props.localUser}/>
                            </Form>
                        </div>
        }

        if (this.state.mode === 'requirements') {
            requirementsTable =
                <div>
                    <Row className='mt-2'>
                        <Col sm={6} className='text-center'>
                            <small><Badge bg='primary'><NerdHerderFontIcon icon='flaticon-verification-sign'/></Badge> Player has checked-in</small>
                        </Col>
                        <Col sm={6} className='text-center'>
                            <small><Badge bg='danger'><NerdHerderFontIcon icon='flaticon-cross-sign'/></Badge> Player has not checked-in</small>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12} className='text-center'>
                            <small>Clicking the icon will toggle the player's check-in status</small>
                        </Col>
                    </Row>
                    <TableOfUsers userIds={Object.keys(this.state.checkinJsxDict)} showDeleteButton={false} headers={['Player', 'Lists', 'Checked-In']}
                                  middleColumnContent={this.state.listJsxDict} rightColumnContent={this.state.checkinJsxDict} localUser={this.props.localUser} emptyMessage='This tournament has no players'/>
                </div>
        }

        return(
            <NerdHerderStandardCardTemplate id='tournament-players-card' title="Tournament Players" titleIcon="team-management.png">
                <div className='d-grid gap-2'>     
                    <ToggleButtonGroup size='sm' name='tournament-players' type="radio" value={this.state.mode} onChange={(v)=>this.handleModeChange(v)}>
                        {showScoreTab &&
                        <ToggleButton variant='outline-primary' id='toggle-players-score' value={'score'}>Score</ToggleButton>}
                        <ToggleButton variant='outline-primary' id='toggle-players-stats' value={'stats'}>Record</ToggleButton>
                        {showRequirementsTab &&
                        <ToggleButton variant='outline-primary' id='toggle-players-reqs' value={'requirements'}>Requirements</ToggleButton>}
                        <ToggleButton variant='outline-primary' id='toggle-players-modify' disabled={disableInputs} value={'modify'}>Modify</ToggleButton>
                        <ToggleButton variant='outline-primary' id='toggle-players-add' disabled={disableInputs} value={'add'}>Add</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                {rankTable}
                {statsTable}
                {modifyTable}
                {addTable}
                {requirementsTable}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class ManageTournamentRoundsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ManageTournamentRoundsCard'>
                <ManageTournamentRoundsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ManageTournamentRoundsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.isElimination = false;
        this.generatePairingsTimeout = null;
        this.countdownRef = React.createRef();
        this.setKeyToDraftRoundIndex = null;
        this.pairedUsersTournaments = [];
        this.hasPlayedDict = {};
        if (this.props.tournament.type === 'elimination') this.isElimination = true;

        const initialFormErrorMessage = {};
        for (const roundId of this.props.tournamentRoundIds) {
            initialFormErrorMessage[roundId] = null;
        }

        this.state = {
            updating: false,
            activeKey: null,
            checkAutoStartRound: false,
            showAddGameModal: false,
            showAutoStartPromptModal: false,
            showConfirmGameDeleteModal: false,
            showCompleteTournamentModal: false,
            addGameRoundName: null,
            addGameTableName: null,
            roundErrorMessages: initialFormErrorMessage,
            currentRoundUnassignedList: [],
            currentRoundPairings: null,
            currentRoundPairingsModified: false,
            currentRoundPairingsIndex: null,
            currentRoundSeeds: null,
            pairingMessages: [],
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeSilently('tournament', this.props.tournament.id, (d, k)=>{this.updateTournament(d, k)});
        for (const roundId of this.props.tournamentRoundIds) {
            sub = this.restPubSub.subscribeSilently('tournament-round', roundId, (d, k)=>{this.updateTournamentRound(d, k)}, (e, k)=>{this.tournamentRoundError(e, k)}, roundId);
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTournament(tournamentData, key) {
        // don't actually update the tournament, just clear updating flag and update form values
        const initialFormErrorMessage = {};
        for (const roundId of this.props.tournamentRoundIds) {
            initialFormErrorMessage[roundId] = null;
        }

        // need to refresh the current round games, there could be lots of games, don't flood the server
        const currentTournamentRound = getCurrentRound(tournamentData)
        if (currentTournamentRound) {
            let delay = 0;
            for (const gameId of currentTournamentRound.game_ids) {
                this.restPubSub.refresh('game', gameId, delay);
                delay += 20;
            }
        }

        // for currentRoundPairings, normally we want to keep them, but if there is a change in the round (or there
        // isn't a current round) or a change in the players reset them
        let firstDraftRound = geFirstDraftRound(tournamentData);
        let newCurrentRoundPairings = this.state.currentRoundPairings;
        let newActiveKey = this.state.activeKey;
        if (!firstDraftRound || this.state.currentRoundPairingsIndex === null || firstDraftRound.index !== this.state.currentRoundPairingsIndex) {
            newCurrentRoundPairings = null;
        } else if (tournamentData.users_tournaments.length !== this.pairedUsersTournaments.length) {
            newCurrentRoundPairings = null;
        } else {
            for (let i=0; i<this.pairedUsersTournaments.length; i++) {
                const newUsersTournaments = tournamentData.users_tournaments[i];
                const oldUsersTournaments = this.pairedUsersTournaments[i];
                if (newUsersTournaments.user_id !== oldUsersTournaments.user_id) {
                    newCurrentRoundPairings = null;
                    break;
                }
                else if (newUsersTournaments.seed !== oldUsersTournaments.seed) {
                    newCurrentRoundPairings = null;
                    break;
                }
                else if (newUsersTournaments.metric !== oldUsersTournaments.metric) {
                    newCurrentRoundPairings = null;
                    break;
                }
                else if (newUsersTournaments.dropped !== oldUsersTournaments.dropped) {
                    newCurrentRoundPairings = null;
                    break;
                }
            }
        }

        // if we are waiting on a new draft round to be populated so we can switch to it, and this is that round, set
        // the active key
        if (this.setKeyToDraftRoundIndex !== null && firstDraftRound && firstDraftRound.index === this.setKeyToDraftRoundIndex) {
            newActiveKey = firstDraftRound.id;
        }
        this.setKeyToDraftRoundIndex = null;

        this.setState({
            updating: false,
            roundErrorMessages: initialFormErrorMessage,
            currentRoundPairings: newCurrentRoundPairings,
            activeKey: newActiveKey,
        });
    }

    updateTournamentRound(tournamentRoundData, roundId) {
        if (tournamentRoundData !== 'DELETED') {
            // don't actually update the round, just clear updating flag and force a tournament refresh
            this.setState({updating: false});
            this.setState((state) => {
                return {roundErrorMessages: {...state.roundErrorMessages, [roundId]: null}}
            });
        }

        this.restPubSub.refresh('tournament', this.props.tournament.id);
    }

    tournamentRoundError(error, roundId) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            let errorString = '';
            if (formErrors.hasOwnProperty('name')) errorString += `Name: ${formErrors.name} `;
            if (formErrors.hasOwnProperty('date')) errorString += `Date: ${formErrors.date} `;
            if (formErrors.hasOwnProperty('time')) errorString += `Time: ${formErrors.time} `;
            if (formErrors.hasOwnProperty('duration')) errorString += `Duration: ${formErrors.duration} `;
            if (errorString.length === 0) errorString = 'There is an error in the form.';
            this.setState((state) => {
                return {roundErrorMessages: {...state.roundErrorMessages, [roundId]: errorString}}
            });
        }
        // caught this error, keep it from going up
        this.setState({updating: false});
        return true;
    }

    generatePairings(roundData) {
        const tournament = this.props.tournament;
        const tournamentRound = roundData;
        const hasPlayedDict = {};
        const recordDict = {};
        const matchPointsDict = {};
        const scoreDict = {};
        const movDict = {};
        const metricPerOpponentDict = {};
        const strengthOfScheduleDict = {};
        const numberOfByesDict = {};
        const pairingMessages = [];
        tabulateTournamentData(tournament, tournamentRound, hasPlayedDict, recordDict, matchPointsDict, scoreDict,  movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict);
        const pairings = generatePairings(tournament, tournamentRound, hasPlayedDict, recordDict, matchPointsDict, scoreDict, movDict, metricPerOpponentDict, strengthOfScheduleDict, numberOfByesDict, this.props.contacts, pairingMessages);
        for (const pairing of pairings) {
            pairing.playersList = [pairing.user1Id, pairing.user2Id];
        }
        this.pairedUsersTournaments = this.props.tournament.users_tournaments;
        this.setState({currentRoundPairings: pairings, currentRoundPairingsModified: false, currentRoundPairingsIndex: roundData.index, pairingMessages: pairingMessages});
        this.generatePairingsTimeout = null;
        this.hasPlayedDict = hasPlayedDict;
    }

    generateSeeds(roundData) {
        const tournament = this.props.tournament;
        const seedsUsed = [];
        const playersList = [];
        
        // first get a legit players list
        for (const usersTournaments of tournament.users_tournaments) {
            if (usersTournaments.dropped === false) playersList.push(usersTournaments.user_id);
        }
        let numPlayers = playersList.length;

        // next throw out any invalid seed values
        for (const usersTournaments of tournament.users_tournaments) {
            if (usersTournaments.dropped) continue;
            // don't allow out-of-range seeds
            if (usersTournaments.seed > numPlayers || usersTournaments.seed < 0) usersTournaments.seed = 0;
            // don't allow duplicate seeds
            if (seedsUsed.includes(usersTournaments.seed)) usersTournaments.seed = 0;
            // if the seed is legit then save it so we don't reuse it
            // eslint-disable-next-line eqeqeq
            if (usersTournaments.seed != 0) seedsUsed.push(usersTournaments.seed);
        }

        // next assign a seed value to players without one
        for (const usersTournaments of tournament.users_tournaments){
            if (usersTournaments.dropped) continue;
            // try to randomly assign a seed value
            // eslint-disable-next-line eqeqeq
            if (usersTournaments.seed == 0) {
                for (let attempt=0; attempt<20; attempt++) {
                    let randomSeedValue = getRandomInteger(1, numPlayers+1);
                    if (!seedsUsed.includes(randomSeedValue)) {
                        usersTournaments.seed = randomSeedValue;
                        seedsUsed.push(usersTournaments.seed);
                        break;
                    }
                }
            }
            // if we failed to randomly generate a workable seed randomly, just assign the next free value
            // eslint-disable-next-line eqeqeq
            if (usersTournaments.seed == 0) {
                for (let freeSeedValue=1; freeSeedValue<numPlayers+1; freeSeedValue++) {
                    if (!seedsUsed.includes(freeSeedValue)) {
                        usersTournaments.seed = freeSeedValue;
                        seedsUsed.push(usersTournaments.seed);
                        break;
                    }
                }
            }
        }

        // finally build this all into a simple list of user ids
        const seedList = [];
        for (let i=0; i<seedList; i++) {
            seedList.push(0);
        }
        for (const usersTournaments of tournament.users_tournaments){
            if (usersTournaments.dropped) continue;
            seedList[usersTournaments.seed-1] = usersTournaments.user_id;
        }
        this.setState({currentRoundSeeds: seedList});
    }

    checkAutoStartRound(roundData) {
        if (roundData.state === 'initialized' && roundData.duration === null) {
            this.setState({showAutoStartPromptModal: true, checkAutoStartRound: false});
        } else {
            this.setState({checkAutoStartRound: false});
        }
    }

    onPairingDragEnd(result) {
        const newRoundPairings = [...this.state.currentRoundPairings];
        const newUnassignedList = [...this.state.currentRoundUnassignedList];
        let playerId = null;
        if (result.draggableId.includes('user-0-')) {
            playerId = 0;
        } else {
            playerId = result.draggableId.replace('user-','');
            playerId = parseInt(playerId);
        }
        let srcList = null;
        let dstList = null;

        // the source could be a table, or could be the unassigned list
        if (result.source.droppableId.includes('table-')) {
            let srcTableIndex = result.source.droppableId.replace('table-','');
            srcTableIndex = parseInt(srcTableIndex);
            srcList = newRoundPairings[srcTableIndex].playersList;
        } else if (result.source.droppableId.includes('unassigned')) {
            srcList = newUnassignedList;
        } else {
            // not sure where this came from...
            return;
        }

        // dropped outside the list - add player to end of unassigned list
        if (!result.destination) {
            srcList.splice(result.source.index, 1);
            // don't allow the user to add byes to the unassigned list
            if (playerId !== 0) newUnassignedList.push(playerId);
        }
        // dropped into a list, but could be unassigned list
        else {
            if (result.destination.droppableId.includes('table-')) {
                let dstTableIndex = result.destination.droppableId.replace('table-','');
                dstTableIndex = parseInt(dstTableIndex);
                dstList = newRoundPairings[dstTableIndex].playersList;
            } else if (result.source.droppableId.includes('unassigned')) {
                dstList = newUnassignedList;
            } else {
                // not sure where this is going to...
                return;
            }

            srcList.splice(result.source.index, 1);
            // don't allow the user to add byes to the unassigned list
            if (playerId === 0 && dstList === newUnassignedList) return;
            dstList.splice(result.destination.index, 0, playerId);
        }

        // go through all the pairings lists - if there are lists < 2 players add byes, if there are > 2 players with byes remove byes
        for (const table of newRoundPairings) {
            const playersList = table.playersList;
            while (playersList.length < 2) {
                playersList.push(0);
            }
            while (playersList.length > 2) {
                let removedItem = false;
                for (let i=0; i<playersList.length; i++) {
                    let playerId = playersList[i];
                    if (playerId === 0) {
                        playersList.splice(i, 1);
                        removedItem = true;
                        break;
                    }
                }

                if (!removedItem) break;
            }
        }

        this.setState({currentRoundPairings: newRoundPairings, currentRoundUnassignedList: newUnassignedList, currentRoundPairingsModified: true});
    }

    onSeedDragEnd(result) {
        const newRoundSeeds = [...this.state.currentRoundSeeds];
        let playerId = result.draggableId.replace('user-','');
        playerId = parseInt(playerId);

        // dropped outside the list - add player to end of list
        if (!result.destination) {
            newRoundSeeds.splice(result.source.index, 1);
        }
        // dropped into a list
        else {
            newRoundSeeds.splice(result.source.index, 1);
            newRoundSeeds.splice(result.destination.index, 0, playerId);
        }

        this.setState({currentRoundSeeds: newRoundSeeds, currentRoundSeedsModified: true});
    }

    onAddGame(roundId) {
        const roundData = this.props.tournamentRounds[roundId];
        const tableName = `Table ${roundData.game_ids.length + 1}`;
        this.setState({showAddGameModal: true, currentRoundId: roundId, addGameTableName: tableName, addGameRoundName: roundData.name});
    }

    onAddGameCancel() {
        this.setState({showAddGameModal: false, addGameTableName: null, addGameRoundName: null});
        this.restPubSub.refresh('league-alerts', this.props.league.id, 200);
        this.restPubSub.refresh('tournament', this.props.tournament.id, 300);
        if (this.props.event) this.restPubSub.refresh('event', this.props.event.id, 400);
        this.restPubSub.refresh('header-alerts', null, 500);
    }

    onTimerComplete(roundId) {
        this.restPubSub.refresh('tournament', this.props.tournament.id, 1000);
    }

    handleRoundNameChange(roundId, value) {
        if (value.length < 1 || value.length > 25) {
            this.setState((state) => {
                return {roundErrorMessages: {...state.roundErrorMessages, [roundId]: 'Round name must be between 1 and 20 characters'}}
            });
        } else {
            this.setState({updating: true});
            this.restPubSub.patch('tournament-round', roundId, {name: value});
        }
    }

    handleRoundDateChange(roundId, value) {
        this.setState({updating: true});
        const value2 = value === '' ? null : value;
        this.restPubSub.patch('tournament-round', roundId, {date: value2});
    }

    handleRoundTimeChange(roundId, value) {
        this.setState({updating: true});
        const value2 = value === '' ? null : value;
        this.restPubSub.patch('tournament-round', roundId, {time: value2});
    }

    handleRoundDurationChange(roundId, value) {
        this.setState({updating: true});
        const value2 = value === '' ? null : value * 60;
        this.restPubSub.patch('tournament-round', roundId, {duration: value2});
    }

    handleTableNameChange(index, event) {
        const value = event.target.value;
        const newRoundPairings = [...this.state.currentRoundPairings];
        const tableDict = newRoundPairings[index];
        tableDict.tableName = value;
        this.setState({currentRoundPairings: newRoundPairings, currentRoundPairingsModified: true});
    }

    onAddTable(roundId) {
        let tableName = `Table ${this.state.currentRoundPairings.length+1}`;
        const newTable = buildTableDict(0, 0, tableName, 0, '')
        this.setState({currentRoundPairings: [...this.state.currentRoundPairings, newTable]})
    }

    onAcceptPairings(roundId) {
        // server uses a slightly different table dict format
        const serverPairings = [];
        for (const pairing of this.state.currentRoundPairings) {
            const newPairing = {table_name: pairing.tableName, game_id: pairing.gameId, elim_code: pairing.elimCode};
            if (pairing.playersList.length >= 2) {
                newPairing.user1_id = pairing.playersList[0];
                newPairing.user2_id = pairing.playersList[1];
            } else if (pairing.playersList.length === 1) {
                newPairing.user1_id = pairing.playersList[0];
                newPairing.user2_id = 0;
            }
            serverPairings.push(newPairing);
        }
        this.setState({updating: true, checkAutoStartRound: true});
        const patchData = {state: 'initialized', init_pairings: serverPairings};
        this.restPubSub.patch('tournament-round', roundId, patchData);
    }

    onAcceptSeeds(roundId) {
        this.setState({updating: true, checkAutoStartRound: true});
        const patchData = {state: 'initialized', init_seeds: this.state.currentRoundSeeds};
        this.restPubSub.patch('tournament-round', roundId, patchData);
    }

    onRevertPairings(roundId) {
        const thisRoundData = this.props.tournamentRounds[roundId];
        if (this.generatePairingsTimeout) clearTimeout(this.generatePairingsTimeout);
        this.generatePairingsTimeout = setTimeout(()=>this.generatePairings(thisRoundData), 1200);
        this.setState({currentRoundPairings: null, currentRoundPairingsIndex: null});
    }

    onRevertSeeds(roundId) {
        const thisRoundData = this.props.tournamentRounds[roundId];
        this.generateSeeds(thisRoundData);
    }

    onAcceptElimRound(roundId) {
        this.setState({updating: true, checkAutoStartRound: true, showAutoStartPromptModal: false});
        const patchData = {state: 'initialized'};
        this.restPubSub.patch('tournament-round', roundId, patchData);
    }

    onStartRound(roundId) {
        this.setState({updating: true, showAutoStartPromptModal: false});
        const patchData = {state: 'in-progress'};
        this.restPubSub.patch('tournament-round', roundId, patchData);

        // if the round is real time, reset the Time Remaining control
        if (this.props.tournamentRounds[roundId].duration !== null && this.countdownRef.current) {
            console.debug(`setting Time Remaining to ${this.props.tournamentRounds[roundId].duration} due to round starting`);
            this.countdownRef.current.setRemaining(this.props.tournamentRounds[roundId].duration);
        }
    }

    onCompleteRound(roundId) {
        this.setState({updating: true});
        const patchData = {state: 'complete'};
        this.restPubSub.patch('tournament-round', roundId, patchData);
    }

    onRevertRound(roundId, revertedState) {
        if (revertedState === 'draft-prompt') {
            this.setState({showConfirmGameDeleteModal: true});
        } else {
            this.setState({updating: true, showConfirmGameDeleteModal: false});
            const patchData = {state: revertedState};
            this.restPubSub.patch('tournament-round', roundId, patchData);
        }
    }

    onOverrideReviews(roundId) {
        this.setState({updating: true});
        const needsOverrideGames = [];
        for (const gameId of this.props.tournamentRounds[roundId].game_ids) {
            for (const playerId of this.props.tournament.player_ids) {
                const ugKey = usersGamesKey(playerId, gameId);
                if (this.props.usersGames.hasOwnProperty(ugKey)) {
                    if (this.props.usersGames[ugKey].concur_with_results === false) {
                        needsOverrideGames.push(gameId);
                        break;
                    }
                }
            }   
        }

        let delay = 0;
        for (const gameId of needsOverrideGames) {
            setTimeout(()=>this.doConcurOverride(gameId), delay);
            delay += 50;
        }
        this.restPubSub.refresh('tournament', this.props.tournament.id, delay + 2000);
    }

    doConcurOverride(gameId) {
        this.restPubSub.patch('game', gameId, {force_concur: true});
    }

    onAddRound() {
        const roundIndex = this.props.tournament.round_ids.length + 1;
        const newRound = NerdHerderDataModelFactory('tournament-round', null, this.props.tournament.id, roundIndex);
        // if there is a previous round, initialize this round's info from that one
        const lastRound = getLastRound(this.props.tournament);
        if (lastRound) {
            newRound.duration = lastRound.duration;
            newRound.date = lastRound.date;
            if (lastRound.date && lastRound.time && lastRound.duration) {
                let luxonDatetime = DateTime.fromISO(`${lastRound.date}T${lastRound.time}`, {zone: new SystemZone()});
                let offsetMinutes = parseInt(lastRound.duration/60) + 15;
                luxonDatetime = luxonDatetime.plus({minutes: offsetMinutes});
                newRound.time = luxonDatetime.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            }
        }
        if (newRound.date === null) newRound.date = this.props.tournament.date;
        if (newRound.date === null) newRound.date = getDateIsoFormat();
        this.setKeyToDraftRoundIndex = roundIndex;
        this.restPubSub.postAndSubscribe('tournament-round', 'id', newRound, (d, k)=>{this.updateTournamentRound(d, k)}, (e, k)=>this.tournamentRoundError(e, k), 'id');
    }

    onDeleteRound(roundId) {
        this.setState({updating: true});
        this.restPubSub.delete('tournament-round', roundId);
        this.restPubSub.refresh('tournament', this.props.tournament.id, 500);
    }

    onCompleteTournament() {
        this.setState({showCompleteTournamentModal: true});
    }

    onCancelCompleteTournament() {
        this.setState({showCompleteTournamentModal: false});
    }

    onKeySelect(key) {
        this.setState({activeKey: key})
    }

    render() {
        const accordionItems = [];
        const lastRoundIndex = this.props.tournamentRoundIds.length;
        let roundData = null;
        let prevRoundData = null;
        let nextRoundData = null;
        let disableNextButton = false;
        let currentRound = getCurrentRound(this.props.tournament);
        let firstDraftRound = geFirstDraftRound(this.props.tournament);
        let currentRoundId = null;
        let firstDraftRoundId = null;
        let defaultActiveKey = null;
        let activeKey = this.state.activeKey;
        let isElimination = this.isElimination;

        // the current round is the latest round not in the draft state (unless there is only 1 round, and its in the draft state)
        if (currentRound !== null) {
            currentRoundId = currentRound.id;
            defaultActiveKey = currentRoundId;
        }
        // the first draft round is the first round in the draft state, unless there are no rounds in the draft state, then its null
        // we want the default round shown to the manger to be the first draft round if all earlier rounds are complete - otherwise we want it to be the current round
        if (firstDraftRound !== null) {
            firstDraftRoundId = firstDraftRound.id;
            if (defaultActiveKey === null || currentRound.state === 'complete') {
                defaultActiveKey = firstDraftRoundId;
            }
        }

        // set the active key (which accordian section to open)
        // if there is no active key or the key is invalid, take the default
        if (activeKey === null || !this.props.tournamentRoundIds.includes(activeKey)) {
            activeKey = defaultActiveKey;
        }

        for (const roundId of this.props.tournamentRoundIds) {
            prevRoundData = roundData;
            roundData = this.props.tournamentRounds[roundId];
            nextRoundData = getTournamentRound(this.props.tournament, roundData.index+1);
            let createPairings = false;
            let createSeeds = false;
            let showAcceptPairingsButton = false;
            let showAcceptSeedsButton = false;
            let showAcceptElimRoundButton = false;
            let disableRevertButton = true;
            let showStartButton = false;
            let showCompleteButton = false;

            // the time can sometimes end up in HH:MM:SS format, but we only want HH:MM format - use a cleaned up copy for rendering
            let roundTime = roundData.time;
            if (typeof roundData.time !== 'undefined' && roundData.time !== null) {
                let roundTimeArray = roundTime.split(':');
                if (roundTimeArray.length >= 2) {
                    roundTime = `${roundTimeArray[0]}:${roundTimeArray[1]}`;
                }
            }

            // if we have just transistioned into initialized, but there is no game duration set, see if the manager just wants to start the round
            if (this.state.checkAutoStartRound && roundData.state === 'initialized') {
                const thisRoundData = roundData;
                setTimeout(()=>this.checkAutoStartRound(thisRoundData), 10);
            }

            // the final round gets a delete button
            let isFinalRound = false;
            // eslint-disable-next-line eqeqeq
            if (lastRoundIndex == roundData.index) isFinalRound = true;

            // for round-robin and swiss, if this round is draft & this is the first round or the previous round is complete: create pairings
            if (!isElimination && (prevRoundData === null || prevRoundData.state === 'complete') && roundData.state === 'draft') {
                createPairings = true;
                showAcceptPairingsButton = true;
            }

            // for elimination, if this is the first round and its in draft: create seeds
            if (isElimination && prevRoundData === null && roundData.state === 'draft') {
                createSeeds = true;
                showAcceptSeedsButton = true;
            }

            // for elimination, if this is not the first round and its in draft: accept elim round??
            if (isElimination && prevRoundData !== null && roundData.state === 'draft') {
                showAcceptElimRoundButton = true;
            }
            
            // if this round is initialized (seeds/pairings are created) & this is the first round or the previous round is complete: start round
            if ((prevRoundData === null || prevRoundData.state === 'complete') && roundData.state === 'initialized') {
                showStartButton = true;
                disableRevertButton = false;
            }

            // if this round is in-progress (games are running) & this is the first round or the previous round is complete: complete round
            if ((prevRoundData === null || prevRoundData.state === 'complete') && roundData.state === 'in-progress') {
                showCompleteButton = true;
                disableRevertButton = false;
            }

            // if this round is in something other than draft and either there is no next round or it is in draft then it is possible to revert this round
            if (disableRevertButton === true && roundData.state !== 'draft' && (nextRoundData === null || nextRoundData.state === 'draft')) {
                disableRevertButton = false;
            }

            if (this.props.tournament.state === 'complete') disableRevertButton = true;

            const pairingTableRows = [];
            let pairingErrorAlert = null;
            let creatingPairingsSpinner = null;
            if (createPairings) {
                // if pairings aren't created, quickly generate them
                if (this.state.currentRoundPairings === null) {
                    const thisRoundData = roundData;
                    if (this.generatePairingsTimeout) clearTimeout(this.generatePairingsTimeout);
                    this.generatePairingsTimeout = setTimeout(()=>this.generatePairings(thisRoundData), 1200);
                    creatingPairingsSpinner = 
                        <div className='text-center'>
                            <Spinner variant='primary' animation="border" role="status" style={{height: 60, width: 60}}>
                                <span className="visually-hidden">Loading Pairings...</span>
                            </Spinner>
                        </div>
                } else {
                    let gameIndex = 0;
                    for (const gameDict of this.state.currentRoundPairings) {
                        const thisGameIndex = gameIndex;
                        const playerItems = gameDict.playersList.map((userId, index)=>{
                            return <TablePlayerItem key={`${userId}-${index}`} user={this.props.players[userId]} index={index}/>
                        });

                        let borderString = '2px solid #dee2e6';
                        let formText = null;

                        if (this.props.tournament.type === 'swiss' && this.props.tournament.top_table_finals && 
                            gameIndex === 0 && roundData.index === this.props.tournament.num_rounds) {
                            formText = 'This table is playing the Championship Match';
                        }

                        if (playerItems.length > 2) {
                            borderString = '3px solid #dc3545';
                            disableNextButton = true;
                            formText = 'Tables may only be assigned 2 players';
                        }

                        if (playerItems.length === 2 && gameDict.playersList[0] !== 0 && gameDict.playersList[1] !== 0 && this.hasPlayedDict) {
                            let user1Id = gameDict.playersList[0];
                            let user2Id = gameDict.playersList[1];
                            if (this.hasPlayedDict.hasOwnProperty(user1Id) && this.hasPlayedDict[user1Id].includes(user2Id)) {
                                borderString = '3px solid #ffc107';
                                formText = 'These players have played each other already';
                            }     
                        }

                        if (playerItems.length === 2 && gameDict.playersList[0] === 0 && gameDict.playersList[1] === 0) {
                            formText = 'This table will not be assigned any games this round';
                        }

                        const newTable = 
                            <Row key={gameIndex}>
                                <Col>
                                    <div className='my-2 p-2' style={{border: borderString, borderRadius: '5px'}}>
                                        <Form>
                                            <Form.Group className="form-outline mb-1">
                                                <Form.Control type="text" placeholder="Name the table..." disabled={this.state.updating} onChange={(e)=>this.handleTableNameChange(thisGameIndex, e)} autoComplete='off' value={gameDict.tableName} minLength={1} maxLength={20} required/>
                                            </Form.Group>
                                            <Form.Group className="form-outline mb-3">
                                                <Droppable droppableId={`table-${gameIndex}`}>
                                                    {(provided, snapshot) => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                        {playerItems}
                                                        {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </Form.Group>
                                            {formText && 
                                            <Form.Group className="form-outline mb-1">
                                                <Form.Text muted>{formText}</Form.Text>
                                            </Form.Group>}
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        pairingTableRows.push(newTable);
                        gameIndex++;
                    }

                    // if there are unassigned players add them to a special table
                    if (this.state.currentRoundUnassignedList.length !== 0) {
                        const playerItems = this.state.currentRoundUnassignedList.map((userId, index)=>{
                            return <TablePlayerItem key={`${userId}-${index}`} user={this.props.players[userId]} index={index}/>
                        });
                        const unassignedTable = 
                            <Row key={gameIndex}>
                                <Col>
                                    <div className=' my-2 p-2' style={{border: '2px solid #ffc107', borderRadius: '5px'}}>
                                        <Form>
                                            <Form.Group className="form-outline mb-1">
                                                <Form.Label>Unassigned Players</Form.Label>
                                            </Form.Group>
                                            <Form.Group className="form-outline mb-3">
                                                <Droppable droppableId={'unassigned'}>
                                                    {(provided, snapshot) => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                        {playerItems}
                                                        {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </Form.Group>
                                            <Form.Group className="form-outline mb-1">
                                                <Form.Text muted>These players will not be assigned a table or game this round</Form.Text>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        pairingTableRows.push(unassignedTable);
                    }

                    if (this.state.currentRoundPairings.length === 0) {
                        pairingErrorAlert = <Alert className='my-3' variant='warning'>No tables paired - do you have players added to the tournament?</Alert>
                    }

                    if (this.state.pairingMessages.length !== 0) {
                        const pairingMessageRows = [];
                        let index = 0;
                        for (const message of this.state.pairingMessages) {
                            let className = 'text-primary'
                            if (message.includes('TO pair manually')) className = 'text-danger';
                            const row = <div key={index}><small><small className={className}>{message}</small></small></div>
                            pairingMessageRows.push(row);
                            index++;
                        }
                        const pairingMessagesBox =
                            <Row key='pm'>
                                <Col>
                                    <div className='my-2 p-2' style={{border: '2px solid #dee2e6', borderRadius: '5px'}}>
                                        <Form>
                                            <Form.Group className="form-outline mb-1">
                                                <Form.Label>Pairing Concerns</Form.Label>
                                                <div>
                                                    <Form.Text muted>Issues occured...probably due to having too few players / too many rounds, or a few players having a very bad day.</Form.Text>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-outline mb-3">
                                                {pairingMessageRows}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        pairingTableRows.push(pairingMessagesBox);
                    }
                }
            }

            
            let seedTable = null;
            if (createSeeds) {
                // if pairings aren't created, quickly generate them
                if (this.state.currentRoundSeeds === null) {
                    const thisRoundData = roundData;
                    setTimeout(()=>this.generateSeeds(thisRoundData), 0);
                } else {
                    let index = 0;
                    const playerItems = [];
                    for (const userId of this.state.currentRoundSeeds) {
                        const playerItem = <TablePlayerItem key={userId} user={this.props.players[userId]} index={index}/>
                        playerItems.push(playerItem);
                        index++;
                    }
                    seedTable =
                        <div className='my-2 p-2' style={{border: '2px solid #dee2e6', borderRadius: '5px'}}>
                            <Droppable droppableId='tournament-seeds'>
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                    {playerItems}
                                    {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                }
            }

            // split pairing table rows into 2 columns
            const pairingTableRows1 = [];
            const pairingTableRows2 = [];
            for (let i=0; i<pairingTableRows.length; i++) {
                const row = pairingTableRows[i];
                if (i < pairingTableRows.length / 2) {
                    pairingTableRows1.push(row);
                } else {
                    pairingTableRows2.push(row);
                }
            }

            // if we're not creating pairings or seeds, then show the games
            const gameTableListItems1 = [];
            const gameTableListItems2 = [];
            if (!createPairings && !createSeeds) {
                const numItems = roundData.game_ids.length;
                for (let i=0; i<numItems; i++) {
                    const gameId = roundData.game_ids[i];
                    const gameListItem = <GameListItem key={gameId} gameId={gameId} game={this.props.games[gameId]} tournament={this.props.tournament} league={this.props.league} localUser={this.props.localUser}/>;
                    if (i < numItems / 2) {
                        gameTableListItems1.push(gameListItem);
                    } else {
                        gameTableListItems2.push(gameListItem);
                    }
                }
            }

            let disableCompleteButton = false;
            let disableOverrideButton = true;
            let roundCompletionMessage = null;
            if (showCompleteButton) {
                const roundStatus = evaluateRoundGames(roundData);
                const totalGames = roundStatus.totalGames;
                if (roundStatus.draft > 0) {
                    roundCompletionMessage = `${roundStatus.draft} game(s) have been reverted to draft, they must be posted (or deleted) to continue`;
                    disableCompleteButton = true;
                }
                else if (roundStatus.complete < totalGames) {
                    let incompleteGames = totalGames - roundStatus.complete;
                    roundCompletionMessage = `Waiting for this round's games to be completed (${incompleteGames} left)`;
                    disableCompleteButton = true;
                }
                else if (roundStatus.reviewed < totalGames) {
                    let unreviewedGames = totalGames - roundStatus.reviewed;
                    roundCompletionMessage = `Waiting for this round's games to be reviewed (${unreviewedGames} left)`;
                    disableOverrideButton = false;
                    disableCompleteButton = true;
                }
                else if (this.props.tournament.type === 'elimination' && roundStatus.ties > 0) {
                    roundCompletionMessage = `${roundStatus.ties} game(s) have recorded ties, this is not allowed in an elimination tournament`;
                    disableCompleteButton = true;
                }
                else if ((this.props.tournament.type === 'swiss' || this.props.tournament.type === 'round-robin') &&
                         (this.props.tournament.subtype === 'vp' || this.props.tournament.subtype === 'mov') &&
                         (roundStatus.scored + roundStatus.byes) < totalGames) {
                    let unscoredGames = totalGames - (roundStatus.scored + roundStatus.byes)
                    roundCompletionMessage = `${unscoredGames} game(s) are unscored, this is not allowed in a tournament using MoV or VP pairing`;
                    disableCompleteButton = true;
                }
                else {
                    roundCompletionMessage = 'Games are complete & reviewed! Click below to complete the round.';
                    disableCompleteButton = false;
                }
            }

            let addGameButtonDisabled = false;
            if (this.props.tournament.type === 'elimination') addGameButtonDisabled = true;

            let showTopTableFinalsWarning = false;
            if (roundData.index === this.props.tournament.num_rounds && this.props.tournament.top_table_finals) showTopTableFinalsWarning = true;

            // figure out the min and max days for round date - +/- 1 year and has to fit in the bounds of the tournament & league
            let todayDate = new Date();
            let minStartDate = new Date();
            let maxStartDate = new Date();
            minStartDate.setFullYear(todayDate.getFullYear() - 1);
            maxStartDate.setFullYear(todayDate.getFullYear() + 1);
            // if the tournament has a start date set, use that for the min date. Otherwise if the league has a start/end date set - set the date limits based on that
            if (this.props.tournament.date) {
                minStartDate = this.props.tournament.date;
            } else if (this.props.league.start_date) {
                minStartDate = this.props.league.start_date;
            }
            if (this.props.league.end_date) {
                maxStartDate = this.props.league.end_date;
            }

            let showRealtimeControls = false;
            if (roundData.duration !== null && roundData.state === 'in-progress') showRealtimeControls = true;

            // each round is an accordion
            const accordionItem = 
                <Accordion.Item key={roundId} eventKey={roundId}>
                    <Accordion.Header><b>{roundData.name}</b> ({roundData.getRoundStatusDescription()})</Accordion.Header>
                    <Accordion.Body>
                        {this.state.roundErrorMessages[roundId] &&
                        <Alert variant='danger'>{this.state.roundErrorMessages[roundId]}</Alert>}
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <FormControlSubmit size='sm' disabled={this.state.updating} type='text' placeholder="Round X?" autoComplete='off' onClick={(v)=>this.handleRoundNameChange(roundId, v)} value={roundData.name} minLength={1} maxLength={25} required/>
                                    <Form.Text muted>Something like 'Round 1' or 'Championship Round'.</Form.Text>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>Date</Form.Label>
                                    <FormControlSubmit size='sm' disabled={this.state.updating} type="date" onClick={(v)=>this.handleRoundDateChange(roundId, v)} autoComplete='off' value={roundData.date || ''} min={minStartDate} max={maxStartDate}/>
                                    <Form.Text muted>Optional: on what day will the round be played?</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>Start Time</Form.Label>
                                    <FormControlSubmit size='sm' disabled={this.state.updating} type="time" onClick={(v)=>this.handleRoundTimeChange(roundId, v)} autoComplete='off' value={roundTime || ''}/>
                                    <Form.Text muted>Optional: when will the round be played? <i>Does not automatically start the round - this is primarily for player info.</i></Form.Text>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="form-outline mb-3">
                                    <Form.Label>Duration (minutes)</Form.Label>
                                    <div style={{position: 'relative'}}>
                                    <FormControlSubmit size='sm' disabled={this.state.updating} type="number" onClick={(v)=>this.handleRoundDurationChange(roundId, v)} autoComplete='off' value={(roundData.duration/60) || ''}/>
                                        {roundData.duration !== null &&
                                        <div className='text-muted px-1' style={{position: 'absolute', top: '-12px', right: '80px',
                                                    border: '1px solid #ced4da', borderRadius: '5px', fontSize: '14px',
                                                    backgroundColor: 'white'}}>
                                            {generateTimeRemainingHMS(roundData.duration)}
                                        </div>}
                                    </div>
                                    <Form.Text muted>Optional: how long is the round? (enables Real-Time Controls)</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Collapse in={showRealtimeControls}>
                            <div>
                                <Row>
                                    <Col sm={6}>
                                    </Col>
                                    <Col>
                                        <Form.Group className="form-outline mb-3">
                                            <Form.Label>Time Remaining (minutes)</Form.Label>
                                            <FormControlCountdown ref={this.countdownRef} roundId={roundId} duration={roundData.duration} onComplete={()=>this.onTimerComplete(roundId)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                        {createPairings &&
                        <Row>
                            <Col sm={12}>
                                <hr/>
                                <div><b>Pairings & Tables</b></div>
                                <div><small className='text-muted'>Drag and drop players to adjust pairings. Rename tables as desired. Byes are automatically added and removed as needed. Tables with two byes are ignored when pairings are accepted.</small></div>
                                {showTopTableFinalsWarning &&
                                <Alert variant='warning'>Top Table Championship - ensure top table is paired correctly!</Alert>}
                                {creatingPairingsSpinner}
                                {pairingErrorAlert}
                                <DragDropContext onDragEnd={(r)=>this.onPairingDragEnd(r)}>
                                    <Row>
                                        <Col sm={6}>
                                            {pairingTableRows1}
                                        </Col>
                                        <Col sm={6}>
                                            {pairingTableRows2}
                                        </Col>
                                    </Row>
                                </DragDropContext>
                            </Col>
                        </Row>}
                        {createSeeds &&
                        <Row>
                            <Col sm={12}>
                                <hr/>
                                <div><b>Tournament Seeds</b></div>
                                <div><small className='text-muted'>Drag and drop players to adjust seeds. The top player is the first seed. You may also directly enter the seeds in the Tournament Players card (click Modify).</small></div>
                                <DragDropContext onDragEnd={(r)=>this.onSeedDragEnd(r)}>
                                    {seedTable}
                                </DragDropContext>
                            </Col>
                        </Row>}
                        {!createPairings && !createSeeds && gameTableListItems1.length !== 0 &&
                        <Row>
                            <Col sm={12}>
                                <hr/>
                                <div><b>Games</b></div>
                                <div><small className='text-muted'>Click any game to edit (or delete) it. Add new games as needed.</small></div>
                                {showTopTableFinalsWarning &&
                                <Alert variant='primary'>Top Table Championship Enabled This Round!</Alert>}
                                <DragDropContext onDragEnd={(r)=>this.onPairingDragEnd(r)}>
                                    <Row>
                                        <Col sm={6}>
                                            {gameTableListItems1}
                                        </Col>
                                        <Col sm={6}>
                                            {gameTableListItems2}
                                        </Col>
                                    </Row>
                                </DragDropContext>
                            </Col>
                        </Row>}
                        <Row>
                            <Col xs={12}>
                                <div className='text-end'>
                                    {showAcceptPairingsButton &&
                                    <div>
                                        <Button size='sm' variant='secondary' disabled={this.state.updating && !this.state.currentRoundPairingsModified} onClick={()=>this.onRevertPairings(roundId)}>Regenerate Pairings</Button>
                                        {' '}
                                        <Button size='sm' variant='secondary' disabled={this.state.updating} onClick={()=>this.onAddTable(roundId)}>Add Table</Button>
                                        {' '}
                                        <Button size='sm' variant='primary' disabled={this.state.updating || disableNextButton} onClick={()=>this.onAcceptPairings(roundId)}>Accept Pairings</Button>
                                    </div>}
                                    {showAcceptSeedsButton &&
                                    <div>
                                        <Button size='sm' variant='secondary' disabled={this.state.updating && !this.state.currentRoundSeedsModified} onClick={()=>this.onRevertSeeds(roundId)}>Revert Seeds</Button>
                                        {' '}
                                        <Button size='sm' variant='primary' disabled={this.state.updating || disableNextButton} onClick={()=>this.onAcceptSeeds(roundId)}>Accept Seeds</Button>
                                    </div>}
                                    {showAcceptElimRoundButton &&
                                    <div>
                                        <Button size='sm' variant='primary' disabled={this.state.updating || disableNextButton} onClick={()=>this.onAcceptElimRound(roundId)}>Accept Pairings</Button>
                                    </div>}
                                    {showStartButton &&
                                    <div>
                                        <Button size='sm' variant='secondary' disabled={this.state.updating || disableRevertButton} onClick={()=>this.onRevertRound(roundId, 'draft-prompt')}>Revert Round</Button>
                                        {' '}
                                        <Button size='sm' variant='secondary' disabled={this.state.updating || addGameButtonDisabled} onClick={()=>this.onAddGame(roundId)}>Add Game</Button>
                                        {' '}
                                        <Button size='sm' variant='primary' disabled={this.state.updating || disableNextButton} onClick={()=>this.onStartRound(roundId)}>Start Round</Button>
                                    </div>}
                                    {showCompleteButton &&
                                    <div>
                                        <div>
                                            <small className='text-muted'>{roundCompletionMessage}</small>
                                        </div>
                                        <Button size='sm' variant='secondary' disabled={this.state.updating || disableRevertButton} onClick={()=>this.onRevertRound(roundId, 'initialized')}>Revert Round</Button>
                                        {' '}
                                        <Button size='sm' variant='secondary' disabled={this.state.updating || addGameButtonDisabled} onClick={()=>this.onAddGame(roundId)}>Add Game</Button>
                                        {' '}
                                        <Button size='sm' variant='warning' disabled={this.state.updating || disableOverrideButton} onClick={()=>this.onOverrideReviews(roundId)}>Override Reviews</Button>
                                        {' '}
                                        <Button size='sm' variant='primary' disabled={this.state.updating || disableNextButton || disableCompleteButton} onClick={()=>this.onCompleteRound(roundId)}>Complete Round</Button>
                                    </div>}
                                    {!showAcceptPairingsButton && !showAcceptSeedsButton && !showAcceptElimRoundButton && !showStartButton && !showCompleteButton &&
                                    <div>
                                        <Button size='sm' variant='secondary' disabled={this.state.updating || disableRevertButton} onClick={()=>this.onRevertRound(roundId, 'in-progress')}>Revert Round</Button>
                                    </div>}
                                </div>
                            </Col>
                        </Row>
                        {isFinalRound && this.props.tournament.state !== 'complete' &&
                        <Row className='mt-3'>
                            <Col sm={12}>
                                <TripleDeleteButton label={'Delete Round'} onFinalClick={()=>this.onDeleteRound(roundId)}/>
                            </Col>
                        </Row>}
                    </Accordion.Body>
                </Accordion.Item>
            accordionItems.push(accordionItem);
        }

        let addRoundsDisabled = false;
        let completeTournamentDisabled = false;
        if (accordionItems.length >= 12) addRoundsDisabled = true;
        if (this.props.tournament.state === 'complete') { 
            addRoundsDisabled = true;
            completeTournamentDisabled = true;
        }
        if (this.state.updating) {
            addRoundsDisabled = true;
            completeTournamentDisabled = true;
        }

        return(
            <NerdHerderStandardCardTemplate id='tournament-rounds-card' title="Tournament Rounds" titleIcon="swords.png">
                {this.state.showAddGameModal && <NerdHerderAddGameModal localUser={this.props.localUser}
                                                                        tournament={this.props.tournament}
                                                                        tournamentRoundId={currentRoundId}
                                                                        tableName={this.state.addGameTableName}
                                                                        defaultDetails={`Assigned to ${this.state.addGameTableName} during ${this.state.addGameRoundName} in ${this.props.tournament.name}`}
                                                                        event={this.props.event}
                                                                        league={this.props.league}
                                                                        onCancel={()=>this.onAddGameCancel()} 
                                                                        onAddGame={()=>this.onAddGameCancel()}/>}
                {this.state.showAutoStartPromptModal && <NerdHerderConfirmModal title='Start Round?'
                                                                        message='This round appears to be fully setup - would you like to start it immediately?'
                                                                        cancelButtonText='Not Yet'
                                                                        acceptButtonText='Start Round'
                                                                        onCancel={()=>this.setState({showAutoStartPromptModal: false})} 
                                                                        onAccept={()=>this.onStartRound(roundData.id)}/>}
                {this.state.showConfirmGameDeleteModal && <NerdHerderConfirmModal title='Delete Games?'
                                                                        message={`To revert the round, the ${currentRound.game_ids.length} games already created for the round will be deleted (after re-pairing, new games will be created). Once deleted, there is no undo. Revert the round to Draft and delete this round's games?`}
                                                                        cancelButtonText='Cancel'
                                                                        acceptButtonText='Delete & Revert Round'
                                                                        onCancel={()=>this.setState({showConfirmGameDeleteModal: false})} 
                                                                        onAccept={()=>this.onRevertRound(currentRoundId, 'draft')}/>}
                {this.state.showCompleteTournamentModal && <NerdHerderCompleteTournamentModal localUser={this.props.localUser}
                                                                        tournament={this.props.tournament}
                                                                        event={this.props.event}
                                                                        league={this.props.league}
                                                                        players={this.props.players}
                                                                        onCancel={()=>this.setState({showCompleteTournamentModal: false})} />}
                {accordionItems.length !== 0 &&
                <Accordion flush onSelect={(k)=>this.onKeySelect(k)} activeKey={activeKey}>
                    {accordionItems}
                </Accordion>}
                {this.props.tournament.state !== 'complete' && accordionItems.length === 0 &&
                    <p>No rounds are configured for this tournament, you should add one!</p>
                }
                {this.props.tournament.state !== 'complete' && accordionItems.length < this.props.tournament.num_rounds &&
                <div>
                    <div className='text-end'>
                        <Button variant='primary' disabled={addRoundsDisabled} onClick={()=>this.onAddRound()}>Add Round</Button>
                    </div>
                </div>}
                {this.props.tournament.type === 'elimination' && this.props.tournament.state === 'in-progress' && accordionItems.length >= this.props.tournament.num_rounds &&
                <div>
                    <Alert variant='warning'>No additional rounds are expected.</Alert>
                    <div className='text-end'>
                        <Button variant='primary' disabled={completeTournamentDisabled} onClick={()=>this.onCompleteTournament()}>Complete Tournament</Button>
                    </div>
                </div>}
                {this.props.tournament.type !== 'elimination' && this.props.tournament.state === 'in-progress' && accordionItems.length >= this.props.tournament.num_rounds &&
                <div>
                    <Alert variant='warning'>Additional rounds are not expected, although you may add one if desired.</Alert>
                    <div className='text-end'>
                        <Button variant='secondary' disabled={addRoundsDisabled} onClick={()=>this.onAddRound()}>Add Round</Button>
                        {' '}
                        <Button variant='primary' disabled={completeTournamentDisabled} onClick={()=>this.onCompleteTournament()}>Complete Tournament</Button>
                    </div>
                </div>}
                {this.props.tournament.state === 'complete' &&
                <Alert variant='primary'>This tournament is complete.</Alert>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class TablePlayerItem extends React.Component {
    constructor(props) {
        super(props);

        this.uniqueId = `user-${this.props.user.id}`;
        if (this.props.user.id === 0) {
            this.uniqueId = `user-${this.props.user.id}-${getRandomString(5)}` 
        }

        this.state = {
            userData: this.props.user,
        }
    }

    render() {
        const userData = this.state.userData;
        return (
            <Draggable key={this.uniqueId} draggableId={this.uniqueId} index={this.props.index}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                        <Image className='rounded-circle' src={userData.getImageUrl()} height='15px' width='15px' alt='player profile image'/> {userData.username}
                        {userData.short_name &&
                        <small className='text-muted'> ({userData.short_name})</small>}
                                                </div>
                )}
            </Draggable>
        )
    }
}
  
function getItemStyle(isDragging, draggableStyle) {
    const style = {
        userSelect: "none",
        padding: '2px 5px 2px 5px',
        margin: '0 0 5px 0',
        border: isDragging ? "2px solid #0d6efd" : "1px solid #dee2e6",
        borderRadius: '5px',
        ...draggableStyle
    }
    return (style);
}
  
function getListStyle(isDraggingOver) {
    const style = {
        padding: '2px',
    }
    return (style);
};


export default withRouter(ManageTournamentPage);
