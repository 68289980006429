import React from 'react';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderRestPubSub } from './NerdHerder-RestPubSub';
import { capitalizeFirstLetters } from './utilities';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDocs, addDoc, collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { getAuth, signOut, signInWithCustomToken } from 'firebase/auth';

export class UnitTestPage extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        

        this.state = {
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();

        const firebaseConfig = {
            apiKey: "AIzaSyAJZe9hz0k91LKKHJ0rw_ERgmKFqcAuj94",
            authDomain: "tidal-axis-331817.firebaseapp.com",
            projectId: "tidal-axis-331817",
            storageBucket: "tidal-axis-331817.appspot.com",
            messagingSenderId: "986217896990",
            appId: "1:986217896990:web:4766841ee159697c88220f"
        };

        console.log(capitalizeFirstLetters(''));
        console.log(capitalizeFirstLetters('something'));
        console.log(capitalizeFirstLetters('Something'));
        console.log(capitalizeFirstLetters('SOMETHING'));
        console.log(capitalizeFirstLetters('some words'));
        console.log(capitalizeFirstLetters('some more words'));
        console.log(capitalizeFirstLetters(null));
        console.log(capitalizeFirstLetters());

        initializeApp(firebaseConfig);
        this.firestoreDb = getFirestore();
        this.firebaseAuth = getAuth();
        this.leaguesColRef = collection(this.firestoreDb, 'leagues');
    }

    componentDidMount() {
        /*onSnapshot(this.leaguesColRef, (snapshot)=>{
            const leaguesList = [];
            for (const leagueDoc of snapshot.docs) {
                leaguesList.push({...leagueDoc.data(), id: leagueDoc.id})
            }
            console.debug(leaguesList);
        });*/

        /*getDocs(this.leaguesColRef)
        .then((snapshot)=>{
            const leaguesList = [];
            for (const leagueDoc of snapshot.docs) {
                leaguesList.push({...leagueDoc.data(), id: leagueDoc.id})
            }
            console.debug(leaguesList);
        })
        .catch((error)=>{
            console.error('failed to getDocs()');
            console.error(error);
        });*/

        /*this.leagueRef = doc(this.firestoreDb, 'leagues', '1')
        .then((snapshot)=>{
            const leaguesList = [];
            for (const leagueDoc of snapshot.docs) {
                leaguesList.push({...leagueDoc.data(), id: leagueDoc.id})
            }
            console.debug(leaguesList);
        })
        .catch((error)=>{
            console.error('failed to getDocs()');
            console.error(error);
        });*/
    }

    render() {
        return(<div><p>Done</p></div>);
    }
}
