import React from 'react';
import withRouter from './withRouter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { NerdHerderQrCode, NerdHerderSimpleQrCode } from './nerdherder-components/NerdHerderQrCode';

class QrPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            errorFeedback: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.setState({firebaseSigninComplete: true}), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k)=>{this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    render() {
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);
        let useSimple = false;
        if (this.props.query.get('simple') === 'true') useSimple = true;

        return(
            <NerdHerderStandardPageTemplate pageName='qr_page' headerSelection='qr_page' localUser={this.state.localUser}>
                <QrCard simple={useSimple} localUser={this.state.localUser}/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class QrCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='QrCard'>
                <QrCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class QrCardInner extends React.Component {

    render() {
        const leagueHref = 'https://www.nerdherder.app';
        const qrHref = '';

        return(
            <NerdHerderStandardCardTemplate id="sharing-card" title='Sharing' titleIcon='qr-code.png'>
                <Row>
                    <Col xs={12}>
                        <p>Share this QR code or link to invite others to NerdHerder!</p>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col xs='auto' className="text-center">
                        {this.props.simple &&
                        <NerdHerderSimpleQrCode size={400} href={qrHref}/>}
                        {!this.props.simple &&
                        <NerdHerderQrCode size={400} href={qrHref}/>}
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <a href={leagueHref}>{leagueHref}</a>
                        <Button className='float-end' size='sm' onClick={()=>navigator.clipboard.writeText(leagueHref)}>Copy To Clipboard</Button>
                    </Col>
                </Row>
            </NerdHerderStandardCardTemplate>
        );
    }
}

export default withRouter(QrPage);
