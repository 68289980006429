import React from 'react';
import withRouter from './withRouter';
import { Link, Navigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import Collapse from 'react-bootstrap/Collapse';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderPasswordModal, NerdHerderVenueSearchModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, setCookie, getCookie, delCookie, delCookieAfterDelay, messageServiceWorker, getCookieParseInt, getFailureMessage, capitalizeFirstLetter, delLocal } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderDropzoneImageUploader } from './nerdherder-components/NerdHerderDropzone';
import { FormErrorText, getFormErrors, setErrorState, clearErrorState, FormTimeout, TripleDeleteButton } from './nerdherder-components/NerdHerderFormHelpers';
import { NerdHerderFontIcon} from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderNavigate } from './nerdherder-components/NerdHerderNavigate';
import { LeagueListItem, VenueListItem } from './nerdherder-components/NerdHerderListItems';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            errorFeedback: null,
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        const sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);   
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);

        return(
            <NerdHerderStandardPageTemplate pageName='profile' headerSelection='settings' dropdownSelection='profile' 
                                            navPath={[{icon: 'flaticon-configuration-with-gear', label: 'Profile', href: '/app/profile'}]}
                                            localUser={this.state.localUser}>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                <UserProfileCard localUser={this.state.localUser}/>
                <ExternalSitesCard localUser={this.state.localUser}/>
                <WebPushConfigurationCard localUser={this.state.localUser}/>
                <PastLeaguesCard localUser={this.state.localUser}/>
                <VenuesCard localUser={this.state.localUser}/>
                <UserInterestsCard localUser={this.state.localUser}/>
                <DeleteAccountCard localUser={this.state.localUser}/>
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class UserProfileCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='UserProfileCard'>
                <UserProfileCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class UserProfileCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.handlerTimeoutPeriod = 1000;
        this.timeout = new FormTimeout(1000);

        this.timeoutValues = {};
        this.dzRef = React.createRef();

        this.state = {
            localUser: this.props.localUser,
            updating: false,
            showChangePasswordModal: false,
            countryList: null,
            timezoneList: null,
            useImagePlaceholder: false,

            formUsername: this.props.localUser.username,
            formUsernameAvailable: null,
            formPassword: '',
            formName: this.props.localUser.name,
            formEmail: this.props.localUser.email,
            formZipcode: this.props.localUser.zipcode,
            formCountry: this.props.localUser.country,
            formTimezone: this.props.localUser.timezone,
            formPhone: this.props.localUser.phone,

            formErrors: {},
            similarUsernames: [],
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('self', null, (d, k)=>this.updateSelf(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('country-list', null, (d, k)=>this.updateCountryList(d, k));
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribeNoRefresh('timezone-list', null, (d, k)=>this.updateTimezoneList(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    checkFreeUsername() {
        this.restApi.genericGetEndpointData('user', null, {'username-available': this.state.formUsername})
            .then(response => {
                this.updateFreeUsername(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    updateFreeUsername(response) {
        this.setState({similarUsernames: response});
        if (response.length === 0) {
            this.setState({formUsernameAvailable: true});
        } else if (response.length === 1) {
            if (response[0].id === this.state.localUser.id) {
                this.setState({formUsernameAvailable: true});
            } else {
                this.setState({formUsernameAvailable: false});
            }
        } else {
            this.setState({formUsernameAvailable: false});
        }
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    onUpdatePassword(newPassword) {
        this.setState({formPassword: newPassword, showChangePasswordModal: false});
        this.restPubSub.patch('self', null, {password: newPassword});
    }

    onCancelPasswordModal() {
        this.setState({showChangePasswordModal: false});
    }

    updateSelf(userData, key) {
        const newSelf = NerdHerderDataModelFactory('self', userData);
        this.setState(
            {
                localUser: newSelf,
                formErrors: {},
                useImagePlaceholder: false,
                formUsernameAvailable: null,
                formUsername: newSelf.username,
                formPassword: '',
                formName: newSelf.name,
                formEmail: newSelf.email,
                formZipcode: newSelf.zipcode,
                formCountry: newSelf.country,
                formTimezone: newSelf.timezone,
                formPhone: newSelf.phone,
            }
        );
    }

    onDzSending(file) {
        this.setState({useImagePlaceholder: true})
    }

    onDzSuccess(file) {
        this.dzRef.current.clearUploadedFiles();
        this.restPubSub.refresh('self');
    }

    onDzError(file) {
        this.dzRef.current.clearUploadedFiles();
        this.restPubSub.refresh('self');
    }

    updateCountryList(response, key) {
        this.setState({countryList: response});
    }

    updateTimezoneList(response, key) {
        this.setState({timezoneList: response});
    }

    onRevertUsername() {
        let errorState = clearErrorState('username', {...this.state.formErrors});
        this.setState({formUsernameAvailable: null, formUsername: this.state.localUser.username, formErrors: errorState});
    }

    onChangeUsername() {
        this.restPubSub.patch('self', null, {username: this.state.formUsername.trimEnd()});
    }

    handleUsernameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('username', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('username', {...this.state.formErrors}, 'this username is too short');
        } else {
            this.timeout.setTimeout(()=>this.checkFreeUsername(), 'username');
        }
        this.setState({formUsernameAvailable: null, formUsername: value, formErrors: errorState});
    }

    handleNameChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('name', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('name', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {name: value.trimEnd()}), 'name');
        }
        this.setState({formName: value, formErrors: errorState});
    }

    handlePhoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('phone', {...this.state.formErrors});
        if (value.length < 8) {
            errorState = setErrorState('phone', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {phone: value.trimEnd()}), 'phone');
        }
        this.setState({formPhone: value, formErrors: errorState});
    }

    handleZipcodeChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('zipcode', {...this.state.formErrors});
        if (value.length < 3) {
            errorState = setErrorState('zipcode', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {zipcode: value.trimEnd()}), 'zipcode');
        }
        this.setState({formZipcode: value, formErrors: errorState});
    }

    handleCountryChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('country', {...this.state.formErrors});
        this.setState({formCountry: value, formErrors: errorState});
        this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {country: value}), 'country');
    }

    handleTimezoneChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('timezone', {...this.state.formErrors});
        this.setState({formTimezone: value, formErrors: errorState});
        this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {timezone: value}), 'timezone');
    }

    handleEmailChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('email', {...this.state.formErrors});
        if (value.length < 6) {
            errorState = setErrorState('email', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {email: value.trimEnd()}), 'email');
        }
        this.setState({formEmail: value, formErrors: errorState});
    }

    render() {
        if (this.props.localUser === null) return(<NerdHerderLoadingCard title="Your Profile"/>);
        if (this.state.countryList === null) return(<NerdHerderLoadingCard title="Your Profile"/>);
        if (this.state.timezoneList === null) return(<NerdHerderLoadingCard title="Your Profile"/>);

        const countryOptions = [];
        for (const countryName of this.state.countryList) {
            countryOptions.push(<option key={countryName} value={countryName}>{countryName}</option>)
        }

        // create timezone priority options if the timezone matches the user's country
        const timezoneNamesPriority = [];
        const timezoneNamesOthers = [];
        for (const timezone of this.state.timezoneList) {
            if (timezone.country_name === this.props.localUser.country) {
                timezoneNamesPriority.push(timezone.timezone_name);
            } else {
                timezoneNamesOthers.push(timezone.timezone_name);
            } 
        }
        timezoneNamesPriority.sort();
        timezoneNamesOthers.sort();
        const timezoneOptions = [];
        for (const timezoneName of timezoneNamesPriority) {
            const timezoneItem = <option key={timezoneName} value={timezoneName}>{timezoneName}</option>
            timezoneOptions.push(timezoneItem);
        }
        for (const timezoneName of timezoneNamesOthers) {
            const timezoneItem = <option key={timezoneName} value={timezoneName}>{timezoneName}</option>
            timezoneOptions.push(timezoneItem);
        }

        let postalCodeText = 'Postal Code';
        if (this.state.localUser.country === 'United States') {
            postalCodeText = 'Zipcode';
        }

        // unless the user messes with the username, there's nothing to save or revert
        let changeUsernameDisabled = true;
        let revertUsernameDisabled = true;
        if (this.state.formUsername !== this.state.localUser.username) {
            revertUsernameDisabled = false;
            if (this.state.formUsernameAvailable === true) {
                changeUsernameDisabled = false;
            }
        }

        let usernameError = false;
        if (this.state.formErrors.hasOwnProperty('username')) usernameError = true;

        return(
            <NerdHerderStandardCardTemplate id="profile-card" title="Your Profile" titleIcon='adjust.png'>
                {this.state.showChangePasswordModal &&
                <NerdHerderPasswordModal
                    onCancel={()=>this.onCancelPasswordModal()}
                    onAccept={(p)=>this.onUpdatePassword(p)}
                    matchPassword={true}
                    autocomplete='new-password'
                    userPassword={true}
                    username={this.state.localUser.username}
                    title='Change Password'
                    passwordLabel='Enter new password'
                    acceptButtonText='Change'/>}
                <Form>
                    <Form.Group className="mb-2">
                        <Form.Label>Profile Image</Form.Label>
                        <Row className='text-center align-items-center'>
                            <Col xs='auto'>
                                {!this.state.useImagePlaceholder &&
                                <Image className='rounded-circle mb-1' src={this.state.localUser.getImageUrl()} width='100px' alt='your profile image'/>}
                                {this.state.useImagePlaceholder &&
                                <Spinner variant="primary" animation="grow" size="sm" role="status" aria-hidden="true" style={{width: '100px', height: '100px'}}/>}
                            </Col>
                            <Col>
                                <NerdHerderDropzoneImageUploader
                                    ref={this.dzRef}
                                    localUser={this.state.localUser}
                                    message={'Drop file here to update'}
                                    uploadUrl='/rest/v1/dz-profile-image-upload'
                                    sendingCallback={(f)=>this.onDzSending(f)}
                                    successCallback={(f)=>this.onDzSuccess(f)}
                                    errorCallback={(f)=>this.onDzError(f)}/>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Row className='align-items-center'>
                            <Col className='me-0 pe-0'>
                                <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleUsernameChange(event)} value={this.state.formUsername} minLength={6} maxLength={20} required/>
                            </Col>
                            <Col className='ms-0 ps-1' xs='auto'>
                                <Button type='button' size='sm' variant='primary' onClick={()=>this.onChangeUsername()} disabled={changeUsernameDisabled}><NerdHerderFontIcon icon='flaticon-right-arrow'/></Button>
                                {' '}
                                <Button type='button' size='sm' variant='danger' onClick={()=>this.onRevertUsername()} disabled={revertUsernameDisabled}><NerdHerderFontIcon icon='flaticon-cross-sign'/></Button>
                            </Col>
                        </Row>
                        {!usernameError && this.state.formUsernameAvailable === null &&
                        <Form.Text className='text-muted'>This is your main identifier visible to others on the site.</Form.Text>}
                        {!usernameError && this.state.formUsernameAvailable === true &&
                        <Form.Text className='text-primary'>This username is available.</Form.Text>}
                        {!usernameError && this.state.formUsernameAvailable === false &&
                        <Form.Text className='text-danger'>That username (or something very similar) is already taken.</Form.Text>}
                        <FormErrorText errorId='username' errorState={this.state.formErrors}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>'Real-Life' Name</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} onChange={(event)=>this.handleNameChange(event)} value={this.state.formName} minLength={3} maxLength={20} required/>
                        <FormErrorText errorId='name' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Once a league is running, this is provided to other players to help 'in real life'. Use a name that other players will recognize, plus an initial to reduce confusion for common names (e.g. Tobias S). A nickname is also appropriate.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" disabled={this.state.updating} onChange={(event)=>this.handleEmailChange(event)} value={this.state.formEmail} minLength={6} maxLength={90} required/>
                        <FormErrorText errorId='email' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Email is used to send updates about your leagues & to easily upload lists from sites like Cerebro MCP and Tabletop Admiral. <b className='text-danger'>Changing your address will require re-verification.</b></Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" disabled={this.state.updating} onChange={(event)=>this.handlePhoneChange(event)} value={this.state.formPhone} minLength={8} maxLength={20} required/>
                        <FormErrorText errorId='phone' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Only your contacts can see your phone number.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{`${postalCodeText} & Country`}</Form.Label>
                        <Form.Control id='zipcode' name='zipcode' className='mb-1' type="text" disabled={this.state.updating} onChange={(event)=>this.handleZipcodeChange(event)} value={this.state.formZipcode} minLength={3} maxLength={45} required/>
                        <FormErrorText errorId='zipcode' errorState={this.state.formErrors}/>
                        <Form.Select id='country' name='country' disabled={this.state.updating} onChange={(event)=>this.handleCountryChange(event)} value={this.state.formCountry} required>
                            {countryOptions}
                        </Form.Select>
                        <FormErrorText errorId='country' errorState={this.state.formErrors}/>
                        <Form.Text className='text-muted'>Used to find leagues that you may be interested in.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{`Timezone`}</Form.Label>
                        <Form.Select id='timezone' name='timezone' disabled={this.state.updating} onChange={(event)=>this.handleTimezoneChange(event)} value={this.state.formTimezone} required>
                            {timezoneOptions}
                        </Form.Select>
                        <Form.Text className='text-muted'>Shown to other users who may be trying to schedule a game with you.</Form.Text>
                    </Form.Group>
                    
                    <Form.Group className='text-end'>
                        <Button variant='secondary' disabled={this.state.updating} onClick={()=>this.setState({showChangePasswordModal: true})}>Change Password</Button>
                    </Form.Group>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}


class ExternalSitesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ExternalSitesCard'>
                <ExternalSitesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ExternalSitesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.handlerTimeoutPeriod = 1000;
        this.timeout = new FormTimeout(1000);
        this.timeoutValues = {};

        this.state = {
            updating: false,
            formDiscordId: this.props.localUser.discord_id,
            formLongshanksId: this.props.localUser.longshanks_id,
            formBandaiId: this.props.localUser.bandai_id,
            formKonamiId: this.props.localUser.konami_id,
            formPokemonId: this.props.localUser.pokemon_id,
            formWizardsId: this.props.localUser.wizards_id,
            formErrors: {},
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('self', null, (d, k)=>this.updateSelf(d, k), (e, k)=>this.formUpdateError(e, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    formUpdateError(error, key) {
        const formErrors = getFormErrors(error);
        if (formErrors !== null) {
            this.setState((state) => {
                return {formErrors: {...state.formErrors, ...formErrors}}
            });

            // caught this error, keep it from going up
            return true;
        }
    }

    updateSelf(userData, key) {
        const newSelf = NerdHerderDataModelFactory('self', userData);
        this.setState(
            {
                formErrors: {},
                formDiscordId: newSelf.discord_id,
                formLongshanksId: newSelf.longshanks_id,
                formBandaiId: newSelf.bandai_id,
                formKonamiId: newSelf.konami_id,
                formPokemonId: newSelf.pokemon_id,
                formWizardsId: newSelf.wizards_id,
            }
        );
    }

    handleDiscordIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('discord_id', {...this.state.formErrors});
        if (value.length === 0) {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {discord_id: null}), 'discord_id');
        }
        else if (value.length < 3) {
            errorState = setErrorState('discord_id', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {discord_id: value.trimEnd()}), 'discord_id');
        }
        this.setState({formDiscordId: value, formErrors: errorState});
    }

    handleWizardsIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('wizards_id', {...this.state.formErrors});
        if (value.length === 0) {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {wizards_id: null}), 'wizards_id');
        }
        else if (value.length < 3) {
            errorState = setErrorState('wizards_id', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {wizards_id: value.trimEnd()}), 'wizards_id');
        }
        this.setState({formWizardsId: value, formErrors: errorState});
    }

    handlePokemonIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('pokemon_id', {...this.state.formErrors});
        if (value.length === 0) {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {pokemon_id: null}), 'pokemon_id');
        }
        else if (value.length < 3) {
            errorState = setErrorState('pokemon_id', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {pokemon_id: value.trimEnd()}), 'pokemon_id');
        }
        this.setState({formPokemonId: value, formErrors: errorState});
    }

    handleBandaiIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('bandai_id', {...this.state.formErrors});
        if (value.length === 0) {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {bandai_id: null}), 'bandai_id');
        }
        else if (value.length < 3) {
            errorState = setErrorState('bandai_id', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {bandai_id: value.trimEnd()}), 'bandai_id');
        }
        this.setState({formBandaiId: value, formErrors: errorState});
    }

    handleKonamiIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('konami_id', {...this.state.formErrors});
        if (value.length === 0) {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {konami_id: null}), 'konami_id');
        }
        else if (value.length < 3) {
            errorState = setErrorState('konami_id', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {konami_id: value.trimEnd()}), 'konami_id');
        }
        this.setState({formKonamiId: value, formErrors: errorState});
    }

    handleLongshanksIdChange(event) {
        let value = event.target.value;
        let errorState = clearErrorState('longshanks_id', {...this.state.formErrors});
        if (value.length === 0) {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {longshanks_id: null}), 'longshanks_id');
        }
        else if (value.length < 3) {
            errorState = setErrorState('longshanks_id', {...this.state.formErrors}, 'this value is too short');
        } else {
            this.timeout.setTimeout(()=>this.restPubSub.patch('self', null, {longshanks_id: value.trimEnd()}), 'longshanks_id');
        }
        this.setState({formLongshanksId: value, formErrors: errorState});
    }

    render() {
        if (this.props.localUser === null) return(<NerdHerderLoadingCard title="External Site IDs"/>);

        return(
            <NerdHerderStandardCardTemplate id="external-sites-card" title="External Site IDs" titleIcon='web_configure.png'>
                <div>
                    <small>You may enter your IDs from other sites. It is OK if you don't have them or don't know them, this is all optional. This info is used to help organizers track players across systems (especially for TCG events).</small>
                </div>
                <Form>
                    <Form.Group className="my-3">
                        <Form.Label>Discord ID</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} placeholder='BillyBob' onChange={(event)=>this.handleDiscordIdChange(event)} value={this.state.formDiscordId || ''} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='discord_id' errorState={this.state.formErrors}/>
                        <Form.Text muted>Discord uses a standard username - the # and user number is no longer required (but may still be included).</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Wizards Event Link ID</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} placeholder='BlackManaFTW#78356' onChange={(event)=>this.handleWizardsIdChange(event)} value={this.state.formWizardsId || ''} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='wizards_id' errorState={this.state.formErrors}/>
                        <Form.Text muted>Wizards Event Link is often used for Magic: The Gathering events. Include the # and user number (e.g. BlackManaFTW#78356).</Form.Text>
                    </Form.Group>
                    <Form.Group className="my-3">
                        <Form.Label>Pokemon Trainer Club Username</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} placeholder='SuperTrainer' onChange={(event)=>this.handlePokemonIdChange(event)} value={this.state.formPokemonId || ''} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='pokemon_id' errorState={this.state.formErrors}/>
                        <Form.Text muted>Your Pokemon username is a regular username (e.g. SuperTrainer).</Form.Text>
                    </Form.Group>
                    <Form.Group className="my-3">
                        <Form.Label>Bandai TCG+ ID</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} placeholder='0000138350' onChange={(event)=>this.handleBandaiIdChange(event)} value={this.state.formBandaiId || ''} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='bandai_id' errorState={this.state.formErrors}/>
                        <Form.Text muted>Bandai TCG+ is often used with One Piece or Digimon events. Your Bandai TCG+ ID is your membership number, it probably has a lot of leading zeros (e.g. 0000138350).</Form.Text>
                    </Form.Group>
                    <Form.Group className="my-3">
                        <Form.Label>Konami Card Game ID</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} placeholder='0117923319' onChange={(event)=>this.handleKonamiIdChange(event)} value={this.state.formKonamiId || ''} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='konami_id' errorState={this.state.formErrors}/>
                        <Form.Text muted>Konami is often used with Yu-Gi-Oh! events. Your Card Game ID is typically found under a barcode (e.g. 0117923319).</Form.Text>
                    </Form.Group>
                    <Form.Group className="my-3">
                        <Form.Label>Longshanks ID</Form.Label>
                        <Form.Control type="text" disabled={this.state.updating} placeholder='Mike Brown#1342' onChange={(event)=>this.handleLongshanksIdChange(event)} value={this.state.formLongshanksId || ''} minLength={3} maxLength={45}/>
                        <FormErrorText errorId='longshanks_id' errorState={this.state.formErrors}/>
                        <Form.Text muted>Longshanks is often used with Marvel: Crisis Protocol events. Include your username, a # symbol, and user number (e.g. Mike Brown#1342). Add a nickname inbetween your first and last name to get a highlight effect (e.g. Mike BigBoss Brown#1342: Mike<Badge bg='secondary'>BigBoss</Badge>Brown)</Form.Text>
                    </Form.Group>
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class WebPushConfigurationCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='WebPushConfigurationCard'>
                <WebPushConfigurationCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class WebPushConfigurationCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // for tracking when the user turns on the pushes
        this.enablePushTimeout = null;

        this.state = {
            webPushEnabled: this.props.localUser.web_push_enabled,
            webPushPublicKey: null,
            subscription: null,
            errorFeedback: null,
            userFeedback: null,
        }

        navigator.serviceWorker.onmessage = (event) => {
            if (event.data && event.data.type === 'PONG') {
                console.debug('card got PONG');
            }
            if (event.data && (event.data.type === 'SUBSCRIPTION' || event.data.type === 'ALREADY_SUBSCRIBED')) {
                console.debug('card got', event.data.type)
                const subscription = event.data.subscription
                this.setState({subscription: subscription, userFeedback: 'Registering for notifications with NerdHerder', errorFeedback: null});
                //craft the sub we get from the browser into something the server will accept
                const nerdherderSubscription = {
                    user_id: this.props.localUser.id,
                    endpoint: subscription.endpoint,
                    expiration_time: subscription.expirationTime,
                };
                for (const [key, value] of Object.entries(subscription.keys)) {
                    if (key === 'auth') {
                        nerdherderSubscription.keys_auth_name = 'auth';
                        nerdherderSubscription.keys_auth_value = value;
                    } else {
                        nerdherderSubscription.keys_algo_name = key;
                        nerdherderSubscription.keys_algo_value = value;
                    }
                }
                // only do the patch if this is a unique subscription
                let isUnique = true;
                for (const localUserSubscription of this.props.localUser.web_push_subscriptions) {
                    if (localUserSubscription.endpoint === nerdherderSubscription.endpoint &&
                        localUserSubscription.keys_algo_name === nerdherderSubscription.keys_algo_name &&
                        localUserSubscription.keys_algo_value === nerdherderSubscription.keys_algo_value) {
                            isUnique = false;
                            break;
                        }
                }
                if (isUnique) this.restPubSub.post('web-push-subscription', null, nerdherderSubscription);

                // regardless though we need to send the enable message to the server
                this.restPubSub.patch('self', null, {web_push_enabled: true});
                this.setState({userFeedback: 'Web push notifications enabled', errorFeedback: null});
            }
            
            if (event.data && event.data.type === 'SUBSCRIPTION_ERROR') {
                console.log('card got SUBSCRIPTION_ERROR');
                this.setState({subscription: null, webPushEnabled: false, errorFeedback: 'Browser failed to subscribe for notifications'});
            }
        };
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('web-push-registration', null, (d, k)=>this.updateWebPushPublicKey(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateWebPushPublicKey(publicKeyData, key) {
        this.setState({webPushPublicKey: publicKeyData});
    }

    onChangeWebPush(value) {
        //const patchData = {web_push_enabled: value};
        //this.restPubSub.put('self', null, patchData);
        this.setState({webPushEnabled: value, errorFeedback: null, userFeedback: null});
        if (this.enablePushTimeout) clearTimeout(this.enablePushTimeout);
        if (value) {
            // the user enabled the web push notifications, setup the local device cookies and enable pushes
            setCookie('EnableDeviceWebPush', true, 3650);
            setCookie('SilenceDeviceWebPush', 0, 3650);
            this.enablePushTimeout = setTimeout(()=>this.enableWebPush(), 1000);
         } else {
            this.restPubSub.patch('self', null, {web_push_enabled: false});
            messageServiceWorker({type: 'PUSH_UNSUBSCRIBE'});
            delCookie('EnableDeviceWebPush');
            delCookie('SilenceDeviceWebPush');
         } 
    }

    enableWebPush() {
        this.askPermission()
        .then((result) => {
            console.log('permission granted');
            messageServiceWorker({type: 'PUSH_SUBSCRIBE'});
        })
        .catch((error) => {
            console.log('permission rejected');
            this.setState({subscription: null, webPushEnabled: false, errorFeedback: 'Permission denied'});
        });
    }

    askPermission() {
        return new Promise(function (resolve, reject) {
            const permissionResult = Notification.requestPermission(function (result) {
                resolve(result);
            });
        
            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        }).then(function (permissionResult) {
            if (permissionResult !== 'granted') {
                throw new Error("user clicked block");
            }
        });
    }

    onDeviceSettings(mode) {
        switch(mode) {
            case 'enable':
                setCookie('EnableDeviceWebPush', true, 3650);
                setCookie('SilenceDeviceWebPush', 0, 3650);
                messageServiceWorker({type: 'SET_SILENCE', silenceDeviceMilliseconds: 0});
                break;
            case 'disable':
                setCookie('EnableDeviceWebPush', false, 3650);
                setCookie('SilenceDeviceWebPush', -1, 3650);
                messageServiceWorker({type: 'SET_SILENCE', silenceDeviceMilliseconds: -1});
                break;
            case 'snooze-8':
                setCookie('EnableDeviceWebPush', true, 3650);
                const timeNow = new Date();
                const silenceDeviceMilliseconds = timeNow.getTime() + (8 * 60 * 60 * 1000);
                setCookie('SilenceDeviceWebPush', silenceDeviceMilliseconds, 3650);
                messageServiceWorker({type: 'SET_SILENCE', silenceDeviceMilliseconds: silenceDeviceMilliseconds});
                break;
            default:
                console.error('got unexpected web push device settings mode mode')
        }
        // just need to trigger a refresh since it was cookies and not state that ws modified.
        this.setState({});
    }

    render() {
        // to receive web push, the browser has to not suck
        let broswerSucks = true;
        if ('serviceWorker' in navigator && 'PushManager' in window) broswerSucks = false;
        if (broswerSucks) return(null);

        if (this.state.webPushPublicKey === null) return(<NerdHerderLoadingCard title='Notifications'/>);
        
        // get the basic settings
        const enableDeviceWebPush = getCookie('EnableDeviceWebPush', false);
        const silenceDeviceWebPush = getCookieParseInt('SilenceDeviceWebPush', null);
        const timeNow = new Date();
        const timeMilliseconds = timeNow.getTime()
        let deviceSettingsString = null;
        if (this.props.localUser.web_push_enabled) {
            if (enableDeviceWebPush === false) {
                deviceSettingsString = <small className='text-muted'>Notifications are enabled but disabled on this specific device</small>
            } else {
                if (timeMilliseconds < silenceDeviceWebPush) {
                    deviceSettingsString = <small className='text-muted'>Notifications are currently silenced on this device</small>
                } else {
                    deviceSettingsString = <small className='text-muted'>Notifications are enabled and will be displayed on this device</small>
                }
            }
        }

        // pretty text under the toggle
        let title = null;
        let description = null;
        switch(this.state.webPushEnabled) {
            case true:
                title = "Enabled";
                description = "NerdHerder will act like an app, and will send you notifications (league or tournament updates for example) in real time using push notifications. This is the recommended setting.";
                break;

            case false:
                title = "Disabled";
                description = "NerdHerder will act like a website. Unless you are viewing the site, NerdHerder won't send you notifications.";
                break;
            
            default:
                title = null;
                description = null;
        }

        return(
            <NerdHerderStandardCardTemplate id="notifications-card" title="Push Notifications" titleIcon='text.png' userFeedback={this.state.userFeedback} errorFeedback={this.state.errorFeedback}>
                <small>NerdHerder is a "Web Application"...sort of in-between being a website and a native or mobile app. Many people prefer native apps. You can make NerdHerder behave more like a native app by enabling Web Push Notifications. You can always go back or temporaily silence the notifications.</small>
                <hr/>
                <Form.Group className="form-outline mb-3">
                    <Form.Label>Web Push Notifications</Form.Label>
                    <div className='d-grid gap-2'>
                        <ToggleButtonGroup name='webPushEnable' type="radio" value={this.state.webPushEnabled} onChange={(v)=>this.onChangeWebPush(v)}>
                            <ToggleButton variant='outline-primary' id='webPush-enabled' value={true}>Enabled</ToggleButton>
                            <ToggleButton variant='outline-primary' id='webPush-disabled' value={false}>Disabled</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </Form.Group>
                <Form.Group className='form-outline mb-3'>
                    <Form.Text>
                        <p><b>{title}</b> - {description}</p>
                    </Form.Text>
                </Form.Group>
                <Collapse in={this.props.localUser.web_push_enabled}>
                    <div>
                        <hr/>
                        <Form.Group className="form-outline mb-3">
                            <Form.Label>Device Specific Settings</Form.Label>
                            <Row>
                                {!enableDeviceWebPush &&
                                <Col>
                                    <div className='d-grid gap-2'>
                                        <Button size='sm' variant='primary' onClick={()=>this.onDeviceSettings('enable')} disabled={!this.state.webPushEnabled}>Enable</Button>
                                    </div>
                                </Col>}
                                {enableDeviceWebPush &&
                                <Col>
                                    <div className='d-grid gap-2'>
                                        <Button size='sm' variant='secondary' onClick={()=>this.onDeviceSettings('snooze-8')} disabled={!this.state.webPushEnabled}>Snooze 8 Hrs</Button>
                                    </div>
                                </Col>}
                                {enableDeviceWebPush &&
                                <Col>
                                    <div className='d-grid gap-2'>
                                        <Button size='sm' variant='secondary' onClick={()=>this.onDeviceSettings('disable')} disabled={!this.state.webPushEnabled}>Disable</Button>
                                    </div>
                                </Col>}
                            </Row>
                            <Row>
                                <Col>
                                    {deviceSettingsString}
                                </Col>
                            </Row>
                        </Form.Group>
                    </div>
                </Collapse>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class PastLeaguesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='PastLeaguesCard'>
                <PastLeaguesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class PastLeaguesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            leagues: {},
            leaguesManagedList: [],
            leaguesPlayedList: [],
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribeNoRefresh('self', null, (d, k)=>this.updateLeaguesList(d, k));
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateLeaguesList(userData, key) {
        const newLeaguesManagedList = [...this.state.leaguesManagedList];
        const newLeaguesPlayedList = [...this.state.leaguesManagedList];
        let madeChange = false;

        // go through the users leagues, sort leagues into managed and played, update as needed
        for (const usersLeagues of userData.users_leagues) {
            const leagueId = usersLeagues.league_id;
            if (usersLeagues.manager) {
                if (!this.state.leaguesManagedList.includes(leagueId)) {
                    newLeaguesManagedList.push(leagueId);
                    madeChange = true;
                }
            } else if (usersLeagues.player) {
                if (!this.state.leaguesPlayedList.includes(leagueId)) {
                    newLeaguesPlayedList.push(leagueId);
                    madeChange = true;
                }
            }
        }

        // now make sure that every league on either list is in the dictionary
        for (const leagueId of newLeaguesManagedList) {
            if (!this.state.leagues.hasOwnProperty(leagueId)) {
                let sub = this.restPubSub.subscribeNoRefresh('league', leagueId, (d, k)=>this.updateLeague(d, k), null, leagueId);
                this.restPubSubPool.add(sub);
                this.setState((state) => {
                    return {leagues: {...state.leagues, [leagueId]: null}}
                });
            }
        }
        for (const leagueId of newLeaguesPlayedList) {
            if (!this.state.leagues.hasOwnProperty(leagueId)) {
                let sub = this.restPubSub.subscribeNoRefresh('league', leagueId, (d, k)=>this.updateLeague(d, k), null, leagueId);
                this.restPubSubPool.add(sub);
                this.setState((state) => {
                    return {leagues: {...state.leagues, [leagueId]: null}}
                });
            }
        }

        if (madeChange) {
            this.setState({leaguesManagedList: newLeaguesManagedList, leaguesPlayedList: newLeaguesPlayedList});
        }
    }

    updateLeague(leagueData, leagueId) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState((state) => {
            return {leagues: {...state.leagues, [leagueId]: league}}
        });
    }

    render() {
        const managerListItems = [];
        const playerListItems = [];
        for (const leagueId of this.state.leaguesManagedList) {
            const league = this.state.leagues[leagueId];
            if (league === null) continue;
            if (league.state === 'archived' || league.state === 'complete') {
                const leagueListItem = <LeagueListItem key={leagueId} leagueId={leagueId} league={league} localUse={this.props.localUser}/>
                managerListItems.push(leagueListItem);
            }
        }
        for (const leagueId of this.state.leaguesPlayedList) {
            const league = this.state.leagues[leagueId];
            if (league === null) continue;
            if (league.state === 'archived' || league.state === 'complete') {
                const leagueListItem = <LeagueListItem key={leagueId} leagueId={leagueId} league={league} localUse={this.props.localUser}/>
                playerListItems.push(leagueListItem);
            }
        }

        // don't show this card if there are no past leagues at all
        if (managerListItems.length === 0 && playerListItems.length === 0) return(null);

        return(
            <NerdHerderStandardCardTemplate id="past-leagues-card" title="Past Events & Leagues" titleIcon='return-to-the-past.png'>
                {managerListItems.length !== 0 &&
                <p>Past leagues you have managed</p>}
                {managerListItems}
                {playerListItems.length !== 0 &&
                <p>Past leagues you have participated in</p>}
                {playerListItems}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class UserInterestsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='UserInterestsCard'>
                <UserInterestsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class UserInterestsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            onlineInterest: this.props.localUser.online_interest,
            interests: {},
        }
    }

    componentDidMount() {
        const sub = this.restPubSub.subscribe('user-interest', this.props.localUser.id, (d, k) => {this.updateInterests(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateInterests(interestData, key) {
        this.setState({interests: interestData});
    }

    onChangeOnlineInterest(value) {
        this.restPubSub.patch('self', null, {online_interest: value});
        this.setState({onlineInterest: value});
    }

    onChangeInterest(topicId, value) {
        const topicName = this.state.interests[topicId].name;
        const patchObj = {name: topicName, level_of_interest: value};
        this.restPubSub.put('user-interest', this.props.localUser.id, {[topicId]: patchObj});
        // this is going to be written when the update comes from the server, but it's more responsive to do it now
        this.setState((state) => {
            return {interests: {...state.interests, [topicId]: patchObj}}
        });
    }

    render() {
        if (this.state.interests === null) return(<NerdHerderLoadingCard title="Your Interests"/>)

        let onlineTitle = null;
        let onlineDescription = null;
        if (this.state.onlineInterest) {
            onlineTitle = '+Online';
            onlineDescription = 'You are interested in both in-person leagues and leagues played over the Internet (using TTS, BGO, Google Hangouts, etc). When searching for leagues, both in-person and online leagues will appear.'
        } else {
            onlineTitle = 'In-Person';
            onlineDescription = 'You are only interested in games played in-person. When searching for leagues, online leagues will be disregarded. You will not receive notifications for new leagues if they are online leagues.'
        }

        const topicsList = []
        for (const [topicId, topicData] of Object.entries(this.state.interests)) {
            const topicName = topicData.name;
            const topicLink = <Link to={`/app/topic/${topicId}`}>{topicId}</Link>
            let levelOfInterest = topicData.level_of_interest;
            const item = 
                <Form.Group key={topicId} className="mb-3">
                        <Form.Label>{topicName} ({topicLink})</Form.Label>
                        <div className='d-grid gap-2'>
                            <ToggleButtonGroup name={`topic-group-${topicId}`} type="radio" value={levelOfInterest} onChange={(value)=>this.onChangeInterest(topicId, value)}>
                                <ToggleButton variant='outline-primary' id={`toggle-${topicId}-none`} value='none'>None</ToggleButton>
                                <ToggleButton variant='outline-primary' id={`toggle-${topicId}-some`} value='some'>Some</ToggleButton>
                                <ToggleButton variant='outline-primary' id={`toggle-${topicId}-nominal`} value='nominal'>Moderate</ToggleButton>
                                <ToggleButton variant='outline-primary' id={`toggle-${topicId}-very`} value='very'>Very</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                </Form.Group>
            topicsList.push(item);
        }

        return(
            <NerdHerderStandardCardTemplate id="interests-card" title="Your Interests" titleIcon='interests.png'>
                <p>What kinds of leagues are you interested in?</p>
                <Form.Group className='form-outline mb-2'>
                    <div className='d-grid gap-2'>
                        <ToggleButtonGroup size='sm' name='league-online' type="radio" value={this.state.onlineInterest} onChange={(v)=>this.onChangeOnlineInterest(v)}>
                            <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-in-person' value={false}>In-Person</ToggleButton>
                            <ToggleButton variant='outline-primary' disabled={this.state.updating} id='toggle-online' value={true}>+Online</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </Form.Group>
                <Form.Group className='form-outline mb-3'>
                    <Form.Text>
                        <p><b>{onlineTitle}</b> - {onlineDescription}</p>
                    </Form.Text>
                </Form.Group>
                <hr/>
                <p>Select your level of interest for each topic below. NerdHerder uses this data to help find leagues that fit your interests.</p>
                {topicsList}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class VenuesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='VenuesCard'>
                <VenuesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class VenuesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            navigateTo: null,
            showSearchVenuesModal: false,
        }
    }

    render() {
        if (this.state.navigateTo) return(<Navigate to={this.state.navigateTo}/>);

        const managedVenueListItems = [];
        for (const venueId of this.props.localUser.managed_venue_ids) {
            const item = <VenueListItem key={venueId} venueId={venueId} localUser={this.props.localUser}/>
            managedVenueListItems.push(item);
        }

        const favoriteVenueListItems = [];
        for (const venueId of this.props.localUser.favorite_venue_ids) {
            const item = <VenueListItem key={venueId} venueId={venueId} localUser={this.props.localUser}/>
            favoriteVenueListItems.push(item);
        }
        
        return(
            <NerdHerderStandardCardTemplate id="venues-card" title="Venues" titleIcon='building.png'>
                {this.state.showSearchVenuesModal &&
                <NerdHerderVenueSearchModal onAccept={null} onCancel={()=>this.setState({showSearchVenuesModal: false})} localUser={this.props.localUser}/>}
                {managedVenueListItems.length !== 0 &&
                <small className='text-muted'>Venues you manage</small>}
                {managedVenueListItems}
                {managedVenueListItems.length !== 0 && favoriteVenueListItems.length !== 0 &&
                <hr/>}
                {favoriteVenueListItems.length !== 0 &&
                <small className='text-muted'>Favorite venues</small>}
                {favoriteVenueListItems}
                {(managedVenueListItems.length + favoriteVenueListItems.length) !== 0 &&
                <hr/>}
                <p>
                    Do you run a game store or gaming venue, and want to make it easier to find? Are you a store, venue or even a single individual (not associated with a retail establishment) and need to take registration payments for your league? Click below to get started.
                </p>
                <div className="d-grid gap-2">
                    {!this.props.localUser.under_13 &&
                    <Button variant='primary' onClick={()=>this.setState({navigateTo: '/app/newvenue'})}>Add Venue</Button>}
                    <Button variant='secondary' onClick={()=>this.setState({showSearchVenuesModal: true})}>Search Venues</Button>
                </div>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class DeleteAccountCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='DeleteAccountCard'>
                <DeleteAccountCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class DeleteAccountCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            errorFeedback: null,
            userFeedback: null,
            navigateTo: null,
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    deleteAccount() {
        this.restApi.genericDeleteEndpointData('self', null)
        .then((response)=>{
            delCookie('DesiredUrl');
            delCookie('RememberMe');
            delCookie('LoginToken');
            delLocal('LoginToken');
            delLocal('UserId');
            delLocal('FirebaseToken');
            this.setState({errorFeedback: null, userFeedback: 'Redirecting to Homepage', updating: true, navigateTo: '/app/homepage'});
        })
        .catch((error)=>{
            let message = getFailureMessage(error);
            message = capitalizeFirstLetter(message);
            this.setState({errorFeedback: message, userFeedback: null, updating: false});
        });
        this.setState({updating: true, userFeedback: 'Deleting data...'});
    }

    render() {
        if (this.state.navigateTo) return <NerdHerderNavigate to={this.state.navigateTo}/>
        if (this.props.localUser === null) return(<NerdHerderLoadingCard title="Delete Account"/>);

        return(
            <NerdHerderStandardCardTemplate id="delete-account-card" title="Delete Account" titleIcon='no-stopping.png'>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.userFeedback &&
                <Alert variant='primary'>{this.state.userFeedback}</Alert>}
                <div className='mb-3'>
                    <small>If you wish to delete your account, click the button below three times. There is no undo.</small>
                </div>
                <TripleDeleteButton disabled={this.state.updating} onFinalClick={()=>this.deleteAccount()}/>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(ProfilePage);
