import React from 'react';
import Card from 'react-bootstrap/Card';
import he from 'he';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { CardErrorBoundary } from './NerdHerderErrorBoundary';

export class OpenGraphCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='OpenGraphCardInner'>
                <OpenGraphCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

export class OpenGraphCardInner extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.url === 'undefined') console.error('missing props.url');

        this.restApi = new NerdHerderRestApi();

        this.state = {
            ogInfo: null,
            fetchedUrl: null,
        }
    }

    componentDidMount() {
        if (this.props.url) this.fetchOpenGraphInfo(this.props.url);
    }

    fetchOpenGraphInfo(url) {
        this.restApi.genericGetEndpointData('open-graph-info', null, {url: encodeURI(url)})
        .then((response)=>{
            if (response.data.title !== null && response.data.title.length !== 0) {
                this.setState({ogInfo: response.data, fetchedUrl: url});
            }
        })
        .catch((error)=>{
            console.error(error);
        })
    }

    onImageError(event) {
        console.log(event);
        event.target.style.display='none';
    }

    render() {
        if (!this.props.url) return(null);
        if (!this.state.ogInfo) return(null);
        if (this.state.fetchedUrl !== this.props.url) {
            this.setState({fetchedUrl: null, ogInfo: null});
            setTimeout(()=>this.fetchOpenGraphInfo(this.props.url), 1000);
        }

        let description = null;
        if (this.state.ogInfo.description) description = he.decode(this.state.ogInfo.description);

        return (
            <a className='text-decoration-none' href={this.props.url} target='_blank' rel='noreferrer'>
                <Card className='shadow-sm text-start' bg='dark' text='white'>
                    {this.state.ogInfo.site_name &&
                    <Card.Header as='h5' className='text-white'><b>{this.state.ogInfo.site_name}</b></Card.Header>}
                    {this.state.ogInfo.image &&
                    <Card.Img variant="top" src={this.state.ogInfo.image} onError={(e)=>this.onImageError(e)}/>}
                    <Card.Body>
                        <Card.Title className='text-white'>
                            {this.state.ogInfo.title}
                        </Card.Title>
                        {description &&
                        <Card.Text>
                            <small className='text-white-50'>{description}</small>
                        </Card.Text>}
                    </Card.Body>
                </Card>
            </a> 
        )
    }
}
