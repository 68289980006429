import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Linkify from 'react-linkify';
import he from 'he';
import CurrencyInput from 'react-currency-input-field';
import currency from 'currency.js';
import { AsyncTypeahead} from 'react-bootstrap-typeahead';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';

// this little fucker is magic
// given a react ref (this.ref.current) and a value, it will 'simulate' the user typing something there
// this is a good way to get input into component / component fields where there is no setter
export function changeFormNativeValue(elementRef, value) {
    setFormNativeValue(elementRef, value);
    elementRef.dispatchEvent(new Event('change', {bubbles: true}));
}

export function setFormNativeValue(elementRef, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(elementRef, 'value').set;
    const prototype = Object.getPrototypeOf(elementRef);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(elementRef, value);
    } else {
        valueSetter.call(elementRef, value);
    }
}

export class FormErrorText extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.errorId === 'undefined') console.error('missing props.errorId');
        if (typeof this.props.errorState === 'undefined') console.error('missing props.errorState');
    }

    render() {
        if (!this.props.errorState.hasOwnProperty(this.props.errorId)) return(null);

        return (
            <div>
                <Form.Text className='text-danger'><b>{this.props.errorState[this.props.errorId]}</b></Form.Text>
            </div>
        )
    }
}

export class FormTimeout {
    constructor(timeout = 500) {
        this.defaultTimeout = timeout;
        this.existingTimeouts = {};
    }

    setTimeout(callback, name, timeout=null) {
        if (timeout === null) {
            timeout = this.defaultTimeout;
        }

        if (this.existingTimeouts.hasOwnProperty(name)) {
            clearTimeout(this.existingTimeouts[name]);
        }

        this.existingTimeouts[name] = setTimeout(callback, timeout);
    }
}

export function setErrorState(errorId, errorState, message) {
    errorState[errorId] = message;
    return errorState;
}

export function clearErrorState(errorId, errorState) {
    if (errorState.hasOwnProperty(errorId)) {
        delete errorState[errorId];
    }
    return errorState;
}

export function getFormErrors(error) {
    if (error.response.data.message === 'invalid data') {
        return error.response.data.invalid_data;
    }
    return null;
}

export class FormTextInputLimit extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.max === 'undefined') console.error('missing props.max');
        if (typeof this.props.current === 'undefined') console.error('missing props.current');
    }

    render() {
        return (
            <div className='text-muted px-1' style={{position: 'absolute', top: '-8px', right: '5px',
                                                border: '1px solid #ced4da', borderRadius: '5px', fontSize: '10px',
                                                backgroundColor: 'white'}}>
                {`${this.props.current}/${this.props.max}`}
            </div>
        )
    }
}

export class FormTypeahead extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.endpoint === 'undefined') console.error('missing props.endpoint');
        if (typeof this.props.queryParams === 'undefined') console.error('missing props.queryParams');

        this.restApi = new NerdHerderRestApi();
        this.myid = this.getRandomIdString();
        this.typeaheadRef = React.createRef();

        this.latestQuery = null;

        this.state = {
            isLoading: false,
            options: [],
            selected: [],
        }
    }

    getRandomIdString() {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = characters.charAt(Math.floor(Math.random() * 26));
        for (let i=0; i<10; i++) {
          result += characters.charAt(Math.floor(Math.random() * 62));
        }
        return `username-typeahead-${result}`;
    }

    populateUsernameOptions(query) {
        let triggerLength = 3;
        if (this.props.triggerLength) triggerLength = this.props.triggerLength;

        // make a copy of the query params, and update them to replace 'query' with the actual query
        let dupQueryParams = null;
        if (this.props.queryParams) {
            dupQueryParams = {...this.props.queryParams};
            for (const [key, value] of Object.entries(dupQueryParams)) {
                if (value === 'query') {
                    dupQueryParams[key] = query;
                }
            }
        }

        if (query.length >= triggerLength) {
            console.debug(`FormLookahead doing search for ${query} at endpoint ${this.props.endpoint}`);
            this.setState({isLoading: true});
            this.latestQuery = query;
            this.restApi.genericGetEndpointData(this.props.endpoint, null, dupQueryParams)
            .then((response)=>{
                this.setState({options: response.data, isLoading: false});
            })
            .catch((error)=>{
                this.setState({options: [], isLoading: false});
                console.error(error);
            });
        } else {
            this.latestQuery = null;
            this.setState({options: [], isLoading: false});
        }
    }

    onChange(selected) {
        this.setState({selected: selected});
        if (selected.length === 0) {
            if (this.props.onChange) this.props.onChange(null);
        } else {
            if (this.props.onChange) this.props.onChange(selected[0]);
        }
        
    }

    getSelected() {
        if (this.state.selected.length === 0) return null;
        return this.state.selected[0];
    }

    getQuery() {
        return this.latestQuery;
    }

    clear() {
        if (this.typeaheadRef && this.typeaheadRef.current) {
            this.typeaheadRef.current.clear();
        }
        this.setState({selected: []});
    }

    render() {
        let filterBy = undefined;
        if (this.props.disableFilter) {
            filterBy = ()=>true;
        }

        return (
            <AsyncTypeahead id={this.myid}
                            ref={this.typeaheadRef}
                            placeholder={this.props.placeholder || 'Username to filter on'}
                            delay={this.props.delay || 500}
                            labelKey={this.props.labelKey || 'username'}
                            isLoading={this.state.isLoading}
                            onSearch={(query)=>this.populateUsernameOptions(query)}
                            options={this.state.options}
                            onChange={(selected)=>{this.onChange(selected)}}
                            selected={this.state.selected}
                            flip={true}
                            filterBy={filterBy}
                            disabled={this.props.disabled || this.state.isSearching}/>
        )
    }
}

export class TripleDeleteButton extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onFinalClick === 'undefined') console.error('missing props.onFinalClick');

        this.state = {
            deleteState: 0,
            deleteButtonDisabled: false,
        }
    }

    // this can be called with a ref
    reset() {
        this.setState({deleteState: 0, deleteButtonDisabled: false});
    }

    onDeleteTimerExpired() {
        this.setState({deleteButtonDisabled: false});
    }

    onDeleteClicked(event) {
        switch(this.state.deleteState) {
            case 0:
                this.setState({deleteState: 1, deleteButtonDisabled: true});
                setTimeout(()=>this.onDeleteTimerExpired(), 1500);
                break;
            case 1:
                this.setState({deleteState: 2, deleteButtonDisabled: true});
                setTimeout(()=>this.onDeleteTimerExpired(), 1500);
                break;
            case 2:
                this.setState({deleteButtonDisabled: true});
                this.props.onFinalClick(event);
                break;
            default:
                this.setState({deleteState: 0, deleteButtonDisabled: false});
        }
    }

    render() {
        let warningMessage = null;
        let buttonLabel = this.props.label || 'Delete';
        switch(this.state.deleteState) {
            case 0:
                warningMessage = null;
                buttonLabel = this.props.label || 'Delete';
                break;
            case 1:
                warningMessage = <b>This cannot be undone, are you sure?</b>
                buttonLabel = 'Yes, I am sure';
                break;
            case 2:
                warningMessage = <b>Final warning! Click once more to delete...</b>
                buttonLabel = 'DELETE!!!';
                break;
            default:
                warningMessage = null;
        }

        let deleteButtonDisabled = this.props.disabled || false;
        if (this.state.deleteButtonDisabled) deleteButtonDisabled = true;

        return (
            <div>
                {warningMessage}
                <div className="d-grid gap-2">
                    <Button size='sm' variant="danger" onClick={(e)=>{this.onDeleteClicked(e)}} disabled={deleteButtonDisabled}>{buttonLabel}</Button>
                </div>
            </div>
        )
    }
}

export class FormControlSubmit extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onClick === 'undefined') console.error('missing props.onClick');

        this.state = {
            currentValue: this.props.value || '',
        }
    }

    // this can be called from a ref
    reset() {
        this.setState({currentValue: this.props.value || ''});
    }

    onClickSubmit() {
        this.props.onClick(this.state.currentValue);
    }

    onChangeValue(event) {
        let value = event.target.value;
        if (this.props.onChangeValueIntercept) {
            value = this.props.onChangeValueIntercept(value);
        }
        this.setState({currentValue: value});
    }

    render() {
        let disableAcceptButton = this.props.disabled;
        // eslint-disable-next-line eqeqeq
        if (this.state.currentValue == this.props.value) disableAcceptButton = true;

        // don't want to pass onClick, onChange, or value to the form.control
        const {onClick, onChange, value, onChangeValueIntercept, ...newProps} = this.props;

        return(
            <InputGroup>
                <Form.Control {...newProps} onChange={(e)=>this.onChangeValue(e)} value={this.state.currentValue}/>
                <Button size='sm' variant="primary" disabled={disableAcceptButton} onClick={()=>this.onClickSubmit()}>
                    <NerdHerderFontIcon icon='flaticon-verification-sign'/>
                </Button>
                <Button size='sm' variant="secondary" disabled={this.props.disabled} onClick={()=>this.reset()}>
                    <NerdHerderFontIcon icon='flaticon-cross-sign'/>
                </Button>
            </InputGroup>
        );
    }
}

export class FormControlSearch extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.onClick === 'undefined') console.error('missing props.onClick');

        this.state = {
            currentValue: this.props.value || '',
        }
    }

    onClickSearch() {
        this.props.onClick(this.state.currentValue);
    }

    onChangeValue(event) {
        const value = event.target.value;
        this.setState({currentValue: value});
    }

    render() {
        let disableSearchButton = this.props.disabled;

        // don't want to pass onClick, onChange, or value to the form.control
        const {onClick, onChange, value, ...newProps} = this.props;

        return(
            <InputGroup>
                <Form.Control {...newProps} onChange={(e)=>this.onChangeValue(e)} value={this.state.currentValue}/>
                <Button size='sm' variant="primary" disabled={disableSearchButton} onClick={()=>this.onClickSearch()}>
                    <NerdHerderFontIcon icon='flaticon-search'/>
                </Button>
            </InputGroup>
        );
    }
}

export class EmailLink extends React.Component {

    render() {
        if (this.props.defaultSubject) {
            return (
                <a target='_blank' rel='noreferrer' href={`mailto:helpdesk@ashparkdigital.com?subject=${encodeURIComponent(this.props.defaultSubject)}`}>helpdesk@ashparkdigital.com</a>
            );
        }
        return(
            <a target='_blank' rel='noreferrer' href="mailto:helpdesk@ashparkdigital.com">helpdesk@ashparkdigital.com</a>
        );
    }
}

export class LinkifyText extends React.Component {

    render() {
        return(
            <Linkify componentDecorator={(decoratedHref, decoratedText, key)=>(<a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>{decoratedText}</a>)}>
                {this.props.children}
            </Linkify>
        );
    }
}

export class FormattedText extends React.Component {

    render() {
        let lines = this.props.text.split('\n');

        const result = [];
        for (let i=0; i<lines.length; i++) {
            let line = lines[i];
            line = he.decode(line);
            line = <LinkifyText>{line}</LinkifyText>
            if (i === 0) {
                result.push(<span key={`line-${i}`}>{line}</span>);
            } else {
                if (line.length !== 0) {
                    result.push(<br key={`br-${i}`}/>);
                    result.push(<span key={`line-${i}`}>{line}</span>);
                }
            }
        }

        return (
            <span>{result}</span>
        );
    }
}

export class FormCurrencyInput extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.minorDigits === 'undefined') console.error('missing props.minorDigits');
        this.currencyInputRef = React.createRef();
        this.currencyValue = null;
        this.decimalSeparator = '.';
        this.minorDigits = 0;
        this.symbol = '';
        this.setDefaultOnce = true;
        this.setCurrencyValue(0);
    }

    // internal function
    setCurrencyValue(amount) {
        this.currencyValue = currency(amount, {fromCents: true, decimal: this.decimalSeparator, symbol: this.symbol, precision: this.minorDigits});
    }

    setDefault() {
        this.setDefaultOnce = false;
        if (typeof this.props.defaultValue === 'undefined') return;
        if (this.props.defaultValue === null) return;
        this.setValue(this.props.defaultValue);
    }

    setValue(amount) {
        this.setCurrencyValue(amount);
        let stringValue = this.currencyValue.format();
        if (this.currencyInputRef.current){
            changeFormNativeValue(this.currencyInputRef.current, stringValue);
        } 
    }

    getValue() {
        return(this.currencyValue.intValue);
    }

    getCurrency() {
        return(this.currencyValue);
    }

    onChange(value, name) {
        this.currencyValue = currency(value, {fromCents: false, decimal: this.decimalSeparator, symbol: this.symbol, precision: this.minorDigits});
        if (this.props.onChange) {
            this.props.onChange(this.currencyValue.intValue, name);
        }
    }

    render() {
        // if the defaults have not been set, set them
        if (this.setDefaultOnce) setTimeout(()=>this.setDefault(), 0);

        // save a copy of what we currently are using
        if (this.props.decimalSeparator) this.decimalSeparator = this.props.decimalSeparator;
        if (this.props.symbol) this.symbol = this.props.symbol;
        if (this.props.minorDigits) this.minorDigits = this.props.minorDigits;
        
        let allowDecimals = true;
        if (this.minorDigits === 0) allowDecimals = false;

        let groupSeparator = ',';
        if (this.decimalSeparator === ',') groupSeparator = '.';

        let className = 'form-control';
        if (this.props.size === 'sm') className = 'form-control form-control-sm';

        return (
            <CurrencyInput
                ref={this.currencyInputRef}
                key={this.randomKey}
                className={className}
                id={this.props.id}
                name={this.props.name}
                placeholder={this.props.placeholder || 'Enter a value'}
                prefix={this.symbol}
                allowDecimals={allowDecimals}
                decimalSeparator={this.decimalSeparator}
                groupSeparator={groupSeparator}
                step={1}
                allowNegativeValue={this.props.allowNegativeValue || false}
                disableAbbreviations={true}
                disabled={this.props.disabled}
                decimalsLimit={this.minorDigits}
                decimalScale={this.minorDigits}
                onValueChange={(value, name)=>this.onChange(value, name)} />
        )
    }
}