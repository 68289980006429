import React from 'react';
import jwt_decode from "jwt-decode";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Collapse from 'react-bootstrap/Collapse';
import withRouter from './withRouter';
import { Navigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { CaptchaNotice } from './nerdherder-components/NerdHerderCaptcha';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { getCookie, delCookie, setLocal, delLocal, getCookieParseBool, getLocalStaticFileUrl, getStaticStorageImageFilePublicUrl, getFailureMessage, browserSupportsWebAuthn, arrayBufferToBase64, base64ToArrayBuffer, setCookie } from './utilities';
import { CBORencode, CBORdecode } from './cbor';
import { NerdHerderConfirmModal, NerdHerderForgotPasswordModal, NerdHerderQrScanModal, NerdHerderSelectAccountTypeModal, NerdHerderCreateGoogleAccountModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.webauthnPackedData = 'none';
        this.captcha = React.createRef();
        let rememberMe = getCookieParseBool('RememberMe');

        this.state = {
            navigateTo: null,
            showForgotPasswordModal: false,
            showNoGoogleAccountModal: false,
            showSelectAccountTypeModal: false,
            showCreateGoogleAccountModal: false,
            showQrModal: false,
            localUser: null,
            versionInfo: null,
            sessionInfo: null,
            username: '',
            password: '',
            passwordType: 'password',
            rememberMe: rememberMe,
            userFeedback: null,
            expandVersion: false,
            googleDivWidth: null,
        }

        this.restApi = new NerdHerderRestApi();
        if (!rememberMe) this.restApi.firebaseSignOut();
        this.googleJwt = 'none';
        this.googleJwtDecoded = null;
        this.googleDivRef = React.createRef();
        delLocal('FirebaseToken');
    }

    componentDidMount() {
        this.restApi.genericGetEndpointData('version-info')
        .then(response => {
            this.updateVersionInfo(response.data);
        }).catch(error => {
            console.warn('failed to get version info');
            console.warn(error);
        });
        
        // if the user is already logged in, the JWT is not expired, and they have remember me set, just go to main
        let loginToken = getCookie('LoginToken');
        if (loginToken) {
            loginToken = jwt_decode(loginToken);
            let now = Date.now() / 1000;
            if (loginToken.exp < now) {
                console.debug('JWT is expired');
                loginToken = null;
                delCookie('LoginToken');
                delLocal('LoginToken');
                delLocal('FirebaseToken');
            }
        }
        if (this.state.rememberMe && loginToken) {
            this.setState({navigateTo: '/app/main'});
        }
    }

    componentWillUnmount() {
    }

    updateLocalUser(userData, key) {
        let newData = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: newData});
    }

    updateVersionInfo(versionInfo) {
        this.setState({versionInfo: versionInfo});
    }

    setGoogleDivWidth(element) {
        if (element) {
            let width = element.offsetWidth;
            if (this.state.googleDivWidth !== width) {
                this.setState({googleDivWidth: width});
            }
        }
    }

    packAssertion(assertion) {
        const assertionData = {
            'credentialRawId': new Uint8Array(assertion.rawId),
            'authenticatorData': new Uint8Array(assertion.response.authenticatorData),
            'clientDataJSON': new Uint8Array(assertion.response.clientDataJSON),
            'signature': new Uint8Array(assertion.response.signature),
            'userHandle': new Uint8Array(assertion.response.userHandle)
        };
        return arrayBufferToBase64(CBORencode(assertionData));
    }

    doWebauthnAuthentication(webauthnAuthenticationData) {
        this.setState({userFeedback: <Alert variant="warning">Complete Biometrics on Device</Alert>});
        let pkcro = CBORdecode(base64ToArrayBuffer(webauthnAuthenticationData));
        navigator.credentials.get(pkcro)
        .then((assertionResponse) => {
            this.setState({userFeedback: <Alert variant="primary">Verifying Biometric Data...</Alert>});
            this.webauthnPackedData = this.packAssertion(assertionResponse);
            this.handleWebAuthnSecondPost();
        })
        .catch((err) => {
            console.error(err);
            if (err.name === 'NotAllowedError') {
                this.setState({userFeedback: <Alert variant="warning">Sign in failed (canceled or not allowed)</Alert>});
            }
            else {
                this.setState({userFeedback: <Alert variant="warning">{`Sign in failed (${err.name})`}</Alert>});
            }
            this.setState({webauthnData: 'none'});
        });
    }

    handleUsernameChange(event) {
        this.setState({username: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleRememberMeChange(event) {
        this.setState({rememberMe: event.target.checked});
    }

    handleGoogleLoginResponse(success, response) {
        if (success) {
            this.googleJwt = response.credential
            this.googleJwtDecoded = jwt_decode(this.googleJwt);
            this.setState({username: this.googleJwtDecoded.email, password: ''});
            setTimeout(()=>this.handleLogin(), 200);
        } else {
            console.log('got failure google response');
            console.log(response);
        }
    }

    handleLogin() {
        if (this.googleJwt !== null && this.googleJwt !== 'none') {
            this.setState({userFeedback: <Alert variant="primary">Signing in with Google...</Alert>});
        } else if (this.state.password === '') {
            this.setState({userFeedback: <Alert variant="primary">Signing in with Biometrics</Alert>});
        } else {
            this.setState({userFeedback: <Alert variant="primary">Signing in...</Alert>});
        }

        const loginData = {
            username: this.state.username.trimEnd(),
            password: this.state.password,
            remember_me: this.state.rememberMe,
            webauthn_authentication_data: 'none',
            google_authentication_data: this.googleJwt,
            captcha_token: 'bogustoken',
        }
        this.restApi.genericPostEndpointData('user-login', null, loginData)
        .then((response) => {
            console.debug('login success');
            const loginData = response.data;
            if (loginData.webauthn !== null) {
                console.debug('doing webauthn');
                this.doWebauthnAuthentication(loginData.webauthn);
            } else {
                setCookie('LoginToken', loginData.login_token, 358);
                setLocal('LoginToken', loginData.login_token);
                setLocal('UserId', loginData.self.id);
                if (this.state.rememberMe) {
                    setCookie('RememberMe', true, 358);
                } else {
                    setCookie('RememberMe', false, 358);
                }
                this.setState({userFeedback: <Alert variant="primary">Sign in success!</Alert>});
                setTimeout(()=>this.handleSuccessLogin(), 500);
            }
        }).catch((error) => {
            console.warn('login failed');
            console.warn(error);
            delCookie('LoginToken');
            delLocal('LoginToken');
            delLocal('UserId');
            delLocal('FirebaseToken');
            let failureMessage = getFailureMessage(error, 'Sign in failed');
            if (failureMessage.toLowerCase() === 'incorrect username or password' && this.googleJwtDecoded !== null) {
                this.setState({showNoGoogleAccountModal: true, userFeedback: <Alert variant="warning">Unrecognized Google account</Alert>});
            } else {
                this.setState({userFeedback: <Alert variant="warning">{failureMessage}</Alert>});
            }
        });
    }

    handleWebAuthnSecondPost() {
        const loginData = {
            username: this.state.username,
            password: this.state.password,
            remember_me: this.state.rememberMe,
            webauthn_authentication_data: this.webauthnPackedData,
            google_authentication_data: 'none',
            captcha_token: 'webauthn',
        }
        this.restApi.genericPostEndpointData('user-login', null, loginData)
        .then(response => {
            console.debug('webauthn login success');
            const loginData = response.data;
            setCookie('LoginToken', loginData.login_token, 358);
            setLocal('LoginToken', loginData.login_token);
            setLocal('UserId', loginData.self.id);
            delLocal('FirebaseToken');
            if (this.state.rememberMe) {
                setCookie('RememberMe', true, 358);
            } else {
                setCookie('RememberMe', false, 358);
            }
            this.setState({userFeedback: <Alert variant="primary">Biometric sign in success!</Alert>});
            setTimeout(()=>this.handleSuccessLogin(), 500);
        }).catch(error => {
            console.warn('webauthn login failed');
            console.log(error);
            delCookie('LoginToken');
            delLocal('LoginToken');
            delLocal('UserId');
            delLocal('FirebaseToken');
            this.setState({webauthnData: 'none', userFeedback: <Alert variant="warning">{getFailureMessage(error)}</Alert>});
        });
    }

    handleSuccessLogin() {
        let fallbackUrl = '/app/main'
        let desiredUrl = getCookie('DesiredUrl', fallbackUrl);
        if (desiredUrl.includes('/app/login')) {
            delCookie('DesiredUrl');
            desiredUrl = fallbackUrl;
        } else if (!desiredUrl.includes('/app/')) {
            delCookie('DesiredUrl');
            desiredUrl = fallbackUrl;
        }
        this.setState({navigateTo: desiredUrl});
    }

    onPasswordShow() {
        let newType = 'password';
        if (this.state.passwordType === 'password') newType = 'text';
        this.setState({passwordType: newType});
    }

    onCreateGoogleAccount(googleJwt) {
        this.googleJwt = googleJwt;
        this.setState({showSelectAccountTypeModal: false, showCreateGoogleAccountModal: true});
    }

    onQrScanLogin(decodedText, decodedResult) {
        setCookie('LoginToken', decodedText, 358);
        setLocal('LoginToken', decodedText);
        let jwtDecoded = jwt_decode(decodedText);
        setLocal('UserId', jwtDecoded.user_id);
        delLocal('FirebaseToken');
        setCookie('RememberMe', true, 358);
        this.setState({showQrModal: false});
        setTimeout(()=>this.handleSuccessLogin(), 500);
    }

    render() {
        if (this.state.navigateTo) {
            console.log('successful login, redirecting');
            return(<Navigate to={this.state.navigateTo}/>);
        }
        let versionInfoHtml = null;
        let versionInfoVersion = null;

        if (this.state.versionInfo) {
            versionInfoVersion = this.state.versionInfo.version;
            versionInfoHtml = this.state.versionInfo.html.replace(/['"]+/g, '');
        }

        let supportsWebauthn = browserSupportsWebAuthn();
        let loginDisabled = false;
        if (this.state.username === null || this.state.username.length < 6) {
            loginDisabled = true;
        } else if (!supportsWebauthn && (this.state.password === null || this.state.password.length < 1)){
            loginDisabled = true;
        }

        return (
            <Container className="py-5 h-100">
                <GoogleOAuthProvider clientId='986217896990-4jekqeps6512tpjcere4ucnhkur5ps96.apps.googleusercontent.com'>
                    {this.state.showForgotPasswordModal &&
                    <NerdHerderForgotPasswordModal onCancel={()=>this.setState({showForgotPasswordModal: false})}/>}
                    {this.state.showNoGoogleAccountModal &&
                    <NerdHerderConfirmModal title='No Existing Google Account' message={<span>We could not find an account on NerdHerder associated with {this.googleJwtDecoded.email}.<br/><br/>Would you like to create a new account?</span>}
                                            acceptButtonText='Yes' cancelButtonText='No'
                                            onAccept={()=>{this.googleJwtDecoded=null;this.setState({showCreateGoogleAccountModal: true, showNoGoogleAccountModal: false})}} onCancel={()=>this.setState({showNoGoogleAccountModal: false})}/>}
                    {this.state.showSelectAccountTypeModal &&
                    <NerdHerderSelectAccountTypeModal onAccept={(j)=>this.onCreateGoogleAccount(j)} onCancel={()=>this.setState({showSelectAccountTypeModal: false})}/>}
                    {this.state.showCreateGoogleAccountModal &&
                    <NerdHerderCreateGoogleAccountModal googleJwt={this.googleJwt} onCancel={()=>this.setState({showCreateGoogleAccountModal: false})}/>}
                    {this.state.showQrModal &&
                    <NerdHerderQrScanModal onCancel={()=>this.setState({showQrModal: false})} onScan={(dt, dr)=>this.onQrScanLogin(dt, dr)}>
                        <div>
                            On the signed-in device:
                            <ul>
                                <li>Open the Navagation Menu (<NerdHerderFontIcon icon='flaticon-menu-button-of-three-horizontal-lines'/>)</li>
                                <li>Click settings (<NerdHerderFontIcon icon='flaticon-configuration-with-gear'/>)</li>
                                <li>Select Share Sign In, and scan the QR code shown</li>
                            </ul>
                        </div>
                    </NerdHerderQrScanModal>}
                    <Row className="d-flex justify-content-center align-items-center h-100">
                        <Col xl={4} lg={5} md={6} sm={9} xs={11}>
                            <NerdHerderStandardCardTemplate>
                                <Row className="mb-1 justify-content-center align-items-center">
                                    <Col xs="auto">
                                        <a href="/index">
                                            <Image src={getLocalStaticFileUrl("/nerdherder_light_small.png")} alt="nerdherder logo" height="70"/>
                                        </a>
                                    </Col>
                                    <Col className="col">
                                        <h3 className="text-primary">Welcome to NerdHerder!</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <hr/>
                                        { this.state.userFeedback }
                                        <Form>
                                            <Form.Group className="mb-4">
                                                <Form.Label>Username or Email</Form.Label>
                                                <Row>
                                                    <Col>
                                                        <Form.Control id='username' name='username' type="text" autoComplete="username" onChange={(event)=>this.handleUsernameChange(event)} value={this.username}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="form-outline mb-4">
                                                <Form.Label>Password</Form.Label>
                                                <Row>
                                                    <Col>
                                                        <Form.Control id='current-password' name='current-password' type={this.state.passwordType} autoComplete="current-password" onChange={(event)=>this.handlePasswordChange(event)} value={this.password}/>
                                                    </Col>
                                                    <Col xs='auto' className='ms-0 ps-0'>
                                                        <Button variant='light' onClick={()=>this.onPasswordShow()}><NerdHerderFontIcon icon='flaticon-eye-opened-symbol-with-an-eyebrow'/></Button>
                                                    </Col>
                                                </Row>
                                                {supportsWebauthn &&
                                                <Form.Text className="text-muted">
                                                    <span><Image src={getStaticStorageImageFilePublicUrl("/fingerprint-scan.png")} alt="finger scan icon" height="15"/> Leave password blank for biometric sign in</span>
                                                </Form.Text>}
                                            </Form.Group>
                                            <Form.Group className="form-outline mb-4">
                                                <Form.Check type="checkbox" label="Remember me" onChange={(event)=>this.handleRememberMeChange(event)} checked={this.state.rememberMe}/>
                                            </Form.Group>
                                            <Form.Group className="mb-4">
                                                <div id='google-login' ref={(e)=>this.setGoogleDivWidth(e)} className="d-grid gap-2">
                                                    <Button variant="primary" size="lg" type="button" disabled={loginDisabled} onClick={()=>this.handleLogin()}>Sign In</Button>
                                                    {this.state.googleDivWidth >= 400 &&
                                                    <Row className='justify-content-center'>
                                                        <Col xs='auto'>
                                                            <GoogleLogin onSuccess={(r)=>this.handleGoogleLoginResponse(true, r)}
                                                                 onError={(r)=>this.handleGoogleLoginResponse(false, r)}
                                                                 width={this.state.googleDivWidth}
                                                                 size='large'
                                                                 context='signin'/>
                                                        </Col>
                                                    </Row>}
                                                    {this.state.googleDivWidth < 400 &&
                                                    <GoogleLogin onSuccess={(r)=>this.handleGoogleLoginResponse(true, r)}
                                                                 onError={(r)=>this.handleGoogleLoginResponse(false, r)}
                                                                 width={this.state.googleDivWidth}
                                                                 size='large'
                                                                 context='signin'/>}
                                                    <CaptchaNotice singleLine={true}/>
                                                </div>
                                            </Form.Group>
                                            <Row className='align-items-end'>
                                                <Col>
                                                        <div className="text-start">
                                                            <Button id='qr-login' className='p-0' variant='link' onClick={()=>{this.setState({showQrModal: true})}}>Sign in from another device</Button>
                                                        </div>
                                                        <div className="text-start">
                                                            <Button id='new-user' className='p-0' variant='link' onClick={()=>{this.setState({showSelectAccountTypeModal: '/app/newuser'})}}>I'm new, sign me up!</Button>
                                                        </div>
                                                        <div className="text-start">
                                                            <Button id='forgot-password' className='p-0' variant='link' onClick={()=>{this.setState({showForgotPasswordModal: true})}}>Forgot password</Button>
                                                        </div>
                                                </Col>
                                                <Col sm='auto'>
                                                    <div className="text-end">
                                                        {this.state.versionInfo &&
                                                        <span className='cursor-pointer' onClick={()=>this.setState({expandVersion: !this.state.expandVersion})}><small><Badge bg='primary'>{versionInfoVersion}</Badge></small></span>}
                                                    </div>
                                                </Col>
                                            </Row>                                        
                                        </Form>
                                        {this.state.versionInfo &&
                                        <Collapse in={this.state.expandVersion}>
                                            <div className="card card-body bg-light text-start m-0 p-1" dangerouslySetInnerHTML={{ __html:versionInfoHtml }}/>
                                        </Collapse>}
                                    </Col>
                                </Row>
                            </NerdHerderStandardCardTemplate>
                        </Col>
                    </Row>
                </GoogleOAuthProvider>
            </Container>
        );
    }
}

export default withRouter(LoginPage);
