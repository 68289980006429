import React from 'react';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';
import { NerdHerderToolTipButton } from './NerdHerderToolTip';
import { NerdHerderVerticalScroller } from './NerdHerderScroller';
import { NerdHerderDataModelFactory } from '../nerdherder-models';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';
import { NerdHerderUserProfileModal } from './NerdHerderModals';
import { batchExecuteSmallChunks } from '../utilities';

export class TableOfUsers extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');
        if (typeof this.props.userIds === 'undefined') console.error('missing props.userIds (should be a list of user IDs)');
        
        // can get the users dict from the caller (loadUsers=false), or keep it internally (loadUsers=true)
        // if there are a bunch of users, user the user-summary-dict api (it's slower but doesn't blast the server with calls)
        this.loadUsers = true;
        this.bulkLoadUsers = false;
        if (this.props.users) {
            this.loadUsers = false;
        }
        if (this.loadUsers && this.props.userIds.length > 8) {
            this.bulkLoadUsers = true;
        }

        this.state = {
            showUserProfileModal: false,
            userProfileModalId: null,
            users: {},
        }
        
        this.backgroundLoadingIds = [];
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {
        // if we need to load a bunch of users - grab it in advance using the user-summary-dict api
        if(this.loadUsers && this.bulkLoadUsers) {
            // mark that all of the ids are being background loaded
            for (const userId of this.props.userIds) {
                this.backgroundLoadingIds.push(userId);
            }

            // fetch the dict from the api - get up to 20 at a time
            batchExecuteSmallChunks(this.props.userIds, 20, 1000, (list)=>this.fetchBulkUsers(list));
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    fetchUser(userId) {
        // if managing our own users, try to grab the user from state...
        // if we don't have it, but are loading it just return null...
        // but if we havn't started loading it, start and mark that it is loading
        if (this.loadUsers) {
            if (this.state.users.hasOwnProperty(userId)) {
                return this.state.users[userId];
            } else if (this.bulkLoadUsers || this.backgroundLoadingIds.includes(userId)) {
                return null;
            } else {
                const sub = this.restPubSub.subscribeToFirestore('user-summary', userId, (d, k)=>this.updateUser(d, k), null, userId);
                this.restPubSubPool.add(sub);
                this.backgroundLoadingIds.push(userId);
                return null;
            }
        }
        // if not managing our own users, grab it...or hope something else is loading it!
        else {
            if (this.props.users.hasOwnProperty(userId)) {
                return this.props.users[userId];
            }
            return null;
        }
    }

    fetchBulkUsers(userIdList) {
        console.log(`fetchBulkUsers(${userIdList.toString()})`);
        this.restApi.genericGetEndpointData('user-summary-dict', null, {list: userIdList.toString()}, true)
        .then((response)=>{
            let userDict = response.data;
            for (const [userId, userData] of Object.entries(userDict)) {
                // create a user for each entry in the dict
                this.updateUser(userData, userId);
            } 
        })
        .catch((error)=>{
            console.error('failed to fetch user-summary-dict for table');
            console.error(error);
        });
    }

    updateUser(userData, userId) {
        const newUser = NerdHerderDataModelFactory('user', userData);
        this.setState((state) => {
            return {users: {...state.users, [userId]: newUser}}
        });

        // if the user was updated, then they are 'loaded' and should be removed from the backgroundLoadingIds list
        const index = this.backgroundLoadingIds.indexOf(userId);
        if (index !== -1 ) this.backgroundLoadingIds.splice(index, 1)
    }

    onClick(userId) {
        if (this.props.onClick) {
            this.props.onClick(userId);
        } else {
            this.setState({showUserProfileModal: true, userProfileModalId: userId});
        }
    }

    render() {
        const headers = [];
        const rows = [];

        if (this.props.headers) {
            let headerNumber = 0;
            for (const headerText of this.props.headers) {
                let header = null;
                if (typeof headerText === 'string' && headerText.includes('button')) {
                    header = <th key={`tableheader-${headerNumber}`} style={{width:'40px'}}/>
                } else {
                    header = <th key={`tableheader-${headerNumber}`}>{headerText}</th>
                }
                headers.push(header);
                headerNumber += 1;
            }
        } else {
            headers.push(<th key={'default-header'}/>);
        }

        if (this.props.showDeleteButton) {
            headers.push(<th key={'delete-header'} style={{width:'40px'}}/>);
        }

        for (const userId of this.props.userIds) {
            const userData = this.fetchUser(userId);
            if (userData === null) continue;
            // if props.showDeleteButton is simply true - just show the button, but if its an array only show it if the userId is in the array
            let showDeleteButton = false;
            if (Array.isArray(this.props.showDeleteButton)) {
                if (this.props.showDeleteButton.includes(userId)) showDeleteButton = true;
            } else {
                if (this.props.showDeleteButton) showDeleteButton = true;
            }

            let rowLeftColumnContent = null;
            let rowMiddleColumnContent = null;
            let rowRightColumnContent = null;
            if (this.props.leftColumnContent && this.props.leftColumnContent.hasOwnProperty(userId)) {
                rowLeftColumnContent = this.props.leftColumnContent[userId];
            }
            if (this.props.middleColumnContent && this.props.middleColumnContent.hasOwnProperty(userId)) {
                rowMiddleColumnContent = this.props.middleColumnContent[userId];
            }
            if (this.props.rightColumnContent && this.props.rightColumnContent.hasOwnProperty(userId)) {
                rowRightColumnContent = this.props.rightColumnContent[userId];
            }

            // a goofy thing can happen if the column contents are the integer value zero - this is a work-around
            if (rowLeftColumnContent === 0) rowLeftColumnContent = '0';
            if (rowMiddleColumnContent === 0) rowMiddleColumnContent = '0';
            if (rowRightColumnContent === 0) rowRightColumnContent = '0';

            let extraId = null;
            if (this.props.extraId) {
                switch(this.props.extraId) {
                    case 'wizards':
                        extraId = userData.wizards_id || 'No Wizards ID';
                        break;
                    case 'pokemon':
                        extraId = userData.pokemon_id || 'No Pokemon ID';
                        break;
                    case 'bandai':
                        extraId = userData.bandai_id || 'No Bandai ID';
                        break;
                    case 'konami':
                        extraId = userData.konami_id || 'No Konami ID';
                        break;
                    case 'longshanks':
                        extraId = userData.longshanks_id || 'No Longshanks ID';
                        break;
                    default:
                }
            }

            const newRow = 
                <tr key={userId}>
                    {rowLeftColumnContent && 
                    <td className="align-middle">
                        {rowLeftColumnContent}
                    </td>}
                    <td className="align-middle">
                        <div className='cursor-pointer' onClick={()=>this.onClick(userId)}>
                            {!extraId &&
                            <div>
                                <div>
                                    <Image className='rounded-circle' src={userData.getImageUrl()} height='15px' width='15px' alt='player profile image'/> {userData.username}
                                </div>
                                {userData.short_name &&
                                <div><span style={{width:'15px', display:'inline-block'}}></span> <small className='text-muted'>({userData.short_name})</small></div>}
                            </div>}
                            {extraId &&
                            <div>
                                <div>
                                    <Image className='rounded-circle' src={userData.getImageUrl()} height='15px' width='15px' alt='player profile image'/> {userData.username}
                                    {userData.short_name && <small className='text-muted'> ({userData.short_name})</small>}
                                </div>
                                <div>
                                    <span style={{width:'15px', display:'inline-block'}}></span> <small className='text-muted'>{extraId}</small>
                                </div>
                            </div>}
                        </div>
                    </td>
                    {rowMiddleColumnContent && 
                    <td className="align-middle">
                        {rowMiddleColumnContent}
                    </td>}
                    {rowRightColumnContent && 
                    <td className="align-middle">
                        {rowRightColumnContent}
                    </td>}
                    {this.props.showDeleteButton && 
                    <td className="text-center align-middle">
                        {showDeleteButton && !this.props.deleteButtonToolTipText &&
                        <Button size='sm' variant='danger' disabled={this.props.disable || false} onClick={()=>this.props.onDelete(userId)}><NerdHerderFontIcon icon='flaticon-recycle-bin-filled-tool'/></Button>}
                        {showDeleteButton && this.props.deleteButtonToolTipText &&
                        <NerdHerderToolTipButton size='sm' variant='danger' placement='left' tooltipText={this.props.deleteButtonToolTipText} icon='flaticon-recycle-bin-filled-tool' disabled={this.props.disable || false} onClick={()=>this.props.onDelete(userId)}/>}
                    </td>}
                </tr>
            rows.push(newRow);
        }

        let tableJsx = 
            <Table responsive size="sm" className='mb-1'>
                <thead>
                    {headers.length > 0 &&
                    <tr>{headers}</tr>}
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        
        if (this.props.maxHeight) {
            tableJsx = 
                <NerdHerderVerticalScroller maxHeight={this.props.maxHeight}>
                    {tableJsx}
                </NerdHerderVerticalScroller>
        }

        return (
            <div>
                {this.props.title &&
                this.props.title}
                {this.props.showTotalUsers &&
                <span> <small className='text-muted'>({rows.length} {this.props.totalUsersNoun || 'users'})</small></span>}
                {this.state.showUserProfileModal &&
                <NerdHerderUserProfileModal userId={this.state.userProfileModalId} onCancel={()=>this.setState({showUserProfileModal: false, userProfileModalId: null})} localUser={this.props.localUser}/>}
                {tableJsx}
                {rows.length === 0 && <small className='text-muted'>{this.props.emptyMessage || 'This table is empty.'}</small>}
            </div>
        )
    }
}