import React from 'react';
import withRouter from './withRouter';
import Alert from 'react-bootstrap/Alert';
import { NerdHerderNavigate } from './nerdherder-components/NerdHerderNavigate';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderMessageModal, NerdHerderPasswordModal, NerdHerderAuthorizationKeyModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { getCookie, delCookie, getFailureMessage, setCookieHours, delLocal } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const reason = params.reason;
        this.retryTimeout = null;
        this.retryCount = 0;

        // discard any existing subs
        this.restPubSub.clear();

        // in some cases we need the local user info to properly generate the error page
        this.needLocalUser = false;
        if (reason === 'email-verification' || reason === 'password-update' || reason === 'user-underage') {
            this.needLocalUser = true;
        }

        this.state = {
            navigateTo: null,
            reason: reason,
            localUser: null,
            errorFeedback: null,
        }

        // delete the login cookie if we have password or login issues
        if (this.state.reason === 'login-required' ||
            this.state.reason === 'login-expired' ||
            this.state.reason === 'user-suspended' ||
            this.state.reason === 'password-update') {
                delCookie('RememberMe');
                delCookie('LoginToken');
                delLocal('LoginToken');
                delLocal('UserId');
                delLocal('FirebaseToken');
        }

        // if the reason is email verification, periodically check to see if the user is verified
        this.verificationPeriodic = null;
        this.verificationPeriodicCount = 0;
        if (this.state.reason === 'email-verification') {
            this.verificationPeriodic = setInterval(()=>this.checkVerification(), 5000);
        }
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));

        if (this.needLocalUser) {
            let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        let message = getFailureMessage(error);
        this.setState({errorFeedback: message})
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    checkConnection() {
        console.debug('checking connection to server');
        this.restApi.genericGetEndpointData('server-status', null, null, true)
        .then((response)=>{
            console.debug('server responded');
            this.retryCount = 1;
            console.debug(response);
            if (response.data && response.data.status === 'up') {
                console.debug('server is up');
                clearTimeout(this.retryTimeout);
                this.onNavigateToDesiredUrl();
            } else {
                console.debug('server is not ready');
                this.retryCount++;
                this.retryTimeout = setTimeout(()=>this.checkConnection(), 3000);
            }
        })
        .catch((error)=>{
            console.debug('server is down');
            this.retryCount++;
            let retryDelay = 3000;
            if (this.retryCount > 20) retryDelay = 60000;
            if (this.retryCount > 200) retryDelay = 600000;
            if (this.state.reason === 'no-network') this.retryTimeout = setTimeout(()=>this.checkConnection(), retryDelay);
        })
    }

    checkVerification() {
        console.debug('checking email verification');
        this.restApi.genericGetEndpointData('user-verified', this.state.localUser.id, null, true)
        .then((response)=>{
            console.debug('server responded');
            this.retryCount = 1;
            console.debug(response);
            if (response.data && response.data.verified === true) {
                console.debug('user is verified');
                clearInterval(this.verificationPeriodic);
                this.onNavigateToDesiredUrl();
            } else {
                console.debug('user is not verified');
                this.verificationPeriodicCount++;
                // give up after a bit
                if (this.verificationPeriodicCount >= 100) {
                    clearInterval(this.verificationPeriodic);
                }
            }
        })
        .catch((error)=>{
            console.error('failed to get user verification from server');
            console.error(error)
        })
    }

    onCompleteAuthorization() {
        let desiredUrl = getCookie('DesiredUrl', '/app/main');
        this.setState({reason: null, navigateTo: desiredUrl});
    }

    onNavigateToDesiredUrl() {
        let fallbackUrl = '/app/main'
        let desiredUrl = getCookie('DesiredUrl', fallbackUrl);
        console.debug(`DesiredUrl cookie: ${desiredUrl}`);
        if (desiredUrl.includes('/app/login')) {
            console.debug('delete DesiredUrl cookie')
            delCookie('DesiredUrl');
            desiredUrl = fallbackUrl;
        } else if (!desiredUrl.includes('/app/')) {
            console.debug('delete DesiredUrl cookie');
            delCookie('DesiredUrl');
            desiredUrl = fallbackUrl;
        }
        
        this.setState({navigateTo: desiredUrl});
    }

    onUpdatePassword(password) {
        this.setState({reason: null});
        this.restApi.genericPatchEndpointData('self', null, {password: password})
        .then(response => {
            let desiredUrl = getCookie('DesiredUrl', '/app/main');
            this.setState({reason: null, navigateTo: desiredUrl});
        }).catch(error => {
            this.setState({reason: 'password-update'});
        });
    }

    onCancelPermissionModal() {
        // need to delete the LoginToken and RememberMe cookies
        delCookie('LoginToken');
        delLocal('LoginToken');
        delLocal('UserId');
        delLocal('FirebaseToken');
        delCookie('RememberMe');
        setCookieHours('ParentalPermissionUserId', this.state.localUser.id, 2);
        setCookieHours('ParentalPermissionUserName', this.state.localUser.username, 2);
        this.setState({reason: null, navigateTo: '/app/login'});
    }

    onCancelBadUserModal() {
        // need to delete the LoginToken and RememberMe cookies
        delCookie('LoginToken');
        delLocal('LoginToken');
        delLocal('UserId');
        delLocal('FirebaseToken');
        delCookie('RememberMe');
        this.setState({reason: null, navigateTo: '/app/homepage'});
    }

    onCancelMessageModal() {
        this.setState({reason: null, navigateTo: '/app/main'});
    }

    render() {
        if (this.state.navigateTo) return(<NerdHerderNavigate to={this.state.navigateTo} replace={true}/>);
        if (this.state.reason === 'login-required') return(<NerdHerderNavigate to={'/app/login'} replace={true}/>);
        if (this.state.reason === 'login-expired') return(<NerdHerderNavigate to={'/app/login'} replace={true}/>);
        if (this.needLocalUser && !this.state.localUser) return (<NerdHerderLoadingModal/>);
        if (this.state.reason === 'no-network' && this.retryTimeout === null) this.retryTimeout = setTimeout(()=>this.checkConnection(), 3000);

        let messageModal = null;
        if (this.state.reason === 'user-underage') messageModal = <NerdHerderMessageModal title='Permission Required' message='Your parent or guardian needs to grant permission for access to NerdHerder. They can do this by logging into their account (or creating one) and granting permission.' onCancel={()=>this.onCancelPermissionModal()}/>
        if (this.state.reason === 'user-suspended') messageModal = <NerdHerderMessageModal title='Account Suspended' message='Your account has been suspended.' onCancel={()=>this.onCancelBadUserModal()}/>
        if (this.state.reason === 'user-deleted') messageModal = <NerdHerderMessageModal title='Account Deleted' message='Your account has been deleted.' onCancel={()=>this.onCancelBadUserModal()}/>
        if (this.state.reason === 'read-404') messageModal =   <NerdHerderMessageModal title='Missing Data' message='You are trying to view something that is missing. It may have been deleted by another user?' onCancel={()=>this.onCancelMessageModal()}/>
        if (this.state.reason === 'write-404') messageModal =  <NerdHerderMessageModal title='Missing Data' message='You are trying to update something that is missing. It may have been deleted by another user?' onCancel={()=>this.onCancelMessageModal()}/>
        if (this.state.reason === 'delete-404') messageModal = <NerdHerderMessageModal title='Missing Data' message='You are trying to delete something that is not there. Another user may have already deleted it?' onCancel={()=>this.onCancelMessageModal()}/>
        if (this.state.reason === 'unauthorized-read') messageModal =   <NerdHerderMessageModal title='Unauthorized' message="You attempted to view something you aren't supposed to be able to view. Someone might have changed permissions?" onCancel={()=>this.onCancelMessageModal()}/>
        if (this.state.reason === 'unauthorized-write') messageModal =  <NerdHerderMessageModal title='Unauthorized' message="You attempted to update something you aren't supposed to be able to change. Someone might have changed permissions?" onCancel={()=>this.onCancelMessageModal()}/>
        if (this.state.reason === 'unauthorized-delete') messageModal = <NerdHerderMessageModal title='Unauthorized' message="You attempted to delete something you aren't supposed to be able to remove. Someone might have changed permissions?" onCancel={()=>this.onCancelMessageModal()}/>

        return(
            <NerdHerderStandardPageTemplate pageName='errorpage' disableHeader={true} localUser={this.state.localUser}>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.reason === 'email-verification' && 
                <NerdHerderAuthorizationKeyModal onAccept={()=>this.onCompleteAuthorization()} localUser={this.state.localUser}/>}
                {this.state.reason === 'password-update' && 
                <NerdHerderPasswordModal onAccept={(p)=>this.onUpdatePassword(p)}
                                         onCancel={()=>this.setState({navigateTo: '/app/login'})}
                                         matchPassword={true}
                                         autocomplete='new-password'
                                         title='Password Expired'
                                         passwordLabel='Your password has expired, please enter a new one...'
                                         acceptButtonText='Update Password'
                                         localUser={this.state.localUser}/>}
                {this.state.reason === 'no-network' &&
                <NerdHerderLoadingModal title='No Network' errorFeedback='Cannot Reach NerdHerder...'/>}
                {messageModal}
            </NerdHerderStandardPageTemplate>
        );
    }
}

export default withRouter(ErrorPage);
