import React from 'react';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NerdHerderRestApi } from '../NerdHerder-RestApi';
import { getStaticStorageImageFilePublicUrl, getLocalStaticFileUrl } from '../utilities';
import { NerdHerderFontIcon } from './NerdHerderFontIcon';
import { NerdHerderAddGameModal, NerdHerderAboutModal, NerdHerderReportBugModal, NerdHerderQrLoginModal } from './NerdHerderModals';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from '../NerdHerder-RestPubSub';

export class NerdHerderHeader extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.localUser === 'undefined') console.error('missing props.localUser');

        this.state = {
            leaguesDict: {},
            venuesDict: {},
            showOffcanvas: false,
            unreadMessageCount: 0,
            gameIssuesCount: 0,
            contactRequestCount: 0,
            settingsIssuesCount: 0,
            showAddGameModal: false,
            showReportBugModal: false,
            showAboutModal: false,
            showQrLoginModal: false,
        };

        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool(); 
    }

    componentDidMount() {
        let sub = null;
        if (this.props.localUser !== null) {
            sub = this.restPubSub.subscribe('header-alerts', null, (d, k) => {this.updateHeaderAlerts(d, k)});
            this.restPubSubPool.add(sub);
            sub = this.restPubSub.subscribe('header-leagues', null, (d, k) => {this.updateHeaderLeagues(d, k)});
            this.restPubSubPool.add(sub);
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateHeaderLeagues(data, key) {
        const newLeaguesDict = {};
        const newVenuesDict = {};
        for (const leagueSummary of data.league_summary) {
            newLeaguesDict[leagueSummary.id] = leagueSummary;
        }
        for (const venueSummary of data.venue_summary) {
            if (venueSummary.manager) newVenuesDict[venueSummary.id] = venueSummary;
        }
        this.setState({leaguesDict: newLeaguesDict, venuesDict: newVenuesDict});
    }

    updateHeaderAlerts(responseData, key) {
        this.setState(
            {
                unreadMessageCount: responseData.unread_message_count,
                gameIssuesCount: responseData.game_issues_count,
                contactRequestCount: responseData.contact_request_count
            }
        );
    }

    handleShowOffcanvas() {
        this.setState({showOffcanvas: true});
    }

    handleHideOffcanvas() {
        this.setState({showOffcanvas: false});
    }

    getLeagueImageUrl(leagueSummaryData) {
        if (leagueSummaryData.image === null || leagueSummaryData.image.includes('kraken.png')) {
            return getStaticStorageImageFilePublicUrl('/kraken.png');
        }
        return leagueSummaryData.image;
    }

    getVenueImageUrl(venueSummaryData) {
        if (venueSummaryData.image === null || venueSummaryData.image.includes('venue_image.png')) {
            return getStaticStorageImageFilePublicUrl('/venue_image.png');
        }
        return venueSummaryData.image;
    }

    render() {
        let mainNavActiveKey = this.props.headerSelection || null;
        let dropdownActiveKey = this.props.dropdownSelection || null;

        let manageLeagueDropdownItems = [];
        let playerLeagueDropdownItems = [];
        let inviteAndRequestLeagueDropdownItems = [];
        let otherLeagueDropdownItems = [];
        let leagueSettingsManageDropdownItems = [];
        let venueSettingsManageDropdownItems = [];
        if (this.props.localUser) {
            for (const [leagueId, leagueSummary] of Object.entries(this.state.leaguesDict)) {
                const imageUrl = this.getLeagueImageUrl(leagueSummary);
                let dropdownItem = 
                    <Dropdown.Item key={`league-${leagueId}`} href={`/app/league/${leagueId}`} className={`league-${leagueId}`===dropdownActiveKey ? 'active' : ''}>
                        <Image className="rounded text-center" height='20px' width='20px' src={imageUrl}/> {leagueSummary.name}
                    </Dropdown.Item>
                let managementDropdownItem = 
                    <Dropdown.Item key={`manageleague-${leagueId}`} href={`/app/manageleague/${leagueId}`} className={`manageleague-${leagueId}`===dropdownActiveKey ? 'active' : ''}>
                        <Image className="rounded text-center" height='20px' width='20px' src={imageUrl}/> {leagueSummary.name}
                    </Dropdown.Item>

                if (leagueSummary.manager) {
                    manageLeagueDropdownItems.push(dropdownItem);
                    leagueSettingsManageDropdownItems.unshift(managementDropdownItem);
                }
                else if (leagueSummary.request_join || leagueSummary.invited) {
                    inviteAndRequestLeagueDropdownItems.push(dropdownItem);
                }
                else if (leagueSummary.player) {
                    playerLeagueDropdownItems.push(dropdownItem);
                }
                else {
                    otherLeagueDropdownItems.push(dropdownItem);
                }
            }
            for (const [venueId, venueSummary] of Object.entries(this.state.venuesDict)) {
                const imageUrl = this.getVenueImageUrl(venueSummary);
                let managementDropdownItem = 
                    <Dropdown.Item key={`managevenue-${venueId}`} href={`/app/managevenue/${venueId}`} className={`managevenue-${venueId}`===dropdownActiveKey ? 'active' : ''}>
                        <Image className="rounded text-center" height='20px' width='20px' src={imageUrl}/> {venueSummary.name}
                    </Dropdown.Item>

                if (venueSummary.manager) {
                    venueSettingsManageDropdownItems.unshift(managementDropdownItem);
                }
            }
        }

        // put all the dropdown items into a combined list
        let leagueDropdownItems = [];
        if (manageLeagueDropdownItems.length !== 0) {
            leagueDropdownItems.push(<Dropdown.Header key="manage_leagues">Events & leagues you manage</Dropdown.Header>);
            leagueDropdownItems.push(...manageLeagueDropdownItems);
        }
        if (inviteAndRequestLeagueDropdownItems.length !== 0) {
            leagueDropdownItems.push(<Dropdown.Header key="invite_leagues">Invites & Joins</Dropdown.Header>);
            leagueDropdownItems.push(...inviteAndRequestLeagueDropdownItems);
        }
        if (playerLeagueDropdownItems.length !== 0) {
            leagueDropdownItems.push(<Dropdown.Header key="play_leagues">Events & leagues you play in</Dropdown.Header>);
            leagueDropdownItems.push(...playerLeagueDropdownItems);
        }
        if (otherLeagueDropdownItems.length !== 0) {
            leagueDropdownItems.push(<Dropdown.Header key="other_leagues">Other events or leagues</Dropdown.Header>);
            leagueDropdownItems.push(...otherLeagueDropdownItems);
        }

        let leagueDropdownDisabled = false;
        let addGamesDisabled = false;
        if (leagueDropdownItems.length === 0) {
            leagueDropdownDisabled = true;
            addGamesDisabled = true;
        }

        let gameDropdownItems = [<Dropdown.Item key="manage" href="/app/games">
                                    <Image className="rounded text-center" height='20px' width='20px' src={getStaticStorageImageFilePublicUrl('/dice.png')}/> Games & Stats
                                 </Dropdown.Item>,
                                 <Dropdown.Item key="add" onClick={()=>this.setState({showOffcanvas: false, showAddGameModal: true})} disabled={addGamesDisabled}>
                                    <Image className="rounded text-center" height='20px' width='20px' src={getStaticStorageImageFilePublicUrl('/add.png')}/> Add Game
                                </Dropdown.Item>];

        let settingDropdownItems = [];
        const settingProfileItem =
            <Dropdown.Item key="profile" href="/app/profile" className={dropdownActiveKey==='profile' ? 'active' : ''}>
                <Image className="rounded text-center" height='20px' width='20px' src={getStaticStorageImageFilePublicUrl('/adjust.png')}/> Profile
            </Dropdown.Item>
        settingDropdownItems.push(settingProfileItem);
        const bioProfileItem =
            <Dropdown.Item key="biometrics" href="/app/biometrics" className={dropdownActiveKey==='biometrics' ? 'active' : ''}>
                <Image className="rounded text-center" height='20px' width='20px' src={getStaticStorageImageFilePublicUrl('/fingerprint-scan.png')}/> Biometric Login
            </Dropdown.Item>
        settingDropdownItems.push(bioProfileItem);
        const parentProfileItem =
            <Dropdown.Item key="parents" href="/app/parents" className={dropdownActiveKey==='parents' ? 'active' : ''}>
                <Image className="rounded text-center" height='20px' width='20px' src={getStaticStorageImageFilePublicUrl('/propeller.png')}/> Child Accounts
            </Dropdown.Item>
        // don't add the parent item if this is a child
        if (this.props.localUser && !this.props.localUser.under_13) settingDropdownItems.push(parentProfileItem);
        const qrProfileItem =
            <Dropdown.Item key="qrLogin" className={dropdownActiveKey==='qrLogin' ? 'active' : ''} onClick={()=>this.setState({showOffcanvas: false, showQrLoginModal: true})}>
                <Image className="rounded text-center" height='20px' width='20px' src={getStaticStorageImageFilePublicUrl('/qr-code.png')}/> Share Device Sign In
            </Dropdown.Item>
        settingDropdownItems.push(qrProfileItem);

        // merge the leagueManageDropdownItems with the settingDropdownItems and add headers
        if (leagueSettingsManageDropdownItems.length > 0 || venueSettingsManageDropdownItems.length > 0) {
            settingDropdownItems.splice(0, 0, <Dropdown.Header key="mysettings">My Settings</Dropdown.Header>);
            if (venueSettingsManageDropdownItems.length > 0) {
                venueSettingsManageDropdownItems.splice(0, 0, <Dropdown.Header key="venuemanagement">Venue Management</Dropdown.Header>);
                let temp = settingDropdownItems.concat(venueSettingsManageDropdownItems);
                settingDropdownItems = temp;
            }
            if (leagueSettingsManageDropdownItems.length > 0) {
                leagueSettingsManageDropdownItems.splice(0, 0, <Dropdown.Header key="leaguemanagement">Event Management</Dropdown.Header>);
                let temp = settingDropdownItems.concat(leagueSettingsManageDropdownItems);
                settingDropdownItems = temp;
            }
        }
        
        let unreadMessageCount = this.state.unreadMessageCount;
        if (unreadMessageCount === 0) unreadMessageCount = null;
        else if (unreadMessageCount > 9) unreadMessageCount = '9+';

        let gameIssuesCount = this.state.gameIssuesCount;
        if (gameIssuesCount === 0) gameIssuesCount = null;
        else if (gameIssuesCount > 9) gameIssuesCount = '9+';

        let contactRequestCount = this.state.contactRequestCount;
        if (contactRequestCount === 0) contactRequestCount = null;
        else if (contactRequestCount > 9) contactRequestCount = '9+';

        let settingsIssuesCount = this.state.settingsIssuesCount;
        if (settingsIssuesCount === 0) settingsIssuesCount = null;
        else if (settingsIssuesCount > 9) settingsIssuesCount = '9+';

        // determine the breadcrumb layout
        let breadcrumbs = [];
        if (this.props.navPath) {
            breadcrumbs.push(<Breadcrumb.Item key={0} href="/app/main"><NerdHerderFontIcon icon="flaticon-home"/></Breadcrumb.Item>)
            for (let i=0; i<this.props.navPath.length; i++) {
                const navPath = this.props.navPath[i];
                let breadcrumbItem = null;
                let breadcrumbJsx = null;
                let lastOne = i===this.props.navPath.length-1;
                let secondToLastOne = i===this.props.navPath.length-2;
                let navPathLabel = navPath.label;
                // progressively shorten the titles if needed
                if (this.props.navPath.length >= 2) {
                    if (lastOne) {
                        if (navPathLabel && navPathLabel.length > 20) navPathLabel = navPathLabel.slice(0, 17) + '...';
                    }
                    else if (secondToLastOne) {
                        if (navPathLabel && navPathLabel.length > 15) navPathLabel = navPathLabel.slice(0, 12) + '...';
                    }
                    else {
                        navPathLabel = null;
                    }
                }

                if (navPathLabel && navPath.icon) {
                    breadcrumbJsx = <span style={{display: 'inline-block'}}><NerdHerderFontIcon icon={navPath.icon}/> {navPathLabel}</span>
                } else if (navPathLabel) {
                    breadcrumbJsx = <span style={{display: 'inline-block'}}>{navPathLabel}</span>
                } else if (navPath.icon) {
                    breadcrumbJsx = <span style={{display: 'inline-block'}}><NerdHerderFontIcon icon={navPath.icon}/></span>
                } else {
                    console.warn('nav path missing icon or label (or both)');
                    continue;
                }
                
                // last one uses active instead of href
                if (lastOne) {
                    breadcrumbItem = <Breadcrumb.Item key={i+1} active>{breadcrumbJsx}</Breadcrumb.Item>
                } else {
                    breadcrumbItem = <Breadcrumb.Item key={i+1} href={navPath.href}>{breadcrumbJsx}</Breadcrumb.Item>
                }
                breadcrumbs.push(breadcrumbItem);
            }
        }
        
        if (this.props.headerHidden === true) return(null);

        // if this isn't a logged in user, show a fake header with everything disabled...
        if (this.props.localUser === null) {
            return(
                <div>
                    <Button className="fixed-top mt-2 m-1 text-primary bg-white" style={{width: '60px', border: 'none', zIndex: 1040}} disabled={true}>
                        <span className="flaticon-menu-button-of-three-horizontal-lines" style={{fontSize: '30px'}}></span>
                    </Button>

                    <Navbar variant="primary" bg="white" className="text-primary">
                        <Container fluid>
                            <Nav className="ms-auto" variant="pills">
                                <Nav.Item>
                                    <Nav.Link className="position-relative px-3" href="#" disabled={true}>
                                        <NerdHerderFontIcon icon="flaticon-home" fontSize="30px"/>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="position-relative px-3" href="#" disabled={true}>
                                        <NerdHerderFontIcon icon="flaticon-new-email-filled-back-envelope" fontSize="30px"/>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="position-relative px-3" href="#" disabled={true}>
                                        <NerdHerderFontIcon icon="flaticon-sport-dices" fontSize="30px"/>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="position-relative px-3" href="#" disabled={true}>
                                        <NerdHerderFontIcon icon="flaticon-team" fontSize="30px"/>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Container>
                    </Navbar>
                </div>
            )
        }

        return (
            <div>
                <Button id='menu-button' className="fixed-top mt-2 m-1 text-primary bg-white" style={{width: '60px', border: 'none', zIndex: 1040}} onClick={() => this.handleShowOffcanvas()}>
                    <span className="flaticon-menu-button-of-three-horizontal-lines" style={{fontSize: '30px'}}></span>
                </Button>

                <Navbar variant="primary" bg="white" className="text-primary">
                    <Container fluid>
                        <Nav className="ms-auto" variant="pills" activeKey={mainNavActiveKey}>
                            <Nav.Item id='header-home-nav'>
                                <Nav.Link eventKey='main' className="position-relative px-3" href="/app/main">
                                    <NerdHerderFontIcon icon="flaticon-home" fontSize="30px"/>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item id='header-messages-nav'>
                                <Nav.Link eventKey='messages' className="position-relative px-3" href="/app/messages">
                                    <NerdHerderFontIcon icon="flaticon-new-email-filled-back-envelope" fontSize="30px"/>
                                    {(unreadMessageCount > 0) && <Badge className="position-absolute top-0 end-0" bg="danger" style={{fontSize: "15px"}} pill>{unreadMessageCount}</Badge>}
                                </Nav.Link>
                            </Nav.Item>

                            <NerdHerderNavDropdown id='header-games-nav' icon="flaticon-sport-dices" fontSize="30px" active={mainNavActiveKey==='games'} badgeTextRelative={gameIssuesCount}>
                                {gameDropdownItems}
                            </NerdHerderNavDropdown>

                            <NerdHerderNavDropdown id='header-leagues-nav' icon="flaticon-team" fontSize="30px" active={mainNavActiveKey==='leagues'} disabled={leagueDropdownDisabled}>
                                {leagueDropdownItems}
                            </NerdHerderNavDropdown>
                        </Nav>
                    </Container>
                </Navbar>

                <Offcanvas show={this.state.showOffcanvas} onHide={()=>this.handleHideOffcanvas()}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className="text-primary">
                            <a href='/app/homepage' style={{textDecoration: 'none'}}>
                                <Image className="d-inline-block align-text-bottom" src={getLocalStaticFileUrl('/nerdherder_light_small.png')} alt="nerdherder logo" height="30"/>
                                <big><b> NerdHerder</b></big>
                            </a>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="flex-column" variant="pills" activeKey={mainNavActiveKey}>
                            <Nav.Item>
                                <Nav.Link eventKey='main' href="/app/main">
                                    <NerdHerderFontIcon icon="flaticon-home" fontSize="25px"> Home</NerdHerderFontIcon>
                                </Nav.Link>
                            </Nav.Item>
                            <NerdHerderNavDropdown icon="flaticon-team" title=" Events & Leagues" fontSize="25px" active={mainNavActiveKey==='leagues'} disabled={leagueDropdownDisabled}>
                                {leagueDropdownItems}
                            </NerdHerderNavDropdown>
                            <NerdHerderNavDropdown icon="flaticon-sport-dices" title=" Games" fontSize="25px" badgeText={gameIssuesCount} active={mainNavActiveKey==='games'}>
                                {gameDropdownItems}
                            </NerdHerderNavDropdown>
                            <Nav.Item>
                                <Nav.Link eventKey='messages' href="/app/messages">
                                    <NerdHerderFontIcon icon="flaticon-new-email-filled-back-envelope" fontSize="25px"> Messages {(unreadMessageCount > 0) && <Badge bg="danger" style={{fontSize: "15px"}} pill>{unreadMessageCount}</Badge>}</NerdHerderFontIcon>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey='contacts' href="/app/contacts">
                                    <NerdHerderFontIcon icon="flaticon-invisible-person-of-clothes" fontSize="25px"> Contacts {(contactRequestCount > 0) && <Badge bg="danger" style={{fontSize: "15px"}} pill>{contactRequestCount}</Badge>}</NerdHerderFontIcon>
                                </Nav.Link>
                            </Nav.Item>
                            <NerdHerderNavDropdown icon="flaticon-configuration-with-gear" title=" Settings"fontSize="25px" badgeText={settingsIssuesCount} active={mainNavActiveKey==='settings'}>
                                {settingDropdownItems}
                            </NerdHerderNavDropdown>
                            <Nav.Item>
                                <Nav.Link eventKey='bug' onClick={()=>this.setState({showOffcanvas: false, showReportBugModal: true})}>
                                    <NerdHerderFontIcon icon="flaticon-bug" fontSize="25px"> Report Bug</NerdHerderFontIcon>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey='about' onClick={()=>this.setState({showOffcanvas: false, showAboutModal: true})}>
                                    <NerdHerderFontIcon icon="flaticon-question-sign-in-a-circle" fontSize="25px"> About</NerdHerderFontIcon>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey='logout' href="/app/logout">
                                    <NerdHerderFontIcon icon="flaticon-logout" fontSize="25px"> Logout</NerdHerderFontIcon>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {breadcrumbs.length !== 0 &&
                        <div>
                            <hr/>
                            <span className='text-muted' style={{fontSize: '12px'}}>Location:</span>
                            <Breadcrumb style={{fontSize: '15px'}}>
                                {breadcrumbs}
                            </Breadcrumb>
                        </div>}
                    </Offcanvas.Body>
                </Offcanvas>

                {this.state.showAddGameModal && <NerdHerderAddGameModal localUser={this.props.localUser} onCancel={()=>this.setState({showAddGameModal: false})} onAddGame={()=>this.setState({showAddGameModal: false})}/>}
                {this.state.showReportBugModal && <NerdHerderReportBugModal localUser={this.props.localUser} onCancel={()=>this.setState({showReportBugModal: false})}/>}
                {this.state.showAboutModal && <NerdHerderAboutModal onCancel={()=>this.setState({showAboutModal: false})}/>}
                {this.state.showQrLoginModal && <NerdHerderQrLoginModal simple={true} onCancel={()=>this.setState({showQrLoginModal: false})}/>}
            </div>
        )
    }
}

class NerdHerderNavDropdown extends React.Component {
    render() {
        let disabled=false;
        if (this.props.disabled || false) {
            disabled=true;
        }

        let relativeBadgeJsx = null;
        if (this.props.badgeTextRelative || false) {
            relativeBadgeJsx = <Badge className="position-absolute top-0 end-0" bg="danger" style={{fontSize: "15px"}} pill>{this.props.badgeTextRelative}</Badge>;
        }

        let inlineBadgeJsx = null;
        if (this.props.badgeText || false) {
            inlineBadgeJsx = <Badge bg="danger" style={{fontSize: "15px"}} pill>{this.props.badgeText}</Badge>;
        }

        let fontSize = '30px';
        if (this.props.fontSize || false) {
            fontSize = this.props.fontSize;
        }

        return (
        <Dropdown id={this.props.id} as={NavItem} align="end">
            <Dropdown.Toggle as={NavLink} disabled={disabled} className={`position-relative px-3 ${this.props.active ? 'active' : ''}`} >
                <NerdHerderFontIcon icon={this.props.icon} fontSize={fontSize}>{this.props.title} {inlineBadgeJsx}</NerdHerderFontIcon>
                {relativeBadgeJsx}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {this.props.children}
            </Dropdown.Menu>
        </Dropdown>
        );
    }
}
