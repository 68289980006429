import React from 'react';

export class NerdHerderNavigate extends React.Component {
    constructor(props) {
        super(props);

        if (typeof this.props.to === 'undefined') console.error('missing props.to');
    }

    render() {
        if (!this.props.to) return(null);
        if (this.props.replace) {
            console.log(`NerdHerderNavigate (with replace) to ${this.props.to}`);
            window.location.replace(this.props.to);
        }
        else {
            console.log(`NerdHerderNavigate to ${this.props.to}`);
            window.location.assign(this.props.to);
        }
        
        return(null);
    }
}
