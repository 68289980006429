import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import withRouter from './withRouter';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDocs, addDoc, collection, doc, onSnapshot, query, where, getDoc } from 'firebase/firestore';
import { getAuth, signOut, signInWithCustomToken } from 'firebase/auth';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderLoadingModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { getCookie } from './utilities';


export class FirebaseTestPage extends React.Component {
    constructor(props) {
        super(props);        

        this.state = {
            localUser: null,
            firebaseToken: null,
        }

        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        const firebaseConfig = {
            apiKey: "AIzaSyAJZe9hz0k91LKKHJ0rw_ERgmKFqcAuj94",
            authDomain: "tidal-axis-331817.firebaseapp.com",
            projectId: "tidal-axis-331817",
            storageBucket: "tidal-axis-331817.appspot.com",
            messagingSenderId: "986217896990",
            appId: "1:986217896990:web:4766841ee159697c88220f"
        };

        initializeApp(firebaseConfig);
        this.firestoreDb = getFirestore();
        this.firebaseAuth = getAuth();
        this.leaguesColRef = collection(this.firestoreDb, 'leagues');
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('self-firebase', null, (d, k) => {this.updateLocalUserFirebase(d, k)});
        this.restPubSubPool.add(sub);

        /*onSnapshot(this.leaguesColRef, (snapshot)=>{
            const leaguesList = [];
            for (const leagueDoc of snapshot.docs) {
                leaguesList.push({...leagueDoc.data(), id: leagueDoc.id})
            }
            console.debug(leaguesList);
        });*/

        /*getDocs(this.leaguesColRef)
        .then((snapshot)=>{
            const leaguesList = [];
            for (const leagueDoc of snapshot.docs) {
                leaguesList.push({...leagueDoc.data(), id: leagueDoc.id})
            }
            console.debug(leaguesList);
        })
        .catch((error)=>{
            console.error('failed to getDocs()');
            console.error(error);
        });*/

        /*this.leagueRef = doc(this.firestoreDb, 'leagues', '1')
        .then((snapshot)=>{
            const leaguesList = [];
            for (const leagueDoc of snapshot.docs) {
                leaguesList.push({...leagueDoc.data(), id: leagueDoc.id})
            }
            console.debug(leaguesList);
        })
        .catch((error)=>{
            console.error('failed to getDocs()');
            console.error(error);
        });*/
    }

    updateLocalUser(userData, key) {
        let newData = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: newData});
    }

    updateLocalUserFirebase(firebaseData, key) {
        this.setState({firebaseToken: firebaseData.token});
    }

    doFirebaseLogin() {
        console.log('doing firebase login');
        const loginToken = getCookie('LoginToken');
        if (loginToken) {
            signInWithCustomToken(this.firebaseAuth, this.state.firebaseToken)
            .then((cred)=>{
                console.log(cred);
                console.log(cred.user);
            })
            .catch((error)=>{
                console.error('login failed');
                console.error(error);
            });
        }
        
    }

    doFirebaseLogout() {
        console.log('doing firebase logout');
        signOut(this.firebaseAuth)
        .then(()=>{
            console.log('logged out')
        })
        .catch((error)=>{
            console.error('logout failed');
            console.error(error);
        });
    }

    doFirebaseSelfRead(user_id) {
        let docRef = doc(this.firestoreDb, 'database/debug/self', user_id.toString())
        getDoc(docRef)
        .then((snapshot)=>{
            console.debug(snapshot.data());
        })
        .catch((error)=>{
            console.error('doc() failed');
            console.error(error);
        });
    }

    doFirebaseUserRead(user_id) {
        let docRef = doc(this.firestoreDb, 'database/debug/users', user_id.toString())
        getDoc(docRef)
        .then((snapshot)=>{
            console.debug(snapshot.data());
        })
        .catch((error)=>{
            console.error('doc() failed');
            console.error(error);
        });
    }

    doFirebaseLeagueRead(league_id) {
        let docRef = doc(this.firestoreDb, 'database/debug/leagues', league_id.toString())
        getDoc(docRef)
        .then((snapshot)=>{
            console.debug(snapshot.data());
        })
        .catch((error)=>{
            console.error('doc() failed');
            console.error(error);
        });
    }

    render() {
        if (!this.state.localUser) return (<NerdHerderLoadingModal />);
        if (!this.state.firebaseToken) return (<NerdHerderLoadingModal />);
        return (
            <NerdHerderStandardPageTemplate pageName='test' localUser={this.state.localUser}>
                <NerdHerderStandardCardTemplate title='Firebase Test'>
                    <h2>Sign In</h2>
                    <Button variant='primary' onClick={()=>this.doFirebaseLogin()}>Sign In</Button>
                    <hr/>
                    <h2>Sign Out</h2>
                    <Button variant='primary' onClick={()=>this.doFirebaseLogout()}>Sign Out</Button>
                    <hr/>
                    <h2>Read Self</h2>
                    <Button variant='primary' onClick={()=>this.doFirebaseSelfRead(1)}>Read</Button>
                    <hr/>
                    <h2>Read User 2</h2>
                    <Button variant='primary' onClick={()=>this.doFirebaseUserRead(2)}>Read</Button>
                    <hr/>
                    <h2>Read League 1</h2>
                    <Button variant='primary' onClick={()=>this.doFirebaseLeagueRead(1)}>Read</Button>
                </NerdHerderStandardCardTemplate>
            </NerdHerderStandardPageTemplate>
        );
    }
}

export default withRouter(FirebaseTestPage);
