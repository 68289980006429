import React from 'react';
import withRouter from './withRouter';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderStandardCardTemplate } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';

class FeaturesPage extends React.Component {

    render() {
        return(
            <NerdHerderStandardPageTemplate ref={this.pageRef} pageName='features' localUser={null}>
                <FeaturesCard />
            </NerdHerderStandardPageTemplate>
        );
    }
}

class FeaturesCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navigateTo: null,
        }
    }


    render() {

        return(
            <NerdHerderStandardCardTemplate title="Why Choose NerdHerder?" titleIcon='nerdherder_light_192.png'>
                <p>
                    <small className='text-muted'>
                        <i>This page is not intended to be consumed top to bottom! You want to do a thing, use this page to figure out if NerdHerder can help you do your thing!</i>
                    </small>
                </p>
                
                <b>What Is NerdHerder?</b>
                <p>
                    <small className='text-muted'>
                        NerdHerder is the only one-stop-shop for organizing and running gaming events, multi-week leagues, tournaments, special events, and narrative play. NerdHerder was designed with miniature wargaming in mind but has since been expanded to include RPGs, board games and card games (like MTG, Pokemon, or Yu-Gi-Oh). It has a ton of capability, but these things are our most important goals:
                        <small className='text-primary'><b><ol>
                            <li>Support more than tournaments & official formats - there is more to this hobby than the score.</li>
                            <li>Help players and organizers find each other - this will grow the miniature wargaming community.</li>
                            <li>Organize players, dates, files, posts, lists, rules, events, tournaments, etc. in one place - basically help the organizer be successful with their event, which will lead to more events.</li>
                        </ol></b></small>
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        Most competing sites and apps all have a singular focus. It might be running tournaments, or it might be registering users for a tournament that is then run elsewhere (or on paper). Some are dedicated to particular games. There are a few that pair tournaments and statistics...but NerdHerder goes so much further than any of these.
                        None acknowledge that there is more to the community than competitive play. Points for painting? For bringing in new players? Forget about it. When you register with them, you are registering for the tournament, and that's it.
                    </small>
                </p>

                <b>Community Building</b>
                <p>
                    <small className='text-muted'>
                        NerdHerder takes a few approaches to community building:
                        <ul>
                            <li><b>Promotion</b> - NerdHerder examines what events & leagues are starting up, and compares that to our user's interests and location. Users near the event (or interested in online play) are sent a few emails to notify them that there's something fun for them nearby (or online).</li>
                            <li><b>Making Contacts</b> - We encourage our users to make contacts. When a user joins a league or event, it will notify their contacts if they share an interest in that game.</li>
                            <li><b>Opponent Web</b> - NerdHerder knows who has played who recently and in what games. If a past opponent has joined an event or league, we'll send you a notification so that you don't miss it.</li>
                            <li><b>Organizer Features</b> - Similarly, NH knows about your past events & leagues - even ones you were interested in but couldn't join. When a similar event is starting we will let the organizer know so they can make sure to send you an invite.</li>
                            <li><b>Venue / Retailer Features</b> - Users can mark their 'favorite' venues (LGS, Club, etc.). Venue managers (who understand your interests) are able to invite these users directly to their events.</li>
                            <li><b>Global Ranking System</b> - Tournaments are probably the least effective way to build a community. However, our 'global ranking mechanism' rewards for participation in large events and for playing many different opponents in addition to simply winning.</li>
                        </ul>
                    </small>
                </p>

                <b>General Minutia</b>
                <p>
                    <small className='text-muted'>
                        Few people stay dedicated to the same game forever. Something new comes along, and players switch. NerdHerder isn't tied to a particular game - just the tabletop in general. When you eventually switch games, all your stats, progress, contacts come with you...and if you're playing multiple games it can all be together in one place.
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        Every aspect of NerdHerder has been optimized for mobile. Of course using a computer is supported, but NH really shines on phones and tablets. Although NerdHerder is a website, it is installable and has the look and feel of a native application once installed.
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        Privacy is a big concern for NerdHerder. On the one hand, we'd like to keep everything anonymous, but on the other we are trying to help people meet up and play games 'in real life'. So NerdHerder tries to strike a balance, we collect just enough information to prove that you're human and help that person across the table remember your name!
                    </small>
                </p>

                <b>Events & Leagues</b>
                <p>
                    <small className='text-muted'>
                        NerdHerder was specifically designed to work well in multi-week league scenarios (although it does great at single day events). It was also specifically designed to support non-standard formats.
                        Additionally NerdHerder supports...
                        <ul>
                            <li>Multiple League or Tournament Organizers</li>
                            <li>Mixed Scoring (e.g. from games, tournaments, achieving special conditions, painting, etc.)</li>
                            <li>Minior Events (mini-leagues)</li>
                            <li>League / Event Posts (including images)</li>
                            <li>Special Features for Leagues or Tournaments Played Online</li>
                            <li>Polls</li>
                            <li>Integrated Chat</li>
                            <li>Roster / Army List Uploads</li>
                            <li>File Uploads (custom rules, signup forms, etc.)</li>
                            <li>Randomized Drafting / Prize Support</li>
                        </ul>
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        You need to get players to join your league, and NerdHerder helps you out in a couple of ways. After creating your league and making it public, NerdHerder will send out a few promotions:
                        <ul>
                            <li>Promotions are sent to players based on their interests. We don't spam promotions to everyone for every league!</li>
                            <li>Travel distance is a consideration, as is the length of the league or event:</li>
                            <ul>
                                <li>Users who are very interested in the game your league is running will get the promotions even if they live far away</li>
                                <li>On the other hand, users with a lower interest won't get the promotion unless they live nearby</li>
                                <li>Single-day events (which are often tournaments) are promoted to players further away than multi-day or multi-week leagues</li>
                            </ul>
                            <li>When a user signs up for a league or event, any contacts they have that are not signed up are also sent a promotion</li>
                            <li>No promotions are sent for private leagues</li>
                        </ul>
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        Users can also directly search for leagues and events in NerdHerder (including completed ones), and when they do this they can select where to search from and to search for leagues that they normally wouldn't be interested in. Mobile users can search from their current location.
                    </small>
                </p>

                <b>Public and Private Events & Leagues</b>
                <p>
                    <small className='text-muted'>
                        Most events & leagues in NerdHerder are public - meaning that they get promoted and users can search for them. It is possible to make a league private and it is hidden from the everyday user.
                        It is also possible to set up a league to have a password - users without the password cannot join (perhaps they need to pay first).
                        Alternatively, an organizer may set up a league such that players 'request to join' instead of immediately being added. This is meant to be a second option to managing a password (that could easily become public).
                        Finally, the organizer of a league may set a player cap - after the cap is reached the league becomes 'request to join' which is essentially a waitlist.
                    </small>
                </p>

                <b>Casual Games</b>
                <p>
                    <small className='text-muted'>
                        In many leagues, players are expected to show up and play what are essentially pick-up games. Players can simply add these games themselves to NerdHerder, no action is required by the league organizer.
                        However, if they so choose, the organizers can disable casual games...or they can co-opt them to be something more serious or more competitive.
                    </small>
                </p>

                <b>Narrative Play & Minor Events</b>
                <p>
                    <small className='text-muted'>
                        An organizer can set up one or more narrative events within a league - in many ways these events are like a league within a league. NerdHerder calls these 'minor events'. They can include all the league's players, or just a subset.
                        Players outside the minor event cannot see into the event - so this can be used to distribute hidden event information...or keep information hidden until the players should know about it!
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        However, that's not the only way to use minor events within NerdHerder. If there is a special set of rules one night (or week, or whatever) or a special contest, setting up a minor event is a great way to capture that.
                    </small>
                </p>

                <b>Semi-Competitive Leagues & ELO</b>
                <p>
                    <small className='text-muted'>
                        Some players prefer tournaments and competitive play. Others are casual and aren't looking to 'try hard'. Often players can't tie up an entire weekend for a tournament. Furthermore, touraments don't help grow the community nearly as much as general leagues.
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        What if there was a way to get that competitive feeling of a tournament, but with the flexiblity of a multi-week 'funsies' league? There is! The <i>ELO</i> mechanism used in Chess provides a way for the competitve players to see who is best without the strict pairing required of tournaments.
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                       With NH, organizers can enable ELO ratings:
                        <ul>
                            <li>Ratings are normally applied to pick-up (casual) games, but they can be used on any game type</li>
                            <li>NH doesn't use the same ELO as Chess - instead we use the popular OpenSkill library to rate players</li>
                            <li>OpenSkill uses a statistical approach to rating players, the more they play the better their rating will reflect their actual ability</li>
                            <li>Normally ELO works on Win-Loss ratios, optionally organizers can enable a 'special modification' to consider margin of victory (MoV) in the calculations</li>
                        </ul>
                    </small>
                </p>

                <b>Tournaments</b>
                <p>
                    <small className='text-muted'>
                        NerdHerder excels at swiss, round-robin, and elimination style tournaments.
                        <ul>
                            <li>All formats can support up to 128 players</li>
                            <li>Swiss supports up to 20 rounds
                                <ul>
                                    <li>System avoids pairing players that know each other in round 1</li>
                                    <li>Optionally set up a 'Championship Match' in the final round</li>
                                </ul>
                            </li>
                            <li>Single or double elimination is supported</li>
                            <li>A league or event can have multiple tournaments</li>
                            <li>Results from one tournament can be used as initial score or seeds in a second tournament</li>
                            <li>Standings and pairing can be based on Match Points, Victory Points, or Margin of Victory</li>
                            <li>Many games have defaults set based on the manufactures official tournament rules</li>
                                <ul>
                                    <li>If they are not set and you are familiar with the game, we would love to work with you to get them set!</li>
                                </ul>
                            <li>Tiebreaking can use any combination / order of:
                                <ul>
                                    <li>Match Points</li>
                                    <li>Victory Points</li>
                                    <li>Margin of Victory</li>
                                    <li>Strength of Schedule</li>
                                    <li>Player vs Opponent Record</li>
                                </ul>
                            </li>
                            <li>Sometimes games have more than one kind of Victory Point (e.g. objective points and army points defeated), NH supports this as well</li>
                            <li>It is always possible for the organizers to override NH's pairing or victory determination</li>
                            <li>Organizers can set up a tournament among a subset of players - one could have several smaller tournaments with winners participating in a capstone tournament to determine the ultimate champion!</li>
                            <li>Tournament games in NerdHerder must be <i>reviewed</i> - too many times a game is reported incorrectly and the outcome of the tournament is affected. So, during tournaments after a player records a game a push is sent to the other player to verify the results recorded. Tournament organizers are able to see who has not verified their games, and remind them or override them if they don't review the game in a timely manner.</li>
                        </ul>
                    </small>
                </p>

                <b>Real-Time Tournaments</b>
                <p>
                    <small className='text-muted'>
                        When there are many people playing in a tournament's games simultaneously, organizers can enable the real-time features of NerdHerder.
                        <ul>
                            <li>Players are sent push notifications when the...
                                <ul>
                                    <li>tournament starts</li>
                                    <li>pairings are posted</li>
                                    <li>current round starts</li>
                                    <li>current round ends</li>
                                </ul>
                            </li>
                            <li>A page designed to be projected with a countdown clock, QR code and other details is provided to the organizers</li>
                            <li>An extra card is displayed to the players with a similar clock which is synchronized to the organizer's clock through NerdHerder's servers</li>
                            <li>Tournament organizers are given the ability to stop, start, restart the clock as needed</li>
                            <li>Players in the tournament are given a "Call TO" button on their device that sends an alert to all tournament managers</li>
                        </ul>
                    </small>
                </p>

                <b>Tournament Streamlining</b>
                <p>
                    <small className='text-muted'>
                        For large tournaments, having to deal with every player is tedious - NerdHerder is here to help!
                        <ul>
                            <li>Automated Check-in: No need to meet with each player to verify that they made it to the tournament!
                                <ul>
                                    <li>NH provides a QR code that is scanned by your players to check in</li>
                                    <li>TOs keep these codes private, showing them only at the venue during the tournament (displayed on a screen or printed out and placed at tables)</li>
                                    <li>NH prompts players to 'check-in' when they arrive - this requires them to scan the code (players are physically present when they check in)</li>
                                    <li>The TO is given a page showing who has checked in and who is missing</li>
                                    <li>A 6-digit manual code and TO 'check-in override' is also provided</li>
                                </ul>
                            </li>
                            <li>Army List Uploads: No need to meet with each player to collect and verify their lists! No paper lists!
                                <ul>
                                    <li>The TO can make a list required - players are prompted to upload before they can view opponents' lists</li>
                                    <li>The TO can make a list locked - the player cannot change the list without contacting a TO</li>
                                    <li>The TO can view/modify/delete any list at any time</li>
                                    <li>The TO is given a page showing who is missing a required list</li>
                                </ul>
                            </li>
                            <li>Registration Payments: No need to take payments from every player day of!
                                <ul>
                                    <li>Venue Owners can setup a Stripe account to collect payments</li>
                                    <li>Organizers (who don't need to be Venue Owners) can setup payments using the Venue's Stripe account</li>
                                    <li>Payments through Stripe can be required or optional</li>
                                    <li>Discount codes can be setup allowing the TO to run whatever fee schedule they want</li>
                                </ul>
                            </li>
                        </ul>
                    </small>
                </p>

                <b>External Tournaments</b>
                <p>
                    <small className='text-muted'>
                        Sometimes a game system comes with its own tournament software (e.g. MTG's Event Link). NerdHerder provides a way for a League Organizer to use NH's features for communication, organization, and sharing while using that external site for the tournament. Currently NH supports:
                        <ul>
                            <li>Digimon TCG - Bandai TCG+</li>
                            <li>Marvel: Crisis Protocol - Longshanks</li>
                            <li>Magic the Gathering - Wizards Event Link</li>
                            <li>One Piece TCG - Bandai TCG+</li>
                            <li>Pokemon - Pokemon Trainer</li>
                            <li>Yu-Gi-Oh! - Konami Events</li>
                        </ul>
                    </small>
                </p>

                <b>Roster / Army List Upload</b>
                <p>
                    <small className='text-muted'>
                        NerdHerder allows users to upload their list from a multitude of sources and supports virtually all formats.
                        <ul>
                            <li>Files - any file that can be viewed in a browser works (text, pdf, jpg, png for example)</li>
                            <ul>
                                <li>On mobile, users can opt to activate their camera and snap a pic of a paper list</li>
                            </ul>
                            <li>Text - can be pasted into NH or typed in</li>
                            <li>Integration with external sites allow users to bypass uploads altogether, they just pick from a list on NH</li>
                            <ul>
                                <li>Star Wars: Legion players can view and select a list from Tabletop Admiral</li>
                                <li>Star Wars: Shatterpoint players can view and select a list from Point Break</li>
                                <li>Marvel: Crisis Protocol players can view and select a list from Cerebro MCP</li>
                                <li>Marvel: Crisis Protocol players can also directly copy the Tabletop Simulator codes from a Cerebro MCP list</li>
                            </ul>
                        </ul>
                        There are a few other capabilities supported:
                        <ul>
                            <li>A list can be <i>required</i> - players who have not uploaded a required list cannot see other's lists</li>
                            <li>A list can be <i>hidden</i> - others cannot see the list until the player shares it with them</li>
                            <li>A list can be <i>locked</i> - after the list is uploaded, the player cannot change it</li>
                            <li>Even when a list is hidden or locked, organizers are able to view / unlock / delete the list as needed</li>
                            <li>Multiple Lists - it is possible for users to upload multiple lists in different categories</li>
                            <li>List Affinity - it is also possible to have one category of lists for casual play, another for narrative events, and yet another for a tournament</li>
                            <li>Optionally the organizers can add instructions or clarifications on what is allowed in a list</li>
                        </ul>
                    </small>
                </p>

                <b>Posts and Integrated Chat</b>
                <p>
                    <small className='text-muted'>
                        Within a league the players (and managers) are presented with an interface that has a social media feel to it. They can post questions, images of their miniatures, or of memorable (salty?!) game moments.
                        Additionally this interface can be used by the managers to answer questions, make additional clarifications, or broadcast announcements. Each post is emailed to all of the league's participants - as it would be unreasonable to expect them to be monitoring the league on NerdHerder. 
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        For a more real-time experience, league participants can also use the real-time chat features of NerdHerder. While in the chat, participants get a notification as they would in Discord or Facebook Messenger.
                    </small>
                </p>
                <p>
                    <small className='text-muted'>
                        Of course some organizers may have an established Discord server (or some other chat mechanism) that they prefer. For these situations, the league organizer can disable integrated chat and optionally leave a discord invite on the chat tab.
                    </small>
                </p>
                
                <b>Polling</b>
                <p>
                    <small className='text-muted'>
                        NerdHerder has a basic polling mechanism that allows a league organizer to query their participants. It is flexible, and can be used for so much more than a 'vote for your favorite...' type interaction.
                        <ul>
                            <li>Supports limits on voting to players, organizers, players & organizers, or everyone - including those not yet in the league!</li>
                            <li>Can be configured for public or anonymous voting</li>
                            <li>Can also be set to show the results to everyone, only after voting, or only after the poll is completed</li>
                        </ul>
                    </small>
                </p>

                <b>Statistics</b>
                <p>
                    <small className='text-muted'>
                        NerdHerder has stats!
                        <ul>
                            <li>TBD</li>
                        </ul>
                    </small>
                </p>

                <b>Ranking System</b>
                <p>
                    <small className='text-muted'>
                        <p>The global ranking system on NerdHerder aims to reward players for:</p>
                        <ul>
                            <li>Winning games</li>
                            <li>Playing more</li>
                            <li>Playing recently</li>
                            <li>Playing in person</li>
                            <li>Playing large tournaments</li>
                            <li>Playing different opponents</li>
                            <li>Playing tougher opponents</li>
                        </ul>
                        <p>The list above is in no particular order. The rank includes games from tournaments with 6 or more players, and only from tournaments with global ranking enabled. Casual and minor-event (narrative) games are not ranked.</p>
                        <p>Each game system is ranked separately - a Marvel: Crisis Protocol tournament has no impact on global rank for Warmachine & Hordes or Kill Team.</p>
                        <p><i>Note: We're not saying that the #1 ranked player is the GOAT, but a well ranked player is probably pretty good at the game.</i></p>
                    </small>
                </p>

            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(FeaturesPage);
