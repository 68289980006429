import { generateDateString, getStorageFilePublicUrl, getStaticStorageImageFilePublicUrl, convertTodaysLocalDateObjectToFormInput } from './utilities.js';
import { DateTime, IANAZone, SystemZone } from 'luxon';

class NerdHerderDataModel {

}

export class NerdHerderTopic extends NerdHerderDataModel {
    constructor(id=null, name='') {
        super();

        this.id = id;
        this.name = name;
        this.curator_ids = [];
        this.lead_user_id = null;
        this.faction_data = '';
        this.collect_game_stats = false;
        this.collect_list_stats = false;
    }

    isCurator(userId) {
        if (this.curator_ids.includes(userId)) return true;
        return false;
    }

    getFactionDict() {
        const factionDict = {};
        const factionList = this.faction_data.split('|');
        for (const factionItem of factionList) {
            const factionItems = factionItem.split(',');
            if (factionItems.length < 2)  continue;
            const itemId = factionItems[0];
            const itemName = factionItems[1];
            if (itemId === '' || itemName === '') continue;
            factionDict[itemId] = itemName;
        }
        return factionDict;
    }

    getExternalSiteName() {
        switch(this.external_site) {
            case 'longshanks':
                return 'Longshanks';
            case 'bandai':
                return 'Bandai TCG+';
            case 'konami':
                return 'Konami';
            case 'wizards':
                return 'Wizards Event Link';
            case 'pokemon':
                return 'Pokemon Trainer Club';
            default:
                return '';
        }
    }
}

export class NerdHerderUser extends NerdHerderDataModel {

    mergeContactInfo(localUser) {
        if (localUser.id === this.id) {
            this.short_name = localUser.short_name;
            this.phone = localUser.phone;
        } else if (localUser.contact_ids.includes(this.id)) {
            for (const contact of localUser.contacts) {
                if (contact.id === this.id) {
                    this.short_name = contact.short_name;
                    this.phone = contact.phone;
                    break
                }
            }
        }
    }

    hasContact(otherUserId) {
        if (this.contact_ids.includes(otherUserId)) {
            const state = this.getContactState(otherUserId);
            if (state === 'friends') return true;
            return false;
        }
        return false;
    }

    getContactState(otherUserId) {
        const userContact = this.getUsersContacts(otherUserId);
        if (userContact !== null) {
            switch(userContact.state) {
                case 'friends':
                    return 'friends';
                
                case 'requested':
                    // eslint-disable-next-line eqeqeq
                    if (userContact.source_user_id == this.id) {
                        return 'pending';
                    } else {
                        return 'requested';
                    }

                default:
                    return userContact.state;
            }
        }
        return null;
    }

    getUsersContacts(otherUserId) {
        for (const userContact of this.users_contacts) {
            if (userContact.user1_id === this.id && userContact.user2_id === otherUserId) {
                return userContact;
            }
            if (userContact.user2_id === this.id && userContact.user1_id === otherUserId) {
                return userContact;
            }
        }
        return null;
    }

    getContact(otherUserId) {
        for (const contactData of this.contacts) {
            if (otherUserId === contactData.id) return contactData;
        }
        return null;
    }

    getImageUrl() {
        if (this.profile_image === null || this.profile_image.includes('meeple.png')) {
            return getStaticStorageImageFilePublicUrl('/meeple.png');
        }
        return this.profile_image;
    }

    getUserNameAndShortNameIfAvailable() {
        if (this.short_name) {
            return `${this.username} (${this.short_name})`;
        }
        return this.username;
    }
}

export class NerdHerderLeague extends NerdHerderDataModel {
    constructor() {
        super();

        this.id = null;
        this.name = '';
        this.type = 'league';
        this.top_post_id = null;
        this.creator_id = null;
        this.players_create_games = true;
        this.elo_configuration = 'disabled';
        this.elo_ratio_enabled = false;
        this.join_league_via_password = null;
        this.join_league_via_request = false;
        this.use_concur_reg_games = false;
        this.chat_channel_enabled = 'members';
        this.private_league = false;
        this.rough_dates = false;
        this.start_date = convertTodaysLocalDateObjectToFormInput();
        this.end_date = null;
        this.open_time = null;
        this.start_time = null;
        this.summary = '';
        this.topic_id = 'SWL';
        this.league_id = null;
        this.zipcode = '';
        this.country = 'United States';
        this.venue_string = '';
        this.venue_id = null;
        this.contact = '';
        this.max_players = null;
        this.state = 'draft';
        this.googlesheet = null;
        this.googlesheet_label = null;
        this.googlesheet_range = null;
        this.external_links = '';
        this.external_site = null;
        this.online = false;
        this.latitude = null;
        this.longitude = null;
        this.timezone = null;
        this.registration_fee_currency = 'USD';
        this.registration_fee_option = 'disabled';
        this.registration_fee_overhead = 'venue';
        this.registration_fee_codes = '';
        this.registration_fee_message = null;
        this.registration_fee_cost = 0.0;
        this.external_site = null;
        this.external_site_code = null;
        this.alt_chat_text = null;

        this.manager_ids = [];
        this.player_ids = [];
        this.interested_user_ids = [];
        this.join_request_user_ids = [];
        this.invite_sent_user_ids = [];
        this.waitlist_user_ids = [];
        this.game_ids = [];
        this.tournament_ids  =[];
        this.team_ids = [];
        this.event_ids = [];
        this.message_tree = [];
    }

    isManager(userId) {
        if (this.manager_ids.includes(userId)) return true;
        return false;
    }

    isPlayer(userId) {
        if (this.player_ids.includes(userId)) return true;
        return false;
    }

    isFull() {
        if (this.max_players === null) return false;
        if (this.player_ids.length + this.invite_sent_user_ids.length >= this.max_players) return true;
        return false;
    }

    getManageUrl() {
        return `/app/manageleague/${this.id}`;
    }

    getPageUrl() {
        return `/app/league/${this.id}`;
    }

    getImageUrl() {
        if (this.image === null || this.image.includes('kraken.png')) {
            return getStaticStorageImageFilePublicUrl('/kraken.png');
        }
        return this.image;
    }

    getScheduleString() {
        let startDateString = null;
        let endDateString = null;
        let roughDates = this.rough_dates;
        let singleDay = false;
        let luxonTimeZone = new IANAZone(this.timezone);
        if (!luxonTimeZone.isValid) luxonTimeZone = new SystemZone();
        if (this.start_date) {
            let luxonDate = DateTime.fromISO(`${this.start_date}T00:00:00`, {zone: luxonTimeZone});
            startDateString = luxonDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
        }
        if (this.end_date) {
            let luxonDate = DateTime.fromISO(`${this.end_date}T00:00:00`, {zone: luxonTimeZone});
            endDateString = luxonDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
        }
        if (this.start_date && this.end_date && this.start_date === this.end_date) singleDay = true;

        let datesString = 'Schedule Undecided'
        if (singleDay && startDateString && endDateString && roughDates) datesString = "Probably on " + startDateString;
        else if (startDateString && endDateString && roughDates) datesString = "Roughly " + startDateString + " to " + endDateString;
        else if (singleDay && startDateString && endDateString) datesString = startDateString;
        else if (startDateString && endDateString) datesString = startDateString + " to " + endDateString;
        else if (startDateString && !endDateString && roughDates) datesString = "Starts roughly " + startDateString;
        else if (startDateString) datesString = "Starts on " + startDateString;
        return datesString;
    }

    getStatusString() {
        switch (this.state) {
            case 'draft':
                return "Draft"
            case 'interest':
                return "Generating Player Interest"
            case 'open':
                return "Open for Registration"
            case 'running':
                return "In-Progress"
            case 'complete':
                return "Concluded"
            case 'archived':
                return "Concluded & Archived"
            default:
                return "Unknown"
        }
    }

    getTypeWord(shortName=false) {
        switch (this.type) {
            case 'league':
                return "league";
            case 'tournament':
                if (shortName === true) return "event";
                return "competitive event";
            case 'event':
                return "event";
            default:
                return "league";
        }
    }

    getTypeWordCaps(shortName=false) {
        switch (this.type) {
            case 'league':
                return "League";
            case 'tournament':
                if (shortName === true) return "Event";
                return "Competitive Event";
            case 'event':
                return "Event";
            default:
                return "League";
        }
    }

    getTypeWordPossessive(shortName=false) {
        switch (this.type) {
            case 'league':
                return "league's";
            case 'tournament':
                return "event's";
            case 'event':
                return "event's";
            default:
                return "league's";
        }
    }

    getTypeWordPlural() {
        switch (this.type) {
            case 'league':
                return "leagues";
            case 'tournament':
                return "competitive events";
            case 'event':
                return "events";
            default:
                return "leagues";
        }
    }

    isSingleTournamentEnabled() {
        // we can get into single tournament mode if:
        // - this league is a tournament only type
        // - there are no casual games, and players cannot enter casual games
        // - there is exactly 1 tournament in the posted, in-progress states and it's not part of an event
        // - the league is not using an external site to run tournaments
        let singleTournamentMode = false;
        
        if (this.type === 'tournament' && this.tournament_summary.length === 1 && this.games.casual.length === 0 && this.players_create_games === false && this.external_site === null) {
            const tournamentSummary = this.tournament_summary[0];
            if (['posted', 'in-progress'].includes(tournamentSummary.state) && tournamentSummary.event_id === null) {
                singleTournamentMode = true;
            }
        }
        return(singleTournamentMode);
    }

    getSingleTournamentId() {
        let tournamentId = null;
        if (this.isSingleTournamentEnabled()) {
            tournamentId = this.tournament_ids[0];
        }
        return(tournamentId);
    }
}

export class NerdHerderFile extends NerdHerderDataModel {
}

export class NerdHerderUserLeague extends NerdHerderDataModel {
    constructor(userId=null, leagueId=null) {
        super();

        this.user_id = userId;
        this.league_id = leagueId;
        this.level_of_interest = 'none';
        this.manager = false;
        this.player = false;
        this.paid = false;
        this.paid_amount = 0;
        this.paid_currency = 'USD';
        this.paid_code = null;
        this.join_request = false;
        this.invite_sent = false;
        this.waitlist_position = null;
    }
}

export class NerdHerderUserVenue extends NerdHerderDataModel {
    constructor(userId=null, venueId=null) {
        super();

        this.user_id = userId;
        this.venue_id = venueId;
        this.manager = false;
        this.favorite = false;
    }
}

export class NerdHerderEvent extends NerdHerderDataModel {

    constructor(leagueId=null) {
        super();

        this.id = null;
        this.league_id = leagueId;
        this.name = '';
        this.summary = '';
        this.start_date = null;
        this.end_date = null;
        this.top_post_id = null;
        this.state = 'posted';
        this.order = 1;
        this.all_league_players = true;
    }

    getScheduleString() {
        let startDateString = null;
        let endDateString = null;
        if (this.start_date) startDateString = generateDateString(new Date(this.start_date))
        if (this.end_date) endDateString = generateDateString(new Date(this.end_date))

        let datesString = null;
        if (startDateString && endDateString) datesString = startDateString + " to " + endDateString;
        else if (startDateString) datesString = startDateString;
        else if (endDateString) datesString = endDateString;
        return datesString;
    }

    isManager(userId) {
        if (this.manager_ids.includes(userId)) return true;
        return false;
    }

    isPlayer(userId) {
        if (this.player_ids.includes(userId)) return true;
        return false;
    }

    getManageUrl() {
        return `/app/manageevent/${this.id}`;
    }
}

export class NerdHerderTournament extends NerdHerderDataModel {

    isManager(userId) {
        if (this.manager_ids.includes(userId)) return true;
        return false;
    }

    isPlayer(userId) {
        if (this.player_ids.includes(userId)) return true;
        return false;
    }

    getManageUrl() {
        return `/app/managetournament/${this.id}`;
    }

    getPageUrl() {
        return `/app/tournament/${this.id}`;
    }

    getImageUrl() {
        if (this.image === null || this.image.includes('kraken.png')) {
            return getStaticStorageImageFilePublicUrl('/kraken.png');
        }
        return getStorageFilePublicUrl(this.image);
    }

    getByeResults() {
        const result = {record: 'w', mp: 3, vp: 14, lp: 0, vp1: 14, lp1: 0, vp2: 0, lp2: 0, vp3: 0, lp3: 0};
        // should be something like w:3:14:0 or w:3:14:0:0:0:0:0
        // w = win (t = tie, l = loss)
        // 3 = match points
        // 14 = points assigned to winner (bye player)
        // 0 = points assigned to fictional player who lost - only used for MoV scoring
        // the last 4 zeros are points assigned to winner (bye player) for VP2, VP3 and the associated fictional loser player
        const values = this.bye_results.split(':');
        // if the result is not valid, return the default result
        if (values.length !== 4 && values.length !== 8) return result;
        result.record = values[0];
        // note that the second value is not used - instead it is set automatically from the record setting
        switch (result.record) {
            case 't':
                result.mp = 1;
                break;
            case 'l':
                result.mp = 0;
                break;
            default:
                result.mp = 3;
        }
        result.vp1 = parseInt(values[2]);
        result.lp1 = parseInt(values[3]);
        if (values.length === 8) {
            result.vp2 = parseInt(values[4]);
            result.lp2 = parseInt(values[5]);
            result.vp3 = parseInt(values[6]);
            result.lp3 = parseInt(values[7]);
        }
        // maintain backward compatibility
        result.vp = result.vp1;
        result.lp = result.lp1;
        return result
    }

    getByeResultsString() {
        if (this.type === 'elimination') return ('Considered a Win');
        const byeResults = this.getByeResults();
        const recordToWords = {w: 'win', t: 'tie', l: 'loss'};
        let result = `Considered a ${recordToWords[byeResults.record]}`;
        if (this.subtype === 'mp') result += ` worth ${byeResults.mp} match points`;
        return result;
    }

    setByeResults(record, mp, vp1, lp1, vp2=0, lp2=0, vp3=0, lp3=0) {
        this.bye_results = `${record}:${mp}:${vp1}:${lp1}:${vp2}:${lp2}:${vp3}:${lp3}`;
        return this.bye_results;
    }

    getRankingString() {
        if (this.type === 'elimination') return ('Not Applicable');
        let rankingString = `${this.ranking}`;
        rankingString = rankingString.replaceAll('>', ' > ');
        rankingString = rankingString.replaceAll('mov', 'MoV');
        rankingString = rankingString.replaceAll('vp', 'VPs');
        rankingString = rankingString.replaceAll('mp', 'MPs');
        rankingString = rankingString.replaceAll('rec', 'Record');
        rankingString = rankingString.replaceAll('sos', 'SoS');
        return rankingString;
    }

    getScheduleString() {
        let dateString = 'Date Undecided';
        const dateRange = [];
        if (this.date) {
            let date = new Date(this.date);
            dateRange.push(date)
        }
        if (this.rounds) {
            for (const roundData of this.rounds) {
                if (roundData.date) {
                    let date = new Date(roundData.date);
                    dateRange.push(date)
                }
            }
        }

        // remove duplicates
        const uniqDateRange = dateRange.filter((item, index, array)=>{
            const itemValue = item.valueOf();
            for (let i=0; i<array.length; i++) {
                const item2 = array[i];
                if (item2.valueOf() === itemValue) {
                    if (i === index) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            // should never get here
            return true;
        });


        if (uniqDateRange.length === 1) {
            dateString = generateDateString(dateRange[0]);
        }
        else if (uniqDateRange.length > 1) {
            dateRange.sort((date1, date2) => date1 - date2);
            let ds1 = generateDateString(dateRange[0]);
            let ds2 = generateDateString(dateRange[dateRange.length-1]);
            dateString = `${ds1} to ${ds2}`;
        }
        
        return dateString;
    }

    getShortStatusString() {
        switch (this.state) {
            case 'draft':
                return "Draft"
            case 'posted':
                return "Not Started"
            case 'in-progress':
                return "In Progress"
            case 'complete':
                return "Complete"
            default:
                return "Unknown"
        }
    }

    getTypeDescription() {
        let typeDescription = null;
        let subtypeString = null;
        if (this.type === 'swiss') {
            if (this.subtype === 'mov') {
                subtypeString = 'margin of victory paring';
            } else if (this.subtype === 'vp') {
                subtypeString = 'victory points paring';
            } else {
                subtypeString = 'match points paring';
            }
            typeDescription = `Swiss (${subtypeString})`;
        } else if (this.type === 'round-robin') {
            if (this.subtype === 'mov') {
                subtypeString = 'margin of victory scoring';
            } else if (this.subtype === 'vp') {
                subtypeString = 'victory points scoring';
            } else {
                subtypeString = 'match points scoring';
            }
            typeDescription = `Round-Robin (${subtypeString})`;
        } else {
            if (this.subtype === 'double-elimination') {
                typeDescription = 'Double Elimination';
            } else {
                typeDescription = 'Single Elimination';
            }
        }
        return typeDescription;
    }

    getRoundStatusDescription() {
        // draft and complete are easy - just return the state 'in english'
        if (this.state === 'draft') return('Still being configured...');
        if (this.state === 'complete') return('All rounds completed');

        // for in-progress or posted, it depends on what the rounds are doing
        let roundStatus = 'Waiting to start...';
        for (const roundData of this.rounds) {
            if (roundData.state === 'initialized') {
                roundStatus = `${roundData.name} pairings posted`;
            } else if (roundData.state === 'in-progress') {
                roundStatus = `Running - in ${roundData.name}`;
            } else if (roundData.state === 'complete') {
                roundStatus = `Running - ${roundData.name} complete`;
            }
        }
        return roundStatus;
    }

    getMaxNumberOfRounds() {
        let numberOfRounds = this.num_rounds;
        if (this.round_ids.length > numberOfRounds) numberOfRounds = this.round_ids.length;
        return(numberOfRounds);
    }

    getTypeAndMethodJsx() {
        let typeAndMethodJsx = null;
        const tournamentType = this.type;
        const tournamentSubtype = this.subtype;
        if (tournamentType === 'elimination') {
            if (tournamentSubtype === 'single-elimination') typeAndMethodJsx = <p>Single Elimination</p>
            else if (tournamentSubtype === 'double-elimination') typeAndMethodJsx = <p>Double Elimination (using losers bracket)</p>
        } else if (tournamentType === 'swiss') {
            if (tournamentSubtype === 'mp') typeAndMethodJsx = 
                <div>
                    <p>Swiss Pairings (using match points)</p>
                    <ul><li>Win = 3 match points</li><li>Tie = 1 match point</li><li>Loss = 0 match points</li></ul>
                </div>
            else if (tournamentSubtype === 'vp') typeAndMethodJsx = 
                <div>
                    <p>Swiss Pairings (using victory points)</p>
                    <ul><li>victory points = score achieved during game</li></ul>
                </div>
            else if (tournamentSubtype === 'mov') typeAndMethodJsx = 
                <div>
                    <p>Swiss Pairings (using margin of victory)</p>
                    <ul><li>Win = winners score - losers score</li><li>Loss = losers score - winners score</li></ul>
                </div>
        } else if (tournamentType === 'round-robin') {
            if (tournamentSubtype === 'mp') typeAndMethodJsx = 
                <div>
                    <p>Round-Robin Pairings (using match points)</p>
                    <ul><li>Win = 3 match points</li><li>Tie = 1 match point</li><li>Loss = 0 match points</li></ul>
                </div>
            else if (tournamentSubtype === 'vp') typeAndMethodJsx = 
                <div>
                    <p>Round-Robin Pairings (using victory points)</p>
                    <ul><li>victory points = score achieved during game</li></ul>
                </div>
            else if (tournamentSubtype === 'mov') typeAndMethodJsx = 
                <div>
                    <p>Round-Robin Pairings (using margin of victory)</p>
                    <ul><li>Win = winners score - losers score</li><li>Loss = losers score - winners score</li></ul>
                </div>
        }
        return(typeAndMethodJsx);
    }
}

export class NerdHerderUserTournament extends NerdHerderDataModel {

    constructor(userId=null, tournamentId=null) {
        super();

        this.tournament_id = tournamentId;
        this.user_id = userId;
        this.metric = 0;
        this.seed = 0;
        this.dropped = false;
    }
}

export class NerdHerderTournamentRound extends NerdHerderDataModel {

    constructor(tournamentId=null, index=null) {
        super();

        this.tournament_id = tournamentId;
        this.name = index === null ? 'New Round' : `Round ${index}`;
        this.index = index;
        this.state = 'draft';
        this.date = null;
        this.time = null;
        this.duration = null;
        this.remaining = null;
        this.clock_running = false;
        this.clock_started = null;
    }

    getManageUrl() {
        return `/app/managetournament/${this.id}`;
    }

    getRoundStatusDescription() {
        if (this.state === 'draft') return('Draft');
        else if (this.state === 'initialized') return('Ready - not started');
        else if (this.state === 'in-progress') return('In-Progress');
        else return('Complete');
    }
}

export class NerdHerderMessage extends NerdHerderDataModel {
    getUserReactionType(userId) {
        for(const usersMessages of this.reactions) {
            if (usersMessages.user_id === userId) {
                return usersMessages.type;
            }
        }
    }
}

export class NerdHerderRandomSelector extends NerdHerderDataModel {
    constructor(league_id=null) {
        super();

        this.id = null;
        this.league_id = league_id;
        this.tournament_id = null;
        this.event_id = null;
        this.state = 'draft';
        this.name = 'A Random Player';
        this.result = null;
        this.description = null;
    }
}

export class NerdHerderNotification extends NerdHerderDataModel {
}

export class NerdHerderGame extends NerdHerderDataModel {
    constructor(league_id=null, event_id=null) {
        super();

        this.id = null;
        this.date = convertTodaysLocalDateObjectToFormInput();
        this.proposed_datetime = null;
        this.details = '';
        this.league_id = null;
        this.event_id = null;
        this.tournament_id = null;
        this.round_id = null;
        this.elim_code = null;
        this.table_name = null;
        this.completion = 'completed';
        this.state = 'posted';
        this.bye = false;
        this.ranked = false;
        this.board_game_id = null;
        this.game_points = null;
        this.first_player_id = null;
        this.extra_info = null;

        this.player_ids = [];
        this.players = [];
    }

    isPlayer(userId) {
        if (this.player_ids.includes(userId)) return true;
        return false;
    }

    getDateString() {
        let dateString = 'Date Not Set';
        if (this.proposed_datetime) {
            let postfix = '';
            if (!this.scheduleFullyAccepted()) postfix = ' (proposed)';
            let luxonDate = DateTime.fromISO(this.proposed_datetime);
            dateString = `${luxonDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}${postfix}`;
        }
        else if (this.date) {
            let luxonDate = DateTime.fromISO(this.date);
            dateString = luxonDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
        }
        return dateString;
    }

    setElimCode(isWinnerBracket, roundIndex, gameIndex, topPrevIndex, botPrevIndex) {
        let bracket_string = 'wb';
        if (!isWinnerBracket) {
            bracket_string = 'lb';
        }
        let code = `${bracket_string}:${roundIndex}:${gameIndex}:${topPrevIndex}:${botPrevIndex}`
        this.elim_code = code;
        return code;
    }

    isFullyVerified() {
        // if any player hasn't concurred, then not fully verified
        let playersCounted = 0;
        for (const usersGames of this.players) {
            if (!usersGames.concur_with_results) {
                return false;
            }
            playersCounted++;
        }
        // we don't consider single player (or zero-player) games to ever be fully verified (otherwise after creation they couldn't be edited)
        if (playersCounted <= 1) return false;

        // ok must have enough players and all marked verified so it's fully verified!
        return true;
    }

    scheduleFullyAccepted() {
        for (const usersGames of this.players) {
            if (!usersGames.concur_with_schedule) {
                return false;
            }
        }
        return true;
    }

    parseElimCode() {
        let bracket = 'wb';
        let roundIndex = 0;
        let gameIndex = 0;
        let topPrevIndex = 0;
        let botPrevIndex = 0;

        if (this.elim_code !== null) {
            const elimArray = this.elim_code.split(':');
            bracket = elimArray[0];
            roundIndex = elimArray[1];
            gameIndex = elimArray[2];
            topPrevIndex = elimArray[3];
            botPrevIndex = elimArray[4]; 
            roundIndex = parseInt(roundIndex);
            gameIndex = parseInt(gameIndex);
            topPrevIndex = parseInt(topPrevIndex);
            botPrevIndex = parseInt(botPrevIndex);
        }
            
        return {bracket: bracket, roundIndex: roundIndex, gameIndex: gameIndex, topPrevIndex: topPrevIndex, botPrevIndex: botPrevIndex};
    }

    getStatusString() {
        switch (this.state) {
            case 'draft':
                return "Draft"
            default:
                if (this.bye) return "BYE";
                switch (this.completion) {
                    case 'scheduled':
                        return "Scheduled"
                    case 'in-progress':
                        return "In Progress"
                    case 'completed':
                        return "Completed"
                    default:
                        return "Unknown"
                }
        }
    }

    getPlayersDict(leagueData) {
        const winnerPlayerIds = [];
        const loserPlayerIds = [];
        const needsConcurIds = [];
        const needsScheduleConcurIds = [];
        const userDict = {};
        const userGamesDict = {};
        let isScored = false;
        let isPostedAndCompleted = false;
        let isSchedulable = false;
        if (this.state === 'posted' && this.completion === 'completed') isPostedAndCompleted = true;
        if (this.state === 'posted' && (this.completion === 'scheduled' || this.completion === 'in-progress')) isSchedulable = true;

        for (const usersGames of this.players) {
            const userData = usersGames.user_info;
            const newUser = NerdHerderDataModelFactory('user', userData);
            userGamesDict[usersGames.user_id] = usersGames;
            userDict[usersGames.user_id] = newUser;
            if (isPostedAndCompleted && usersGames.winner) {
                winnerPlayerIds.push(usersGames.user_id);
            } else {
                loserPlayerIds.push(usersGames.user_id);
            }
            
            // if a game is posted and completed, it needs concur if a tournament game, or if it's casual and the league uses casual games...
            if (isPostedAndCompleted) { 
                if (this.tournament_id !== null) {
                    if (!usersGames.concur_with_results) {
                        needsConcurIds.push(usersGames.user_id);
                    }
                } else {
                    if (leagueData.use_concur_reg_games && !usersGames.concur_with_results) {
                        needsConcurIds.push(usersGames.user_id);
                    }
                }
            }
            if (isPostedAndCompleted && usersGames.score !== 0) isScored = true;

            // if a game is posted and either scheduled or in-progress, allow scheduling the game
            if (isSchedulable && this.proposed_datetime) {
                if (!usersGames.concur_with_schedule) {
                    needsScheduleConcurIds.push(usersGames.user_id);
                }
            }
        }

        return {
            winnerPlayerIds: winnerPlayerIds,
            loserPlayerIds: loserPlayerIds,
            needsConcurIds: needsConcurIds,
            needsScheduleConcurIds: needsScheduleConcurIds,
            usersGames: userGamesDict,
            users: userDict,
            isScored: isScored,
            isSchedulable: isSchedulable
        }
    }
}

export class NerdHerderUserGame extends NerdHerderDataModel {

    constructor(userId=null, gameId=null) {
        super();

        this.game_id = gameId;
        this.user_id = userId;
        this.list_id = null;
        this.concur_with_results = false;
        this.concur_with_schedule = false;
        this.concur_user_id = null;
        this.winner = false;
        this.score = 0;
        this.score1 = 0;
        this.score2 = 0;
        this.score3 = 0;
        this.dropped = false;
        this.faction = null;
        this.list_points = null;
    }
}

export class NerdHerderPoll extends NerdHerderDataModel {
    constructor(leagueId=null) {
        super();

        this.id = null;
        this.league_id = leagueId;
        this.state = 'draft';
        this.title = '';
        this.text = null;
        this.end_date = null;
        this.results_shown = 'postvote';
        this.voters_shown = true;
        this.allowed_voters = 'members';

        this.user_ids = [];
        this.users = [];
        this.option_ids = [];
        this.options = [];
        this.results = {};
    }

    hasUserVoted(userId) {
        if (this.user_ids.includes(userId)) return true;
        return false;
    }

    canUserVote(userId, league) {
        let canVote = false;
        if (this.state === 'posted') {
            switch (this.allowed_voters) {
                case 'members':
                    if (league.isPlayer(userId) || league.isManager(userId)) canVote = true;
                    break;
                case 'players':
                    if (league.isPlayer(userId)) canVote = true;
                    break;
                case 'managers':
                    if (league.isManager(userId)) canVote = true;
                    break;
                default:
                    canVote = true;
            }
        }
        return canVote;
    }

    canShowResults(userId) {
        let showResults = false;
        let hasVoted = this.hasUserVoted(userId);
        switch (this.results_shown) {
            case 'postvote':
                if (hasVoted) showResults = true;
                else if (this.state === 'completed') showResults = true;
                break;
            case 'completion':
                if (this.state === 'completed') showResults = true;
                break;
            default:
                showResults = true;
        }
        return showResults;
    }

    canShowVoters() {
        return this.voters_shown;
    }
}

export class NerdHerderPollOption extends NerdHerderDataModel {
    constructor(pollId=null) {
        super();

        this.id = null;
        this.poll_id = pollId;
        this.state = 'draft';
        this.title = '';
        this.text = null;
        this.user_id = null;
        this.image_file = null;
    }

    getImageUrl() {
        if (this.image_file === null ) return null;
        return getStorageFilePublicUrl(`/league_uploads/${this.image_file}`);
    }
}

export class NerdHerderVenue extends NerdHerderDataModel {
    constructor(localUserId=null) {
        super();

        this.id = null;
        this.name = '';
        this.creator_id = localUserId;
        this.address1 = null;
        this.address2 = null;
        this.city = null;
        this.state_province = null;
        this.zipcode = null;
        this.country = 'United States';
        this.phone = null;
        this.email = '';
        this.venue_string = '';
        this.private_venue = false;
        this.image = 'venue_image.png';
        this.website = null;
        this.discord_link = null;
        this.description = null;
        this.latitude = null;
        this.longitude = null;
        this.timezone = null;
        this.stripe_account_id = null;
        this.stripe_onboarding_complete = false;

        this.manager_ids = [];
    }

    isManager(userId) {
        if (this.manager_ids.includes(userId)) return true;
        return false;
    }

    getManageUrl() {
        return `/app/managevenue/${this.id}`;
    }

    getImageUrl() {
        if (this.image === null || this.image.includes('venue_image.png')) {
            return getStaticStorageImageFilePublicUrl('/venue_image.png');
        }
        return this.image;
    }

    isPaymentEnabled() {
        if (this.stripe_account_id && this.stripe_onboarding_complete) return true;
        return false;
    }
}

export class NerdHerderCalendarDate extends NerdHerderDataModel {
    constructor(leagueId=null) {
        super();

        this.id = null;
        this.league_id = leagueId;
        this.name = '';
        this.description = null;
        this.date = convertTodaysLocalDateObjectToFormInput();
        this.repeat_weekly = false;
        this.repeat_schedule = null;
        this.repeat_end_date = null;
        this.repeat_exceptions = null;
    }

    getScheduleString() {
        let dateString = null;
        let luxonTimeZone = new SystemZone();
        let luxonDate = DateTime.fromISO(`${this.date}T00:00:00`, {zone: luxonTimeZone});
        dateString = luxonDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
        return dateString;
    }
}

export function NerdHerderDataModelFactory(type, jsonData, id1=null, id2=null) {
    let instance = null;
    switch(type) {
        case 'topic':
            instance = Object.assign(Object.create(NerdHerderTopic.prototype), jsonData);
            break;

        case 'self':
            instance = Object.assign(Object.create(NerdHerderUser.prototype), jsonData);
            break;

        case 'user':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderUser.prototype), jsonData);
            } else {
                instance = new NerdHerderUser();
            }
            break;

        case 'random_selector':
            instance = Object.assign(Object.create(NerdHerderRandomSelector.prototype), jsonData);
            break;

        case 'calendar_date':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderCalendarDate.prototype), jsonData);
            } else {
                instance = new NerdHerderCalendarDate(id1);
            }
            break;

        case 'notification':
            instance = Object.assign(Object.create(NerdHerderNotification.prototype), jsonData);
            break;

        case 'message':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderMessage.prototype), jsonData);
            } else {
                instance = new NerdHerderMessage();
            }
            break;

        case 'venue':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderVenue.prototype), jsonData);
            } else {
                instance = new NerdHerderVenue(id1);
            }
            break;

        case 'user-venue':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderUserVenue.prototype), jsonData);
            } else {
                instance = new NerdHerderUserVenue(id1, id2);
            }
            break;

        case 'league':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderLeague.prototype), jsonData);
            } else {
                instance = new NerdHerderLeague();
            }
            if (instance.hasOwnProperty('topic')) instance.topic = NerdHerderDataModelFactory('topic', instance.topic);
            if (instance.hasOwnProperty('venue')) instance.venue = NerdHerderDataModelFactory('venue', instance.venue);
            if (instance.hasOwnProperty('random_selectors')) {
                for (let i=0; i<instance.random_selectors.length; i++) {
                    instance.random_selectors[i] = NerdHerderDataModelFactory('random_selector', instance.random_selectors[i]);
                }
            }
            break;

        case 'user-league':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderUserLeague.prototype), jsonData);
            } else {
                instance = new NerdHerderUserLeague(id1, id2);
            }
            break;

        case 'file':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderFile.prototype), jsonData);
            } else {
                instance = new NerdHerderFile();
            }
            break;

        case 'event':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderEvent.prototype), jsonData);
            } else {
                instance = new NerdHerderEvent();
            }
            break;

        case 'game':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderGame.prototype), jsonData);
            } else {
                instance = new NerdHerderGame(id1, id2);
            }
            break;

        case 'user-game':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderUserGame.prototype), jsonData);
            } else {
                instance = new NerdHerderUserGame(id1, id2);
            }
            break;

        case 'tournament':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderTournament.prototype), jsonData);
            } else {
                instance = new NerdHerderTournament();
            }
            break;

        case 'tournament-round':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderTournamentRound.prototype), jsonData);
            } else {
                instance = new NerdHerderTournamentRound(id1, id2);
            }
            break;

        case 'user-tournament':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderTournament.prototype), jsonData);
            } else {
                instance = new NerdHerderUserTournament(id1, id2);
            }
            break;

        case 'poll':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderPoll.prototype), jsonData);
            } else {
                instance = new NerdHerderPoll(id1);
            }
            break;

        case 'poll_option':
            if (jsonData !== null) {
                instance = Object.assign(Object.create(NerdHerderPollOption.prototype), jsonData);
            } else {
                instance = new NerdHerderPollOption(id1);
            }
            break;

        default:
            console.error(`NerdHerderDataModelFactory passed invalid type: ${type}`)
    }
    
    return instance;
}
