import React from 'react';

export class NerdHerderScrollToFocusElement extends React.Component {

    constructor(props) {
        super(props);

        this.timeout = null;

        this.state = {
            done: false,
            tries: 0,
        }
    }

    findFocusElement() {
        let element = null;
        let searchFor = this.props.elementId;
        //searchFor = 'basics-card';
        //searchFor = 'delete-league-card';
        if (this.props.elementId) {
            element = document.getElementById(searchFor);
        }
        if (element === null && this.props.elementName) {
            searchFor = this.props.elementName;
            element = document.getElementByName(searchFor);
        }

        if (element) {
            // scrollIntoView is the 'traditional' way, however we sometimes put a static header up there, so in those
            // cases add a little offset
            let useOffset = false;
            let pathName = window.location.pathname;
            if (pathName.includes('app/league')) useOffset = true;
            else if (pathName.includes('app/manageleague')) useOffset = true;
            if (useOffset) {
                const elementRect = element.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const position = absoluteElementTop - 130;
                window.scrollTo(0, position);
            } else {
                element.scrollIntoView(true);
            }
            this.setState({done: true});
        } else if (this.state.tries < 10) {
            this.timeout = setTimeout(()=>this.findFocusElement(), 1000);
            this.setState({tries: this.state.tries + 1});
        } else {
            if (this.props.elementId) console.warn(`NerdHerderScrollToFocusElement was unable to find element.id=${this.props.elementId}`);
            if (this.props.elementName) console.warn(`NerdHerderScrollToFocusElement was unable to find element.name=${this.props.elementName}`);
            this.setState({done: true});
        }
    }

    render() {
        if (!this.state.done && (this.props.elementId || this.props.elementName)) {
            if (this.timeout === null) this.timeout = setTimeout(()=>this.findFocusElement(), 1000);
        }

        return(null);
    }
}