import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export class NerdHerderFontIcon extends React.Component {
    render() {
        let colorClassString = '';
        if (this.props.variant || false) {
            colorClassString = `text-${this.props.variant}`;
        }

        let positionString = '';
        if (this.props.relative) {
            positionString = 'position-relative';
        }
        
        if (this.props.fontSize) {
            return (
                <span className={`${this.props.icon} ${colorClassString} ${positionString}`} style={{fontSize: this.props.fontSize}}>{this.props.children}</span>
            )
        } else {
            return (
                <span className={`${this.props.icon} ${colorClassString} ${positionString}`}>{this.props.children}</span>
            )
        }
        
    }
}

export class NerdHerderClickableFontIcon extends React.Component {

    onClickFunction(e) {
        e.stopPropagation();
        console.debug(`clicked NerdHerderClickableFontIcon - ${this.props.icon}`);
        if (this.props.href) {
            window.open(this.props.href, this.props.target || '_blank');
        }
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        let colorClassString = '';
        if (this.props.variant || false) {
            colorClassString = `text-${this.props.variant}`;
        }

        // <a style={{float: 'right'}} target="_blank" href={mapUrl.href}><span className="flaticon-map-filled-with-a-placeholder"></span></a>
        let floatStyle = {};
        let fontSizeStyle = {};
        let extraStyle = {};
        if (this.props.float) {
            floatStyle = {float: this.props.float}
        }

        if (this.props.fontSize) {
            fontSizeStyle = {fontSize: this.props.fontSize};
        }

        if (this.props.style) {
            extraStyle = this.props.style;
        }

        let includeTooltip = false;
        if (this.props.tooltipText) {
            includeTooltip = true;
        }
        let tooltipShow = this.props.tooltipShow || 500;
        let tooltipHide = this.props.tooltipHide || 500;
        
        if (includeTooltip) {
            return (
                <OverlayTrigger placement={this.props.tooltipPlacement || 'left'} trigger={["hover","hover"]} delay={{ show: tooltipShow, hide: tooltipHide }} overlay={<Tooltip>{this.props.tooltipText}</Tooltip>}>
                    <span className={`${this.props.icon} ${colorClassString}`} style={Object.assign({}, floatStyle, fontSizeStyle, extraStyle)} onClick={(e) => this.onClickFunction(e)}>
                        {this.props.children}
                    </span>
                </OverlayTrigger>
            );
        } 
        // no tooltip
        else {
            return (
                <span className={`${this.props.icon} ${colorClassString}`} style={Object.assign({}, floatStyle, fontSizeStyle, extraStyle)} onClick={(e) => this.onClickFunction(e)}>
                    {this.props.children}
                </span>
            );
        }
        
    }
}

export class NerdHerderMapIcon extends React.Component {
    render() {
        let mapIconJsx = null;
        if (this.props.location && this.props.location.length > 10) {
            const mapUrl = new URL("https://www.google.com/maps/search/");
            mapUrl.searchParams.append('api', 1);
            mapUrl.searchParams.append('query', this.props.location);
            mapIconJsx =
                <span onClick={(e)=>{e.stopPropagation()}}>
                    <a style={{float: 'right', textDecoration: 'none'}} target="_blank" rel="noreferrer" href={mapUrl.href}>
                        <span className="flaticon-placeholder-filled-tool-shape-for-maps"/>
                    </a>
                </span>
        }
        return(mapIconJsx)
    }
}

export class NerdHerderComingSoonIcon extends React.Component {
    render() {
        let iconJsx = <Badge bg='warning' text='dark'><span className='flaticon-warning-exclamation-sign-in-filled-triangle'/> Coming Soon!</Badge>
        return(iconJsx)
    }
}

export class NerdHerderBetaIcon extends React.Component {
    render() {
        let iconJsx = <Badge bg='warning' text='dark'><span className='flaticon-warning-exclamation-sign-in-filled-triangle'/> BETA</Badge>
        return(iconJsx)
    }
}

export class NerdHerderNewIcon extends React.Component {
    render() {
        let iconJsx = <Badge bg='primary'><span className='flaticon-bookmark-for-favorites'/> NEW!</Badge>
        return(iconJsx)
    }
}

export class NerdHerderFavoriteIcon extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            favorite: this.props.favorite,
        }
    }

    onClick(event) {
        event.stopPropagation();
        this.setState({favorite: !this.state.favorite});
        this.props.onClick();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.favorite !== this.props.favorite && this.state.favorite !== this.props.favorite) this.setState({favorite: this.props.favorite});
    }

    render() {
        let iconJsx = null;
        if (this.state.favorite) {
            iconJsx =<span className='flaticon-favourites-filled-star-symbol' style={{color: '#ffc107'}}/>
        } else {
            iconJsx =<span className='flaticon-favourites-filled-star-symbol' style={{color: '#cccccc'}}/>
        }

        let clickIconJsx = null;
        if (this.props.onClick) {
            clickIconJsx =
                <span onClick={(e)=>{this.onClick(e)}}>
                    {iconJsx}
                </span>
        } else {
            clickIconJsx = iconJsx
        }
        return(clickIconJsx)
    }
}
