import React from 'react';
import withRouter from './withRouter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderConfirmModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay, getFailureMessage, capitalizeFirstLetter, delCookie, getCookie, setCookieHours, setLocal, delLocal } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { FormTypeahead } from './nerdherder-components/NerdHerderFormHelpers';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { NerdHerderScrollToFocusElement } from './nerdherder-components/NerdHerderScrollToFocus';
import { NerdHerderNavigate } from './nerdherder-components/NerdHerderNavigate';
import { LeagueListItem } from './nerdherder-components/NerdHerderListItems';

class ManageCoppaPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            errorFeedback: null,
            children: {},
        }

        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a)=>this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        const sub = this.restPubSub.subscribe('self', null, (d, k) =>this.updateLocalUser(d, k));
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        const newChildren = {...this.state.children};
        for (const childId of localUser.child_ids) {
            if (!newChildren.hasOwnProperty(childId)) {
                newChildren[childId] = null;
                const sub = this.restPubSub.subscribe('user', childId, (d, k)=>this.updateChildUser(d, k), null, childId);
                this.restPubSubPool.add(sub);
            }
        }
        this.setState({localUser: localUser, children: newChildren});
    }

    updateChildUser(userData, userId) {
        const childUser = NerdHerderDataModelFactory('user', userData);
        const newChildren = {...this.state.children};
        newChildren[userId] = childUser;
        this.setState({children: newChildren});
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return (<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);

        const childrenCards = [];
        for (const childId of this.state.localUser.child_ids) {
            const childData = this.state.children[childId];
            let childCard = null;
            if (childData === null) {
                childCard = <NerdHerderLoadingCard key={childId} title='Loading Child Data...'/>
            } else {
                childCard = <CoppaChildCard key={childId} child={childData} localUser={this.state.localUser}/>
            }
            childrenCards.push(childCard);
        }

        return(
            <NerdHerderStandardPageTemplate pageName='biometrics' headerSelection='settings' dropdownSelection='biometrics'
                                            navPath={[{icon: 'flaticon-configuration-with-gear', label: 'Child Accounts', href: '/app/parents'}]}
                                            localUser={this.state.localUser}>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                <NewChildAccountCard localUser={this.state.localUser}/>
                {childrenCards}
                <NerdHerderScrollToFocusElement elementId={this.props.query.get('focus')}/>
            </NerdHerderStandardPageTemplate>
        );
    }
}

class NewChildAccountCard extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.typeaheadRef = React.createRef();

        let childUserId = getCookie('ParentalPermissionUserId', null);
        let childUserName = getCookie('ParentalPermissionUserName', null);

        this.state = {
            errorFeedback: null,
            updating: false,
            addChildUserId: null,
            autoAddChildId: childUserId,
            autoAddChildUsername: childUserName
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    handleChildUsernameChange(userDetails) {
        if (userDetails === null) {
            this.setState({addChildUserId: null, errorFeedback: null});
        } else {
            this.setState({addChildUserId: userDetails.id, errorFeedback: null});
        }
    }

    onAddChildUser(childUserId) {
        this.restApi.genericPostEndpointData('user-claim-child', null, {child_user_id: childUserId})
        .then((response)=> {
            this.setState({errorFeedback: null, autoAddChildId: null});
            this.restPubSub.refresh('self');
            delCookie('ParentalPermissionUserId');
            delCookie('ParentalPermissionUserName');
        })
        .catch((error)=>{
            let message = getFailureMessage(error);
            message = capitalizeFirstLetter(message);
            this.setState({errorFeedback: message, autoAddChildId: null});
        });
        this.setState({errorFeedback: null});
    }

    onNotMyChild() {
        delCookie('ParentalPermissionUserId');
        delCookie('ParentalPermissionUserName');
        this.setState({autoAddChildId: null, autoAddChildUsername: null});
    }

    render() {
        let addChildUserDisabled = false;
        if (this.state.addChildUserId === null && this.state.autoAddChildId === null) {
            addChildUserDisabled = true;
        }
        else if (this.props.localUser.child_ids.includes(this.state.addChildUserId)) {
            addChildUserDisabled = true;
        }

        let showAutoAddChild = false;
        if (this.state.autoAddChildId && this.state.autoAddChildUsername) showAutoAddChild = true;

        return(
            <NerdHerderStandardCardTemplate id="add-child-user-card" title="Add Children" titleIcon='add.png'>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                <Form>
                    <Form.Group className="form-outline mb-3">
                        <div>
                            <b>NerdHerder is happy to include children under 13 years!</b>
                        </div>
                        <div>
                            <small>To do so, we must be in compliance with COPPA. You can find out more <a href='https://www.ftc.gov/business-guidance/resources/complying-coppa-frequently-asked-questions' rel='noreferrer' target='_blank'>here</a>.</small>
                        </div>
                        <div>
                            <small>
                                In summary:
                                <ul>
                                    <li>Children under 13 must have permission from a parent (or guardian).</li>
                                    <li>Parents are able to see what activities their children are participating in.</li>
                                    <li>Parents can log into their children's accounts to view their online interactions.</li>
                                </ul>
                            </small>
                        </div>
                    </Form.Group>
                    <hr/>
                    {showAutoAddChild &&
                    <Form.Group className="form-outline mb-3">
                        <Form.Label>{this.state.autoAddChildUsername}</Form.Label>
                        <Row>
                            <Col xs={12}>
                                <Form.Text muted>A user under 13 recently used this computer. Is this your child?</Form.Text>
                            </Col>
                            <Col xs={12}>
                                <div className='text-end'>
                                    <Button type='button' variant='primary' disabled={addChildUserDisabled} onClick={()=>this.onAddChildUser(this.state.autoAddChildId)}>Yes</Button>
                                    {' '}
                                    <Button type='button' variant='primary' disabled={addChildUserDisabled} onClick={()=>this.onNotMyChild()}>No</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>}
                    {!showAutoAddChild &&
                    <Form.Group className="form-outline mb-3">
                        <Form.Label>Search Users</Form.Label>
                        <Row>
                            <Col className='pe-0'>
                                <FormTypeahead ref={this.typeaheadRef} placeholder='Search for your child' delay={300} endpoint='user' queryParams={{'username-similar': 'query', 'realname-similar': 'query'}} labelKey='username' onChange={(s)=>{this.handleChildUsernameChange(s)}} disabled={this.state.updating}/>
                            </Col>
                            <Col xs='auto'>
                                <Button className='mt-1 float-end' size='sm' type='button' variant='primary' disabled={addChildUserDisabled} onClick={()=>this.onAddChildUser(this.state.addChildUserId)}><NerdHerderFontIcon icon='flaticon-add'/></Button>
                            </Col>
                        </Row>
                        <Form.Text muted>Note that all users appear in this search, not only children</Form.Text>
                    </Form.Group>}
                </Form>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class CoppaChildCard extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.typeaheadRef = React.createRef();

        this.state = {
            updating: false,
            errorFeedback: null,
            navigateTo: null,
            formPermissionGranted: this.props.child.under_13_permission,
            showUpdateToAdultModal: false,
            showLoginAsChildModal: false,
            showUnclaimChildModal: false,
            showDeleteChildModal: false
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    handlePermissionChange(event) {
        let permissionGranted = event.target.checked
        this.setState({formPermissionGranted: permissionGranted});
        const postData = {child_user_id: this.props.child.id, under_13_permission: permissionGranted};
        this.restApi.genericPostEndpointData('user-update-child', null, postData)
        .then((response)=> {
            delCookie('ParentalPermissionUserId');
            delCookie('ParentalPermissionUserName');
            this.setState({updating: false});
            this.restPubSub.refresh('user', this.props.child.id);
        })
        .catch((error)=>{
            let message = getFailureMessage(error);
            message = capitalizeFirstLetter(message);
            this.setState({errorFeedback: message, updating: false});
        });
        this.setState({updating: true});
    }

    updateToAdult() {
        const postData = {child_user_id: this.props.child.id, under_13: 0};
        this.restApi.genericPostEndpointData('user-update-child', null, postData)
        .then((response)=> {
            this.setState({updating: false});
            this.restPubSub.refresh('self');
            this.closeModals();
        })
        .catch((error)=>{
            let message = getFailureMessage(error);
            message = capitalizeFirstLetter(message);
            this.setState({errorFeedback: message, updating: false});
            this.closeModals();
        });
        this.setState({updating: true});
    }

    unclaimChild() {
        const postData = {child_user_id: this.props.child.id, parent_user_id: 0};
        this.restApi.genericPostEndpointData('user-update-child', null, postData)
        .then((response)=> {
            this.setState({updating: false});
            this.restPubSub.refresh('self');
            this.closeModals();
        })
        .catch((error)=>{
            let message = getFailureMessage(error);
            message = capitalizeFirstLetter(message);
            this.setState({errorFeedback: message, updating: false});
            this.closeModals();
        });
        this.setState({updating: true});
    }

    deleteChild() {
        this.restApi.genericPostEndpointData('user-child-delete', null, {child_user_id: this.props.child.id})
        .then((response)=> {
            this.setState({updating: false});
            this.restPubSub.refresh('self');
            this.closeModals();
        })
        .catch((error)=>{
            let message = getFailureMessage(error);
            message = capitalizeFirstLetter(message);
            this.setState({errorFeedback: message, updating: false});
            this.closeModals();
        });
        this.setState({updating: true});
    }

    loginAsChild() {
        this.restApi.genericPostEndpointData('user-child-parent-login', null, {child_user_id: this.props.child.id})
        .then((response)=> {
            this.restPubSub.refresh('self');
            const loginData = response.data;
            delCookie('DesiredUrl');
            delCookie('RememberMe');
            setCookieHours('LoginToken', loginData.login_token, 1);
            setLocal('LoginToken', loginData.login_token);
            setLocal('UserId', this.props.child.id);
            delLocal('FirebaseToken');
            this.setState({navigateTo: '/app/main'});
        })
        .catch((error)=>{
            let message = getFailureMessage(error);
            message = capitalizeFirstLetter(message);
            this.setState({errorFeedback: message, updating: false});
            this.closeModals();
        });
        this.setState({updating: true});
    }

    closeModals() {
        this.setState({showUpdateToAdultModal: false, showLoginAsChildModal: false, showUnclaimChildModal: false, showDeleteChildModal: false})
    }

    render() {
        if (this.state.navigateTo) return <NerdHerderNavigate to={this.state.navigateTo}/>
        const child = this.props.child;
        const imageHref = child.getImageUrl();

        const childMemberLeagueJsx = [];
        const childPossibleLeagueJsx = [];
        const childInterestLeagueJsx = [];
        for (const usersLeagues of child.users_leagues) {
            const leagueItem = <LeagueListItem leagueId={usersLeagues.league_id} localUser={this.props.localUser}/>
            if (usersLeagues.player || usersLeagues.manager) {
                childMemberLeagueJsx.push(leagueItem);
            } else if (usersLeagues.invite_sent || usersLeagues.join_request || usersLeagues.waitlist_position !== null) {
                childPossibleLeagueJsx.push(leagueItem);
            } else if (usersLeagues.level_of_interest !== 'none') {
                childInterestLeagueJsx.push(leagueItem);
            }
        }
        let totalLeagues = childMemberLeagueJsx.length + childPossibleLeagueJsx.length + childInterestLeagueJsx.length;

        return(
            <NerdHerderStandardCardTemplate id={`child-user-card-${this.props.child.id}`} title={this.props.child.username} titleIcon='propeller.png'>
                {this.state.errorFeedback &&
                <Alert variant='danger'>{this.state.errorFeedback}</Alert>}
                {this.state.showUpdateToAdultModal &&
                <NerdHerderConfirmModal title='Convert to Regular User?' message="Promote this child's account to a regular account? By clicking 'Accept' you are certifying that you are the child's parent (or guardian) and the child is 13 years old or older. There is no undo." acceptButtonText='Accept' onCancel={()=>this.closeModals()} onAccept={()=>this.updateToAdult()}/>}
                {this.state.showLoginAsChildModal &&
                <NerdHerderConfirmModal title='Switch to Child User?' message="This will log you out of your account and into this child's account. To return to your account, simply logout and log back into this account." acceptButtonText='Switch Accounts' onCancel={()=>this.closeModals()} onAccept={()=>this.loginAsChild()}/>}
                {this.state.showUnclaimChildModal &&
                <NerdHerderConfirmModal title='Unclaim this Child?' message="This will remove you as the parent (or guardian) to this child's account. Only do this if you wish to switch this child account to another parent account." onCancel={()=>this.closeModals()} onAccept={()=>this.unclaimChild()}/>}
                {this.state.showDeleteChildModal &&
                <NerdHerderConfirmModal title='Delete Child User?' message="This will delete this child's account. There is no undo." acceptButtonText='Delete Account' onCancel={()=>this.closeModals()} onAccept={()=>this.deleteChild()}/>}
                {!child.under_13_permission &&
                <Row>
                    <Col xs={12}>
                        <Alert variant='warning'>{child.username} has not been granted permission to use NerdHerder</Alert>
                    </Col>
                </Row>}
                <Row>
                    <Col sm='auto' className='text-center'>
                        <Image className="rounded-circle" height={150} width={150} src={imageHref} alt='user profile image'/>
                    </Col>
                    <Col>
                        <Table>
                            <tbody>
                                <tr><td>Username</td><td>{child.username}</td></tr>
                                <tr><td>Name</td><td>{child.short_name}</td></tr>
                                <tr><td>Phone</td><td>{child.phone}</td></tr>
                                <tr><td>Timezone</td><td>{child.timezone}</td></tr>
                                <tr><td>Country</td><td>{child.country}</td></tr>
                                <tr><td>Discord</td><td>{child.discord_id || 'Not Set'}</td></tr>
                            </tbody>
                        </Table>
                        <div>
                            <small className='text-muted'><b>Name</b> - we encourage you to use a nickname or first name only.</small>
                        </div>
                        <div>
                            <small className='text-muted'><b>Phone</b> - although we obfuscate your child's phone number, you may also use your number or a bogus number.</small>
                        </div>
                    </Col>
                </Row>
                {totalLeagues !== 0 &&
                <div>
                    <hr/>
                    <div><b>Events & Leagues</b></div>
                    {childMemberLeagueJsx.length !== 0 &&
                    <div>
                        <small className='text-muted'>{child.username} is participating in these events & leagues:</small>
                        {childMemberLeagueJsx}
                    </div>}
                    {childPossibleLeagueJsx.length !== 0 &&
                    <div>
                        <small className='text-muted'>{child.username} may participate in these events & leagues:</small>
                        {childPossibleLeagueJsx}
                    </div>}
                    {childInterestLeagueJsx.length !== 0 &&
                    <div>
                        <small className='text-muted'>{child.username} is interested in these events & leagues:</small>
                        {childInterestLeagueJsx}
                    </div>}
                </div>}
                <hr/>
                <Row>
                    <Col>
                        <div><b>Grant Permission</b></div>
                        <div>
                            <small className='text-muted'>By enabling this switch, you declare that you are a parent or guardian of {this.props.child.username}, and grant permission for them to access NerdHerder.</small>
                        </div>
                    </Col>
                    <Col xs='auto'>
                        <Form>
                            <Form.Check type="switch" disabled={this.state.updating} onChange={(event)=>this.handlePermissionChange(event)} autoComplete='off' checked={this.state.formPermissionGranted}/>
                        </Form>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col sm={6} className='mt-1'>
                        <div className="d-grid gap-1">
                            <Button size='sm' variant='primary' onClick={()=>this.setState({showUpdateToAdultModal: true})}>Child is 13+</Button>
                            <Button size='sm' variant='primary' onClick={()=>this.setState({showLoginAsChildModal: true})}>Login as Child</Button>
                        </div>
                    </Col>
                    <Col sm={6} className='mt-1'>
                        <div className="d-grid gap-1">
                            <Button size='sm' variant='danger' onClick={()=>this.setState({showUnclaimChildModal: true})}>Unclaim Child</Button>
                            <Button size='sm' variant='danger' onClick={()=>this.setState({showDeleteChildModal: true})}>Delete Account</Button>
                        </div>
                    </Col>
                </Row>
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(ManageCoppaPage);
