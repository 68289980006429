import React from 'react';
import withRouter from './withRouter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import he from 'he';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderGlobalRankingCalculationsModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { capitalizeFirstLetter, handleGlobalRestError } from './utilities';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderStandardCardTemplate, NerdHerderLoadingCard } from './nerdherder-components/NerdHerderStandardCardTemplate';
import { UserListItem, LeagueListItem } from './nerdherder-components/NerdHerderListItems';
import { TableOfUsers } from './nerdherder-components/NerdHerderTableHelpers';
import { EmailLink } from './nerdherder-components/NerdHerderFormHelpers';
import Button from 'react-bootstrap/esm/Button';

class TopicPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi(); 
        this.restPubSub = new NerdHerderRestPubSub();  
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        // discard any existing subs
        this.restPubSub.clear();

        // priority notifications go first
        this.priorityNotificationsToUpdate = ['contact request', 'invite'];
        this.regularNotificationsToUpdate = ['message'];
        this.notificationsToUpdate = this.priorityNotificationsToUpdate.concat(this.regularNotificationsToUpdate);

        this.state = {
            firebaseSigninComplete: false,
            localUser: null,
            errorFeedback: null,
            topicId: this.props.params.topicId,
            topic: null,
        }
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.componentDidMountStage2(), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k)=>{this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('topic', this.state.topicId, (d, k)=>{this.updateTopic(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentDidMountStage2() {
        this.setState({firebaseSigninComplete: true});
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateTopic(topicData, key) {
        const newTopic = NerdHerderDataModelFactory('topic', topicData);
        this.setState({topic: newTopic});
    }

    render() {
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return (<NerdHerderLoadingModal/>);
        if (!this.state.topic) return (<NerdHerderLoadingModal/>);
        let isCurator = this.state.topic.isCurator(this.state.localUser.id);

        return(
            <NerdHerderStandardPageTemplate pageName='topic' headerSelection='topic'
                                            navPath={[{icon: 'flaticon-bookmarked-filled-square', label: this.state.topic.name, href: `/app/topic/${this.state.topicId}`}]}
                                            localUser={this.state.localUser}>
                <SummaryCard topicId={this.state.topicId} topic={this.state.topic} localUser={this.state.localUser}/>
                {isCurator &&
                <CuratorCard topicId={this.state.topicId} topic={this.state.topic} localUser={this.state.localUser}/>}
                {this.state.topic.googlesheet &&
                <ResourcesCard topicId={this.state.topicId} topic={this.state.topic} localUser={this.state.localUser}/>}
                {this.state.topic.googlesheet &&
                <FactionsCard topicId={this.state.topicId} topic={this.state.topic} localUser={this.state.localUser}/>}
                {this.state.topic.allow_ranking &&
                <GlobalRankingCard topicId={this.state.topicId} topic={this.state.topic} localUser={this.state.localUser}/>}
            </NerdHerderStandardPageTemplate>
        );
    }
}

class SummaryCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='SummaryCard'>
                <SummaryCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class SummaryCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            bggDescription: null,
            bggThumbnailUrl: null,
            bggPublisherString: null,
        }
    }

    componentDidMount() {
        if (this.props.topic.bgg_id) {
            const queryParams = {id: this.props.topic.bgg_id};
            axios.get('https://boardgamegeek.com/xmlapi2/thing', {params: queryParams})
            .then(response => {
                this.processBggResponse(response.data);
            }).catch(error => {
                console.error('failed to get xml from BGG REST API');
            });
        }
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    processBggResponse(xmlString) {
        const xmlParser = new DOMParser();
        const xmlDoc = xmlParser.parseFromString(xmlString, "text/xml");
        
        let descriptionElements = xmlDoc.getElementsByTagName("description");
        if (descriptionElements.length !== 0) {
            let description = descriptionElements[0].childNodes[0].nodeValue;
            if (description.length > 300) {
                description = description.slice(0, 300);
                description += '...';
            }
            //description = description.replaceAll('&#10;', ' ');
            description = he.decode(description);
            this.setState({bggDescription: description});
        }

        let thumbnailElements = xmlDoc.getElementsByTagName("thumbnail");
        if (thumbnailElements.length !== 0) {
            let bggThumbnailUrl = thumbnailElements[0].childNodes[0].nodeValue;
            this.setState({bggThumbnailUrl: bggThumbnailUrl});
        }

        let linkElements = xmlDoc.getElementsByTagName("link");
        for (const element of linkElements) {
            if (element.getAttribute("type") === 'boardgamepublisher') {
                let bggPublisherString = element.getAttribute("value");
                this.setState({bggPublisherString: bggPublisherString});
                break;
            }
        }
    }

    render() {
        let otherCuratorsListItems = [];
        for (const userId of this.props.topic.curator_ids) {
            if (userId === this.props.topic.lead_user_id) continue;
            const listItem = <UserListItem slim={true} key={userId} userId={userId} localUser={this.props.localUser}/>
            otherCuratorsListItems.push(listItem);
        }

        return(
            <NerdHerderStandardCardTemplate id="topic-summary-card" title={this.props.topic.name} titleIcon='information.png'>
                <Row>
                    <Col>
                        <small className='text-muted'>On NerdHerder, {this.props.topic.name} is abbreviated {this.props.topic.id}</small>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    {this.state.bggThumbnailUrl && 
                    <Col xs={4}>
                        <div className='text-center'>
                            <Image fluid rounded alt='thumbnail' src={this.state.bggThumbnailUrl}/>
                        </div>
                    </Col>}
                    {this.state.bggDescription &&
                    <Col>   
                        <p className='mb-1'>
                            {this.state.bggDescription}
                        </p>
                        <small><small className='text-muted'>from <a target='_blank' rel='noreferrer' href={`https://boardgamegeek.com/boardgame/${this.props.topic.bgg_id}`}>Board Game Geek</a></small></small>
                    </Col>}
                </Row>
                {this.state.bggPublisherString &&
                <Row className='mt-2'>
                    <Col>
                        {this.props.topic.id} is published by {this.state.bggPublisherString}
                    </Col>
                </Row>}
                {this.props.topic.bgg_id &&
                <Row className='mt-2'>
                    <Col>
                        Further details can be found at <a target='_blank' rel='noreferrer' href={`https://boardgamegeek.com/boardgame/${this.props.topic.bgg_id}`}>Board Game Geek - {this.props.topic.name}</a>
                    </Col>
                </Row>}
                {this.props.topic.lead_user_id &&
                <Row>
                    <Col>
                        <hr/>
                        The lead curator for {this.props.topic.id} on NerdHerder is:
                        <UserListItem userId={this.props.topic.lead_user_id} localUser={this.props.localUser}/>
                        {otherCuratorsListItems.length !== 0 &&
                        <div>
                            <small className='text-muted'>Other curators include...</small>
                            {otherCuratorsListItems}
                        </div>}
                        <small className='text-muted'>If you would like to become a curator, contact <EmailLink/></small>
                    </Col>
                </Row>}
                {!this.props.topic.lead_user_id &&
                <Row>
                    <Col>
                        <hr/>
                        <Alert variant='warning'>There is no lead curator for {this.props.topic.id} on NerdHerder</Alert>
                        {otherCuratorsListItems}
                        <small className='text-muted'>If you would like to become a curator, contact <EmailLink/></small>
                    </Col>
                </Row>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class ResourcesCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='ResourcesCard'>
                <ResourcesCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class ResourcesCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            resources: null,
        }
    }

    componentDidMount() {
        if (this.props.topic.googlesheet) {
            const sub = this.restPubSub.subscribe('topic-google-data', this.props.topicId, (d, k)=>{this.updateTopicGoogleData(d, k)});
            this.restPubSubPool.add(sub);
        }         
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTopicGoogleData(topicGoogleData, key) {
        this.setState({resources: topicGoogleData.resources});
    }

    render() {
        if (this.state.resources === null) return(null);

        const headings = [];
        for (const resource of this.state.resources) {
            if (resource.length === 4) {
                let heading = resource[0];
                if (!headings.includes(heading)) {
                    headings.push(heading);
                }
            }
        }

        const sections = [];
        let index = 0;
        for (const heading of headings) {
            let sectionRows = [];
            for (const resource of this.state.resources) {
                if (resource.length === 4 && resource[0] === heading) {
                    let url = resource[1];
                    let title = resource[2];
                    let description = resource[3];
                    const resourceJsx = 
                        <div key={`${index}`}>
                            <b><a href={url} target='_blank' rel='noreferrer'>{title}</a></b><br/>
                            <small className='text-muted'>{description}</small>
                        </div>
                    sectionRows.push(resourceJsx)
                }
                index++;
            }
            let section =
                <div key={heading}>
                    <h4>{heading}</h4>
                    {sectionRows}
                </div>
            sections.push(section);
        }

        return(
            <NerdHerderStandardCardTemplate id="topic-resources-card" title='Additional Resources' titleIcon='right.png'>
                {sections}
            </NerdHerderStandardCardTemplate>
        )
    }
}

class FactionsCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='FactionsCard'>
                <FactionsCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class FactionsCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
    }

    componentDidMount() {        
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    render() {
        // if neither the lists or players have factions, don't mention factions
        if (this.props.topic.list_has_faction === false && this.props.topic.game_player_has_faction === false) return(null);
        let factionNoun = capitalizeFirstLetter(this.props.topic.faction_noun);

        const factionDict = this.props.topic.getFactionDict();
        const factionTableRows = [];
        for (const [factionId, factionName] of Object.entries(factionDict)) {
            factionTableRows.push(<tr key={factionId}><td>{factionId}</td><td>{factionName}</td></tr>)
        }

        return(
            <NerdHerderStandardCardTemplate id="topic-factions-card" title={`Recognized ${factionNoun}s`} titleIcon='team-management.png'>
                <small className='text-muted'>If another row needs to be added to this table, contact a curator and they can add it.</small>
                <Table striped>
                    <thead>
                        <tr><th>ID</th><th>{factionNoun} Name</th></tr>
                    </thead>
                    <tbody>
                        {factionTableRows}
                    </tbody>
                </Table>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class GlobalRankingCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='GlobalRankingCard'>
                <GlobalRankingCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class GlobalRankingCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            showCalculationsModal: false,
            globalRankingData: null,
        }
    }

    componentDidMount() {
        let sub = this.restPubSub.subscribe('topic-global-ranking', this.props.topicId, (d, k)=>{this.updateGlobalRanking(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    onCancelModal() {
        this.setState({showCalculationsModal: false});
    }

    updateGlobalRanking(globalRankingData, key) {
        this.setState({globalRankingData: globalRankingData});
    }

    render() {
        if (this.state.globalRankingData === null) return(<NerdHerderLoadingCard title="Global Rankings"/>);

        const playerIds = [];
        const globalRankData = {};
        const globalScoreData = {};
        for (const [rank, rankData] of Object.entries(this.state.globalRankingData)) {
            const playerId = rankData.id;
            playerIds.push(playerId);
            globalRankData[playerId] = rank;
            globalScoreData[playerId] = Math.floor(rankData.fs);
        }

        return(
            <NerdHerderStandardCardTemplate id="global-rankings-card" title="Global Rankings" titleIcon='swiss.png'>
                {this.state.showCalculationsModal &&
                <NerdHerderGlobalRankingCalculationsModal onCancel={()=>this.onCancelModal()} localUser={this.props.localUser}/>}
                <Row>
                    <Col>
                        <small className='text-muted'>Below you will find the top rated players for {this.props.topic.id}. If you are curious how this is determined (or desire to improve your score) click the button to the right.</small>
                    </Col>
                    <Col xs='auto'>
                        <Button size='sm' variant='secondary' onClick={()=>this.setState({showCalculationsModal: true})}>Calculations</Button>
                    </Col>
                </Row>
                <TableOfUsers userIds={playerIds} headers={['Rank', 'Player', 'Score']} leftColumnContent={globalRankData} rightColumnContent={globalScoreData} localUser={this.props.localUser} emptyMessage='This topic has no global ranking data'/>
            </NerdHerderStandardCardTemplate>
        )
    }
}

class CuratorCard extends React.Component {
    render() {
        return (
            <CardErrorBoundary cardTypeName='CuratorCard'>
                <CuratorCardInner {...this.props}/>
            </CardErrorBoundary>
        )
    }
}

class CuratorCardInner extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();

        this.state = {
            updating: false,
            loaded: false,
            loadedTopic: null,
        }
    }

    componentDidMount() {    
        const sub = this.restPubSub.subscribe('topic', this.props.topic.id, (d, k)=>{this.updateTopic(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    updateTopic(topicData, key) {
        this.setState({updating: false});
    }

    updateLoadedTopic(topicData, key) {
        const newTopic = NerdHerderDataModelFactory('topic', topicData);
        this.setState({loadedTopic: newTopic, loaded: true, updating: false});
    }

    onLoadTopic() {
        if (!this.state.loaded) {
            const sub = this.restPubSub.subscribe('load-topic', this.props.topic.id, (d, k)=>{this.updateLoadedTopic(d, k)});
            this.restPubSubPool.add(sub);
        } else {
            this.restPubSub.refresh('load-topic', this.props.topic.id);
        }
        this.setState({loadedTopic: null, loaded: false, updating: true});
    }

    onCommitTopic() {
        this.setState({updating: true});
        if (this.state.loaded) {
            this.restApi.genericGetEndpointData('load-topic', this.props.topic.id, {commit: true})
            .then(response => {
                this.setState({loadedTopic: null, loaded: false});
                this.restPubSub.refresh('topic', this.props.topic.id, 1000);
            })
            .catch(error => {
                console.error('got an unexpected error committing topic');
                console.error(error);
            });
        }
    }

    render() {
        let currentTopic = this.props.topic;
        let loadedTopic = this.state.loadedTopic;

        let loadedListNoun = '';
        let loadedFactionNoun = '';
        let loadedPointsNoun = '';
        let loadedFirstPlayerNoun = '';
        let loadedScoresRecorded = '';
        let loadedScore1Noun = '';
        let loadedScore1Help = '';
        let loadedScore2Noun = '';
        let loadedScore2Help = '';
        let loadedScore3Noun = '';
        let loadedScore3Help = '';
        let loadedListHasFaction = '';
        let loadedListMultiFaction = '';
        let loadedListHasPoints = '';
        let loadedGamePlayerHasFaction = '';
        let loadedGamePlayerMultiFaction = '';
        let loadedGamePlayerHasPoints = '';
        let loadedGamePlayerHasPointsHelp = '';
        let loadedGameHasPoints = '';
        let loadedGameHasPointsHelp = '';
        let loadedGameHasPointsDefault = '';
        let loadedGameHasFirstPlayer = '';
        let loadedTournamentHasDefaults = '';
        let loadedTournamentSuggestion = '';
        let loadedTournamentByeResult = '';
        let loadedTournamentByeVps1 = '';
        let loadedTournamentByeLoserVps1 = '';
        let loadedTournamentByeVps2 = '';
        let loadedTournamentByeLoserVps2 = '';
        let loadedTournamentByeVps3 = '';
        let loadedTournamentByeLoserVps3 = '';
        let loadedTournamentRanking = '';
        let loadedTournamentSoSMethod = '';

        if (loadedTopic) {
            loadedListNoun = loadedTopic.list_noun;
            loadedFactionNoun = loadedTopic.faction_noun;
            loadedPointsNoun = loadedTopic.points_noun;
            loadedFirstPlayerNoun = loadedTopic.first_player_noun;
            loadedScoresRecorded = loadedTopic.scores_recorded;
            loadedScore1Noun = loadedTopic.score1_noun;
            loadedScore1Help = loadedTopic.score1_help;
            loadedScore2Noun = loadedTopic.score2_noun;
            loadedScore2Help = loadedTopic.score2_help;
            loadedScore3Noun = loadedTopic.score3_noun;
            loadedScore3Help = loadedTopic.score3_help;
            loadedListHasFaction = loadedTopic.list_has_faction;
            loadedListMultiFaction = loadedTopic.list_multi_faction;
            loadedListHasPoints = loadedTopic.list_has_points;
            loadedGamePlayerHasFaction = loadedTopic.game_player_has_faction;
            loadedGamePlayerMultiFaction = loadedTopic.game_player_multi_faction;
            loadedGamePlayerHasPoints = loadedTopic.game_player_has_points;
            loadedGamePlayerHasPointsHelp = loadedTopic.game_player_has_points_help;
            loadedGameHasPoints = loadedTopic.game_has_points;
            loadedGameHasPointsHelp = loadedTopic.game_has_points_help;
            loadedGameHasPointsDefault = loadedTopic.game_has_points_default;
            loadedGameHasFirstPlayer = loadedTopic.game_has_first_player;
            loadedTournamentHasDefaults = loadedTopic.tournament_has_defaults;
            loadedTournamentSuggestion = loadedTopic.tournament_suggestion;
            loadedTournamentByeResult = loadedTopic.tournament_bye_result;
            loadedTournamentByeVps1 = loadedTopic.tournament_bye_vps1;
            loadedTournamentByeLoserVps1 = loadedTopic.tournament_bye_loser_vps1;
            loadedTournamentByeVps2 = loadedTopic.tournament_bye_vps2;
            loadedTournamentByeLoserVps2 = loadedTopic.tournament_bye_loser_vps2;
            loadedTournamentByeVps3 = loadedTopic.tournament_bye_vps3;
            loadedTournamentByeLoserVps3 = loadedTopic.tournament_bye_loser_vps3;
            loadedTournamentRanking = loadedTopic.tournament_ranking;
            loadedTournamentSoSMethod = loadedTopic.tournament_sos_method;

            // this is super error prone and should be re-written
            if (loadedListNoun !== currentTopic.list_noun) loadedListNoun = <span className='text-danger'>{loadedListNoun}</span>
            if (loadedFactionNoun !== currentTopic.faction_noun) loadedFactionNoun = <span className='text-danger'>{loadedFactionNoun}</span>
            if (loadedPointsNoun !== currentTopic.points_noun) loadedPointsNoun = <span className='text-danger'>{loadedPointsNoun}</span>
            if (loadedFirstPlayerNoun !== currentTopic.first_player_noun) loadedFirstPlayerNoun = <span className='text-danger'>{loadedFirstPlayerNoun}</span>
            if (loadedScoresRecorded !== currentTopic.scores_recorded) loadedScoresRecorded = <span className='text-danger'>{loadedScoresRecorded}</span>
            if (loadedScore1Noun !== currentTopic.score1_noun) loadedScore1Noun = <span className='text-danger'>{loadedScore1Noun}</span>
            if (loadedScore1Help !== currentTopic.score1_help) loadedScore1Help = <span className='text-danger'>{loadedScore1Help}</span>
            if (loadedScore2Noun !== currentTopic.score2_noun) loadedScore2Noun = <span className='text-danger'>{loadedScore2Noun}</span>
            if (loadedScore2Help !== currentTopic.score2_help) loadedScore2Help = <span className='text-danger'>{loadedScore2Help}</span>
            if (loadedScore3Noun !== currentTopic.score3_noun) loadedScore3Noun = <span className='text-danger'>{loadedScore3Noun}</span>
            if (loadedScore3Help !== currentTopic.score3_help) loadedScore3Help = <span className='text-danger'>{loadedScore3Help}</span>
            if (loadedListHasFaction !== currentTopic.list_has_faction) loadedListHasFaction = <span className='text-danger'>{loadedListHasFaction ? 'true' : 'false'}</span>
            else loadedListHasFaction = <span>{loadedListHasFaction ? 'true' : 'false'}</span>
            if (loadedListMultiFaction !== currentTopic.list_multi_faction) loadedListMultiFaction = <span className='text-danger'>{loadedListMultiFaction ? 'true' : 'false'}</span>
            else loadedListMultiFaction = <span>{loadedListMultiFaction ? 'true' : 'false'}</span>
            if (loadedListHasPoints !== currentTopic.list_has_points) loadedListHasPoints = <span className='text-danger'>{loadedListHasPoints ? 'true' : 'false'}</span>
            else loadedListHasPoints = <span>{loadedListHasPoints ? 'true' : 'false'}</span>
            if (loadedGamePlayerHasFaction !== currentTopic.game_player_has_faction) loadedGamePlayerHasFaction = <span className='text-danger'>{loadedGamePlayerHasFaction ? 'true' : 'false'}</span>
            else loadedGamePlayerHasFaction = <span>{loadedGamePlayerHasFaction ? 'true' : 'false'}</span>
            if (loadedGamePlayerMultiFaction !== currentTopic.game_player_multi_faction) loadedGamePlayerMultiFaction = <span className='text-danger'>{loadedGamePlayerMultiFaction ? 'true' : 'false'}</span>
            else loadedGamePlayerMultiFaction = <span>{loadedGamePlayerMultiFaction ? 'true' : 'false'}</span>
            if (loadedGamePlayerHasPoints !== currentTopic.game_player_has_points) loadedGamePlayerHasPoints = <span className='text-danger'>{loadedGamePlayerHasPoints ? 'true' : 'false'}</span>
            else loadedGamePlayerHasPoints = <span>{loadedGamePlayerHasPoints ? 'true' : 'false'}</span>
            if (loadedGamePlayerHasPointsHelp !== currentTopic.game_player_has_points_help) loadedGamePlayerHasPointsHelp = <span className='text-danger'>{loadedGamePlayerHasPointsHelp}</span>
            if (loadedGameHasPoints !== currentTopic.game_has_points) loadedGameHasPoints = <span className='text-danger'>{loadedTournamentHasDefaults ? 'true' : 'false'}</span>
            else loadedGameHasPoints = <span>{loadedGameHasPoints ? 'true' : 'false'}</span>
            if (loadedGameHasPointsDefault !== currentTopic.game_has_points_default) loadedGameHasPointsDefault = <span className='text-danger'>{loadedGameHasPointsDefault}</span>
            if (loadedGameHasPointsHelp !== currentTopic.game_has_points_help) loadedGameHasPointsHelp = <span className='text-danger'>{loadedGameHasPointsHelp}</span>
            if (loadedGameHasFirstPlayer !== currentTopic.game_has_first_player) loadedGameHasFirstPlayer = <span className='text-danger'>{loadedGameHasFirstPlayer ? 'true' : 'false'}</span>
            else loadedGameHasFirstPlayer = <span>{loadedGameHasFirstPlayer ? 'true' : 'false'}</span>
            if (loadedTournamentHasDefaults !== currentTopic.tournament_has_defaults) loadedTournamentHasDefaults = <span className='text-danger'>{loadedTournamentHasDefaults ? 'true' : 'false'}</span>
            else loadedTournamentHasDefaults = <span>{loadedTournamentHasDefaults ? 'true' : 'false'}</span>
            if (loadedTournamentSuggestion !== currentTopic.tournament_suggestion) loadedTournamentSuggestion = <span className='text-danger'>{loadedTournamentSuggestion}</span>
            if (loadedTournamentByeResult !== currentTopic.tournament_bye_result) loadedTournamentByeResult = <span className='text-danger'>{loadedTournamentByeResult}</span>
            if (loadedTournamentByeVps1 !== currentTopic.tournament_bye_vps1) loadedTournamentByeVps1 = <span className='text-danger'>{loadedTournamentByeVps1}</span>
            if (loadedTournamentByeLoserVps1 !== currentTopic.tournament_bye_loser_vps1) loadedTournamentByeLoserVps1 = <span className='text-danger'>{loadedTournamentByeLoserVps1}</span>
            if (loadedTournamentByeVps2 !== currentTopic.tournament_bye_vps2) loadedTournamentByeVps2 = <span className='text-danger'>{loadedTournamentByeVps2}</span>
            if (loadedTournamentByeLoserVps2 !== currentTopic.tournament_bye_loser_vps2) loadedTournamentByeLoserVps2 = <span className='text-danger'>{loadedTournamentByeLoserVps2}</span>
            if (loadedTournamentByeVps3 !== currentTopic.tournament_bye_vps3) loadedTournamentByeVps3 = <span className='text-danger'>{loadedTournamentByeVps3}</span>
            if (loadedTournamentByeLoserVps3 !== currentTopic.tournament_bye_loser_vps3) loadedTournamentByeLoserVps3 = <span className='text-danger'>{loadedTournamentByeLoserVps3}</span>
            if (loadedTournamentRanking !== currentTopic.tournament_ranking) loadedTournamentRanking = <span className='text-danger'>{loadedTournamentRanking}</span>
            if (loadedTournamentSoSMethod !== currentTopic.tournament_sos_method) loadedTournamentSoSMethod = <span className='text-danger'>{loadedTournamentSoSMethod}</span>
        }

        let googlesheetLink = null;
        if (this.props.topic.googlesheet) {
            googlesheetLink = <a target='_blank' rel='noreferrer' href={`https://docs.google.com/spreadsheets/d/${this.props.topic.googlesheet}`}>{this.props.topic.id} Googlesheet</a>
        }

        return(
            <NerdHerderStandardCardTemplate id="topic-curator-card" title='Curator Configuration' titleIcon='cogwheel.png'>
                {googlesheetLink &&
                <div>
                    <Row>
                        <Col>
                            <h5>Topic Googlesheet</h5>
                            The Googlesheet that contains the configuration options for this topic can be found here:<br/>
                            {googlesheetLink}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr/>
                            <h5>Topic Configuration</h5>
                            <Table>
                                <thead>
                                    <tr><th>Key</th><th>Current</th><th>New</th></tr>
                                </thead>
                                <tbody>
                                    <tr><td>ListNoun</td><td>{currentTopic.list_noun}</td><td>{loadedListNoun}</td></tr>
                                    <tr><td>FactionNoun</td><td>{currentTopic.faction_noun}</td><td>{loadedFactionNoun}</td></tr>
                                    <tr><td>PointsNoun</td><td>{currentTopic.points_noun}</td><td>{loadedPointsNoun}</td></tr>
                                    <tr><td>FirstPlayerNoun</td><td>{currentTopic.first_player_noun}</td><td>{loadedFirstPlayerNoun}</td></tr>
                                    <tr><td>ScoresRecorded</td><td>{currentTopic.scores_recorded}</td><td>{loadedScoresRecorded}</td></tr>
                                    <tr><td>Score1Noun</td><td>{currentTopic.score1_noun}</td><td>{loadedScore1Noun}</td></tr>
                                    <tr><td>Score1Help</td><td>{currentTopic.score1_help}</td><td>{loadedScore1Help}</td></tr>
                                    <tr><td>Score2Noun</td><td>{currentTopic.score2_noun}</td><td>{loadedScore2Noun}</td></tr>
                                    <tr><td>Score2Help</td><td>{currentTopic.score2_help}</td><td>{loadedScore2Help}</td></tr>
                                    <tr><td>Score3Noun</td><td>{currentTopic.score3_noun}</td><td>{loadedScore3Noun}</td></tr>
                                    <tr><td>Score3Help</td><td>{currentTopic.score3_help}</td><td>{loadedScore3Help}</td></tr>
                                    <tr><td>ListHasFaction</td><td>{currentTopic.list_has_faction ? 'true' : 'false'}</td><td>{loadedListHasFaction}</td></tr>
                                    <tr><td>ListMultiFaction</td><td>{currentTopic.list_multi_faction ? 'true' : 'false'}</td><td>{loadedListMultiFaction}</td></tr>
                                    <tr><td>ListHasPoints</td><td>{currentTopic.list_has_points ? 'true' : 'false'}</td><td>{loadedListHasPoints}</td></tr>
                                    <tr><td>GamePlayerHasFaction</td><td>{currentTopic.game_player_has_faction ? 'true' : 'false'}</td><td>{loadedGamePlayerHasFaction}</td></tr>
                                    <tr><td>GamePlayerMultiFaction</td><td>{currentTopic.game_player_multi_faction ? 'true' : 'false'}</td><td>{loadedGamePlayerMultiFaction}</td></tr>
                                    <tr><td>GamePlayerHasPoints</td><td>{currentTopic.game_player_has_points ? 'true' : 'false'}</td><td>{loadedGamePlayerHasPoints}</td></tr>
                                    <tr><td>GamePlayerHasPointsHelp</td><td>{currentTopic.game_player_has_points_help}</td><td>{loadedGamePlayerHasPointsHelp}</td></tr>
                                    <tr><td>GameHasPoints</td><td>{currentTopic.game_has_points ? 'true' : 'false'}</td><td>{loadedGameHasPoints}</td></tr>
                                    <tr><td>GameHasPointsHelp</td><td>{currentTopic.game_has_points_help}</td><td>{loadedGameHasPointsHelp}</td></tr>
                                    <tr><td>GameHasPointsDefault</td><td>{currentTopic.game_has_points_default}</td><td>{loadedGameHasPointsDefault}</td></tr>
                                    <tr><td>GameHasFirstPlayer</td><td>{currentTopic.game_has_first_player ? 'true' : 'false'}</td><td>{loadedGameHasFirstPlayer}</td></tr>
                                    <tr><td>TournamentHasDefaults</td><td>{currentTopic.tournament_has_defaults ? 'true' : 'false'}</td><td>{loadedTournamentHasDefaults}</td></tr>
                                    <tr><td>TournamentSuggestion</td><td>{currentTopic.tournament_suggestion}</td><td>{loadedTournamentSuggestion}</td></tr>
                                    <tr><td>TournamentByeResult</td><td>{currentTopic.tournament_bye_result}</td><td>{loadedTournamentByeResult}</td></tr>
                                    <tr><td>TournamentByeVps1</td><td>{currentTopic.tournament_bye_vps1}</td><td>{loadedTournamentByeVps1}</td></tr>
                                    <tr><td>TournamentByeLoserVps1</td><td>{currentTopic.tournament_bye_loser_vps1}</td><td>{loadedTournamentByeLoserVps1}</td></tr>
                                    <tr><td>TournamentByeVps2</td><td>{currentTopic.tournament_bye_vps2}</td><td>{loadedTournamentByeVps2}</td></tr>
                                    <tr><td>TournamentByeLoserVps2</td><td>{currentTopic.tournament_bye_loser_vps2}</td><td>{loadedTournamentByeLoserVps2}</td></tr>
                                    <tr><td>TournamentByeVps3</td><td>{currentTopic.tournament_bye_vps3}</td><td>{loadedTournamentByeVps3}</td></tr>
                                    <tr><td>TournamentByeLoserVps3</td><td>{currentTopic.tournament_bye_loser_vps3}</td><td>{loadedTournamentByeLoserVps3}</td></tr>
                                    <tr><td>TournamentRanking</td><td>{currentTopic.tournament_ranking}</td><td>{loadedTournamentRanking}</td></tr>
                                    <tr><td>TournamentSoSMethod</td><td>{currentTopic.tournament_sos_method}</td><td>{loadedTournamentSoSMethod}</td></tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button variant='primary' onClick={()=>this.onLoadTopic()}>Load Google Sheet</Button>
                                {this.state.loaded &&
                                <Button variant='danger' onClick={()=>this.onCommitTopic()}>Commit Google Sheet</Button>}
                            </div>
                        </Col>
                    </Row>
                </div>}
                {!googlesheetLink &&
                <p>You are a curator on this topic, however the curator's googlesheet is not loaded into the NerdHerder database. This means that the configuration for this topic cannot be adjusted - this is probably a mistake. Contact <EmailLink/> to find a solution.</p>}
            </NerdHerderStandardCardTemplate>
        )
    }
}

export default withRouter(TopicPage);
