import React from 'react';
import withRouter from './withRouter';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Fade from 'react-bootstrap/Fade';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { CardErrorBoundary } from './nerdherder-components/NerdHerderErrorBoundary';
import { NerdHerderFontIcon } from './nerdherder-components/NerdHerderFontIcon';
import { NerdHerderStandardPageTemplate } from './nerdherder-components/NerdHerderStandardPageTemplate';
import { NerdHerderLoadingModal, NerdHerderErrorModal, NerdHerderPleaseWaitModal, NerdHerderSetTimerModal, NerdHerderMessageModal } from './nerdherder-components/NerdHerderModals';
import { NerdHerderRestApi } from './NerdHerder-RestApi';
import { NerdHerderRestPubSub, NerdHerderRestPubSubPool } from './NerdHerder-RestPubSub';
import { NerdHerderQrCode } from './nerdherder-components/NerdHerderQrCode';
import { getStorageFilePublicUrl } from './utilities';
import { NerdHerderDataModelFactory } from './nerdherder-models';
import { handleGlobalRestError, delCookieAfterDelay } from './utilities';
import { generateTournamentCheckinCode } from './tournament_utilities';

class TournamentCheckinPage extends React.Component {
    constructor(props) {
        super(props);
        this.restApi = new NerdHerderRestApi();
        this.restPubSub = new NerdHerderRestPubSub();
        this.restPubSubPool = new NerdHerderRestPubSubPool();
        this.ref = null;

        // discard any existing subs
        this.restPubSub.clear();
        
        this.state = {
            showMessageModal: true,
            firebaseSigninComplete: false,
            localUser: null,
            tournamentId: this.props.params.tournamentId,
            tournament: null,
            league: null,
        }

        this.setRef = ref => {
            let oldref = this.ref;
            this.ref = ref;
            if (oldref === null) this.forceUpdate();
        }
            
        // reached a target page, delete the desired page cookie
        delCookieAfterDelay('DesiredUrl', 5000);
    }

    componentDidMount() {
        this.restPubSub.subscribeGlobalErrorHandler((e, a) => this.globalRestError(e, a));
        this.restApi.firebaseSignin(()=>this.setState({firebaseSigninComplete: true}), (e)=>this.globalRestError(e, 'firebase-signin'));
        let sub = this.restPubSub.subscribe('self', null, (d, k) => {this.updateLocalUser(d, k)});
        this.restPubSubPool.add(sub);
        sub = this.restPubSub.subscribe('tournament', this.state.tournamentId, (d, k) => {this.updateTournament(d, k)});
        this.restPubSubPool.add(sub);
    }

    componentWillUnmount() {
        this.restPubSubPool.unsubscribe();
    }

    globalRestError(error, apiName) {
        console.error(`An error was encountered during REST API access (${apiName})`, error);
        handleGlobalRestError(error, apiName, false);
    }

    updateLocalUser(userData, key) {
        const localUser = NerdHerderDataModelFactory('self', userData);
        this.setState({localUser: localUser});
    }

    updateTournament(tournamentData, key) {
        const tournament = NerdHerderDataModelFactory('tournament', tournamentData);
        this.setState({tournament: tournament});
        let sub = this.restPubSub.subscribe('league', tournament.league_id, (d, k) => {this.updateLeague(d, k)});
        this.restPubSubPool.add(sub);
    }

    updateLeague(leagueData, key) {
        const league = NerdHerderDataModelFactory('league', leagueData);
        this.setState({league: league});        
    }

    render() {
        if (!this.state.localUser && this.state.errorFeedback) return(<NerdHerderErrorModal errorFeedback={this.state.errorFeedback}/>);
        if (!this.state.localUser || !this.state.firebaseSigninComplete) return(<NerdHerderLoadingModal />);
        if (!this.state.tournament) return(<NerdHerderLoadingModal />);
        if (!this.state.league) return(<NerdHerderLoadingModal />);

        const manualCode = generateTournamentCheckinCode(this.state.tournamentId);
        const tournamentCheckinHref = `/app/tournament/${this.state.tournamentId}?checkin=${manualCode}`;

        let qrSize = 0;
        if (this.ref) {
            let modalRect = this.ref.getBoundingClientRect();
            let modalWidth = modalRect.width;
            if (window.innerHeight < modalWidth) qrSize = window.innerHeight * 0.75;
            else qrSize = modalWidth * 0.75;
        }

        return (
            <NerdHerderStandardPageTemplate
                pageName='tournament_checkin'
                headerSelection='leagues'
                headerHidden={true}
                toastsHidden={true}
                allowLocalUserRefresh={false}
                dropdownSelection={this.state.league.name}
                disableRefresh={false}
                localUser={this.state.localUser}>
                {this.state.showMessageModal &&
                <NerdHerderMessageModal title='Check-In Codes' message='Organizers should print or display this page at the venue during the tournament to allow players to check-in electronically.' autoCancelDuration='8000' onCancel={()=>this.setState({showMessageModal: false})}/>}

                <Card className="card shadow-sm" style={{borderRadius: '0.5rem'}}>
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <h2>{this.state.tournament.name} Check-In</h2>
                                <hr className="mb-1"/>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12}>
                                <h4>Use This QR Code To Check-In Automatically:</h4>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col xs={12} className="text-center" ref={this.setRef}>
                                <Row className='justify-content-center'>
                                    <Col xs='auto'>
                                        {qrSize !== 0 && 
                                        <NerdHerderQrCode size={qrSize} href={tournamentCheckinHref}/>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr/>
                                <h4>Use This Code To Check-In Manually:</h4>
                                <div className='text-center mx-5'>
                                    <div style={{border: '5px solid #0d6efd', borderRadius: '8px', fontSize: '48px'}}>
                                        <span><b>{manualCode}</b></span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </NerdHerderStandardPageTemplate>
        );     
    }
}

export default withRouter(TournamentCheckinPage);
