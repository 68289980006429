import React from 'react';
import Badge from 'react-bootstrap/esm/Badge';

export class LongshanksId extends React.Component {

    render() {
        if (!this.props.id) return(null);
        const baseUrl = 'https://longshanks.org/player';
        
        try {
            let array = this.props.id.split('#');
            let textPart = array[0];
            let numericPart = array[1];
            let textArray = textPart.split(' ');
            if (textArray.length === 0) {
                return(null);
            } else if (textArray.length === 1) {
                if (this.props.noLink) return (<span>{textArray[0]} <small>({numericPart})</small></span>);
                else return (<span><a target='_blank' rel='noreferrer' href={`${baseUrl}/${numericPart}/`}>{textArray[0]} <small>({numericPart})</small></a></span>);
            } else if (textArray.length === 2) {
                if (this.props.noLink) return (<span>{textArray[0]} {textArray[1]} <small>({numericPart})</small></span>);
                else return (<span><a target='_blank' rel='noreferrer' href={`${baseUrl}/${numericPart}/`}>{textArray[0]} {textArray[1]} <small>({numericPart})</small></a></span>);
            } else {
                if (this.props.noLink) return (<span>{textArray[0]}<Badge bg='secondary'>{textArray[1]}</Badge>{textArray[2]}<small>({numericPart})</small></span>);
                else return (<span><a target='_blank' rel='noreferrer' href={`${baseUrl}/${numericPart}/`}>{textArray[0]}<Badge bg='primary'>{textArray[1]}</Badge>{textArray[2]}<small>({numericPart})</small></a></span>);
            }
            
        } catch(error) {
            console.error('could not parse longshanks id')
        }
        return (null);
    }
}